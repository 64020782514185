import {
  Airline,
  CustomOffice,
  Debtor,
  ExportTransportDocument,
  LocalAgent,
  LocalForwarder,
  Measures, PortDetail,
  Reference,
  Supplier,
  Warehouse
} from './file';
import {Importer} from './clearingFile';
import {BillOfEntryMarksAndNumbers, EDIMessage} from './billOfEntries';
import {CuscarType} from './roadManifest';

export class TransShipmentDetails {
  feederVessel?: string;
  feederVoyageNo?: string;
  transferPort?: PortDetail;
  departureDate?: string;
  arrivalDate?: string;
}

export class ForwardingTransportDocument extends ExportTransportDocument {
  masterAirline?: Airline;
  releaseAgent?: LocalForwarder;
  measures?: MasterBillMeasures;
  importer?: Importer;
  supplier?: Supplier;
  debtor?: Debtor;
  localAgent?: LocalAgent;
  clearingFileNumber?: string;
  marksNumbersAndEndorsement?: BillOfEntryMarksAndNumbers[];
  runningTotals?: RunningTotals;
  transShipmentDetails?: TransShipmentDetails;
  trackingDetails?: TrackingDetails;
  digiScan?: DigiScan;
  scanHawb?: string;
  specialInstruction?: string;
  invoicingInUse?: boolean;

}

export class TrackingDetails {
  submissionDate?: string;
  releaseDate?: string;
  receivedAtORTIA?: string;
  receivedAtJNBOPS?: string;
  podDate?: string;
  comment?: string;
  hawbAndDetails?: string;
  statusCustomsReleased?: string;
  statusStoppedForInspection?: string;
  statusConditionallyReleased?: string;
  statusShipmentDetainedForOGA?: string;
  statusSupportingDocumentsRequired?: string;
  statusShipmentReferredForChecking?: string;
  statusOGASupportingDocuments?: string;
  statusOGAReleased?: string;
  statusExamScheduleFor?: string;
  statusExamComplete?: string;
  statusReportReceived?: string;
  statusSupportingDocumentsReceived?: string;
  statusOutcomeOfInspection?: string;
  statusProvisionPaymentOrVOClodged?: string;
  statusStoppedShipmentReleased?: string;
  statusGeneralQueryTimestamp?: string;
  generalQuery?: string;
}

export class MasterBillMeasures extends Measures {
  numberOfHawbs?: number;
}

export class DigiScan {
  scanHawb?: string;
  typeHawb?: string;
  customerReference?: string;
  specialInstruction?: string;
  noOfItems?: number;
  descriptionOfGoods?: string;
  length?: number;
  width?: number;
  height?: number;
  totalMass?: number;
  volMass?: number;
  importer?: Importer;
  supplier?: Supplier;
}


export class RunningTotals {
  remainingNoOfPacks?: number;
  capturedNoOfPacks?: number;
  remainingNoOfHouseBills?: number;
  capturedNoOfHouseBills?: number;
  remainingWeight?: number;
  capturedWeight?: number;
  remainingChargeableWeight?: number;
  capturedChargeableWeight?: number;
}

export class ForwardingFile {
  fileNumber?: string;
  id?: string;
  transportMethodCode?: string;
  masterBill?: ForwardingTransportDocument;
  customOffice?: CustomOffice;
  reference?: Reference;
  warehouse?: Warehouse;
  houseBills?: ForwardingTransportDocument[];
  contrl?: EDIMessage;
  cusres?: EDIMessage;
  cuscars?: string[];
  cuscarType?: CuscarType;
  sequence?: number;
  manifestType?: string;
  branch?: string;
  manifestAmendmentOrCancellation?: string;

  [k: string]: any;
}
