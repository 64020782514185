<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12">
          <digi-boe-lines-top-section></digi-boe-lines-top-section>
        </div>
        <div class="ui-g-12">
          <digi-boe-lines-table-section [billOfEntryLines]="billOfEntry.lines"
            (selectedBillOfEntryLine)="selectBillOfEntryLine($event)"></digi-boe-lines-table-section>
        </div>
        <div class="ui-g-12">
          <digi-boe-lines-tab-tariff-description-section></digi-boe-lines-tab-tariff-description-section>
        </div>
        <div class="ui-g-12">
          <digi-boe-line-details [fileType]="fileType" [markedAndSubmitted]="markedAndSubmitted"></digi-boe-line-details>
        </div>
      </div>
    </div>
  </div>
</div>
