import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-travel-types',
  templateUrl: './travel-types.component.html',
  styleUrls: ['./travel-types.component.css']
})
export class TravelTypesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
