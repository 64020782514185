<div class="ui-fluid">
  <div class="ui-g">
    <div *ngIf="fileType==='imports'" class="ui-g-12">
      <digi-app-local-forwarder (cargoCarrierCode)="getCargoCarrierCode($event)"></digi-app-local-forwarder>
    </div>
    <div class="ui-g-12 " *ngIf="file.clearingInstructions[0].transportMethod.method === 'ROA'">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" class="ui-inputgroup"
                       [(ngModel)]="transportDocument.clientRefNo"
                       pInputText required>
                     <label>Client Ref no</label>
             </span>
            </div>
          </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="file.clearingInstructions[0].transportMethod.method === 'AIR'">
        <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
          <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text"
                       pInputText
                       [(ngModel)]="transportDocument.flight" required=true>
                <label>Flight</label>
              </span>
          </div>
           <div *ngIf="!file.quotation && !transportDocument.flight" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
        </div>
    </div>
    <div class="ui-g-12" *ngIf="file.clearingInstructions[0].transportMethod.method === 'AIR'">
      <digi-port-details [transportDetails]="transportDocument.departurePort" [file]="file" [transportMethodCode]="'AIR'">
      </digi-port-details>
    </div>
    <div class="ui-g-12" *ngIf="file.clearingInstructions[0].transportMethod.method === 'AIR'">
      <digi-port-details portLabel='Destination Airport'
                         [transportDetails]="transportDocument.destinationPort" [file]="file" [transportMethodCode]="'AIR'">
      </digi-port-details>
    </div>
    <div class="ui-g-12" *ngIf="fileType==='exports' && isAirTransport()">
      <digi-port-details portLabel='Final Destination Airport'
                         [transportDetails]="transportDocument.finalDestinationPort" [file]="file" [transportMethodCode]="'AIR'">
      </digi-port-details>
    </div>
    <div *ngIf="fileType==='exports' && isAirTransport()" class="ui-g-12 ui-md-12">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="portOfExit" [ngModel]="transportDocument.exitPort" field="label"
                    [suggestions]="filteredPortDetails"
                    (completeMethod)="searchExitPort($event)"
                    (onSelect)="selectExitPort($event)"
                    [autoHighlight]="true"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="portOfExit">Place of Exit</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!transportDocument.exitPort?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>
                        </div>
    </div>
    <div class="ui-g form-group" *ngIf="file.clearingInstructions[0].transportMethod.method === 'AIR'">
      <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="departureDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="transportDocument.departureDate">
                                  </p-inputMask>
                                  <label for="departureDate">Departure Date</label>
                                </span>
                              </span>
                               <div *ngIf="validationService.validateDateIsBefore(transportDocument.departureDate)"
                                    class="ui-message warning ui-corner-all ">Departure date should not be past date</div>
                            </span>
      </div>

      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="arrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="transportDocument.arrivalDate">
            </p-inputMask>
            <label for="arrivalDate">Arrival Date</label>
        </span>
          <div *ngIf="!file.quotation && !transportDocument.arrivalDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
      </div>
    </div>
  </div>
</div>



