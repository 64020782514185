<div class="ui-g-12 ui-md-12">
  <div class="ui-g-6 ui-md-6">
    <p-panel header="Personal Details">
      <div class="ui-g form-group ui-fluid">
        <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     [(ngModel)]="selectedCrewMember.surname">
              <label>Surname</label>
            </span>
            </div>
            <div *ngIf="!selectedCrewMember.surname"
                 class="ui-message ui-messages-error ui-corner-all">This field is required
            </div>
            </span>
        </div>
        <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     [(ngModel)]="selectedCrewMember.firstNames">
              <label>First Names</label>
            </span>
            </div>
            <div *ngIf="!selectedCrewMember.firstNames"
                 class="ui-message ui-messages-error ui-corner-all">This field is required
            </div></span>
        </div>
        <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     maxlength="13"
                     [(ngModel)]="selectedCrewMember.idNumber">
              <label>ID Number</label>
            </span>
            </div>
            <div *ngIf="!selectedCrewMember.idNumber"
                 class="ui-message ui-messages-error ui-corner-all">This field is required
            </div>
            </span>
        </div>
        <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="dateOfBirth" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="selectedCrewMember.dateOfBirth">

                  </p-inputMask>
                     <label for="dateOfBirth">Date of Birth</label>
                </span>
              </span>
              <div *ngIf="!selectedCrewMember.dateOfBirth"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
        </div>
        <div class="ui-g-6 ui-md-6">
            <span class="ui-float-label">
            <p-dropdown [options]="genderOptions"
                        [(ngModel)]="selectedCrewMember.gender"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Gender</label>
          </span>
          <span class="md-inputfield">
              <div *ngIf="!selectedCrewMember.gender" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
        </div>
        <div class="ui-g-6 ui-md-6">
            <span class="ui-float-label">
            <p-dropdown [options]="occupationOptions"
                        [(ngModel)]="selectedCrewMember.occupation"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Occupation</label>
          </span>
          <span class="md-inputfield">
              <div *ngIf="!selectedCrewMember.occupation" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
        </div>
        <digi-country-of-origin class="ui-g-6 ui-md-6" [country]="selectedCrewMember?.nationality" label="Nationality"
                                (onSelect)="selectNationality($event)"></digi-country-of-origin>
        <digi-country-of-origin class="ui-g-6 ui-md-6" [country]="selectedCrewMember?.countryOfResidence"
                                label="Country Of Residence"
                                (onSelect)="selectCountryOfResidence($event)"></digi-country-of-origin>
        <div class="ui-g-12 ui-md-12">
          <p-checkbox name="foreign"
                      label="Foreign"
                      [(ngModel)]="selectedCrewMember.foreign"
                      binary="true">
          </p-checkbox>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-6 ui-md-6">
    <div class="ui-g-12 ui-md-12">
      <p-panel header="Travel Details">
        <div class="ui-g form-group ui-fluid">
          <div class="ui-g-6 ui-md-6">
            <span class="ui-float-label">
            <p-dropdown [options]="travelerTypeOptions"
                        [(ngModel)]="selectedCrewMember.travelerType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Traveler Type</label>
          </span>
            <span class="md-inputfield">
              <div *ngIf="!selectedCrewMember.travelerType" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
          </div>
          <div class="ui-g-6 ui-md-6">
            <span class="ui-float-label">
            <p-dropdown [options]="reasonForMovementOptions"
                        [(ngModel)]="selectedCrewMember.reasonForMovement"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Reason For Movement</label>
          </span>
            <span class="md-inputfield">
              <div *ngIf="!selectedCrewMember.reasonForMovement" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
          </div>
          <div class="ui-g-6 ui-md-6">
            <span class="ui-float-label">
            <p-dropdown [options]="travelerDocTypeOptions"
                        [(ngModel)]="selectedCrewMember.travelDocType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Travel Document Type</label>
          </span>
            <span class="md-inputfield">
              <div *ngIf="!selectedCrewMember.travelDocType" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
          </div>
          <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     [(ngModel)]="selectedCrewMember.passportNumber">
              <label>Passport Number</label>
            </span>
            </div>
            <div *ngIf="!selectedCrewMember.passportNumber"
                 class="ui-message ui-messages-error ui-corner-all">This field is required
            </div>
            </span>
          </div>
          <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="travelDocExpiryDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="selectedCrewMember.travelDocExpiryDate">

                  </p-inputMask>
                     <label for="travelDocExpiryDate">Travel Doc. Expiry Date</label>
                </span>
              </span>
              <div *ngIf="!selectedCrewMember.travelDocExpiryDate"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
          </div>
          <digi-country-of-origin class="ui-g-6 ui-md-6" [country]="selectedCrewMember?.travelDocCountryOfIssue"
                                  label="Travel Doc. Country Of Issue"
                                  (onSelect)="selectTravelDocCountryOfIssue($event)"></digi-country-of-origin>
        </div>
      </p-panel>
    </div>
    <div *ngIf="showRole" class="ui-g-12 ui-md-12">
      <p-panel header="Role">
        <div class="ui-g form-group ui-fluid">
          <div class="ui-g-3 ui-md-3">
            <p-radioButton name="rg" value="DRIVER" label="Driver" [(ngModel)]="selectedCrewMember.role"></p-radioButton>
          </div>
          <div class="ui-g-3 ui-md-3">
            <p-radioButton name="rg" value="PASSENGER" label="Passenger" [(ngModel)]="selectedCrewMember.role"></p-radioButton>
          </div>
          <div class="ui-g-3 ui-md-3">
            <p-radioButton name="rg" value="CREW_MEMBER" label="Crew" [(ngModel)]="selectedCrewMember.role"></p-radioButton>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
