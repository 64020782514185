<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g">
      <div class="ui-g-4">
        <p-panel header="Supplier Details">
          <digi-supplier [isQuotation]="clearingFile.quotation"></digi-supplier>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Importer Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <digi-importer></digi-importer>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Default Values">
          <digi-invoice-default-values *ngIf="selectedSupplierInvoice" [isQuotation]="clearingFile.quotation">
            <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <input type="text" placeholder="VDN Number" pInputText
                   [(ngModel)]="selectedSupplierInvoice.supplier.vdnNumber" readonly=true>
          </span>
            </div>
            <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <input type="text" pInputText
                   [(ngModel)]="selectedSupplierInvoice.supplier.vdnMarkup"
                   placeholder="Markup %" readonly=true>
          </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">
                <p-checkbox [(ngModel)]="selectedSupplierInvoice.supplier.vdnWarning" binary="true"></p-checkbox>
              </span>
                <span class="ui-inputgroup-addon">VDN Warning Message</span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <button pButton type="submit" label="Edit Relationship" icon="ui-icon-edit"></button>
            </div>
          </digi-invoice-default-values>
        </p-panel>
      </div>
    </div>
  </div>
</div>
