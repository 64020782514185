import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternationalPortService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllInternationalPortStartWith(code): Observable<any> {
    if (code === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/internationalPorts/search/codeOrNameStartsWith?code=` + code)
      .pipe(
        map(response => response['_embedded'].internationalPorts)
      );
  }

  findAllInternationalPortsWithCodeOrNameStartsWith(code): Observable<any> {
    if (code === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/internationalPorts/search/findByCodeOrNameStartsWithIgnoreCase?code=` + code)
      .pipe(
        map(response => response['_embedded'].internationalPorts)
      );
  }

  findTransportDetailsWithCodeOrNameAndTransportMethodIsAir(searchValue): Observable<any> {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/internationalPorts/search/codeOrNameStartsWithAndTransportMethod?code=`
        + searchValue + '&' + 'transportMethod=AIR')
      .pipe(
        map(response => response['_embedded'].internationalPorts)
      );
  }

  findTransportDetailsWithCodeOrNameAndTransportMethodIsNotAir(searchValue): Observable<any> {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/internationalPorts/search/findByCodeStartsWithIgnoreCaseAndTransportMethodOrTransportMethodIsNull?code=`
        + searchValue + '&' + 'transportMethod=null')
      .pipe(
        map(response => response['_embedded'].internationalPorts)
      );
  }

  findAllInternationalPortsWithCodeOrNameStartsWithAndCountryCode(query: string, country: string) {
    if (query === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/internationalPorts/search/findByCodeOrNameStartsWithIgnoreCaseAndCountry?code=` + query + `&countryCode=${country}`)
      .pipe(
        map(response => response['_embedded'].internationalPorts)
      );
  }
}


