import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  TariffCodeReference,
  TariffDescriptionHistory,
  TariffFormula,
  TariffFormulaPK,
} from '../app/digi-common/models/TariffCode';
import { SchedulePart } from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class TariffCodeBehaviorSubject {
  private tariffCodeSubject = new BehaviorSubject<TariffCodeReference>(this.buildTariffCode());

  constructor() {
  }

  getTariffCode(): Observable<TariffCodeReference> {
    return this.tariffCodeSubject.asObservable();
  }

  addTariffCode(tariffCode: TariffCodeReference) {
    this.tariffCodeSubject.next(tariffCode);
  }


  buildTariffCode(): TariffCodeReference {
    const tariff = new TariffCodeReference();
    tariff.schedulePart = new SchedulePart();
    tariff.formulae = new Array<TariffFormula>();
    tariff.formulae[0] = new TariffFormula();
    tariff.formulae[0].id = new TariffFormulaPK();
    tariff.descriptions = new Array<TariffDescriptionHistory>();
    tariff.formulae = new Array<TariffFormula>();
    return tariff;
  }
}
