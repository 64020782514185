import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Debtor} from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private isFileSaving = new BehaviorSubject<boolean>(null);
  private isLoadingTariffData = new BehaviorSubject<boolean>(null);
  private isLoadingPermit = new BehaviorSubject<boolean>(null);
  private isLoadingAdditionalSchedules = new BehaviorSubject<boolean>(null);
  constructor() { }

  setIsFileSaving(value: boolean) {
    this.isFileSaving.next(value);
  }
  getIsFileSaving(): Observable<boolean> {
    return this.isFileSaving.asObservable();
  }

  setIsLoadingTariffData(value: boolean) {
    this.isLoadingTariffData.next(value);
  }

  getIsLoadingTariffData() {
    return this.isLoadingTariffData.asObservable();
  }

  setIsLoadingPermit(value: boolean) {
    this.isLoadingPermit.next(value);
  }

  getIsLoadingPermit() {
    return this.isLoadingPermit.asObservable();
  }

  setIsLoadingAdditionalSchedules(value: boolean) {
    this.isLoadingAdditionalSchedules.next(value);
  }

  getIsLoadingAdditionalSchedules() {
    return this.isLoadingAdditionalSchedules.asObservable();
  }
}
