import {Injectable, Inject} from '@angular/core';
import {EMPTY, empty, Observable, of} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Address, Debtor} from '../models/file';
import {ClearingFile} from '../models/clearingFile';

@Injectable({
  providedIn: 'root'
})
export class DebtorService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findDebtorByCode(code) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + code + '&roleType=1' + '&projection=flattenedDebtor')
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findDebtorFromClearingFileAndDebtors(clearingFile: ClearingFile, debtors: Debtor[]) {
    if (clearingFile.debtor.code) {
      return this.findDebtorFromClearingFile(clearingFile, clearingFile.debtor, false);
    }
    if (debtors && debtors.length === 1) {
      return this.findDebtorFromClearingFile(clearingFile, debtors[0]);
    }
    return EMPTY;
  }

  findDebtorFromClearingFile(clearingFile: ClearingFile, debtor: Debtor, updateDebtor: boolean = true) {
    if (clearingFile) {
      return this.findDebtorByCode(debtor.code).pipe(
        tap(debtorData => {
          if (updateDebtor) {
            clearingFile.debtor = new Debtor();
            clearingFile.debtor.name = debtor.name;
            clearingFile.debtor.code = debtor.code;
            clearingFile.debtor.label = debtor.label;
          }
          if (debtorData && (!clearingFile.debtor.address || !clearingFile.debtor.address.line1)) {
            const debtorAddresses = debtorData[0].addresses;
            let debtorAddress;
            if (debtorAddresses.length === 1) {
              debtorAddress = debtorAddresses[0];
            } else {
              debtorAddress = debtorAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'INV';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (debtorAddress) {
              clearingFile.debtor.address = new Address();
              clearingFile.debtor.address.line1 = debtorAddress.line1;
              clearingFile.debtor.address.line2 = debtorAddress.line2;
              clearingFile.debtor.address.line3 = debtorAddress.line3;
              clearingFile.debtor.address.line4 = debtorAddress.line4;
              clearingFile.debtor.address.zipCode = debtorAddress.zipCode;
            }
          }

        }));
    } else {
      return EMPTY;
    }
  }

  findDebtorCurrency(debtorCode) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + debtorCode + '&roleType=1')
      .pipe(
        map(response => response['_embedded'].businessEntities[0].roles.find(
          role => role.roleType === 1
        ).currency)
      );
  }
}
