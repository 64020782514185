<div class="card card-w-title">
  <h1>Diagnostic Tests</h1>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12 ui-md-4">
        <button
          pButton
          type="button"
          label="Run Diagnostic Tests"
          icon="ui-icon-edit"
          (click)="runDiagnosticTests()"
          [disabled]="isRunning"
        ></button>
      </div>
      <div *ngIf="isRunning" class="ui-g-12 ui-md-8">
        <p-progressBar [value]="percentCompleted"></p-progressBar>
      </div>
    </div>
  </div>

  <hr />

  <p-table
    [columns]="cols"
    [value]="diagnosticTests"
    selectionMode="single"
    dataKey="vin"
    [(selection)]="selectedDiagnosticTest"
    [paginator]="false"
    [rows]="10"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      Diagnostic test results
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            'background-color': getBackgroundColorByStatusDescription(
              rowData.statusDescription
            )
          }"
        >
          {{ rowData[col.field] }}

          <!-- <div *ngIf="col.field !== 'testToRun'">
          {{rowData[col.field]}}
          </div>
          <div *ngIf="col.field === 'testToRun'">
            <div class="btn btn-primary">Run</div>
          </div> -->
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
