import { Injectable, OnInit } from '@angular/core';
import { ClearingFileBehaviourSubject } from '../../subjects/clearingfile-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../subjects/supplierInvoice-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../subjects/transport-document-behaviour-subject';
import { ClearingFileService } from './services/clearing-file.service';
import { ShareDataService } from './services/share-data.service';
import { CorrectionService } from './services/correction.service';
import { ClearingFile } from './models/clearingFile';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TransportDocument } from './models/file';
import {FileService} from './services/file.service';

@Injectable({
  providedIn: 'root',
})
export class SaveClearingFile implements OnInit {
  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingFileService: ClearingFileService,
              private shareDataService: ShareDataService,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
              private correctionService: CorrectionService,
              private fileService: FileService
  ) {
  }

  ngOnInit() {
  }

  public saveClearingFile(clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine): Observable<ClearingFile> {
    this.fileService.setIsFileSaving(true);
    if (!clearingFile.type) {
      clearingFile.type = 'ClearingFile';
      return this.clearingFileService.sendClearingFile(clearingFile).pipe(
        tap((data: ClearingFile) => {
            this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument,
              selectedSupplierInvoice, selectedSupplierInvoiceLine);
          this.fileService.setIsFileSaving(false);
          },
        ),
      )
    } else if (clearingFile.type === 'Correction') {
      return this.correctionService.sendCorrection(clearingFile).pipe(
        tap(data => {
          this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument,
            selectedSupplierInvoice, selectedSupplierInvoiceLine);
          this.fileService.setIsFileSaving(false);
        })
      )
    } else {
      return this.clearingFileService.sendClearingFile(clearingFile).pipe(
        tap((data: ClearingFile) => {
          this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument,
            selectedSupplierInvoice, selectedSupplierInvoiceLine);
          this.fileService.setIsFileSaving(false);
        })
      )
    }
  }

  updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    // update clearingFile
    this.clearingFileBehaviourSubject.addClearingFile(data);
    const transportDocuments = data.transportDocuments;
    // check if transport documents are available and navigate through
    if (transportDocuments === null || transportDocuments.length === 0) {
      const transportDocument: TransportDocument = this.shareDataService.addTransportDocument('imports');
      if (clearingFile) {
        clearingFile.transportDocuments.push(transportDocument);
        clearingFile.transportDocuments = [...clearingFile.transportDocuments];
        this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
      }
    } else {
      if (selectedTransportDocument) {
        const nextTransportDocument = transportDocuments.find(t => t.hawbDate === selectedTransportDocument.hawbDate);
        if (nextTransportDocument !== undefined) {
          this.transportDocumentBehaviourSubject.addTransportDocument(nextTransportDocument);
        } else {
          this.transportDocumentBehaviourSubject.addTransportDocument(transportDocuments[0]);
        }
        this.navigateSupplierInvoices(nextTransportDocument, transportDocuments, selectedSupplierInvoice, selectedSupplierInvoiceLine);
      }
    }
  }

  private navigateSupplierInvoices(selectedTransportDocument, transportDocuments, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    if (selectedTransportDocument) {
      if (selectedSupplierInvoice) {
        transportDocuments.forEach(t => {
          const nextSupplierInvoice = t.supplierInvoices.find(s => s.invoiceDate === selectedSupplierInvoice.invoiceDate && s.invoiceNumber === selectedSupplierInvoice.invoiceNumber);
          if (nextSupplierInvoice) {
            this.supplierInvoiceBehaviourSubject.addSupplierInvoice(nextSupplierInvoice);
          } else {
            this.supplierInvoiceBehaviourSubject.addSupplierInvoice(selectedTransportDocument.supplierInvoices[0]);
          }
          this.navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine);
        });
      }
    }
  }

  private navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    if (selectedSupplierInvoice) {
      const nextSupplierInvoiceLine = selectedSupplierInvoice.lines.find(l => selectedSupplierInvoiceLine && l.lineNo === selectedSupplierInvoiceLine.lineNo);
      if (nextSupplierInvoiceLine) {
        this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(nextSupplierInvoiceLine);
      } else {
        this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(selectedSupplierInvoice.lines[0]);
      }
    }
  }
}
