import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {BusinessEntity} from '../../digi-business-entity/models/business-entity';
import {Observable} from 'rxjs';
import {DutyCalculationParams} from '../models/DutyCalculationParams';

@Injectable({
  providedIn: 'root'
})
export class DutyCalculationService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  calculateDuty(params: DutyCalculationParams): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.apiEndpoint + 'api/tariffCodes/calculateDuty', JSON.stringify(params), {headers});
  }
}
