/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i2 from "primeng/components/steps/steps";
import * as i3 from "@angular/router";
import * as i4 from "./exbond-dashboard.component";
import * as i5 from "../../../subjects/exbondfile-behaviour-subject";
import * as i6 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i7 from "../services/ex-bond-file.service";
import * as i8 from "../../digi-common/services/notification.service";
import * as i9 from "../../digi-common/services/share-data.service";
import * as i10 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i11 from "../../../subjects/billOfEntryLine-behaviour-subject";
import * as i12 from "../../digi-common/services/validation.service";
var styles_ExbondDashboardComponent = ["\n      .ui-steps-item {\n          width: 14.2%\n      }\n  "];
var RenderType_ExbondDashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExbondDashboardComponent, data: {} });
export { RenderType_ExbondDashboardComponent as RenderType_ExbondDashboardComponent };
export function View_ExbondDashboardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Steps_0, i1.RenderType_Steps)), i0.ɵdid(2, 49152, null, 0, i2.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"] }, { activeIndexChange: "activeIndexChange" }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 5, 0); }, null); }
export function View_ExbondDashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-exbond-dashboard", [], null, null, null, View_ExbondDashboardComponent_0, RenderType_ExbondDashboardComponent)), i0.ɵdid(1, 245760, null, 0, i4.ExbondDashboardComponent, [i5.ExBondFileBehaviourSubject, i6.BillOfEntryBehaviourSubject, i7.ExBondFileService, i8.NotificationService, i3.Router, i9.ShareDataService, i10.ClearingInstructionBehaviorSubject, i6.BillOfEntryBehaviourSubject, i11.BillOfEntryLineBehaviourSubject, i12.ValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExbondDashboardComponentNgFactory = i0.ɵccf("digi-exbond-dashboard", i4.ExbondDashboardComponent, View_ExbondDashboardComponent_Host_0, {}, {}, []);
export { ExbondDashboardComponentNgFactory as ExbondDashboardComponentNgFactory };
