<div class="ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports' || fileType === 'exbond'">
        <p-autoComplete [ngModel]="selectedBillOfEntry.paymentType"
          placeholder="Payment Code" field="label"
          [autoHighlight]="true" [dropdown]="true"
          [suggestions]="paymentTypes"
          (completeMethod)="filterPaymentTypes($event)"
          (onSelect)=selectPaymentType($event)>
          <ng-template let-option pTemplate="item">
            <div>{{option.paymentCode}}:{{option.paymentDesc}}</div>
          </ng-template>
        </p-autoComplete>

    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'exports'">
      <span class="md-inputfield">
        <input type="text" pInputText value="{{selectedBillOfEntry?.exchangeRateDate}}"
               readonly>
        <label>Exchange Rate Date</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
          <p-checkbox [(ngModel)]="selectedBillOfEntry.vatIndicator" label="Vat
            Indicator" binary="true"></p-checkbox>
            </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports'">
      <span class="md-inputfield">
        <input type="text" pInputText value="{{selectedBillOfEntry?.cifcValue}}"
          readonly>
        <label> C.I.F & C</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.totalCustomsValue}}" readonly>
        <label *ngIf="fileType === 'imports' || fileType === 'exbond'"> Customs Value</label>
        <label *ngIf="fileType === 'exports'"> Export Value (FOB)</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports' || fileType === 'exbond'">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.totalCustomsDuty}}" readonly>
        <label> Customs Duty</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports' || fileType === 'exbond'">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.totalCustomsVAT}}" readonly>
        <label>Customs VAT</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports' || fileType === 'exbond'">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.totalAmountDue}}" readonly>
        <label>Amount Due</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'exports'">
      <p-autoComplete [ngModel]="selectedBillOfEntry.receivingBank"
                      placeholder="Receiving Bank" field="label"
                      [autoHighlight]="true" [dropdown]="true"
                      [suggestions]="filteredReceivingBanks"
                      (completeMethod)="filterReceivingBanks($event)"
                      (onSelect)=selectReceivingBank($event)>
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>

    </div>
  </div>
</div>
