import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';

import { Address, BusinessEntity } from '../models/business-entity';
import { RoleMetaData } from '../models/role-metadata.';

@Component({
  selector: 'digi-business-entity-address-search',
  templateUrl: './business-entity-address-search.component.html',
  styleUrls: ['./business-entity-address-search.component.scss']
})
export class BusinessEntityAddressSearchComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() address: Address;
  @Output() onSelect = new EventEmitter;
  filteredAddress = [];
  selectedAddress: Address;

  constructor() {
  }

  ngOnInit() {
    this.selectedAddress = this.address;
  }

  ngOnChanges() {
    this.selectedAddress = this.address;
  }

  selectedAddressFromDropDown(value) {
    this.onSelect.emit(value);
  }

  searchAddress(event) {
    const query = event.query;
    this.filteredAddress = this.businessEntity.addresses
      .filter(address => address.line1.toLowerCase().indexOf(query.toLowerCase()) === 0);
  }

}
