<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Report" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-9">
              <div class="ui-g-3 ui-md-3">
  <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="importers" [(ngModel)]="importer"
                            class="color-white"
                            field="label"
                            [suggestions]="filteredImporters"
                            (completeMethod)="searchImporter($event)"
                            [autoHighlight]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Importer">
              <ng-template let-value pTemplate="selectedItem">
          <span style="font-size:14px">{{value.code}}</span>
        </ng-template>
            </p-autoComplete>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
              </div>
              <div class="ui-g-3 ui-md-3">
  <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="customsOffice" [(ngModel)]="customsOffice"
                            class="color-white"
                            field="label"
                            [suggestions]="filteredCustomsOffices"
                            (completeMethod)="searchCustomsOffice($event)"
                            (onSelect)="selectCustomsOffice($event)"
                            [autoHighlight]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Customs Office">
              <ng-template let-value pTemplate="selectedItem">
          <div>{{value.districtOfficeCode}}: {{value.districtOfficeName}}</div>
        </ng-template>
            </p-autoComplete>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
              </div>
              <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="fromDate"
                                             placeholder="From"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
              <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="toDate"
                                             placeholder="To"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <div class="ui-md-12 ui-g-12">
                <button pButton type="button" label="Generate"
                        [disabled]="disableButton"
                        (click)="generate()"></button>
                <button *ngIf="pdfSrc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
              </div>
            </div>
            <div class="ui-toolbar-group-left ui-g-9">
                <div class="ui-g-3 ui-md-3">
                  <span class="md-inputfield">
                    <span class="ui-inputgroup">
                        <input type="text"
                               style="color:white;"
                               [(ngModel)]="sarsPrn"
                               placeholder="PRN"
                               pInputText>
                    </span>
                  </span>
                </div>
                <div class="ui-g-3 ui-md-3">
                    <span class="md-inputfield">
                      <span class="ui-inputgroup">
                                <span class="ui-float-label">
                                    <p-inputMask class="color-white" id="receiptDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                                 [(ngModel)]="receiptDate"
                                                 placeholder="Receipt Date"
                                    >
                                    </p-inputMask>
                                </span>
                      </span>
                    </span>
                </div>
              <div class="ui-g-3 ui-md-3">
                  <span class="md-inputfield">
                    <span class="ui-inputgroup">
                        <input type="text"
                               style="color:white;"
                               [(ngModel)]="receiptRef"
                               placeholder="Receipt Ref"
                               pInputText>
                    </span>
                  </span>
              </div>
              </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="card card-w-title">
            <div class="ui-g">
              <pdf-viewer class="ui-g-12 ui-md-12"
                          *ngIf="pdfSrc"
                          [src]="pdfSrc"
                          [render-text]="true"
                          [original-size]="true"
                          [show-all]="true"
                          [stick-to-page]="true"
                          (after-load-complete)="disableButton=false"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Bills Of Entry">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-9">
              <div class="ui-g-3 ui-md-3">
                <span class="md-inputfield">
                  <span class="ui-inputgroup">
                    <span class="ui-float-label ui-fluid">
                          <p-autoComplete [(ngModel)]="importer"
                                          class="color-white"
                                          field="label"
                                          [suggestions]="filteredImporters"
                                          (completeMethod)="searchImporter($event)"
                                          [autoHighlight]="true"
                                          [inputStyle]="{color:'white'}"
                                          placeholder="Importer">
                            <ng-template let-value pTemplate="selectedItem">
                        <span style="font-size:14px">{{value.code}}</span>
                      </ng-template>
                          </p-autoComplete>
                    </span>
                    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </span>
                </span>
              </div>
              <div class="ui-g-3 ui-md-3">
                <span class="md-inputfield">
                  <span class="ui-inputgroup">
                    <span class="ui-float-label ui-fluid">
                          <p-autoComplete [(ngModel)]="customsOffice"
                                          class="color-white"
                                          field="label"
                                          [suggestions]="filteredCustomsOffices"
                                          (completeMethod)="searchCustomsOffice($event)"
                                          (onSelect)="selectCustomsOffice($event)"
                                          [autoHighlight]="true"
                                          [inputStyle]="{color:'white'}"
                                          placeholder="Customs Office">
                            <ng-template let-value pTemplate="selectedItem">
                        <div>{{value.districtOfficeCode}}: {{value.districtOfficeName}}</div>
                      </ng-template>
                          </p-autoComplete>
                    </span>
                    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </span>
                </span>
              </div>
              <div class="ui-g-2 ui-md-2">
                <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="fromDate"
                                             placeholder="From"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
              <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="toDate"
                                             placeholder="To"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <div class="ui-md-12 ui-g-12">
                <button pButton type="button" label="Load BOEs"
                        (click)="loadBoes()"></button>
                <button *ngIf="boes&&boes.length" pButton type="button" label="Save BOEs" (click)="saveBoes()"></button>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>

        </div>
        <div class="ui-g-12 ui-md-12 ui-fluid">
          <p-table [columns]="cols"  [value]="boes"
                   [paginator]="true" [rows]="10"
                   selectionMode="single">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width"/>
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{ col.header }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td>{{ rowData?.lrn }}</td>
                <td>{{ rowData?.mrn }}</td>
                <td>{{ rowData?.totalCustomsVAT }}</td>
                <td>{{ rowData?.prn }}</td>
                <td style="width: 100%"><input pInputText [(ngModel)]="rowData.receiptReference"></td>
                <td style="width: 100%">
                  <p-inputMask id="receiptDate"
                               mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="rowData.receiptDate">
                  </p-inputMask>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Email Report">
      <ng-template pTemplate="content">
        <div class="ui-fluid">
          <div class="ui-g">
            <digi-customs-documents-email [generatedReportFiles]="generatedFiles" [reportsPdfSrc]="pdfSrc" [importerDefaultEmail]="importer?.reportsDefaultEmail"></digi-customs-documents-email>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
