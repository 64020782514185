import { Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ForwardingFileBehaviourSubject } from '../../subjects/forwarding-file-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../subjects/forwarding-file-behaviour-subject";
import * as i2 from "@angular/router";
var ForwardingFileExistsGuard = /** @class */ (function () {
    function ForwardingFileExistsGuard(forwardingFileBehaviourSubject, router) {
        this.forwardingFileBehaviourSubject = forwardingFileBehaviourSubject;
        this.router = router;
    }
    ForwardingFileExistsGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.forwardingFileBehaviourSubject.getForwardingFile().pipe(take(1), switchMap(function (file) {
            if (file.id) {
                return of(true);
            }
            else {
                var parent_1 = state.root.firstChild.routeConfig.path;
                _this.router.navigate([parent_1, 'manifests']);
                return of(false);
            }
        }));
    };
    ForwardingFileExistsGuard.ngInjectableDef = i0.defineInjectable({ factory: function ForwardingFileExistsGuard_Factory() { return new ForwardingFileExistsGuard(i0.inject(i1.ForwardingFileBehaviourSubject), i0.inject(i2.Router)); }, token: ForwardingFileExistsGuard, providedIn: "root" });
    return ForwardingFileExistsGuard;
}());
export { ForwardingFileExistsGuard };
