import {Inject, Injectable} from '@angular/core';
import {CrewMember} from '../models/file';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';
import {ValidationResult} from './validation.service';

@Injectable({
  providedIn: 'root'
})
export class CrewMemberService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  saveCrewMember(crewMember: CrewMember) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (crewMember._links && crewMember._links.self) {
      return this.http.put(crewMember._links.self.href, JSON.stringify(crewMember), {headers});
    } else {
      return this.http.post(`${this.config.filesApiEndpoint}api/crewMembers`, JSON.stringify(crewMember), {headers});
    }
  }

  findBySurnameOrFirstNamesStartsWith(nameOrSurname) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/crewMembers/search/nameOrSurnameStartsWith?nameOrSurname=${nameOrSurname}`)
      .pipe(
        map(response => response['_embedded'].crewMembers)
      );
  }

  findAll() {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/crewMembers`)
      .pipe(
        map(response => response['_embedded'].crewMembers)
      );
  }

  deleteCrewMember(crewMember: CrewMember) {
    return this.http.delete(`${this.config.filesApiEndpoint}api/crewMembers/${crewMember.id}`);
  }

  isValidCrewMember(crewMember: CrewMember, validationResult: ValidationResult) {
    if (!crewMember.firstNames) {
      validationResult.messages.push('Please Enter First Name(s)');
    }
    if (!crewMember.surname) {
      validationResult.messages.push('Please Enter Surname');
    }
    if (!crewMember.gender) {
      validationResult.messages.push('Please Enter Gender');
    }
    if (!crewMember.occupation) {
      validationResult.messages.push('Please Enter Occupation');
    }
    if (!crewMember.nationality) {
      validationResult.messages.push('Please Enter Nationality');
    }
    if (!crewMember.countryOfResidence) {
      validationResult.messages.push('Please Enter Country of Residence');
    }
    if (!crewMember.foreign && !crewMember.idNumber) {
      validationResult.messages.push('Please Enter ID Number');
    }
    if (!crewMember.foreign && crewMember.idNumber && crewMember.idNumber.length !== 13) {
      validationResult.messages.push('Please Enter A Valid ID Number');
    }
    if (!crewMember.dateOfBirth) {
      validationResult.messages.push('Please Enter Date of Birth');
    }
    if (!crewMember.travelerType) {
      validationResult.messages.push('Please Enter Traveler Type');
    }
    if (!crewMember.reasonForMovement) {
      validationResult.messages.push('Please Enter Reason For Movement');
    }
    if (!crewMember.travelDocType) {
      validationResult.messages.push('Please Enter Travel Document Type');
    }
    if (!crewMember.passportNumber) {
      validationResult.messages.push('Please Enter Passport Number');
    }
    if (!crewMember.travelDocExpiryDate) {
      validationResult.messages.push('Please Enter Travel Document Expiry Date');
    }
    if (!crewMember.travelDocCountryOfIssue) {
      validationResult.messages.push('Please Enter Travel Document Country of Issue');
    }

    if (validationResult.messages.length > 0) {
      validationResult.isValid = false;
    }
    return validationResult;
  }
}
