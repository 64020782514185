import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { Correction } from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class CorrectionService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  sendCorrection(correction: Correction): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (correction._links && correction._links.self) {
      return this.http.put(correction._links.self.href, JSON.stringify(correction), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/corrections', JSON.stringify(correction), {headers});
    }
  }
}
