import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';
import {ShareDataService} from '../app/digi-common/services/share-data.service';
import {ForwardingFile} from '../app/digi-common/models/ForwardingFile';

@Injectable({
  providedIn: 'root'
})
export class ForwardingFileBehaviourSubject {
  private forwardingFileSubject = new BehaviorSubject<ForwardingFile>(this.shareDataService.buildForwardingFile());
  private debtorsSubject = new Subject<Debtor[]>();

  constructor(private shareDataService: ShareDataService) {
  }

  addForwardingFile(forwardingFile: ForwardingFile) {
    this.forwardingFileSubject.next(forwardingFile);
  }

  getForwardingFile(): Observable<ForwardingFile> {
    return this.forwardingFileSubject.asObservable();
  }
}
