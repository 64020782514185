import { Component, OnInit } from '@angular/core';
import { TariffCodeBehaviorSubject } from '../../../../subjects/tariffCode-behavior-subject';
import { TariffCodeReference } from '../../models/TariffCode';

@Component({
  selector: 'digi-selected-tariff-section',
  templateUrl: './selected-tariff-section.component.html',
  styleUrls: ['./selected-tariff-section.component.scss']
})
export class SelectedTariffSectionComponent implements OnInit {
  selectedTariffCode: TariffCodeReference;

  constructor(private tariffCodeBehaviorSubject: TariffCodeBehaviorSubject) {
  }

  ngOnInit() {
    this.tariffCodeBehaviorSubject
      .getTariffCode()
      .subscribe(tariff => this.selectedTariffCode = tariff);
  }

}
