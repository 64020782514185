/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i2 from "primeng/components/progressspinner/progressspinner";
import * as i3 from "@angular/common";
import * as i4 from "./loading.component";
import * as i5 from "../loading.service";
var styles_LoadingComponent = ["   .loading {\n    position: fixed;\n    z-index: 99999;\n    height: 50px;\n    width: 100%;\n    overflow: visible;\n    margin: auto;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n\n   .ui-progress-spinner {\n    width: 50px;\n    height: 50px;\n    z-index: 99999;\n  }\n  }\n  \n  .loading:before {\n    z-index: 99998;\n    content: '';\n    display: block;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0,0,0,0.7);\n  }\n    .align-center {\n      text-align: center;\n    }\n\n  "];
var RenderType_LoadingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
function View_LoadingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "p-grid p-justify-center ui-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "p-col-12 align-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p-progressSpinner", [["animationDuration", ".5s"], ["strokeWidth", "6"]], null, null, null, i1.View_ProgressSpinner_0, i1.RenderType_ProgressSpinner)), i0.ɵdid(5, 49152, null, 0, i2.ProgressSpinner, [], { strokeWidth: [0, "strokeWidth"], animationDuration: [1, "animationDuration"] }, null)], function (_ck, _v) { var currVal_0 = "6"; var currVal_1 = ".5s"; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i0.ɵdid(1, 245760, null, 0, i4.LoadingComponent, [i5.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingComponentNgFactory = i0.ɵccf("digi-loading", i4.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
