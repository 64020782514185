<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Create Part" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-fluid ui-g-8">
              <div class="ui-g-4 ui-md-4">
                <div class="ui-inputgroup">
                  <p-autoComplete id="part" [(ngModel)]="part" field="partNumber"
                                  [suggestions]="filteredParts"
                                  (completeMethod)="searchParts($event)"
                                  (onSelect)="selectPart($event)"
                                  [autoHighlight]="true"
                                  minLength="1"
                                  [inputStyle]="{color:'white'}"
                                  placeholder="Part Number">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.partNumber}}</div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
              <div class="ui-g-4 ui-md-4">
                <div class="ui-inputgroup">
                  <p-autoComplete id="partDesc" [(ngModel)]="part" field="partDescription"
                                  [suggestions]="filteredParts"
                                  (completeMethod)="searchPartsByDescription($event)"
                                  (onSelect)="selectPart($event)"
                                  [autoHighlight]="true"
                                  minLength="1"
                                  [inputStyle]="{color:'white'}"
                                  placeholder="Part Description">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewPart()"
                      class="ui-button-secondary" [disabled]="disableButton"></button>
              <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="disableButton"
                      (click)="savePart()"></button>
            </div>
          </p-toolbar>
          <digi-part [part]="part" [fileType]="importExportIndicator==='I'?'imports':'exports'" [businessEntity]="part.businessEntity"></digi-part>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Upload Parts">
      <ng-template pTemplate="content">
        <div *ngIf="disableButton" class="ui-g-12">
          <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
        </div>
        <div class="ui-g-12">
          <p-fileUpload (onSelect)="selectFiles($event)"
                        (uploadHandler)="uploadPartDetails()"
                        [customUpload]="true"
                        accept=".tsv,.txt">
          </p-fileUpload>
        </div>
        <div class="ui-g-4">
          <p-panel [header]="importExportIndicator === 'I' ? 'Importer' : 'Exporter'">
            <div class="ui-fluid">
              <div class="ui-g">
                <div class="ui-g-12">
                  <digi-business-entity-search [businessEntity]="part.businessEntity"
                                               [showAddress]="false"
                                               [roleName]="importExportIndicator === 'I' ? 'Importer':'Exporter'"
                                               [roleID]="importExportIndicator === 'I' ? 6 : 12"
                                               (selectedBusinessEntity)="selectBusinessEntity($event)">
                  </digi-business-entity-search>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-12">
          <p-table [columns]="cols" [value]="partDetails" dataKey="partNumber"
                   selectionMode="single"
                   [paginator]="true" [rows]="10" [responsive]="true" >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                  {{col.header}}
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td>
                  {{rowData.partNumber}}
                </td>
                <td>
                  {{rowData.description}}
                </td>
                <td>
                  <input [(ngModel)]="rowData.tariffCode" type="text" pInputText/>
                </td>
                <td>
                  <button pButton type="button" icon="fa fa-times" class="danger"
                          (click)="deletePartDetail(rowIndex)" pTooltip="Delete"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
