import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BoeAddInfoType } from '../app/digi-common/models/billOfEntries';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInfoBehaviourSubject {

  private scheduleBehaviourSubject = new BehaviorSubject<BoeAddInfoType[]>([]);

  constructor() {
  }

  addAdditionalInfo(BOELines: BoeAddInfoType[]) {
    this.scheduleBehaviourSubject.next(BOELines);
  }

  getAdditionalInfo(): Observable<BoeAddInfoType[]> {
    return this.scheduleBehaviourSubject.asObservable();
  }
}
