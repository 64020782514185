<div class="form-group">
<div class="ui-g-12">
  <p-panel header="Voucher Of Corrections">
    <div class="ui-g ui-fluid">
      <div class="ui-g-3">
        <div class="ui-g-12 ui-md-12"><p-checkbox binary="true" label="Bill of Entry Cancelled?" [(ngModel)]="correction.cancellation"></p-checkbox></div>
        <div class="ui-g-12 ui-md-12" *ngIf="fileType==='imports'"><p-checkbox binary="true" label="Calculate VAT" [(ngModel)]="correction.claimVat"></p-checkbox></div>
        <div class="ui-g-12 ui-md-12" *ngIf="fileType==='imports'"><p-checkbox binary="true" label="Liquidation Of Provisional Payment" [(ngModel)]="correction.liquidationOfProvisionalPayment"></p-checkbox></div>
      </div>
      <div class="ui-g-5">
        <div class="ui-md-12 ui-g-nopad">
          <p-panel header="Correction Reason">
            <textarea pInputTextarea [rows]="2" [cols]="50" placeholder="Reason"
              [(ngModel)]="correction.reasons" style="resize: none"></textarea>
            <div *ngIf="!correction.reasons" [ngStyle]="{'font-size': '10px', 'color': 'red'}">
              This field is required
            </div>
          </p-panel>
        </div>
      </div>
      <div class="ui-g-4">
        <div class="ui-g-12 ui-g-nopad">
          <p-panel header="Assessment Date">
            <div class="ui-g">
              <div class="ui-g-12 ui-g-nopad">
                <div class="ui-inputgroup ui-g-12">
                  <div class="ui-g-12 ui-md-12">
                    <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" [(ngModel)]="correction.assessmentDate"
                      dataType="string" placeholder="Assessment Date" readonly></p-inputMask>
                  </div>
                </div>
              </div>
            </div>
            </p-panel>
          </div>
        </div>
      </div>
    </p-panel>
  </div>

