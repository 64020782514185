/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boe-lines-tab-tariff-description-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "@angular/forms";
import * as i4 from "./boe-lines-tab-tariff-description-section.component";
var styles_BoeLinesTabTariffDescriptionSectionComponent = [i0.styles];
var RenderType_BoeLinesTabTariffDescriptionSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoeLinesTabTariffDescriptionSectionComponent, data: {} });
export { RenderType_BoeLinesTabTariffDescriptionSectionComponent as RenderType_BoeLinesTabTariffDescriptionSectionComponent };
export function View_BoeLinesTabTariffDescriptionSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-g ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "ui-g-12 ui-g-nopad pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "ui-inputgroup-addon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Description "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.selectedBillOfEntryLine == null) ? null : ((_co.selectedBillOfEntryLine.tariffCode == null) ? null : _co.selectedBillOfEntryLine.tariffCode.description)), ""); var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 6).filled; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_BoeLinesTabTariffDescriptionSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-boe-lines-tab-tariff-description-section", [], null, null, null, View_BoeLinesTabTariffDescriptionSectionComponent_0, RenderType_BoeLinesTabTariffDescriptionSectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.BoeLinesTabTariffDescriptionSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoeLinesTabTariffDescriptionSectionComponentNgFactory = i1.ɵccf("digi-boe-lines-tab-tariff-description-section", i4.BoeLinesTabTariffDescriptionSectionComponent, View_BoeLinesTabTariffDescriptionSectionComponent_Host_0, { selectedBillOfEntryLine: "selectedBillOfEntryLine" }, {}, []);
export { BoeLinesTabTariffDescriptionSectionComponentNgFactory as BoeLinesTabTariffDescriptionSectionComponentNgFactory };
