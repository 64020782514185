import { PartDetail } from '../models/partDetail';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var CsvUploadService = /** @class */ (function () {
    function CsvUploadService() {
    }
    CsvUploadService.prototype.createPartDetails = function (file, objectType, importExportIndicator, businessEntity) {
        return new Observable(function (subscriber) {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function () {
                var csvData = reader.result;
                var headerRow = csvData.split(/\r?\n|\r|\n/g)[0];
                var headerCols = headerRow.split('\t');
                var partNumberIndex = headerCols.map(function (el) { return el.toLowerCase(); }).indexOf('part number');
                var tariffCodeIndex = headerCols.map(function (el) { return el.toLowerCase(); }).indexOf('tariff code');
                var descriptionIndex = headerCols.map(function (el) { return el.toLowerCase(); }).indexOf('description');
                var invoiceValueIndex = headerCols.map(function (el) { return el.toLowerCase(); }).indexOf('invoice value');
                var weightIndex = headerCols.map(function (el) { return el.toLowerCase(); }).indexOf('line weight');
                var dataRows = csvData.split(/\r?\n|\r|\n/g);
                dataRows.splice(0, 1);
                var parts = [];
                dataRows.forEach(function (el) {
                    var isEmptyRow = !el || 0 === el.length;
                    if (!isEmptyRow) {
                        var data = el.split('\t');
                        var part = new PartDetail();
                        if (businessEntity && businessEntity.code) {
                            part.businessEntity = { name: businessEntity.name, code: businessEntity.code };
                        }
                        part.importExportIndicator = importExportIndicator;
                        if (partNumberIndex !== -1) {
                            part.partNumber = data[partNumberIndex];
                        }
                        if (descriptionIndex !== -1) {
                            part.description = data[descriptionIndex];
                        }
                        if (tariffCodeIndex) {
                            var formattedTariffWithCheckDigit = data[tariffCodeIndex].replace(/\./g, '');
                            part.tariffCode = formattedTariffWithCheckDigit.substring(0, formattedTariffWithCheckDigit.indexOf('/')).trim();
                            if (part.tariffCode.length % 2 !== 0) {
                                part.tariffCode = '0' + part.tariffCode;
                            }
                        }
                        if (objectType === 'invoiceLine') {
                            if (invoiceValueIndex !== -1) {
                                part.invoiceValue = data[invoiceValueIndex];
                            }
                            if (weightIndex !== -1) {
                                part.weight = data[weightIndex];
                            }
                        }
                        if (part.partNumber && part.partNumber !== '') {
                            parts.push(part);
                        }
                    }
                });
                subscriber.next(parts);
            };
        });
    };
    CsvUploadService.ngInjectableDef = i0.defineInjectable({ factory: function CsvUploadService_Factory() { return new CsvUploadService(); }, token: CsvUploadService, providedIn: "root" });
    return CsvUploadService;
}());
export { CsvUploadService };
