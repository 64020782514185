<p-dialog header="AWB Reservation"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1300"
          [minWidth]="200"
          [minY]="100"
          [baseZIndex]="10000"
          (onHide)="hide()">

  <div class="ui-g-12">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-g-4">
        <p-panel header="MAWB Reservation Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-10 ui-md-10">
                    <span class="md-inputfield">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" [ngModel]="airline.label" disabled
                                 pInputText class="ui-inputgroup">
                          <label>Transport Line</label>
                        </span>
                      </div>
                    </span>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    ({{airline.prefix}})
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" [ngModel]="mawbNo"
                                 pInputText class="ui-inputgroup" disabled>
                          <label>AWB No</label>
                        </span>
                      </div>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="mawbDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [ngModel]="poolDate" disabled>

                  </p-inputMask>
                     <label for="mawbDate">Pool Date</label>
                </span>
              </span>
            </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="exporter"
                            [(ngModel)]="reservation.exporter"
                            field="label"
                            [suggestions]="filteredExporters"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            minLength="3"
                            required=true
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}: {{option.name}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="exporter">Exporter</label>
            </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Additional Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" [(ngModel)]="reservation.weight"
                                 pInputText class="ui-inputgroup">
                          <label>Booked Weight (kg)</label>
                        </span>
                      </div>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="commodity"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="reservation.commodity"
                                                  field="label"
                                                  [suggestions]="filteredCommodities"
                                                  (completeMethod)="searchCommodity($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  [dropdown]="true"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="commodity">Commodity</label>
                                </span>
                              </div>
                            </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                            <textarea pInputTextarea [(ngModel)]="reservation.description" [rows]="3" [cols]="50"
                                      placeholder="Description"
                                      style="resize: none">
                            </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-12">
        <div class="ui-g-4">
          <p-panel header="Routing Details 1">
            <div class="ui-fluid">
              <div class="ui-g">
                <div class="ui-g-12">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                      <digi-port-details portLabel='Airport'
                                         [transportDetails]="reservation.routeDetails1.departurePort"
                                         [transportMethodCode]="'AIR'"
                                         [isRequired]="false"
                      ></digi-port-details>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="reservation.routeDetails1.departurePort.countryName" disabled>
                                  <label>Country</label>
                                </span>
                              </div>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="departureDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="reservation.routeDetails1.flightDate">
                                  </p-inputMask>
                                  <label for="departureDate">Departure Date</label>
                                </span>
                              </span>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="reservation.routeDetails1.flightNo">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-4">
          <p-panel header="Routing Details 2">
            <div class="ui-fluid">
              <div class="ui-g">
                <div class="ui-g-12">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                      <digi-port-details portLabel='Airport'
                                         [transportDetails]="reservation.routeDetails2.departurePort"
                                         [transportMethodCode]="'AIR'"
                                         [isRequired]="false"
                      ></digi-port-details>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="routeAirline"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="reservation.routeDetails2.airline"
                                                  field="label"
                                                  [suggestions]="filteredAirlines"
                                                  (completeMethod)="searchAirline($event)"
                                                  (onSelect)="selectRouteDetail2Airline($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.code}}: {{option.name}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="routeAirline">Transport Line</label>
                                </span>
                                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                              </div>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="reservation.routeDetails2.flightDate">
                                  </p-inputMask>
                                  <label>Departure Date</label>
                                </span>
                              </span>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="reservation.routeDetails2.flightNo">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-4">
          <p-panel header="Routing Details 3">
            <div class="ui-fluid">
              <div class="ui-g">
                <div class="ui-g-12">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                      <digi-port-details portLabel='Airport'
                                         [transportDetails]="reservation.routeDetails3.departurePort"
                                         [transportMethodCode]="'AIR'"
                                         [isRequired]="false"
                      ></digi-port-details>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="route3Airline"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="reservation.routeDetails3.airline"
                                                  field="label"
                                                  [suggestions]="filteredAirlines"
                                                  (completeMethod)="searchAirline($event)"
                                                  (onSelect)="selectRouteDetail3Airline($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.code}}: {{option.name}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="route3Airline">Transport Line</label>
                                </span>
                                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                              </div>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="reservation.routeDetails3.flightDate">
                                  </p-inputMask>
                                  <label>Departure Date</label>
                                </span>
                              </span>
                            </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="reservation.routeDetails3.flightNo">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="hide()" label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
