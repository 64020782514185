import {Component, OnInit} from '@angular/core';
import {Country, CrewMember} from '../../models/file';
import {CrewMemberService} from '../../services/crew-member.service';
import {NotificationService} from '../../services/notification.service';
import {Gender, Occupation, ReasonForMovement, TravelDocumentType, TravelerType} from '../../models/enumerations';
import {ValidationResult} from '../../services/validation.service';

@Component({
  selector: 'digi-crew-maintenance',
  templateUrl: './crew-maintenance.component.html',
  styleUrls: ['./crew-maintenance.component.scss']
})
export class CrewMaintenanceComponent implements OnInit {
  selectedCrewMember: CrewMember = new CrewMember();
  filteredCrew: CrewMember[];

  constructor(
    private crewMemberService: CrewMemberService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
  }

  createCrewMember() {
    this.selectedCrewMember = new CrewMember();
  }

  saveCrewMember() {
    if (this.isValidCrewMember()) {
      this.crewMemberService.saveCrewMember(this.selectedCrewMember).subscribe(
        (crewMember: CrewMember) => {
          this.selectedCrewMember = crewMember;
          this.notificationService.successNotify('Crew Member Saved Successfully');
        }
      );
    }
  }

  isValidCrewMember() {
    const validationResult: ValidationResult = {isValid: true, messages: []};
    if (!this.crewMemberService.isValidCrewMember(this.selectedCrewMember, validationResult).isValid) {
      this.addFailure(validationResult.messages);
    }

    return validationResult.isValid;
  }

  addFailure(msg: any) {
    this.notificationService.errorNotify(
      'Warning',
      msg,
    );
  }

  deleteCrewMember() {
    this.crewMemberService.deleteCrewMember(this.selectedCrewMember).subscribe(
      _ => {
        this.notificationService.successNotify('Crew Member Removed Successfully');
        this.selectedCrewMember = new CrewMember();
      }
    );
  }

  searchCrew(event) {
    this.crewMemberService.findBySurnameOrFirstNamesStartsWith(event.query).subscribe(
      (crew: CrewMember[]) => this.filteredCrew = crew
    );
  }
}
