<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12 ui-md-12">
      <p-toolbar>
        <div class="ui-g-12 ui-md-3">
          <digi-exbond-file-search></digi-exbond-file-search>
        </div>
      </p-toolbar>
    </div>
    <div class="ui-g-12">
      <p-fileUpload name="file" [url]="billOfEntry._links.files.href"
                    (onUpload)="afterFileUpload()"
                    (onError)="onFileUploadError($event)"
                    (onProgress)="beforeFileUpload()"
                    multiple="multiple">
        <ng-template pTemplate="content">
          <p-table [value]="listOfFiles" [(selection)]="selectedFiles"
                   selectionMode="multiple">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th colspan="4">
                  File Name
                </th>
                <th colspan="3">
                  Date
                </th>
                <th colspan="3">
                  Size
                </th>
                <th colspan="3">
                  Status
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <td>
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td colspan="3">
                  {{rowData.fileName}}
                </td>
                <td colspan="3">
                  {{rowData.date}}
                </td>
                <td colspan="3">
                  {{rowData.size}} KB
                </td>
                <td colspan="3">
                  File Uploaded Successfully
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="10">
                  <label>No Files Found</label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>
</div>
