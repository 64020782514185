import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Part} from '../../models/part';
import {BusinessEntity, TariffCode, TariffFormula} from '../../models/file';
import {ReferenceCategoryService} from '../../services/reference-category.service';
import {IndustryService} from '../../services/industry.service';
import {TariffService} from '../../services/tariff.service';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-part',
  templateUrl: './part.component.html',
  styleUrls: ['./part.component.scss']
})
export class PartComponent implements OnChanges {

  @Input() part = new Part();
  @Input() updatePart = false;
  @Input() businessEntity = new BusinessEntity();
  @Input() fileType: FileType;
  tariffCode = new TariffCode();
  filteredReferenceCategories: any[];
  filteredIndustries: any[];
  filteredTariffCodes: any[];
  constructor(
    private referenceCategoryService: ReferenceCategoryService,
    private industryService: IndustryService,
    private tariffService: TariffService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.part && changes.part.currentValue) {
      this.part = changes.part.currentValue;
      if (this.part.businessEntity) {
        this.businessEntity = Object.assign(this.part.businessEntity);
      }
      this.tariffService
        .findTariffByCodeStartsWith(this.part.tariffCode)
        .subscribe(data => {
          this.selectTariffCode(data[0]);
        });
    }

    if (changes.businessEntity && changes.businessEntity.currentValue) {
      this.businessEntity = changes.businessEntity.currentValue;
    }
  }

  selectReferenceCategory(value: any) {
    this.part.referenceCategory = value;
  }

  searchReferenceCategory(event: any) {
    this.referenceCategoryService.findByCode(event.query).subscribe(data => this.filteredReferenceCategories = data);
  }

  searchIndustry(event: any) {
    this.industryService.findByCode(event.query).subscribe(data => this.filteredIndustries = data);
  }

  selectIndustry(value: any) {
    this.part.industry = value;
  }

  selectBusinessEntity(value) {
    this.businessEntity = value;
    if (value) {
      this.part.businessEntity = new BusinessEntity();
      this.part.businessEntity.code = value.code;
      this.part.businessEntity.name = value.name;
      this.part.businessEntity.label = value.label;
    }
  }

  searchTariffCode(event: any) {
    this.tariffService
      .findTariffByCodeStartsWith(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value: any) {
    if (value) {
      this.part.tariffCode = value.code;
      this.tariffCode = Object.assign({}, value);

      const formula = value.formulae.find(f => {
        return !!f.uomCode;
      });

      if (formula) {
        this.tariffCode.formula = new TariffFormula();
        this.tariffCode.formula.statisticalUOM = formula.uomCode;
      }
    } else {
      this.tariffCode = new TariffCode();
    }
  }

}
