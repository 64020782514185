import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CurrencyService = /** @class */ (function () {
    function CurrencyService(http, config) {
        this.http = http;
        this.config = config;
    }
    CurrencyService.prototype.getCurrency = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/currencies/" + code);
    };
    CurrencyService.prototype.findCurrencyStartWith = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/currencies/search/codeStartsWithNonPageable?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].currencies; }));
    };
    CurrencyService.prototype.getEffectiveCurrencyAndType = function (code, effectiveDate) {
        return this.http.get(this.config.apiEndpoint + "api/exchangeRates/search/effectiveAtForCurrencyAndType?code=" + code + '&type=cus&' +
            'effectiveDate=' + effectiveDate);
    };
    CurrencyService.prototype.getTypeAndEffective = function (type, effectiveDate) {
        return this.http.get(this.config.apiEndpoint + "api/exchangeRates/search/effectiveAtForType?type=" + type +
            '&effectiveDate=' + effectiveDate);
    };
    CurrencyService.prototype.getExchangeRateTypes = function () {
        return this.http.get(this.config.apiEndpoint + "api/exchangeRatesTypes");
    };
    CurrencyService.prototype.saveExchangeRate = function (rate) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (rate._links && rate._links.self) {
            return this.http.put(this.config.apiEndpoint + "api/exchangeRates", JSON.stringify(rate), { headers: headers });
        }
        else {
            return this.http.post(this.config.apiEndpoint + "api/exchangeRates", JSON.stringify(rate), { headers: headers });
        }
    };
    CurrencyService.prototype.saveExchangeRateRange = function (selectExchangeRateType, fromDate, toDate) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.apiEndpoint + "api/exchangeRates/" + selectExchangeRateType + '/' + fromDate + '/' + toDate + '/copy', { headers: headers });
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
