import { of } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var InternationalPortService = /** @class */ (function () {
    function InternationalPortService(http, config) {
        this.http = http;
        this.config = config;
    }
    InternationalPortService.prototype.findAllInternationalPortStartWith = function (code) {
        if (code === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/internationalPorts/search/codeOrNameStartsWith?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].internationalPorts; }));
    };
    InternationalPortService.prototype.findAllInternationalPortsWithCodeOrNameStartsWith = function (code) {
        if (code === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/internationalPorts/search/findByCodeOrNameStartsWithIgnoreCase?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].internationalPorts; }));
    };
    InternationalPortService.prototype.findTransportDetailsWithCodeOrNameAndTransportMethodIsAir = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/internationalPorts/search/codeOrNameStartsWithAndTransportMethod?code="
            + searchValue + '&' + 'transportMethod=AIR')
            .pipe(map(function (response) { return response['_embedded'].internationalPorts; }));
    };
    InternationalPortService.prototype.findTransportDetailsWithCodeOrNameAndTransportMethodIsNotAir = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/internationalPorts/search/findByCodeStartsWithIgnoreCaseAndTransportMethodOrTransportMethodIsNull?code="
            + searchValue + '&' + 'transportMethod=null')
            .pipe(map(function (response) { return response['_embedded'].internationalPorts; }));
    };
    InternationalPortService.prototype.findAllInternationalPortsWithCodeOrNameStartsWithAndCountryCode = function (query, country) {
        if (query === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/internationalPorts/search/findByCodeOrNameStartsWithIgnoreCaseAndCountry?code=" + query + ("&countryCode=" + country))
            .pipe(map(function (response) { return response['_embedded'].internationalPorts; }));
    };
    InternationalPortService.ngInjectableDef = i0.defineInjectable({ factory: function InternationalPortService_Factory() { return new InternationalPortService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: InternationalPortService, providedIn: "root" });
    return InternationalPortService;
}());
export { InternationalPortService };
