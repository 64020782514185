import { EMPTY } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Address, Debtor } from '../models/file';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var DebtorService = /** @class */ (function () {
    function DebtorService(http, config) {
        this.http = http;
        this.config = config;
    }
    DebtorService.prototype.findDebtorByCode = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + code + '&roleType=1' + '&projection=flattenedDebtor')
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    DebtorService.prototype.findDebtorFromClearingFileAndDebtors = function (clearingFile, debtors) {
        if (clearingFile.debtor.code) {
            return this.findDebtorFromClearingFile(clearingFile, clearingFile.debtor, false);
        }
        if (debtors && debtors.length === 1) {
            return this.findDebtorFromClearingFile(clearingFile, debtors[0]);
        }
        return EMPTY;
    };
    DebtorService.prototype.findDebtorFromClearingFile = function (clearingFile, debtor, updateDebtor) {
        if (updateDebtor === void 0) { updateDebtor = true; }
        if (clearingFile) {
            return this.findDebtorByCode(debtor.code).pipe(tap(function (debtorData) {
                if (updateDebtor) {
                    clearingFile.debtor = new Debtor();
                    clearingFile.debtor.name = debtor.name;
                    clearingFile.debtor.code = debtor.code;
                    clearingFile.debtor.label = debtor.label;
                }
                if (debtorData && (!clearingFile.debtor.address || !clearingFile.debtor.address.line1)) {
                    var debtorAddresses = debtorData[0].addresses;
                    var debtorAddress = void 0;
                    if (debtorAddresses.length === 1) {
                        debtorAddress = debtorAddresses[0];
                    }
                    else {
                        debtorAddress = debtorAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'INV';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (debtorAddress) {
                        clearingFile.debtor.address = new Address();
                        clearingFile.debtor.address.line1 = debtorAddress.line1;
                        clearingFile.debtor.address.line2 = debtorAddress.line2;
                        clearingFile.debtor.address.line3 = debtorAddress.line3;
                        clearingFile.debtor.address.line4 = debtorAddress.line4;
                        clearingFile.debtor.address.zipCode = debtorAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return EMPTY;
        }
    };
    DebtorService.prototype.findDebtorCurrency = function (debtorCode) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + debtorCode + '&roleType=1')
            .pipe(map(function (response) { return response['_embedded'].businessEntities[0].roles.find(function (role) { return role.roleType === 1; }).currency; }));
    };
    DebtorService.ngInjectableDef = i0.defineInjectable({ factory: function DebtorService_Factory() { return new DebtorService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: DebtorService, providedIn: "root" });
    return DebtorService;
}());
export { DebtorService };
