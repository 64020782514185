import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var PackTypeService = /** @class */ (function () {
    function PackTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    PackTypeService.prototype.findByCodeOrDescription = function (codeOrDesc) {
        return this.http
            .get(this.config.apiEndpoint + "api/packTypes/search/findByCodeOrDescriptionStartsWithIgnoreCase?codeOrDesc=" + codeOrDesc)
            .pipe(map(function (response) { return response['_embedded'].packTypes; }));
    };
    PackTypeService.ngInjectableDef = i0.defineInjectable({ factory: function PackTypeService_Factory() { return new PackTypeService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: PackTypeService, providedIn: "root" });
    return PackTypeService;
}());
export { PackTypeService };
