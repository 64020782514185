import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var EmailService = /** @class */ (function () {
    function EmailService(http, config) {
        this.http = http;
        this.config = config;
    }
    EmailService.prototype.sendMail = function (email, lrn, clearingFileNumber) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + "api/document/email?lrn=" + lrn + "&clearingFileNumber=" + clearingFileNumber, JSON.stringify(email), { headers: headers });
    };
    EmailService.prototype.sendReportMail = function (email, dateRange) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + "api/document/reportEmail?dateRange=" + dateRange, JSON.stringify(email), { headers: headers });
    };
    EmailService.prototype.sendHawbReportMail = function (email, fileNumber) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + "api/document/hawbReportEmail?fileNumber=" + fileNumber, JSON.stringify(email), { headers: headers });
    };
    EmailService.ngInjectableDef = i0.defineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: EmailService, providedIn: "root" });
    return EmailService;
}());
export { EmailService };
