import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var VocReportService = /** @class */ (function () {
    function VocReportService(http, config) {
        this.http = http;
        this.config = config;
    }
    VocReportService.prototype.generateImporterVocReport = function (from, to, importerCode) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/vocImportReports?from=" + from + "&to=" + to + "&importerCode=" + importerCode);
    };
    VocReportService.prototype.generateExporterVocReport = function (from, to, exporterCode) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/vocExportReports?from=" + from + "&to=" + to + "&exporterCode=" + exporterCode);
    };
    VocReportService.ngInjectableDef = i0.defineInjectable({ factory: function VocReportService_Factory() { return new VocReportService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: VocReportService, providedIn: "root" });
    return VocReportService;
}());
export { VocReportService };
