import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {BusinessEntity, Country, Currency, CustomsTraderType, Supplier} from '../../models/business-entity';

@Component({
  selector: 'digi-supplier-role',
  templateUrl: './supplier-role.component.html',
  styleUrls: ['./supplier-role.component.scss']
})
export class SupplierRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() supplier: Supplier;
  constructor() { }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
  }

  ngOnChanges(){
   this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if (!this.supplier.customsCode) {
      this.supplier.customsCode = this.businessEntity.defaultCustomsCode;
    }

    if (!this.supplier.vatRegNo) {
      this.supplier.vatRegNo = this.businessEntity.defaultVatRegNo;
    }

  }

  selectCountry(value: Country){
    this.supplier.country = value;
  }

  selectCurrency(value: Currency){
    this.supplier.currency = value;
  }

  selectTraderType(value: CustomsTraderType){
    this.supplier.registrationType = value;
  }

}
