/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./local-agent-consignee.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "../../../address/address.component.ngfactory";
import * as i6 from "../../../address/address.component";
import * as i7 from "./local-agent-consignee.component";
var styles_LocalAgentConsigneeComponent = [i0.styles];
var RenderType_LocalAgentConsigneeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocalAgentConsigneeComponent, data: {} });
export { RenderType_LocalAgentConsigneeComponent as RenderType_LocalAgentConsigneeComponent };
function View_LocalAgentConsigneeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ui-message ui-messages-error ui-corner-all"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Local Agent not provided "]))], null, null); }
export function View_LocalAgentConsigneeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Local Agent"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocalAgentConsigneeComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "digi-address", [], null, null, null, i5.View_AddressComponent_0, i5.RenderType_AddressComponent)), i1.ɵdid(11, 638976, null, 0, i6.AddressComponent, [], { address: [0, "address"], addresses: [1, "addresses"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_6 = !((_co.clearingInstruction == null) ? null : ((_co.clearingInstruction.consignee == null) ? null : _co.clearingInstruction.consignee.code)); _ck(_v, 9, 0, currVal_6); var currVal_7 = ((_co.clearingInstruction == null) ? null : ((_co.clearingInstruction.consignee == null) ? null : _co.clearingInstruction.consignee.address)); var currVal_8 = _co.localAgentAddresses; _ck(_v, 11, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clearingInstruction.consignee.label; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 5).filled; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_LocalAgentConsigneeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-local-agent-consignee", [], null, null, null, View_LocalAgentConsigneeComponent_0, RenderType_LocalAgentConsigneeComponent)), i1.ɵdid(1, 114688, null, 0, i7.LocalAgentConsigneeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocalAgentConsigneeComponentNgFactory = i1.ɵccf("digi-local-agent-consignee", i7.LocalAgentConsigneeComponent, View_LocalAgentConsigneeComponent_Host_0, { clearingFile: "clearingFile", clearingInstruction: "clearingInstruction" }, {}, []);
export { LocalAgentConsigneeComponentNgFactory as LocalAgentConsigneeComponentNgFactory };
