import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DepotTerminalService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllDepotTerminalStartsWith(searchValue, depotTerminal): Observable<any> {
    return this.http
      .get(`${this.config.apiEndpoint}api/depotTerminals/search/codeStartsWithAndDepotTerminalNonPageable?code=` + searchValue + '&depotTerminal=' + depotTerminal)
      .pipe(
        map(response => response['_embedded'].depotTerminals)
      );
  }

  findAllDepotTerminalsStartsWith(searchValue): Observable<any> {
    return this.http
      .get(`${this.config.apiEndpoint}api/depotTerminals/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].depotTerminals)
      );
  }
}
