import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Guarantor} from '../../models/business-entity';

@Component({
  selector: 'digi-app-guarantor-role',
  templateUrl: './guarantor-role.component.html',
  styleUrls: ['./guarantor-role.component.scss']
})
export class GuarantorRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() guarantor: Guarantor;

  constructor() {
  }

  ngOnInit() {
  }


  selectGuarantor(value: Guarantor) {
    this.guarantor.guaranteeType = value;
  }
}
