import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { BusinessEntity, Role } from 'src/app/digi-business-entity/models/business-entity';
import { RoleMetaData } from '../models/role-metadata.';
import { BusinessEntityService } from '../services/business-entity.service';
import { RoleMetadataService } from '../services/role-metadata.service';
import { CsvUploadService } from '../../digi-common/services/csv-upload.service';
import { NotificationService } from '../../digi-common/services/notification.service';

@Component({
  selector: 'digi-business-entity-layout',
  templateUrl: './business-entity-layout.component.html',
  styles: [`
    .ui-steps .ui-steps-item {
      width: 25%;
    }`],
  encapsulation: ViewEncapsulation.None

})
export class BusinessEntityLayoutComponent implements OnInit {
  disableButton: boolean;
  items: MenuItem[];
  activeIndex: number = 0;
  businessEntityStepActive: boolean;
  supplierDropDown: any[];
  businessEntity = new BusinessEntity();
  filteredBusinessEntities = [];
  rolesMetaData: RoleMetaData[];
  activeRoleType: RoleMetaData;
  activeRole: Role;
  consigneeDetails: any[];
  private file: any;

  constructor(private router: Router,
              private businessEntityService: BusinessEntityService,
              private roleMetaDateService: RoleMetadataService,
              private csvUploadService: CsvUploadService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {

    this.roleMetaDateService.getRoleMetaData()
      .subscribe(data => {
          this.rolesMetaData = data;
        },
        error => {
          console.log(error);
        });

    this.businessEntityStepActive = true;
    this.items = [
      {
        label: 'Business Entity',
        command: (event: any) => {
          this.activeIndex = 0;
          this.businessEntityStepActive = true;
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Role',
        command: (event: any) => {
          if (this.businessEntity.code) {
            this.activeIndex = 1;
            this.businessEntityStepActive = false;
          } else {
            this.activeIndex = 0;
          }
        },
        icon: 'fa fa-user-plus',
      }];

    this.supplierDropDown = [{name: 'Supplier'},
      {name: 'Debtor'},
      {name: 'Creditor'}];
  }


  search(event) {
    this.businessEntityService.findBusinessEntityStartsWith(event.query).subscribe(data => {
      this.filteredBusinessEntities = data;
    });
  }

  selectFiles(event: any) {
      const files = event.originalEvent.srcElement.files;
      this.file = files[0];
    }

  uploadExportConsigneeDetails() {
    this.disableButton = true;
    this.businessEntityService.uploadEntitiesFromSpreadsheet(this.file).subscribe(
        file => {
          this.notificationService.successNotify('Entities Created Successfully');
          this.disableButton = false;
        },
          _ => this.disableButton = false
      );
  }

  onSelectBusinessEntity(businessEntity: BusinessEntity) {
    if(this.businessEntity.code !== businessEntity.code){
      this.activeRoleType = undefined;
      this.activeRole = undefined;
    }
    this.businessEntity = businessEntity;
  }

  onSelectedRoleMetaData(roleMetaData: RoleMetaData) {
    this.activeRoleType = roleMetaData;
  }

  onSelectedRole(role: Role) {
    this.activeRole = role;
  }
}

