import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {Email} from '../../models/email';
import {EmailService} from '../../services/email.service';
import {BillOfEntry, ExportBillOfEntry} from '../../models/billOfEntries';
import {File} from '../../models/file';
import {FileType} from '../../models/enumerations';
import {BusinessEntity} from '../../../digi-business-entity/models/business-entity';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {ForwardingFileBehaviourSubject} from '../../../../subjects/forwarding-file-behaviour-subject';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../services/notification.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-customs-documents-email',
  templateUrl: './customs-documents-email.component.html',
  styleUrls: ['./customs-documents-email.component.scss']
})
export class CustomsDocumentsEmailComponent implements OnInit {
  generatedFiles: any[];
  disableButton: boolean;
  cols: any[];
  attachments: any[];
  email = new Email();
  @Input() fileType: FileType;
  @Input() generatedReportFiles: any[];
  @Input() reportsPdfSrc: string;
  @Input() importerDefaultEmail: string;
  private boe: BillOfEntry;
  file: File;
  private subscription: Subscription;

  constructor(private billOfEntryService: BillOfEntryService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileBehaviorSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviorSubject: ExportFileBehaviourSubject,
              private forwardingFileBehaviorSubject: ForwardingFileBehaviourSubject,
              private emailService: EmailService,
              private authorisationService: AuthorisationService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    if (this.fileType) {
      if (this.fileType === 'forwarding') {
        this.email.subject = 'HAWB Report';
      } else {
        this.email.subject = 'Customs Documents';
      }
    } else {
      this.email.subject = 'VAT Payments Report';
    }
    if (this.importerDefaultEmail) {
      this.email.to = this.importerDefaultEmail;
    }
    this.email.text = 'Please see the attachments';
    this.cols = [
      {
        header: '',
        width: '10%'
      },
      {
        header: 'Filename',
        width: '90%'
      }
    ];
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviorSubject.getClearingFile()
        .subscribe(cf => {
          this.file = cf;
        });
    } else if (this.fileType === 'exports') {
      this.subscription = this.exportFileBehaviorSubject.getExportFile()
        .subscribe(ef => {
          this.file = ef;
        });
    } else if (this.fileType === 'forwarding') {
      this.subscription = this.forwardingFileBehaviorSubject.getForwardingFile()
        .subscribe(ff => {
          this.file = ff;
        });
    }
    this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(boe => {
      if (boe) {
        this.boe = boe;
        this.disableButton = true;
        this.billOfEntryService.loadFiles(boe.id).subscribe(data => {
          this.generatedFiles = data._embedded.supportingDocuments;
          this.disableButton = false;
        }, _ => this.disableButton = false);
      } else if (this.generatedReportFiles) {
        this.generatedFiles = this.generatedReportFiles;
      }
    });
    if (this.fileType) {
      this.authorisationService.getLoggedInUser().subscribe(user => this.email.to = user.email)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.reportsPdfSrc && this.generatedReportFiles) {
      this.generatedFiles = this.generatedReportFiles;
    }
  }

  sendMail() {
    this.disableButton = true;
    this.email.attachments = this.attachments.map(el => el.fileName);
    if (this.reportsPdfSrc) {
      this.emailService.sendReportMail(this.email, this.reportsPdfSrc.match("vatReports/(.*)/VAT")[1]).subscribe(_ => {
        this.notificationService.successNotify('Email Sent.');
          this.disableButton = false;
      },
       _ => {
         this.notificationService.successNotify('Could Not Send Email.');
         this.disableButton = false;
       });
    } else if (this.fileType === 'forwarding') {
      this.emailService.sendHawbReportMail(this.email, this.file? this.file.fileNumber : null).subscribe(_ => {
        this.notificationService.successNotify('Email Sent.');
          this.disableButton = false;
      },
       _ => {
         this.notificationService.successNotify('Could Not Send Email.');
         this.disableButton = false;
       });
    } else {
      this.emailService.sendMail(this.email, this.boe? this.boe.lrn : null, this.file? this.file.clearingFileNumber : null).subscribe(_ => {
        this.notificationService.successNotify('Email Sent.');
          this.disableButton = false;
      },
        _ => {
          this.notificationService.successNotify('Could Not Send Email.');
          this.disableButton = false;
        });
    }
  }
}
