<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <span *ngIf="view!=='containers'" class="md-inputfield">
        <input type="text" pInputText [(ngModel)]="transportDocument.measures.numberOfPackages" [required]="!clearingFile.quotation">
        <label>No. of Packages</label>
        <div *ngIf="!clearingFile.quotation && !transportDocument.measures.numberOfPackages" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
      </span>
      <span *ngIf="view==='containers'" class="md-inputfield">
        <input type="text" pInputText [(ngModel)]="selectedContainer.noOfPackages" required=true>
        <label>No. of Packages</label>
        <div *ngIf="!selectedContainer.noOfPackages" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input *ngIf="view==='containers'" type="text" pInputText [(ngModel)]="selectedContainer.cbm" required=true>
          <input *ngIf="view!=='containers'" type="text" pInputText [(ngModel)]="transportDocument.measures.volume">
          <label>{{label}}</label>
        </span>
      </div>
    </div>
    <div *ngIf="view!=='containers'" class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
          <input type="text" pInputText [(ngModel)]="transportDocument.measures.grossWeight" required=true>
          <label>Gross Weight (kg)</label>
          <div *ngIf="!clearingFile.quotation && !transportDocument.measures.grossWeight" class="ui-message ui-messages-error ui-corner-all">
              This field is required</div>
      </span>
    </div>
    <div *ngIf="view!=='containers'" class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText [(ngModel)]="transportDocument.measures.chargeableWeight">
          <label>Chargeable Weight (kg)</label>
        </span>
      </div>
    </div>
    <div  class="ui-g-12 ui-md-12" *ngIf="view!=='containers' && (isSeaTransportDocument() || isRoadTransportDocument())">
      <span class="md-inputfield">
          <input type="text" pInputText [(ngModel)]="transportDocument.measures.noOfContainers" required="true">
          <label>No. of Containers</label>
          <div *ngIf="!clearingFile.quotation && !transportDocument.measures.noOfContainers" class="ui-message ui-messages-error ui-corner-all">
            This field is required</div>
        </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="view!=='containers' && isRoadTransportDocument()">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" [(ngModel)]="transportDocument.measures.numberOfTrucks" pInputText>
          <label>No of Trucks</label>
        </span>
      </div>
    </div>
  </div>
</div>
