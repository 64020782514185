<div class="ui-g-4 ui-md-4">
  <div class="ui-g-12">
    <p-panel header="Part Details">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="part.partNumber"
                                       pInputText class="ui-inputgroup"
                                       id="partNumber"
                                       [disabled]="updatePart"
                                >
                                <label for="partNumber">Part Number</label>
                              </span>
                            </div>
                          </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="part.description" [rows]="3" [cols]="50"
                                    placeholder="Description"
                                    style="resize: none"
                          >
                          </textarea>
              </div>
              <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                  <p-autoComplete [(ngModel)]="part.referenceCategory" field="label"
                                                  [suggestions]="filteredReferenceCategories"
                                                  (completeMethod)="searchReferenceCategory($event)"
                                                  (onSelect)="selectReferenceCategory($event)"
                                                  [autoHighlight]="true" minLength="1"
                                                  [dropdown]="true"
                                                  [disabled]="updatePart"
                                  >
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                <label>Reference Category</label>
                              </span>
                            </div>
                          </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                  <p-autoComplete [(ngModel)]="part.industry" field="label"
                                                  [suggestions]="filteredIndustries"
                                                  (completeMethod)="searchIndustry($event)"
                                                  (onSelect)="selectIndustry($event)"
                                                  [autoHighlight]="true" minLength="1"
                                                  [dropdown]="true"
                                                  [disabled]="updatePart"
                                  >
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                <label>Industry</label>
                              </span>
                            </div>
                          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
<div class="ui-g-4 ui-md-4">
  <div class="ui-g-12">
    <p-panel header="Tariff Code">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                    <p-autoComplete id="tariff" [(ngModel)]="tariffCode" field="code"
                                                    [suggestions]="filteredTariffCodes"
                                                    (completeMethod)="searchTariffCode($event)"
                                                    [autoHighlight]="true"
                                                    (onSelect)="selectTariffCode($event)"
                                                    minLength="3"
                                                    required="true">
                                      <ng-template let-option pTemplate="item">
                                        <div>{{option.code}}</div>
                                      </ng-template>
                                    </p-autoComplete>
                                    <label for="tariff">Tariff Code</label>
                                </span>
                                <span class="ui-inputgroup-addon">{{tariffCode?.checkDigit}}</span>
                                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                              </div>
                          </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [ngModel]="tariffCode?.tariffSDesc" [rows]="3" [cols]="50"
                                    placeholder="Description"
                                    style="resize: none">
                          </textarea>
              </div>
              <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText [ngModel]="tariffCode?.formula?.statisticalUOM"
                                         readonly>
                                  <label>Statistical UOM</label>
                                </span>
                            </div>
                          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
<div class="ui-g-4">
  <div class="ui-g-12">
    <p-panel [header]="fileType === 'imports' ? 'Importer' : 'Exporter'">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <digi-business-entity-search [businessEntity]="businessEntity"
                                         [showAddress]="false"
                                         [roleName]="fileType === 'imports' ? 'Importer':'Exporter'"
                                         [roleID]="fileType === 'imports' ? 6 : 12"
                                         (selectedBusinessEntity)="selectBusinessEntity($event)">
            </digi-business-entity-search>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
