<div class="ui-g-2 ui-md-2 ui-fluid">
  <p-panel header="Total">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="localAmount"
                                 readonly>
                          <label>Total</label>
                        </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="capturedAmount"
                                 readonly
                          >
                          <label>Captured</label>
                        </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [ngModel]=" getBalance()"
                                 readonly>
                          <label>Balance</label>
                        </span>
        </div>
      </div>
    </div>
  </p-panel>
</div>
