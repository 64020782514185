import {Inject, Injectable} from '@angular/core';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class ExitPortService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  findExitPortByNameOrCode(query: string) {
    if (query === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/exitPorts/search/codeOrNameStartsWith?code=${query}`)
      .pipe(
        map(response => response['_embedded'].exitPorts)
      );
  }
}
