<div class="ui-g-12 ui-md-12">
  <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
      <p-autoComplete id="purchaseTerm"
                      [(ngModel)]="selectedPurchaseTerm" field="label"
                      [suggestions]="filteredPurchaseTerms"
                      (completeMethod)="search($event)"
                      [autoHighlight]="true" (onSelect)="select($event)" minLength="2"
                      [forceSelection]="true"
                      required="true">
        <ng-template let-option pTemplate="item">
          <div>{{option.code}}: {{option.description}}</div>
        </ng-template>
      </p-autoComplete>
            <label for="purchaseTerm">Purchase Term</label>
        </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
     <div *ngIf="!selectedPurchaseTerm?.code" class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
  </span>
</div>
