<p-panel>
  <div class="ui-g">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                           pInputText readonly>
                    <label>Supplier Code</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.name}}"
                           pInputText readonly>
                    <label>Supplier Name</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-6">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Customs Code</label>

                        </span>
                    </div>
      </div>
      <div class="ui-g-12 ui-md-6">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           [(ngModel)]="supplier.rooNumber" pInputText>
                    <label>Roo Number</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <input type="text"
                           [(ngModel)]="businessEntity.defaultVatRegNo" pInputText>
                    <label>VAT Registration No</label>
                </span>
      </div>
      <div class="ui-g-12 ui-md-" style="color: black;">
        <digi-customs-trader-type [customsTraderType]="supplier?.registrationType"
                                  (onSelect)="selectTraderType($event)"></digi-customs-trader-type>
      </div>
      <div class="ui-g-12 ui-md-12">
        <digi-currency [currency]="supplier?.currency" (onSelect)="selectCurrency($event)"></digi-currency>
      </div>
      <div class="ui-g-12 ui-md-12">
        <digi-country-of-origin [country]="supplier?.country"
                                (onSelect)="selectCountry($event)"></digi-country-of-origin>
      </div>
    </div>
  </div>
</p-panel>
