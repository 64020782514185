<p-panel>
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input value="{{businessEntity?.code}}" type="text" pInputText readonly>
            <label>Importer Code</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" value="{{businessEntity?.name}}" pInputText readonly>
            <label>Importer Name</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" [(ngModel)]="importer.defaultCustomsCode" pInputText>
          <label>Customs Code</label>
        </span>
      </div>
    </div>
    <div class="ui-g-6 ui-md-6" style="color: black;">
      <digi-customs-trader-type [customsTraderType]="importer?.registrationType"
                                (onSelect)="selectTraderType($event)"></digi-customs-trader-type>
    </div>
    <div class="ui-g-6 ui-md-6">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" [(ngModel)]="importer.defaultVatRegNo" pInputText>
            <label>VAT Registration No</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <div class="ui-inputgroup">
        <span *ngIf="importer?.registrationType?.id===174" class="md-inputfield">
          <input type="text" [(ngModel)]="importer.idNumber" pInputText>
          <label>ID Number</label>
        </span>
        <span *ngIf="importer?.registrationType?.id===53" class="md-inputfield">
          <input type="text" [(ngModel)]="importer.passportNumber" pInputText>
          <label>Passport Number</label>
        </span>
      </div>
    </div>
    <div class="ui-g-6 ui-md-6">
      <span class="md-inputfield">
        <div class="ui-inputgroup">
          <span class="md-inputfield">
            <input type="text" [(ngModel)]="importer.reportsDefaultEmail"
                   pInputText class="ui-inputgroup"
                   id="email">
            <label for="email">Reports Email</label>
          </span>
        </div>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <digi-country-of-origin [country]="importer?.country" (onSelect)="selectCountry($event)"></digi-country-of-origin>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <p-checkbox [(ngModel)]="importer.costingRequired" label="Costing Required?"
                    binary="true"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-2 ui-md-2">
      <div class="ui-inputgroup">
        <span class="ui-float-label">
          <p-autoComplete id="costingUom"
                          [(ngModel)]="importer.costingUom"
                          field="uomCode"
                          [autoHighlight]="true"
                          [suggestions]="filteredUoms"
                          (completeMethod)="searchUoms($event)"
                          (onSelect)="selectUom($event)"
                          [dropdown]="true"
                          minLength="1">
            <ng-template let-option pTemplate="item">
              <div>{{option.label}}</div>
            </ng-template>
          </p-autoComplete>
          <label for="costingUom">Default Costing UOM</label>
        </span>
      </div>
    </div>
  </div>
</p-panel>
