import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SchedulePart } from '../app/digi-common/models/billOfEntries';

@Injectable({
  providedIn: 'root'
})
export class ScheduleBehaviourSubject {

  private scheduleBehaviourSubject = new BehaviorSubject<SchedulePart>(null);

  constructor() {
  }

  addSchedulePart(supplierInvoice: SchedulePart) {
    this.scheduleBehaviourSubject.next(supplierInvoice);
  }

  getSchedulePart(): Observable<SchedulePart> {
    return this.scheduleBehaviourSubject.asObservable();
  }
}
