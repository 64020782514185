<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g">
      <div class="ui-g-4">
        <p-panel header="Export Consignee">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <digi-business-entity-search [businessEntity]="selectedSupplierInvoice.supplier"
                                             [roleName]="'Consignee'"
                                             [roleID]="2"
                                             [showAddress]="true"
                                             [canAdd]="true"
                                             [isNomination]="true"
                                             [declarantRoleId]="12"
                                             [declarantCode]="exportFile.exporter.code"
                                             [businessEntities]="filteredExportConsignees"
                                             (selectedBusinessEntity)="selectConsignee($event)"
                ></digi-business-entity-search>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Exporter Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <digi-business-entity-search [businessEntity]="exportFile.exporter"
                                             [roleName]="'Exporter'"
                                             [roleID]="12"
                                             [isNomination]="false"
                                             [showAddress]="true"
                ></digi-business-entity-search>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Default Values">
          <digi-invoice-default-values>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="certificateType" [(ngModel)]="selectedSupplierInvoice.certificate.type"
                                                  field="label"
                                                  [suggestions]="filteredCertificateTypes"
                                                  (completeMethod)="searchCertificateTypes($event)"
                                                  (onSelect)="selectCertificateType($event)"
                                                  [dropdown]="true"
                                                  minLength="3"
                                                  required="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="certificateType">Certificate Type</label>
                  </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
                              <span class="md-inputfield">
                                <input type="text" pInputText
                                       [(ngModel)]="selectedSupplierInvoice.certificate.certificateNo"
                                       required=true>
                                <label>Certificate No.</label>
                              </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <button pButton
                      type="button"
                      label="Copy Certificate to lines"
                      (click)="copyCertificateToLines()"></button>
            </div>
          </digi-invoice-default-values>
        </p-panel>
      </div>
    </div>
  </div>
</div>
