import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ClearingInstructionsService = /** @class */ (function () {
    function ClearingInstructionsService(http, config) {
        this.http = http;
        this.config = config;
    }
    ClearingInstructionsService.prototype.findAll = function () {
        return this.http.get(this.config.apiEndpoint + "api/customsPurposes?size=1000&projection=flattenedCustomsPurpose")
            .pipe(map(function (response) { return response['_embedded'].customsPurposes; }));
    };
    ClearingInstructionsService.prototype.findAllClearingInstructions = function () {
        return this.http.get(this.config.apiEndpoint + "api/customsPurposes/search/imports?projection=flattenedCustomsPurpose");
    };
    ClearingInstructionsService.prototype.findAllExportInstructions = function () {
        return this.http.get(this.config.apiEndpoint + "api/customsPurposes/search/exports?projection=flattenedCustomsPurpose");
    };
    ClearingInstructionsService.prototype.findAllExBondInstructions = function () {
        return this.http.get(this.config.apiEndpoint + "api/customsPurposes/search/exbond?projection=flattenedCustomsPurpose");
    };
    ClearingInstructionsService.prototype.findCustomProceduresForPreviousBOE = function () {
        return this.http.get(this.config.apiEndpoint + "api/customsProcedures/search/customsPurposeIn?customsPurposeCode=RIB,RIT")
            .pipe(map(function (response) { return response['_embedded'].customsProcedures; }));
    };
    ClearingInstructionsService.prototype.findCustomProceduresForExbond = function () {
        var codes = 'XDP';
        return this.http.get(this.config.apiEndpoint + "api/customsProcedures/search/customsPurposeIn?customsPurposeCode=" + codes)
            .pipe(map(function (response) { return response['_embedded'].customsProcedures; }));
    };
    ClearingInstructionsService.ngInjectableDef = i0.defineInjectable({ factory: function ClearingInstructionsService_Factory() { return new ClearingInstructionsService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ClearingInstructionsService, providedIn: "root" });
    return ClearingInstructionsService;
}());
export { ClearingInstructionsService };
