<div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="incoTerm" [(ngModel)]="clearingFile.incoTerm"
                            field="incoTermDesc"
                            [suggestions]="filteredIncoTerms"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true" minLength="3">
              <ng-template let-option pTemplate="item">
                <div>{{option.incoTermCode}}: {{option.incoTermDesc}}</div>
              </ng-template>
            </p-autoComplete>
               <label for="incoTerm">File Inco Term </label>
              </span>
  <span class="ui-inputgroup-addon"><i class="fa fa-search"></i>
        </span>
</div>

