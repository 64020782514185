import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Container, DepotTerminal} from '../../../../models/file';
import {DepotTerminalService} from '../../../../services/depot-terminal.service';
import {ContainerBehaviorSubject} from '../../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, OnDestroy {

  @Input()
  filteredDepotTerminals: DepotTerminal[];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private depotTerminalService: DepotTerminalService,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => {
        this.selectedContainer = ct;
      });
  }

  search(event) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, 'T').subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  clearAll() {
    this.selectedContainer.terminal = undefined;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
