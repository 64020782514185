import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingService} from '../loading.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-loading',
  templateUrl: './loading.component.html',
  styles: [`   .loading {
    position: fixed;
    z-index: 99999;
    height: 50px;
    width: 100%;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

   .ui-progress-spinner {
    width: 50px;
    height: 50px;
    z-index: 99999;
  }
  }
  
  .loading:before {
    z-index: 99998;
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
  }
    .align-center {
      text-align: center;
    }

  `],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit, OnDestroy {
  subscriprion: Subscription;
  loading = false;

  constructor(private loadingSubject: LoadingService) {
  }

  ngOnInit() {
   this.subscriprion =  this.loadingSubject.getLoader()
      .subscribe(loader => {
      this.loading = loader;
    });
  }

  ngOnDestroy() {
    this.subscriprion.unsubscribe();
  }
}
