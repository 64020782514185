<p-panel>
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value={{businessEntity.code}} pInputText
                           readonly>
                    <label>Guarantor Code</label>
                </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <digi-app-guarantee-type [guaranteeType]="guarantor?.guaranteeType" (onSelect)="selectGuarantor($event)"></digi-app-guarantee-type>
    </div>
  </div>
</p-panel>
