import { OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BillOfEntryBehaviourSubject } from '../../../subjects/billOfEntry-behaviour-subject';
import { ExBondFileBehaviourSubject } from '../../../subjects/exbondfile-behaviour-subject';
import { ExBondBillOfEntryService } from '../services/ex-bond-bill-of-entry.service';
import { ExBondFileService } from '../services/ex-bond-file.service';
var GenerateComponent = /** @class */ (function () {
    function GenerateComponent(exBondFileBehaviourSubject, billOfEntryBehaviourSubject, exBondFileService, exBondBillOfEntryService) {
        this.exBondFileBehaviourSubject = exBondFileBehaviourSubject;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.exBondFileService = exBondFileService;
        this.exBondBillOfEntryService = exBondBillOfEntryService;
    }
    GenerateComponent.prototype.ngOnInit = function () {
        var _this = this;
        var exBondFileObservable = this.exBondFileBehaviourSubject.getExBondFile();
        var billOfEntryObservable = this.billOfEntryBehaviourSubject.getBillOfEntry();
        this.subscription = combineLatest(exBondFileObservable, billOfEntryObservable).subscribe(function (_a) {
            var exBondFile = _a[0], billOfEntry = _a[1];
            _this.exBondFile = exBondFile;
            _this.selectedBillOfEntry = billOfEntry;
        });
    };
    GenerateComponent.prototype.loadUploadedFiles = function (event, tabview) {
        var _this = this;
        if (event.index === 2) {
            if (this.exBondFile && this.selectedBillOfEntry) {
                this.exBondBillOfEntryService.loadUploadedFile(this.selectedBillOfEntry)
                    .pipe(catchError(function (err) {
                    console.log(err.status);
                    if (err.status !== 404) {
                        return throwError(err);
                    }
                    return EMPTY;
                }))
                    .subscribe(function (uploadedFiles) {
                    if (uploadedFiles && uploadedFiles['_embedded']) {
                        _this.files = [];
                        (_a = _this.files).push.apply(_a, uploadedFiles._embedded.supportingDocuments);
                        _this.files = _this.files.slice();
                    }
                    var _a;
                });
            }
        }
    };
    GenerateComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return GenerateComponent;
}());
export { GenerateComponent };
