import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DataScrollerModule} from 'primeng/datascroller';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {ListboxModule} from 'primeng/listbox';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {PickListModule} from 'primeng/picklist';
import {CardModule, ConfirmDialogModule, MenubarModule, MenuModule, TabMenuModule} from 'primeng/primeng';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {TreeModule} from 'primeng/tree';
import {DigiCommonModule} from '../digi-common/digi-common.module';
import {AirContextNavigationComponent} from './air-context-navigation/air-context-navigation.component';
import {AirDashboardComponent} from './air-dashboard/air-dashboard.component';
import {DocumentsComponent} from './air-registration/documents/documents.component';
import {PlanningComponent} from './air-registration/planning/planning.component';
import {RegisterComponent} from './air-registration/register/register.component';
import {HawbAdditionalReferencesTabComponent} from './components/hawb-additional-references-tab/hawb-additional-references-tab.component';
import {HawbAirlineSectionComponent} from '../digi-common/components/hawb-airline-section/hawb-airline-section.component';
import {AIR_ROUTES} from './digi-air.routes';
import {HawbComponent} from './modules/air-transport/hawb/hawb.component';
import { AirExportRegistrationComponent } from './export/air-export-registration/air-export-registration.component';
import { ExportAirDashboardComponent } from './air-dashboard/export-air-dashboard/export-air-dashboard.component';
import { AwbPoolComponent } from './components/awb-pool/awb-pool.component';
import {ConfirmationService} from 'primeng/api';
import { BookingComponent } from '../digi-common/components/booking/booking.component';
import { AwbReservationModalComponent } from './components/awb-reservation-modal/awb-reservation-modal.component';
import { AirForwardingDashboardComponent } from './air-dashboard/air-forwarding-dashboard/air-forwarding-dashboard.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {EdiComponent} from '../digi-common/components/forwarding/edi/edi.component';
import {ManifestsComponent} from '../digi-common/components/forwarding/manifests/manifests.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
    ButtonModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    StepsModule,
    ScrollPanelModule,
    CheckboxModule,
    ListboxModule,
    InputTextModule,
    AutoCompleteModule,
    PickListModule,
    AutoCompleteModule,
    InputTextModule,
    ListboxModule,
    PanelModule,
    CardModule,
    CardModule,
    PickListModule,
    CalendarModule,
    DialogModule,
    FieldsetModule,
    InputTextareaModule,
    DropdownModule,
    TooltipModule,
    ToolbarModule,
    MultiSelectModule,
    DataScrollerModule,
    DataViewModule,
    AccordionModule,
    ToastModule,
    OverlayPanelModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    KeyFilterModule,
    DigiCommonModule,
    MenubarModule,
    MenuModule,
    InputMaskModule,
    RouterModule.forChild(AIR_ROUTES),
    ConfirmDialogModule,
    PdfViewerModule,
  ],
  declarations: [
    AirDashboardComponent,
    AirContextNavigationComponent,
    HawbComponent,
    PlanningComponent,
    RegisterComponent,
    DocumentsComponent,
    HawbAdditionalReferencesTabComponent,
    AirExportRegistrationComponent,
    ExportAirDashboardComponent,
    AwbPoolComponent,
    AwbReservationModalComponent,
    AirForwardingDashboardComponent,
    ManifestsComponent,
    EdiComponent
  ],
    exports: [
        PlanningComponent
    ],
  providers: [
    HttpClient, ConfirmationService
  ]
})
export class DigiAirModule {
}
