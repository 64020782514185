<p-panel>
  <div class="ui-g">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                           pInputText readonly>
                    <label>Overseas Agent Code</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <digi-country-of-origin [country]="overseasAgent?.country"
                                (onSelect)="selectCountry($event)"></digi-country-of-origin>
      </div>
      <div class="ui-g-12 ui-md-12">
        <digi-currency [currency]="overseasAgent?.currency" (onSelect)="selectCurrency($event)" [required]=false></digi-currency>
      </div>
    </div>
  </div>
</p-panel>
