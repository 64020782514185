import {Exporter, ReceivingBank} from '../../digi-business-entity/models/business-entity';
import {ExportFile} from './exportFile';
import {AdditionalUom, Certificate, SundryCharge, Warehouse} from './file';
import {Uom} from './TariffCode';

export class AbstractBoE {
  id?: string;
  documentCode?: string;
  assessmentDate?: string;
  houseNumber?: string;
  sobDate?: string;
  financialAccountNumber?: string;
  clearingInstruction?: ClearingInstruction;
  transportDocNo?: string;
  customsProcedureCode?: number;
  previousCustomsProcedureCode?: number;
  previousMrnNumber?: string;
  destinationWarehouse?: Warehouse;
  noOfLines?: number;
  serialNumber?: string;
  markAndNumbers?: BillOfEntryMarksAndNumbers[];
  lrn?: string;
  grossMass?: number;
  numberOfPackages?: number;
  paymentType?: PaymentType;
  contrl?: EDIMessage;
  cusres?: EDIMessage;
  containers?: Container[];
  cpc?: string;
  dateEdiSubmitted?: string;
  schedulePartTotals?: SchedulePartTotal[];
  totalDuties?: number;
  mrn?: string;
  customsPurposeCode?: string;
  permits?: BillOfEntryPermit[];
  totalBoE?: number;
  boeIndex ?: number;
  cusDec?: string;
  boeInvoiceDetails?: BoEInvoiceDetails[];
  destination?: Destination;
  bondNo?: string;
  bondValue ?: number;
  cusdecs: String[];
  vatIndicator?: boolean;
  acknowledgementIndicator?: number;
  markedAsSubmitted?: boolean;
  parentMarkedAsSubmitted?: boolean;
  releaseDate?: string;
  [k: string]: any;
  type?: string;
}

export class BillOfEntry extends AbstractBoE {
  importer?: Importer;
  totalCustomsVAT?: number;
  totalCustomsValue?: number;
  totalAmountDue?: number;
  lines?: BillOfEntryLine[];
  clearingFile?: string;
  export?: Export;
  exporters?: Supplier[];
  customsPurposeCode?: string;
  permits?: BillOfEntryPermit[];
  totalCustomsDuty?: number;
  totalRebateAmount?: number;
  cifcValue?: number;
}

export class ExportBillOfEntry extends AbstractBoE {
  exporter?: Exporter;
  lines?: ExportBillOfEntryLine[];
  receivingBank?: ReceivingBank;
  exportFile?: ExportFile;
  certificateNumbers?: string[];
  [k: string]: any;
}

export class ExBondBillOfEntry extends AbstractBoE {
  importer?: Importer;
  totalCustomsVAT?: number;
  totalCustomsValue?: number;
  totalAmountDue?: number;
  lines?: ExBondBillOfEntryLine[];
  exBondFile?: string;
  customsPurposeCode?: string;
  totalCustomsDuty?: number;
  totalDuties?: number;
  totalRebateAmount?: number;
  cifcValue?: number;
  [k: string]: any;
}

export class SchedulePartTotal {
  schedulePart?: SchedulePart;
  totalValue?: number;
}

export class Container {
  containerType?: ContainerType;
  nonIsoContainerNo?: boolean;
  containerNo?: string;
  SerialNo?: string;
  noOfPackages?: number;
  zone?: string;
  solas?: Solas;
  nettWeight?: number;
  cbm?: number;
  freightTons?: number;
  containerSpecification?: ContainerSpecification;
  sealDetails?: SealDetails;
  masContainerType?: MasContainerType;
  masContainerSize?: MasContainerSize;
  terminal?: DepotTerminal;
  depot?: DepotTerminal;
}

export class DepotTerminal {
  code?: string;
  name?: string;
  label?: string;

  [k: string]: any;
}

class SundryChargeAmount {
  localAmount?: number;
  type?: string;
  chargeDescription?: string;
}

export class BoEInvoiceDetails {
  currency: any;
  invoiceDate?: string;
  bankInvoiceNumber?: string;
  supplier?: string;
  exchangeRate?: number;
  totalForeignInvoice?: number;
  sundryCharges?: SundryChargeAmount[];
}

export class ErrorDetail {
  code?: string;
  description?: string;
  lineNumber?: number;
}

export class ErrorPointDetails {
  errorPointDetail?: string;
  lineNumber?: number;
}

export class ApplicationErrorInfo {
  error?: string;
  errorCode?: string;
}

export class EDIErrorDetails {
  messageRefNumber?: string;
  errorPointDetails?: ErrorPointDetails;
  errorNumber?: ApplicationErrorInfo[];
  errorDescriptions?: string[];
}

export class EDIMessage {
  id?: string;
  lrn?: string;
  contrlRefNumber?: string;
  messageRefNumber?: string;
  receivedDate?: string;
  messageFunction?: string;
  customsStatus?: string;
  messageStatus?: string;
  interchangeStatus?: string;
  messages?: string[];
  mrn?: string;
  caseNumber?: string;
  customsStatusError?: string;
  errorDetails?: EDIErrorDetails[];
}

export class Importer {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;

  [k: string]: any;
}

export class Address {
  zipCode?: string;
  line4?: string;
  line3?: string;
  line2?: string;
  line1?: string;

  [k: string]: any;
}

export class SchedulePart {
  code?: string;
  description?: string;
  value?: number;
  scheduleCode?: string;
  scheduleDescription?: string;
  calcOrder?: number;
  chargeRebate?: string;
  excludedFromDuties?: boolean;
}

export class BoeAddInfoType {
  description?: string;
  value?: string;
  code?: string;
}

export class BoeAddInfoTypeId {
  code?: string;
  fileTypeId?: number;
}

export class BillOfEntryMarksAndNumbers {
  marksAndNumbers?: string[];
  endorsements?: string[];

  [k: string]: any;
}

export class ClearingInstruction {
  customsPurpose?: CustomsPurpose;
  procedureCategoryCode?: string;
  customVatRequired?: boolean;
  customsProcedureCode?: number;
  previousCustomsProcedureCode?: number;
  previousMrnNumber?: string;
  destinationWarehouse?: Warehouse;
  destination?: Country;
  customsProcDesc?: string;
  consolidateBoeLines?: boolean;
  pcDescription?: string;
  transportMethod?: TransportMethod;

  [k: string]: any;
}

export class BillOfEntryPermit {
  companyCode?: string;
  number?: string;
  lineNumbers?: number[];
  permitLineQuality?: number;
  permitLineValue?: number;
  valueBased?: boolean;
  quantityBased?: boolean;

  [k: string]: any;
}

export class CustomsPurpose {
  customsPurposeName?: string;
  customsPurposeFile?: CustomsPurposeFile;
  customsPurposeCode?: string;

  [k: string]: any;
}

export class CustomsPurposeFile {
  documentCode?: string;
  fileTypeId?: number;
  customsPurposeCode?: string;
  vocDocumentCode?: string;

  [k: string]: any;
}

export class Country {
  code?: string;
  defaultTradeAgreement?: TradeAgreement;
  name?: string;
  tradeAgreements?: TradeAgreement[];

  [k: string]: any;
}

export class TradeAgreement {
  code?: string;
  description?: string;

  [k: string]: any;
}

export class TransportMethod {
  code?: string;
  method?: string;
  description?: string;

  [k: string]: any;
}

export class FormulaComputation {
  number?: number;
  formulaNo?: number;
  computationBase?: string;
  consolidateAllowed?: boolean;
  computation?: string;

  [k: string]: any;
}

class WarehouseCaptureDetails {
  noOfPacksAtWarehouse: number;
}

export abstract class AbstractBoELine {
  grossMass?: number;
  tariffCode?: TariffCode;
  used?: boolean;
  da59?: boolean;
  actualValue?: number;
  countryOfOrigin?: Country;
  usedGoods?: boolean;
  discountValue?: number;
  permit?: Permit;
  excludeFromCosting?: boolean;
  additionalSchedules?: SchedulePart[];
  additionalInformation?: BoeAddInfoType[];
  additionalScheduleTariffs?: TariffCode[];
  lineQuantity?: number;
  lineNo?: number;
  lineUom?: string;
  customsProcedureCode?: number;
  previousCustomsProcedureCode?: number;
  previousMrnNumber?: string;
//   destinationWarehouse?: Warehouse;
  customsValue?: number;
  description?: string;
  discountPercent?: number;
  vatRequired?: boolean;
  tradeAgreement?: TradeAgreement;
  additionalUoms?: AdditionalUom[];
  additionalClientReference?: string;
  invoiceNumber?: string;
  partNumber: string;
  warehouseCaptureDetails?: WarehouseCaptureDetails;
}
export class BillOfEntryLine extends AbstractBoELine {
  repair?: boolean;
  totalRebate?: number;
  customsDuty?: number;
  customsVAT?: number;
  rooNumber?: string;
  vdnMarkup?: number;
  vdnMarkupAmount?: number;
  costingValue?: number;
  costingUom?: Uom;
  invoiceGrossValue?: number;
  localInvoiceGrossValue?: number;
  [k: string]: any;
}

export class ExBondBillOfEntryLine extends AbstractBoELine {

}

export class ExportBillOfEntryLine extends AbstractBoELine {
  certificate?: Certificate;
  [k: string]: any;
}

export class TariffCode {
  code?: string;
  permits?: Permit[];
  description?: string;
  formula?: Formula;
  checkDigit?: number;
  fullDescription?: string;
  parameters?: TariffParameter[];
  calculatedValue?: number;
  schedulePart: SchedulePart;
  [k: string]: any;
}

export class Permit {
  permitNumber?: string;
  permitUom?: string;
  balanceValue?: number;
  balanceQuantity?: number;
  valueBased?: boolean;
  permitQuantity?: number;
  permitValue?: number;
  quantityBased?: boolean;

  [k: string]: any;
}

export class Formula {
  computations?: FormulaComputation[];
  statisticalUOM?: string;
  rebate?: boolean;
  description?: string;
  hasDcc?: boolean;
  number?: number;
  rebateExcludes12B?: boolean;
  chargeString?: string;
  noOfParameters?: number;
  prodatFormulaNo?: string;
  free?: boolean;
  hasRcc?: boolean;
  parameters?: FormulaParameter[];
  effectiveDate?: string;
  formulaEffUntilDate?: string;

  [k: string]: any;
}

export class FormulaParameter {
  number?: number;
  incInRebate?: string;
  formulaNo?: number;
  dataType?: string;
  value?: string;

  [k: string]: any;
}

export class TariffParameter {
  tradeGroupType?: string;
  number?: number;
  value2?: string;
  tradeGroupCode?: string;
  value1?: string;
  tariffId?: number;
  effectiveDate?: string;

  [k: string]: any;
}

export class Supplier {
  vdnNumber?: string;
  code?: string;
  address?: Address;
  vdnMarkup?: number;
  name?: string;
  vdnWarning?: boolean;
  customsCode?: string;

  [k: string]: any;
}

export class Destination {
  code?: string;
  countryCode?: string;
  name?: string;
  countryName?: string;

  [k: string]: any;
}

export class Export {
  code?: string;
  countryCode?: string;
  name?: string;
  countryName?: string;

  [k: string]: any;
}

export class PaymentType {
  paymentCode?: string;
  paymentDesc?: string;

  [k: string]: any;
}

export class PermitTariff {
  companyCode?: string;
  unconfirmedQuantity?: number;
  customsBalanceQuantity?: number;
  customsBalanceValue?: number;
  balanceQuantity?: number;
  valueBased?: boolean;
  permitNumber?: string;
  permitUom?: string;
  balanceValue?: number;
  tariffId?: number;
  permitQuantity?: number;
  unconfirmedValue?: number;
  permitValue?: number;
  quantityBased?: boolean;

  [k: string]: any;
}

export class ContainerType {
  code?: string;
  description?: string;
  cargoStatusCode?: number;
}

export class ContainerSpecification {
  code?: string;
  description?: string;
  tareWeight?: number;
  maxWeight?: number;
  capacity?: number;
  containerSize?: ContainerSize;
}

export class SealDetails {
  sealingParty?: string;
  sealStatus?: string;
  sealType?: string;
}


export class MasContainerType {
  type?: string;
  description?: string;
  additionalInformation?: string;
}

export class MasContainerSize {
  code?: string;
  description?: string;
}

export class Solas {
  vgm?: number;
  verificationMethod?: number;
  vgmVerifier?: string;
  samasaAccrediation?: string;
}

export class ContainerSize {
  size?: number;
  code?: string;
  description?: string;
  label?: string;
  maxMass?: number;
  nettMass?: number;
}

export class SequenceData {
  sequence: number;
  generatedFiles: any[];
}









