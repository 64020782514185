import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PurchaseTermService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getPurchaseTerms(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/purchaseTerms/` + code);
  }

  findPurchaseTermsStartWith(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/purchaseTerms/search/codeOrDescriptionStartsWith?code=` + code);
  }

  findPurchaseTermsStartsWith(searchValue) {
    if (searchValue === '') {
      return of([]);
    }
    const terms = this.http
      .get(`${this.config.apiEndpoint}api/purchaseTerms/search/codeOrDescriptionStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].purchaseTerms)
      );
    return terms;
  }


}
