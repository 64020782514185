<p-tabView orientation="left" styleClass="stepMenu" #tabView>
  <p-tabPanel header="Transport" [selected]="true">
    <div class="ui-g-12 ui-g-nopad">
      <!-- Clearing Form -->
      <div class="ui-g-12 pt-0">
        <digi-exports-file-header-section step="transportDocument" [canAdd]="true">
        </digi-exports-file-header-section>
      </div>
      <!--/Clearing Form -->
    </div>
    <!-- Table -->
    <div *ngIf="exportFile.transportDocument" class="mt-2">
      <pre style="visibility: hidden;">{{hasTransportDocument()}}</pre>
      <br>
      <!-- Table -->
      <div class="ui-md-12 ui-g-12">
        <p-messages [(value)]="validationMessagesForMawbNo"></p-messages>
        <p-messages [(value)]="validationMessagesForHawbNo"></p-messages>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel *ngIf="isSeaTransportDocument() || isRoadTransportDocument() || isRailTransportDocument()"
                     [header]="isSeaTransportDocument()?'Ocean Line':'Transporter'">
              <digi-road-oceanline-details [fileType]="'exports'"
                                           [validationMessagesForMawbNo]="validationMessagesForMawbNo"
                                           [validationMessagesForHawbNo]="validationMessagesForHawbNo">
              </digi-road-oceanline-details>
            </p-panel>
            <p-panel *ngIf="isAirTransportDocument()" header="Airline">
              <digi-hawb-airline-section [validationMessagesForMawbNo]="validationMessagesForMawbNo"
                                         [validationMessagesForHawbNo]="validationMessagesForHawbNo"
                                         [transportDocument]="selectedTransportDocument"
                                         [fileNumber]="exportFile.clearingFileNumber"
                                         [isAirExports]="isAirTransportDocument()"
              >
              </digi-hawb-airline-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel *ngIf="isRoadTransportDocument() || isSeaTransportDocument() || isRailTransportDocument()"
                     header="Voyage Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <digi-app-local-forwarder *ngIf="isRoadTransportDocument()"
                                                class="ui-g-12 ui-md-12"
                                                (cargoCarrierCode)="selectLocalForwarder($event)"
                      ></digi-app-local-forwarder>
                      <div *ngIf="isSeaTransportDocument()" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="exportFile.transportDocument.voyageDetails.voyageNo"
                           required>
                   <label>Voyage No</label>
                 </span>
              </div>
              <div *ngIf="!exportFile.transportDocument.voyageDetails.voyageNo"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
                      </div>
                      <div *ngIf="isSeaTransportDocument()" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="exportFile.transportDocument.voyageDetails.customsRadioCallSign"
                                field="vesselName"
                                [suggestions]="filteredCustomsRadioCallSigns"
                                (completeMethod)="searchRadioCallSignByCodeOrName($event, 'vesselName')"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.vesselName}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Vessel Name</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
             <div *ngIf="!exportFile.transportDocument.voyageDetails.customsRadioCallSign.radioCallSign"
                  class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12" *ngIf="isRailTransportDocument()">
                        <span class="md-inputfield">
                          <span class="ui-float-label">
                            <input id="railName" type="text" pInputText [(ngModel)]="selectedTransportDocument.voyageDetails.railName"
                                   required=true>
                               <label for="railName">Rail Name</label>
                          </span>
                          <div *ngIf="!selectedTransportDocument.voyageDetails.railName"
                               class="ui-message ui-messages-error ui-corner-all">This field is required
                            </div>
                        </span>
                      </div>
                      <div class="ui-g-12 ui-md-12" *ngIf="isRailTransportDocument()">
                        <span class="md-inputfield">
                          <span class="ui-float-label">
                            <input id="railCode" type="text" pInputText [(ngModel)]="selectedTransportDocument.voyageDetails.railCode"
                                   required=true>
                               <label for="railCode">Rail Code</label>
                          </span>
                          <div *ngIf="!selectedTransportDocument.voyageDetails.railCode"
                               class="ui-message ui-messages-error ui-corner-all">This field is required
                            </div>
                        </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                              <p-autoComplete id="departurePort" [ngModel]="exportFile.transportDocument.departurePort" field="label"
                                              [suggestions]="filteredPortDetails"
                                              (completeMethod)="searchPort($event, 'loading')"
                                              (onSelect)="selectPort($event, 'departurePort')"
                                              [autoHighlight]="true"
                                              minLength="3"
                                              required=true>
                                <ng-template let-option pTemplate="item">
                                  <div>{{option.code}}: {{option.name}}</div>
                                </ng-template>
                              </p-autoComplete>
                                 <label for="departurePort">Departure Port</label>
                                  </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                            <div *ngIf="!exportFile.transportDocument.departurePort?.code"
                                 class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="docsIssuedAtPort" [ngModel]="exportFile.transportDocument.docsIssuedAtPort" field="label"
                    [suggestions]="filteredPortDetails"
                    (completeMethod)="searchPort($event, 'docsIssuedAtPort')"
                    (onSelect)="selectPort($event, 'docsIssuedAtPort')"
                    [autoHighlight]="true"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="docsIssuedAtPort">Docs Issued At Port</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!exportFile.transportDocument.docsIssuedAtPort?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="dischargePort" [ngModel]="exportFile.transportDocument.dischargePort" field="label"
                    [suggestions]="filteredPortDetails"
                    (completeMethod)="searchPort($event, 'discharge')"
                    (onSelect)="selectPort($event, 'dischargePort')"
                    [autoHighlight]="true"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="dischargePort">Discharge Port</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!exportFile.transportDocument.dischargePort?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="destinationPort" [ngModel]="exportFile.transportDocument.destinationPort" field="label"
                    [suggestions]="filteredPortDetails"
                    (completeMethod)="searchPort($event, 'destination')"
                    (onSelect)="selectPort($event, 'destinationPort')"
                    [autoHighlight]="true"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="destinationPort">Destination Port</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!exportFile.transportDocument.destinationPort?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="portOfExit" [ngModel]="exportFile.transportDocument.exitPort" field="label"
                    [suggestions]="filteredPortDetails"
                    (completeMethod)="searchExitPort($event)"
                    (onSelect)="selectExitPort($event)"
                    [autoHighlight]="true"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="portOfExit">Place of Exit</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!selectedTransportDocument.exitPort?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>
                      </div>
                      <div *ngIf="isSeaTransportDocument()" class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete id="callPurpose"
                    [(ngModel)]="selectedTransportDocument.callPurpose"
                    [suggestions]="callPurposes"
                    [dropdown]="true"
                    (completeMethod)="callPurposes = ['Loading', 'Unloading']"
    ></p-autoComplete>
       <label for="callPurpose">Call Purpose</label>
        </span>
  </div>
</span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="departureDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="selectedTransportDocument.departureDate">
                </p-inputMask>
                <label for="departureDate">Departure Date</label>
            </span>
          <div *ngIf="!selectedTransportDocument.departureDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
                      </div>

                      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="arrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="selectedTransportDocument.arrivalDate">
            </p-inputMask>
            <label for="arrivalDate">Arrival Date</label>
        </span>
          <div *ngIf="!selectedTransportDocument.arrivalDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
            <p-panel *ngIf="isAirTransportDocument()" header="Section">
              <digi-hawb-flight-detail-section [fileType]="'exports'">
              </digi-hawb-flight-detail-section>
            </p-panel>
            <br>
            <div class="ui-g-4 ui-g-nopad pt-2">
              <button pButton type="submit" label="Sundry Changes" class="ui-button-secondary"
                      (click)="openSundryChargeModel()"></button>
            </div>
            <digi-sundry-charges [display]="display" (displayChange)="onDialogClose($event)"
                                 [hasSundryCharge]="selectedTransportDocument"></digi-sundry-charges>
          </div>
          <div class="ui-g-4">
            <p-panel header="Weights & Measures">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text"
                             pInputText
                             [(ngModel)]="selectedTransportDocument.measures.numberOfPackages"
                             required=true>
                      <label>No. of Packages</label>
                      <div *ngIf="!selectedTransportDocument.measures.numberOfPackages"
                           class="ui-message ui-messages-error ui-corner-all">
                        This field is required
                      </div>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText [(ngModel)]="selectedTransportDocument.measures.volume"
                               required="true">
                        <label>{{isAirTransportDocument() ? 'Volume' : 'CBM'}}</label>
                      </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedTransportDocument.measures.grossWeight"
                             required=true>
                      <label>Gross Weight (kg)</label>
                      <div *ngIf="!selectedTransportDocument.measures.grossWeight"
                           class="ui-message ui-messages-error ui-corner-all">
                        This field is required
                      </div>
                    </span>
                  </div>
                  <div *ngIf="isRoadTransportDocument()" class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [ngModel]="selectedTransportDocument.measures.grossWeight/1000"
                             readonly>
                      <label>Freight Tonnage</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12" *ngIf="isRoadTransportDocument()">
                    <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" [(ngModel)]="selectedTransportDocument.measures.numberOfTrucks" pInputText>
          <label>No of Trucks</label>
        </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText
                               [(ngModel)]="selectedTransportDocument.measures.chargeableWeight">
                        <label>Chargeable Weight (kg)</label>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isAirTransportDocument()" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText [(ngModel)]="selectedTransportDocument.measures.nettWeight">
                        <label>Nett Weight (kg)</label>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="isAirTransportDocument() || isRailTransportDocument()" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText
                               [(ngModel)]="selectedTransportDocument.measures.noOfContainers">
                        <label>No. of Containers</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
            <br>
            <p-panel header="Freight">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-g-nopad pt-2">
                    <digi-currency label="Currency"
                                   [currency]="selectedTransportDocument.freight.currency">
                    </digi-currency>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText
                 [(ngModel)]="selectedTransportDocument.freight.amount">
          <label>Amount</label>
        </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-g-nopad pt-2">
                    <div class="ui-g-12 ui-md-12">
   <span class="md-inputfield">
     <span class="ui-float-label">
        <p-autoComplete id="collectPrepaid" [(ngModel)]="selectedTransportDocument.freight.collectPrepaid"
                        field="label" [dropdown]="true"
                        [suggestions]="filteredCollectPrepaids"
                        (completeMethod)="searchCollectOrPrepaid($event)"
                        [autoHighlight]="true" (onSelect)="selectCollectOrPrepaid($event)" minLength="1"
                        [forceSelection]="true"
                        required=true>
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}: {{option.description}}</div>
          </ng-template>
        </p-autoComplete>
        <label for="collectPrepaid">Collect/Prepaid</label>
     </span>
     <div *ngIf="!selectedTransportDocument?.freight?.collectPrepaid?.code"
          class="ui-message ui-messages-error ui-corner-all">This field is required</div>
   </span>
                    </div>

                  </div>
                </div>
              </div>

            </p-panel>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Date Details">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <p-inputMask id="exchangeRateDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="selectedTransportDocument.exchangeRateDate">

                </p-inputMask>
                        <label for="exchangeRateDate">Exchange Rate Date</label>
              </span>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <p-inputMask id="stackOpenDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="selectedTransportDocument.stackOpen">

                </p-inputMask>
                        <label for="stackOpenDate">Stack Open</label>
              </span>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <p-inputMask id="stackCloseDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="selectedTransportDocument.stackClose">

                </p-inputMask>
                        <label for="stackCloseDate">Stack Close</label>
              </span>
                    </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div *ngIf="isRoadTransportDocument()" class="ui-g-4">
            <p-panel header="Vehicle Registration">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <input id="vehicle" pInputText
                       [(ngModel)]="selectedTransportDocument.voyageDetails.vehicleAndTrailerReg">
                        <label for="vehicle">Vehicle</label>
              </span>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <input pInputText id="trailer1"
                       [(ngModel)]="selectedTransportDocument.voyageDetails.trailerOne">
                        <label for="trailer1">Trailer 1</label>
              </span>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <span class="ui-float-label">
                <input id="trailer2" pInputText
                       [(ngModel)]="selectedTransportDocument.voyageDetails.trailerTwo">
                        <label for="trailer2">Trailer 2</label>
              </span>
                    </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Overseas Agent Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <digi-business-entity-search [businessEntity]="selectedTransportDocument.overseasAgent"
                                                 [businessEntities]="overseasAgents | async"
                                                 [showAddress]="true"
                                                 [roleName]="'Overseas Agent'"
                                                 [roleID]="5"
                                                 (selectedBusinessEntity)="selectOverseasAgent($event)"
                                                 [requiresValidation]="false"
                    ></digi-business-entity-search>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div *ngIf="isSeaTransportDocument()" class="ui-g-4">
            <p-panel header="Co-Loader Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <digi-business-entity-search [businessEntity]="selectedTransportDocument.coLoader"
                                                 [roleName]="'Co-Loader'"
                                                 [roleID]="4"
                                                 [showAddress]="true"
                                                 (selectedBusinessEntity)="selectCoLoader($event)"
                                                 [requiresValidation]="false"
                    ></digi-business-entity-search>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Pick Up/Packing Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <digi-business-entity-search [businessEntity]="exportFile.exporter"
                                                 [showAddress]="true"
                                                 [roleName]="'Pick Up/Packing'"
                                                 [roleID]="12"
                    ></digi-business-entity-search>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Notify Party to print on B/L">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyName">
                   <label>Name</label>
                 </span>
              </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyAddress.line1">
                   <label>Address</label>
                 </span>
              </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyAddress.line2">
                 </span>
              </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyAddress.line3">
                 </span>
              </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyAddress.line4">
                 </span>
              </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.notifyPartyAddress.zipCode">
                   <label>Zip Code</label>
                 </span>
              </div>
          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="!isAirTransportDocument()" header="Containers" [disabled]="!selectedTransportDocument">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-containers-section [type]="'exports'">
          <digi-exports-file-header-section step="transportDocument" [canAdd]="true" (add)="addContainer()">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon">HBOL No</span>
                  <input type="text" pInputText value="{{this.exportFile.transportDocument.hawbNo}}" readonly>
                </div>
              </div>
            </div>
          </digi-exports-file-header-section>
        </digi-containers-section>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Mrks,Nmbrs" [disabled]="!selectedTransportDocument">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-marks-numbers>
          <digi-exports-file-header-section step="transportDocument">
            <div class="ui-g-12 ui-md-6">
              <div class="ui-inputgroup">
      <span class="md-inputfield ui-md-4">
        <input type="text" pInputText value="{{exportFile.transportDocument.hawbNo}}"
               readonly>
        <label style="color: white;">HBOL No</label>
      </span>
              </div>
            </div>
          </digi-exports-file-header-section>
        </digi-marks-numbers>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>

