import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  errorNotificationChange: Subject<Object> = new Subject<Object>();
  successNotificationChange: Subject<Object> = new Subject<Object>();

  constructor() {
  }

  errorNotify(summary: string, detail?: string[]) {
    this.errorNotificationChange.next({summary: summary, detail: detail});
  }

  successNotify(summary: string, detail?: string[]) {
    this.successNotificationChange.next({summary: summary, detail: detail});
  }
}
