<form [formGroup]="form" novalidate>
  <div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-g-nopad pt-2">
      <div class="ui-g-6">
        <p-panel header="MARKS & NUMBERS">
          <div class="ui-g ui-fluid" formArrayName="marksAndNumbers">
            <div *ngFor="let mark of getMarksAndNumbers(form); let i= index"
                 class="ui-md-6">
              <div class="ui-inputgroup" [formGroupName]="i">
                <input type="text" pInputText placeholder="Mark or Number"
                       formControlName="markOrNumber"
                       (change)="pushMarksAndNumbers(i)"
                       (keyup.enter)="addMarkOrNumber()"
                       maxlength="35">
                <span class="fa fa-times ui-inputgroup-addon"
                      *ngIf="getMarksAndNumbers(form).length> 1"
                      (click)="removeMarkOrNumber(i)"></span>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-6" *ngIf="displayEndorsements">
        <p-panel header="ENDORSMENTS">
          <div class="ui-g ui-fluid" formArrayName="endorsements">
            <div *ngFor="let endorsement of getEndorsements(form); let i= index"
                 class="ui-md-6">
              <div class="ui-inputgroup" [formGroupName]="i">
                <input type="text" pInputText placeholder="Endorsement"
                       formControlName="endorsement" (change)="pushEndorsements(i)"
                       (keyup.enter)="addEndorsement()" maxlength="35">
                <span class="fa fa-times ui-inputgroup-addon"
                      *ngIf="getEndorsements(form).length> 1"
                      (click)="removeEndorsement(i)"></span>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</form>
