<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText value="N/A" readonly>
          <label>Customs BOE No. (MRN)</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText value="0" readonly>
          <label>Line No</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText value="**/**/***" readonly>
          <label>Date</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText value="00" readonly>
          <label>Purpose Code / CPC</label>
        </span>
      </div>
    </div>
  </div>
</div>