<!--<div class="ui-fluid"
ui-g-12 column ui-md-12>-->

<div class="ui-g form-group" *ngIf="clearingFile">
  <div class="ui-g-12 column ui-md-12">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
    <p-dropdown [options]="role" [(ngModel)]="selectedRole" (onChange)="roleChanged()" [autoWidth]="false"></p-dropdown>
    <label>Select Role for Consignee</label>
    </span>
    </div>
  </div>
  <div class="ui-g-12" [ngSwitch]="selectedRole">
    <div class="" *ngSwitchCase="'Warehouse'">
      <digi-warehouse-consignee
        [clearingInstruction]="clearingInstruction"></digi-warehouse-consignee>
    </div>
    <div class="" *ngSwitchCase="'Importer'">
      <digi-importer-consignee
        [clearingInstruction]="clearingInstruction"></digi-importer-consignee>
    </div>
    <div class="" *ngSwitchCase="'Local Agent'">
      <digi-local-agent-consignee [clearingFile]="clearingFile"
                                  [clearingInstruction]="clearingInstruction"></digi-local-agent-consignee>
    </div>
    <div class="" *ngSwitchCase="'Overseas Agent'">
      <digi-overseas-agent-consignee></digi-overseas-agent-consignee>
    </div>
    <div class="" *ngSwitchDefault>
      <digi-customs-office-consignee
        [clearingInstruction]="clearingInstruction"></digi-customs-office-consignee>
    </div>
  </div>
</div>
