import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ImportsReportService = /** @class */ (function () {
    function ImportsReportService(http, config) {
        this.http = http;
        this.config = config;
    }
    ImportsReportService.prototype.generateImportBondStoreReports = function (from, to, importerCode, importerName, warehouseCode, warehouseName, mrnNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/importBondStoreReports?from=" + from + "&to=" + to + "&importerCode=" + importerCode + "&importerName=" + importerName + "&warehouseCode=" + warehouseCode + "&warehouseName=" + warehouseName + "&mrnNumber=" + mrnNumber);
    };
    ImportsReportService.ngInjectableDef = i0.defineInjectable({ factory: function ImportsReportService_Factory() { return new ImportsReportService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ImportsReportService, providedIn: "root" });
    return ImportsReportService;
}());
export { ImportsReportService };
