<div class="ui-g-12 ui-md-12">
   <span class="md-inputfield">
      <span class="ui-float-label">
          <p-autoComplete id="depot" [(ngModel)]="transportDocument.depotTerminal"
                          [dropdown]="true"
                          field="label" [suggestions]="filteredDepotTerminals"
                          (completeMethod)="search($event)"
                          [autoHighlight]="true" minLength="1"
                          [forceSelection]="true"
                          required=true>
            <ng-template let-option pTemplate="item">
              <div>{{option?.label}}</div>
            </ng-template>
          </p-autoComplete>
          <label for="depot">Degroup/Transit Depot</label>
      </span>
        <div *ngIf="!isQuotation && !transportDocument?.depotTerminal?.code" class="ui-message ui-messages-error ui-corner-all">
                 This field is required
        </div>
  </span>
</div>
