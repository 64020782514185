import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ShareDataService} from '../../../digi-common/services/share-data.service';
import {ForwardingFileBehaviourSubject} from '../../../../subjects/forwarding-file-behaviour-subject';

@Component({
  selector: 'digi-air-forwarding-dashboard',
  templateUrl: './air-forwarding-dashboard.component.html',
  styleUrls: ['./air-forwarding-dashboard.component.scss']
})
export class AirForwardingDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private shareDataService: ShareDataService,
  ) { }

  ngOnInit() {
    const forwardingFile = this.shareDataService.buildForwardingFile('AIR');
    this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
  }

}
