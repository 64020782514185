import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TariffCodeFormulaeService {

  constructor() { }

  ngOnit() {

  }

  effectiveTradeAgreementParameters(effectiveParameters, supplierInvoiceLine) {
    if (!this.isEmpty(effectiveParameters)) {
      supplierInvoiceLine.tariffCode.parameters = [];
      const trimmedCode = supplierInvoiceLine.tradeAgreement.code ? supplierInvoiceLine.tradeAgreement.code.trim() : null;
      if (effectiveParameters[trimmedCode]) {
        Object.keys(effectiveParameters[trimmedCode]).forEach(function (effectiveParameterKeys) {
          supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters[trimmedCode][effectiveParameterKeys]);
        });
      } else if (Object.keys(effectiveParameters).length === 1) {
        const tradeAgreementKeys = Object.keys(effectiveParameters)[0];
        Object.keys(effectiveParameters[tradeAgreementKeys]).forEach(function (effectiveParameterKeys) {
          supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters[tradeAgreementKeys][effectiveParameterKeys]);
        });
      } else if (trimmedCode !== null) {
        Object.keys(effectiveParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
          supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters['STANDARD'][effectiveParameterKeys]);
        });
      }
    }
  }

  effectiveTradeAgreementFormulae(effectiveFormulae, supplierInvoiceLine) {
    if (!this.isEmpty(effectiveFormulae)) {
      const trimmedCode = supplierInvoiceLine.tradeAgreement.code ? supplierInvoiceLine.tradeAgreement.code.trim() : null;
      if (effectiveFormulae[trimmedCode]) {
        const effectiveFormula = effectiveFormulae[trimmedCode];
        supplierInvoiceLine.tariffCode.formula = effectiveFormula;
      } else if (Object.keys(effectiveFormulae).length === 1) {
        const key = Object.keys(effectiveFormulae)[0];
        const effectiveFormula = effectiveFormulae[key];
        supplierInvoiceLine.tariffCode.formula = effectiveFormula;
      } else if (trimmedCode !== null) {
        const effectiveFormula = effectiveFormulae['STANDARD'];
        supplierInvoiceLine.tariffCode.formula = effectiveFormula;
      }
    }
  }

  private isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
