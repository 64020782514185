import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { ClearingFile } from '../../models/clearingFile';
import { LocalForwarder, TransportDocument } from '../../models/file';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'digi-app-local-forwarder',
  templateUrl: './local-forwarder.component.html',
  styleUrls: ['./local-forwarder.component.scss']
})
export class LocalForwarderComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  filteredLocalForwarders: LocalForwarder[];
  subscribe: Subscription;
  tdSubscribe: Subscription;
  @Output()
  cargoCarrierCode = new EventEmitter();

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private localAgentService: AgentService) {
  }

  ngOnInit() {

    this.subscribe = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
        }
      });

    this.tdSubscribe = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
    this.tdSubscribe.unsubscribe();
  }

  search(event) {
    this.localAgentService
      .findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(event.query, this.clearingFile.clearingInstructions[0].transportMethod.method)
      .subscribe(data => {
        console.log(data);
        this.filteredLocalForwarders = data;
      });
  }

  select(value) {
    this.selectedTransportDocument.localForwarder = value;
  }

}
