<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-g-6 ui-md-6">
      <digi-export-file-search></digi-export-file-search>
    </div>
    <p-toolbar class="toolbar">
      <div class="ui-toolbar-group-right">
        <button pButton type="button" label="Print"
                icon="fa fa-print"
                (click)="print()"></button>
      </div>
    </p-toolbar>
  </div>
</div>
<div class="ui-g-12 ui-g-nopad ui-md-12 print">
  <div class="ui-md-12 ui-g-12 title text-uppercase align-center">Commercial Invoice</div>

  <div class="ui-g ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="main-rect border-top border-right border-left">
            <div class="ui-md-12 ui-g-12 align-center">
              <img [src]="logo" id="logo">
              <div class="ui-g-12 ui-md-12 text-uppercase">
                {{companyName}}<br>
                <div [innerHTML]="companyAddress"></div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span> Invoice No. and Date</span>
          <br>
          <span style="font-weight: normal"> {{file.transportDocument.supplierInvoices[0].invoiceNumber}} {{file.transportDocument.supplierInvoices[0].invoiceDate}}</span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span> Exporter's Reference</span><br>
          <span style="font-weight: normal"> {{file.exporterReference}}</span>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span> UCR No</span><br>
          <span style="font-weight: normal"> {{file.transportDocument.supplierInvoices[0].ucr?.number}}</span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span> Buyer's Reference</span><br>
          <span style="font-weight: normal"> {{file.consigneeReference}}</span>
        </div>
      </div>

      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="ci-big-rect heading border-top border-right">
          <span> Importer / Buyer / Consignee</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.name}}</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.address?.line1}}</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.address?.line2}}</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.address?.line3}}</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.address?.line4}}</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.address?.zipCode}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="bigger-rect heading border-top border-left border-right">
          <span> Exporter</span><br>
          <span style="font-weight: normal"> {{file.exporter?.name}}</span><br>
          <span style="font-weight: normal"> {{file.exporter?.address?.line1}}</span><br>
          <span style="font-weight: normal"> {{file.exporter?.address?.line2}}</span><br>
          <span style="font-weight: normal"> {{file.exporter?.address?.line3}}</span><br>
          <span style="font-weight: normal"> {{file.exporter?.address?.line4}}</span><br>
          <span style="font-weight: normal"> {{file.exporter?.address?.zipCode}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="bigger-rect heading border-top border-right">
          <span> Notify Party</span><br>
          <input id="notifyPartyName" [ngModel]="file.exportConsignee?.name" class="ui-g-10 ui-md-10" type="text" pInputText/><br>
          <input id="notifyPartyAddrLine1" [ngModel]="file.exportConsignee?.address?.line1" class="ui-g-10 ui-md-10" type="text" pInputText/><br>
          <input id="notifyPartyAddrLine2" [ngModel]="file.exportConsignee?.address?.line2" class="ui-g-10 ui-md-10" type="text" pInputText/><br>
          <input id="notifyPartyAddrLine3" [ngModel]="file.exportConsignee?.address?.line3" class="ui-g-10 ui-md-10" type="text" pInputText/><br>
          <input id="notifyPartyAddrLine4" [ngModel]="file.exportConsignee?.address?.line4" class="ui-g-10 ui-md-10" type="text" pInputText/><br>
          <input id="notifyPartyAddrZip" [ngModel]="file.exportConsignee?.address?.zipCode" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-left border-right">
          <span> Country of Export</span><br>
          <span style="font-weight: normal"> SOUTH AFRICA</span>
        </div>
      </div>

      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-left border-right">
          <span> Country of Destination</span><br>
          <span style="font-weight: normal"> {{file.clearingInstructions[0]?.destination?.name}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="ci-big-rect heading border-top border-right">
          <span> Terms of Payment</span><br>
          <span style="font-weight: normal"> {{file.exportConsignee?.termsOfPayment}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-left border-right">
          <span> Port of Loading</span><br>
          <span style="font-weight: normal"> {{file.transportDocument?.departurePort?.name}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span *ngIf="transportMethod==='SEA'"> Vessel and Voyage nr</span><br>
          <span *ngIf="transportMethod==='SEA'" style="font-weight: normal"> {{file.transportDocument?.voyageDetails?.customsRadioCallSign?.vesselName}} {{file.transportDocument?.voyageDetails?.voyageNo}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-left border-right">
          <span> Port of Discharge</span><br>
          <span style="font-weight: normal"> {{file.transportDocument?.dischargePort?.name}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-right">
          <span> Waybill No</span><br>
          <span style="font-weight: normal"> {{file.transportDocument?.mawbNo}}</span>
        </div>
      </div>
    </div>


    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-top border-bottom border-left border-right">
          <span> Description Of Goods, No. and Kind Of Packages</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="med-rect heading border-top border-bottom border-right align-center">
          <span> Quantity</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="med-rect heading border-top border-bottom border-right align-center">
          <span> Price {{currencyCode}}</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="med-rect heading border-top border-bottom border-right align-center">
          <span> Amount {{currencyCode}}</span>
        </div>
      </div>
    </div>

<!--    Start of invLines-->
    <div class="ui-md-12 line-text ui-g-12 ui-g-nopad" *ngFor="let invoiceLine of file.transportDocument.supplierInvoices[0].lines">

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-12 ui-g-12 ui-g-nopad">
          <div class="small-rect  border-left border-right">
            <span> {{invoiceLine?.description}}</span>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="small-rect  border-right align-center">
            <span> {{getDecimalAmount(invoiceLine?.quantity)}} {{invoiceLine?.quantityType}}</span>
          </div>
        </div>
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="small-rect  border-right align-center">
            <span> {{getDecimalAmount(invoiceLine?.unitPrice)}}</span>
          </div>
        </div>
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="small-rect  border-right align-center">
            <span> {{getLineAmount(invoiceLine.quantity, invoiceLine.unitPrice)}}</span>
          </div>
        </div>
      </div>

    </div>
<!--    End of invLines-->

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="small-rect border-top border-left border-right">
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect border-top border-right align-center">
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect border-top border-right align-center">
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect line-text border-top border-right align-center">
          <span> {{getInvLinesTotal()}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="small-rect heading border-top border-left border-right align-center">
          <span> As Per ICC Incoterms&reg; 2010</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="small-rect heading border-top border-right align-center">
          <span> TERMS OF DELIVERY</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="ci-big-rect line-text border-top border-left border-right">
          <span *ngFor="let charge of file.transportDocument.supplierInvoices[0].valueBasedCharges">
            {{charge?.sundryCharge?.description}}<br>
          </span>
        </div>
      </div>
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="small-rect heading border-left border-right">
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="ci-big-rect border-right border-top">
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="ci-big-rect border-right border-top">
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="ci-big-rect line-text border-right border-top align-center">
          <span *ngFor="let charge of file.transportDocument.supplierInvoices[0].valueBasedCharges">
            {{getDecimalAmount(charge?.amount)}}<br>
          </span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect heading border-right border-top align-center">
          <span> TOTAL ({{currencyCode}})</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect border-right border-top">
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="small-rect line-text border-right border-top align-center">
          {{getGrandTotal()}}
        </div>
      </div>
    </div>

<!--    Banking details-->
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="main-rect heading border-top border-bottom border-left border-right">
          <span> Bank Information</span><br>
          <span></span><br>
          <span style="font-weight: normal"> {{companyName}}</span><br>
          <span style="font-weight: normal"> Account Number: {{bankAccountNumber}}</span><br>
          <span style="font-weight: normal"> Swift Code: {{bankSwiftCode}}</span><br>
          <span style="font-weight: normal"> Branch Code: {{bankBranchCode}}</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-right border-bottom border-top">
          <span> Name of Authorised Signatory (BLOCK LETTERS)</span><br>
          <input id="signatoryName" [ngModel]="file.reference?.channel" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="med-rect heading border-right border-bottom">
          <span> Date and Place of Issue:</span><br>
          <input id="timeAndPLaceOfIssue" [ngModel]="'JOHANNESBURG ' + file.assessmentDate" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="ci-big-rect heading border-right border-bottom">
          <span> Signature:</span><br>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="line-text">
        <span> Prepared by Digi-Freight</span>
      </div>
    </div>
  </div>
</div>
