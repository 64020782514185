import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Certificate, Country, Currency, PurchaseTerm, Supplier, SupplierInvoice, SupplierInvoiceLine} from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class SupplierInvoiceBehaviourSubject {
  private supplierInvoiceSubject = new BehaviorSubject<SupplierInvoice>(null);
  private countryOfOriginSubject = new BehaviorSubject<Country>(null);
  private currencySubject = new BehaviorSubject<Country>(null);
  private suppliersSubject = new Subject<Supplier[]>();
  private certificateSubject = new Subject<Certificate>();


  constructor() {
  }

  addSupplierInvoice(supplierInvoice: SupplierInvoice) {
    if (supplierInvoice && supplierInvoice.lines != null) {
      supplierInvoice.lines.forEach(supplierInvoiceLine => {
        if (supplierInvoiceLine.invoiceGrossValue && !supplierInvoiceLine.customsValue) {
          supplierInvoiceLine.discountValue =
            supplierInvoiceLine.invoiceGrossValue *
            (supplierInvoiceLine.discountPercent / 100);
          supplierInvoiceLine.invoiceValue =
            supplierInvoiceLine.invoiceGrossValue -
            supplierInvoiceLine.discountValue;
          supplierInvoiceLine.customsValue = supplierInvoiceLine.invoiceValue;
          if (supplierInvoiceLine.vdnPercent > 0) {
            const vdnPercentDiscountedValue =
              supplierInvoiceLine.invoiceGrossValue *
              (supplierInvoiceLine.vdnPercent / 100);
            supplierInvoiceLine.invoiceValue += vdnPercentDiscountedValue;
            supplierInvoiceLine.customsValue = supplierInvoiceLine.invoiceValue;
          }
        }
      })
    }
    this.supplierInvoiceSubject.next(supplierInvoice);
  }

  getSupplierInvoice(): Observable<SupplierInvoice> {
    return this.supplierInvoiceSubject.asObservable();
  }

  addCountry(countryOfOrigin: Country) {
    this.countryOfOriginSubject.next(countryOfOrigin);
  }

  getCountry(): Observable<Country> {
    return this.countryOfOriginSubject.asObservable();
  }

  addCurrency(currency: Currency) {
    this.currencySubject.next(currency);
  }

  getCurrency(): Observable<Currency> {
    return this.currencySubject.asObservable();
  }

  addSuppliers(suppliers: Supplier[]) {
    this.suppliersSubject.next(suppliers);
  }

  getSuppliers(): Observable<Supplier[]> {
    return this.suppliersSubject.asObservable();
  }

  addCertificate(certificate: Certificate) {
    this.certificateSubject.next(certificate);
  }

  getCertificate(): Observable<Certificate> {
    return this.certificateSubject;
  }
}
