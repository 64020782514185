<div class="ui-g-12 pt-0">
  <digi-exports-file-header-section [canAdd]="true" (add)="createNewPackage()">
  </digi-exports-file-header-section>
</div>
<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-table [columns]="packageDimsCols" [value]="exportFile.transportDocument.packageDetails" selectionMode="single"
               [(selection)]="packageDetails"
               (onRowSelect)="selectPackageDetail()"
               [paginator]="true" [rows]="5"
               [resizableColumns]="true">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pResizableColumn>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"   let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td  class="ui-resizable-column">
              <label>{{rowData.rateClass?.code}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.commodity?.code}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.units}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.actualWeight}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.volumetricWeight}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.hawbRate}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.iataRate}}</label>
            </td>
            <td  class="ui-resizable-column">
              <p-checkbox binary="true" [ngModel]="rowData.hazardous"></p-checkbox>
            </td>
            <td  class="ui-resizable-column">
              <p-checkbox binary="true" [ngModel]="rowData.refrigerated"></p-checkbox>
            </td>
            <td  class="ui-resizable-column">
              <p-checkbox binary="true" [ngModel]="rowData.asAgreed"></p-checkbox>
            </td>
            <td>
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="deletePackageDetail(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-4">
      <p-panel header="Package Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="rateClass"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="packageDetails.rateClass"
                                                  field="label"
                                                  [suggestions]="filteredRateClasses"
                                                  (completeMethod)="searchRateClass($event)"
                                                  [autoHighlight]="true" minLength="1"
                                                  [dropdown]="true"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="rateClass">Rate Class</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="commodity"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="packageDetails.commodity"
                                                  field="label"
                                                  [suggestions]="filteredCommodities"
                                                  (completeMethod)="searchCommodity($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  [dropdown]="true"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="commodity">Commodity</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         (change)="defaultChargeableWeight()"
                                         [(ngModel)]="packageDetails.actualWeight">
                                  <label>Actual Weight</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="packageDetails.chargeableWeight">
                                  <label>Chargeable Weight</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <textarea pInputTextarea [(ngModel)]="packageDetails.description" [rows]="3" [cols]="50"
                                      placeholder="Description"
                                      style="resize: none">
                            </textarea>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="packageDetails.hawbRate">
                                  <label>HAWB Rate</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="packageDetails.iataRate">
                                  <label>IATA Rate</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="ui-float-label">
                              <p-dropdown [options]="unitsOptions"
                                          [(ngModel)]="packageDetails.units"
                                          [autoWidth]="false" [style]="{'height':'25px'}">
                                <ng-template let-item pTemplate="selectedItem">
                                  <span style="vertical-align:middle; text-transform: capitalize">{{item.value}}</span>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                  <span style="vertical-align:middle; text-transform: capitalize">{{item.value}}</span>
                                </ng-template>
                              </p-dropdown>
                              <label>Kg/Lb</label>
                            </span>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <p-checkbox name="hazardous"
                              label="Hazardous"
                              [(ngModel)]="packageDetails.hazardous"
                              binary="true">
                  </p-checkbox>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <p-checkbox name="refrigerated"
                              label="Refrigerated"
                              [(ngModel)]="packageDetails.refrigerated"
                              binary="true">
                  </p-checkbox>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <p-checkbox name="asAgreed"
                              label="As Agreed"
                              [(ngModel)]="packageDetails.asAgreed"
                              binary="true">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-table [value]="packageDetails.dimensions" selectionMode="single"
               [(selection)]="selectedDimension"
               compareSelectionBy="equals"
               [paginator]="true" [rows]="5"
               [resizableColumns]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>Qty</th>
            <th>L</th>
            <th>W</th>
            <th>H</th>
            <th>Vol. Weight</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"   let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td  class="ui-resizable-column">
              <label>{{rowData.quantity}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.length}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.width}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.height}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.volumetricWeight}}</label>
            </td>
            <td>
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="deletePackageDimension(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div style="text-align:left">
            <button type="button" pButton icon="fa fa-plus" (click)="addNewDimension()" label="Add"></button>
          </div>
        </ng-template>
      </p-table>
      <p-panel header="Dimensions">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="selectedDimension.quantity" (ngModelChange)="updateVolumetricWeight()">
                                  <label>Quantity (q)</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="selectedDimension.length" (ngModelChange)="updateVolumetricWeight()">
                                  <label>Length (l)</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="selectedDimension.width" (ngModelChange)="updateVolumetricWeight()">
                                  <label>Width (w)</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="selectedDimension.height" (ngModelChange)="updateVolumetricWeight()">
                                  <label>Height (h)</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="selectedDimension.volumetricWeight" disabled>
                                  <label>Volumetric Weight (q * l * w * h)/6000</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
