import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ChargeTypeService = /** @class */ (function () {
    function ChargeTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    ChargeTypeService.prototype.findChargeTypesByCodeStartWith = function (value) {
        return this.http
            .get(this.config.apiEndpoint + "api/chargeTypes/search/findByCodeOrDescriptionIgnoreCase?codeOrDesc=" + value)
            .pipe(map(function (response) { return response['_embedded'].chargeTypes; }));
    };
    ChargeTypeService.ngInjectableDef = i0.defineInjectable({ factory: function ChargeTypeService_Factory() { return new ChargeTypeService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: ChargeTypeService, providedIn: "root" });
    return ChargeTypeService;
}());
export { ChargeTypeService };
