import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CustomOffice} from '../../../models/file';
import { CustomOfficeService } from '../../../services/custom-office.service';
import {ClearingFileService} from '../../../services/clearing-file.service';
import {Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';

@Component({
  selector: 'digi-customs-office',
  templateUrl: './customs-office.component.html',
  styleUrls: ['./customs-office.component.scss']
})
export class CustomsOfficeComponent implements OnInit, OnChanges {
  @Input() customOffice: CustomOffice;
  @Input() isRequired;
  @Output() onSelectedCustomOffice = new EventEmitter();
  filteredCustomsOffices: CustomOffice[];

  constructor(private customOfficeService: CustomOfficeService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customOffice) {
      this.select(changes.customOffice.currentValue);
    }
  }
  search(event) {
    this.customOfficeService.findAllCustomsOfficeStartWith(event.query).subscribe(data => {
      this.filteredCustomsOffices = data;
    });
  }

  select(event) {
    this.customOffice = event;
    this.onSelectedCustomOffice.emit(this.customOffice);
  }

}
