import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {Container, TransportDocument} from '../../../models/file';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ShareDataService} from '../../../services/share-data.service';


@Component({
  selector: 'digi-container-section-table',
  templateUrl: './container-section-table.component.html',
  styleUrls: ['./container-section-table.component.scss']
})
export class ContainerSectionTableComponent implements OnInit, OnDestroy {
  selectedContainer: Container;
  containerSubscription: Subscription;
  cols: any[];
  selectedTransportDocument: TransportDocument;
  transportDocumentSubscription: Subscription;
  exportsTransportDocumentSubscription: Subscription;

  constructor(private containerBehaviorSubject: ContainerBehaviorSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private shareDataService: ShareDataService) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'containerNo', header: 'Container No', width: '8%'},
      {field: 'code', header: 'Type', width: '5%'},
      {field: 'nettWeight', header: 'Gross Weight', width: '5%'},
      {field: 'freightTons', header: 'Freight Tons', width: '5%'},
      {field: 'cbm', header: 'Volume', width: '5%'},
      {field: 'name', header: 'Terminal', width: '15%'},
      {field: 'name', header: 'Depot', width: '15%'},
      {field: '', header: 'Booking Reference', width: '5%'},
      {field: '', header: '', width: '3%'},
    ];
    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
        if (td) {
          this.selectedTransportDocument = td;
        }
      });
    this.containerSubscription = this.containerBehaviorSubject.getContainer()
      .subscribe(ct => {
        if (ct) {
          this.selectedContainer = ct;
        } else {
          this.selectedContainer = this.selectedTransportDocument.containers[0];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.transportDocumentSubscription) {
      this.transportDocumentSubscription.unsubscribe();
    }

    if (this.exportsTransportDocumentSubscription) {
      this.exportsTransportDocumentSubscription.unsubscribe();
    }

    if (this.containerSubscription) {
      this.containerSubscription.unsubscribe();
    }
  }

  onRowSelect(value) {
    this.containerBehaviorSubject.addContainer(this.selectedTransportDocument.containers[value.index]);
  }

  onRowDelete(index) {
    this.selectedTransportDocument.containers.splice(index, 1);
    this.selectedTransportDocument.containers = [...this.selectedTransportDocument.containers];

    if (this.selectedTransportDocument.containers.length === 0) {
      this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
    } else {
      this.containerBehaviorSubject.addContainer(this.selectedTransportDocument.containers[index - 1]);
    }
  }
}

