import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AdditionalInfoBehaviourSubject = /** @class */ (function () {
    function AdditionalInfoBehaviourSubject() {
        this.scheduleBehaviourSubject = new BehaviorSubject([]);
    }
    AdditionalInfoBehaviourSubject.prototype.addAdditionalInfo = function (BOELines) {
        this.scheduleBehaviourSubject.next(BOELines);
    };
    AdditionalInfoBehaviourSubject.prototype.getAdditionalInfo = function () {
        return this.scheduleBehaviourSubject.asObservable();
    };
    AdditionalInfoBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function AdditionalInfoBehaviourSubject_Factory() { return new AdditionalInfoBehaviourSubject(); }, token: AdditionalInfoBehaviourSubject, providedIn: "root" });
    return AdditionalInfoBehaviourSubject;
}());
export { AdditionalInfoBehaviourSubject };
