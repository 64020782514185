import { BehaviorSubject, Observable } from 'rxjs';
import * as i0 from "@angular/core";
var FileService = /** @class */ (function () {
    function FileService() {
        this.isFileSaving = new BehaviorSubject(null);
        this.isLoadingTariffData = new BehaviorSubject(null);
        this.isLoadingPermit = new BehaviorSubject(null);
        this.isLoadingAdditionalSchedules = new BehaviorSubject(null);
    }
    FileService.prototype.setIsFileSaving = function (value) {
        this.isFileSaving.next(value);
    };
    FileService.prototype.getIsFileSaving = function () {
        return this.isFileSaving.asObservable();
    };
    FileService.prototype.setIsLoadingTariffData = function (value) {
        this.isLoadingTariffData.next(value);
    };
    FileService.prototype.getIsLoadingTariffData = function () {
        return this.isLoadingTariffData.asObservable();
    };
    FileService.prototype.setIsLoadingPermit = function (value) {
        this.isLoadingPermit.next(value);
    };
    FileService.prototype.getIsLoadingPermit = function () {
        return this.isLoadingPermit.asObservable();
    };
    FileService.prototype.setIsLoadingAdditionalSchedules = function (value) {
        this.isLoadingAdditionalSchedules.next(value);
    };
    FileService.prototype.getIsLoadingAdditionalSchedules = function () {
        return this.isLoadingAdditionalSchedules.asObservable();
    };
    FileService.ngInjectableDef = i0.defineInjectable({ factory: function FileService_Factory() { return new FileService(); }, token: FileService, providedIn: "root" });
    return FileService;
}());
export { FileService };
