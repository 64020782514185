<div class="ui-g">
  <div class="ui-g-12">
    <p-table [columns]="cols" [value]="tariffCode?.formulae" columnResizeMode="expand"
             [scrollable]="true"
             scrollHeight="300px">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field.id">
            {{col.header}}
            <p-sortIcon [field]="col.field.id" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tariffFormula let-rowIndex="rowIndex">
        <tr [pSelectableRow]="tariffFormula" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
          <td>{{tariffFormula?.id?.tradeGroupCode}}</td>
          <td>{{tariffFormula?.uomCode}}</td>
          <td>{{tariffFormula?.referenceCode}}</td>
          <td>{{tariffFormula?.id?.formulaEffDate}}</td>
          <td>{{tariffFormula?.formulaEffUntilDate}}</td>
          <td>{{tariffFormula?.filledFormulaString1}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
