import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ClearingInstructionBehaviorSubject } from '../../../../../subjects/clearingInstruction-behavior-subject';
import { ClearingInstruction, RebateUser } from '../../../models/file';
import { RebateUserService } from '../../../services/rebate-user.service';
import { ShareDataService } from '../../../services/share-data.service';


@Component({
  selector: 'digi-rebate-user',
  templateUrl: './rebate-user.component.html',
  styleUrls: ['./rebate-user.component.scss']
})
export class RebateUserComponent implements OnInit, OnDestroy {
  filteredRebaseUsers = [];
  clearingInstruction: ClearingInstruction;
  rebateUserAddresses = [];
  subscription: Subscription;
  rebateUsers: RebateUser[];

  constructor(private shareDataService: ShareDataService,
              private rebateUserService: RebateUserService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject) {
  }

  ngOnInit() {
    const instructionObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    const rebateUserObservable = this.clearingInstructionBehaviorSubject.getRebateUsers();
    this.subscription = combineLatest(instructionObservable, rebateUserObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingInstruction = tuple[0];
            if (this.clearingInstruction) {
              this.clearingInstruction.rebateUser = Object.assign({}, this.clearingInstruction.rebateUser);
            }
          }
          if (tuple[1]) {
            this.rebateUsers = tuple[1];
          }
        }),
      switchMap((tuple: any[]) => {
        if (this.clearingInstruction && this.rebateUsers) {
          return this.rebateUserService.findRebateUserFromClearingInstructionAndRebateUsers(this.clearingInstruction, this.rebateUsers);
        }
        return EMPTY;
      })
    ).subscribe(rebateUser => {
      this.rebateUserAddresses = rebateUser[0].addresses;
    });
  }

  search(event) {
    this.filteredRebaseUsers = [];
    for (let i = 0; i < this.rebateUsers.length; i++) {
      const rebateUser: RebateUser = this.rebateUsers[i];
      if (
        rebateUser.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
        rebateUser.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0

      ) {
        this.filteredRebaseUsers.push(rebateUser);
      }
    }
  }

  selectRebateUser(rebateUser) {
    this.rebateUserService.findRebateUserFromClearingInstruction(this.clearingInstruction, rebateUser)
      .subscribe(result => {
        this.rebateUserAddresses = result[0].addresses;
      });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
