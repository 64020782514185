import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationService} from 'src/app/digi-common/services/notification.service';
import {Address, BusinessEntity, Role} from '../models/business-entity';
import {RoleMetaData} from '../models/role-metadata.';
import {BusinessEntityService} from '../services/business-entity.service';
import {ValidationResult} from '../../digi-common/services/validation.service';

@Component({
  selector: 'app-business-entity-toolbar',
  templateUrl: './business-entity-toolbar.component.html',
  styles: [`
      :host ::ng-deep button {
          margin-right: .25em;
      }

      :host ::ng-deep .ui-dropdown {
          margin-right: .25em;
      }

      :host ::ng-deep .ui-float-label {
          margin-right: .25em;
          display: inline;
      }

      :host ::ng-deep .ui-float-label label {
          color: #FFFFFF;
      }

      :host ::ng-deep .ui-inputtext {
          color: #FFFFFF;
      }

  `]
})
export class BusinessEntityToolbarComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() businessEntityStepActive: boolean;
  @Input() activeRoleType: RoleMetaData;
  @Output() selectBusinessEntity = new EventEmitter();
  address: Address;

  filteredBusinessEntities = [];

  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService) {
  }

  ngOnInit() {
  }

  selectedBusinessEntityFromDropDown(value) {
    this.selectBusinessEntity.emit(value);
  }

  searchBusinessEntity(event) {
    this.businessEntityService.findBusinessEntityStartsWith(event.query).subscribe(data => {
      this.filteredBusinessEntities = data;
    });
  }

  searchImporterEntityCustomsCode(event) {
      this.businessEntityService.findBusinessEntityImporterCode(event.query).subscribe(data => {
        this.filteredBusinessEntities = data;
      });
    }

  newBusinessEntity() {
    this.businessEntity = new BusinessEntity();
    this.selectBusinessEntity.emit(this.businessEntity);
  }

  saveBusinessEntity() {
    let countBlankBusinessEntityAddresses = 0;
    let roleAddressCount = 0;
    let roleNominationsCount = 0;
    let roleWithBlankRoleAddresses = [];
    let roleWithBlankRoleNominations = [];

    let roleWithBlankRoleNomination = new Role();
    if (this.businessEntity.roles) {
      //search for role nominations inside business entity roles
      this.businessEntity.roles.find(role => {
        if (role.roleNominations) {
          roleWithBlankRoleNominations = role.roleNominations;
          return true;
        } else {
          return false;
        }
      });

      //search for role addresses inside business entity roles
      this.businessEntity.roles.find(role => {
        if (role.roleAddresses) {
          roleWithBlankRoleAddresses = role.roleAddresses;
          return true;
        } else {
          return false;
        }
      });
    }

    //find blank role nominations
    roleWithBlankRoleNominations.find(roleNomination => {
      if (!roleNomination.businessEntityCode ) {
        //if you have a blank role nomination then add to count
        roleNominationsCount = roleNominationsCount + 1;
        return true;
      } else {
        return false;
      }
    });

    //find blank role addresses
    roleWithBlankRoleAddresses.find(addressNomination => {
      if (!addressNomination.address && addressNomination.addressType.id) {
        //if you have a blank role address then add to count
        roleAddressCount = roleAddressCount + 1;
        return true;
      } else {
        return false;
      }
    });
    function isAlphanumeric(input) {
      const regex = /^[a-z0-9() ]+$/i;
      return regex.test(input);
    }
    function isNumeric(input) {
      const regex = /^[0-9]+$/;
      return regex.test(input);
    }

    if (this.businessEntity.addresses) {
      for (this.address of this.businessEntity.addresses) {
        if (!this.address.line1) {
          countBlankBusinessEntityAddresses = countBlankBusinessEntityAddresses + 1;
        }
      }
    }

    if (this.businessEntity.name && isAlphanumeric(this.businessEntity.name)) {
      if (this.businessEntity.registeredName && isAlphanumeric(this.businessEntity.registeredName)) {
        if (this.businessEntity.addresses) {
          for (this.address of this.businessEntity.addresses) {
            if (!isAlphanumeric(this.address.line1) ||
              !isAlphanumeric(this.address.line2) ||
              !isAlphanumeric(this.address.line3) ||
              !isAlphanumeric(this.address.line4) ||
              (this.address.zipCode && !isNumeric(this.address.zipCode))) {
              // tslint:disable-next-line:max-line-length
              this.addFailure(['Cannot save invalid Address, Please enter valid Address with Alpha and Numeric values and exclude Foreign Characters']);
              return;
            }
          }
        }
        if (countBlankBusinessEntityAddresses < 1) {
          if (roleNominationsCount < 1) {
            if (roleAddressCount < 1) {
              const validation = this.validateRoles();
              if (validation.isValid) {
                this.businessEntityService.saveBusinessEntity(this.businessEntity)
                  .subscribe(data => {
                      this.businessEntity = data;
                      this.businessEntityConfirmation();
                    },
                    error => {
                    },
                    () => this.selectBusinessEntity.emit(this.businessEntity)
                  );
              } else {
                this.addFailure(validation.messages);
              }
            } else {
              this.addFailure(['Cannot save blank Role Address, Please enter Address']);
            }
          } else {
            this.addFailure(['Cannot save blank Nomination, Please enter Nomination']);
          }
        } else {
          this.addFailure(['Cannot save blank Address, Please enter Address']);
        }
      } else {
        this.addFailure(['Cannot save empty registered name, Please enter Registered Name and exclude foreign characters']);
      }
    } else {
      this.addFailure(['Cannot save empty business entity name, Please enter Business Entity Name and exclude foreign characters']);
    }
  }

  businessEntityConfirmation() {
    this.messageService.successNotify('Business Entity Saved');
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  validateRoles(): ValidationResult {
    const validation: ValidationResult = {
      isValid: true,
      messages: []
    };
    if (this.businessEntity.roles) {
      this.businessEntity.roles.forEach(role => {
        switch (role.roleType) {
          case 12:
            if (this.activeRoleType && !role.registrationType) {
              validation.isValid = false;
              validation.messages.push('Cannot save Exporter, Please enter Registration Type');
            }

            if (this.activeRoleType && !role.country) {
              validation.isValid = false;
              validation.messages.push('Cannot save Exporter, Please enter Default Country');
            }

            if (this.activeRoleType && !role.currency) {
              validation.isValid = false;
              validation.messages.push('Cannot save Exporter, Please enter Default Currency');
            }
            break;
          case 2:
            if (this.activeRoleType && !role.country) {
              validation.isValid = false;
              validation.messages.push('Cannot save Export Consignee, Please enter Default Country');
            }
            break;
          case 5:
            if (this.activeRoleType && !role.country) {
              validation.isValid = false;
              validation.messages.push('Cannot save Overseas Agent, Please enter Country');
            }
            break;
          default:
            validation.isValid = true;
            break;
        }
      });
    }
    return validation;
  }
}
