import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Container, ContainerRunningTotals, ExportTransportDocument, TransportDocument} from '../../../models/file';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';


@Component({
  selector: 'digi-running-total-section-container',
  templateUrl: './running-total-section-container.component.html',
  styleUrls: ['./running-total-section-container.component.scss']
})
export class RunningTotalSectionContainerComponent implements OnInit, OnDestroy {
  selectedTransportDocument: TransportDocument|ExportTransportDocument;
  transportDocumentSubscription: Subscription;
  selectedContainer: Container;
  containerSubscription: Subscription;
  exportsTransportDocumentSubscription: Subscription;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument|ExportTransportDocument) => {
        if (td) {
          this.selectedTransportDocument = td;
          if (this.containerSubscription) {
            this.containerSubscription.unsubscribe();
          }
          this.containerSubscription = this.containerBehaviorSubject.getContainer()
            .subscribe(ct => {
              this.selectedContainer = ct;
              this.selectedTransportDocument.containerRunningTotals = new ContainerRunningTotals();
              this.selectedTransportDocument.containerRunningTotals.capturedValue = this.selectedTransportDocument.containers? this.selectedTransportDocument.containers.length : 0;
              this.selectedTransportDocument.containerRunningTotals.remainingValue =
                this.selectedTransportDocument.measures.noOfContainers -
                (this.selectedTransportDocument.containerRunningTotals.capturedValue ?
                  this.selectedTransportDocument.containerRunningTotals.capturedValue : 0);

              this.selectedTransportDocument.containerRunningTotals.capturedGrossWeight = this.selectedTransportDocument.containers? Math.round(this.selectedTransportDocument.containers
                .map(c => c.solas.vgm)
                .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100 : 0;

              this.selectedTransportDocument.containerRunningTotals.remainingGrossWeight = Math.round((this.selectedTransportDocument.measures.grossWeight -
                (this.selectedTransportDocument.containerRunningTotals.capturedGrossWeight ?
                  this.selectedTransportDocument.containerRunningTotals.capturedGrossWeight : 0)) * 100) / 100;

              this.selectedTransportDocument.containerRunningTotals.capturedVolume = this.selectedTransportDocument.containers? Math.round(this.selectedTransportDocument.containers
                .map(c => c.cbm)
                .reduce((sum, current) => current ? +sum + +current : +sum, 0) * 100) / 100 : 0;

              this.selectedTransportDocument.containerRunningTotals.remainingVolume = Math.round((this.selectedTransportDocument.measures.volume -
                (this.selectedTransportDocument.containerRunningTotals.capturedVolume ?
                  this.selectedTransportDocument.containerRunningTotals.capturedVolume : 0)) * 100) / 100;

              this.selectedTransportDocument.containerRunningTotals.capturedNoOfPackages = this.selectedTransportDocument.containers? this.selectedTransportDocument.containers
                .map(c => c.noOfPackages)
                .reduce((sum, current) => current ? +sum + +current : +sum, 0) : 0;

              this.selectedTransportDocument.containerRunningTotals.remainingNoOfPackages =
                this.selectedTransportDocument.measures.numberOfPackages -
                (this.selectedTransportDocument.containerRunningTotals.capturedNoOfPackages ?
                  this.selectedTransportDocument.containerRunningTotals.capturedNoOfPackages : 0);

            });
        }
      });
  }

  ngOnDestroy(): void {
    this.transportDocumentSubscription.unsubscribe();
    this.containerSubscription.unsubscribe();
  }
}
