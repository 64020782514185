import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DataScrollerModule} from 'primeng/datascroller';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {ListboxModule} from 'primeng/listbox';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {PickListModule} from 'primeng/picklist';
import {CardModule, TabMenuModule} from 'primeng/primeng';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {OCEAN_ROUTES} from './digi-ocean.routes';
import {OceanDashboardComponent} from './ocean-dashboard/ocean-dashboard.component';
import {OceanContextNavigationComponent} from './ocean-context-navigation/ocean-context-navigation.component';
import {RegistrationComponent} from './ocean-registration/registration/registration.component';
import {PlanningComponent} from './ocean-registration/planning/planning.component';
import {DigiCommonModule} from '../digi-common/digi-common.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HawbComponent} from '../digi-common/components/road-ocean-components/hawb/hawb.component';
import {DigiHawbShipmentDetailSectionComponent} from './components/digi-hawb-shipment-detail-section/digi-hawb-shipment-detail-section.component';
import {GeneralWeightAndMeasuresComponent} from './components/general-weight-and-measures/general-weight-and-measures.component';
import {InputMaskModule} from 'primeng/inputmask';
import {SeaExportRegistrationComponent} from './export/ocean-registration/registration/sea-export-registration.component';
import { ReportComponent } from '../digi-common/components/report/report.component';
import { ReportFileComponent } from '../digi-common/components/report/report-file/report-file.component';
import { PaymentFileComponent } from '../digi-common/components/report/payment-file/payment-file.component';
import { ClearingInstructionFileComponent } from '../digi-common/components/report/clearing-instruction-file/clearing-instruction-file.component';
import { ExportsOceanDashboardComponent } from './ocean-dashboard/exports-ocean-dashboard/exports-ocean-dashboard.component';
import { SeaForwardingDashboardComponent } from './ocean-dashboard/sea-forwarding-dashboard/sea-forwarding-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    StepsModule,
    ToastModule,
    TabViewModule,
    PanelModule,
    DigiCommonModule,
    AccordionModule,
    TreeModule,
    TooltipModule,
    ToolbarModule,
    TableModule,
    CardModule,
    ScrollPanelModule,
    TabMenuModule,
    PickListModule,
    OverlayPanelModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    ListboxModule,
    KeyFilterModule,
    InputTextareaModule,
    FileUploadModule,
    InputTextModule,
    FieldsetModule,
    DropdownModule,
    DialogModule,
    DataViewModule,
    DataScrollerModule,
    CheckboxModule,
    CalendarModule,
    ButtonModule,
    AutoCompleteModule,
    InputMaskModule,
    ReactiveFormsModule,
    RouterModule.forChild(OCEAN_ROUTES)
  ],
  declarations: [
    RegistrationComponent,
    PlanningComponent,
    OceanDashboardComponent,
    OceanContextNavigationComponent,
    HawbComponent,
    DigiHawbShipmentDetailSectionComponent,
    GeneralWeightAndMeasuresComponent,
    SeaExportRegistrationComponent,
    ReportComponent,
    ReportFileComponent,
    PaymentFileComponent,
    ClearingInstructionFileComponent,
    ExportsOceanDashboardComponent,
    SeaForwardingDashboardComponent
  ]
})
export class DigiOceanModule {
}
