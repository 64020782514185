import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WaybillNumber, WaybillReservation} from '../../../digi-common/models/AirWaybill';
import {Airline, Commodity, PortDetail, RouteDetails} from '../../../digi-common/models/file';
import {Exporter} from '../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';
import {CommodityService} from '../../../digi-common/services/commodity.service';
import {TransportService} from '../../services/transport.service';

@Component({
  selector: 'digi-awb-reservation-modal',
  templateUrl: './awb-reservation-modal.component.html',
  styleUrls: ['./awb-reservation-modal.component.scss']
})
export class AwbReservationModalComponent implements OnInit, OnChanges {
  @Input('display') display;
  @Output() displayChange = new EventEmitter();
  @Input() reservation: WaybillReservation;
  @Input() airline: Airline;
  @Input() poolDate: string;
  filteredExporters: any[];
  filteredCommodities: any[];
  filteredAirlines: any[];
  @Input() mawbNo: string;

  constructor(
    private businessEntityService: BusinessEntityService,
    private commodityService: CommodityService,
    private transportService: TransportService
    ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservation && changes.reservation.currentValue) {
      this.reservation = changes.reservation.currentValue;

      if (!this.reservation.routeDetails1) {
        this.reservation.routeDetails1 = new RouteDetails();
        this.reservation.routeDetails1.departurePort = new PortDetail();
      }

      if (!this.reservation.routeDetails2) {
        this.reservation.routeDetails2 = new RouteDetails();
        this.reservation.routeDetails2.departurePort = new PortDetail();
        this.reservation.routeDetails2.airline = new Airline();
      }

      if (!this.reservation.routeDetails3) {
        this.reservation.routeDetails3 = new RouteDetails();
        this.reservation.routeDetails3.departurePort = new PortDetail();
        this.reservation.routeDetails3.airline = new Airline();
      }
    } else {
      this.reservation = new WaybillReservation();
      this.reservation.routeDetails1 = new RouteDetails();
      this.reservation.routeDetails1.departurePort = new PortDetail();
      this.reservation.routeDetails2 = new RouteDetails();
      this.reservation.routeDetails2.departurePort = new PortDetail();
      this.reservation.routeDetails2.airline = new Airline();
      this.reservation.routeDetails3 = new RouteDetails();
      this.reservation.routeDetails3.departurePort = new PortDetail();
      this.reservation.routeDetails3.airline = new Airline();
    }

  }

  hide() {
    this.displayChange.emit(false);
  }

  search(event: any) {
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, 12).subscribe(
      data => this.filteredExporters = data
    );
  }

  searchCommodity(event: any) {
    this.commodityService.findByCode(event.query).subscribe(
      (data: Commodity[]) => this.filteredCommodities = data
    );
  }

  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectRouteDetail2Airline(value: Airline) {
    this.reservation.routeDetails2.airline = value;
  }
  selectRouteDetail3Airline(value: Airline) {
    this.reservation.routeDetails3.airline = value;
  }
}
