import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Remover} from '../../models/business-entity';

@Component({
  selector: 'digi-app-remover-role',
  templateUrl: './remover-role.component.html',
  styleUrls: ['./remover-role.component.scss']
})
export class RemoverRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() remover: Remover;

  constructor() {
  }

  ngOnInit() {
  }

}
