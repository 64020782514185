import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RailDashboardComponent} from './rail-dashboard/rail-dashboard.component';
import {RegistrationComponent} from './rail-registration/registration.component';
import {RouterModule} from '@angular/router';
import {RAIL_ROUTES} from './digi-rail.routes';
import { ExportsRailDashboardComponent } from './rail-dashboard/export-rail-dashboard/exports-rail-dashboard.component';
import {DigiCommonModule} from '../digi-common/digi-common.module';
import { RegisterComponent } from './rail-registration/register/register.component';
import { PlanningComponent } from './rail-registration/planning/planning.component';
import { DocumentsComponent } from './rail-registration/documents/documents.component';
import {PickListModule} from 'primeng/picklist';
import {FieldsetModule} from 'primeng/fieldset';
import {InputMaskModule, PanelModule, TabViewModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {DigiAirModule} from '../digi-air/digi-air.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(RAIL_ROUTES),
    DigiCommonModule,
    PickListModule,
    FieldsetModule,
    TabViewModule,
    PanelModule,
    InputMaskModule,
    FormsModule,
    DigiAirModule,
  ],
  declarations: [RailDashboardComponent, RegistrationComponent, ExportsRailDashboardComponent, RegisterComponent, PlanningComponent, DocumentsComponent]
})
export class DigiRailModule { }
