import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {Debtor} from '../../../models/file';
import {ClearingFile} from '../../../models/clearingFile';
import {ShareDataService} from '../../../services/share-data.service';
import {DebtorService} from '../../../services/debtor.service';
import {switchMap, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-importer-debtors',
  templateUrl: './importer-debtors.component.html',
  styleUrls: ['./importer-debtors.component.scss']
})
export class ImporterDebtorsComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  filteredDebtors = [];
  debtorAddresses = [];
  subscription: Subscription;
  debtorsSubscription: Subscription;
  debtors: Debtor[];

  constructor(
    private shareDataService: ShareDataService,
    private debtorService: DebtorService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.subscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(
      cf => {
        this.clearingFile = cf;
        this.clearingFile.debtor = Object.assign({}, this.clearingFile.debtor);
        if (!this.debtorsSubscription) {
          this.debtorsSubscription = this.clearingFileBehaviourSubject.getDebtors().pipe(
            tap(debtors => {
              this.debtors = debtors;
            }),
            switchMap(debtors =>
              this.debtorService.findDebtorFromClearingFileAndDebtors(this.clearingFile, this.debtors)
            )
          ).subscribe(debtor => {
            this.debtorAddresses = debtor[0].addresses;
          });
        }
      }
    );
  }

  search(event) {
    this.filteredDebtors = [];
    for (let i = 0; i < this.debtors.length; i++) {
      const importerDebtor: Debtor = this.debtors[i];
      if (
        importerDebtor.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
        importerDebtor.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      ) {
        this.filteredDebtors.push(importerDebtor);
      }
    }
  }

  selectDebtor(debtor) {
    this.debtorService.findDebtorFromClearingFile(this.clearingFile, debtor)
      .subscribe(result => {
        this.debtorAddresses = result[0].addresses;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.debtorsSubscription.unsubscribe();
  }
}
