<p-panel>
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input value="{{businessEntity?.code}}" type="text" pInputText readonly>
            <label>Remover Code</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" value="{{businessEntity?.name}}" pInputText readonly>
            <label>Remover Name</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-8">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" [(ngModel)]="remover.customsCode" pInputText>
            <label>Customs Code</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-8">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" [(ngModel)]="remover.bondValue" pInputText>
            <label>Bond Value</label>
          </span>
      </div>
    </div>
  </div>
</p-panel>
