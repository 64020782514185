import { of } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClearingFileBehaviourSubject } from '../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import { ShareDataService } from './share-data.service';
import { DebtorService } from './debtor.service';
import { TransportMethodsService } from './transport-methods.service';
import { ClearingInstructionsService } from './clearing-instructions.service';
import { RebateUserService } from './rebate-user.service';
import { BillOfEntryBehaviourSubject } from '../../../subjects/billOfEntry-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../subjects/transport-document-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { AdditionalInfoBehaviourSubject } from '../../../subjects/addittional-info-behaviour-subject';
import { ContainerBehaviorSubject } from '../../../subjects/container-behavior-subject';
import { BillOfEntryLineBehaviourSubject } from '../../../subjects/billOfEntryLine-behaviour-subject';
import { BillOfEntryService } from './bill-of-entry.service';
import { UniqueConsignmentBehaviourSubject } from '../../../subjects/unique-consignment-reference-subject';
import pako from 'pako';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "../../../subjects/clearingfile-behaviour-subject";
import * as i4 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i5 from "./share-data.service";
import * as i6 from "./debtor.service";
import * as i7 from "./transport-methods.service";
import * as i8 from "./clearing-instructions.service";
import * as i9 from "./rebate-user.service";
import * as i10 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i11 from "../../../subjects/transport-document-behaviour-subject";
import * as i12 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i13 from "../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i14 from "../../../subjects/addittional-info-behaviour-subject";
import * as i15 from "../../../subjects/container-behavior-subject";
import * as i16 from "../../../subjects/billOfEntryLine-behaviour-subject";
import * as i17 from "./bill-of-entry.service";
import * as i18 from "../../../subjects/unique-consignment-reference-subject";
var ClearingFileService = /** @class */ (function () {
    function ClearingFileService(http, config, clearingFileBehaviourSubject, clearingInstructionBehaviorSubject, shareDataService, debtorService, transportMethodsService, clearingInstructionService, rebateUserService, billOfEntryBehaviourSubject, transportDocumentBehaviourSubject, supplierInvoiceBehaviourSubject, supplierInvoiceLineBehaviourSubjectComponent, additionalInfoBehaviourSubject, containerBehaviorSubject, billOfEntryLineBehaviourSubject, billOfEntryService, uniqueConsignmentBehaviourSubject) {
        this.http = http;
        this.config = config;
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.shareDataService = shareDataService;
        this.debtorService = debtorService;
        this.transportMethodsService = transportMethodsService;
        this.clearingInstructionService = clearingInstructionService;
        this.rebateUserService = rebateUserService;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.supplierInvoiceLineBehaviourSubjectComponent = supplierInvoiceLineBehaviourSubjectComponent;
        this.additionalInfoBehaviourSubject = additionalInfoBehaviourSubject;
        this.containerBehaviorSubject = containerBehaviorSubject;
        this.billOfEntryLineBehaviourSubject = billOfEntryLineBehaviourSubject;
        this.billOfEntryService = billOfEntryService;
        this.uniqueConsignmentBehaviourSubject = uniqueConsignmentBehaviourSubject;
    }
    ClearingFileService.prototype.sendClearingFile = function (clearingFile) {
        var headers;
        var body;
        if (this.config.zipRequests) {
            headers = new HttpHeaders({
                'Content-Type': 'application/octet-stream',
                'Content-Encoding': 'gzip'
            });
            headers.append('Content-Encoding', 'gzip');
            headers.set('Content-Type', 'application/octet-stream');
            body = pako.gzip(JSON.stringify(clearingFile)).buffer;
        }
        else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            body = JSON.stringify(clearingFile);
        }
        if (clearingFile._links && clearingFile._links.self) {
            return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id, body, { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/clearingFiles', body, { headers: headers });
        }
    };
    ClearingFileService.prototype.loadClearingFile = function (clearingFileNumber, transportMethod, branch, isQuotation) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        if (branch) {
            if (isQuotation) {
                return this.http
                    .get(this.config.filesApiEndpoint + "api/clearingFiles/search/findByClearingQuotationFileNumberOrReferenceFileNoAndBranchCode?number=" + clearingFileNumber + "&branchCode=" + branch + "&transportMethod=" + transportMethod)
                    .pipe(map(function (response) { return response['_embedded'].clearingFiles; }));
            }
            else {
                return this.http
                    .get(this.config.filesApiEndpoint + "api/clearingFiles/search/findByClearingFileNumberOrReferenceFileNoAndBranchCode?number=" + clearingFileNumber + "&branchCode=" + branch + "&transportMethod=" + transportMethod)
                    .pipe(map(function (response) { return response['_embedded'].clearingFiles; }));
            }
        }
        else {
            if (isQuotation) {
                return this.http
                    .get(this.config.filesApiEndpoint + "api/clearingFiles/search/clearingQuotationFileNumberOrReferenceFileNoStartsWith?number=" + clearingFileNumber + "&transportMethod=" + transportMethod)
                    .pipe(map(function (response) { return response['_embedded'].clearingFiles; }));
            }
            else {
                return this.http
                    .get(this.config.filesApiEndpoint + "api/clearingFiles/search/clearingFileNumberOrReferenceFileNoStartsWith?number=" + clearingFileNumber + "&transportMethod=" + transportMethod)
                    .pipe(map(function (response) { return response['_embedded'].clearingFiles; }));
            }
        }
    };
    ClearingFileService.prototype.loadAllCorrectionsIncludingOriginalClearingFile = function (clearingFileNumber, transportMethod) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/corrections/search/clearingFileNumberOrReferenceFileNoStartsWith?number=" + clearingFileNumber + "&transportMethod=" + transportMethod)
            .pipe(map(function (response) { return response['_embedded'].corrections; }));
    };
    ClearingFileService.prototype.findAllSequences = function (clearingFileNumber) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/corrections/search/findSequencesByClearingFileNumber?clearingFileNumber="
            + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
            .pipe(map(function (response) { return response['_embedded'].corrections; }));
    };
    ClearingFileService.prototype.findByClearingFileNumberAndSequence = function (clearingFileNumber, sequence) {
        if (clearingFileNumber === '' && sequence === null) {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/corrections/search/clearingFileNumberAndSequence?clearingFileNumber=" + clearingFileNumber
            + '&sequence=' + sequence)
            .pipe(map(function (response) { return response['_embedded'].corrections; }));
    };
    ClearingFileService.prototype.createNewFile = function (transportMethod, isQuotation) {
        this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile(transportMethod, isQuotation));
        this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
        this.additionalInfoBehaviourSubject.addAdditionalInfo([]);
        this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
        this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        var transportDocument = this.shareDataService.addTransportDocument('imports');
        this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
        var supplierInvoice = this.shareDataService.addSupplierInvoice(transportDocument);
        this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
        var supplierInvoiceLine = this.shareDataService.addSupplierInvoiceLine(supplierInvoice, undefined);
        this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
        this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
        this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);
        this.supplierInvoiceBehaviourSubject.addCountry(null);
        this.supplierInvoiceBehaviourSubject.addCurrency(null);
        this.supplierInvoiceLineBehaviourSubjectComponent.addCountry(null);
        this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
    };
    ClearingFileService.prototype.selectClearingFile = function (value) {
        var _this = this;
        this.createNewFile();
        this.clearingFileBehaviourSubject.addClearingFile(value);
        if (value.transportDocuments.length > 0) {
            this.transportDocumentBehaviourSubject.addTransportDocument(value.transportDocuments[0]);
            if (value.transportDocuments[0].containers.length > 0) {
                this.containerBehaviorSubject.addContainer(value.transportDocuments[0].containers[0]);
            }
            if (value.transportDocuments[0].supplierInvoices.length > 0) {
                this.supplierInvoiceBehaviourSubject.addSupplierInvoice(value.transportDocuments[0].supplierInvoices[0]);
                if (value.transportDocuments[0].supplierInvoices[0].lines.length > 0) {
                    this.supplierInvoiceLineBehaviourSubjectComponent
                        .addSupplierInvoiceLine(value.transportDocuments[0].supplierInvoices[0].lines[0]);
                }
            }
        }
        if (value.clearingInstructions.length) {
            this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
        }
        this.billOfEntryService
            .findBillOfEntryByClearingFile_id(value.id)
            .subscribe(function (boes) {
            if (boes.length > 0) {
                _this.billOfEntryBehaviourSubject.addBillOfEntry(boes[0]);
                _this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(boes[0].lines[0]);
                _this.additionalInfoBehaviourSubject.addAdditionalInfo(boes[0].lines[0].additionalInformation);
                _this.billOfEntryBehaviourSubject.addBillsOfEntry(boes);
            }
        });
    };
    ClearingFileService.prototype.findClearingFileNumbersByInvoiceNo = function (invoiceNumber, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/clearingFiles/search/findClearingFileNumbersByInvoiceNo?clearingFileNumber=" + fileNumber + "&invoiceNo=" + invoiceNumber)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ClearingFileService.prototype.findClearingFileNumbersByHawbNo = function (hawbNo, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/clearingFiles/search/findClearingFileNumbersByHawbNo?clearingFileNumber=" + fileNumber + "&hawbNo=" + hawbNo)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ClearingFileService.prototype.findClearingFileNumbersByMawbNo = function (mawbNo, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/clearingFiles/search/findClearingFileNumbersByMawbNo?clearingFileNumber=" + fileNumber + "&mawbNo=" + mawbNo)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ClearingFileService.prototype.uploadFromSpreadsheet = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.put(this.config.filesApiEndpoint + ("api/clearingFiles/" + id + "/vehiclesUpload"), formData);
    };
    ClearingFileService.prototype.createInvoiceLines = function (partDetails, clearingFileId, mawbNo, invoiceNumber) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + "api/clearingFiles/" + clearingFileId + "/supplierInvoices/upload?mawbNo=" + mawbNo + "&invoiceNo=" + invoiceNumber, JSON.stringify(partDetails), { headers: headers });
    };
    ClearingFileService.prototype.createNewFileFromForwardingFileHousebill = function (housebill, clearingFile) {
        clearingFile.importer = housebill.importer;
        clearingFile.customOffice = housebill.customsOffice;
        var transportDocument = this.shareDataService.addTransportDocument('imports');
        Object.keys(housebill).forEach(function (key) {
            if (housebill[key]) {
                transportDocument[key] = housebill[key];
            }
        });
        clearingFile.transportDocuments = [transportDocument];
    };
    ClearingFileService.ngInjectableDef = i0.defineInjectable({ factory: function ClearingFileService_Factory() { return new ClearingFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ClearingFileBehaviourSubject), i0.inject(i4.ClearingInstructionBehaviorSubject), i0.inject(i5.ShareDataService), i0.inject(i6.DebtorService), i0.inject(i7.TransportMethodsService), i0.inject(i8.ClearingInstructionsService), i0.inject(i9.RebateUserService), i0.inject(i10.BillOfEntryBehaviourSubject), i0.inject(i11.TransportDocumentBehaviourSubject), i0.inject(i12.SupplierInvoiceBehaviourSubject), i0.inject(i13.SupplierInvoiceLineBehaviourSubject), i0.inject(i14.AdditionalInfoBehaviourSubject), i0.inject(i15.ContainerBehaviorSubject), i0.inject(i16.BillOfEntryLineBehaviourSubject), i0.inject(i17.BillOfEntryService), i0.inject(i18.UniqueConsignmentBehaviourSubject)); }, token: ClearingFileService, providedIn: "root" });
    return ClearingFileService;
}());
export { ClearingFileService };
