import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CustomsTraderType } from '../../../digi-business-entity/models/business-entity';
import { CustomsTraderTypeService } from '../../../digi-business-entity/services/customs-trader-type.service';

@Component({
  selector: 'digi-customs-trader-type',
  templateUrl: './customs-trader-type.component.html',
  styleUrls: ['./customs-trader-type.component.scss']
})
export class CustomsTraderTypeComponent implements OnInit, OnChanges {
  @Input() customsTraderType: CustomsTraderType;
  @Output() onSelect = new EventEmitter;
  selectedTradeType: CustomsTraderType;
  filteredTradeTypes: CustomsTraderType[];


  constructor(private customsTraderTypeService: CustomsTraderTypeService) { }

  ngOnInit() {
    this.selectedTradeType = this.customsTraderType;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTradeType = this.customsTraderType;
  }

  select(value) {
    this.onSelect.emit(value);
  }

  search(event) {
    this.customsTraderTypeService.findRegistrationTypeStartsWith(event.query).subscribe(data => {
      this.filteredTradeTypes = data;
    });
  }

}
