<div class="ui-g form-group" *ngIf="clearingInstruction">
  <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="warehouse"
                            [(ngModel)]="clearingInstruction.warehouse"
                            field="label"
                            [suggestions]="filteredWarehouses"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectWarehouse($event)"
                            minLength="3"
                            [required]="isRequired()"
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}: {{option.name}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="warehouse">Warehouse</label>
            </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
      <div *ngIf="isRequired()" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
  </div>
  <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
             <span class="ui-float-label">
                <span class="md-inputfield">
                   <input id="customsWarehouseNumber"
                          [ngModel]="clearingInstruction.warehouse.customsWarehouseNumber" pInputText disabled>
                  <label for="customsWarehouseNumber">Customs Warehouse Number</label>
                </span>
             </span>
      </span>
  </div>
  <digi-address [addresses]="warehouseAddresses" [address]="clearingInstruction?.warehouse?.address"></digi-address>
</div>

