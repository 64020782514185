<p-tabView orientation="left" styleClass="stepMenu" (onChange)="loadUploadedFiles($event,tabView)"  #tabView>
  <p-tabPanel header="Generate" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-exbond-boe-file-section></digi-exbond-boe-file-section>
        <p-tabView>
          <p-tabPanel header="Bill's of Entries">
            <digi-bill-of-entry [fileType]="'exbond'"></digi-bill-of-entry>
          </p-tabPanel>
          <p-tabPanel header="Mrks,Nos,Endrs">
            <digi-boe-marks-numbers [fileType]="'exbond'" [file]="exBondFile"></digi-boe-marks-numbers>
          </p-tabPanel>
          <p-tabPanel header="Lines" leftIcon="pi pi-user">
            <digi-boe-lines [fileType]="'exbond'"></digi-boe-lines>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Confirm BOE">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-confirmation-section [fileType]="'exbond'"></digi-confirmation-section>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Supporting Documents">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-upload-documents [files]="files"></digi-upload-documents>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
