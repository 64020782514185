var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { File } from './file';
var LineDetails = /** @class */ (function () {
    function LineDetails() {
    }
    return LineDetails;
}());
export { LineDetails };
var ShippingOrder = /** @class */ (function () {
    function ShippingOrder() {
    }
    return ShippingOrder;
}());
export { ShippingOrder };
var ExportFile = /** @class */ (function (_super) {
    __extends(ExportFile, _super);
    function ExportFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExportFile;
}(File));
export { ExportFile };
var Exporter = /** @class */ (function () {
    function Exporter() {
    }
    return Exporter;
}());
export { Exporter };
var ExportConsignee = /** @class */ (function () {
    function ExportConsignee() {
    }
    return ExportConsignee;
}());
export { ExportConsignee };
