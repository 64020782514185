import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FileService} from './file.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulePartService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private fileService: FileService
  ) {
  }

  getScheduleParts(): Observable<any> {
    return this.http.get(
      `${
        this.config.apiEndpoint
        }api/scheduleParts/search/chargeRebateStartsWith?chargeRebate=s`
    ).pipe(
      map(response => response['_embedded'].scheduleParts)
    );
  }


  findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(customsPurposeCode, tariffCode, chargeRebate): Observable<any> {
    return this.http.get(
      `${this.config.apiEndpoint}api/tariffCodes/search/additionalByCustomsPurposeCodeAndTariffCodeAndChargeRebate?customsPurposeCode=${customsPurposeCode.replace('#','')}`+
      `&code=${tariffCode}&chargeRebate=${chargeRebate}&projection=tariffCodeWithFullDescription`).pipe(
        map(response => response['_embedded'] && response['_embedded'].tariffCodes ? response['_embedded'].tariffCodes : [])
    ).pipe(
      tap(_ => this.fileService.setIsLoadingAdditionalSchedules(false))
    );
  }

  findRebateOrScheduleByTariffCodeAndChargeRebate(tariffCode, chargeRebate): Observable<any> {
    return this.http.get(
      `${this.config.apiEndpoint}api/tariffCodes/search/additionalByTariffCodeAndChargeRebate?&code=${tariffCode}&chargeRebate=${chargeRebate}&projection=tariffCodeWithFullDescription`).pipe(
        map(response => response['_embedded'] && response['_embedded'].tariffCodes ? response['_embedded'].tariffCodes : [])
    ).pipe(
      tap(_ => this.fileService.setIsLoadingAdditionalSchedules(false))
    );
  }

  findBySchedulePart(schedulePart): Observable<any> {
    if (schedulePart === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/scheduleParts/search/findBySchedulePart?code=` + schedulePart)
      .pipe(
        map(response => response['_embedded'].scheduleParts)
      );
  }
}
