import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntry, ExportBillOfEntry, PaymentType} from '../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {PaymentTypeService} from '../../services/payment-type.service';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {ClearingFile} from '../../models/clearingFile';
import {FileType} from '../../models/enumerations';
import {ExportFile} from '../../models/exportFile';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {BusinessEntity, ReceivingBank} from '../../../digi-business-entity/models/business-entity';
import {ReceivingBankService} from '../../services/receiving-bank.service';
import {ExporterService} from '../../services/exporter.service';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';
import {ExBondFileBehaviourSubject} from '../../../../subjects/exbondfile-behaviour-subject';

@Component({
  selector: 'digi-boe-payment-details',
  templateUrl: './boe-payment-details.component.html',
  styleUrls: ['./boe-payment-details.component.scss']
})
export class BoePaymentDetailsComponent implements OnInit, OnDestroy {
  selectedBillOfEntry: BillOfEntry|ExportBillOfEntry;
  billSubscription: Subscription;
  file: ClearingFile|ExportFile;
  subscription: Subscription;
  paymentTypes: PaymentType[];
  @Input() fileType: FileType;
  filteredReceivingBanks: ReceivingBank[];

  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private paymentTypeService: PaymentTypeService,
              private clearingFileBehaviorSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviorSubject: ExportFileBehaviourSubject,
              private receivingBankService: ReceivingBankService,
              private businessEntityService: BusinessEntityService,
              private exBondFileBehaviourSubject: ExBondFileBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;

        if (this.fileType === 'imports') {
          this.subscription = this.clearingFileBehaviorSubject.getClearingFile()
            .subscribe(cf => {
              this.file = cf;
            });
        } else if (this.fileType === 'exports') {
          this.subscription = this.exportFileBehaviorSubject.getExportFile()
            .subscribe(ef => {
              this.file = ef;
              if (!this.selectedBillOfEntry.receivingBank) {
                this.businessEntityService.findBusinessEntityStartsWithAndRoleType(this.file.exporter.code, 12).subscribe(
                  (businessEntities: BusinessEntity[]) => {
                    this.selectedBillOfEntry.receivingBank = businessEntities[0].roles.find(role => role.roleType === 12).receivingBank;
                  }
                );
              }
            });
        } else {
          this.subscription = this.exBondFileBehaviourSubject.getExBondFile()
            .subscribe(ef => {
              this.file = ef;
            });
        }
      });

  }


  filterPaymentTypes(event) {
    this.paymentTypeService.getPaymentType().subscribe(data => {
      this.paymentTypes = data._embedded.paymentType;
    });
  }

  selectPaymentType(value) {
    this.selectedBillOfEntry.paymentType = value;
    this.file.localAgent.customsOfficeCreditors.filter(officeCreditor => {
      if (officeCreditor.paymentType && this.file.customOffice) {
        if (value.paymentCode === officeCreditor.paymentType.paymentCode
          && officeCreditor.districtOfficeCode === this.file.customOffice.districtOfficeCode) {
          this.selectedBillOfEntry.financialAccountNumber = officeCreditor.financialAccountNumber;
        }
      }
    });
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  filterReceivingBanks(event) {
    this.receivingBankService.findReceivingBankStartingWith(event.query).subscribe(
      (receivingBanks: ReceivingBank[]) => this.filteredReceivingBanks = receivingBanks
    );
  }

  selectReceivingBank(value: ReceivingBank) {
    this.selectedBillOfEntry.receivingBank = value;
  }
}
