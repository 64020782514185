import { BehaviorSubject } from 'rxjs';
import { Formula, TariffFormula, TariffFormulaPK } from '../app/digi-common/models/TariffCode';
import * as i0 from "@angular/core";
var TariffFormulaSubjectBehavior = /** @class */ (function () {
    function TariffFormulaSubjectBehavior() {
        this.tariffFormulaSubject = new BehaviorSubject(this.buildTariffFormula());
    }
    TariffFormulaSubjectBehavior.prototype.getTariffFormula = function () {
        return this.tariffFormulaSubject.asObservable();
    };
    TariffFormulaSubjectBehavior.prototype.addTariffFormula = function (tariffFormula) {
        this.tariffFormulaSubject.next(tariffFormula);
    };
    TariffFormulaSubjectBehavior.prototype.buildTariffFormula = function () {
        var tariffFormula = new TariffFormula();
        tariffFormula.id = new TariffFormulaPK();
        tariffFormula.formula = new Formula();
        tariffFormula.formula.parameters = new Array();
        return tariffFormula;
    };
    TariffFormulaSubjectBehavior.ngInjectableDef = i0.defineInjectable({ factory: function TariffFormulaSubjectBehavior_Factory() { return new TariffFormulaSubjectBehavior(); }, token: TariffFormulaSubjectBehavior, providedIn: "root" });
    return TariffFormulaSubjectBehavior;
}());
export { TariffFormulaSubjectBehavior };
