import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExportConsignee, ExportFile} from '../../models/exportFile';
import {CertificateType, SupplierInvoice} from '../../models/file';
import {Subscription} from 'rxjs';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {CountryService} from '../../services/country.service';
import {CertificateTypeService} from '../../services/certificate-type.service';
import {BusinessEntity} from '../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';

@Component({
  selector: 'digi-invoice-export-consignee-exporter-details',
  templateUrl: './invoice-export-consignee-exporter-details.component.html',
  styleUrls: ['./invoice-export-consignee-exporter-details.component.scss']
})
export class InvoiceExportConsigneeExporterDetailsComponent implements OnInit, OnDestroy {

  exportFile: ExportFile;
  selectedSupplierInvoice: SupplierInvoice;
  filteredExportConsignees: ExportConsignee[];
  filteredCertificateTypes: CertificateType[];

  subscription: Subscription;
  siSubscription: Subscription;
  filteredExportConsigneesSubscription: Subscription;
  certificateTypesServiceSubscription: Subscription;

  constructor(
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private certificateTypesService: CertificateTypeService,
    private businessEntityService: BusinessEntityService,
    private countryService: CountryService
  ) { }

  ngOnInit() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.exportFileBehaviourSubject.getExportFile()
      .subscribe(ef => {
        this.exportFile = ef;
        if (this.filteredExportConsigneesSubscription) {
          this.filteredExportConsigneesSubscription.unsubscribe();
        }
        this.exportFileBehaviourSubject.getExportConsignees().subscribe(
          (exportConsignees: ExportConsignee[]) => this.filteredExportConsignees = exportConsignees
        );
        if (this.siSubscription) {
          this.siSubscription.unsubscribe();
        }
        this.siSubscription = this.supplierInvoiceBehaviourSubject
          .getSupplierInvoice()
          .subscribe(si => {
            if (si !== null && si !== undefined) {
              this.selectedSupplierInvoice = si;
              if (this.selectedSupplierInvoice.supplier && !this.selectedSupplierInvoice.supplier.code) {
                this.selectConsignee(this.exportFile.exportConsignee);
              } else {
                this.supplierInvoiceBehaviourSubject.addCurrency(this.selectedSupplierInvoice.currency);
                this.supplierInvoiceBehaviourSubject.addCountry(this.selectedSupplierInvoice.defaultValues.country);
              }
            }
          });
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.siSubscription) {
      this.siSubscription.unsubscribe();
    }

    if (this.filteredExportConsigneesSubscription) {
      this.filteredExportConsigneesSubscription.unsubscribe();
    }

    if (this.certificateTypesServiceSubscription) {
      this.certificateTypesServiceSubscription.unsubscribe();
    }
  }

  selectConsignee(value) {
    if (value.code) {
      this.selectedSupplierInvoice.supplier.code = value.code;
      this.selectedSupplierInvoice.supplier.name = value.name;
      this.selectedSupplierInvoice.supplier.label = value.label;
      this.selectedSupplierInvoice.supplier.address = value.address;
      this.selectedSupplierInvoice.defaultValues.purchaseTerm = value.defaultPurchaseTerm;
      if (!this.selectedSupplierInvoice.currency.code) {
        this.selectedSupplierInvoice.currency = this.exportFile.exporter.currency;
        this.supplierInvoiceBehaviourSubject.addCurrency(this.exportFile.exporter.currency);
      }
      this.businessEntityService.findBusinessEntityStartsWithAndRoleType(this.exportFile.exporter.name, 12).subscribe(
        (businessEntities: BusinessEntity[]) => {
          this.selectedSupplierInvoice.defaultValues.country = businessEntities[0].roles.find(role => role.roleType === 12).country;
          this.countryService.getCountry(this.selectedSupplierInvoice.defaultValues.country.code).subscribe(data => {
            this.selectedSupplierInvoice.defaultValues.country.tradeAgreements = data['tradeAgreements'];
            this.selectedSupplierInvoice.defaultValues.country.name = data['name'];
            this.selectedSupplierInvoice.defaultValues.country.code = data['code'];
            this.selectedSupplierInvoice.defaultValues.country.label = data['label'];
            this.selectedSupplierInvoice.defaultValues.country.defaultTradeAgreement = data['defaultTradeAgreement'];
          });
          this.supplierInvoiceBehaviourSubject.addCountry(this.selectedSupplierInvoice.defaultValues.country);
        }
      );
    }
  }

  searchCertificateTypes(event) {
    this.certificateTypesServiceSubscription = this.certificateTypesService.findByCode(event.query).subscribe(
      (certificateTypes: CertificateType[]) => this.filteredCertificateTypes = certificateTypes,
    );
  }

  selectCertificateType(value) {
    this.selectedSupplierInvoice.certificate.type = value;
  }

  copyCertificateToLines() {
    this.supplierInvoiceBehaviourSubject.addCertificate(this.selectedSupplierInvoice.certificate);
  }
}
