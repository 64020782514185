import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Address,
  BusinessEntity,
  Country,
  Currency,
  CustomsTraderType,
  ExporterConsignee, ImporterConsignee, ImporterSupplier,
  NominatedBusinessEntity,
  Role, RoleAddress
} from '../../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../../../services/notification.service';
import {PurchaseTerm} from '../../../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';


@Component({
  selector: 'digi-business-entity-modal',
  templateUrl: './business-entity-modal.component.html',
  styleUrls: ['./business-entity-modal.component.scss']
})
export class BusinessEntityModalComponent implements OnInit {
  @Input('display') display;
  @Input() isNomination: boolean;
  @Input() roleID: number;
  @Input() declarantCode: string;
  @Input() declarantRoleId: number;
  @Output() created = new EventEmitter();
  businessEntity: BusinessEntity;
  role: Role;
  selectedAddress: Address;
  roleNomination: ExporterConsignee;
  currency = new Currency();
  country = new Country();
  nominatedBusinessEntity: NominatedBusinessEntity;

  constructor(
    private businessEntityService: BusinessEntityService,
    private messageService: NotificationService
  ) { }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.businessEntity = new BusinessEntity();
    this.nominatedBusinessEntity = new NominatedBusinessEntity();
    switch (this.roleID) {
      case 2:
        this.roleNomination = new ExporterConsignee();
        this.roleNomination.defaultPurchaseTerm = new PurchaseTerm();
        break;
      case 6:
        this.roleNomination = new ImporterConsignee();
        break;
      case 21:
        this.roleNomination = new ImporterSupplier();
        break;
      default:
        break;
    }
    this.selectedAddress = new Address();
  }

  onSave() {
    if (this.businessEntity && !this.businessEntity.id) {
      this.businessEntity.registeredName = this.businessEntity.name;
      this.role = {roleType: this.roleID, country: this.country};
      this.businessEntity.roles = [];
      this.businessEntity.roles.push(this.role);
      this.businessEntity.roles = [...this.businessEntity.roles];

      if (this.isNomination) {
        if (this.selectedAddress && this.selectedAddress.line1) {
          this.businessEntity.addresses = [];
          this.businessEntity.addresses.push(this.selectedAddress);
        }
        this.nominatedBusinessEntity.businessEntity = this.businessEntity;
        this.nominatedBusinessEntity.roleNomination = this.roleNomination;
        this.nominatedBusinessEntity.roleNomination.roleType = this.roleID;
        this.businessEntityService.saveBusinessEntityWithRole(this.declarantCode, this.declarantRoleId, this.roleID, this.nominatedBusinessEntity).subscribe(
          data => {
            this.nominatedBusinessEntity.businessEntity = data;
            this.businessEntity = data;
            this.businessEntity.defaultPurchaseTerm = Object.assign({}, this.roleNomination.defaultPurchaseTerm);
            this.businessEntityConfirmation();
          }
        );
      } else {
        this.businessEntityService.saveBusinessEntity(this.businessEntity)
          .subscribe(be => {
            this.businessEntity = be;
            if (this.selectedAddress && this.selectedAddress.line1) {
              this.businessEntity.addresses = [];
              this.businessEntity.addresses.push(this.selectedAddress);
              this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(updatedBE => {
                this.businessEntity = updatedBE;
                const role = this.businessEntity.roles.find(el => el.roleType === this.roleID);
                const roleAddress = new RoleAddress();
                roleAddress.address = this.businessEntity.addresses[0];
                roleAddress.addressType = {id: 'CUS', description: 'Customs Address'};
                role.roleAddresses = [roleAddress];

                this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(beWithRoleAddress => {
                  this.businessEntity = beWithRoleAddress;
                  this.messageService.successNotify('Business Entity Saved');
                });
              });
            }
          }, error => {
            this.messageService.errorNotify(error.error.message, [error.error.errors]);
          });
      }
    } else {
      this.addFailure(['Business Entity has already been saved']);
    }

  }

  onNew() {
    this.initialize();
  }

  onClose() {
    this.display = false;
    this.created.emit(this.businessEntity);
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  businessEntityConfirmation() {
    this.messageService.successNotify('Business Entity Saved and Role linked to Exporter');
  }

  selectCountry(value) {
    this.country = value;
  }

  getRoleHeader(roleID: number): string {
    switch (roleID) {
      case 6:
        return 'Add New Importer';
      case 4:
        return 'Add New Clearing Agent';
      case 21:
        return 'Add New Supplier';
      default:
        return 'Unknown Role';
    }
  }

}
