import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TariffCodeBehaviorSubject } from '../../../../subjects/tariffCode-behavior-subject';
import { TariffDescriptionHistoryBehaviourSubject } from '../../../../subjects/tariffDescriptionHistory-behaviour-subject';
import { TariffCodeReference, TariffDescriptionHistory } from '../../models/TariffCode';
import { NotificationService } from '../../services/notification.service';
import { TariffService } from '../../services/tariff.service';

@Component({
  selector: 'digi-tariff-maintenance-table-description',
  templateUrl: './tariff-maintenance-table-description.component.html',
  styleUrls: ['./tariff-maintenance-table-description.component.scss'],
})
export class TariffMaintenanceTableDescriptionComponent implements OnInit, OnDestroy {
  cols: any[];
  selectedTariffCode: TariffCodeReference;
  selectedDescription: TariffDescriptionHistory;
  subscription: Subscription;
  dSubscription: Subscription;

  constructor(private tariffCodeBehaviorSubject: TariffCodeBehaviorSubject,
              private tariffDescriptionHistoryBehaviourSubject: TariffDescriptionHistoryBehaviourSubject,
              private tariffService: TariffService,
              private messageService: NotificationService) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'effectiveFrom', header: 'Effective From', width: '40%'},
      {field: 'descriptions', header: 'Customs Description', width: '60%'}
    ];

    this.subscription = this.tariffCodeBehaviorSubject
      .getTariffCode()
      .subscribe(tariff => this.selectedTariffCode = tariff);

    this.dSubscription = this.tariffDescriptionHistoryBehaviourSubject
      .getTariffDescriptionHistory()
      .subscribe(d => this.selectedDescription = d);
  }

  onRowSelect(value) {
    this.selectedDescription = this.selectedTariffCode.descriptions[value.index];
  }

  createNewDescription() {
    this.selectedDescription = this.tariffDescriptionHistoryBehaviourSubject.buildTariffDescriptionHistory();
    this.selectedDescription.id.tariffId = this.selectedTariffCode.tariffID;
    this.tariffCodeBehaviorSubject.addTariffCode(this.selectedTariffCode);
    this.selectedTariffCode.descriptions.push(this.selectedDescription);
  }

  saveNewDescription() {
    this.tariffService.saveTariffDescriptionHistory(this.selectedDescription)
      .subscribe(data => {
          this.tariffDescriptionHistoryBehaviourSubject.addTariffDescriptionHistory(data);
          this.addSuccess();
        },
        (error) => {

        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addSuccess() {
    this.messageService.successNotify(
      'Successfully Saved'
    );
  }
}
