<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="selectedContainer.containerType" field="description"
                                  [suggestions]="filteredContainerTypes"
                                  (completeMethod)="search($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true"
                                  required=true (onSelect)="select($event)"
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}: {{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                   <label>Container Type</label>
            </span>
              </div>
               <div *ngIf="!selectedContainer.containerType.code"
                    class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
