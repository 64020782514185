import {Component, OnInit, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng/primeng';
import {ExBondFile, ExBondLine} from '../../digi-common/models/exBondFile';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-exbond-file-search',
  templateUrl: './exbond-file-search.component.html',
  styleUrls: ['./exbond-file-search.component.scss']
})
export class ExbondFileSearchComponent implements OnInit {
  exBondFile: ExBondFile;
  @ViewChild('correctionList') correctionList: OverlayPanel;
  listExBondFiles: any[];
  filteredExBondFiles: ExBondFile[];
  private exBondFileSubscription: Subscription;

  constructor(
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private exBondFileService: ExBondFileService
  ) { }

  ngOnInit() {
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(ef => {
      this.exBondFile = ef;

      if (this.exBondFile.clearingFileNumber !== null && this.exBondFile.clearingFileNumber !== undefined) {
        this.exBondFileService.loadAllCorrectionsIncludingOriginalExBondFile(this.exBondFile.clearingFileNumber)
          .subscribe(data => {
            this.listExBondFiles = data;
          });
      }
    });
  }

  search(event) {
    this.exBondFileService
      .loadExBondFile(event.query)
      .subscribe(data => {
        this.filteredExBondFiles = data;
      });
  }

  selectInitial(value) {
    if (value.type !== 'ExBondFileCorrection') {
      this.exBondFileService.selectExBondFile(value);
    }
  }

  selectCorrection(value) {
    this.exBondFileService.selectExBondFile(value);
    this.correctionList.hide();
  }

}
