<div class="ui-g" *ngIf="clearingInstruction">
  <div class="ui-g-12">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
             <span class="ui-float-label">
                  <p-autoComplete id="rebateUser" [(ngModel)]="clearingInstruction.rebateUser"
                                  field="label"
                                  [suggestions]="filteredRebaseUsers"
                                  (completeMethod)="search($event)"
                                  [dropdown]="true"
                                  [autoHighlight]="true"
                                  (onSelect)="selectRebateUser($event)">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}: {{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="rebateUser">Rebate User</label>
             </span>
        </div>
      </div>
    </div>
    <digi-address [addresses]="rebateUserAddresses"
                  [address]="clearingInstruction?.rebateUser?.address"></digi-address>
  </div>
</div>
