import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoadManifest, CuscarType, ManifestWaybill } from '../models/roadManifest';
import { Address, Country, LocalAgent, PortDetail, Transporter, VoyageDetails } from '../models/file';
import { CustomsOffice } from '../../digi-business-entity/models/business-entity';
import { ExportFileService } from './export-file.service';
import * as moment from 'moment';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "./export-file.service";
import * as i4 from "../../../subjects/authorisation-behaviour-subject";
var ManifestService = /** @class */ (function () {
    function ManifestService(http, config, exportFileService, authorisationService) {
        this.http = http;
        this.config = config;
        this.exportFileService = exportFileService;
        this.authorisationService = authorisationService;
    }
    ManifestService.prototype.saveRoadManifest = function (roadManifest) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (roadManifest._links && roadManifest._links.self) {
            if (roadManifest.type === 'RoadManifest') {
                return this.http.put(this.config.filesApiEndpoint + 'api/roadManifests/' + roadManifest.id, JSON.stringify(roadManifest), { headers: headers });
            }
            else {
                return this.http.put(this.config.filesApiEndpoint + 'api/roadManifestCorrections/' + roadManifest.id, JSON.stringify(roadManifest), { headers: headers });
            }
        }
        else if (roadManifest.type === 'RoadManifest') {
            return this.http.post(this.config.filesApiEndpoint + 'api/roadManifests', JSON.stringify(roadManifest), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/roadManifestCorrections', JSON.stringify(roadManifest), { headers: headers });
        }
    };
    ManifestService.prototype.generate = function (id) {
        return this.http
            .post(this.config.filesApiEndpoint + "api/roadManifests/" + id + "/manifest", {});
    };
    ManifestService.prototype.findRoadManifestByDocumentMessageNumber = function (documentMessageNumber) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifests/search/findByDocumentMessageNumber?documentMessageNumber=" + documentMessageNumber);
    };
    ManifestService.prototype.findRoadManifestsByHawbNo = function (hawbNo) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifests/search/findByHawbNo?hawbNo=" + hawbNo)
            .pipe(map(function (response) { return response['_embedded'].roadManifests; }));
    };
    ManifestService.prototype.findByHawbOrManifestFileNoOrManifestNo = function (query) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifests/search/findByHawbNoOrManifestNoOrManifestFileNo?query=" + query)
            .pipe(map(function (response) { return response['_embedded'].roadManifests; }));
    };
    ManifestService.prototype.loadAllCorrectionsIncludingOriginalManifestFile = function (manifestFileNo) {
        if (manifestFileNo === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifestCorrections/search/findByManifestFileNo?manifestFileNo=" + manifestFileNo)
            .pipe(map(function (response) { return response['_embedded'].roadManifestCorrections; }));
    };
    ManifestService.prototype.findByManifestFileNoAndSequence = function (manifestFileNo, selectedSequence) {
        if (manifestFileNo === '' && selectedSequence === null) {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifestCorrections/search/findByManifestFileNoAndSequence?manifestFileNo=" + manifestFileNo
            + '&sequence=' + selectedSequence)
            .pipe(map(function (response) { return response['_embedded'].roadManifestCorrections; }));
    };
    ManifestService.prototype.findAllSequences = function (manifestFileNo) {
        if (manifestFileNo === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifestCorrections/search/findSequencesByManifestFileNo?manifestFileNo="
            + manifestFileNo + '&projection=sequenceAndManifestFileNo')
            .pipe(map(function (response) { return response['_embedded'].roadManifestCorrections; }));
    };
    ManifestService.prototype.findById = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/roadManifests/" + id);
    };
    ManifestService.prototype.createNewManifest = function () {
        var roadManifest = new RoadManifest();
        this.authorisationService.getLoggedInUser().pipe(map(function (user) {
            if (user && user.attributes && user.attributes['branch']) {
                roadManifest.branch = user.attributes['branch'][0];
            }
        }));
        roadManifest.voyageDetails = new VoyageDetails();
        roadManifest.countryOfExport = new Country();
        roadManifest.countryOfExport.code = 'ZA ';
        roadManifest.countryOfExport.name = 'SOUTH AFRICA';
        roadManifest.countryOfExport.label = 'ZA: SOUTH AFRICA';
        roadManifest.exitPort = new PortDetail();
        roadManifest.exitPort.code = 'BBR';
        roadManifest.exitPort.name = 'BEIT BRIDGE (ZIMBABWE BORDER POST)';
        roadManifest.exitPort.label = 'BBR: BEIT BRIDGE (ZIMBABWE BORDER POST)';
        roadManifest.customOffice = new CustomsOffice();
        roadManifest.destination = new Country();
        roadManifest.transporter = new Transporter();
        roadManifest.waybills = [];
        roadManifest.cuscarType = new CuscarType();
        roadManifest.cuscarType.type = 'RFM       ';
        roadManifest.cuscarType.description = 'Road Freight Manifest';
        roadManifest.cuscarType.label = 'RFM: Road Freight Manifest';
        roadManifest.localAgent = new LocalAgent();
        roadManifest.localAgent.address = new Address();
        roadManifest.crew = [];
        roadManifest.type = 'RoadManifest';
        roadManifest.transportMethodCode = 'ROA';
        roadManifest.manifestType = 'EXPORT';
        return roadManifest;
    };
    ManifestService.prototype.createWaybill = function (roadManifest, boe) {
        var _this = this;
        var waybill = new ManifestWaybill();
        waybill.containers = boe.containers;
        waybill.lrn = boe.lrn;
        waybill.mrn = boe.mrn;
        if (boe.cusres) {
            waybill.releaseDate = moment(boe.cusres.receivedDate, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
        }
        waybill.mawbNo = boe.mawbNumber;
        waybill.hawbNo = boe.transportDocNo;
        waybill.cpc = boe.cpc;
        waybill.marksAndNumbers = boe.markAndNumbers;
        waybill.numberOfPackages = boe.numberOfPackages;
        waybill.descriptionOfGoods = boe.lines[0].description;
        waybill.weight = boe.totalWeight;
        waybill.grossMass = boe.grossMass;
        waybill.ucrNumber = boe.ucrNumber;
        this.exportFileService.findByRef(boe._links.exportFile.href).subscribe(function (ef) {
            waybill.consignee = ef.exportConsignee;
            waybill.consignor = ef.exporter;
            waybill.destinationPort = ef.transportDocument.destinationPort;
            waybill.departurePort = ef.transportDocument.departurePort;
            waybill.mawbDate = ef.transportDocument.mawbDate;
            waybill.hawbDate = ef.transportDocument.hawbDate;
            boe.exportFile = ef;
            _this.setRoadManifestValues(roadManifest, boe);
        });
        roadManifest.waybills.push(waybill);
    };
    ManifestService.prototype.setRoadManifestValues = function (roadManifest, boe) {
        if (!roadManifest.manifestNo) {
            roadManifest.manifestNo = boe.mawbNumber;
        }
        if (!roadManifest.transporter.transporterCode) {
            roadManifest.transporter = boe.exportFile.transportDocument.transporter;
        }
        if (!roadManifest.voyageDetails.vehicleAndTrailerReg) {
            roadManifest.voyageDetails.vehicleAndTrailerReg = boe.exportFile.transportDocument.voyageDetails.vehicleAndTrailerReg;
        }
        if (!roadManifest.voyageDetails.trailerOne) {
            roadManifest.voyageDetails.trailerOne = boe.exportFile.transportDocument.voyageDetails.trailerOne;
        }
        if (!roadManifest.voyageDetails.trailerTwo) {
            roadManifest.voyageDetails.trailerTwo = boe.exportFile.transportDocument.voyageDetails.trailerTwo;
        }
        if (!roadManifest.localAgent.code) {
            roadManifest.localAgent = boe.exportFile.localAgent;
        }
        if (!roadManifest.countryOfExport.code) {
            roadManifest.countryOfExport = {
                name: boe.exportFile.transportDocument.departurePort.countryName,
                code: boe.exportFile.transportDocument.departurePort.countryCode,
                label: boe.exportFile.transportDocument.departurePort.label
            };
        }
        if (!roadManifest.exitPort.code) {
            roadManifest.exitPort = boe.exportFile.transportDocument.exitPort;
        }
        if (!roadManifest.destination.code) {
            roadManifest.destination = boe.destination;
        }
        if (!roadManifest.customOffice.districtOfficeCode) {
            roadManifest.customOffice = boe.exportFile.customOffice;
        }
        if (!roadManifest.arrivalDate) {
            roadManifest.arrivalDate = boe.exportFile.transportDocument.arrivalDate;
        }
        if (!roadManifest.masterTransportDocDate) {
            roadManifest.masterTransportDocDate = boe.exportFile.transportDocument.mawbDate;
        }
    };
    ManifestService.prototype.generateReports = function (id, voyageMasterFileNo) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/voyageFiles/" + id + "/documents?masterFileNo=" + voyageMasterFileNo);
    };
    ManifestService.prototype.findByVoyageMasterFileNumber = function (voyageMasterFileNumber) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/voyageMasterFiles/search/findByVoyageMasterFileNo?voyageMasterFileNo=" + voyageMasterFileNumber);
    };
    ManifestService.prototype.saveVoyageMasterFile = function (voyageMasterFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (voyageMasterFile._links && voyageMasterFile._links.self) {
            return this.http.put(this.config.filesApiEndpoint + 'api/voyageMasterFiles/' + voyageMasterFile.id, JSON.stringify(voyageMasterFile), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/voyageMasterFiles', JSON.stringify(voyageMasterFile), { headers: headers });
        }
    };
    ManifestService.ngInjectableDef = i0.defineInjectable({ factory: function ManifestService_Factory() { return new ManifestService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ExportFileService), i0.inject(i4.AuthorisationService)); }, token: ManifestService, providedIn: "root" });
    return ManifestService;
}());
export { ManifestService };
