import { Component, OnInit, Input, IterableDiffer, IterableDiffers, DoCheck, Output, EventEmitter } from '@angular/core';
import { ShareDataService } from './../../services/share-data.service';

@Component({
  selector: 'digi-rate-based-charge',
  templateUrl: './rate-based-charge.component.html',
  styleUrls: ['./rate-based-charge.component.scss']
})
export class RateBasedChargeComponent implements OnInit, DoCheck {
  @Input('hasSundryCharge') hasSundryCharge;
  @Input('rateBasedCharges') rateBasedCharges;
  rateBasedChargesDiffers: IterableDiffer<any>;
  constructor(private shareDataService: ShareDataService,
    private iterableDiffers: IterableDiffers
  ) { }

  ngOnInit() {
  }

  ngDoCheck(): void {
    if (this.hasSundryCharge) {
      if (this.rateBasedChargesDiffers) {
        const rateBasedChargesChanges = this.rateBasedChargesDiffers.diff(this.hasSundryCharge.rateBasedCharges);
        if (rateBasedChargesChanges) {
          this.createChangeDetectionForRateBasedCharges();
        }
      }
    }
  }

  private createChangeDetectionForRateBasedCharges() {
    this.rateBasedChargesDiffers = this.iterableDiffers.find(this.hasSundryCharge.rateBasedCharges).create();
  }

  addRateBasedCharge() {
    if (this.hasSundryCharge) {
      this.rateBasedCharges = this.shareDataService.addRateBasedCharge(this.hasSundryCharge);
      if (!this.rateBasedChargesDiffers) {
        this.createChangeDetectionForRateBasedCharges();
      }
    }
  }

  onRowDelete(index) {
    this.rateBasedCharges.splice(index, 1);
  }
}
