<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Bond Store Reports" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-10">
              <div class="ui-g-2 ui-md-2">
                <span class="md-inputfield">
                  <span class="ui-float-label">
                    <input type="text" style="color:white" pInputText [(ngModel)]="mrnNumber" placeholder="MRN Number"/>
                  </span>
                </span>
              </div>
              <div class="ui-g-3 ui-md-3">
                <span class="md-inputfield">
                  <span class="ui-inputgroup">
                    <span class="ui-float-label ui-fluid">
                          <p-autoComplete id="importer" [(ngModel)]="importer"
                                          class="color-white"
                                          field="label"
                                          [suggestions]="filteredImporters"
                                          (completeMethod)="searchImporter($event)"
                                          [autoHighlight]="true"
                                          [inputStyle]="{color:'white'}"
                                          placeholder="Importer">
                            <ng-template let-value pTemplate="selectedItem">
                        <span style="font-size:14px">{{value.code}}</span>
                      </ng-template>
                          </p-autoComplete>
                    </span>
                    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </span>
                </span>
              </div>
              <div class="ui-g-3 ui-md-3">
                <span class="md-inputfield">
                  <span class="ui-inputgroup">
                    <span class="ui-float-label ui-fluid">
                          <p-autoComplete id="warehouse" [(ngModel)]="warehouse"
                                          class="color-white"
                                          field="customsWarehouseNumber"
                                          [suggestions]="filteredWarehouses"
                                          (completeMethod)="searchWarehouse($event)"
                                          [autoHighlight]="true"
                                          [inputStyle]="{color:'white'}"
                                          placeholder="Warehouse No.">
                            <ng-template let-value pTemplate="selectedItem">
                        <span style="font-size:14px">{{value.code}}</span>
                      </ng-template>
                          </p-autoComplete>
                    </span>
                    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </span>
                </span>
              </div>
              <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="fromDate"
                                             placeholder="From"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
              <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="toDate"
                                             placeholder="To"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <div class="ui-g-12 ui-md-12">
                  <button pButton type="button" label="Generate Reports"
                          [disabled]="disableButton"
                          (click)="generate()"></button>
                <button *ngIf="pdfSrc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="card card-w-title">
            <div class="ui-g">
              <pdf-viewer class="ui-g-12 ui-md-12"
                          *ngIf="pdfSrc"
                          [src]="pdfSrc"
                          [render-text]="true"
                          [original-size]="true"
                          [show-all]="true"
                          [stick-to-page]="true"
                          (after-load-complete)="disableButton=false"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
