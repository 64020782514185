import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Permit} from '../../models/file';

@Component({
  selector: 'digi-permits-table',
  templateUrl: './permits-table.component.html',
  styleUrls: ['./permits-table.component.scss']
})
export class PermitsTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() permits: Permit[];
  @Input() selectedPermit: Permit;
  @Output() selectPermit = new EventEmitter();

  cols: any[];

  constructor() {
  }

  ngOnInit() {

    this.cols = [
      {width: '10%'},
      {field: 'permitNumber', header: 'Permit No', width: '20%'},
      {field: 'quantityBased', header: 'Quantity Based', width: '20%'},
      {field: 'balanceQuantity', header: 'Balance Quantity', width: '20%'},
      {field: 'appliedQuantity', header: 'Applied Quantity', width: '20%'},
      {field: 'permitUom', header: 'UOM', width: '8%'},
      {field: 'valueBased', header: 'Value Based', width: '20%'},
      {field: 'balanceValue', header: 'Balance Value', width: '20%'},
      {field: 'appliedValueForeign', header: 'Applied Value(foreign)', width: '20%'},
      {field: 'appliedValueLocal', header: 'Applied Value(local)', width: '20%'}
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedPermit && this.permits && this.permits.length) {
      const permitFromTable = this.permits.find(p => p.permitNumber === this.selectedPermit.permitNumber);
      if (permitFromTable) {
        if (permitFromTable.valueBased) {
          permitFromTable.appliedValueLocal = this.selectedPermit.appliedValueLocal;
          permitFromTable.appliedValueForeign = this.selectedPermit.appliedValueForeign;
        } else {
          permitFromTable.appliedValueLocal = 0;
          permitFromTable.appliedValueForeign = 0;
        }
        if (permitFromTable.quantityBased) {
          permitFromTable.appliedQuantity = this.selectedPermit.appliedQuantity;
        } else {
          permitFromTable.appliedQuantity = 0;
        }
      }
    }
  }

  select(permit) {
    if (this.selectedPermit && this.selectedPermit.permitNumber !== permit.permitNumber) {
      this.reversePermitValues(this.selectedPermit);
    }
    this.selectPermit.emit(permit);
  }

  unselect(permit: Permit) {
    this.reversePermitValues(permit);
    this.selectPermit.emit(null);
  }

  private reversePermitValues(permit: Permit) {
    const unselectedPermit = this.permits.find(el => el.permitNumber === permit.permitNumber);
    if (unselectedPermit) {
      unselectedPermit.balanceValue += permit.appliedValueForeign;
      unselectedPermit.balanceQuantity += permit.appliedQuantity;
      unselectedPermit.appliedValueForeign = 0;
      unselectedPermit.appliedValueLocal = 0;
      unselectedPermit.appliedQuantity = 0;
    }
  }

  ngOnDestroy(): void {

  }
}
