import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransportDocumentBehaviourSubject } from 'src/subjects/transport-document-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../../subjects/clearingInstruction-behavior-subject';
import { ClearingFile } from '../../models/clearingFile';
import { ClearingInstruction, TransportDocument } from '../../models/file';
import { ShareDataService } from '../../services/share-data.service';

@Component({
  selector: 'digi-hawb-summary-table',
  templateUrl: './hawb-summary-table.component.html',
  styleUrls: ['./hawb-summary-table.component.css']
})
export class HawbSummaryTableComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedTransport: TransportDocument;
  cols: any[];
  clearingInstructionSubscription: Subscription;
  transportDocumentSubscription: Subscription;
  clearingInstructions: ClearingInstruction[];
  clearingFileSubscription: Subscription;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private shareDataService: ShareDataService) {
  }

  ngOnInit() {
    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
      this.clearingFile = cf;
      if (this.clearingFile) {
        if (this.transportDocumentSubscription) {
          this.transportDocumentSubscription.unsubscribe();
        }
        this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
          .subscribe(td => {
            if (td) {
              this.selectedTransport = td;
            } else {
              this.selectedTransport = this.clearingFile.transportDocuments[0];
            }
          });
      } else {
        this.selectedTransport = this.clearingFile.transportDocuments[0];
      }
    });

    this.clearingInstructionSubscription = this.clearingInstructionBehaviorSubject.getClearingInstructions()
      .subscribe(ci => {
        this.clearingInstructions = ci;
      });

    if (this.clearingInstructions.length > 0) {
      switch (this.clearingInstructions[0].transportMethod.method) {
        case 'AIR':
          this.cols = [
            {field: 'hawbNo', header: 'HAWB No', width: '11%'},
            {field: 'mawbNo', header: 'MAWB No'},
            {field: 'clientRefNo', header: 'Client Ref No'}
          ];
          break;
        case 'SEA':
          this.cols = [
            {field: 'hawbNo', header: 'HBOL No', width: '11%'},
            {field: 'mawbNo', header: 'MBOL No'},
            {field: 'clientRefNo', header: 'Client Ref No'}
          ];
          break;
        case 'ROA':
          this.cols = [
            {field: 'hawbNo', header: 'Waybill No', width: '11%'},
            {field: 'mawbNo', header: 'Manifest No'},
            {field: 'clientRefNo', header: 'Client Ref No'}
          ];
          break;
        case 'RAI':
          this.cols = [
            {field: 'hawbNo', header: 'Waybill No', width: '11%'},
            {field: 'mawbNo', header: 'Manifest No'},
            {field: 'clientRefNo', header: 'Client Ref No'}
          ];
          break;
      }
    }
  }

  onRowSelect(value: any): void {
    this.transportDocumentBehaviourSubject.addTransportDocument(value.data);
  }

  ngOnDestroy() {
    this.clearingInstructionSubscription.unsubscribe();
    this.transportDocumentSubscription.unsubscribe();
    this.clearingFileSubscription.unsubscribe();
  }

  onRowDelete(index) {
    this.defaultToFirstTransportDocument(index);
    this.clearingFile.transportDocuments.splice(index, 1);
    this.clearingFile.transportDocuments = [...this.clearingFile.transportDocuments];
    this.recreateDefaultTransportDocument();
  }

  private recreateDefaultTransportDocument() {
    if (this.clearingFile.transportDocuments.length === 0) {
      this.updateTable(this.shareDataService.addTransportDocument('imports'));
    }
  }

  private defaultToFirstTransportDocument(index) {
    if (this.clearingFile.transportDocuments[index].mawbDate === this.selectedTransport.mawbDate) {
      if (index === 0) {
        this.transportDocumentBehaviourSubject.addTransportDocument(this.clearingFile.transportDocuments[index + 1]);
      } else {
        this.transportDocumentBehaviourSubject.addTransportDocument(this.clearingFile.transportDocuments[0]);
      }
    }
  }

  private updateTable(transportDocument) {
    this.clearingFile.transportDocuments.push(transportDocument);
    this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
  }
}
