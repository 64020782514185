import {Component, OnInit} from '@angular/core';
import {BankingDetails, GenLedgerAccDetails, Branch, Company, CompanyDefaults, CustomsOfficeDefaults, InvoiceDetails} from '../../models/company';
import {CompanyService} from '../../services/company.service';
import {NotificationService} from '../../services/notification.service';
import {Address, TransportMethod} from '../../models/file';
import {TransportMethodsService} from '../../services/transport-methods.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, Subscription} from 'rxjs';
import {InvoiceSection, SupplierInvoiceLine} from '../../models/financials';
import {ChargeTypeService} from '../../services/charge-type.service';
import {TranTypeService} from '../../services/tran-type.service';
import {InvoiceSectionService} from '../../services/invoice-section.service';
import {TaxTypeService} from '../../services/tax-type.service';

@Component({
  selector: 'digi-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  company: Company;
  cols: any[];
  branch: Branch;
  displayCustomsOfficeDefaults: boolean;
  customsOfficeDefaultsCols: any[];
  customsOfficeDefault = new CustomsOfficeDefaults();
  methods: TransportMethod[];
  declarationTypeOptions: any[];
  bankingDetailsCols: any[];
  bankingDetails = new BankingDetails();
  displayBankingDetailsDialog: boolean;
  genLedgerAccDetailsCols: any[];
  genLedgerAccDetails = new GenLedgerAccDetails();
  displayGenLedgerAccDetailsDialog: boolean;
  logo: any;
  displayInvoiceLinesDialog: boolean;
  invoiceLine = new SupplierInvoiceLine();
  invoiceLinesCols: any[];
  filteredChargeTypes: any[];
  chargeTypeSubscription: Subscription;
  tranTypeSubscription: Subscription;
  filteredTranTypes: any[];
  filteredTaxTypes: any[];
  taxTypeSubscription: Subscription;
  invoiceSectionSubscription: Subscription;
  filteredInvoiceSections: any[];
  filteredInvoiceSubSections: any[];
  invoiceSubSectionSubscription: Subscription;

  constructor(
    private companyService: CompanyService,
    private transportMethodsService: TransportMethodsService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private chargeTypeService: ChargeTypeService,
    private invoiceSectionService: InvoiceSectionService,
    private taxTypeService: TaxTypeService,
    private tranTypeService: TranTypeService) {
  }

  ngOnInit() {
    this.declarationTypeOptions = [
      {'label': '', 'value': ''}, {'label': 'Import', 'value': 'import'}, {'label': 'Export', 'value': 'export'}
      ];
    this.cols = [
      {
        field: 'code',
        header: 'Code'
      },
      {
        field: 'name',
        header: 'Name'
      }
      ];
    this.bankingDetailsCols = [
      {
        header: 'Bank',
        width: '35%'
      },
      {
        header: 'Branch Code',
        width: '15%'
      },
      {
        header: 'Acount No',
        width: '35%'
      },
      {
        header: 'Acount No',
        width: '15%'
      }
      ];
    this.genLedgerAccDetailsCols = [
      {
        header: 'Charge Type',
      },
      {
        header: 'G.L Account Number',
      },
      {
        header: '',
        width: '25%'
      }
      ];
    this.customsOfficeDefaultsCols = [
      {
        header: 'Transport Method'
      },
      {
        header: 'Declaration Type'
      },
      {
        header: 'Customs Office'
      },
      {
        header: ''
      }
    ];

    this.invoiceLinesCols = [
      {
        header: 'Charge Type'
      },
      {
        header: 'Tran. Type'
      },
      {
        header: 'Tax Type'
      },
      {
        header: 'Invoice Section'
      },
      {
        header: 'Invoice Sub-Section'
      },
      {
        header: 'Air (Imp.)',
        width: '5%'
      },
      {
        header: 'Air (Exp.)',
        width: '5%'
      },
      {
        header: 'Sea (Imp.)',
        width: '5%'
      },
      {
        header: 'Sea (Exp.)',
        width: '5%'
      },
      {
        header: 'Road (Imp.)',
        width: '5%'
      },
      {
        header: 'Road (Exp.)',
        width: '5%'
      },
      {
        header: '',
        width: '5%'
      }
    ];
    this.createNewCompany();
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        this.company = companies[0];
        this.logo = this.domSanitizer.bypassSecurityTrustStyle(`url(${'data:image/png;base64,' + this.company.logo})`);
      } else {
        this.createNewCompany();
      }
    });
  }

  saveCompany() {
    this.companyService.save(this.company).subscribe(company => {
        this.company = company;
        this.logo = this.domSanitizer.bypassSecurityTrustStyle(`url(${'data:image/png;base64,' + this.company.logo})`);
        this.notificationService.successNotify('Company saved successfully');
      },
      _ => this.notificationService.errorNotify('Save Not Successful'));
  }

  private createNewCompany() {
    this.company = new Company();
    this.company.companyDefaults = new CompanyDefaults();
    this.company.branches = [];
  }

  handleIndexChange(index) {
    if (index === 1) {
      if (this.company.branches && this.company.branches.length) {
        this.selectBranch(this.company.branches[0]);
      } else {
        this.createBranch();
      }
    }
  }

  createBranch() {
    this.branch = new Branch();
    this.branch.invoiceDetails = new InvoiceDetails();
    this.branch.invoiceDetails.postalAddress = new Address();
    this.branch.invoiceDetails.physicalAddress = new Address();
    this.branch.invoiceDetails.bankingDetails = [];
    this.branch.invoiceDetails.genLedgerAccDetails = [];
    this.company.branches.push(this.branch);
  }

  showDialogToAddCustomsOfficeDefault() {
    this.customsOfficeDefault = new CustomsOfficeDefaults();
    this.displayCustomsOfficeDefaults = true;
  }

  deleteCustomsOfficeDefault(rowIndex: any) {
    this.branch.customsOfficeDefaults.splice(rowIndex, 1);
    this.branch.customsOfficeDefaults = [...this.branch.customsOfficeDefaults];
  }

  searchTransportMethod(event: any) {
    this.transportMethodsService.findTransportMethods().subscribe(
      transportMethods => this.methods = transportMethods
    );
  }

  selectTransportMethod(value) {
    this.customsOfficeDefault.transportMethod = value;
  }

  saveCustomsOfficeDefault() {
    if (this.branch.customsOfficeDefaults) {
      this.branch.customsOfficeDefaults.push(this.customsOfficeDefault);
    } else {
      this.branch.customsOfficeDefaults = [this.customsOfficeDefault];
    }
    this.displayCustomsOfficeDefaults = false;
  }

  showDialogToAddBankingDetails() {
    this.bankingDetails = new BankingDetails();
    this.displayBankingDetailsDialog = true;
  }

  deleteBankingDetails(rowIndex: any) {
    this.branch.invoiceDetails.bankingDetails.splice(rowIndex, 1);
    this.branch.invoiceDetails.bankingDetails = [...this.branch.invoiceDetails.bankingDetails];
  }

  saveBankingDetails() {
    if (this.branch.invoiceDetails.bankingDetails) {
      this.branch.invoiceDetails.bankingDetails.push(this.bankingDetails);
    } else {
      this.branch.invoiceDetails.bankingDetails = [this.bankingDetails];
    }
    this.displayBankingDetailsDialog = false;
  }

  showDialogToAddGenLedgerAccDetails() {
      this.genLedgerAccDetails = new GenLedgerAccDetails();
      this.displayGenLedgerAccDetailsDialog = true;
    }

    deleteGenLedgerAccDetails(rowIndex: any) {
      this.branch.invoiceDetails.genLedgerAccDetails.splice(rowIndex, 1);
      this.branch.invoiceDetails.genLedgerAccDetails = [...this.branch.invoiceDetails.genLedgerAccDetails];
    }

    saveGenLedgerAccDetails() {
      if (this.branch.invoiceDetails.genLedgerAccDetails) {
        this.branch.invoiceDetails.genLedgerAccDetails.push(this.genLedgerAccDetails);
      } else {
        this.branch.invoiceDetails.genLedgerAccDetails = [this.genLedgerAccDetails];
      }
      this.displayGenLedgerAccDetailsDialog = false;
    }

  saveInvoiceItem() {
    if (this.branch.invoiceLines) {
      this.branch.invoiceLines.push(this.invoiceLine);
    } else {
      this.branch.invoiceLines = [this.invoiceLine];
    }
    this.displayInvoiceLinesDialog = false;
  }

  deleteInvoiceLine(rowIndex: any) {
    this.branch.invoiceLines.splice(rowIndex, 1);
    this.branch.invoiceLines = [...this.branch.invoiceLines];
  }

  showDialogToAddInvoiceLines() {
    this.invoiceLine = new SupplierInvoiceLine();
    this.displayInvoiceLinesDialog = true;
  }

  searchChargeType(event) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(event.query).subscribe(chargeTypes => {
      this.filteredChargeTypes = chargeTypes;
    });
  }

  searchTaxTypeCode(event) {
    this.taxTypeSubscription = this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(taxTypes => {
      this.filteredTaxTypes = taxTypes;
    });
  }

  searchTranType(event) {
    this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith(event.query).subscribe(tranTypes => {
      this.filteredTranTypes = tranTypes;
    });
  }

  searchInvoiceSection(event) {
    this.invoiceSectionSubscription = this.invoiceSectionService.findInvoiceSection(event.query).subscribe(
      invoiceSections => {
        this.filteredInvoiceSections = invoiceSections;
      }
    );
  }

  searchInvoiceSubSection(query, invoiceSection: InvoiceSection) {
    if (invoiceSection.id) {
      this.invoiceSubSectionSubscription = this.invoiceSectionService
        .findInvoiceSubSectionsByInvoiceSectionId(invoiceSection.id, query)
        .subscribe(
          invoiceSubSections => {
            this.filteredInvoiceSubSections = [];
            invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
          }
        );
    } else {
      this.invoiceSectionSubscription = this.invoiceSectionService
        .findInvoiceSection(invoiceSection.description)
        .subscribe(
          invoiceSections => {
            this.invoiceSubSectionSubscription = this.invoiceSectionService
              .findInvoiceSubSectionsByInvoiceSectionId(invoiceSections[0].id, query)
              .subscribe(
                invoiceSubSections => {
                  this.filteredInvoiceSubSections = [];
                  invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
                }
              );
          }
        );
    }
  }

  selectFiles(event: any) {
    const files = event.srcElement.files;
    const file = files[0];
    const fileObservable = new Observable<any>(subscriber => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        subscriber.next(fileReader.result);
      };
    });
    fileObservable.subscribe(base64 => {
      this.company.logo = base64.split('base64,')[1];
      this.logo = this.domSanitizer.bypassSecurityTrustStyle(`url(${'data:image/png;base64,' + this.company.logo})`);
    });
  }

  selectDefaultInvoiceItem(value) {
    this.invoiceLine = value;
  }

  selectBranch(branch) {
    this.branch = branch;
    if (!this.branch.invoiceDetails.postalAddress) {
      this.branch.invoiceDetails.postalAddress = new Address();
    }

    if (!this.branch.invoiceDetails.physicalAddress) {
      this.branch.invoiceDetails.physicalAddress = new Address();
    }

    if (!this.branch.invoiceDetails) {
      this.branch.invoiceDetails = new InvoiceDetails();
    }
  }
}
