<div class="ui-g ui-fluid">
    <div class="ui-g">
        <div class="ui-g-12">
            <p-panel header="">
                <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-6">
                        <span class="md-inputfield md-inputfield-box">
                            <input [(ngModel)]="businessEntity.code" type="text"
                                pInputText>
                            <label>BE Code</label>
                        </span>
                    </div>

                    <div class="ui-g-12 ui-md-6">
                        <span class="md-inputfield md-inputfield-box">
                            <input [(ngModel)]="creditor.externalCreditorCode" type="text"
                                pInputText>
                            <label>External Creditor Code</label>
                        </span>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                        <digi-currency [currency]="creditor?.currency" (onSelect)="selectCurrency($event)"></digi-currency>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                        <span class="ui-float-label">
                            <p-autoComplete
                                [suggestions]="filteredExhangeRateTypes"
                                field="label" [dropdown]="true"
                                [autoHighlight]="true"
                                (completeMethod)="searchExchangeRateType($event)"
                                [minLength]="2"
                                [(ngModel)]="creditor.exchangeRateType"
                                (onSelect)="selectExchangeRateType($event)"
                                [forceSelection]="true"
                                required=true>
                                <ng-template let-option pTemplate="item">
                                    <div>{{option.type}}:{{option.description}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <label>Exchange Rate Type</label>
                        </span>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                        <span class="ui-float-label">
                            <p-autoComplete minLength="2" field="label"
                                [(ngModel)]="creditor.defaultTaxType"
                                [suggestions]="filteredTaxTypes"
                                (completeMethod)="searchTaxTypeCode($event)"
                                (onSelect)="selectedTaxType($event)"
                                [dropdown]="true" [forceSelection]="true">
                                <ng-template let-option pTemplate="item">
                                    <div>{{option.label}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <label>Default Tax Type</label>
                        </span>
                    </div>
                </div>
            </p-panel>
        </div>
    </div>
</div>