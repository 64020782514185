<h1 style="text-align: center">Please Choose Transport Type</h1>
<div class="row">
  <div class="col-sm-6">
    <a routerLink="/air/register" class="nav-link travel-type" title="Air">
      <i class="fa fa-plane fa-3x"></i> Air
    </a>
  </div>
  <div class="col-sm-6">
    <a routerLink="/ocean" class="nav-link travel-type mb-2" title="Ocean">
      <i class="fa fa-ship fa-3x"></i> Ocean
    </a>
  </div>
</div>
<div class="row">
  <div class="col-sm-6">
    <a routerLink="/rail" class="nav-link travel-type" title="Rail">
      <i class="fa fa-train fa-3x"></i> Rail
    </a>
  </div>
  <div class="col-sm-6">
    <a routerLink="/road" class="nav-link travel-type" title="Road">
      <i class="fa fa-road fa-3x"></i> Road
    </a>
  </div>
</div>
