/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./app.breadcrumb.component";
import * as i4 from "./breadcrumb.service";
import * as i5 from "keycloak-angular";
import * as i6 from "./digi-common/services/notification.service";
var styles_AppBreadcrumbComponent = [];
var RenderType_AppBreadcrumbComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppBreadcrumbComponent, data: {} });
export { RenderType_AppBreadcrumbComponent as RenderType_AppBreadcrumbComponent };
function View_AppBreadcrumbComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["home"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AppBreadcrumbComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.items[0] == null) ? null : _co.items[0].routerLink); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "", ((_co.items[0] == null) ? null : _co.items[0].icon), ""); _ck(_v, 2, 0, currVal_3); }); }
function View_AppBreadcrumbComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_3); }); }
function View_AppBreadcrumbComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }); }
function View_AppBreadcrumbComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"]))], null, null); }
function View_AppBreadcrumbComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_7)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.routerLink; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.routerLink; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 6, 0, currVal_2); }, null); }
function View_AppBreadcrumbComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_4)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.items[0] == null) ? null : _co.items[0].items); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppBreadcrumbComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "layout-breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_2)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_3)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "layout-breadcrumb-options"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["title", "Logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["power_settings_new"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.items; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.items; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.items; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_AppBreadcrumbComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-app-breadcrumb", [], null, null, null, View_AppBreadcrumbComponent_0, RenderType_AppBreadcrumbComponent)), i0.ɵdid(1, 180224, null, 0, i3.AppBreadcrumbComponent, [i4.BreadcrumbService, i5.KeycloakService, i6.NotificationService], null, null)], null, null); }
var AppBreadcrumbComponentNgFactory = i0.ɵccf("digi-app-breadcrumb", i3.AppBreadcrumbComponent, View_AppBreadcrumbComponent_Host_0, {}, {}, []);
export { AppBreadcrumbComponentNgFactory as AppBreadcrumbComponentNgFactory };
