import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Formula, FormulaParameter, TariffFormula, TariffFormulaPK } from '../app/digi-common/models/TariffCode';

@Injectable({
  providedIn: 'root'
})
export class TariffFormulaSubjectBehavior {
  private tariffFormulaSubject = new BehaviorSubject<TariffFormula>(this.buildTariffFormula());

  constructor() {
  }

  getTariffFormula(): Observable<TariffFormula> {
    return this.tariffFormulaSubject.asObservable();
  }

  addTariffFormula(tariffFormula: TariffFormula) {
    this.tariffFormulaSubject.next(tariffFormula);
  }

  buildTariffFormula() {
    const tariffFormula = new TariffFormula();
    tariffFormula.id = new TariffFormulaPK();
    tariffFormula.formula = new Formula();
    tariffFormula.formula.parameters = new Array<FormulaParameter>();
    return tariffFormula;
  }
}
