import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ShareDataService } from '../app/digi-common/services/share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/services/share-data.service";
var ExportFileBehaviourSubject = /** @class */ (function () {
    function ExportFileBehaviourSubject(shareDataService) {
        this.shareDataService = shareDataService;
        this.exportFileSubject = new BehaviorSubject(this.shareDataService.buildExportFile());
        this.debtorsSubject = new Subject();
        this.consigneesSubject = new Subject();
        this.overseasAgentsSubject = new Subject();
        this.exporterConsignorSubject = new Subject();
        this.warehousesSubject = new Subject();
        this.removersSubject = new Subject();
        this.guarantorSubject = new Subject();
    }
    ExportFileBehaviourSubject.prototype.addExportFile = function (exportFile) {
        this.exportFileSubject.next(exportFile);
    };
    ExportFileBehaviourSubject.prototype.getExportFile = function () {
        return this.exportFileSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addDebtors = function (debtors) {
        this.debtorsSubject.next(debtors);
    };
    ExportFileBehaviourSubject.prototype.getDebtors = function () {
        return this.debtorsSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.getExportConsignees = function () {
        return this.consigneesSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addExportConsignees = function (consignees) {
        this.consigneesSubject.next(consignees);
    };
    ExportFileBehaviourSubject.prototype.addOverseasAgents = function (overseasAgents) {
        this.overseasAgentsSubject.next(overseasAgents);
    };
    ExportFileBehaviourSubject.prototype.getOverseasAgents = function () {
        return this.overseasAgentsSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addExporterConsignor = function (exporterConsignors) {
        this.exporterConsignorSubject.next(exporterConsignors);
    };
    ExportFileBehaviourSubject.prototype.getExporterConsignor = function () {
        return this.exporterConsignorSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addWarehouses = function (warehouses) {
        this.warehousesSubject.next(warehouses);
    };
    ExportFileBehaviourSubject.prototype.getWarehouses = function () {
        return this.warehousesSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addRemovers = function (warehouses) {
        this.removersSubject.next(warehouses);
    };
    ExportFileBehaviourSubject.prototype.getRemovers = function () {
        return this.removersSubject.asObservable();
    };
    ExportFileBehaviourSubject.prototype.addGuarantors = function (guarantors) {
        this.guarantorSubject.next(guarantors);
    };
    ExportFileBehaviourSubject.prototype.getGuarantors = function () {
        return this.guarantorSubject.asObservable();
    };
    ExportFileBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function ExportFileBehaviourSubject_Factory() { return new ExportFileBehaviourSubject(i0.inject(i1.ShareDataService)); }, token: ExportFileBehaviourSubject, providedIn: "root" });
    return ExportFileBehaviourSubject;
}());
export { ExportFileBehaviourSubject };
