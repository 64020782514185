import { Component, Input, OnInit } from '@angular/core';
import { SundryCharge } from '../../../models/file';
import { SundryChargesService } from '../../../services/sundry-charges.service';

@Component({
  selector: 'digi-sundry-search',
  templateUrl: './sundry-search.component.html',
  styleUrls: ['./sundry-search.component.scss']
})
export class SundrySearchComponent implements OnInit {
  selectedSundryCharge: SundryCharge;
  @Input() sundryChargeType;
  @Input() sundryCharge: SundryCharge;
  filteredSundryCharges: SundryCharge[];

  constructor(private sundryChargesService: SundryChargesService) {
  }

  ngOnInit() {
    this.selectedSundryCharge = this.sundryCharge;
  }

  search(event) {
    this.sundryChargesService.findAllSundryChargesStartWith(event.query, this.sundryChargeType).subscribe(data => {
      this.filteredSundryCharges = data;
    });
  }

  selectSundryCharge(value) {
    this.sundryCharge.code = value.code;
    this.sundryCharge.description = value.description;
  }
}
