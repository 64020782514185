<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     [(ngModel)]="selectedContainer.zone">
              <label>Zone</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
            <p-dropdown [options]="sealingParties"
                        [(ngModel)]="selectedContainer.sealDetails.sealingParty"
                        [autoWidth]="false">
            </p-dropdown>
             <label>Sealing Party</label>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
              <p-dropdown [options]="sealStatuses"
                          [(ngModel)]="selectedContainer.sealDetails.sealStatus"
                          [autoWidth]="false">
            </p-dropdown>
            <label>Seal Status</label>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
          <p-dropdown [options]="sealTypes"
                      [(ngModel)]="selectedContainer.sealDetails.sealType"
                      [autoWidth]="false">
          </p-dropdown>
            <label>Seal Type</label>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


