<div class="ui-g-12 ui-md-12">
  <span class="md-inputfield">
          <span class="ui-float-label">
                <p-autoComplete id="guaranteeType" [(ngModel)]="selectedGuaranteeType" field="label" [dropdown]="true"
                                [suggestions]="filteredGuaranteeTypes"
                                (completeMethod)="search($event)"
                                [autoHighlight]="true"
                                (onSelect)="select($event)"
                                minLength="1"
                                [forceSelection]="true"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.label}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="guaranteeType">GuaranteeType</label>
          </span>
     <div *ngIf="!selectedGuaranteeType?.code" class="ui-message ui-messages-error ui-corner-all">
          This field is required
      </div>
  </span>
</div>
