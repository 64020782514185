<div class="ui-g-12">
  <p-table [columns]="[{}]" [value]="voyageMasterFile.voyageMasterFilePackages" [(selection)]="package"
           [paginator]="true" [rows]="4"
           selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th>Line</th>
        <th>Description</th>
        <th>Quantity</th>
        <th>CQDesc</th>
        <th>Weight</th>
        <th>Volume</th>
        <th>Container No</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package let-rowIndex="rowIndex">
      <tr [pSelectableRow]="package">
        <td class="ui-resizable-column">
          {{rowIndex + 1}}
        </td>
        <td class="ui-resizable-column">
          <label>{{package.description}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{package.noOfPackages}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{package.packageType?.description}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{package.weight}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{package.volume}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{package.containerNo}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel header="Package Details">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.description">
                      <label>Package Description</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.vinNumber">
                      <label>Vin Number</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.referenceNo">
                      <label>LRN/MRN</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.noOfPackages">
                      <label>No. Of Packages</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.ucrNumber">
                      <label>UCR Number</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.weight">
                      <label>Package Weight</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.volume">
                      <label>Package Volume</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="packType" [(ngModel)]="package.packageType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredPackTypes"
                                    (completeMethod)="searchPackTypes($event)"
                                    (onSelect)="selectPackType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="packType">Package Type</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.containerNo">
                      <label>Container No.</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="package.containerType" field="description"
                                  [suggestions]="filteredContainerTypes"
                                  (completeMethod)="searchContainerTypes($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true"
                                  required=true (onSelect)="selectContainerType($event)"
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}: {{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                   <label>Container Type</label>
            </span>
              </div>
            </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete id="tariff" [(ngModel)]="package.tariffCode" field="code"
                                      [suggestions]="filteredTariffCodes"
                                      (completeMethod)="searchTariffCode($event)"
                                      [autoHighlight]="true"
                                      (onSelect)="selectTariffCode($event)"
                                      minLength="3"
                                      required="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.code}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="tariff">Tariff Code</label>
                  </span>
                  <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                </div>
            </span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Goods Description">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.code">
                      <label>Code</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.undgCode">
                      <label>UNDGCode</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.imoPageNo">
                      <label>IMO Page No.</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="package.goodsDescription.description" [rows]="3" [cols]="50"
                                    placeholder="Description"
                                    style="resize: none">
                          </textarea>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.unDangerousGoodsNo">
                      <label>UN Dangerous Goods No.</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.hazardCodeVersionNo">
                      <label>Hazard Code Version No.</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.undgFlashPoint">
                      <label>UNDG Flash Point</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="package.goodsDescription.undgFlashPointUnit">
                      <label>UNDG Flash Point Unit</label>
                    </span>
                </span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
