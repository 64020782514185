import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { TransportDocument } from '../../../models/file';
import { CollectPrepaidService } from '../../../services/collect-prepaid.service';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';

@Component({
  selector: 'digi-collect-or-prepaid-search',
  templateUrl: './collect-or-prepaid-search.component.html',
  styleUrls: ['./collect-or-prepaid-search.component.scss']
})
export class CollectOrPrepaidSearchComponent implements OnChanges {
  filteredCollectPrepaids = [];
  subscription: Subscription;
  @Input() freight;
  @Input() required = true;

  constructor(private collectPrepaidService: CollectPrepaidService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.freight && changes.freight.currentValue) {
      this.freight = changes.freight.currentValue;
    }
  }

  search(event) {
    this.collectPrepaidService.findCollectPrepaidStartWith(event.query).subscribe(data => {
      this.filteredCollectPrepaids = data;
    });
  }

  select(value) {
    this.freight.collectPrepaid.code = value.code;
    this.freight.collectPrepaid.description = value.description;
  }
}


