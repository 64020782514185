import {Component, OnDestroy, OnInit} from '@angular/core';
import {Container} from '../../../models/file';
import {MasContainerTypeService} from '../../../../digi-ocean/services/mas-container-type.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-mas-container-type',
  templateUrl: './mas-container-type.component.html',
  styleUrls: ['./mas-container-type.component.scss']
})
export class MasContainerTypeComponent implements OnInit, OnDestroy {
  filteredMASContainerTypes = [];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private masContainerTypeService: MasContainerTypeService,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => this.selectedContainer = ct);
  }

  search(code) {
    this.masContainerTypeService
      .findAllMASContainerTypeStartsWith(code.query)
      .subscribe(data => {
        this.filteredMASContainerTypes = data;
      });
  }

  select(value) {
    this.selectedContainer.masContainerType = value;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
