import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortEvent} from 'primeng/api';
import {Subscription} from 'rxjs';
import {TariffCodeBehaviorSubject} from '../../../../subjects/tariffCode-behavior-subject';
import {TariffFormulaSubjectBehavior} from '../../../../subjects/TariffFormula-subject-behavior';
import {Formula} from '../../models/billOfEntries';
import {TradeAgreement} from '../../models/file';
import {FormulaParameter, TariffCodeReference, TariffFormula, TariffParameter, TariffParameterPK} from '../../models/TariffCode';
import {FormulaService} from '../../services/formula.service';
import {NotificationService} from '../../services/notification.service';
import {TariffRefService} from '../../services/tariff-ref.service';
import {TariffService} from '../../services/tariff.service';
import {TradeAgreementService} from '../../services/trade-agreement.service';
import {UomService} from '../../services/uom.service';

@Component({
  selector: 'digi-tariff-formulae-table',
  templateUrl: './tariff-formulae-table.component.html',
  styleUrls: ['./tariff-formulae-table.component.scss'],

})
export class TariffFormulaeTableComponent implements OnInit, OnDestroy {
  cols: any[];
  paramCols: any[];
  selectedTariffCode: TariffCodeReference;
  selectedFormula: TariffFormula;
  parameters = [];
  filteredTradeAgreements = [];
  filteredUoms = [];
  filteredFormulas = [];
  filteredtariffRefs = [];
  tradeAgreement: TradeAgreement;
  isRowSelect: boolean;
  formula: Formula;
  subscription: Subscription;
  tariffAndFormulaParams = [];
  customsCode: string;

  constructor(private tariffCodeBehaviorSubject: TariffCodeBehaviorSubject,
              private tradeAgreementService: TradeAgreementService,
              private uomService: UomService,
              private tariffRefService: TariffRefService,
              private formulaService: FormulaService,
              private tariffService: TariffService,
              private tariffFormulaSubjectBehavior: TariffFormulaSubjectBehavior,
              private messageService: NotificationService) {
  }

  ngOnInit() {
    this.cols = [
      {field: {id: 'tradeGroupCode'}, header: 'Trade Agreement', width: '30%'},
      {field: 'number', header: 'Formula', width: '10%'},
      {field: {id: 'formulaEffDate'}, header: 'Effective Date', width: '40%'},
      {field: 'effectiveUntil', header: 'Effective Until Date', width: '40%'},
      {field: 'description', header: 'Formula Description', width: '40%'}
    ];

    this.paramCols = [
      {header: 'Parameter', width: '50%'},
      {header: 'Value', width: '50%'}
    ];
    this.subscription = this.tariffCodeBehaviorSubject
      .getTariffCode()
      .subscribe(tariff => {
        this.selectedTariffCode = tariff;
      });

    this.tariffFormulaSubjectBehavior
      .getTariffFormula()
      .subscribe(tariffFormula => {
        if (tariffFormula) {
          this.selectedFormula = tariffFormula;
        }
      });

    this.tariffRefService.findAll()
      .subscribe((data: Formula[]) => {
        this.filteredFormulas = data;
      });
  }

  selectTariffFormula(value) {
    this.tariffAndFormulaParams = [];
    this.isRowSelect = true;
    this.selectedFormula = this.selectedTariffCode.formulae[value.index];
    const formulaParams = this.findFormulaParamsForTariffParameters(this.selectedFormula.formula.parameters);
    this.tariffAndFormulaParams = formulaParams.map(formulaParam => {
        const tarrifParam = this.findTariffParameter(this.selectedTariffCode.tariffParameters, this.selectedFormula, formulaParam);
        return {
          tariffParam: tarrifParam,
          formulaParam: formulaParam
        };
      }
    );
    this.tariffFormulaSubjectBehavior.addTariffFormula(this.selectedFormula);
    this.findTradeAgreement(this.selectedFormula.id.tradeGroupCode);
    this.findTariffRef(this.selectedFormula.referenceCode);
    this.findUom(this.selectedFormula.uomCode);
    this.findFormula(this.selectedFormula.formula.number);


  }

  selectFormula(data) {
    this.tariffAndFormulaParams = [];
    const formulaParams = this.findFormulaParamsForTariffParameters(data.value.parameters);
    this.tariffAndFormulaParams = formulaParams.map(formulaParam => {
        let tariffParam = this.findTariffParameter(this.selectedTariffCode.tariffParameters, this.selectedFormula, formulaParam);
        if (!tariffParam) {
          tariffParam = new TariffParameter();
          tariffParam.id = new TariffParameterPK();
          tariffParam.id.tradeGroupType = this.selectedFormula.id.tradeGroupType;
          tariffParam.id.tradeGroupCode = this.selectedFormula.id.tradeGroupCode;
          tariffParam.id.effectiveDate = this.selectedFormula.id.formulaEffDate;
          tariffParam.id.tariffId = this.selectedFormula.id.tariffId;
          tariffParam.id.number = formulaParam.id.number;
        }
        return {
          tariffParam: tariffParam,
          formulaParam: formulaParam
        };
      }
    );
    this.fillFormulaString();
  }

  fillFormulaString() {
    let formulaString = this.selectedFormula.formula.string;
    const paramNumbers = [];
    const re = /@([0-9]+)/g;
    let m;

    do {
      m = re.exec(formulaString);
      if (m) {
        paramNumbers.push(m[1]);
      }
    } while (m);

    paramNumbers.forEach(e => {
      const number = parseInt(e);
      let param = this.tariffAndFormulaParams.find(p => {
        if (p.formulaParam.id.number === number) {
          return true;
        } else {
          return false;
        }
      });

      let value = '';
      if (param && param.tariffParam && param.tariffParam.value1) {
        value = param.tariffParam.value1;
      } else {
        param = this.selectedFormula.formula.parameters.find(p => {
          if (p.id.number === number) {
            return true;
          } else {
            return false;
          }
        });
        if (param && param.dataType === 'SU') {
          value = this.customsCode;
        }
      }
      formulaString = formulaString.replace(`@${e}`, value);
    });
    this.selectedFormula.filledFormulaString1 = formulaString;
  }

  private findTariffParameter(tariffParameters: TariffParameter[], selectedFormula: TariffFormula, formulaParam: FormulaParameter) {
    return tariffParameters.find(tp => {
      return tp.id.tariffId === selectedFormula.id.tariffId
        && tp.id.effectiveDate === selectedFormula.id.formulaEffDate
        && tp.id.tradeGroupCode === selectedFormula.id.tradeGroupCode
        && tp.id.tradeGroupType === selectedFormula.id.tradeGroupType
        && tp.id.number === formulaParam.id.number;
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = new Date(data1.id[event.field]);
      const value2 = new Date(data2.id[event.field]);
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  search(event) {
    this.tradeAgreementService.findTradeAgreementByCodeStartWith(event.query)
      .subscribe(data => {
        this.filteredTradeAgreements = data;
      });
  }

  select(value) {
    this.selectedFormula.id.tradeGroupCode = value.code;
    this.selectedFormula.id.tradeGroupType = 'TRADEAGRMT';
  }

  selectUnitMeasure(value) {
    this.findUom(value.uomCode);
    this.customsCode = value.customsUomCode;
    this.fillFormulaString();
  }

  selectReferenceCode(value) {
    this.selectedFormula.referenceCode = value.referenceCode;
  }

  findTradeAgreement(value) {
    this.tradeAgreementService.findByCodeIgnoreCase(value).subscribe(data => {
      this.selectedFormula.id.tradeGroupCode = data.code;
    });
  }

  findUom(value) {
    if (value.uomCode) {
      this.uomService.findByCode(value.uomCode).subscribe(data => {
        this.selectedFormula.uomCode = data.uomCode;
      });
    } else {
      this.uomService.findByCode(value).subscribe(data => {
        this.selectedFormula.uomCode = data.uomCode;
      });
    }
  }

  findTariffRef(value) {
    this.tariffRefService.findByCode(value).subscribe(data => {
      this.selectedFormula.referenceCode = data.referenceCode;
    });
  }

  findFormula(value) {
    this.formulaService.findByFormulaNo(value).subscribe(data => {
      this.selectedFormula.formula = data;
    });
  }

  searchUnitMeasure(event) {
    this.uomService.findUomByCodeStartWith(event.query)
      .subscribe(data => {
        this.filteredUoms = data;
      });
  }

  searchReferenceCode(event) {
    this.tariffRefService.findByReferenceCodeStartWith(event.query)
      .subscribe(data => {
        this.filteredtariffRefs = data;
      });
  }

  createNewTariffFormula() {
    this.selectedFormula = this.tariffFormulaSubjectBehavior.buildTariffFormula();
    this.selectedFormula.id.tariffId = this.selectedTariffCode.tariffID;
    this.tariffCodeBehaviorSubject.addTariffCode(this.selectedTariffCode);
    this.selectedTariffCode.formulae.push(this.selectedFormula);
  }

  saveNewTariffFormula() {
    let tariffFormula: TariffFormula;
    const tariffParameters = [];
    tariffFormula = this.selectedFormula;
    tariffFormula.uomCode = this.selectedFormula.uomCode;
    tariffFormula.referenceCode = this.selectedFormula.referenceCode;
    tariffFormula.filledFormulaString1 = this.selectedFormula.filledFormulaString1;
    this.tariffAndFormulaParams.forEach(ts => {
      tariffParameters.push(ts.tariffParam);
    });
    const tariffFormulaAndTariffParameter = {
      tariffFormula: tariffFormula,
      tariffParameters: tariffParameters
    };
    this.tariffService.saveTariffFormula(tariffFormulaAndTariffParameter)
      .subscribe((data) => {
          this.addSuccess();
        },
        (error) => {

        });

  }

  private findFormulaParamsForTariffParameters(parameters) {
    return parameters.filter(p => {
      if (p.dataType === 'I' || p.dataType === 'U') {
        return true;
      }
      return false;
    });
  }

  addSuccess() {
    this.messageService.successNotify(
      'Successfully Saved'
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
