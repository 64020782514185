<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <digi-country-of-origin (onSelect)="selectCountryOfOrigin($event)"
                                  [country]="selectedCountry"
                                  [isQuotation]="isQuotation"></digi-country-of-origin>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-currency (onSelect)="selectCurrency($event)" [currency]="selectedCurrency" [required]="!isQuotation"></digi-currency>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-purchase-terms
            [purchaseTerm]="selectedSupplierInvoice.defaultValues.purchaseTerm" [isQuotation]="isQuotation"></digi-purchase-terms>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
