<div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
            <p-autoComplete appendTo="body" [baseZIndex]="10000" [(ngModel)]="selectedSundryCharge" [dropdown]="true" field="description" [suggestions]="filteredSundryCharges"
                (completeMethod)="search($event)" [autoHighlight]="true" (onSelect)="selectSundryCharge($event)" minLength="1">
                <ng-template let-option pTemplate="item">
                    <div>{{option.code}}: {{option.description}}</div>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>
</div>
