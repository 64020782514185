import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FileService } from './file.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "./file.service";
var SchedulePartService = /** @class */ (function () {
    function SchedulePartService(http, config, fileService) {
        this.http = http;
        this.config = config;
        this.fileService = fileService;
    }
    SchedulePartService.prototype.getScheduleParts = function () {
        return this.http.get(this.config.apiEndpoint + "api/scheduleParts/search/chargeRebateStartsWith?chargeRebate=s").pipe(map(function (response) { return response['_embedded'].scheduleParts; }));
    };
    SchedulePartService.prototype.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate = function (customsPurposeCode, tariffCode, chargeRebate) {
        var _this = this;
        return this.http.get(this.config.apiEndpoint + "api/tariffCodes/search/additionalByCustomsPurposeCodeAndTariffCodeAndChargeRebate?customsPurposeCode=" + customsPurposeCode.replace('#', '') +
            ("&code=" + tariffCode + "&chargeRebate=" + chargeRebate + "&projection=tariffCodeWithFullDescription")).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].tariffCodes ? response['_embedded'].tariffCodes : []; })).pipe(tap(function (_) { return _this.fileService.setIsLoadingAdditionalSchedules(false); }));
    };
    SchedulePartService.prototype.findRebateOrScheduleByTariffCodeAndChargeRebate = function (tariffCode, chargeRebate) {
        var _this = this;
        return this.http.get(this.config.apiEndpoint + "api/tariffCodes/search/additionalByTariffCodeAndChargeRebate?&code=" + tariffCode + "&chargeRebate=" + chargeRebate + "&projection=tariffCodeWithFullDescription").pipe(map(function (response) { return response['_embedded'] && response['_embedded'].tariffCodes ? response['_embedded'].tariffCodes : []; })).pipe(tap(function (_) { return _this.fileService.setIsLoadingAdditionalSchedules(false); }));
    };
    SchedulePartService.prototype.findBySchedulePart = function (schedulePart) {
        if (schedulePart === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/scheduleParts/search/findBySchedulePart?code=" + schedulePart)
            .pipe(map(function (response) { return response['_embedded'].scheduleParts; }));
    };
    SchedulePartService.ngInjectableDef = i0.defineInjectable({ factory: function SchedulePartService_Factory() { return new SchedulePartService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.FileService)); }, token: SchedulePartService, providedIn: "root" });
    return SchedulePartService;
}());
export { SchedulePartService };
