import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentsReportService} from '../../services/payments-report.service';
import {NotificationService} from '../../services/notification.service';
import {ImporterService} from '../../services/importer.service';
import {CustomOfficeService} from '../../services/custom-office.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';
import {CustomsOffice, Importer} from '../../../digi-business-entity/models/business-entity';
import {Subscription} from 'rxjs';
import {BillOfEntry, SequenceData} from '../../models/billOfEntries';
import {ExportsReportService} from '../../services/exports-report.service';

@Component({
  selector: 'digi-export-registration-reports',
  templateUrl: './export-registration-reports.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  `]
})
export class ExportRegistrationReportsComponent implements OnInit, OnDestroy {
  fromDate: string;
  toDate: string;
  display = false;
  pdfSrc: string;
  disableButton: boolean;
  private exportReportsSubscription: Subscription;

  constructor(
    private exportReportsService: ExportsReportService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
  ) {
  }

  ngOnInit() {
  }

  generate(isSea: boolean) {
    this.disableButton = true;
    this.exportReportsSubscription = this.exportReportsService.generateExportRegistrationReports(this.fromDate, this.toDate, isSea).subscribe(data => {
      this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
    },
    error => {
      this.notificationService.errorNotify(error.error.message, error.error.errors);
      this.pdfSrc = null;
      this.disableButton = false;
    });
  }

  showDialog() {
    this.display = true;
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  ngOnDestroy(): void {
    if (this.exportReportsSubscription) {
      this.exportReportsSubscription.unsubscribe();
    }
  }
}
