<p-table [columns]="cols" [value]="clearingFile.transportDocuments" selectionMode="single"
         [(selection)]="selectedTransport"
         (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="3" columnResizeMode="expand">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{col.header}}
      </th>
      <th style="width: 4%">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
      <td>
        {{rowData?.hawbNo}}
      </td>
      <td>
        {{rowData?.mawbNo}}
      </td>
      <td>
        {{rowData?.clientRefNo}}
      </td>
      <td class="delete">
        <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
