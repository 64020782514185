import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../subjects/clearingfile-behaviour-subject';
import {switchMap, take} from 'rxjs/operators';
import {ExBondFileBehaviourSubject} from '../../subjects/exbondfile-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class ExBondFileExistsGuard implements CanActivate {
  constructor(
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.exBondFileBehaviourSubject.getExBondFile().pipe(
      take(1),
      switchMap(ef => {
        if (ef.id) {
          return of(true);
        } else {
          const parent = state.root.firstChild.routeConfig.path;
          this.router.navigate([parent, 'register']);
          return of(false);
        }
      })
    );
  }
}
