import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';
import { BillOfEntry, BillOfEntryLine, BoeAddInfoType } from '../../models/billOfEntries';
import { AdditionalInformationService } from '../../services/additional-information.service';
import { ShareDataService } from '../../services/share-data.service';
import {LazyLoadEvent} from 'primeng/api';

@Component({
  selector: 'digi-boe-line-additional-info',
  templateUrl: './boe-line-additional-info.component.html',
  styleUrls: ['./boe-line-additional-info.component.scss'],
})
export class BoeLineAdditionalInfoComponent implements OnInit, OnDestroy {
  selectedBillOfEntryLine: BillOfEntryLine;
  display = false;
  cols: any[];
  additionalInformation: BoeAddInfoType;
  billOfEntry: BillOfEntry;
  boeAddInfoTypes: BoeAddInfoType[];
  selectedBoeInfoTypes: BoeAddInfoType[];
  billSubscription: Subscription;
  totalRecords: any;

  constructor(private additionalInformationService: AdditionalInformationService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private shareDataService: ShareDataService,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
        if (this.selectedBillOfEntryLine && this.selectedBillOfEntryLine.additionalInformation
          && this.selectedBillOfEntryLine.additionalInformation.length > 0) {
          this.populateSelectedTypes();
        } else {
          this.selectedBoeInfoTypes = [];
        }
        this.updateTableDisplay();
      });
  }

  private updateTableDisplay() {
    this.additionalInformationService.getAdditionalInformation(0).subscribe(data => {
      this.boeAddInfoTypes = data._embedded.boeAddInfoTypes;
      this.selectedBoeInfoTypes.forEach(l => {
        const type = this.boeAddInfoTypes.find(t => t.code === l.code);
        if (type) {
          type.value = l.value;
        } else {
          this.boeAddInfoTypes = this.selectedBillOfEntryLine.additionalInformation.concat(this.boeAddInfoTypes);
        }
      });
    });
  }

  private populateSelectedTypes() {
    this.selectedBoeInfoTypes = [];
    this.selectedBillOfEntryLine.additionalInformation.map(i => {
      const type = Object.assign(new BoeAddInfoType(), i);
      this.selectedBoeInfoTypes.push(type);
    });
    this.selectedBoeInfoTypes = [...this.selectedBoeInfoTypes];
  }

  addAdditionalInformation() {
    this.display = true;
    this.cols = [
      {field: '', header: '', width: '5%'},
      {field: 'code', header: 'Code', width: '15%'},
      {field: 'description', header: 'Description', width: '50%'},
      {field: 'value', header: 'Value', width: '25%'}
    ];
  }

  select() {
    this.display = false;
    this.selectedBillOfEntryLine.additionalInformation = this.selectedBoeInfoTypes;
    this.updateTableDisplay();
  }

  close() {
    this.populateSelectedTypes();
    this.updateTableDisplay();
    this.display = false;
  }

  onLazyLoad(event: LazyLoadEvent) {
    const pageNumber = event.first / event.rows;
    this.additionalInformationService.getAdditionalInformation(pageNumber).subscribe(data => {
      this.boeAddInfoTypes = data._embedded.boeAddInfoTypes;
      this.totalRecords = data.page.totalElements;
      this.selectedBoeInfoTypes.forEach(l => {
        const type = this.boeAddInfoTypes.find(t => t.code === l.code);
        if (type) {
          type.value = l.value;
        } else {
          this.boeAddInfoTypes = this.selectedBillOfEntryLine.additionalInformation.concat(this.boeAddInfoTypes);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.billSubscription) {
      this.billSubscription.unsubscribe();
    }
  }
}

