import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {RoadManifest, CuscarType} from '../../../digi-common/models/roadManifest';
import {ManifestService} from '../../../digi-common/services/manifest.service';
import {CuscarTypeService} from '../../../digi-common/services/cuscar-type.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {NotificationService} from '../../../digi-common/services/notification.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-header',
  templateUrl: './manifest-header.component.html',
  styles: [`
      .color-white {
          color: white !important;
      }

      ::-webkit-input-placeholder {
          color: white;
      }

      :-ms-input-placeholder {
          color: white;
      }

      ::placeholder {
          color: white;
      }`],
  encapsulation: ViewEncapsulation.None
})
export class ManifestHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() roadManifest: RoadManifest;
  @Output() onUpdateRoadManifest = new EventEmitter();
  @Output() onCreateNew = new EventEmitter();
  filteredRoadManifests: RoadManifest[];
  listManifestFiles: any[];
  disableButton: boolean;
  pdfSrc: string;
  displayVocDialog: boolean;
  @ViewChild('correctionList') correctionList: OverlayPanel;
  filteredCuscarTypes: CuscarType[];
  cuscarType: string;

  manifestServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;

  constructor(
    private manifestService: ManifestService,
    private cuscarTypeService: CuscarTypeService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
      if (this.roadManifest.cuscarType) {
        this.cuscarType = this.roadManifest.cuscarType.type;
      }
      this.selectRoadManifest(this.roadManifest);
    }
  }

  ngOnDestroy() {
    if (this.manifestServiceSubscription) {
      this.manifestServiceSubscription.unsubscribe();
    }

    if (this.cuscarTypeServiceSubscription) {
      this.cuscarTypeServiceSubscription.unsubscribe();
    }
  }

  searchRoadManifestsByQuery(event: any) {
    this.manifestServiceSubscription = this.manifestService.findByHawbOrManifestFileNoOrManifestNo(event.query).subscribe(
      data => this.filteredRoadManifests = data
    );
  }

  selectRoadManifest(value) {
    if (this.roadManifest.manifestFileNo) {
      this.manifestServiceSubscription = this.manifestService.loadAllCorrectionsIncludingOriginalManifestFile(this.roadManifest.manifestFileNo)
        .subscribe(data => {
          this.listManifestFiles = data;
        });
    }
    this.onUpdateRoadManifest.emit(value);
  }

  selectCorrection(value) {
    this.selectRoadManifest(value);
    this.correctionList.hide();
  }

  createNewRoadManifest() {
    this.onCreateNew.emit(true);
  }

  hasCarn() {
    return !!(this.roadManifest && this.roadManifest.carn);
  }

  saveRoadManifest() {
    this.manifestServiceSubscription = this.manifestService.saveRoadManifest(this.roadManifest).subscribe(
      (roadManifest: RoadManifest) => {
        this.roadManifest = roadManifest;
        this.notificationService.successNotify('Manifest Saved Successfully');
        this.selectRoadManifest(this.roadManifest);
        this.disableButton = false;
      },
      _ => this.disableButton = false
    );
  }

  searchCuscarType(event: any) {
    this.cuscarTypeServiceSubscription = this.cuscarTypeService.findByTypeOrDescription(event.query).subscribe(
      data => this.filteredCuscarTypes = data
    );
  }

  selectCuscarType(value: CuscarType) {
    this.roadManifest.cuscarType = value;
  }

  updateRoadManifest(value: any) {
    this.roadManifest = value;
    this.onUpdateRoadManifest.emit(this.roadManifest);
  }
}
