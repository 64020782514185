import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CustomsOffice, Exporter, Importer} from '../../digi-business-entity/models/business-entity';
import {NotificationService} from '../services/notification.service';
import {ImporterService} from '../services/importer.service';
import {ExporterService} from '../services/exporter.service';
import {Subscription} from 'rxjs';
import {VocReportService} from '../services/voc-report.service';
import {File, Reference} from '../models/file';

@Component({
  selector: 'digi-voc-report',
  templateUrl: './voc-report.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white input{
      color: white !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  .page {
      border: 9px solid transparent !important;
      background-clip: content-box !important;
      -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
  }
  `],
  encapsulation: ViewEncapsulation.None
})
export class VocReportComponent implements OnInit {
  fromDate: string;
  toDate: string;
  display = false;
  pdfSrc: string;
  disableButton: boolean;
  cols: any[];
  importer: Importer;
  filteredImporters: Importer[];
  exporter: Exporter;
  filteredExporters: Exporter[];
  private reportSubscription: Subscription;
  generatedFiles: any[];
  @Input() file: File;
  filteredChannel: Reference[];
  activeIndex = 0;







  constructor(
    private vocReportService: VocReportService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
    private exporterService: ExporterService,
  ) {
  }

  ngOnInit() {
  }

  searchImporter(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }
  searchExporter(event) {
    this.exporterService.findExporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredExporters = data;
    });
  }

  generateImportVocReport() {
    this.disableButton = true;
    // tslint:disable-next-line:max-line-length
    this.reportSubscription = this.vocReportService.generateImporterVocReport(this.fromDate, this.toDate, this.importer ? this.importer.code : null).subscribe(data => {
        this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`; // to refresh link
        this.generatedFiles = data._embedded.supportingDocuments;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrc = null;
        this.disableButton = false;
      });
  }
  generateExportVocReport() {
    this.disableButton = true;
    // tslint:disable-next-line:max-line-length
    this.reportSubscription = this.vocReportService.generateExporterVocReport(this.fromDate, this.toDate, this.exporter ? this.exporter.code : null).subscribe(data => {
        this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`; // to refresh link
        this.generatedFiles = data._embedded.supportingDocuments;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrc = null;
        this.disableButton = false;
      });
  }

  resetImportPanel() {
    this.importer = null;
    this.fromDate = null;
    this.toDate = null;
    this.pdfSrc = null;
  }
  resetExportPanel() {
    this.exporter = null;
    this.fromDate = null;
    this.toDate = null;
    this.pdfSrc = null;
  }
  onTabChange(event) {
    if (this.activeIndex === 0) {
      this.resetExportPanel();
    } else if (this.activeIndex === 1) {
      this.resetImportPanel();
    }
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }
}
