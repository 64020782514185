import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ClearingInstruction, Consignee, RebateUser, Remover } from '../app/digi-common/models/file';
import * as i0 from "@angular/core";
var ClearingInstructionBehaviorSubject = /** @class */ (function () {
    function ClearingInstructionBehaviorSubject() {
        this.clearingInstructionSubject = new BehaviorSubject([]);
        this.rebateUsersSubject = new ReplaySubject(1);
        this.warehousesSubject = new ReplaySubject(1);
        this.removersSubject = new ReplaySubject(1);
        this.importerConsigneeSubject = new ReplaySubject(1);
        this.instructionSubject = new BehaviorSubject(ClearingInstruction);
    }
    ClearingInstructionBehaviorSubject.prototype.addClearingInstructions = function (clearingInstructions) {
        this.clearingInstructionSubject.next(clearingInstructions);
    };
    ClearingInstructionBehaviorSubject.prototype.getClearingInstructions = function () {
        return this.clearingInstructionSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.prototype.addInstruction = function (clearingInstruction) {
        this.instructionSubject.next(clearingInstruction);
    };
    ClearingInstructionBehaviorSubject.prototype.getInstruction = function () {
        return this.instructionSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.prototype.addRebateUsers = function (rebateUsers) {
        this.rebateUsersSubject.next(rebateUsers);
    };
    ClearingInstructionBehaviorSubject.prototype.getRebateUsers = function () {
        return this.rebateUsersSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.prototype.addWarehouses = function (warehouses) {
        this.warehousesSubject.next(warehouses);
    };
    ClearingInstructionBehaviorSubject.prototype.getWarehouses = function () {
        return this.warehousesSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.prototype.addRemovers = function (remover) {
        this.removersSubject.next(remover);
    };
    ClearingInstructionBehaviorSubject.prototype.getRemovers = function () {
        return this.removersSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.prototype.addImporterConsignee = function (importerConsignee) {
        this.importerConsigneeSubject.next(importerConsignee);
    };
    ClearingInstructionBehaviorSubject.prototype.getImporterConsignee = function () {
        return this.importerConsigneeSubject.asObservable();
    };
    ClearingInstructionBehaviorSubject.ngInjectableDef = i0.defineInjectable({ factory: function ClearingInstructionBehaviorSubject_Factory() { return new ClearingInstructionBehaviorSubject(); }, token: ClearingInstructionBehaviorSubject, providedIn: "root" });
    return ClearingInstructionBehaviorSubject;
}());
export { ClearingInstructionBehaviorSubject };
