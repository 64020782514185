import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.errorNotificationChange = new Subject();
        this.successNotificationChange = new Subject();
    }
    NotificationService.prototype.errorNotify = function (summary, detail) {
        this.errorNotificationChange.next({ summary: summary, detail: detail });
    };
    NotificationService.prototype.successNotify = function (summary, detail) {
        this.successNotificationChange.next({ summary: summary, detail: detail });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
