<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-8">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                    <p-autoComplete id="containerSpecification" [(ngModel)]="selectedContainer.containerSpecification"
                                    field="label"
                                    [suggestions]="filteredContainerSpecifications"
                                    (completeMethod)="search($event)"
                                    [autoHighlight]="true" minLength="1"
                                    [dropdown]="true"
                                    [forceSelection]="true"
                                    required=true (onSelect)="select($event)"
                    >
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}}: {{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                   <label for="containerSpecification">Container Size</label>
                </span>
              </div>
                 <div *ngIf="(selectedContainer?.containerType?.description !== 'Break Bulk' && selectedContainer?.containerType?.description !== 'Bulk') && !selectedContainer?.containerSpecification?.containerSize?.code"
                      class="ui-message ui-messages-error ui-corner-all">This field is required
              </div>
          </span>
        </div>
        <div class="ui-g-12 ui-md-4">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
            <input type="text"
                   placeholder=""
                   pInputText
                   [ngModel]="selectedContainer?.containerSpecification?.containerSize?.size"
                   readonly>
              <label>Size(ft)</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
