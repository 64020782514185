import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Address, ClearingInstruction, Consignee, Guarantor} from '../../../../models/file';
import {Subscription} from 'rxjs';
import {ClearingInstructionBehaviorSubject} from '../../../../../../subjects/clearingInstruction-behavior-subject';
import {ImporterService} from '../../../../services/importer.service';
import {Importer} from '../../../../models/clearingFile';

@Component({
  selector: 'digi-importer-consignee',
  templateUrl: './importer-consignee.component.html',
  styleUrls: ['./importer-consignee.component.scss']
})
export class ImporterConsigneeComponent implements OnInit, OnDestroy {
  @Input() clearingInstruction: ClearingInstruction;
  filteredConsignees: Guarantor[];
  subscription: Subscription;
  roleId = 'Importer';
  importerConsignees: Importer[];
  importerConsigneeAddresses = [];

  constructor(private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private importerService: ImporterService
  ) {
  }

  ngOnInit() {
    this.subscription = this.clearingInstructionBehaviorSubject
      .getImporterConsignee().subscribe(importerConsignees => {
        this.importerConsignees = importerConsignees;
      });

    if (this.importerConsignees && this.importerConsignees.length === 1) {
      this.updateSelectedToConsignee(this.importerConsignees[0]);
    } else {
      this.clearingInstruction.consignee = new Consignee();
    }
  }

  search(event) {
    this.filteredConsignees = [];
    if (this.importerConsignees.length > 0) {
      for (let i = 0; i < this.importerConsignees.length; i++) {
        const importer: Importer = this.importerConsignees[i];
        if (
          importer.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
          importer.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        ) {
          this.filteredConsignees.push(importer);
        }
      }
    }
  }

  selectConsignee(importer) {
    this.updateSelectedToConsignee(importer);
  }

  private updateSelectedToConsignee(importer) {
    this.clearingInstruction.consignee = new Consignee();
    this.clearingInstruction.consignee.code = importer.code;
    this.clearingInstruction.consignee.name = importer.name;
    this.clearingInstruction.consignee.label = importer.label;
    this.clearingInstruction.consignee.roleType = this.roleId;

    this.importerService.findImporterNameOrCodeStartsWith(importer.code)
      .subscribe(importerConsignee => {
        if (importerConsignee) {
          this.clearingInstruction.consignee.taxRegNo = importerConsignee[0].taxRegNo;
          this.clearingInstruction.consignee.customsCode = importerConsignee[0].customsCode

          this.clearingInstruction.consignee.address = new Address();
          this.clearingInstruction.consignee.address.line1 = importerConsignee[0].addresses[0].line1;
          this.clearingInstruction.consignee.address.line2 = importerConsignee[0].addresses[0].line2;
          this.clearingInstruction.consignee.address.line3 = importerConsignee[0].addresses[0].line3;
          this.clearingInstruction.consignee.address.line4 = importerConsignee[0].addresses[0].line4;
          this.clearingInstruction.consignee.address.zipCode = importerConsignee[0].addresses[0].zipCode;
          this.importerConsigneeAddresses = importerConsignee[0].addresses;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
