<div class="layout-breadcrumb">
  <ul>
    <li><a routerLink="/" *ngIf="!items"><i class="material-icons">home</i></a></li>
    <li><a [routerLink]="items[0]?.routerLink" *ngIf="items"><i class="{{items[0]?.icon}}"></i></a></li>
    <li>/</li>
    <ng-template *ngIf="items" ngFor let-item let-last="last" [ngForOf]="items[0]?.items">
      <li>
        <a [routerLink]="item.routerLink" *ngIf="item.routerLink"><i class="{{item.icon}}"></i></a>
        <ng-container *ngIf="!item.routerLink">{{item.icon}}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>

  <div class="layout-breadcrumb-options">
    <a (click)="logout()" title="Logout">
      <i class="material-icons">power_settings_new</i>
    </a>
  </div>
</div>
