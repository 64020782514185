import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CargoType, ManifestType, VoyageFile, VoyageMasterFile} from '../../models/voyageFile';
import {Subscription} from 'rxjs';
import {ManifestService} from '../../services/manifest.service';
import {NotificationService} from '../../services/notification.service';
import {SendEdiToSarsService} from '../../services/send-edi-to-sars.service';
import {CuscarTypeService} from '../../services/cuscar-type.service';
import {EDIMessage} from '../../models/billOfEntries';
import {CuscarType} from '../../models/roadManifest';

@Component({
  selector: 'digi-ships-agent-reports',
  templateUrl: './ships-agent-reports.component.html',
  styleUrls: ['./ships-agent-reports.component.scss']
})
export class ShipsAgentReportsComponent implements OnInit, OnChanges {

  @Input() voyageFile: VoyageFile;
  @Input() indicator: string;
  @Output() updateVoyageMasterFile = new EventEmitter();
  selectedVoyageMasterFile = new VoyageMasterFile();
  display = false;
  generatedFiles: any = [];
  disableButton: boolean;

  colsForCuscar: any[];
  cuscars: string[];
  colsForContrl: any[];
  colsForCusres: any[];
  contrl = new EDIMessage();
  cusres = new EDIMessage();

  manifestServiceSubscription: Subscription;
  sendToSarsServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;
  filteredCuscarTypes: CuscarType[];
  manifestTypeOptions: any[];
  cargoTypeOptions: any[];

  constructor(
    private manifestService: ManifestService,
    private notificationService: NotificationService,
    private sendEdiToSarsService: SendEdiToSarsService,
    private cuscarTypeService: CuscarTypeService
  ) { }

  ngOnInit() {
    this.colsForContrl = [
      {header: 'Contrl message(s)'}
    ];

    this.colsForCuscar = [
      {header: 'Cuscar message(s)'}
    ];

    this.colsForCusres = [
      {header: 'Cusres message(s)'}
    ];

    const defaultValue = [{'label': '', 'value': ''}];
    this.manifestTypeOptions = defaultValue.concat(Object.keys(ManifestType).map(key => ({ 'label': ManifestType[key], 'value': key })));
    this.cargoTypeOptions = defaultValue.concat(Object.keys(CargoType).map(key => ({ 'label': CargoType[key], 'value': key })));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.voyageFile.currentValue) {
      this.voyageFile = changes.voyageFile.currentValue;
      this.selectVoyageMasterFile(this.voyageFile.voyageMasterFiles[0]);
    }
  }

  generateReports() {
    if (this.voyageFile && this.voyageFile.id && this.selectedVoyageMasterFile && this.selectedVoyageMasterFile.id) {
      this.disableButton = true;
      this.manifestServiceSubscription = this.manifestService
        .generateReports(this.voyageFile.id, this.selectedVoyageMasterFile.voyageMasterFileNo).subscribe(
        data => {
          this.generatedFiles = data._embedded.supportingDocuments;
          this.display = true;
          this.disableButton = false;
        },
        _ => this.disableButton = false
      );
    }
  }

  sendManifestToSARS() {
    if (this.selectedVoyageMasterFile.cuscarType  && this.selectedVoyageMasterFile.cuscarType.type) {
      if (this.selectedVoyageMasterFile && this.selectedVoyageMasterFile.id) {
        this.disableButton = true;
        this.notificationService.successNotify('Sending Manifest...');
        this.sendToSarsServiceSubscription = this.sendEdiToSarsService
          .sendShipsManifest(this.selectedVoyageMasterFile)
          .subscribe(data => {
              this.notificationService.successNotify('Manifest sent');
              this.refresh();
              this.disableButton = false;
            },
            _ => this.disableButton = false);
      }
    } else {
      this.notificationService.errorNotify(
        'Validation',
        ['Please Select Cuscar Type'],
      );
    }
  }

  hasCarn() {
    return !!(this.selectedVoyageMasterFile && this.selectedVoyageMasterFile.carn);
  }

  refresh() {
    setTimeout(() => {
      this.retrieveShipsManifestAfterEDI();
    }, 2000);
  }

  retrieveShipsManifestAfterEDI() {
    this.manifestServiceSubscription = this.manifestService
      .findByVoyageMasterFileNumber(this.selectedVoyageMasterFile.voyageMasterFileNo)
      .subscribe(data => {
          this.selectedVoyageMasterFile = data;

          this.cuscars = this.selectedVoyageMasterFile.cuscars ? this.selectedVoyageMasterFile.cuscars : [];

          if (this.selectedVoyageMasterFile.contrl) {
            this.selectedVoyageMasterFile.contrl.messages.reverse();
            this.contrl = this.selectedVoyageMasterFile.contrl;
          } else {
            this.contrl = new EDIMessage();
            this.contrl.messages = [];
          }

          if (this.selectedVoyageMasterFile.cusres) {
            this.selectedVoyageMasterFile.cusres.messages.reverse();
            this.cusres = this.selectedVoyageMasterFile.cusres;
          } else {
            this.cusres = new EDIMessage();
            this.cusres.messages = [];
          }
          this.updateVoyageMasterFile.emit(this.selectedVoyageMasterFile);
        },
        _ => this.disableButton = false
      );
  }

  selectVoyageMasterFile(voyageMasterFile: VoyageMasterFile) {
    this.selectedVoyageMasterFile = voyageMasterFile;
    if (voyageMasterFile.contrl) {
      this.contrl = voyageMasterFile.contrl;
    } else {
      this.contrl = new EDIMessage();
      this.contrl.messages = [];
    }

    if (voyageMasterFile.cusres) {
      this.cusres = voyageMasterFile.cusres;
    } else {
      this.cusres = new EDIMessage();
      this.cusres.messages = [];
    }

    if (voyageMasterFile.cuscars) {
      this.cuscars = voyageMasterFile.cuscars;
    } else {
      this.cuscars = [];
    }
  }

  searchCuscarType(event: any) {
    this.cuscarTypeServiceSubscription = this.cuscarTypeService.findByTypeOrDescription(event.query).subscribe(
      data => this.filteredCuscarTypes = data
    );
  }

  selectCuscarType(value: CuscarType) {
    this.selectedVoyageMasterFile.cuscarType = value;
  }

  format(value) {
    return value ? value.replace(/_/g, ' ').toLowerCase() : '';
  }

  saveVoyageMasterFile() {
    this.manifestService.saveVoyageMasterFile(this.selectedVoyageMasterFile).subscribe((voyageMasterFie: VoyageMasterFile) => {
      this.notificationService.successNotify('Saved Voyage Master File successfully');
      this.selectedVoyageMasterFile = voyageMasterFie;
    });
  }
}
