<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12">
      <p-panel header="Permits">
        <div class="content-section implementation">
          <p-table [value]="selectedBillOfEntry.permits" selectionMode="single"
                   [resizableColumns]="true" [(selection)]="selectedPermit">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>Permit No.</th>
                <th>Lines</th>
                <th>Value</th>
                <th>Quantity</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-permit let-columns="columns">
              <tr *ngIf="permit.number">
                <td class="ui-resizable-column">
                  <label>{{permit.number}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{permit.lineNumbers | permitNumbers}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{permit.permitLineValue}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{permit.permitLineQuantity}}</label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <br>
        <button pButton type="button" name="open" label="Allocate IRCC's/DCC's" *ngIf="fileType==='imports'"></button>
      </p-panel>
    </div>
  </div>
</div>
