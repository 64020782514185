<div class="ui-g">
  <div class="ui-g-12">
    <p-table #rebates [columns]="cols"
             [value]="scheduleParts"
             [resizableColumns]="true"
             [scrollable]="true" scrollHeight="200px">
      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="50" placeholder="Global
                Filter"
                 (input)="rebates.filterGlobal($event.target.value, 'contains')"
                 style="width:auto">
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width"/>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td>
            {{rowData?.schedulePart.code}}
          </td>
          <td>
            {{rowData?.dependantCode}}
          </td>
          <td>
            {{rowData?.dependantCheckDigit}}
          </td>
          <td>
            {{rowData?.schedulePart.description}}
          </td>
          <td>
            {{rowData?.effectiveTradeAgreementRebateFormulae?.STANDARD?.description}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
