import {Component, Input, OnInit} from '@angular/core';
import {BillOfEntryLine, ExportBillOfEntryLine} from '../../models/billOfEntries';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-boe-line-details',
  templateUrl: './boe-line-details.component.html',
  styleUrls: ['./boe-line-details.component.scss']
})
export class BoeLineDetailsComponent implements OnInit {
  selectedBillOfEntryLine: BillOfEntryLine|ExportBillOfEntryLine;
  @Input() fileType: FileType;
  @Input() markedAndSubmitted: boolean;
  cols: any[];

  constructor(private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject) {
  }

  ngOnInit() {
    this.cols = [
      {
        header: 'UOM Code',
      },
      {
        'header': 'value'
      }
    ];
    this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        if (boeLine.customsVAT === null) {
          boeLine.customsVAT = 0;
        }
        this.selectedBillOfEntryLine = boeLine;
      });
  }
}
