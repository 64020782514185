import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClearingFile } from '../../models/clearingFile';
import { ExBondFile } from '../../models/exBondFile';
import {ExportTransportDocument, TransportDocument} from '../../models/file';
import { ClearingFileService } from '../../services/clearing-file.service';
import { ShareDataService } from '../../services/share-data.service';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { ExBondFileBehaviourSubject } from '../../../../subjects/exbondfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';

@Component({
  selector: 'digi-marks-numbers',
  templateUrl: './marks-numbers.component.html',
  styleUrls: ['./marks-numbers.component.css']
})
export class MarksNumbersComponent implements OnInit, OnDestroy {
  @Input() label = 'HAWB No';
  @Input() filetype: string;
  transportDocument: TransportDocument|ExportTransportDocument;
  clearingFile: ClearingFile;
  exbondFile: ExBondFile;
  form: FormGroup;
  subscription: Subscription;
  ebSubscription: Subscription;
  tdSubscription: Subscription;

  constructor(private clearingFileService: ClearingFileService,
              private shareDataService: ShareDataService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe((cf: ClearingFile) => {
        this.clearingFile = cf;
      });
    if (this.filetype === 'exBond') {
      this.ebSubscription = this.exBondFileBehaviourSubject.getExBondFile()
      .subscribe((ebf: ExBondFile) => {
        this.exbondFile = ebf;
      });
    }
    this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
        if (td !== null && td !== undefined) {
          this.transportDocument = td;
        }
      });

    this.form = this.fb.group({
      marksAndNumbers: this.fb.array([
        this.initMarksAndNumbers(),
      ]),
      endorsements: this.fb.array([
        this.initEndorsements(),
      ]),
    });

    const marksAndNumbersArray = <FormArray>this.form.get('marksAndNumbers');
    const endorsementsArray = <FormArray>this.form.get('endorsements');

    let count = 0;

    if (this.filetype === 'exBond') {
      this.exbondFile.marksAndNumbers.forEach(element => {
        this.addMarkOrNumber();
        marksAndNumbersArray.controls[count].get('markOrNumber').setValue(element);
        count++;
      });
      count = 0;
      this.exbondFile.endorsements.forEach(element => {
        this.addEndorsement();
        endorsementsArray.controls[count].get('endorsement').setValue(element);
        count++;
      });
    } else {
      this.transportDocument.marksAndNumbers.forEach(element => {
        this.addMarkOrNumber();
        marksAndNumbersArray.controls[count].get('markOrNumber').setValue(element);
        count++;
      });
      count = 0;
      this.transportDocument.endorsements.forEach(element => {
        this.addEndorsement();
        endorsementsArray.controls[count].get('endorsement').setValue(element);
        count++;
      });
    }

  }

  initMarksAndNumbers() {
    return this.fb.group({
      markOrNumber: ['']
    });
  }

  initEndorsements() {
    return this.fb.group({
      endorsement: ['']
    });
  }

  addMarkOrNumber() {
    const control = <FormArray>this.form.get('marksAndNumbers');
    control.push(this.initMarksAndNumbers());
  }

  addEndorsement() {
    const control = <FormArray>this.form.get('endorsements');
    control.push(this.initEndorsements());
  }

  removeMarkOrNumber(i) {
    const control = <FormArray>this.form.get('marksAndNumbers');
    control.removeAt(i);
    if (this.filetype === 'exBond') {
      this.exbondFile.marksAndNumbers.splice(i, 1);
    } else {
      this.transportDocument.marksAndNumbers.splice(i, 1);
    }
//     this.transportDocument.marksAndNumbers.splice(i, 1);
  }

  removeEndorsement(j) {
    const control = <FormArray>this.form.get('endorsements');
    control.removeAt(j);
    if (this.filetype === 'exBond') {
      this.exbondFile.endorsements.splice(j, 1);
    } else {
      this.transportDocument.endorsements.splice(j, 1);
    }
//     this.transportDocument.endorsements.splice(j, 1);
  }

  getMarksAndNumbers(form) {
    return form.controls.marksAndNumbers.controls;
  }

  getEndorsements(form) {
    return form.controls.endorsements.controls;
  }

  pushMarksAndNumbers(i) {
    const control = <FormArray>this.form.get('marksAndNumbers');
    const rawArray = control.getRawValue();
    if (this.filetype === 'exBond') {
      this.exbondFile.marksAndNumbers[i] = rawArray[i].markOrNumber;
    } else {
      this.transportDocument.marksAndNumbers[i] = rawArray[i].markOrNumber;
    }
//     this.transportDocument.marksAndNumbers[i] = rawArray[i].markOrNumber;
  }

  pushEndorsements(i) {
    const control = <FormArray>this.form.get('endorsements');
    const rawArray = control.getRawValue();
    if (this.filetype === 'exBond') {
      this.exbondFile.endorsements[i] = rawArray[i].endorsement;
    } else {
      this.transportDocument.endorsements[i] = rawArray[i].endorsement;
    }
//     this.transportDocument.endorsements[i] = rawArray[i].endorsement;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.ebSubscription) {
      this.ebSubscription.unsubscribe();
    }
    this.tdSubscription.unsubscribe();
  }

}
