<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="masContainerType" [ngModel]="selectedContainer?.masContainerType" field="label"
                            [suggestions]="filteredMASContainerTypes"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true" minLength="1"
                            required=true (onSelect)="select($event)"
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.type}}: {{option.description}}</div>
              </ng-template>
            </p-autoComplete>
               <label for="masContainerType">MAS Type</label>
            </span>
            <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
