<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="HAWB" [selected]="true">
    <div class="ui-g-12 ui-g-nopad">
      <!-- Clearing Form -->
      <div class="ui-g-12 pt-0">
        <digi-clearing-file-section (add)="addTransportDocument()" [canAdd]="true"></digi-clearing-file-section>
      </div>
      <!--/Clearing Form -->
    </div>
    <!-- Table -->
    <div *ngIf="hasTransportDocument()" class="mt-2">
      <pre style="visibility: hidden;">{{hasTransportDocument()}}</pre>
      <digi-hawb-summary-table></digi-hawb-summary-table>
      <br>
      <!-- Table -->
      <div class="ui-g-12">
        <p-messages [(value)]="validationMessagesForMawbNo"></p-messages>
        <p-messages [(value)]="validationMessagesForHawbNo"></p-messages>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Air Line">
              <digi-hawb-airline-section [validationMessagesForMawbNo]="validationMessagesForMawbNo"
                                         [validationMessagesForHawbNo]="validationMessagesForHawbNo"
                                         [transportDocument]="selectedTransportDocument"
                                         [fileNumber]="clearingFile.clearingFileNumber" [isQuotation]="clearingFile.quotation">
              </digi-hawb-airline-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Section">
              <digi-hawb-flight-detail-section fileType="imports">
              </digi-hawb-flight-detail-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Degroup/Transit Depot">
              <digi-hawb-degroup-transit-section [transportDocument]="selectedTransportDocument" [isQuotation]="clearingFile.quotation">
              </digi-hawb-degroup-transit-section>
            </p-panel>
            <br>
            <p-panel header="Forward File">
              <digi-hawb-forward-file-section [transportDocument]="selectedTransportDocument">
              </digi-hawb-forward-file-section>
            </p-panel>
            <div class="ui-g-4 ui-g-nopad pt-2">
              <button pButton type="submit" label="Hand-in Forwarding" class="ui-button-secondary"></button>
            </div>
            <div class="ui-g-4 ui-g-nopad pt-2">
              <button pButton type="submit" label="Sundry Changes" class="ui-button-secondary"
                      (click)="openSundryChargeModel()"></button>
            </div>
            <div class="ui-g-4 ui-g-nopad pt-2">
              <button pButton type="submit" label="Link Indent Files" class="ui-button-secondary"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Weights & Measures">
              <digi-hawb-weight-measures-section></digi-hawb-weight-measures-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Freight">
              <digi-hawb-freight-section [freight]="selectedTransportDocument.freight"></digi-hawb-freight-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="RIB BOE Details">
              <digi-hawb-boe-details-section></digi-hawb-boe-details-section>
            </p-panel>
          </div>
        </div>
      </div>
      <digi-sundry-charges [display]="display" (displayChange)="onDialogClose($event)"
                           [hasSundryCharge]="selectedTransportDocument"></digi-sundry-charges>
    </div>
    <!-- Table -->
  </p-tabPanel>
  <p-tabPanel header="Mrks,Nmbrs" [disabled]="clearingFile.transportDocuments.length <= 0">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-marks-numbers>
          <digi-clearing-file-section step="transportDocument" [canAdd]="false">
            <div class="ui-g-12 ui-md-6">
              <div class="ui-inputgroup">
                <span class="md-inputfield ui-md-4">
                  <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}"
                         readonly>
                  <label style="color: white;">HAWB No</label>
                </span>
              </div>
            </div>
          </digi-clearing-file-section>
        </digi-marks-numbers>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Air Track Trace">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <button class="ui-button" (click)="navigateToSite()">Track Shipment</button>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>

