import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {BillsOfEntry, ExportBillsOfEntry} from '../models/billsOfEntry';
import {ClearingFile} from '../models/clearingFile';
import {Observable} from 'rxjs';
import {BillOfEntry, ExportBillOfEntry} from '../models/billOfEntries';
import {ExportFile} from '../models/exportFile';
import {map} from 'rxjs/operators';
import {FileType} from '../models/enumerations';

@Injectable({
  providedIn: 'root'
})
export class ExportsBillOfEntryService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {

  }

  generateBOE(billsOfEntry: ExportBillsOfEntry, exportFile: ExportFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
      billsOfEntry.boes[0].exportFile = exportFile._links.self.href;
      return this.http.put(
        billsOfEntry.boes[0]._links.self.href,
        JSON.stringify(billsOfEntry.boes[0]),
        {headers}
      );
    } else {
      if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].clearingFile) {
        billsOfEntry.boes[0].exportFile = exportFile._links.self.href;
      }
      return this.http.post(
        this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id + '/exportBillOfEntries/generate',
        {headers}
      );
    }
  }

  regenerateBOE(exportFile: ExportFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id + '/exportBillOfEntries/regenerate', {headers}
    );
  }

  findParentExportFile(billId): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/corrections/` + billId + '/parent');
  }

  findBillOfEntryById(billId): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/billOfEntries/` + billId);
  }

  saveBillOfEntry(billOfEntry: ExportBillOfEntry, exportFile: ExportFile): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    if (billOfEntry._links && billOfEntry._links.self) {
      billOfEntry.clearingFile = exportFile._links.self.href;
      const url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
      if (billOfEntry.cusdecs) {
        billOfEntry.cusdecs = billOfEntry.cusdecs.filter(cusdec => cusdec !== null);
      }
      return this.http.put(url, JSON.stringify(billOfEntry), {headers});
    }
  }

  loadFiles(id: String): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/document/` + id);
  }

  loadUploadedFile(billOfEntry: BillOfEntry): Observable<any> {
    return this.http
      .get(`${billOfEntry._links.files.href}`);
  }

  uploadFiles(files: File[], billOfEntry: BillOfEntry) {
    const data = new FormData();
    data.append('file', files[0]);
    // console.log(file)
    console.log(data)
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(`${billOfEntry._links.files.href}`, data, {headers});
  }

  retrieveBillOfEntry(currentBillOfEntry): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
    return this.http.get(url);
  }

  findBillOfEntryByClearingFile_id(id): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportBillOfEntries/search/findByExportFile_Id?id=` + id);
  }

  findAllEdiMessages(page = 0, size = 10): Observable<any> {
    return this.http.get(
      `${this.config.filesApiEndpoint}api/exportBillOfEntries?projection=onlyEdiMessages&page=${page}&size=${size}&sort=dateEdiSubmitted,desc`
    );
  }

  findByHawbNoOrMawbNo(query: string) {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportBillOfEntries/search/findByHawbNoOrMawbNo?hawbNoOrMawbNo=${query}`).pipe(
      map(response => response['_embedded'].exportBillOfEntries)
    );
  }

  findByMawbNo(manifestNo: string) {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportBillOfEntries/search/findByMawbNo?mawbNo=${manifestNo}`).pipe(
      map(response => response['_embedded'].exportBillOfEntries)
    );
  }

  markBoesAsSubmitted(clearingFileId: string, selectedBoes: BillOfEntry[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const ids = selectedBoes.map(el => el.id);
    return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + clearingFileId + '/exportBillOfEntries/submit', ids, {headers}
    ).pipe(
      map((response) => response['_embedded'] && response['_embedded'].exportBillOfEntries ? response['_embedded'].exportBillOfEntries : [])
    );
  }
}
