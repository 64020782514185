import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map, tap } from 'rxjs/operators';
import { Address, Remover } from '../models/file';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var RemoverService = /** @class */ (function () {
    function RemoverService(http, config) {
        this.http = http;
        this.config = config;
    }
    RemoverService.prototype.findRemoverNameOrCodeStartsWith = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=" + code + "&roleType=8&projection=flattenedRemover")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    RemoverService.prototype.findRemoverFromClearingInstructionAndRemovers = function (clearingInstruction, removers) {
        if (clearingInstruction.length > 0) {
            if (removers.length === 0) {
                return this.returnDefault(clearingInstruction[0]);
            }
            else if (removers && removers.length === 1) {
                return this.findRemoverFromClearingInstruction(clearingInstruction, removers[0]);
            }
            else if (clearingInstruction[0].remover.code) {
                return this.findRemoverFromClearingInstruction(clearingInstruction, clearingInstruction.remover, false);
            }
            return this.returnDefault(clearingInstruction[0]);
        }
        return EMPTY;
    };
    RemoverService.prototype.returnDefault = function (clearingInstruction) {
        var remover = new Remover();
        remover.address = new Address();
        if (clearingInstruction) {
            clearingInstruction.remover = remover;
        }
        return of([remover]);
    };
    RemoverService.prototype.findRemoverFromClearingInstruction = function (clearingInstruction, remover, updateRemover) {
        if (updateRemover === void 0) { updateRemover = true; }
        if (clearingInstruction) {
            return this.findRemoverNameOrCodeStartsWith(remover.code).pipe(tap(function (removerData) {
                if (updateRemover) {
                    clearingInstruction.remover = new Remover();
                    clearingInstruction.remover.name = remover.name;
                    clearingInstruction.remover.code = remover.code;
                    clearingInstruction.remover.label = remover.label;
                    clearingInstruction.remover.customsCode = removerData[0].customsCode;
                    clearingInstruction.remover.bondValue = removerData[0].bondValue;
                }
                if (removerData && (!clearingInstruction.remover.address
                    || !clearingInstruction.remover.address.line1)) {
                    var removerAddresses = removerData[0].addresses;
                    var removerAddress = void 0;
                    if (removerAddresses.length === 1) {
                        removerAddress = removerAddresses[0];
                    }
                    else {
                        removerAddress = removerAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (removerAddress) {
                        clearingInstruction.remover.address = new Address();
                        clearingInstruction.remover.address.line1 = removerAddress.line1;
                        clearingInstruction.remover.address.line2 = removerAddress.line2;
                        clearingInstruction.remover.address.line3 = removerAddress.line3;
                        clearingInstruction.remover.address.line4 = removerAddress.line4;
                        clearingInstruction.remover.address.zipCode = removerAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return this.returnDefault(clearingInstruction);
        }
    };
    RemoverService.ngInjectableDef = i0.defineInjectable({ factory: function RemoverService_Factory() { return new RemoverService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: RemoverService, providedIn: "root" });
    return RemoverService;
}());
export { RemoverService };
