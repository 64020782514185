<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-md-8">
    <p-panel>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.name"
                                   [maxLength]="35"
                                   pInputText>
                            <label>Trading Name</label>

                        </span>
                    </div>
                             <div *ngIf="!businessEntity?.name" class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
                  </span>
        </div>
        <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text"
                                 [(ngModel)]="businessEntity.registeredName"
                                 pInputText>
                          <label>Registered Name</label>
                        </span>
                    </div>
                    <div *ngIf="!businessEntity?.registeredName" class="ui-message ui-messages-error ui-corner-all">
                      This field is required
                    </div>
                  </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="businessEntity.code">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.code"
                                   pInputText>
                            <label>Code</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.compRegNo" pInputText>
                            <label>Company Registration No</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Default Customs Code</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.defaultVatRegNo"
                                   pInputText>
                            <label>Default VAT No</label>
                        </span>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
