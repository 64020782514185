import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../../subjects/clearingfile-behaviour-subject";
import * as i2 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i3 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i4 from "@angular/common/http";
import * as i5 from "../../app.config.module";
var BusinessEntitySearchService = /** @class */ (function () {
    function BusinessEntitySearchService(clearingFileBehaviourSubject, supplierInvoiceBehaviourSubject, clearingInstructionBehaviorSubject, http, config) {
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.http = http;
        this.config = config;
    }
    BusinessEntitySearchService.prototype.findBusinessEntityNameAndRole = function (name, role) {
        var projection;
        switch (role) {
            case 9:
                projection = 'flattenedRebateUser';
                break;
            case 6:
                projection = 'flattenedImporter';
                break;
            case 21:
                projection = 'flattenedSupplier';
                break;
            case 1:
                projection = 'flattenedDebtor';
                break;
            case 4:
                projection = 'flattenedLocalAgent';
                break;
            case 8:
                projection = 'flattenedRemover';
                break;
            case 13:
                projection = 'flattenedWarehouse';
                break;
            case 11:
                projection = 'flattenedCreditor';
                break;
            case 3:
                projection = 'flattenedConsignor';
                break;
            case 30:
                projection = 'flattenedGuarantor';
                break;
            case 22:
                projection = 'flattenedHaulier';
                break;
            case 12:
                projection = 'flattenedExporter';
                break;
            case 2:
                projection = 'flattenedExportConsignee';
                break;
            case 5:
                projection = 'flattenedOverseasAgent';
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + name + ("&roleType=" + role + "&projection=" + projection))
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    BusinessEntitySearchService.ngInjectableDef = i0.defineInjectable({ factory: function BusinessEntitySearchService_Factory() { return new BusinessEntitySearchService(i0.inject(i1.ClearingFileBehaviourSubject), i0.inject(i2.SupplierInvoiceBehaviourSubject), i0.inject(i3.ClearingInstructionBehaviorSubject), i0.inject(i4.HttpClient), i0.inject(i5.APP_CONFIG)); }, token: BusinessEntitySearchService, providedIn: "root" });
    return BusinessEntitySearchService;
}());
export { BusinessEntitySearchService };
