import { Component, Input, IterableDiffers, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClearingFileService } from 'src/app/digi-common/services/clearing-file.service';
import { CurrencyService } from 'src/app/digi-common/services/currency.service';
import { ClearingFileBehaviourSubject } from '../../../../../subjects/clearingfile-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../../../../../subjects/supplierInvoice-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';
import { ClearingFile } from '../../../models/clearingFile';
import {
  CertificateType,
  Currency,
  ExportTransportDocument,
  SupplierInvoice,
  TransportDocument,
  UniqueConsignmentReference,
} from '../../../models/file';
import { ShareDataService } from '../../../services/share-data.service';
import { UniqueConsignmentReferenceService } from '../../../services/unique-consignment-reference.service';
import { UniqueConsignmentBehaviourSubject } from '../../../../../subjects/unique-consignment-reference-subject';
import { ExportConsignee, ExportFile } from '../../../models/exportFile';
import { ExportFileService } from '../../../services/export-file.service';
import { ExportFileBehaviourSubject } from '../../../../../subjects/exportfile-behaviour-subject';
import { BusinessEntityService } from '../../../../digi-business-entity/services/business-entity.service';
import { BusinessEntity } from '../../../../digi-business-entity/models/business-entity';
import { BusinessEntitySearchService } from '../../../services/business-entity-search.service';
import { CertificateTypeService } from '../../../services/certificate-type.service';
import { SupplierService } from '../../../services/supplier.service';
import { CountryService } from '../../../services/country.service';

@Component({
  selector: 'digi-exports-invoice-headers',
  templateUrl: './exports-invoice-headers.component.html',
  styleUrls: ['./exports-invoice-headers.component.scss'],
})
export class ExportsInvoiceHeadersComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
