<div class="ui-g ui-fluid">
  <div class="ui-g-12">
    <button pButton type="button" label="Add Additional Information" (click)="addAdditionalInformation()"></button>
  </div>
</div>
<p-dialog header="Additional Information Available" [(visible)]="display" [responsive]="true" [width]="600"
          [minWidth]="400" [minY]="100" [baseZIndex]="10000" [contentStyle]="{ 'max-height': '600px' }">
  <p-table [value]="boeAddInfoTypes" [paginator]="true" [rows]="20" [lazy]="true" [lazyLoadOnInit]="true"
           (onLazyLoad)="onLazyLoad($event)"
           [totalRecords]="totalRecords"
           [columns]="cols"
           [(selection)]="selectedBoeInfoTypes"
           selectionMode="multiple"
           [responsive]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width"/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td class="ui-resizable-column">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="ui-resizable-column">{{ rowData?.code }}</td>
        <td class="ui-resizable-column">{{ rowData?.description}}</td>
        <td>
          <input type="text" style="height: 20px;" pInputText [(ngModel)]="rowData.value"/>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button pButton type="button" label="select" (click)="select()"></button>
    <button pButton type="button" label="cancel" (click)="close()"></button>
  </p-footer>
</p-dialog>
<p-table [columns]="cols"
         [value]="selectedBillOfEntryLine?.additionalInformation"
        [scrollable]="true" scrollHeight="200px"
>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
      <td>
        {{rowData?.code}}
      </td>
      <td>
        {{rowData?.value}}
      </td>
    </tr>
  </ng-template>
</p-table>
