<p-tabView orientation="left" styleClass="stepMenu" (activeIndexChange)="handleIndexChange($event)">
  <p-tabPanel header="Supplr Inv. Header" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <digi-clearing-file-section *ngIf="fileType==='imports'" step="supplierInvoice" (add)="addSupplierInvoice()"
                                    [canAdd]="true">
        </digi-clearing-file-section>

        <digi-exports-file-header-section *ngIf="fileType==='exports'" step="supplierInvoice" (add)="addSupplierInvoice()"
                                          [canAdd]="true">
        </digi-exports-file-header-section>

        <!--/Clearing Form -->
        <div *ngIf="hasSupplierInvoice()" class="mt-2">
          <div class="ui-fluid">
            <div class="ui-g">
              <div *ngIf="showProgressBar" class="ui-g-12">
                <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
              </div>
              <div *ngIf="isWarehouseImport()" class="ui-g-12">
                <p-fileUpload (onSelect)="selectFiles($event)"
                              (uploadHandler)="uploadManifest()"
                              [customUpload]="true"
                              accept=".xls,.xlsx"
                >
                </p-fileUpload>
              </div>
              <div class="ui-g-12">
                <digi-supplier-invoice-table [fileType]="fileType"></digi-supplier-invoice-table>
                <div>
                  <p-messages [(value)]="validationMessagesForInvoiceNo"></p-messages>
                </div>
                <p-panel header="Invoice Header Details">
                  <div class="ui-fluid">
                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-4">
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="selectedSupplierInvoice.invoiceNumber"
                                     (focusout)="validateInvoiceNumber()"
                                     required=true>
                              <label>Invoice Number</label>
                            <div *ngIf="!file.quotation && !selectedSupplierInvoice.invoiceNumber" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-4">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask id="invoiceDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="selectedSupplierInvoice.invoiceDate">
                                </p-inputMask>
                               <label for="invoiceDate">Invoice Date</label>
                            </span>
                            <div *ngIf="!file.quotation && !selectedSupplierInvoice.invoiceDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-4">
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText
                             [(ngModel)]="selectedSupplierInvoice.sequence"
                             readonly>
                      <label>Sequence</label>
                    </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
                <digi-invoice-supplier-importer-details *ngIf="fileType==='imports'"
                  [clearingFile]="file">
                </digi-invoice-supplier-importer-details>
                <digi-invoice-export-consignee-exporter-details *ngIf="fileType==='exports'">
                </digi-invoice-export-consignee-exporter-details>
                <div class="ui-g-12 ui-md-4">
                  <p-panel header="">
                    <div class="ui-g ui-fluid">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" pInputText
                                   [(ngModel)]="selectedSupplierInvoice.totalGrossIncomeValue"
                                   (ngModelChange)="invoiceGrossValueChanged($event)"
                                   required=true>
                            <label>Total Gross Invoice Value</label>
                            <div *ngIf="!selectedSupplierInvoice.totalGrossIncomeValue && !isWarehouseImport()" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" pInputText
                                   [(ngModel)]="selectedSupplierInvoice.quantity"
                                   required=true>
                            <label> Quantity (KG)</label>
                            <div *ngIf="!selectedSupplierInvoice.quantity" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" pInputText
                                   [(ngModel)]="selectedSupplierInvoice.defaultValues.rooNumber">
                            <label> R.O.O Number</label>
                          </span>
                        </div>
                            <div class="ui-g-12 ui-md-12">
                              <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete id="ucr" [(ngModel)]="selectedSupplierInvoice.ucr"
                                      field="number"
                                      [suggestions]="filteredUCRs"
                                      (completeMethod)="searchUniqueConsignmentReference($event)"
                                      (onSelect)="selectUCR($event)"
                                      [dropdown]="true"
                                      minLength="3"
                                      required="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.number}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="ucr">UCR</label>
                  </span>
                                <span class="ui-inputgroup-addon" (click)="clearUcr()"><i class="fa fa-close"></i></span>
                              </div>
                            </div>
                            <div class="ui-g-12 ui-md-12">
                              <div class="ui-inputgroup">
                                <button pButton
                                        type="button"
                                        label="Add UCR" (click)="openUCRModel()"></button>
                              </div>
                            </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="ui-g-12 ui-md-4">
                  <p-panel header="">
                    <div class="ui-g ui-fluid">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <p-checkbox binary="true"
                                      label="Default all lines to use STANDARD Trade Agreement trade tarrif rate"
                                      [(ngModel)]="selectedSupplierInvoice.userInterfaceDefaults.standardTradeAgreement"></p-checkbox>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <p-checkbox label="Automatically apportion line weight based on value"
                                      [(ngModel)]="selectedSupplierInvoice.apportionWeightOnLines"
                                      binary="true"></p-checkbox>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <button pButton type="submit" label="Sundry Charges"
                                  class="ui-button-secondary"
                                  (click)="openSundryChargeModel()"></button>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="ui-g-12 ui-md-4">
                  <p-panel header="Lines on Invoice & Lines on File"
                           [style]="{'text-align':'center'}">
                    <div class="ui-g ui-fluid">
                      <div class="ui-g-6">
                  <span for="txtLineOnInvoice">#Lines on Invoice:
                    {{numOfInvoiceLines}}</span>
                      </div>
                      <div class="ui-g-6">
                  <span for="txtLinesOnFile"> #Lines on File:
                    {{calcLinesOnFiles}}</span>
                      </div>
                    </div>
                    <div class="ui-g ui-fluid">
                      <div class="ui-g-6">
                  <span
                    for="txtLineOnInvoice">#Invoice Total:
                    {{selectedSupplierInvoice.totalGrossIncomeValue}}</span>
                      </div>
                      <div class="ui-g-6">
                  <span for="txtLinesOnFile"> ##File Total:
                    {{calcTotalOnFiles}}</span>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <digi-sundry-charges [display]="display"
                                     (displayChange)="onDialogClose($event)"
                                     [hasSundryCharge]="selectedSupplierInvoice"></digi-sundry-charges>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Invoice Lines"
              [disabled]="selectedTransportDocument?.supplierInvoices.length <= 0">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-invoice-lines [fileType]=fileType></digi-invoice-lines>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Import Invoice Lines"
              [disabled]="selectedTransportDocument?.supplierInvoices.length <= 0">
    <ng-template pTemplate="content">
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-12">
        <p-fileUpload (onSelect)="selectFile($event)"
                      (uploadHandler)="uploadPartDetails()"
                      [customUpload]="true"
                      accept=".tsv,.txt"
        >
        </p-fileUpload>
      </div>
      <div class="ui-g-12">
        <p-table [columns]="cols" [value]="partDetails" dataKey="partNumber"
                 selectionMode="single"
                 [paginator]="true" [rows]="10" [responsive]="true" >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style]="col.width" [pSortableColumn]="col.field">
                {{col.header}}
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
              <td>
                {{rowData.partNumber}}
              </td>
              <td>
                {{rowData.description}}
              </td>
              <td>
                <input [(ngModel)]="rowData.tariffCode" type="text" pInputText/>
              </td>
              <td>
                <input [(ngModel)]="rowData.invoiceValue" type="text" pInputText/>
              </td>
              <td>
                <input [(ngModel)]="rowData.weight" type="text" pInputText/>
              </td>
              <td>
                <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                        (click)="deletePartDetail(rowIndex)" pTooltip="Delete"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel *ngIf="fileType==='exports'" header="Commercial Invoice"
              [disabled]="selectedTransportDocument?.supplierInvoices.length <= 0">
    <ng-template pTemplate="content">
      <digi-commercial-invoice [file]="file" [transportMethod]="file.clearingInstructions[0].transportMethod.method"></digi-commercial-invoice>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel *ngIf="fileType==='exports'" header="Packing List"
              [disabled]="selectedTransportDocument?.supplierInvoices.length <= 0">
    <ng-template pTemplate="content">
      <digi-packing-list [file]="file"></digi-packing-list>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Trans Docs"
              [disabled]="selectedTransportDocument?.supplierInvoices.length <= 0">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad" *ngIf="fileType==='imports'">
        <digi-invoice-lines-transport-docs [clearingFile]="file"></digi-invoice-lines-transport-docs>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>

<digi-unique-consignment-reference [businessEntity]="fileType==='imports' ? file.localAgent:file.exporter"
                                   [fileType]="fileType"
                                   [customsCode]="fileType==='imports' ? file.localAgent.customsRegCode:file.exporter.customsCode"
                                   [roleType]="fileType==='imports' ? '4':'12'"
                                   [display]="displayUCRModal"
                                   [invoiceNo]="selectedSupplierInvoice.invoiceNumber"
                                   [invoiceValue]="selectedSupplierInvoice.totalGrossIncomeValue"
                                   [currency]="selectedSupplierInvoice.currency"
                                   [ucrNumber]="selectedSupplierInvoice?.ucr?.number"
                                   (created)="onUCRCreated($event)"
></digi-unique-consignment-reference>
