import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { ExportBillsOfEntry } from '../models/billsOfEntry';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ExportsBillOfEntryService = /** @class */ (function () {
    function ExportsBillOfEntryService(http, config) {
        this.http = http;
        this.config = config;
    }
    ExportsBillOfEntryService.prototype.generateBOE = function (billsOfEntry, exportFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
            billsOfEntry.boes[0].exportFile = exportFile._links.self.href;
            return this.http.put(billsOfEntry.boes[0]._links.self.href, JSON.stringify(billsOfEntry.boes[0]), { headers: headers });
        }
        else {
            if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].clearingFile) {
                billsOfEntry.boes[0].exportFile = exportFile._links.self.href;
            }
            return this.http.post(this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id + '/exportBillOfEntries/generate', { headers: headers });
        }
    };
    ExportsBillOfEntryService.prototype.regenerateBOE = function (exportFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id + '/exportBillOfEntries/regenerate', { headers: headers });
    };
    ExportsBillOfEntryService.prototype.findParentExportFile = function (billId) {
        return this.http.get(this.config.filesApiEndpoint + "api/corrections/" + billId + '/parent');
    };
    ExportsBillOfEntryService.prototype.findBillOfEntryById = function (billId) {
        return this.http.get(this.config.filesApiEndpoint + "api/billOfEntries/" + billId);
    };
    ExportsBillOfEntryService.prototype.saveBillOfEntry = function (billOfEntry, exportFile) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (billOfEntry._links && billOfEntry._links.self) {
            billOfEntry.clearingFile = exportFile._links.self.href;
            var url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
            if (billOfEntry.cusdecs) {
                billOfEntry.cusdecs = billOfEntry.cusdecs.filter(function (cusdec) { return cusdec !== null; });
            }
            return this.http.put(url, JSON.stringify(billOfEntry), { headers: headers });
        }
    };
    ExportsBillOfEntryService.prototype.loadFiles = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/document/" + id);
    };
    ExportsBillOfEntryService.prototype.loadUploadedFile = function (billOfEntry) {
        return this.http
            .get("" + billOfEntry._links.files.href);
    };
    ExportsBillOfEntryService.prototype.uploadFiles = function (files, billOfEntry) {
        var data = new FormData();
        data.append('file', files[0]);
        // console.log(file)
        console.log(data);
        var headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });
        return this.http.post("" + billOfEntry._links.files.href, data, { headers: headers });
    };
    ExportsBillOfEntryService.prototype.retrieveBillOfEntry = function (currentBillOfEntry) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
        return this.http.get(url);
    };
    ExportsBillOfEntryService.prototype.findBillOfEntryByClearingFile_id = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries/search/findByExportFile_Id?id=" + id);
    };
    ExportsBillOfEntryService.prototype.findAllEdiMessages = function (page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 10; }
        return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries?projection=onlyEdiMessages&page=" + page + "&size=" + size + "&sort=dateEdiSubmitted,desc");
    };
    ExportsBillOfEntryService.prototype.findByHawbNoOrMawbNo = function (query) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries/search/findByHawbNoOrMawbNo?hawbNoOrMawbNo=" + query).pipe(map(function (response) { return response['_embedded'].exportBillOfEntries; }));
    };
    ExportsBillOfEntryService.prototype.findByMawbNo = function (manifestNo) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries/search/findByMawbNo?mawbNo=" + manifestNo).pipe(map(function (response) { return response['_embedded'].exportBillOfEntries; }));
    };
    ExportsBillOfEntryService.prototype.markBoesAsSubmitted = function (clearingFileId, selectedBoes) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var ids = selectedBoes.map(function (el) { return el.id; });
        return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + clearingFileId + '/exportBillOfEntries/submit', ids, { headers: headers }).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].exportBillOfEntries ? response['_embedded'].exportBillOfEntries : []; }));
    };
    ExportsBillOfEntryService.ngInjectableDef = i0.defineInjectable({ factory: function ExportsBillOfEntryService_Factory() { return new ExportsBillOfEntryService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ExportsBillOfEntryService, providedIn: "root" });
    return ExportsBillOfEntryService;
}());
export { ExportsBillOfEntryService };
