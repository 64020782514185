import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntryLine} from '../../models/billOfEntries';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-app-boe-schedule-amount-per-line',
  templateUrl: './boe-schedule-amount-per-line.component.html',
  styleUrls: ['./boe-schedule-amount-per-line.component.scss']
})
export class BoeScheduleAmountPerLineComponent implements OnInit {
  billSubscription: Subscription;
  cols: any[];
  selectedBillOfEntryLine: BillOfEntryLine;
  @Input() markedAndSubmitted: boolean;

  constructor(private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
      });
  }

  hasBeenMarkedAndSubmitted(): boolean {
    return !!this.markedAndSubmitted;
  }
}
