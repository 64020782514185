import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Warehouse} from '../../models/business-entity';

@Component({
  selector: 'digi-app-warehouse-role',
  templateUrl: './warehouse-role.component.html',
  styleUrls: ['./warehouse-role.component.scss']
})
export class WarehouseRoleComponent implements OnInit {

  @Input() businessEntity: BusinessEntity;
  @Input() warehouse: Warehouse;

  constructor() {
  }

  ngOnInit() {
  }

}
