import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng/primeng';
import {ExBondFile, ExBondLine} from '../../digi-common/models/exBondFile';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {Subscription} from 'rxjs';
import {computeStyle} from '@angular/animations/browser/src/util';
import {convertSchemaToOptions} from '@angular/cli/models/json-schema';

@Component({
  selector: 'digi-exbond-file-xrw-search',
  templateUrl: './exbond-file-xrw-search.component.html',
  styleUrls: ['./exbond-file-xrw-search.component.scss']
})
export class ExbondFileXrwSearchComponent implements OnInit {
  exBondFile: string;
  sequence: number;
  @ViewChild('correctionList') correctionList: OverlayPanel;
  listExBondFiles: any[];
  filteredExBondFiles: ExBondFile[];
  private exBondFileSubscription: Subscription;
  @Output() selectedFileLines = new EventEmitter<ExBondLine[]>();


  constructor(
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private exBondFileService: ExBondFileService
  ) { }

  ngOnInit() {
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(ef => {
      this.exBondFile = ef.clearingFileNumber;
      this.sequence = ef.sequence;
    });
  }

  search(event) {
    this.exBondFileService
      .loadExBondFile(event.query)
      .subscribe(data => {
        this.filteredExBondFiles = data;
      });
  }

  selectInitial(xrwValue) {
    if (xrwValue.type !== 'ExBondFileCorrection') {
      this.exBondFileService.selectXrwExBondFile(xrwValue);

      const clearingFileNumber = xrwValue.clearingFileNumber;

      if (clearingFileNumber !== null && clearingFileNumber !== undefined) {
        this.exBondFileService.loadAllCorrectionsIncludingOriginalExBondFile(clearingFileNumber)
          .subscribe(data => {
            this.listExBondFiles = data;
          });
      }
    }
  }



  selectCorrection(xrwValue) {
    this.exBondFileService.selectXrwExBondFile(xrwValue);
    this.correctionList.hide();
  }

}
