<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon" style="color: #FFFFFF;">Tariff Code :</span>
        <p-autoComplete id="tariffCode" minLength="3" [suggestions]="filteredTariffCodes"
                        [(ngModel)]="selectedTariffCode"
                        (completeMethod)="searchTariffCode($event)"
                        [autoHighlight]="true"
                        (onSelect)="select($event)"
                        field="code">
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}</div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewTariff()"></button>
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="!isCreateNew"
            (click)="saveNewTariff()"></button>
  </div>
</p-toolbar>
<div class="pt-2">
  <p-panel header="Tariff Details">
    <div class="ui-g">
      <div class="ui-g-4 pt-2">
        <p-panel header="Tariff Section">
          <div class="ui-g ui-fluid">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedTariffCode.code" pInputText>
                    <label>Tariff Code</label>
                  </span>
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedTariffCode.tariffCheckDigit" pInputText/>
                    <label>Tariff Check Digit</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" readonly [(ngModel)]="selectedTariffCode.tariffLevel" pInputText/>
                      <label>Tariff Level</label>
                  </span>
                  <span class="ui-inputgroup-addon">Tariff ?</span>
                  <span class="ui-inputgroup-addon">
                            <p-checkbox binary="true" [(ngModel)]="selectedTariffCode.tariff"></p-checkbox>
                        </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                <span class="ui-float-label">
                    <p-autoComplete id="taxType"
                                    minLength="0"
                                    name="taxTypeCode"
                                    [(ngModel)]="selectedTariffCode.taxTypeCode"
                                    [suggestions]="filteredTaxTypes"
                                    [dropdown]="true"
                                    (completeMethod)="searchTaxTypeCode($event)"
                                    (onSelect)="selectTaxType($event)">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="taxType">Tax Type</label>
                </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedTariffCode.additionalType" pInputText/>
                    <label>Additional Type</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4 pt-2">
        <p-panel header="Effective Section">
          <div class="ui-g ui-fluid">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <span class="ui-float-label">
                      <p-inputMask id="tariffEffectiveFrom" mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="selectedTariffCode.tariffEffectiveFrom">
                                        </p-inputMask>
                                        <label for="tariffEffectiveFrom">Effective From</label>
                    </span>
                  </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                   <span class="md-inputfield">
                    <span class="ui-float-label">
                      <p-inputMask id="tariffEffectiveUntil" mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="selectedTariffCode.tariffEffectiveUntil">
                                        </p-inputMask>
                                        <label for="tariffEffectiveUntil">Effective Until</label>
                    </span>
                  </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                   <span class="ui-float-label">
                        <p-autoComplete id="replacedByTariff" minLength="0">
                          <ng-template let-option pTemplate="item">
                            <div>{{option.code}}</div>
                          </ng-template>
                        </p-autoComplete>
                     <label for="replacedByTariff">Replaced by Tariff</label>
                   </span>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4 pt-2">
        <p-panel header="Link Section">
          <div class="ui-g">
            <div class="ui-fluid">
              <div class="ui-g-12 ui-g-nopad pt-2">
                <button pButton type="button" label="Link Permits" pTooltip="Link Permits"
                        tooltipPosition="top"></button>
              </div>
              <div class="ui-g-12 ui-g-nopad pt-2">
                <button pButton type="button" label="Link Audits" pTooltip="Link Audits"
                        tooltipPosition="top"></button>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-12 pt-2">
        <p-panel header="Short Description Section">
          <div class="ui-g">
            <div class="ui-fluid">
            <textarea class="ui-inputtextarea hideResize" [(ngModel)]="selectedTariffCode.tariffSDesc" rows="5"
                      cols="175" pInputTextarea></textarea>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-12 pt-2">
        <p-panel header="Core Freight Description Section">
          <div class="ui-g">
            <div class="ui-fluid">
              <textarea class="ui-inputtextarea hideResize" rows="5" cols="175" pInputTextarea></textarea>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-12 pt-2">
        <p-panel header="Current Customs Description Section">
          <div class="ui-g">
            <div class="ui-fluid">
            <textarea class="ui-inputtextarea hideResize" [ngModel]="selectedTariffCode?.fullDescription"
                      rows="5"
                      cols="175" pInputTextarea></textarea>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </p-panel>
</div>
