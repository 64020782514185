import { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { BillOfEntryService } from '../../services/bill-of-entry.service';
import { NotificationService } from '../../services/notification.service';
import { LoadingService } from '../loading.service';
var DocumentsComponent = /** @class */ (function () {
    function DocumentsComponent(clearingFileBehaviourSubject, billOfEntryService, messageService, billOfEntryBehaviorSubject, loadingSubject) {
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.billOfEntryService = billOfEntryService;
        this.messageService = messageService;
        this.billOfEntryBehaviorSubject = billOfEntryBehaviorSubject;
        this.loadingSubject = loadingSubject;
    }
    DocumentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setListOfFiles();
        this.billOfEntrySubscription = this.billOfEntryBehaviorSubject.getBillOfEntry()
            .subscribe(function (billOfEntry) {
            _this.billOfEntry = billOfEntry;
        });
    };
    DocumentsComponent.prototype.ngOnChanges = function () {
        this.setListOfFiles();
    };
    DocumentsComponent.prototype.setListOfFiles = function () {
        if (this.files) {
            this.listOfFiles = this.files;
        }
    };
    DocumentsComponent.prototype.afterFileUpload = function () {
        this.loadUpLoadedFiles();
        this.loadingSubject.addLoader(false);
        this.messageService.successNotify('File Uploaded');
    };
    DocumentsComponent.prototype.onFileUploadError = function (event) {
        var error = JSON.parse(event.xhr.response);
        this.loadUpLoadedFiles();
        this.loadingSubject.addLoader(false);
        this.messageService.errorNotify(error.message, [error.errors[0]]);
    };
    DocumentsComponent.prototype.loadUpLoadedFiles = function () {
        var _this = this;
        this.billOfEntryService.loadUploadedFile(this.billOfEntry)
            .subscribe(function (uploadedFiles) {
            if (uploadedFiles && uploadedFiles['_embedded']) {
                _this.files = [];
                (_a = _this.files).push.apply(_a, uploadedFiles._embedded.supportingDocuments);
                _this.listOfFiles = _this.files;
                _this.files = _this.files.slice();
            }
            var _a;
        });
    };
    DocumentsComponent.prototype.beforeFileUpload = function () {
        this.loadingSubject.addLoader(true);
    };
    DocumentsComponent.prototype.ngOnDestroy = function () {
        this.billOfEntrySubscription.unsubscribe();
    };
    return DocumentsComponent;
}());
export { DocumentsComponent };
