import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Bank, BusinessEntity, Country, Currency, CustomsTraderType, Exporter, ReceivingBank} from '../../models/business-entity';
import {ReceivingBankService} from '../../../digi-common/services/receiving-bank.service';
import {CurrencyService} from '../../../digi-common/services/currency.service';
import {CountryService} from '../../../digi-common/services/country.service';
import {BankService} from '../../../digi-common/services/bank.service';

@Component({
  selector: 'digi-exporter-role',
  templateUrl: './exporter-role.component.html',
  styleUrls: ['./exporter-role.component.scss']
})
export class ExporterRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() exporter: Exporter;
  filteredReceivingBanks: ReceivingBank[];
  filteredBanks: Bank[];

  constructor(
    private receivingBankService: ReceivingBankService,
    private currencyService: CurrencyService,
    private countryService: CountryService,
    private bankService: BankService
  ) {
  }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
    this.currencyService.findCurrencyStartWith('zar').subscribe((currencies: Currency[]) => {
      this.exporter.currency = currencies[0];
    });

    this.countryService.findCountryStartsWith('za').subscribe((countries: Country[]) => {
      this.exporter.country = countries[0];
    });
  }

  ngOnChanges() {
    this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if (!this.exporter.customsCode) {
      this.exporter.customsCode = this.businessEntity.defaultCustomsCode;
    }

    if (!this.exporter.vatRegNo) {
      this.exporter.vatRegNo = this.businessEntity.defaultVatRegNo;
    }

  }

  selectCountry(value: Country) {
    this.exporter.country = value;
  }

  selectCurrency(value: Currency) {
    this.exporter.currency = value;
  }

  selectTraderType(value: CustomsTraderType) {
    this.exporter.registrationType = value;
  }

  searchReceivingBanks(event) {
    this.receivingBankService.findReceivingBankStartingWith(event.query).subscribe(
      (receivingBanks: ReceivingBank[]) => this.filteredReceivingBanks = receivingBanks
    );
  }

  selectReceivingBank(receivingBank: ReceivingBank) {
    this.exporter.receivingBank = receivingBank;
  }

  selectBank(bank: Bank) {
    this.exporter.bank = bank.name;
  }

  searchBanks(event) {
    this.bankService.findBankStartingWith(event.query).subscribe(
      (banks: Bank[]) => this.filteredBanks = banks
    );
  }
}
