<!--<div class="ui-g form-group" *ngIf="clearingFile">-->

    <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
         <p-autoComplete id="consignee"
                         [(ngModel)]="clearingInstruction.consignee"
                         field="label"
                         [suggestions]="filteredConsignees"
                         (completeMethod)="search($event)"
                         [autoHighlight]="true"
                         (onSelect)="selectConsignee($event)"
                         minLength="2"
                         required=true
         >
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>
        <label for="consignee">Search Consignee</label>
      </span>
        <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="!clearingInstruction?.consignee?.code" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>


