import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';
import {CreditNote} from '../models/financials';
import {ClearingFile} from '../models/clearingFile';
import {ExportFile} from '../models/exportFile';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  findReasonsByCode(code: string) {
    return this.http.get(this.config.apiEndpoint + `api/creditNoteReasons/search/findByCodeStartsWith?code=${code}`).pipe(
      map(response => response['_embedded'].creditNoteReasons)
    );
  }

  saveCreditNote(creditNote: CreditNote, file: ClearingFile | ExportFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (file.type === 'ClearingFile' || file.type === 'Correction') {
      creditNote.clearingFile = file._links.self.href;
      creditNote.type = 'CreditNote';
    } else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
      creditNote.exportFile = file._links.self.href;
      creditNote.type = 'ExportCreditNote';
    } else {
      creditNote.exBondFile = file._links.self.href;
      creditNote.type = 'ExBondCreditNote';
    }
    if (creditNote._links && creditNote._links.self) {
      return this.http.put(creditNote._links.self.href, JSON.stringify(creditNote), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/creditNotes', JSON.stringify(creditNote), {headers});
    }
  }

  getCreditNotes(file: ClearingFile | ExportFile): Observable<CreditNote[]> {
    if (file.type === 'ClearingFile' || file.type === 'Correction') {
      return this.http.get(`${this.config.filesApiEndpoint}api/importCreditNotes/search/findByClearingFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].importCreditNotes)
        );
    } else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
      return this.http.get(`${this.config.filesApiEndpoint}api/exportCreditNotes/search/findByExportFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].exportCreditNotes)
        );
    } else {
      return this.http.get(`${this.config.filesApiEndpoint}api/exBondCreditNotes/search/findByExBondFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].exBondCreditNotes)
        );
    }
  }
}
