<p-toolbar>
  <div class="ui-toolbar-group-left ui-fluid ui-g-8">
    <div class="ui-g-4 ui-md-4">
      <div class="ui-inputgroup">
        <p-autoComplete id="manifestFileNumber" [(ngModel)]="roadManifest" field="manifestFileNo"
                        [suggestions]="filteredRoadManifests"
                        (completeMethod)="searchRoadManifestsByQuery($event)"
                        [autoHighlight]="true"
                        (onSelect)="selectRoadManifest($event)"
                        minLength="2"
                        [inputStyle]="{color:'white'}"
                        placeholder="Manifest File Number">
          <ng-template let-option pTemplate="item">
            <div>{{option.manifestFileNo}}</div>
          </ng-template>
        </p-autoComplete>
        <span class="sequence ui-inputgroup-addon" (click)="correctionList.toggle($event)">{{roadManifest.sequence}}</span>
        <p-overlayPanel #correctionList [dismissable]="true">
          <ng-template ngFor let-option [ngForOf]="listManifestFiles" pTemplate="item">
            <div id="sequence" class="ui-overlaypanel-content" (click)="selectCorrection(option)">
              {{option.manifestFileNo}}/{{option.sequence}}
            </div>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>
    <div class="ui-g-3 ui-md-3">
                  <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     class="color-white"
                     [(ngModel)]="roadManifest.manifestNo">
              <label class="color-white">Manifest No.</label>
            </span>
            </div>
            </span>
    </div>
    <div class="ui-g-2 ui-md-2">
                  <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     class="color-white"
                     [(ngModel)]="cuscarType">
              <label class="color-white">Cuscar Type</label>
            </span>
            </div>
            </span>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewRoadManifest()"
            class="ui-button-secondary" [disabled]="disableButton"></button>
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="hasCarn() || disableButton"
            (click)="saveRoadManifest()"></button>
    <button pButton type="button" *ngIf="hasCarn()" label="Create VOC" icon="fa fa-plus"
            (click)="displayVocDialog=true"></button>
  </div>
</p-toolbar>
<digi-manifest-sequence-modal [roadManifest]="roadManifest"
                              [display]="displayVocDialog"
                              (displayChange)="displayVocDialog=$event"
                              (onUpdateRoadManifest)="updateRoadManifest($event)"

>
</digi-manifest-sequence-modal>
