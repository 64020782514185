import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ClearingFile} from '../../../models/clearingFile';
import {LocalAgent} from '../../../models/file';
import {AgentService} from '../../../services/agent.service';
import {ShareDataService} from '../../../services/share-data.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-clearing-agent',
  templateUrl: './clearing-agent.component.html',
  styleUrls: ['./clearing-agent.component.css']
})
export class ClearingAgentComponent implements OnInit, OnDestroy, OnChanges {
  clearingFile: ClearingFile;
  @Input() clearingAgent: LocalAgent;
  @Input('label') labelAgent = 'Clearing Agent';
  @Input() withAddress = true;
  @Output()
  localForwarderRegistration = new EventEmitter();
  selectedClearingAgent: LocalAgent;
  localAgentAddresses = [];
  filteredAgents: LocalAgent[];
  subscribe: Subscription;

  constructor(
    private localAgentService: AgentService,
    private shareDataService: ShareDataService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.selectedClearingAgent = this.clearingAgent;
    this.subscribe = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
          this.findLocalAgentAddresses(this.clearingFile);
        }
      });
  }

  ngOnChanges() {
    this.selectedClearingAgent = this.clearingAgent;
    if (this.clearingFile) {
      this.findLocalAgentAddresses(this.clearingFile);
    }
  }

  search(event) {
    this.localAgentService
      .findLocalAgentNameOrCodeStartsWith(event.query)
      .subscribe(data => {
        this.filteredAgents = data;
      });
  }

  select(value) {
    this.clearingAgent.code = value.code;
    this.clearingAgent.name = value.name;
    this.clearingAgent.label = value.label;
    this.clearingAgent.customsRegCode = value.customsRegCode;
    this.clearingAgent.customsOfficeCreditors = value.customsOfficeCreditors;
    this.clearingAgent.customsOfficeBonds = value.customsOfficeBonds;
    this.findLocalAgentAddresses(this.clearingFile);
  }

  private findLocalAgentAddresses(clearingFile) {
    this.localAgentService.findLocalAgentFromClearingAgent(clearingFile)
      .subscribe(localAgentData => {
        this.localAgentAddresses = localAgentData[0].addresses;
      });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
