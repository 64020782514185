/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/primeng/components/growl/growl.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/growl/growl";
import * as i5 from "primeng/components/common/messageservice";
import * as i6 from "../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i7 from "primeng/components/fileupload/fileupload";
import * as i8 from "@angular/platform-browser";
import * as i9 from "primeng/components/common/shared";
import * as i10 from "./filedemo.component";
import * as i11 from "../../breadcrumb.service";
var styles_FileDemoComponent = [];
var RenderType_FileDemoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileDemoComponent, data: {} });
export { RenderType_FileDemoComponent as RenderType_FileDemoComponent };
function View_FileDemoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " - ", " bytes"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = _v.context.$implicit.size; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FileDemoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDemoComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadedFiles; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileDemoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDemoComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadedFiles.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileDemoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Upload"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "p-growl", [], null, null, null, i2.View_Growl_0, i2.RenderType_Growl)), i0.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i0.ɵdid(8, 4636672, null, 0, i4.Growl, [i0.ElementRef, i3.DomHandler, i0.IterableDiffers, [2, i5.MessageService], i0.NgZone], { value: [0, "value"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 5, "p-fileUpload", [["accept", "image/*"], ["maxFileSize", "1000000"], ["multiple", "multiple"], ["name", "demo[]"], ["url", "./upload.php"]], null, [[null, "onUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpload" === en)) {
        var pd_0 = (_co.onUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FileUpload_0, i6.RenderType_FileUpload)), i0.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i0.ɵdid(11, 5488640, null, 1, i7.FileUpload, [i0.ElementRef, i3.DomHandler, i8.DomSanitizer, i0.NgZone], { name: [0, "name"], url: [1, "url"], multiple: [2, "multiple"], accept: [3, "accept"], maxFileSize: [4, "maxFileSize"] }, { onUpload: "onUpload" }), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_FileDemoComponent_1)), i0.ɵdid(14, 16384, [[1, 4]], 0, i9.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.msgs; _ck(_v, 8, 0, currVal_0); var currVal_1 = "demo[]"; var currVal_2 = "./upload.php"; var currVal_3 = "multiple"; var currVal_4 = "image/*"; var currVal_5 = "1000000"; _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "content"; _ck(_v, 14, 0, currVal_6); }, null); }
export function View_FileDemoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FileDemoComponent_0, RenderType_FileDemoComponent)), i0.ɵdid(1, 49152, null, 0, i10.FileDemoComponent, [i11.BreadcrumbService], null, null)], null, null); }
var FileDemoComponentNgFactory = i0.ɵccf("ng-component", i10.FileDemoComponent, View_FileDemoComponent_Host_0, {}, {}, []);
export { FileDemoComponentNgFactory as FileDemoComponentNgFactory };
