import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var MasContainerSizeService = /** @class */ (function () {
    function MasContainerSizeService(http, config) {
        this.http = http;
        this.config = config;
    }
    MasContainerSizeService.prototype.findAllMASContainerSizeStartsWith = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/masContainerSizes/search/codeStartsWith?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].masContainerSizes; }));
    };
    MasContainerSizeService.ngInjectableDef = i0.defineInjectable({ factory: function MasContainerSizeService_Factory() { return new MasContainerSizeService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: MasContainerSizeService, providedIn: "root" });
    return MasContainerSizeService;
}());
export { MasContainerSizeService };
