import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {RoadManifest, CuscarType, ManifestWaybill} from '../models/roadManifest';
import {Address, Country, LocalAgent, PortDetail, Transporter, VoyageDetails} from '../models/file';
import {ExportBillOfEntry} from '../models/billOfEntries';
import {CustomsOffice} from '../../digi-business-entity/models/business-entity';
import {ExportFile} from '../models/exportFile';
import {ExportFileService} from './export-file.service';
import {CompanyService} from './company.service';
import {AgentService} from './agent.service';
import {VoyageMasterFile} from '../models/voyageFile';
import * as moment from 'moment';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private exportFileService: ExportFileService,
    private authorisationService: AuthorisationService
  ) {
  }

  saveRoadManifest(roadManifest: RoadManifest) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (roadManifest._links && roadManifest._links.self) {
      if (roadManifest.type === 'RoadManifest') {
        return this.http.put(this.config.filesApiEndpoint + 'api/roadManifests/' + roadManifest.id, JSON.stringify(roadManifest), {headers});
      } else {
        return this.http.put(this.config.filesApiEndpoint + 'api/roadManifestCorrections/' + roadManifest.id, JSON.stringify(roadManifest), {headers});
      }
    } else if (roadManifest.type === 'RoadManifest') {
      return this.http.post(this.config.filesApiEndpoint + 'api/roadManifests', JSON.stringify(roadManifest), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/roadManifestCorrections', JSON.stringify(roadManifest), {headers});
    }
  }

  generate(id: string): Observable<any> {
    return this.http
      .post(`${this.config.filesApiEndpoint}api/roadManifests/${id}/manifest`, {});
  }

  findRoadManifestByDocumentMessageNumber(documentMessageNumber: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifests/search/findByDocumentMessageNumber?documentMessageNumber=${documentMessageNumber}`);
  }


  findRoadManifestsByHawbNo(hawbNo: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifests/search/findByHawbNo?hawbNo=${hawbNo}`)
      .pipe(
        map(response => response['_embedded'].roadManifests)
      );
  }

  findByHawbOrManifestFileNoOrManifestNo(query: any) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifests/search/findByHawbNoOrManifestNoOrManifestFileNo?query=${query}`)
      .pipe(
        map(response => response['_embedded'].roadManifests)
      );
  }

  loadAllCorrectionsIncludingOriginalManifestFile(manifestFileNo: string) {
    if (manifestFileNo === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifestCorrections/search/findByManifestFileNo?manifestFileNo=${manifestFileNo}`)
      .pipe(
        map(response => response['_embedded'].roadManifestCorrections)
      );
  }

  findByManifestFileNoAndSequence(manifestFileNo: string, selectedSequence: any) {
    if (manifestFileNo === '' && selectedSequence === null) {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifestCorrections/search/findByManifestFileNoAndSequence?manifestFileNo=` + manifestFileNo
        + '&sequence=' + selectedSequence)
      .pipe(
        map(response => response['_embedded'].roadManifestCorrections)
      );
  }

  findAllSequences(manifestFileNo: string) {
    if (manifestFileNo === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifestCorrections/search/findSequencesByManifestFileNo?manifestFileNo=`
        + manifestFileNo + '&projection=sequenceAndManifestFileNo')
      .pipe(
        map(response => response['_embedded'].roadManifestCorrections)
      );
  }

  findById(id: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/roadManifests/${id}`);
  }

  createNewManifest() {
    const roadManifest = new RoadManifest();
    this.authorisationService.getLoggedInUser().pipe(
      map(user => {
        if (user && user.attributes && user.attributes['branch']) {
          roadManifest.branch  = user.attributes['branch'][0];
        }
      })
    );
    roadManifest.voyageDetails = new VoyageDetails();
    roadManifest.countryOfExport = new Country();
    roadManifest.countryOfExport.code = 'ZA ';
    roadManifest.countryOfExport.name = 'SOUTH AFRICA';
    roadManifest.countryOfExport.label = 'ZA: SOUTH AFRICA';
    roadManifest.exitPort = new PortDetail();
    roadManifest.exitPort.code = 'BBR';
    roadManifest.exitPort.name = 'BEIT BRIDGE (ZIMBABWE BORDER POST)';
    roadManifest.exitPort.label = 'BBR: BEIT BRIDGE (ZIMBABWE BORDER POST)';
    roadManifest.customOffice = new CustomsOffice();
    roadManifest.destination = new Country();
    roadManifest.transporter = new Transporter();
    roadManifest.waybills = [];
    roadManifest.cuscarType = new CuscarType();
    roadManifest.cuscarType.type = 'RFM       ';
    roadManifest.cuscarType.description = 'Road Freight Manifest';
    roadManifest.cuscarType.label = 'RFM: Road Freight Manifest';

    roadManifest.localAgent = new LocalAgent();
    roadManifest.localAgent.address = new Address();
    roadManifest.crew = [];
    roadManifest.type = 'RoadManifest';
    roadManifest.transportMethodCode = 'ROA';
    roadManifest.manifestType = 'EXPORT';
    return roadManifest;
  }

  createWaybill(roadManifest: RoadManifest, boe: ExportBillOfEntry) {
    const waybill = new ManifestWaybill();
    waybill.containers = boe.containers;
    waybill.lrn = boe.lrn;
    waybill.mrn = boe.mrn;
    if (boe.cusres) {
      waybill.releaseDate = moment(boe.cusres.receivedDate, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
    }
    waybill.mawbNo = boe.mawbNumber;
    waybill.hawbNo = boe.transportDocNo;
    waybill.cpc = boe.cpc;
    waybill.marksAndNumbers = boe.markAndNumbers;
    waybill.numberOfPackages = boe.numberOfPackages;
    waybill.descriptionOfGoods = boe.lines[0].description;
    waybill.weight = boe.totalWeight;
    waybill.grossMass = boe.grossMass;
    waybill.ucrNumber = boe.ucrNumber;
    this.exportFileService.findByRef(boe._links.exportFile.href).subscribe(
      (ef: ExportFile) => {
        waybill.consignee = ef.exportConsignee;
        waybill.consignor = ef.exporter;
        waybill.destinationPort = ef.transportDocument.destinationPort;
        waybill.departurePort = ef.transportDocument.departurePort;
        waybill.mawbDate = ef.transportDocument.mawbDate;
        waybill.hawbDate = ef.transportDocument.hawbDate;
        boe.exportFile = ef;
        this.setRoadManifestValues(roadManifest, boe);
      }
    );
    roadManifest.waybills.push(waybill);
  }

  private setRoadManifestValues(roadManifest: RoadManifest, boe: ExportBillOfEntry) {
    if (!roadManifest.manifestNo) {
      roadManifest.manifestNo = boe.mawbNumber;
    }
    if (!roadManifest.transporter.transporterCode) {
      roadManifest.transporter = boe.exportFile.transportDocument.transporter;
    }
    if (!roadManifest.voyageDetails.vehicleAndTrailerReg) {
      roadManifest.voyageDetails.vehicleAndTrailerReg = boe.exportFile.transportDocument.voyageDetails.vehicleAndTrailerReg;
    }
    if (!roadManifest.voyageDetails.trailerOne) {
      roadManifest.voyageDetails.trailerOne = boe.exportFile.transportDocument.voyageDetails.trailerOne;
    }
    if (!roadManifest.voyageDetails.trailerTwo) {
      roadManifest.voyageDetails.trailerTwo = boe.exportFile.transportDocument.voyageDetails.trailerTwo;
    }
    if (!roadManifest.localAgent.code) {
      roadManifest.localAgent = boe.exportFile.localAgent;
    }
    if (!roadManifest.countryOfExport.code) {
      roadManifest.countryOfExport = {
        name: boe.exportFile.transportDocument.departurePort.countryName,
        code: boe.exportFile.transportDocument.departurePort.countryCode,
        label: boe.exportFile.transportDocument.departurePort.label
      };
    }
    if (!roadManifest.exitPort.code) {
      roadManifest.exitPort = boe.exportFile.transportDocument.exitPort;
    }
    if (!roadManifest.destination.code) {
      roadManifest.destination = boe.destination;
    }
    if (!roadManifest.customOffice.districtOfficeCode) {
      roadManifest.customOffice = boe.exportFile.customOffice;
    }
    if (!roadManifest.arrivalDate) {
      roadManifest.arrivalDate = boe.exportFile.transportDocument.arrivalDate;
    }
    if (!roadManifest.masterTransportDocDate) {
      roadManifest.masterTransportDocDate = boe.exportFile.transportDocument.mawbDate;
    }
  }

  generateReports(id: string, voyageMasterFileNo: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/voyageFiles/${id}/documents?masterFileNo=${voyageMasterFileNo}`);
  }

  findByVoyageMasterFileNumber(voyageMasterFileNumber: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/voyageMasterFiles/search/findByVoyageMasterFileNo?voyageMasterFileNo=` + voyageMasterFileNumber);
  }

  saveVoyageMasterFile(voyageMasterFile: VoyageMasterFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (voyageMasterFile._links && voyageMasterFile._links.self) {
      return this.http.put(this.config.filesApiEndpoint + 'api/voyageMasterFiles/' + voyageMasterFile.id, JSON.stringify(voyageMasterFile), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/voyageMasterFiles', JSON.stringify(voyageMasterFile), {headers});
    }
  }
}
