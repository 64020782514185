import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ExitPortService = /** @class */ (function () {
    function ExitPortService(http, config) {
        this.http = http;
        this.config = config;
    }
    ExitPortService.prototype.findExitPortByNameOrCode = function (query) {
        if (query === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/exitPorts/search/codeOrNameStartsWith?code=" + query)
            .pipe(map(function (response) { return response['_embedded'].exitPorts; }));
    };
    ExitPortService.ngInjectableDef = i0.defineInjectable({ factory: function ExitPortService_Factory() { return new ExitPortService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ExitPortService, providedIn: "root" });
    return ExitPortService;
}());
export { ExitPortService };
