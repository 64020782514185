import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getPaymentType(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/paymentType`);
  }
}
