<div class="ui-g">
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataTable</h1>
            <p-table [columns]="cols" [value]="cars1" selectionMode="single" dataKey="vin" [(selection)]="selectedCar"
                     [paginator]="true" [rows]="10" [responsive]="true" >
                <ng-template pTemplate="caption">
                    List of Cars
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                          {{col.header}}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns">
                          {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataView</h1>
            <p-dataView #dv [value]="cars2" [paginator]="true" [rows]="10" paginatorPosition="both" filterBy="brand" [sortField]="sortField"
                        [sortOrder]="sortOrder">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="ui-g">
                            <div class="ui-g-12 ui-md-4">
                                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By" (onChange)="onSortChange($event)" [style]="{'width':'15em'}"></p-dropdown>
                            </div>
                            <div class="ui-g-6 ui-md-4 filter-container">
                                <div style="position:relative">
                                    <input type="text" pInputText placeholder="Search by brand" (keyup)="dv.filter($event.target.value)">
                                </div>
                            </div>
                            <div class="ui-g-6 ui-md-4" style="text-align:right">
                                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                        </div>
                    </div>
                </p-header>
                <ng-template let-car pTemplate="listItem">
                    <div class="ui-g" style="padding: 2em;border-bottom: 1px solid #d9d9d9">
                        <div class="ui-g-12 ui-md-3" style="text-align:center">
                            <img src="assets/demo/images/car/{{car.brand}}.gif">
                        </div>
                        <div class="ui-g-12 ui-md-8 car-details">
                            <div class="ui-g">
                                <div class="ui-g-2 ui-sm-6">Vin: </div>
                                <div class="ui-g-10 ui-sm-6">
                                    <b>{{car.vin}}</b>
                                </div>

                                <div class="ui-g-2 ui-sm-6">Year: </div>
                                <div class="ui-g-10 ui-sm-6">
                                    <b>{{car.year}}</b>
                                </div>

                                <div class="ui-g-2 ui-sm-6">Brand: </div>
                                <div class="ui-g-10 ui-sm-6">
                                    <b>{{car.brand}}</b>
                                </div>

                                <div class="ui-g-2 ui-sm-6">Color: </div>
                                <div class="ui-g-10 ui-sm-6">
                                    <b>{{car.color}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="ui-g-12 ui-md-1 search-icon">
                            <button pButton type="button" icon="pi pi-search" ></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-car pTemplate="gridItem">
                    <div style="padding:.5em" class="ui-g-12 ui-md-3">
                        <p-panel [header]="car.vin" [style]="{'text-align':'center'}">
                            <img src="assets/demo/images/car/{{car.brand}}.gif">
                            <div class="car-detail">{{car.year}} - {{car.color}}</div>
                            <hr class="ui-widget-content" style="border-top:0">
                            <button pButton type="button" icon="pi pi-search"  style="margin-top:0"></button>
                        </p-panel>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
    </div>
    <div class="ui-g-12 ui-md-8">
        <div class="card card-w-title">
            <h1>PickList</h1>
            <p-pickList [source]="sourceCars" [target]="targetCars" sourceHeader="Available" targetHeader="Selected" [responsive]="true"
                        [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px"/>
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
    </div>

    <div class="ui-g-12 ui-md-4">
        <div class="card card-w-title">
            <h1>OrderList</h1>
            <p-orderList [value]="orderListCars" [listStyle]="{'height':'250px'}" [responsive]="true" header="OrderList">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px" />
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-orderList>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card">
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6">
                    <h1>Tree</h1>
                    <p-tree [value]="files1" selectionMode="single" [(selection)]="selectedNode1" [style]="{width: '100%'}"></p-tree>
                </div>
                <div class="ui-g-12 ui-md-6">
                    <h1>Checkbox Tree</h1>
                    <p-tree [value]="files2" selectionMode="checkbox" [(selection)]="selectedNodes" [style]="{width: '100%'}"></p-tree>
                </div>
            </div>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card">
            <h1>Horizontal Tree</h1>
            <p-tree [value]="files3" selectionMode="single" [(selection)]="selectedNode2" layout="horizontal"></p-tree>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>TreeTable</h1>
            <p-treeTable [value]="files4" [columns]="cols2" selectionMode="checkbox" [(selection)]="selectedNode3">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns; let i = index">
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                            <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0"></p-treeTableCheckbox>
                            <span style="vertical-align: middle">{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-treeTable>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title" style="overflow: auto">
            <h1>Organization Chart</h1>
            <p-organizationChart [value]="data" selectionMode="single" [(selection)]="selectedNodeOrg"></p-organizationChart>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Carousel</h1>
            <p-carousel headerText="Cars" [value]="carouselCars">
                <ng-template let-car pTemplate="item">
                    <div class="ui-g" style="text-align:center">
                        <div class="ui-g-12" style="text-align:Center">
                            <img src="assets/demo/images/car/{{car.brand}}.gif" />
                        </div>
                        <div class="ui-g-6">Vin: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.vin}}</div>

                        <div class="ui-g-6">Year: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.year}}</div>

                        <div class="ui-g-6">Brand: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.brand}}</div>

                        <div class="ui-g-6">Color: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.color}}</div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Schedule</h1>
            <p-schedule [events]="events" defaultDate="2016-01-12" [header]="scheduleHeader"></p-schedule>
        </div>
    </div>
</div>
