<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-left">
    <div class="ui-inputgroup">
      <digi-exbond-file-search></digi-exbond-file-search>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="isSaveDisabled() || disableButtons" (click)="saveExBondFile()"></button>
    <button *ngIf="canAdd" pButton type="button" [label]="label" icon="fa fa-file" (click)="addAction()"
            class="ui-button-secondary" [disabled]="disableButtons"></button>
    <button pButton type="button" *ngIf="canCreateVoC && hasExBondFile() && hasMrn()" label="Create VOC" icon="fa fa-plus"
            (click)="createVoc()"></button>
  </div>
</p-toolbar>
