<div class="content-section implementation">
  <p-table [value]="billOfEntryLines" selectionMode="single" [(selection)]="defaultSelectedBillOfEntryLine" [resizableColumns]="true"
    (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="header" >
      <tr>
        <th scope="col" [style.width]="'5%'">
          Line
        </th>
        <th scope="col" [style.width]="'5%'">
          Origin
        </th>
        <th scope="col" [style.width]="'10%'">
          Tarrif
        </th>
        <th [style.width]="'3%'"></th>
        <th scope="col" [style.width]="'10%'">
          Prev. Proc
        </th>
        <th scope="col" [style.width]="'20%'">
          Invoice No.
        </th>
        <th scope="col" [style.width]="'47%'">
          Description
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td scope="row">
          <label>{{rowData.lineNo}}</label>
        </td>
        <td>
          <label>{{rowData.countryOfOrigin?.code}}</label>
        </td>
        <td>
          <label>{{rowData.tariffCode?.code}}</label>
        </td>
        <td>
          <label>{{rowData.tariffCode?.checkDigit}}</label>
        </td>
        <td>
          <label>{{rowData.invoiceGrossValue}}</label>
        </td>
        <td>
          <label>{{rowData.invoiceNumber}}</label>
        </td>
        <td colspan="8">
          <div class="ui-g-12">
              <label>{{rowData.tariffCode?.fullDescription}}</label>
          </div>
        </td>
      </tr>
    </ng-template>
       <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="12">
            <label>records found</label>
          </td>
        </tr>
      </ng-template>
  </p-table>
</div>
