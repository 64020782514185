import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  Address,
  Supplier,
  SupplierInvoice,
  TransportDocument
} from '../../../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {Subscription} from 'rxjs';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import * as moment from 'moment';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company.service';
import {BillOfEntry} from '../../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../../subjects/billOfEntry-behaviour-subject';
import {ClearingFile, ReportFile} from '../../../models/clearingFile';
import {ImporterService} from '../../../services/importer.service';

@Component({
  selector: 'digi-report-file',
  templateUrl: './report-file.component.html',
  styleUrls: ['./report-file.component.scss']
})
export class ReportFileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clearingFile: ClearingFile;
  @Input() reportFile: ReportFile;
  @Input() title: string;
  @Input() transportMethod: string;
  supplier: Supplier;
  transportDocument: TransportDocument;
  logo: string;
  companyAddress: string;
  companyName: string;
  invoiceNo: string;
  boe: BillOfEntry;

  transportDocumentSubscription: Subscription;
  supplierSubscription: Subscription;
  companySubscription: Subscription;
  boeSubscription: Subscription;
  importerDeliveryAddressSubscription: Subscription;

  constructor(
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private companyService: CompanyService,
    private importerService: ImporterService,
    private boeBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.supplierSubscription = this.supplierInvoiceBehaviourSubject.getSupplierInvoice().subscribe(
      (supplierInvoice: SupplierInvoice) => {
        this.supplier = supplierInvoice.supplier;
        this.invoiceNo = supplierInvoice.invoiceNumber;
      }
    );

    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments().subscribe(
      (transportDocument: TransportDocument) => this.transportDocument = transportDocument
    );

    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          this.logo = 'data:image/png;base64,' + companies[0].logo;
          const branch = this.clearingFile.branch ? companies[0].branches.find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
          this.companyAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.companyName = companies[0].name;
        }
      }
    );

    this.boeSubscription = this.boeBehaviourSubject.getBillOfEntry().subscribe(
      (boe: BillOfEntry) => this.boe = boe
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearingFile) {
      this.clearingFile = changes.clearingFile.currentValue;
    }

    if (changes.reportFile) {
      this.reportFile = changes.reportFile.currentValue;
      if (!this.reportFile.deliveryAddress) {
        this.importerDeliveryAddressSubscription = this.importerService.findImporterDeliveryAddress(this.clearingFile.importer.code).subscribe(
          (deliveryAddress => {
            this.reportFile.deliveryAddress = Object.assign({}, deliveryAddress);
          })
        );
      }
    }
  }

  ngOnDestroy() {
    if (this.transportDocumentSubscription) {
      this.transportDocumentSubscription.unsubscribe();
    }

    if (this.supplierSubscription) {
      this.supplierSubscription.unsubscribe();
    }

    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }

    if (this.boeSubscription) {
      this.boeSubscription.unsubscribe();
    }

    if (this.importerDeliveryAddressSubscription) {
      this.importerDeliveryAddressSubscription.unsubscribe();
    }
  }

  getDate() {
    return moment().format('DD/MM/YYYY');
  }

  getTime() {
    return `${moment().format('HH:mm')}`;
  }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }
}
