import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var PaymentTypeService = /** @class */ (function () {
    function PaymentTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    PaymentTypeService.prototype.getPaymentType = function () {
        return this.http.get(this.config.apiEndpoint + "api/paymentType");
    };
    PaymentTypeService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentTypeService_Factory() { return new PaymentTypeService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: PaymentTypeService, providedIn: "root" });
    return PaymentTypeService;
}());
export { PaymentTypeService };
