import { Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ExportFileBehaviourSubject } from '../../subjects/exportfile-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../subjects/exportfile-behaviour-subject";
import * as i2 from "@angular/router";
var ExportFileExistsGuard = /** @class */ (function () {
    function ExportFileExistsGuard(exportFileBehaviourSubject, router) {
        this.exportFileBehaviourSubject = exportFileBehaviourSubject;
        this.router = router;
    }
    ExportFileExistsGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.exportFileBehaviourSubject.getExportFile().pipe(take(1), switchMap(function (ef) {
            if (ef.id) {
                return of(true);
            }
            else {
                var parent_1 = state.root.firstChild.routeConfig.path;
                _this.router.navigate([parent_1, 'register']);
                return of(false);
            }
        }));
    };
    ExportFileExistsGuard.ngInjectableDef = i0.defineInjectable({ factory: function ExportFileExistsGuard_Factory() { return new ExportFileExistsGuard(i0.inject(i1.ExportFileBehaviourSubject), i0.inject(i2.Router)); }, token: ExportFileExistsGuard, providedIn: "root" });
    return ExportFileExistsGuard;
}());
export { ExportFileExistsGuard };
