import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Container, DepotTerminal} from '../../../../models/file';
import {DepotTerminalService} from '../../../../services/depot-terminal.service';
import {ContainerBehaviorSubject} from '../../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';
import {ClearingInstructionBehaviorSubject} from '../../../../../../subjects/clearingInstruction-behavior-subject';


@Component({
  selector: 'digi-app-depot',
  templateUrl: './depot.component.html',
  styleUrls: ['./depot.component.scss']
})
export class RoadSeaDepotComponent implements OnInit, OnDestroy {
  @Input()
  filteredDepotTerminals: DepotTerminal[];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private depotTerminalService: DepotTerminalService,
              private containerBehaviorSubject: ContainerBehaviorSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => {
        this.selectedContainer = ct;
        console.log(this.selectedContainer);
        if (this.selectedContainer.depot && !this.selectedContainer.depot.code) {
          this.clearingInstructionBehaviorSubject.getClearingInstructions().subscribe(clearingInstructions => {
            const ciWithDepot = clearingInstructions.find(el => el.degroupingDepot !== null);
            if (ciWithDepot) {
              this.selectedContainer.depot = Object.assign({}, ciWithDepot.degroupingDepot);
            }
            console.log(this.selectedContainer);
          });
        }
      });
  }

  search(event) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, 'D').subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  clearAll() {
    this.selectedContainer.depot = undefined;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
