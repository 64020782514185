import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {ExportFileBehaviourSubject} from '../../subjects/exportfile-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class ExportFileExistsGuard implements CanActivate {
  constructor(
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.exportFileBehaviourSubject.getExportFile().pipe(
      take(1),
      switchMap(ef => {
        if (ef.id) {
          return of(true);
        } else {
          const parent = state.root.firstChild.routeConfig.path;
          this.router.navigate([parent, 'register']);
          return of(false);
        }
      })
    );
  }
}
