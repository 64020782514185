import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var PurchaseTermService = /** @class */ (function () {
    function PurchaseTermService(http, config) {
        this.http = http;
        this.config = config;
    }
    PurchaseTermService.prototype.getPurchaseTerms = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/purchaseTerms/" + code);
    };
    PurchaseTermService.prototype.findPurchaseTermsStartWith = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/purchaseTerms/search/codeOrDescriptionStartsWith?code=" + code);
    };
    PurchaseTermService.prototype.findPurchaseTermsStartsWith = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        var terms = this.http
            .get(this.config.apiEndpoint + "api/purchaseTerms/search/codeOrDescriptionStartsWith?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].purchaseTerms; }));
        return terms;
    };
    PurchaseTermService.ngInjectableDef = i0.defineInjectable({ factory: function PurchaseTermService_Factory() { return new PurchaseTermService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: PurchaseTermService, providedIn: "root" });
    return PurchaseTermService;
}());
export { PurchaseTermService };
