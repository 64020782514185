import { Component, Input, OnInit } from '@angular/core';
import { BusinessEntity } from 'src/app/digi-business-entity/models/business-entity';

@Component({
  selector: 'app-main-business-entity-details',
  templateUrl: './business-entity-details.component.html',
  styleUrls: ['./business-entity-details.component.scss']
})
export class BusinessEntityDetailsComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  constructor() { }

  ngOnInit() {
  }

}
