import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CommodityService = /** @class */ (function () {
    function CommodityService(http, config) {
        this.http = http;
        this.config = config;
    }
    CommodityService.prototype.findByCode = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/commodities/search/findByCodeStartsWithIgnoreCase?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].commodities; }));
    };
    CommodityService.ngInjectableDef = i0.defineInjectable({ factory: function CommodityService_Factory() { return new CommodityService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CommodityService, providedIn: "root" });
    return CommodityService;
}());
export { CommodityService };
