import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../app.config.module";
import * as i2 from "@angular/common/http";
var CertificateTypeService = /** @class */ (function () {
    function CertificateTypeService(config, http) {
        this.config = config;
        this.http = http;
    }
    CertificateTypeService.prototype.findByCode = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/certificateTypes/search/codeStartsWith?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].certificateTypes; }));
    };
    CertificateTypeService.ngInjectableDef = i0.defineInjectable({ factory: function CertificateTypeService_Factory() { return new CertificateTypeService(i0.inject(i1.APP_CONFIG), i0.inject(i2.HttpClient)); }, token: CertificateTypeService, providedIn: "root" });
    return CertificateTypeService;
}());
export { CertificateTypeService };
