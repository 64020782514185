import {ClearingFile} from './clearingFile';
import {BillOfEntry, ExBondBillOfEntry, ExportBillOfEntry} from './billOfEntries';
import {ExportFile} from './exportFile';
import {ExBondFile} from './exBondFile';

export class BillsOfEntry {
  boes: BillOfEntry[] = new Array<BillOfEntry>();
  clearingFile: ClearingFile;

  [k: string]: any;
}

export class ExportBillsOfEntry {
  boes: ExportBillOfEntry[] = new Array<ExportBillOfEntry>();
  exportFile: ExportFile;

  [k: string]: any;
}

export class ExBondBillsOfEntry {
  boes: ExBondBillOfEntry[] = new Array<ExBondBillOfEntry>();
  exBondFile: ExBondFile;

  [k: string]: any;
}


