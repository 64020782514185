import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng/primeng';
import {Subscription} from 'rxjs';
import {AdditionalInfoBehaviourSubject} from '../../../../subjects/addittional-info-behaviour-subject';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {ContainerBehaviorSubject} from '../../../../subjects/container-behavior-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile} from '../../models/clearingFile';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {ClearingFileService} from '../../services/clearing-file.service';
import {ImporterService} from '../../services/importer.service';
import {ShareDataService} from '../../services/share-data.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-clearing-file-search',
  templateUrl: './clearing-file-search.component.html',
  styleUrls: ['./clearing-file-search.component.scss']
})
export class ClearingFileSearchComponent implements OnInit, OnDestroy {
  clearingFileNumberDiffer: KeyValueDiffer<string, any>;
  filteredClearingFiles: any[];
  clearingFile: ClearingFile;
  listClearingFiles: any[];
  @Input() isQuotation: boolean;
  @Input() transportMethod: string;
  @Output() selectedClearingFile: EventEmitter<any> = new EventEmitter();
  @ViewChild('correctionList') correctionList: OverlayPanel;
  subscription: Subscription;
  private branchCode: string;

  constructor(
    private importerService: ImporterService,
    private clearingFileService: ClearingFileService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
    private billOfEntryService: BillOfEntryService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private shareDataService: ShareDataService,
    private differs: KeyValueDiffers,
    private authorisationService: AuthorisationService
  ) {
    this.clearingFileNumberDiffer = this.differs.find({}).create();
  }

  ngOnInit() {
    this.authorisationService.getLoggedInUser().subscribe(user => {
      if (user && user.attributes && user.attributes['branch']) {
        this.branchCode = user.attributes['branch'][0];
      }
    });
    this.subscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
      this.clearingFile = cf;
      if (this.clearingFile.clearingFileNumber !== null && this.clearingFile.clearingFileNumber !== undefined) {
        this.clearingFileService.loadAllCorrectionsIncludingOriginalClearingFile(this.clearingFile.clearingFileNumber, this.transportMethod)
          .subscribe(data => {
            this.listClearingFiles = data;
            console.log(this.listClearingFiles);
          });
      }
    });
  }

  search(clearingFileNumber) {
    this.clearingFileService
      .loadClearingFile(clearingFileNumber.query, this.transportMethod, this.branchCode, this.isQuotation)
      .subscribe(data => {
        this.filteredClearingFiles = data;
      });
  }

  selectInitial(value) {
    if (value.type !== 'Correction') {
      this.clearingFileService.selectClearingFile(value);
    }
  }

  selectCorrection(value) {
    this.clearingFileService.selectClearingFile(value);
    this.correctionList.hide();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
