import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFile } from '../../models/clearingFile';
import {ClearingInstruction, Permit, SupplierInvoice, SupplierInvoiceLine, TariffCode, TariffFormula} from '../../models/file';
import { ShareDataService } from '../../services/share-data.service';
import { SupplierInvoiceLineBehaviourSubject } from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../../../subjects/supplierInvoice-behaviour-subject';
import { SchedulePartService } from '../../services/schedule-part.service';
import {PackType} from '../../models/roadManifest';
import {PackTypeService} from '../../services/pack-type.service';

@Component({
  selector: 'digi-invoice-lines-duty-paid-modal',
  templateUrl: './invoice-lines-duty-paid-modal.component.html',
  styleUrls: ['./invoice-lines-duty-paid-modal.component.scss'],
})
export class InvoiceLinesDutyPaidModalComponent implements OnInit, OnDestroy {
  @Input('display') display;
  supplierInvoiceLine: SupplierInvoiceLine;
  supplierInvoice: SupplierInvoice;
  @Input('clearingInstruction') clearingInstruction: ClearingInstruction;
  @Output() displayChange = new EventEmitter();
  clearingFile: ClearingFile;
  balanceValue: number;
  permits = new Array<Permit>();
  localAppliedValue;
  foreignAppliedValue;
  fullDescription;
  description;
  key;
  cols: any;
  rebateCols: any;
  dutiesAndRebates = [];
  appliedValue;
  openGlobalRebate;
  scheduleParts: TariffCode[];
  selectedRebate: TariffCode;
  selectedGlobalRebates: TariffCode[];
  subscription: Subscription;
  lineSubscription: Subscription;
  globalSubscription: Subscription;

  constructor(
    private shareDataService: ShareDataService,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private schedulePartService: SchedulePartService
  ) {
  }

  ngOnInit() {
    this.subscription = this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe(si => {
        this.supplierInvoice = si;
      });

    this.lineSubscription = this.supplierInvoiceLineBehaviourSubject
      .getSupplierInvoiceLine()
      .subscribe(line => {
        this.supplierInvoiceLine = line;
        if (this.supplierInvoiceLine) {
          if (this.supplierInvoiceLine.additionalScheduleTariffs) {
            this.populateSelectedRebates();
          } else {
            this.selectedRebate = new TariffCode();
          }
        }
      });

    this.selectedRebate = this.updateTableDisplay();
    this.description = this.supplierInvoiceLine.tariffCode.fullDescription;
    this.cols = [
      {field: 'scheduleCode', header: '', width: '5%'},
      {field: 'scheduleCode', header: '', width: '5%'},
      {field: 'dependantCode', header: '', width: '15%'},
      {field: 'dependantCheckDigit', header: '', width: '5%'},
      {field: 'scheduleDescription', header: '', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'},
    ];

    this.rebateCols = [
      {field: '', header: '', width: '2%'},
      {field: 'code', header: 'S/P', width: '2%'},
      {field: 'dependantCode', header: 'Dependant Code', width: '8%'},
      {field: 'tariffCheckDigit', header: '', width: '2%'},
      {field: 'tariffSDesc', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'},
    ];
  }

  private updateTableDisplay(): TariffCode {
    this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(
      this.clearingInstruction.customsPurpose.customsPurposeCode, this.supplierInvoiceLine.tariffCode.code, 'R')
      .subscribe(data => {
        this.scheduleParts = data;
        if (this.selectedRebate && this.selectedRebate.dependantCode) {
          const type = this.scheduleParts.find(t => t.dependantCode === this.selectedRebate.dependantCode);
          type.dependantCode = this.selectedRebate.dependantCode;
          this.selectedRebate = type;
        }
      });
    return this.selectedRebate;
  }

  private populateSelectedRebates() {
    this.selectedRebate = new TariffCode();
  }

  displayShortDescription() {
    this.description = this.supplierInvoiceLine.tariffCode.description;
  }

  displaySupplierInvDescription() {
    this.description = this.supplierInvoiceLine.description;
  }

  displayCustomsDescription() {
    this.description = this.supplierInvoiceLine.tariffCode.fullDescription;
  }

  onHide(event) {
    this.displayChange.emit(false);
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onSelect() {
    this.openGlobalRebate = false;
    this.updateTableDisplay();
  }

  onRowSelect(value): void {
    this.updateRebate(value.data);
  }

  private updateRebate(data): TariffCode {
    this.supplierInvoiceLine.procedureCategoryCode = this.clearingInstruction.customsProcedureCode;
    return this.updateSchedulePartFormula(data);
  }

  private updateSchedulePartFormula(data): TariffCode {
    const rebate: TariffCode = data;
    rebate.formula = new TariffFormula();
    rebate.formula.description =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].description;
    rebate.formula.computations =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].computations;
    rebate.formula.parameters =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].parameters;

    rebate.parameters = [];
    if (data.effectiveTradeAgreementParameters.STANDARD) {
      Object.keys(data.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
        rebate.parameters.push(
          data.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
      });
    } else {
      rebate.parameters = [];
    }
    return rebate;
  }

  selectCountryOfOrigin(event) {
    this.supplierInvoiceLine.countryOfOrigin = Object.assign({}, event);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.lineSubscription) {
      this.lineSubscription.unsubscribe();
    }
  }
}
