<table class="table table-sm table-bordered table-hover table-striped ">
    <thead class="table-primary">
      <tr>
        <th scope="col">
          <label>Line</label>
        </th>
        <th scope="col">
          <label>Origin</label>
        </th>
        <th scope="col">
          <label>Tariff</label>
        </th>
        <th scope="col">
          <label>Invoice</label>
        </th>
        <th scope="col">
          <label>Inv Line No</label>
        </th>
        <th scope="col">
          <label>Group No</label>
        </th>
        <th scope="col">
          <label>Orig Group No</label>
        </th>
        <th scope="col">
          <label>VOC Group No</label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td scope="row">
          <label></label>
        </td>
        <td>
          <label></label>
        </td>
        <td>
          <label> </label>
        </td>
        <td>
          <label></label>
        </td>
        <td>
          <label></label>
        </td>
        <td scope="row">
          <label></label>
        </td>
        <td scope="row">
          <label></label>
        </td>
        <td scope="row">
          <label></label>
        </td>
      </tr>
    </tbody>
  </table>
