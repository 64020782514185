<p-table [columns]="cols"
         [value]="selectedBillOfEntryLine?.additionalScheduleTariffs"
         [scrollable]="true" scrollHeight="100px">
  <ng-template pTemplate="body" let-rowData>
    <tr *ngIf="rowData?.schedulePart?.chargeRebate !='R' && rowData?.calculatedValue != 0">
      <td>
        {{rowData?.schedulePart.code}}
      </td>
      <td>
        <input type="text" pInputText [(ngModel)]="rowData.calculatedValue" [readOnly]="hasBeenMarkedAndSubmitted()">
      </td>
    </tr>
  </ng-template>
</p-table>
