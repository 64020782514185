import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var InvoiceService = /** @class */ (function () {
    function InvoiceService(http, config) {
        this.http = http;
        this.config = config;
    }
    InvoiceService.prototype.saveInvoice = function (invoice, file) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (file.type === 'ClearingFile' || file.type === 'Correction') {
            invoice.clearingFile = file._links.self.href;
            invoice.type = 'Invoice';
        }
        else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
            invoice.exportFile = file._links.self.href;
            invoice.type = 'ExportInvoice';
        }
        else {
            invoice.exBondFile = file._links.self.href;
            invoice.type = 'ExBondInvoice';
        }
        if (invoice._links && invoice._links.self) {
            return this.http.put(invoice._links.self.href, JSON.stringify(invoice), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/invoices', JSON.stringify(invoice), { headers: headers });
        }
    };
    InvoiceService.prototype.getInvoices = function (file) {
        if (file.type === 'ClearingFile' || file.type === 'Correction') {
            return this.http.get(this.config.filesApiEndpoint + "api/importInvoices/search/findByClearingFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].importInvoices; }));
        }
        else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
            return this.http.get(this.config.filesApiEndpoint + "api/exportInvoices/search/findByExportFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].exportInvoices; }));
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/exBondInvoices/search/findByExBondFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].exBondInvoices; }));
        }
    };
    InvoiceService.prototype.generateInvoiceReports = function (invoiceNumber, from, to) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/invoices?invoiceNumber=" + invoiceNumber + "&from=" + from + "&to=" + to);
    };
    InvoiceService.prototype.generateInvoiceRegisterReport = function (from, to) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/invoiceRegister?from=" + from + "&to=" + to);
    };
    InvoiceService.prototype.generateInvoiceCsv = function (from, to) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/invoicesCsv?from=" + from + "&to=" + to);
    };
    InvoiceService.prototype.findAllInvoicesStartingWith = function (invoiceNo, indicator) {
        if (indicator === 'imports') {
            return this.http
                .get(this.config.filesApiEndpoint + "api/importInvoices/search/findByInvoiceNumberStartsWith?invoiceNo=" + invoiceNo + "&projection=flattenedInvoice")
                .pipe(map(function (response) { return response['_embedded'].importInvoices; }));
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportInvoices/search/findByInvoiceNumberStartsWith?invoiceNo=" + invoiceNo + "&projection=flattenedInvoice")
            .pipe(map(function (response) { return response['_embedded'].exportInvoices; }));
    };
    InvoiceService.prototype.findClearingFileByInvoiceSelfRefLink = function (invoice) {
        return this.http
            .get(invoice._links.clearingFile.href.replace('\{\?projection\}', ''));
    };
    InvoiceService.ngInjectableDef = i0.defineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: InvoiceService, providedIn: "root" });
    return InvoiceService;
}());
export { InvoiceService };
