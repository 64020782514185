import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransportMethodsService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findTransportMethods(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/transportMethods`).pipe(
      map(response => response['_embedded'].transportMethods.map(method => {
        const transportMethod = {
          code: String,
          method: String,
          description: String
        };
        transportMethod.code = method.boetransportCode;
        transportMethod.method = method.transportMethod;
        transportMethod.description = method.transportMethodDesc;
        return transportMethod;
      }))
    );
  }
}
