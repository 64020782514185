<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="selectedContainer.terminal" field="label"
                                  [suggestions]="filteredDepotTerminals"
                                  (completeMethod)="search($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true"
                                  [forceSelection]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                <label>Terminal</label>
              </span>
              <span class="ui-inputgroup-addon" (click)="clearAll()"><i class="fa fa-times"></i></span>
            </div>
              <div *ngIf="!selectedContainer?.depot?.code && !selectedContainer?.terminal?.code" class="ui-message ui-messages-error ui-corner-all">
                 This field is required
              </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
