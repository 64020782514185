<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-left" *ngIf="showClearingFileSection">
    <div class="ui-inputgroup">
      <digi-clearing-file-search [transportMethod]="transportMethod" [isQuotation]="clearingFile.quotation"></digi-clearing-file-search>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="canDisable && (isSaveDisabled() || disableButtons)" (click)="saveClearingFile()"></button>
    <button *ngIf="canAdd" pButton type="button" label="New" icon="fa fa-file" (click)="addAction()"
            class="ui-button-secondary" [disabled]="disableButtons"></button>
    <button pButton type="button" *ngIf="canCreateVoC && hasClearingFile() && hasMrn()" label="Create VOC" icon="fa fa-plus"
            (click)="createVoc()"></button>
    <button pButton type="button" *ngIf="hasClearingFile() && clearingFile.quotation && hasBoe()" label="Create File from Quote" icon="fa fa-plus"
            (click)="createFileFromQuote()"></button>
  </div>
</p-toolbar>
