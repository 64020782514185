import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OceanlineService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllOceanLineStartsWith(searchValue) {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/oceanlines/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].oceanlines)
      );
  }
}



