import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BusinessEntity, RebateUser, Creditor, Currency, ExchangeRateType, TaxType } from '../../models/business-entity';
import { CurrencyService } from 'src/app/digi-common/services/currency.service';
import { TaxTypeService } from 'src/app/digi-common/services/tax-type.service';

@Component({
  selector: 'app-creditor-role',
  templateUrl: './creditor-role.component.html',
  styleUrls: ['./creditor-role.component.scss']
})
export class CreditorRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() creditor: Creditor;
  exchangeRateTypes = [];
  filteredExhangeRateTypes = [];
  filteredTaxTypes = [];
  selectedCurrency: Currency;

  constructor(private currencyService: CurrencyService,
    private taxTypeService: TaxTypeService) { }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
    this.currencyService.getExchangeRateTypes()
      .subscribe(data => {
        this.exchangeRateTypes = data._embedded.exchangeRatesTypes;
      });
  }


  ngOnChanges() {
    this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if(!this.creditor.externalCreditorCode){
      this.creditor.externalCreditorCode = this.businessEntity.code;
    }
  }

  searchExchangeRateType(event) {
    this.filteredExhangeRateTypes = [];
    for (let i = 0; i < this.exchangeRateTypes.length; i++) {
      let exchange = this.exchangeRateTypes[i];
      if (exchange.type.toLowerCase().indexOf(event.query.toLowerCase()) === 0 || exchange.description.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredExhangeRateTypes.push(exchange);
      }
    }
  }

  selectExchangeRateType(value: ExchangeRateType) {
    this.creditor.exchangeRateType = value;
  }

  selectCurrency(value: Currency) {
    this.creditor.currency = value;
  }

  searchTaxTypeCode(event) {
    this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(data => {
      this.filteredTaxTypes = data;
    });
  }

  selectedTaxType(value: TaxType) {
    this.creditor.defaultTaxType = value;
  }

}
