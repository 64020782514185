import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TariffDescriptionHistory, TariffDescriptionHistoryPK } from '../app/digi-common/models/TariffCode';

@Injectable({
  providedIn: 'root'
})
export class TariffDescriptionHistoryBehaviourSubject {
  private tariffDescriptionSubject = new BehaviorSubject<TariffDescriptionHistory>(this.buildTariffDescriptionHistory());

  constructor() {
  }

  getTariffDescriptionHistory(): Observable<TariffDescriptionHistory> {
    return this.tariffDescriptionSubject.asObservable();
  }

  addTariffDescriptionHistory(tariffDescriptionHistory: TariffDescriptionHistory) {
    this.tariffDescriptionSubject.next(tariffDescriptionHistory);
  }

  buildTariffDescriptionHistory() {
    const description = new TariffDescriptionHistory();
    description.id = new TariffDescriptionHistoryPK();
    return description;
  }
}
