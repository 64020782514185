import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {ExportTransportDocument, TransportDocument} from '../app/digi-common/models/file';
import {ForwardingTransportDocument} from '../app/digi-common/models/ForwardingFile';

@Injectable({
  providedIn: 'root'
})
export class TransportDocumentBehaviourSubject {
  private transportDocumentSubject = new BehaviorSubject<TransportDocument|ExportTransportDocument|ForwardingTransportDocument>(null);

  constructor() {
  }

  addTransportDocument(transportDocument: TransportDocument|ExportTransportDocument|ForwardingTransportDocument) {
    this.transportDocumentSubject.next(transportDocument);
  }

  getTransportDocuments(): Observable<TransportDocument|ExportTransportDocument|ForwardingTransportDocument> {
    return this.transportDocumentSubject.asObservable();
  }
}
