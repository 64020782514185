<div class="ui-g form-group">
  <ng-content></ng-content>
  <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="importer"
                            [(ngModel)]="businessEntity"
                            field="label"
                            [dropdown]="isDropdown"
                            [suggestions]="filteredBusinessEntities"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            (onSelect)="select($event)"
                            minLength="3"
                            required=true
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}: {{option.name}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="importer">{{ roleName }}</label>
            </span>
      <span *ngIf="!isDropdown" class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="requiresValidation && businessEntity && !businessEntity.name" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
  </div>
  <div *ngIf="businessEntity && businessEntity.customsWarehouseNumber" class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="md-inputfield">
                          <input type="text" pInputText
                                 disabled
                                 [ngModel]="businessEntity.customsWarehouseNumber">
                            <label>Customs Warehouse Number</label>
                        </span>
    </div>
    </span>
  </div>
  <div *ngIf="businessEntity && (roleID===8 || roleID===9)" class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="md-inputfield">
                          <input type="text" pInputText
                                 (ngModelChange)="updateCustomsCode($event)"
                                 [ngModel]="businessEntity.customsCode">
                            <label>Customs Code</label>
                        </span>
    </div>
    </span>
  </div>
</div>
<digi-address *ngIf="showAddress" [addresses]="addresses" [address]="businessEntity?.address"></digi-address>
<div class="ui-g form-group">
  <div *ngIf="canAdd" class="ui-md-12">
    <div class="ui-inputgroup">
      <button pButton
              type="button"
              icon="fa fa-file"
              class="ui-button-secondary"
              [label]="'Add New ' +roleName" (click)="openModal()"></button>
    </div>
  </div>
  <div *ngIf="businessEntity && !businessEntity.code && businessEntity.name" class="ui-md-12">
    <div class="ui-inputgroup">
      <button pButton
              type="button" icon="fa fa-save"
              [label]="'Save ' +roleName" (click)="saveBusinessEntity()"></button>
    </div>
  </div>
</div>
<digi-business-entity-modal [isNomination]="isNomination" [roleID]="roleID"
                            [display]="displayModal"
                            [declarantCode]="declarantCode"
                            [declarantRoleId]="declarantRoleId"
                            (created)="onBusinessEntityCreated($event)"></digi-business-entity-modal>

