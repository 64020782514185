import {Component, OnInit} from '@angular/core';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {ClearingInstruction, DepotTerminal} from '../../models/file';
import {DepotTerminalService} from '../../services/depot-terminal.service';

@Component({
  selector: 'digi-app-degrouping',
  templateUrl: './depot.component.html',
  styleUrls: ['./depot.component.scss']
})
export class DepotComponent implements OnInit {
  filteredDepotTerminals: DepotTerminal[];
  selectedClearingInstruction: ClearingInstruction;

  constructor(private depotTerminalService: DepotTerminalService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject) {
  }

  ngOnInit() {
    this.clearingInstructionBehaviorSubject
      .getInstruction()
      .subscribe(c => {
        this.selectedClearingInstruction = c;
      });
  }

  search(event) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, 'D').subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  selectDepot(event) {
    this.selectedClearingInstruction.degroupingDepot = event;
  }

  clearAll() {
    this.selectedClearingInstruction.degroupingDepot = undefined;
  }
}
