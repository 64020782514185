import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {RoadManifest, CuscarType} from '../../../digi-common/models/roadManifest';
import {ManifestService} from '../../../digi-common/services/manifest.service';
import {NotificationService} from '../../../digi-common/services/notification.service';
import {SendEdiToSarsService} from '../../../digi-common/services/send-edi-to-sars.service';
import {EDIMessage} from '../../../digi-common/models/billOfEntries';
import {CuscarTypeService} from '../../../digi-common/services/cuscar-type.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-manifest-edi',
  templateUrl: './manifest-edi.component.html',
  styleUrls: ['./manifest-edi.component.scss']
})
export class ManifestEdiComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() roadManifest: RoadManifest;
  @Input() disableButton: boolean;
  pdfSrc: string;
  cols: any[];
  colsForCuscar: any[];
  cuscars: string[];
  colsForContrl: any[];
  colsForCusres: any[];
  contrl: EDIMessage;
  cusres: EDIMessage;
  timerId: any;
  @Input() view: string;
  filteredCuscarTypes: CuscarType[];
  display = false;
  generatedFiles: any = [];
  @Output() updateRoadManifest = new EventEmitter();

  manifestServiceSubscription: Subscription;
  sendToSarsServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;

  constructor(
    private manifestService: ManifestService,
    private notificationService: NotificationService,
    private sendEdiToSarsService: SendEdiToSarsService,
    private cuscarTypeService: CuscarTypeService
  ) { }

  ngOnInit() {
    this.cols = [
      {field: 'lrn', header: 'LRN', width: '45%'},
      {field: 'hawbNo', header: 'Waybill No.', width: '45%'}
    ];
    this.colsForContrl = [
      {header: 'Contrl message(s)'}
    ];

    this.colsForCuscar = [
      {header: 'Cuscar message(s)'}
    ];

    this.colsForCusres = [
      {header: 'Cusres message(s)'}
    ];
  }

  ngAfterViewInit() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.startRefreshPoller();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;

      if (this.roadManifest.contrl) {
        this.contrl = this.roadManifest.contrl;
      } else {
        this.contrl = new EDIMessage();
        this.contrl.messages = [];
      }

      if (this.roadManifest.cusres) {
        this.cusres = this.roadManifest.cusres;
      } else {
        this.cusres = new EDIMessage();
        this.cusres.messages = [];
      }

      if (this.roadManifest.cuscars) {
        this.cuscars = this.roadManifest.cuscars;
      } else {
        this.cuscars = [];
      }
      this.updateRoadManifest.emit(this.roadManifest);
    }
  }

  ngOnDestroy() {
    if (this.manifestServiceSubscription) {
      this.manifestServiceSubscription.unsubscribe();
    }

    if (this.sendToSarsServiceSubscription) {
      this.sendToSarsServiceSubscription.unsubscribe();
    }

    if (this.cuscarTypeServiceSubscription) {
      this.cuscarTypeServiceSubscription.unsubscribe();
    }
  }

  startRefreshPoller() {
    console.log('starting refresh poller...');
    // repeat with the interval of 5 seconds
    this.timerId = setInterval(() => this.refresh(), 5000);

    // after 60 seconds stop
    setTimeout(() => {
      clearInterval(this.timerId);
      console.log('stopped refresh poller after 64 seconds');
    }, 64000);
  }

  generateManifest() {
    if (this.roadManifest && this.roadManifest.id) {
      this.disableButton = true;
      this.manifestServiceSubscription = this.manifestService.generate(this.roadManifest.id).subscribe(
        data => {
          this.generatedFiles = data._embedded.supportingDocuments;
          this.display = true;
          this.disableButton = false;
        },
        _ => this.disableButton = false
      );
    }
  }

  sendManifestToSARS() {
    if (this.roadManifest.cuscarType  && this.roadManifest.cuscarType.type) {
      if (this.roadManifest && this.roadManifest.id) {
        this.disableButton = true;
        this.notificationService.successNotify('Sending Manifest...');
        this.sendToSarsServiceSubscription = this.sendEdiToSarsService
          .sendManifest(this.roadManifest)
          .subscribe(data => {
              this.notificationService.successNotify('Manifest sent');
              this.refresh();
              this.disableButton = false;
            },
            _ => this.disableButton = false);
      }
    } else {
      this.notificationService.errorNotify(
        'Validation',
        ['Please Select Cuscar Type'],
      );
    }
  }

  refresh() {
    setTimeout(() => {
      this.retrieveRoadManifestAfterEDI();
    }, 2000);
  }

  retrieveRoadManifestAfterEDI() {
    this.manifestServiceSubscription = this.manifestService.findByManifestFileNoAndSequence(this.roadManifest.manifestFileNo, this.roadManifest.sequence)
      .subscribe(data => {
          this.roadManifest = data[0];

          this.cuscars = this.roadManifest.cuscars ? [...this.roadManifest.cuscars.reverse()] : [];

          if (this.roadManifest.contrl) {
            this.roadManifest.contrl.messages.reverse();
            this.contrl = Object.assign({}, this.roadManifest.contrl);
          } else {
            this.contrl = new EDIMessage();
            this.contrl.messages = [];
          }

          if (this.roadManifest.cusres) {
            this.roadManifest.cusres.messages.reverse();
            this.cusres = Object.assign(this.roadManifest.cusres);
          } else {
            this.cusres = new EDIMessage();
            this.cusres.messages = [];
          }
          this.updateRoadManifest.emit(this.roadManifest);
        },
        _ => this.disableButton = false
      );
  }

  hasCarn() {
    return !!(this.roadManifest && this.roadManifest.carn);
  }

  hasSuccessfulResponseOrIsAwaitingResponse() {
      if (this.roadManifest) {
          if (this.roadManifest.cusres && this.roadManifest.cusres.customsStatus !== 'REJECT_TO_CLEARER') {
            return true;
          } else if (this.roadManifest.cusres && this.roadManifest.cusres.customsStatus === 'REJECT_TO_CLEARER') {
            return false;
          } else if (this.roadManifest.contrl && this.roadManifest.contrl.interchangeStatus !== 'REJECTED' && !this.roadManifest.cusres) {
            return true;
          } else if (this.roadManifest.dateEdiSubmitted && (!this.roadManifest.contrl || !this.roadManifest.cusres && this.roadManifest.contrl.interchangeStatus !== 'REJECTED')){
            return true;
          } else {
            return false;
          }
      } else{
        return false;
      }
    }

  searchCuscarType(event: any) {
    this.cuscarTypeServiceSubscription = this.cuscarTypeService.findByTypeOrDescription(event.query).subscribe(
      data => this.filteredCuscarTypes = data
    );
  }

  selectCuscarType(value: CuscarType) {
    this.roadManifest.cuscarType = value;
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }
}
