import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { PurchaseTerm } from '../../models/file';
import { PurchaseTermService } from '../../services/purchase-term.service';

@Component({
  selector: 'digi-purchase-terms',
  templateUrl: './purchase-terms.component.html',
  styleUrls: ['./purchase-terms.component.scss']
})
export class PurchaseTermsComponent implements OnInit, OnChanges {
  @Input('purchaseTerm') purchaseTerm: PurchaseTerm;
  @Input() isQuotation: boolean;
  selectedPurchaseTerm: PurchaseTerm;
  filteredPurchaseTerms = [];

  constructor(
    private purchaseTermService: PurchaseTermService
  ) {
  }

  ngOnInit() {
    this.selectedPurchaseTerm = this.purchaseTerm;
  }

  ngOnChanges() {
//     if (this.isQuotation && !this.purchaseTerm.code && this.selectedPurchaseTerm) {
//       this.purchaseTerm = this.selectedPurchaseTerm;
//     } else {
//       this.selectedPurchaseTerm = this.purchaseTerm;
//     }
    this.selectedPurchaseTerm = this.purchaseTerm;
  }

  search(event) {
    this.purchaseTermService.findPurchaseTermsStartsWith(event.query).subscribe(data => {
      this.filteredPurchaseTerms = data;
    });
  }

  select(value) {
    this.purchaseTerm.code = value.code;
    this.purchaseTerm.description = value.description;
    this.purchaseTerm.label = value.label;
  }
}


