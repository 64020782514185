<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Register" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-exbond-file-section step="registration" (add)="createNewFile()" (displayVocSequenceModal)="openSequenceModal($event)"
                                    [canAdd]="true" [canCreateVoC]="true">
          </digi-exbond-file-section>
        </div>
        <digi-correction fileType="exbond" *ngIf="isCorrection()"></digi-correction>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Importer Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exBondFile.importer"
                                                   [roleName]="'Importer'"
                                                   [isNomination]="false"
                                                   [roleID]="6"
                                                   [showAddress]="true"
                                                   (selectedBusinessEntity)="selectImporter($event)"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-panel header="Debtor Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exBondFile.debtor"
                                                   [showAddress]="true"
                                                   [roleName]="'Debtor'"
                                                   [roleID]="1"
                                                   [isDropdown]="true"
                                                   [businessEntities]="filteredDebtors"
                                                   (selectedBusinessEntity)="selectDebtor($event)"
                                                   [requiresValidation]="false"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Clearing Agent Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exBondFile.localAgent"
                                                   [roleName]="'Clearing Agent'"
                                                   [roleID]="4"
                                                   [showAddress]="true"
                                                   [isNomination]="false"
                                                   (selectedBusinessEntity)="selectLocalAgent($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-4">
              <p-panel header="Warehouse Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exBondFile.warehouse"
                                                   [showAddress]="true"
                                                   [roleName]="'Warehouse'"
                                                   [roleID]="13"
                                                   [isDropdown]="true"
                                                   [businessEntities]="filteredWarehouses"
                                                   (selectedBusinessEntity)="selectWarehouse($event)"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g-12 ui-md-12">
                <p-panel header="To Customs Office">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <digi-customs-office [customOffice]="exBondFile.customOffice"
                                                 [isRequired]=true (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
                <br>
                <p-panel header="Assessment & SLA Date / Promised Date">
                  <div class="ui-g ui-fluid">
                    <div class="ui-g-12 ui-md-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                          <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="Assessment Date"
                                       [(ngModel)]="exBondFile.assessmentDate"></p-inputMask>
                          <label>Assessment Date</label>
                        </span>
                    </div>
                    <div class="ui-g-12 ui-md-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="SLA Date/Promised Date "
                                     [(ngModel)]="exBondFile.slaDate"></p-inputMask>
                        <label>SLA Date/Promised Date</label>
                      </span>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g">
                <div class="ui-g-12 ui-g-nopad">
                  <digi-registration-references-section
                    [file]="exBondFile"></digi-registration-references-section>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-12">
              <p-pickList [source]="availableInstructions" [target]="selectedInstructions"
                          sourceHeader="Available Instruction" targetHeader="Selected Instruction" [responsive]="true"
                          [showSourceControls]="false" [showTargetControls]="false"
                          (onMoveToTarget)="selectClearingOption($event)"
                          (onMoveToSource)="onMoveToSource($event)"
                          [dragdrop]="true" dragdropScope="instructions"
                          (onTargetSelect)="selectedClearingInstruction($event)"
              >
                <ng-template let-instruction pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <div>
                      {{instruction.customsPurpose.customsPurposeCode}} :
                      {{instruction.customsPurpose.customsPurposeName}}
                    </div>
                  </div>
                </ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<digi-clearing-instruction-modal [display]="display"
                                 (displayChange)="onDialogClose($event)"
                                 (onCloseCustomProcedureModal)="onCloseCustomProcedureModal($event)"
                                 [customsProcedures]="customsProcedures"
                                 [showCustomsProcedures]="showCustomsProcedures"
                                 [fileType]="'imports'">
</digi-clearing-instruction-modal>

<digi-sequence-modal
  fileType="exbond"
  [display]="openSequence"
  (displayChange)="openSequence=$event">
</digi-sequence-modal>
