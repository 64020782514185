import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CreditNoteService = /** @class */ (function () {
    function CreditNoteService(http, config) {
        this.http = http;
        this.config = config;
    }
    CreditNoteService.prototype.findReasonsByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + ("api/creditNoteReasons/search/findByCodeStartsWith?code=" + code)).pipe(map(function (response) { return response['_embedded'].creditNoteReasons; }));
    };
    CreditNoteService.prototype.saveCreditNote = function (creditNote, file) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (file.type === 'ClearingFile' || file.type === 'Correction') {
            creditNote.clearingFile = file._links.self.href;
            creditNote.type = 'CreditNote';
        }
        else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
            creditNote.exportFile = file._links.self.href;
            creditNote.type = 'ExportCreditNote';
        }
        else {
            creditNote.exBondFile = file._links.self.href;
            creditNote.type = 'ExBondCreditNote';
        }
        if (creditNote._links && creditNote._links.self) {
            return this.http.put(creditNote._links.self.href, JSON.stringify(creditNote), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/creditNotes', JSON.stringify(creditNote), { headers: headers });
        }
    };
    CreditNoteService.prototype.getCreditNotes = function (file) {
        if (file.type === 'ClearingFile' || file.type === 'Correction') {
            return this.http.get(this.config.filesApiEndpoint + "api/importCreditNotes/search/findByClearingFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].importCreditNotes; }));
        }
        else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
            return this.http.get(this.config.filesApiEndpoint + "api/exportCreditNotes/search/findByExportFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].exportCreditNotes; }));
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/exBondCreditNotes/search/findByExBondFile_Id?id=" + file.id)
                .pipe(map(function (response) { return response['_embedded'].exBondCreditNotes; }));
        }
    };
    CreditNoteService.ngInjectableDef = i0.defineInjectable({ factory: function CreditNoteService_Factory() { return new CreditNoteService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CreditNoteService, providedIn: "root" });
    return CreditNoteService;
}());
export { CreditNoteService };
