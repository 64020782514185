import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var InvoiceSectionService = /** @class */ (function () {
    function InvoiceSectionService(http, config) {
        this.http = http;
        this.config = config;
    }
    InvoiceSectionService.prototype.findInvoiceSection = function (value) {
        return this.http
            .get(this.config.apiEndpoint + "api/invoiceSections/search/descStartsWith?desc=" + value)
            .pipe(map(function (response) { return response['_embedded'].invoiceSections; }));
    };
    InvoiceSectionService.prototype.findInvoiceSubSectionsByInvoiceSectionId = function (id, value) {
        return this.http
            .get(this.config.apiEndpoint + "api/invoiceSubSections/search/findByInvoiceSectionIDAndDesc?invoiceSectionID=" + id + "&invoiceSubSectionDesc=" + value + "&projection=InvoiceSubSectionDescription")
            .pipe(map(function (response) { return response['_embedded'].invoiceSubSections; }));
    };
    InvoiceSectionService.ngInjectableDef = i0.defineInjectable({ factory: function InvoiceSectionService_Factory() { return new InvoiceSectionService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: InvoiceSectionService, providedIn: "root" });
    return InvoiceSectionService;
}());
export { InvoiceSectionService };
