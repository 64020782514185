/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tariff-maintenance-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tariff-maintenance-table-description/tariff-maintenance-table-description.component.ngfactory";
import * as i3 from "../tariff-maintenance-table-description/tariff-maintenance-table-description.component";
import * as i4 from "../../../../subjects/tariffCode-behavior-subject";
import * as i5 from "../../../../subjects/tariffDescriptionHistory-behaviour-subject";
import * as i6 from "../../services/tariff.service";
import * as i7 from "../../services/notification.service";
import * as i8 from "./tariff-maintenance-description.component";
var styles_TariffMaintenanceDescriptionComponent = [i0.styles];
var RenderType_TariffMaintenanceDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TariffMaintenanceDescriptionComponent, data: {} });
export { RenderType_TariffMaintenanceDescriptionComponent as RenderType_TariffMaintenanceDescriptionComponent };
export function View_TariffMaintenanceDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui-g-12 ui-g-nopad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-12 pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "digi-tariff-maintenance-table-description", [], null, null, null, i2.View_TariffMaintenanceTableDescriptionComponent_0, i2.RenderType_TariffMaintenanceTableDescriptionComponent)), i1.ɵdid(3, 245760, null, 0, i3.TariffMaintenanceTableDescriptionComponent, [i4.TariffCodeBehaviorSubject, i5.TariffDescriptionHistoryBehaviourSubject, i6.TariffService, i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_TariffMaintenanceDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-tariff-maintenance-description", [], null, null, null, View_TariffMaintenanceDescriptionComponent_0, RenderType_TariffMaintenanceDescriptionComponent)), i1.ɵdid(1, 114688, null, 0, i8.TariffMaintenanceDescriptionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TariffMaintenanceDescriptionComponentNgFactory = i1.ɵccf("digi-tariff-maintenance-description", i8.TariffMaintenanceDescriptionComponent, View_TariffMaintenanceDescriptionComponent_Host_0, {}, {}, []);
export { TariffMaintenanceDescriptionComponentNgFactory as TariffMaintenanceDescriptionComponentNgFactory };
