import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  findByCode(code: string): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/industries/search/findByCode?code=${code}`)
      .pipe(
        map(response => response['_embedded'].industries)
      );
  }
}
