<div class="container-fluid test-inner-border">
  <digi-boe-file-section [fileType]="fileType"></digi-boe-file-section>
  <div class="ui-fluid">
    <div class="ui-g">
      <ng-container *ngIf="!currentBillOfEntry.markedAsSubmitted">
        <div class="ui-g-12">
          <button pButton type="button" label="Refresh" (click)="refresh()" class="col-sm-2"></button>
        </div>

        <div class="ui-g-12">
          <div class="ui-g form-group">
            <div class="ui-g-12" [ngClass]="{'ui-md-3':isProceedToBorderStatus(), 'ui-md-4': !isProceedToBorderStatus()}">
            <span class="md-inputfield">
              <input type="text" pInputText value="{{this.cusres?.lrn}}"
                     [disabled]="true" class="disabled-status">
              <label>LRN</label>
            </span>
            </div>
            <div class="ui-g-12" [ngClass]="{'ui-md-3':isProceedToBorderStatus(), 'ui-md-4': !isProceedToBorderStatus()}">
            <span class="md-inputfield">
              <input type="text" pInputText value="{{this.cusres?.mrn}}"
                     [disabled]="true" class="disabled-status">
              <label>MRN</label>
            </span>
            </div>
            <div class="ui-g-12" [ngClass]="{'ui-md-3':isProceedToBorderStatus(), 'ui-md-4': !isProceedToBorderStatus()}">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{cusres?.customsStatus === 'DETAIN_OTHER_OGA_OTHER_GOVERNMENT_AGENCY' || cusres?.customsStatus === 'CUSTOMS_STOP_AWAITING_OGA_OUTCOME' && cusres.customsStatusError ? cusres?.customsStatusError:cusres?.customsStatus}}" [disabled]="true" class="disabled-status">
              <label>STATUS</label>
            </span>
            </div>
            <div *ngIf="isProceedToBorderStatus()" class="ui-g-3 ui-md-3">
            <span class="md-inputfield">
              <button pButton type="button" label="Manifest" (click)="navigateToManifest()"></button>
            </span>
            </div>
            <div *ngIf="cusres?.caseNumber" class="ui-g-3 ui-md-3">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{cusres?.caseNumber}}" [disabled]="true" class="disabled-status">
              <label>CASE NUMBER</label>
            </span>
            </div>
          </div>
        </div>

        <div *ngIf="cusres.errorDetails && cusres.errorDetails.length>0" class="ui-g-12 ui-md-12">
          <p-table [columns]="colsForErrors" [value]="errors"
                   [rows]="5"
                   pColumnHeaders="ERRORS">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                  {{rowData[col.field]}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="ui-g-12">
          <p-table [columns]="colsForCusdec" [value]="cusdecs"
                   dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="1"
                   pColumnHeaders="CUSRES">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 3em"></th>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                         let-expanded="expanded" let-columns="columns">
              <tr>
                <td>
                  <a href="#" [pRowToggler]="rowData">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                  </a>
                </td>
                <td *ngFor="let col of columns">
                  {{rowData}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData
                         let-rowIndex="rowIndex" let-columns="columns">
              <tr>
                <td [attr.colspan]="columns.length + 1">
                  <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                    <div class="ui-g-12 ui-md-9">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <pre>{{rowData}}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <p-message *ngIf="controlFailed" severity="error" text="The Contrl message has been rejected."></p-message>
        <div class="ui-g-12">
          <p-table [columns]="colsForContrl" [value]="contrl.messages"
                   dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="4"
                   pColumnHeaders="CUSRES">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 3em"></th>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                         let-expanded="expanded" let-columns="columns">
              <tr>
                <td>
                  <a href="#" [pRowToggler]="rowData">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                  </a>
                </td>
                <td *ngFor="let col of columns">
                  {{rowData}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData
                         let-rowIndex="rowIndex" let-columns="columns">
              <tr>
                <td [attr.colspan]="columns.length + 1">
                  <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                    <div class="ui-g-12 ui-md-9">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <pre>{{rowData}}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="ui-g-12">
          <p-table [columns]="colsForCusres" [value]="cusres.messages"
                   dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="4"
                   pColumnHeaders="CUSRES">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 3em"></th>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                         let-expanded="expanded" let-columns="columns">
              <tr>
                <td>
                  <a href="#" [pRowToggler]="rowData">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                  </a>
                </td>
                <td *ngFor="let col of columns">
                  {{rowData}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData
                         let-rowIndex="rowIndex" let-columns="columns">
              <tr>
                <td [attr.colspan]="columns.length + 1">
                  <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                    <div class="ui-g-12 ui-md-9">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <pre>{{rowData}}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="ui-g">
                <div class="ui-g-6 ui-g-offset-3">
                  <button pButton type="button" label="Request CUSRES Retransmission" (click)="requestCusresRetransmission()"></button>
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
      <ng-container *ngIf="currentBillOfEntry.markedAsSubmitted">
        <div class="ui-g-4 ui-md-4">
          <p-panel header="Bill of Entry Details">
            <div class="ui-fluid">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                     class="ui-inputgroup"
                     pInputText
                     [(ngModel)]="currentBillOfEntry.lrn">
                    <label>LRN</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.mrn">
                    <label>Customs BOE Number (MRN)</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="ui-float-label">
                    <p-dropdown [options]="statuses"
                                [(ngModel)]="currentBillOfEntry.status"
                                [autoWidth]="false">
                    </p-dropdown>
                      <label>Status</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="ui-float-label">
                    <p-dropdown [options]="releaseStatuses"
                                [(ngModel)]="currentBillOfEntry.releaseStatus"
                                [autoWidth]="false">
                    </p-dropdown>
                      <label>Release Status</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <span class="ui-float-label">
                      <span class="md-inputfield">
                        <p-inputMask id="mawbDate" mask="99/99/9999"
                                     slotChar="dd/mm/yyyy"
                                     [(ngModel)]="currentBillOfEntry.releaseDate">

                        </p-inputMask>
                        <label for="mawbDate">Customs BOE Date</label>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.serialNumber"
                           disabled>
                    <label>Serial No.</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.houseNumber"
                           disabled>
                    <label>In-house No.</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.caseNumber">
                    <label>Inspection Case No.</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <p-autoComplete [ngModel]="currentBillOfEntry.paymentType"
                                  placeholder="Payment Code" field="label"
                                  [autoHighlight]="true" [dropdown]="true"
                                  [suggestions]="paymentTypes"
                                  (completeMethod)="searchPaymentTypes()"
                                  (onSelect)=selectPaymentType($event)>
                    <ng-template let-option pTemplate="item">
                      <div>{{option.paymentCode}}:{{option.paymentDesc}}</div>
                    </ng-template>
                  </p-autoComplete>

                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="ui-float-label">
                    <p-dropdown [options]="stopStatuses"
                                [(ngModel)]="currentBillOfEntry.stopStatus"
                                [autoWidth]="false">
                    </p-dropdown>
                      <label>Stop Status</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-4 ui-md-4">
          <p-panel header="Bill of Entry Totals">
            <div class="ui-fluid">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.cifcValue">
                    <label>CIF&C</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.totalCustomsValue">
                    <label>Customs Value</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.totalCustomsDuty">
                    <label>Schd 1.1 Duty</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12" *ngFor="let schedulePart of currentBillOfEntry.schedulePartTotals" >
                  <span class="md-inputfield" *ngIf="currentBillOfEntry.customsProcedureCode!==40">
                    <input type="text" [(ngModel)]="schedulePart.totalValue" pInputText value="{{schedulePart.totalValue}}" >
                    <label>Schd {{schedulePart.schedulePart.code}} Duty</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.totalCustomsVAT">
                    <label>Value Added Tax</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="currentBillOfEntry.totalAmountDue">
                    <label>Amount Due</label>
                  </span>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-4 ui-m-4">
          <button pButton type="button" label="Request CUSRES Retransmission" (click)="requestCusresRetransmission()"></button>
        </div>
      </ng-container>

    </div>
  </div>
</div>
