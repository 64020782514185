import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ShareDataService} from '../../digi-common/services/share-data.service';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-air-dashboard',
  templateUrl: './air-dashboard.component.html',
  styleUrls: ['./air-dashboard.component.css']
})
export class AirDashboardComponent implements OnInit {

  constructor(private router: Router,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile('AIR'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== '/air/register/') { //Todo : is this relevant for new quotations
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['air', 'register']);
    }
  }
}
