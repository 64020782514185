/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boe-line-original-boe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "@angular/forms";
import * as i4 from "./boe-line-original-boe.component";
var styles_BoeLineOriginalBoeComponent = [i0.styles];
var RenderType_BoeLineOriginalBoeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoeLineOriginalBoeComponent, data: {} });
export { RenderType_BoeLineOriginalBoeComponent as RenderType_BoeLineOriginalBoeComponent };
export function View_BoeLineOriginalBoeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "ui-g ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"], ["value", "N/A"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customs BOE No. (MRN)"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"], ["value", "0"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Line No"])), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"], ["value", "**/**/***"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date"])), (_l()(), i1.ɵeld(23, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"], ["value", "00"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Purpose Code / CPC"]))], function (_ck, _v) { _ck(_v, 6, 0); _ck(_v, 13, 0); _ck(_v, 20, 0); _ck(_v, 27, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 6).filled; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; var currVal_8 = true; var currVal_9 = i1.ɵnov(_v, 13).filled; _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = true; var currVal_11 = true; var currVal_12 = true; var currVal_13 = true; var currVal_14 = i1.ɵnov(_v, 20).filled; _ck(_v, 19, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = true; var currVal_16 = true; var currVal_17 = true; var currVal_18 = true; var currVal_19 = i1.ɵnov(_v, 27).filled; _ck(_v, 26, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }); }
export function View_BoeLineOriginalBoeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-boe-line-original-boe", [], null, null, null, View_BoeLineOriginalBoeComponent_0, RenderType_BoeLineOriginalBoeComponent)), i1.ɵdid(1, 114688, null, 0, i4.BoeLineOriginalBoeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoeLineOriginalBoeComponentNgFactory = i1.ɵccf("digi-boe-line-original-boe", i4.BoeLineOriginalBoeComponent, View_BoeLineOriginalBoeComponent_Host_0, {}, {}, []);
export { BoeLineOriginalBoeComponentNgFactory as BoeLineOriginalBoeComponentNgFactory };
