import { BehaviorSubject } from 'rxjs';
import { TariffCodeReference, TariffFormula, TariffFormulaPK, } from '../app/digi-common/models/TariffCode';
import { SchedulePart } from '../app/digi-common/models/file';
import * as i0 from "@angular/core";
var TariffCodeBehaviorSubject = /** @class */ (function () {
    function TariffCodeBehaviorSubject() {
        this.tariffCodeSubject = new BehaviorSubject(this.buildTariffCode());
    }
    TariffCodeBehaviorSubject.prototype.getTariffCode = function () {
        return this.tariffCodeSubject.asObservable();
    };
    TariffCodeBehaviorSubject.prototype.addTariffCode = function (tariffCode) {
        this.tariffCodeSubject.next(tariffCode);
    };
    TariffCodeBehaviorSubject.prototype.buildTariffCode = function () {
        var tariff = new TariffCodeReference();
        tariff.schedulePart = new SchedulePart();
        tariff.formulae = new Array();
        tariff.formulae[0] = new TariffFormula();
        tariff.formulae[0].id = new TariffFormulaPK();
        tariff.descriptions = new Array();
        tariff.formulae = new Array();
        return tariff;
    };
    TariffCodeBehaviorSubject.ngInjectableDef = i0.defineInjectable({ factory: function TariffCodeBehaviorSubject_Factory() { return new TariffCodeBehaviorSubject(); }, token: TariffCodeBehaviorSubject, providedIn: "root" });
    return TariffCodeBehaviorSubject;
}());
export { TariffCodeBehaviorSubject };
