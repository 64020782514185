import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DepotTerminal, TransportDocument } from '../../../models/file';
import { DepotTerminalService } from '../../../services/depot-terminal.service';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';


@Component({
  selector: 'digi-degroup-search',
  templateUrl: './degroup-search.component.html',
  styleUrls: ['./degroup-search.component.scss']
})
export class DegroupSearchComponent implements OnInit, OnDestroy {
  transportDocument: TransportDocument;
  subscription: Subscription;
  @Input() label = 'Degroup/Transit Depot';
  @Input() depotTerminal = 'D';
  @Input() isQuotation: boolean;
  filteredDepotTerminals: DepotTerminal[];

  constructor(private depotTerminalService: DepotTerminalService,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject) {
  }

  ngOnInit() {
    this.subscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => {
        if (td) {
          this.transportDocument = td;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search(event) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, this.depotTerminal).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }
}
