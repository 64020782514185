var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { ForwardingFileBehaviourSubject } from '../../../../subjects/forwarding-file-behaviour-subject';
import { ForwardingFileValidationService } from '../../services/forwarding-file-validation.service';
import { ForwardingFileService } from '../../services/forwarding-file.service';
var ForwardingStepsNavigationComponent = /** @class */ (function () {
    function ForwardingStepsNavigationComponent(router, forwardingFileBehaviourSubject, messageService, forwardingFileValidationService, forwardingFileService) {
        this.router = router;
        this.forwardingFileBehaviourSubject = forwardingFileBehaviourSubject;
        this.messageService = messageService;
        this.forwardingFileValidationService = forwardingFileValidationService;
        this.forwardingFileService = forwardingFileService;
        this.activeIndex = 0;
    }
    ForwardingStepsNavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.forwardingFileSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
            .subscribe(function (file) { return _this.forwardingFile = file; });
        this.items = [
            {
                label: 'Manifests',
                command: function (event) {
                    _this.activeIndex = 0;
                    _this.router.navigate(["/forwarding/" + _this.transportMethod + "/manifests"]);
                },
                icon: 'fa fa-user-plus'
            },
            {
                label: 'EDI',
                command: function (event) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    var validationResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.forwardingFileValidationService.validateManifests(this.forwardingFile, this.transportMethod)];
                            case 1:
                                validationResult = _a.sent();
                                if (validationResult.isValid) {
                                    this.flag = true;
                                    this.activeIndex = 1;
                                    this.forwardingFileService.save(this.forwardingFile).subscribe(function (_) {
                                        _this.router.navigate(["/forwarding/" + _this.transportMethod + "/edi"]);
                                        _this.saveConfirmation();
                                    });
                                }
                                else {
                                    this.activeIndex = 0;
                                    this.showMessages(validationResult.messages);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
                icon: 'fa fa-user-plus'
            }
        ];
    };
    ForwardingStepsNavigationComponent.prototype.ngOnDestroy = function () {
        this.forwardingFileSubscription.unsubscribe();
    };
    ForwardingStepsNavigationComponent.prototype.saveConfirmation = function () {
        this.messageService.successNotify('File Saved Successfully');
    };
    ForwardingStepsNavigationComponent.prototype.showMessages = function (messages) {
        this.messageService.errorNotify('Validation Failed', messages);
        this.flag = true;
    };
    return ForwardingStepsNavigationComponent;
}());
export { ForwardingStepsNavigationComponent };
