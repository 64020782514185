import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {ForwardingFileBehaviourSubject} from '../../subjects/forwarding-file-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class ForwardingFileExistsGuard implements CanActivate {
  constructor(
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.forwardingFileBehaviourSubject.getForwardingFile().pipe(
      take(1),
      switchMap(file => {
        if (file.id) {
          return of(true);
        } else {
          const parent = state.root.firstChild.routeConfig.path;
          this.router.navigate([parent, 'manifests']);
          return of(false);
        }
      })
    );
  }
}
