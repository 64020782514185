import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'tariffCode'
})
export class TariffCodePipe implements PipeTransform {

  transform(value: string): any {
    if (value) {
      return value.replace(/\B(?=(\d{2})+(?!\d))/g, '.').concat('.');
    } else {
      return '';
    }
  }
}

