<div class="ui-g-12 ui-md-12">
  <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
            <p-autoComplete id="country" [(ngModel)]="selectedCountry"
                            field="label"
                            [dropdown]="true"
                            [suggestions]="filteredCountries"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true" (onSelect)="select($event)" minLength="1"
                            required="true">
              <ng-template let-option pTemplate="item">
                <div>{{option.label}}</div>
              </ng-template>
            </p-autoComplete>
        <label for="country">{{label}}</label>
      </span>
    </div>
    <div *ngIf="!isQuotation && !selectedCountry?.code" class="ui-message ui-messages-error ui-corner-all">
          This field is required
      </div>
  </span>
</div>
