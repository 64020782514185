import { BehaviorSubject } from 'rxjs';
import { Container } from '../app/digi-common/models/file';
import * as i0 from "@angular/core";
var ContainerBehaviorSubject = /** @class */ (function () {
    function ContainerBehaviorSubject() {
        this.containersSubject = new BehaviorSubject(null);
    }
    ContainerBehaviorSubject.prototype.getContainer = function () {
        return this.containersSubject.asObservable();
    };
    ContainerBehaviorSubject.prototype.addContainer = function (container) {
        this.containersSubject.next(container);
    };
    ContainerBehaviorSubject.ngInjectableDef = i0.defineInjectable({ factory: function ContainerBehaviorSubject_Factory() { return new ContainerBehaviorSubject(); }, token: ContainerBehaviorSubject, providedIn: "root" });
    return ContainerBehaviorSubject;
}());
export { ContainerBehaviorSubject };
