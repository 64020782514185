import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'permitNumbers'
})
export class PermitNumbersPipe implements PipeTransform {
  transform(lines: any): string {
    if (lines) {
      let numbers = lines[0].toString();
      let temp = '';
      for (let i = 1; i < lines.length; i++) {
        if (lines[i] === lines[i - 1] + 1) {
          temp = lines[i];
          if (lines.length === i + 1) {
            numbers += '-' + temp;
          }
        } else {
          numbers += '-' + temp;
          numbers += ',' + lines[i].toString();
        }
      }
      return numbers;
    } else {
      return '';
    }
  }
}
