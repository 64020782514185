import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TariffRefService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findByCode(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/tariffRefs/search/findByReferenceCode?ReferenceCode=` + code);
  }

  findByReferenceCodeStartWith(code): Observable<any> {

    if (code === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/tariffRefs/search/findByReferenceCodeStartWith?referenceCode=`
        + code)
      .pipe(
        map(response => response['_embedded'].tariffRefs)
      );
  }

  findAll(): Observable<any> {
    return this.http
      .get(`${this.config.apiEndpoint}api/formulae/search/findByFormulaNoNonPageable`)
      .pipe(
        map(response => response['_embedded'].formulae)
      );
  }
}
