import {ClearingFile} from '../models/clearingFile';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ShareDataService} from './share-data.service';
import {DebtorService} from './debtor.service';
import {TransportMethodsService} from './transport-methods.service';
import {ClearingInstructionsService} from './clearing-instructions.service';
import {RebateUserService} from './rebate-user.service';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {AdditionalInfoBehaviourSubject} from '../../../subjects/addittional-info-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../subjects/container-behavior-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {BillOfEntryService} from './bill-of-entry.service';
import {UniqueConsignmentBehaviourSubject} from '../../../subjects/unique-consignment-reference-subject';
import {PartDetail} from '../models/partDetail';
import {ForwardingTransportDocument} from '../models/ForwardingFile';
import pako from 'pako';
import {Invoice} from '../models/financials';

@Injectable({
  providedIn: 'root'
})
export class ClearingFileService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private shareDataService: ShareDataService,
              private debtorService: DebtorService,
              private transportMethodsService: TransportMethodsService,
              private clearingInstructionService: ClearingInstructionsService,
              private rebateUserService: RebateUserService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
              private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
              private containerBehaviorSubject: ContainerBehaviorSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private billOfEntryService: BillOfEntryService,
              private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject) {
  }

  sendClearingFile(clearingFile: ClearingFile): Observable<any> {
    let headers: HttpHeaders;
    let body: any;
    if (this.config.zipRequests) {
      headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Content-Encoding': 'gzip'
      });
      headers.append('Content-Encoding', 'gzip');
      headers.set('Content-Type', 'application/octet-stream');

      body = pako.gzip(JSON.stringify(clearingFile)).buffer;
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      body = JSON.stringify(clearingFile);
    }
    if (clearingFile._links && clearingFile._links.self) {
      return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id, body, {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/clearingFiles', body, {headers});
    }
  }

  loadClearingFile(clearingFileNumber: string, transportMethod: string, branch?: string, isQuotation?: boolean): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    if (branch) {
      if (isQuotation) {
        return this.http
          .get(`${this.config.filesApiEndpoint}api/clearingFiles/search/findByClearingQuotationFileNumberOrReferenceFileNoAndBranchCode?number=${clearingFileNumber}&branchCode=${branch}&transportMethod=${transportMethod}`)
          .pipe(
            map(response => response['_embedded'].clearingFiles)
          );
      } else {
        return this.http
          .get(`${this.config.filesApiEndpoint}api/clearingFiles/search/findByClearingFileNumberOrReferenceFileNoAndBranchCode?number=${clearingFileNumber}&branchCode=${branch}&transportMethod=${transportMethod}`)
          .pipe(
            map(response => response['_embedded'].clearingFiles)
          );
      }
    } else {
      if (isQuotation) {
        return this.http
          .get(`${this.config.filesApiEndpoint}api/clearingFiles/search/clearingQuotationFileNumberOrReferenceFileNoStartsWith?number=${clearingFileNumber}&transportMethod=${transportMethod}`)
          .pipe(
            map(response => response['_embedded'].clearingFiles)
          );
      } else {
        return this.http
          .get(`${this.config.filesApiEndpoint}api/clearingFiles/search/clearingFileNumberOrReferenceFileNoStartsWith?number=${clearingFileNumber}&transportMethod=${transportMethod}`)
          .pipe(
            map(response => response['_embedded'].clearingFiles)
          );
      }
    }

  }

  loadAllCorrectionsIncludingOriginalClearingFile(clearingFileNumber: string, transportMethod: string): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/corrections/search/clearingFileNumberOrReferenceFileNoStartsWith?number=${clearingFileNumber}&transportMethod=${transportMethod}`)
      .pipe(
        map(response => response['_embedded'].corrections)
      );
  }

  findAllSequences(clearingFileNumber: String): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/corrections/search/findSequencesByClearingFileNumber?clearingFileNumber=`
        + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
      .pipe(
        map(response => response['_embedded'].corrections)
      );
  }

  findByClearingFileNumberAndSequence(clearingFileNumber: String, sequence: number): Observable<any> {
    if (clearingFileNumber === '' && sequence === null) {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/corrections/search/clearingFileNumberAndSequence?clearingFileNumber=` + clearingFileNumber
        + '&sequence=' + sequence)
      .pipe(
        map(response => response['_embedded'].corrections)
      );
  }

  createNewFile(transportMethod?: string, isQuotation?: boolean) {
    this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile(transportMethod, isQuotation));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.additionalInfoBehaviourSubject.addAdditionalInfo([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    const transportDocument = this.shareDataService.addTransportDocument('imports');
    this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
    const supplierInvoice = this.shareDataService.addSupplierInvoice(transportDocument);
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
    const supplierInvoiceLine = this.shareDataService.addSupplierInvoiceLine(supplierInvoice, undefined);
    this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
    this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);
    this.supplierInvoiceBehaviourSubject.addCountry(null);
    this.supplierInvoiceBehaviourSubject.addCurrency(null);
    this.supplierInvoiceLineBehaviourSubjectComponent.addCountry(null);
    this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
  }

  selectClearingFile(value) {
    this.createNewFile();
    this.clearingFileBehaviourSubject.addClearingFile(value);
    if (value.transportDocuments.length > 0) {
      this.transportDocumentBehaviourSubject.addTransportDocument(value.transportDocuments[0]);
      if (value.transportDocuments[0].containers.length > 0) {
        this.containerBehaviorSubject.addContainer(value.transportDocuments[0].containers[0]);
      }
      if (value.transportDocuments[0].supplierInvoices.length > 0) {
        this.supplierInvoiceBehaviourSubject.addSupplierInvoice(value.transportDocuments[0].supplierInvoices[0]);
        if (value.transportDocuments[0].supplierInvoices[0].lines.length > 0) {
          this.supplierInvoiceLineBehaviourSubjectComponent
            .addSupplierInvoiceLine(value.transportDocuments[0].supplierInvoices[0].lines[0]);
        }
      }
    }
    if (value.clearingInstructions.length) {
      this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
    }
    this.billOfEntryService
      .findBillOfEntryByClearingFile_id(value.id)
      .subscribe(boes => {
        if (boes.length > 0) {
          this.billOfEntryBehaviourSubject.addBillOfEntry(boes[0]);
          this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(boes[0].lines[0]);
          this.additionalInfoBehaviourSubject.addAdditionalInfo(boes[0].lines[0].additionalInformation);
          this.billOfEntryBehaviourSubject.addBillsOfEntry(boes);
        }
      });
  }

  findClearingFileNumbersByInvoiceNo(invoiceNumber: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/clearingFiles/search/findClearingFileNumbersByInvoiceNo?clearingFileNumber=${fileNumber}&invoiceNo=${invoiceNumber}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  findClearingFileNumbersByHawbNo(hawbNo: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/clearingFiles/search/findClearingFileNumbersByHawbNo?clearingFileNumber=${fileNumber}&hawbNo=${hawbNo}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  findClearingFileNumbersByMawbNo(mawbNo: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/clearingFiles/search/findClearingFileNumbersByMawbNo?clearingFileNumber=${fileNumber}&mawbNo=${mawbNo}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  uploadFromSpreadsheet(id: string, file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put(this.config.filesApiEndpoint + `api/clearingFiles/${id}/vehiclesUpload`, formData);
  }

  createInvoiceLines(partDetails: PartDetail[], clearingFileId: string, mawbNo: string, invoiceNumber: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.config.filesApiEndpoint}api/clearingFiles/${clearingFileId}/supplierInvoices/upload?mawbNo=${mawbNo}&invoiceNo=${invoiceNumber}`, JSON.stringify(partDetails), {headers});
  }

  createNewFileFromForwardingFileHousebill(housebill: ForwardingTransportDocument, clearingFile: ClearingFile): void { //Todo replicate for quotes
    clearingFile.importer = housebill.importer;
    clearingFile.customOffice = housebill.customsOffice;
    const transportDocument = this.shareDataService.addTransportDocument('imports');
    Object.keys(housebill).forEach(key => {
      if (housebill[key]) {
        transportDocument[key] = housebill[key];
      }
    });
    clearingFile.transportDocuments = [transportDocument];
  }
}


