import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-air-export-registration',
  templateUrl: './air-export-registration.component.html',
  styleUrls: ['./air-export-registration.component.scss']
})
export class AirExportRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
