import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DataScrollerModule } from 'primeng/datascroller';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { CardModule, MenubarModule, MenuModule, TabMenuModule } from 'primeng/primeng';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { DigiCommonModule } from '../digi-common/digi-common.module';
import { ROAD_ROUTES } from './digi-road.routes';
import { RoadContextNavigationComponent } from './road-context-navigation/road-context-navigation.component';
import { RoadDashboardComponent } from './road-dashboard/road-dashboard.component';
import { DocumentsComponent } from './road-registration/documents/documents.component';
import { PlanningComponent } from './road-registration/planning/planning.component';
import { RegisterComponent } from './road-registration/register/register.component';
import { ExportsRoadDashboardComponent } from './road-dashboard/exports-road-dashboard/exports-road-dashboard.component';
import {RoadExportRegistrationComponent} from './export/road-registration/road-export-registration.component';
import { ManifestComponent } from './manifest/manifest.component';
import { WaybillsComponent } from './manifest/waybills/waybills.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ManifestRegisterComponent} from './manifest/register/manifest-register.component';
import { ManifestEdiComponent } from './manifest/manifest-edi/manifest-edi.component';
import {ManifestHeaderComponent} from './manifest/header/manifest-header.component';
import { ContainersComponent } from './manifest/containers/containers.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TreeModule,
        ButtonModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        StepsModule,
        ScrollPanelModule,
        CheckboxModule,
        ListboxModule,
        InputTextModule,
        AutoCompleteModule,
        PickListModule,
        AutoCompleteModule,
        InputTextModule,
        ListboxModule,
        PanelModule,
        CardModule,
        CardModule,
        PickListModule,
        CalendarModule,
        DialogModule,
        FieldsetModule,
        InputTextareaModule,
        DropdownModule,
        TooltipModule,
        ToolbarModule,
        MultiSelectModule,
        DataScrollerModule,
        DataViewModule,
        AccordionModule,
        ToastModule,
        OverlayPanelModule,
        FileUploadModule,
        MessagesModule,
        MessageModule,
        KeyFilterModule,
        DigiCommonModule,
        MenubarModule,
        MenuModule,
        InputMaskModule,
        RouterModule.forChild(ROAD_ROUTES),
        PdfViewerModule,
    ],
    declarations: [
        RoadDashboardComponent,
        RoadContextNavigationComponent,
        PlanningComponent,
        RegisterComponent,
        DocumentsComponent,
        ExportsRoadDashboardComponent,
        RoadExportRegistrationComponent,
        ManifestComponent,
        WaybillsComponent,
        ManifestRegisterComponent,
        ManifestEdiComponent,
        ManifestHeaderComponent,
        ContainersComponent

  ],
  providers: [
    HttpClient
  ]
})
export class DigiRoadModule {
}
