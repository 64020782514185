<digi-clearing-file-section step="transportDocument">
  <div class="ui-g-12">
      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <input type="text" pInputText readonly
          value="{{clearingFile?.importer?.name}}">
        <label style="color: white;">Importer</label>
      </span>
      </div>
    </div>
</digi-clearing-file-section>
<div class="container-fluid test-inner-border">
  <div class="card">
    <div class="card-header">
      <label>Associate Supplier Invoices with Transport Documents</label>
    </div>
    <div class="card-body"id="detailOptions">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-8">
          <label>Transport Documents > Supplier Invoices</label>
          <p-tree [value]="transportDocuments" (onNodeDrop)="onNodeDrop($event)"
            draggableNodes="true" droppableNodes="true" dragdropScope="files"></p-tree>
        </div>
      </div>
    </div>
  </div>
</div>
