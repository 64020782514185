import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-consolidation-details-table',
  templateUrl: './consolidation-details-table.component.html',
  styleUrls: ['./consolidation-details-table.component.scss']
})
export class ConsolidationDetailsTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
