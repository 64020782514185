import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  Address,
  Supplier
} from '../../../models/file';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company.service';
import {ForwardingFile, ForwardingTransportDocument} from '../../../models/ForwardingFile';

@Component({
  selector: 'digi-forwarding-report-file',
  templateUrl: './forwarding-report-file.component.html',
  styleUrls: ['./forwarding-report-file.component.scss']
})
export class ForwardingReportFileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() forwardingFile: ForwardingFile;
  @Input() isReleaseLetter: boolean;
  @Input() transportMethod: string;
  @Input() forwardingHawb: ForwardingTransportDocument;
  logo: string;
  companyAddress: string;
  companyName: string;
  companySubscription: Subscription;
  importerDeliveryAddressSubscription: Subscription;

  constructor(
    private companyService: CompanyService) {
  }

  ngOnInit() {
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          this.logo = 'data:image/png;base64,' + companies[0].logo;
          const branch = companies[0].branches[0];
          this.companyAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.companyName = companies[0].name;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.forwardingFile) {
      this.forwardingFile = changes.forwardingFile.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }

    if (this.importerDeliveryAddressSubscription) {
      this.importerDeliveryAddressSubscription.unsubscribe();
    }
  }

  getDate() {
    return moment().format('DD/MM/YYYY');
  }

  getTime() {
    return `${moment().format('HH:mm')}`;
  }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }
}
