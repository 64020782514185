import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address, BusinessEntity} from '../../models/business-entity';


@Component({
  selector: 'app-main-business-entity-address',
  templateUrl: './business-entity-addresses.component.html',
  styleUrls: ['./business-entity-addresses.component.scss']
})
export class BusinessEntityAddressesComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  selectedAddress: Address;
  address: Address;

  constructor() {
  }

  ngOnInit() {
    this.newAddressDefault();
  }

  ngOnChanges() {
    this.newAddressDefault();
  }

  private newAddressDefault() {
    this.selectedAddress = undefined;
    if (!this.businessEntity.addresses) {
      this.businessEntity.addresses = [];
    }
  }

  addNewAddress() {
    let count = 0;
    for (this.address of this.businessEntity.addresses) {
      if (!this.address.line1) {
        count = count + 1;
      }
    }

    if (count < 1) {
      this.selectedAddress = new Address();
      this.businessEntity.addresses.push(this.selectedAddress);
      this.businessEntity.addresses = [...this.businessEntity.addresses];
    }
  }

  onRoleUnSelected(event: any) {
    this.selectedAddress = undefined;
  }
}
