import { Component, OnInit } from '@angular/core';
import { BillOfEntryLine } from '../../models/billOfEntries';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-boe-line-units-quantity',
  templateUrl: './boe-line-units-quantity.component.html',
  styleUrls: ['./boe-line-units-quantity.component.scss']
})
export class BoeLineUnitsQuantityComponent implements OnInit {
  selectedBillOfEntryLine: BillOfEntryLine;

  constructor(private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject) {
  }

  ngOnInit() {
    this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
      });
  }

}
