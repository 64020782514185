<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-panel header="Nomination">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-10">
            <div class="ui-inputgroup">
                            <span class="ui-float-label">
                <p-autoComplete id="address"
                                [suggestions]="filteredAddressTypes"
                                [dropdown]="true" field="addressType.description"
                                [(ngModel)]="selectedAddressType"
                                (completeMethod)=search($event)>
                    <ng-template let-option pTemplate="item">
                      <div>{{option.addressType.description}}</div>
                    </ng-template>
                </p-autoComplete>
                <label for="address">Address Type</label>
              </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-2">
            <button pButton type="button" (click)="addNewAddress()" label="New" icon="fa fa-file"></button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12">
      <p-table [value]="activeRole?.roleAddresses" [(selection)]="selectedRoleAddress" selectionMode="single"
               [resizableColumns]="true" (onRowSelect)="onRoleAddressSelected($event)"
               (onRowUnselect)="onRoleAddressUnSelected($event)">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" colspan="6">
              Address Type
            </th>
            <th scope="col" colspan="6">
              Street
            </th>
            <th style="width: 4%">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td scope="row" colspan="6">
              <label>{{rowData?.addressType?.description}}</label>
            </td>
            <td colspan="6">
              <label>{{rowData?.address?.line1}}</label>
            </td>
            <td>
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="12">
              <label>No addresses found</label>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-12" *ngIf="selectedRoleAddress && selectedAddressType">
      <p-panel header="Address">
        <digi-business-entity-address-search [address]="selectedRoleAddress?.address" [businessEntity]="businessEntity"
                                             (onSelect)="selectAddress($event)">
        </digi-business-entity-address-search>
      </p-panel>
    </div>
  </div>
</div>
