import {Inject, Injectable} from '@angular/core';
import {AirWaybill, WaybillNumber} from '../models/AirWaybill';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AirWaybillService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  save(airWaybill: AirWaybill): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (airWaybill._links && airWaybill._links.self) {
      return this.http.put(airWaybill._links.self.href, JSON.stringify(airWaybill), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/airWaybills', JSON.stringify(airWaybill), {headers});
    }
  }

  findByAirlineCode(code: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/airWaybills/search/findByAirlineCode?code=` + code)
      .pipe(
        map(response => response['_embedded'].airWaybills)
      );
  }

  filterWaybillNumbers(airwaybill: AirWaybill, exporterCode: string, fileNumber: string) {
    const availableWaybillNumbers = [];
    airwaybill.waybillNumbers.forEach(number => {
      let reserved = false;
      let used = false;
      if (number.waybillReservation) {
        reserved = !(number.waybillReservation.exporter && number.waybillReservation.exporter.code === exporterCode);
      }
      if (number.waybillUsages) {
        used = !!number.waybillUsages.find(usage => usage.fileNumber !== fileNumber);
      }

      if (!reserved && !used) {
        availableWaybillNumbers.push(number);
      }
    });
    return availableWaybillNumbers.map((availableWaybillNumber: WaybillNumber) => availableWaybillNumber.number);
  }
}
