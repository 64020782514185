<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel header="Shipment Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="airline"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="exportFile.transportDocument.airline"
                                                  field="label"
                                                  [suggestions]="filteredAirlines"
                                                  (completeMethod)="searchAirline($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  (onSelect)="selectAirline($event)"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.code}}: {{option.name}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="airline">Airline</label>
                                </span>
                                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span *ngIf="exportFile.transportDocument.airline" class="ui-inputgroup-addon">
                                  {{exportFile.transportDocument.airline?.prefix}}
                                </span>
                                <span class="ui-float-label">
                                  <p-autoComplete id="mawbNo"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="exportFile.transportDocument.mawbNo"
                                                  [suggestions]="filteredWaybillNumbers"
                                                  (completeMethod)="searchWaybillNumber()"
                                                  (onSelect)="updateWaybillPool($event)"
                                                  [dropdown]="true"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="mawbNo">MAWB No</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <p-checkbox name="shipmentType"
                              label="Straight"
                              binary="true"
                              [ngModel]="exportFile.transportDocument.shipmentType==='STRAIGHT'"
                              (onChange)="onShipmentType('STRAIGHT')">

                  </p-checkbox>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <p-checkbox name="shipmentType"
                              label="Back To Back"
                              binary="true"
                              [ngModel]="exportFile.transportDocument.shipmentType==='BACK_TO_BACK'"
                              (onChange)="onShipmentType('BACK_TO_BACK')">

                  </p-checkbox>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <p-checkbox name="shipmentType"
                              label="Consolidation"
                              binary="true"
                              [ngModel]="exportFile.transportDocument.shipmentType==='CONSOLIDATION'"
                              (onChange)="onShipmentType('CONSOLIDATION')">

                  </p-checkbox>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.flight">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                              <div *ngIf="!exportFile.transportDocument.flight" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="departureDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               (change)="defaultReservationDate()"
                                               [(ngModel)]="exportFile.transportDocument.departureDate">
                                  </p-inputMask>
                                  <label for="departureDate">Departure Date</label>
                                </span>
                              </span>
                               <div *ngIf="validationService.validateDateIsBefore(exportFile.transportDocument.departureDate)"
                                    class="ui-message warning ui-corner-all ">
                              Departure date should not be past date
                            </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="reservationDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="exportFile.transportDocument.reservationDate">
                                  </p-inputMask>
                                  <label for="reservationDate">Reservation Date</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="arrivalDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="exportFile.transportDocument.arrivalDate">
                                  </p-inputMask>
                                  <label for="arrivalDate">Arrival Date</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="callPurpose"
                                                  [(ngModel)]="exportFile.transportDocument.callPurpose"
                                                  [suggestions]="callPurposes"
                                                  [dropdown]="true"
                                                  (completeMethod)="callPurposes = ['Loading', 'Unloading']"
                                  ></p-autoComplete>
                                  <label for="callPurpose">Call Purpose</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete [(ngModel)]="exportFile.transportDocument.depot" field="label"
                                                  [suggestions]="filteredDepotTerminals"
                                                  (completeMethod)="searchDepotTerminal($event, 'D')"
                                                  [autoHighlight]="true" minLength="1"
                                                  [dropdown]="true">
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label>Unpack Depot</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete [(ngModel)]="exportFile.transportDocument.terminal" field="label"
                                                  [suggestions]="filteredDepotTerminals"
                                                  (completeMethod)="searchDepotTerminal($event, 'T')"
                                                  [autoHighlight]="true" minLength="1"
                                                  [dropdown]="true">
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.label}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label>Discharge Terminal</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Port Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <digi-port-details portLabel='Departure Airport'
                                     [transportDetails]="exportFile.transportDocument.departurePort"
                                     [file]="exportFile"
                                     [transportMethodCode]="'AIR'"
                                     [isRequired]="false"
                  ></digi-port-details>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <digi-port-details portLabel='Destination Airport'
                                     [transportDetails]="exportFile.transportDocument.destinationPort"
                                     [file]="exportFile"
                                     [transportMethodCode]="'AIR'"
                                     [isRequired]="false"
                  ></digi-port-details>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <digi-port-details portLabel='Final Destination Airport'
                                     [transportDetails]="exportFile.transportDocument.finalDestinationPort"
                                     [file]="exportFile"
                                     [transportMethodCode]="'AIR'"
                                     [isRequired]="false"
                  ></digi-port-details>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
      <br>
      <p-panel header="Customs Office">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <digi-customs-office [customOffice]="exportFile.customOffice"
                                       [isRequired]=true
                                       (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Local Agent Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <digi-business-entity-search [businessEntity]="exportFile.localAgent"
                                           [roleName]="'Local Agent'"
                                           [roleID]="4"
                                           [showAddress]="true"
                                           (selectedBusinessEntity)="selectLocalAgent($event)"
              >
              </digi-business-entity-search>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel header="Co-Loader Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <digi-business-entity-search [businessEntity]="exportFile.transportDocument.coLoader"
                                           [roleName]="'Co-Loader'"
                                           [roleID]="4"
                                           [showAddress]="true"
                                           (selectedBusinessEntity)="selectCoLoader($event)"
                                           [requiresValidation]="false"
              ></digi-business-entity-search>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Overseas Agent Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <digi-business-entity-search [businessEntity]="exportFile.transportDocument.overseasAgent"
                                           [businessEntities]="overseasAgents | async"
                                           [showAddress]="true"
                                           [roleName]="'Overseas Agent'"
                                           [roleID]="5"
                                           (selectedBusinessEntity)="selectOverseasAgent($event)"
                                           [requiresValidation]="!isStraightBooking()"
              ></digi-business-entity-search>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
