/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boe-line-units-quantity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i3 from "primeng/components/card/card";
import * as i4 from "./boe-line-units-quantity.component";
import * as i5 from "../../../../subjects/billOfEntryLine-behaviour-subject";
var styles_BoeLineUnitsQuantityComponent = [i0.styles];
var RenderType_BoeLineUnitsQuantityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoeLineUnitsQuantityComponent, data: {} });
export { RenderType_BoeLineUnitsQuantityComponent as RenderType_BoeLineUnitsQuantityComponent };
export function View_BoeLineUnitsQuantityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "p-card", [["class", "ui-card"]], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(1, 49152, null, 2, i3.Card, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { headerFacet: 0 }), i1.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(4, 0, null, 1, 7, "div", [["class", "ui-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "ui-g-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "ui-g-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedBillOfEntryLine == null) ? null : ((_co.selectedBillOfEntryLine.tariffCode == null) ? null : ((_co.selectedBillOfEntryLine.tariffCode.formula == null) ? null : _co.selectedBillOfEntryLine.tariffCode.formula.statisticalUOM))); _ck(_v, 8, 0, currVal_0); var currVal_1 = ((_co.selectedBillOfEntryLine == null) ? null : _co.selectedBillOfEntryLine.lineQuantity); _ck(_v, 11, 0, currVal_1); }); }
export function View_BoeLineUnitsQuantityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-boe-line-units-quantity", [], null, null, null, View_BoeLineUnitsQuantityComponent_0, RenderType_BoeLineUnitsQuantityComponent)), i1.ɵdid(1, 114688, null, 0, i4.BoeLineUnitsQuantityComponent, [i5.BillOfEntryLineBehaviourSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoeLineUnitsQuantityComponentNgFactory = i1.ɵccf("digi-boe-line-units-quantity", i4.BoeLineUnitsQuantityComponent, View_BoeLineUnitsQuantityComponent_Host_0, {}, {}, []);
export { BoeLineUnitsQuantityComponentNgFactory as BoeLineUnitsQuantityComponentNgFactory };
