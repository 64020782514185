import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
