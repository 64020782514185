import {Inject, Injectable} from '@angular/core';
import {Email} from '../models/email';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  sendMail(email: Email, lrn: string, clearingFileNumber: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${this.config.filesApiEndpoint}api/document/email?lrn=${lrn}&clearingFileNumber=${clearingFileNumber}`, JSON.stringify(email), {headers});
  }

  sendReportMail(email: Email, dateRange: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${this.config.filesApiEndpoint}api/document/reportEmail?dateRange=${dateRange}`, JSON.stringify(email), {headers});
  }

  sendHawbReportMail(email: Email, fileNumber: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${this.config.filesApiEndpoint}api/document/hawbReportEmail?fileNumber=${fileNumber}`, JSON.stringify(email), {headers});
  }
}
