<digi-tariff-maintenance-navigation></digi-tariff-maintenance-navigation>
<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Tariff" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad align">
        <digi-tariff-main-maintenance></digi-tariff-main-maintenance>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Descriptions">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-tariff-maintenance-description></digi-tariff-maintenance-description>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Formulae">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-tariff-formulae></digi-tariff-formulae>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
