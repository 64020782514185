/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./travel-types.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./travel-types.component";
var styles_TravelTypesComponent = [i0.styles];
var RenderType_TravelTypesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TravelTypesComponent, data: {} });
export { RenderType_TravelTypesComponent as RenderType_TravelTypesComponent };
export function View_TravelTypesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please Choose Transport Type"])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "nav-link travel-type"], ["routerLink", "/air/register"], ["title", "Air"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-plane fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Air "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "nav-link travel-type mb-2"], ["routerLink", "/ocean"], ["title", "Ocean"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-ship fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ocean "])), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [["class", "nav-link travel-type"], ["routerLink", "/rail"], ["title", "Rail"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fa fa-train fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Rail "])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [["class", "nav-link travel-type"], ["routerLink", "/road"], ["title", "Road"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fa fa-road fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Road "]))], function (_ck, _v) { var currVal_2 = "/air/register"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/ocean"; _ck(_v, 10, 0, currVal_5); var currVal_8 = "/rail"; _ck(_v, 16, 0, currVal_8); var currVal_11 = "/road"; _ck(_v, 21, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 16).target; var currVal_7 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 21).target; var currVal_10 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_9, currVal_10); }); }
export function View_TravelTypesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-travel-types", [], null, null, null, View_TravelTypesComponent_0, RenderType_TravelTypesComponent)), i1.ɵdid(1, 114688, null, 0, i4.TravelTypesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TravelTypesComponentNgFactory = i1.ɵccf("digi-travel-types", i4.TravelTypesComponent, View_TravelTypesComponent_Host_0, {}, {}, []);
export { TravelTypesComponentNgFactory as TravelTypesComponentNgFactory };
