import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config.module';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { FileService } from '../../services/file.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../app.config.module";
import * as i3 from "../../services/file.service";
var PermitsService = /** @class */ (function () {
    function PermitsService(http, config, fileService) {
        this.http = http;
        this.config = config;
        this.fileService = fileService;
        this.permitsBehaviourSubject = new BehaviorSubject([]);
    }
    PermitsService.prototype.findPermitTypeByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/permitTypes/search/codeStartsWith?code=" + code).pipe(map(function (response) { return response['_embedded'].permitTypes; }));
    };
    PermitsService.prototype.findRCCTypeByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/rccTypes/search/codeStartWith?code=" + code).pipe(map(function (response) { return response['_embedded'].rccTypes; }));
    };
    PermitsService.prototype.findBusinessEntity = function (nameOrCode, roleType) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + nameOrCode + ("&roleType=" + roleType))
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    PermitsService.prototype.savePermit = function (permit) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (permit._links && permit._links.self) {
            return this.http.put(permit._links.self.href, JSON.stringify(permit), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/permits', JSON.stringify(permit), { headers: headers });
        }
    };
    PermitsService.prototype.findPermitsByCode = function (code) {
        return this.http.get(this.config.filesApiEndpoint + "api/permits/search/findByPermitNoStartWith?permitNo=" + code)
            .pipe(map(function (response) { return response['_embedded'].permits; }));
    };
    PermitsService.prototype.findPermitsByTariffCodeAndBusinessEntityRef = function (tariffCode, businessEntityRefCode, roleType) {
        var _this = this;
        this.fileService.setIsLoadingPermit(true);
        var date = moment().format('DD/MM/YYYY');
        return this.http
            .get(this.config.filesApiEndpoint + "api/permits/search/findByTariffCodeAndBusinessEntityRef"
            + ("?tariffCode=" + tariffCode)
            + ("&BERefCode=" + businessEntityRefCode)
            + ("&roleType=" + roleType)
            + ("&date=" + date)
            + "&projection=flattenedPermit")
            .pipe(map(function (response) { return response['_embedded'] ? response['_embedded'].permits : []; })).pipe(tap(function (_) { return _this.fileService.setIsLoadingPermit(false); }));
    };
    PermitsService.prototype.findPermitsByTariffCode = function (tariffCode) {
        var date = moment().format('DD/MM/YYYY');
        return this.http
            .get(this.config.filesApiEndpoint + "api/permits/search/findByTariffCode"
            + ("?tariffCode=" + tariffCode)
            + ("&date=" + date))
            .pipe(map(function (response) { return response['_embedded'] ? response['_embedded'].permits : []; }));
    };
    PermitsService.prototype.getAppliedLocalValue = function (currencyCode, date, appliedValueForeign) {
        return this.http
            .post(this.config.apiEndpoint + "api/exchangeRates/calculateLocalValue?code=" + currencyCode + "&type=cus&date=" + date, appliedValueForeign);
    };
    PermitsService.prototype.getPermits = function () {
        return this.permitsBehaviourSubject.asObservable();
    };
    PermitsService.prototype.addPermits = function (value) {
        this.permitsBehaviourSubject.next(value);
    };
    PermitsService.ngInjectableDef = i0.defineInjectable({ factory: function PermitsService_Factory() { return new PermitsService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.FileService)); }, token: PermitsService, providedIn: "root" });
    return PermitsService;
}());
export { PermitsService };
