import { BehaviorSubject, Observable } from 'rxjs';
import { ShareDataService } from '../app/digi-common/services/share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/services/share-data.service";
var ExBondFileBehaviourSubject = /** @class */ (function () {
    function ExBondFileBehaviourSubject(shareDataService) {
        this.shareDataService = shareDataService;
        this.exBondFileBehaviorSubject = new BehaviorSubject(this.shareDataService.buildExBondFile());
    }
    ExBondFileBehaviourSubject.prototype.addExBondFile = function (exBondFile) {
        this.exBondFileBehaviorSubject.next(exBondFile);
    };
    ExBondFileBehaviourSubject.prototype.getExBondFile = function () {
        return this.exBondFileBehaviorSubject.asObservable();
    };
    ExBondFileBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function ExBondFileBehaviourSubject_Factory() { return new ExBondFileBehaviourSubject(i0.inject(i1.ShareDataService)); }, token: ExBondFileBehaviourSubject, providedIn: "root" });
    return ExBondFileBehaviourSubject;
}());
export { ExBondFileBehaviourSubject };
