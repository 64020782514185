import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class ImportsReportService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  generateImportBondStoreReports(from, to, importerCode, importerName, warehouseCode, warehouseName, mrnNumber: string): Observable<any> {
      return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/importBondStoreReports?from=${from}&to=${to}&importerCode=${importerCode}&importerName=${importerName}&warehouseCode=${warehouseCode}&warehouseName=${warehouseName}&mrnNumber=${mrnNumber}`);
    }
}
