import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './services/notification.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('server-error interceptor catchError for request', request);
        console.error('Error status [%s], statusText [%s]',
          error.status, error.statusText);
        // console.error('error.error', error.error);
        // console.error('error.message', error.message);
        // console.error('error.error.message', error.error.message);
        // console.error('error.error.errors', error.error.errors);

        if (error.status === 401) {
          this.notificationService.errorNotify('Unauthorized.');
        } else if (error.status === 406 || error.status === 400) {
          if (error.error) {
            this.notificationService.errorNotify(error.error.message, error.error.errors);
          } else {
            this.notificationService.errorNotify(error.message);
          }
        } else if (error.status === 404) {
          console.log('Ignoring 404 must be handled client side.');
        } else if (error.status === 409) {
          console.log('Ignoring 409 must be handled client side.');
        } else if (error.status === 500) {
          this.notificationService.errorNotify('System is currently unavailable please contact support.');
        } else if (error.status === 0) {
          console.error('Possible Server not available for request: ', request);
          if (error.message && error.message === 'Http failure response for (unknown url): 0 Unknown Error') {
            this.notificationService.errorNotify('Possible backend system down. URL ['
            + request.url
            + '] Error: ' + error.message);
          }
        } else {
          this.notificationService.errorNotify(error.message);
        }
        return throwError(error);
      })
    );
  }
}
