import { OnInit } from '@angular/core';
import { ValidationService } from '../app/digi-common/services/validation.service';
import { ClearingFileBehaviourSubject } from '../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../subjects/transport-document-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../subjects/supplierInvoice-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../subjects/supplier-invoice-line-behaviour-subject.service';
import { SaveClearingFile } from '../app/digi-common/saveClearingFile';
import { NotificationService } from '../app/digi-common/services/notification.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/saveClearingFile";
import * as i2 from "../subjects/clearingfile-behaviour-subject";
import * as i3 from "../subjects/transport-document-behaviour-subject";
import * as i4 from "../subjects/supplierInvoice-behaviour-subject";
import * as i5 from "../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i6 from "../app/digi-common/services/notification.service";
import * as i7 from "../app/digi-common/services/validation.service";
var ClearingFileValidationService = /** @class */ (function () {
    function ClearingFileValidationService(onSaveClearingFile, clearingFileBehaviourSubject, transportDocumentBehaviourSubject, supplierInvoiceBehaviourSubject, supplierInvoiceLineBehaviourSubject, messageService, validationService) {
        this.onSaveClearingFile = onSaveClearingFile;
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.supplierInvoiceLineBehaviourSubject = supplierInvoiceLineBehaviourSubject;
        this.messageService = messageService;
        this.validationService = validationService;
    }
    ClearingFileValidationService.prototype.ngOnInit = function () {
    };
    ClearingFileValidationService.prototype.validateTransportDocument = function (clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        var result = this.validationService.validateTransportDocumentOnly(selectedTransportDocument, clearingFile.clearingInstructions[0].transportMethod.method, 'imports');
        if (result.isValid) {
            this.onSaveClearingFile.saveClearingFile(clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
        }
        else {
            this.showErrors(result.messages);
        }
        return result.isValid;
    };
    ClearingFileValidationService.prototype.showErrors = function (messages) {
        this.messageService.errorNotify('Validation Failed', messages);
    };
    ClearingFileValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ClearingFileValidationService_Factory() { return new ClearingFileValidationService(i0.inject(i1.SaveClearingFile), i0.inject(i2.ClearingFileBehaviourSubject), i0.inject(i3.TransportDocumentBehaviourSubject), i0.inject(i4.SupplierInvoiceBehaviourSubject), i0.inject(i5.SupplierInvoiceLineBehaviourSubject), i0.inject(i6.NotificationService), i0.inject(i7.ValidationService)); }, token: ClearingFileValidationService, providedIn: "root" });
    return ClearingFileValidationService;
}());
export { ClearingFileValidationService };
