import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  Address,
  BusinessEntity,
  Country,
  CustomsTraderType,
  ImporterSupplier,
  NominatedBusinessEntity,
  Role, Importer, RoleAddress
} from '../../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../../../services/notification.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ClearingFile} from '../../../models/clearingFile';
import {LocalAgent, PurchaseTerm, SupplierInvoice, SupplierInvoiceLine, TransportDocument} from '../../../models/file';
import {Subscription} from 'rxjs';
import {SaveClearingFile} from '../../../saveClearingFile';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../../subjects/supplier-invoice-line-behaviour-subject.service';

@Component({
  selector: 'digi-importer-modal',
  templateUrl: './importer-modal.component.html',
  styleUrls: ['./importer-modal.component.scss']
})
export class ImporterModalComponent implements OnInit {
  @Input('display') display;
  @Output() created = new EventEmitter();
  roleType = 6;
  businessEntity: BusinessEntity;
  importer: Importer;
  role: Role;
  selectedAddress: Address;
  clearingFile: ClearingFile;
  nominatedBusinessEntity: NominatedBusinessEntity;
  importerSupplier: ImporterSupplier;
  clearingFileSubscription: Subscription;
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;

  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
              private onSaveClearingFile: SaveClearingFile,
  ) {}

  ngOnInit() {
    this.initialize();

    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
      if (cf) {
        this.clearingFile = cf;
        this.businessEntity.name = this.clearingFile.importer.name;
        this.businessEntity.code = this.clearingFile.importer.code;
        if (this.clearingFile.importer.address) {
          this.selectedAddress = this.clearingFile.importer.address;
        }
        this.importer.defaultCustomsCode = this.clearingFile.importer.customsCode;
        this.importer.registrationType = this.clearingFile.importer.registrationType;
        this.importer.idNumber = this.clearingFile.importer.idNumber;
        this.importer.passportNumber = this.clearingFile.importer.passportNumber;
      }
    });
    this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));

    this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);

    this.role = new Importer();
  }

  initialize() {
    this.businessEntity = new BusinessEntity();
    this.nominatedBusinessEntity = new NominatedBusinessEntity();
    this.importerSupplier = new ImporterSupplier();
    this.importerSupplier.defaultPurchaseTerm = new PurchaseTerm();
    this.importer =  new Importer();
    this.selectedAddress = new Address();
  }

  selectCountry(value: Country) {
    this.importer.country = value;

  }

  selectTraderType(value: CustomsTraderType) {
    this.importer.registrationType = value;
  }

  onSave() {
    if (this.clearingFile.importer.isEdited === true) {
      this.clearingFile.importer = this.businessEntity;
      this.clearingFile.importer.name = this.businessEntity.name;
      this.clearingFile.importer.code = this.businessEntity.code;
      const address = Object.assign({}, this.selectedAddress);
      this.clearingFile.importer.address = [];
      this.clearingFile.importer.address = address;
      this.clearingFile.importer.customsCode = this.importer.defaultCustomsCode;
      this.clearingFile.importer.passportNumber = this.importer.passportNumber;
      this.clearingFile.importer.idNumber = this.importer.idNumber;
      this.clearingFile.importer.registrationType = this.importer.registrationType;
      this.onSaveClearingFile.saveClearingFile(
        this.clearingFile,
        this.selectedTransportDocument,
        this.selectedSupplierInvoice,
        this.selectedSupplierInvoiceLine,
      ).subscribe(
        value => {
          this.messageService.successNotify('Importer Updated Saved');
        });
    } else {
      this.businessEntity.registeredName = this.businessEntity.name;
      const role = {roleType: 6};
      this.businessEntity.roles = [];
      this.businessEntity.roles.push(role);
      this.businessEntity.roles = [...this.businessEntity.roles];
      const address = Object.assign({}, this.selectedAddress);
      this.businessEntityService.saveBusinessEntity(this.businessEntity)
        .subscribe(be => {
          this.businessEntity = be;
          if (address && address.line1) {
            this.businessEntity.addresses = [];
            this.businessEntity.addresses.push(address);
            this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(updatedBE => {
              this.businessEntity = updatedBE;
              const beRole = this.businessEntity.roles.find(el => el.roleType === 6);
              const roleAddress = new RoleAddress();
              roleAddress.address = this.businessEntity.addresses[0];
              roleAddress.addressType = {id: 'CUS', description: 'Customs Address'};
              beRole.roleAddresses = [roleAddress];
              this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(beWithRoleAddress => {
                this.businessEntity = beWithRoleAddress;
                this.messageService.successNotify('Importer Saved');
                this.select(this.businessEntity);
                this.clearingFile.importer = this.businessEntity;
                this.clearingFile.importer.address = this.selectedAddress;
                this.clearingFile.importer.customsCode = this.importer.defaultCustomsCode;
                this.clearingFile.importer.passportNumber = this.importer.passportNumber;
                this.clearingFile.importer.idNumber = this.importer.idNumber;
                this.clearingFile.importer.registrationType = this.importer.registrationType;
              });
            });
          }
        }, error => {
          this.messageService.errorNotify(error.error.message, [error.error.errors]);
        });
    }

  }

  select(value) {
    this.businessEntity = value;
  }

  onNew() {
    this.initialize();
  }

  onClose() {
    this.display = false;
    this.created.emit(this.nominatedBusinessEntity.businessEntity);
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  // ngOnDestroy(): void {
  //   this.clearingFileSubscription.unsubscribe();
  // }
}

