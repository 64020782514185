import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasContainerTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllMASContainerTypeStartsWith(searchValue) {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/masContainerTypes/search/typeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].masContainerTypes)
      );
  }
}

