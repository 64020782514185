import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import { Address } from '../models/file';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var AgentService = /** @class */ (function () {
    function AgentService(http, config) {
        this.http = http;
        this.config = config;
    }
    AgentService.prototype.findLocalAgentNameOrCodeStartsWith = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=" + code + "&roleType=4&projection=flattenedLocalAgent")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    AgentService.prototype.findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod = function (nameOrCargoCarrierCode, transportMethod) {
        return this.http
            .get(this.config.apiEndpoint + "api/customscargocarriercodes/search/nameOrCargoCarrierCodeStartsWithAndTransportMethod?localAgentName=" + nameOrCargoCarrierCode + "&cargoCarrierCode=" + nameOrCargoCarrierCode + "&transportMethod=" + transportMethod + "&projection=FlattenedCustomsCargoCarrierCode")
            .pipe(map(function (response) { return response['_embedded'].customscargocarriercodes; }));
    };
    AgentService.prototype.findLocalAgentFromClearingAgent = function (clearingFile) {
        if (clearingFile.localAgent && clearingFile.localAgent.code) {
            return this.findLocalAgentNameOrCodeStartsWith(clearingFile.localAgent.code).pipe(tap(function (localAgentData) {
                if (localAgentData && (!clearingFile.localAgent.address || !clearingFile.localAgent.address.line1)) {
                    var localAgentAddresses = localAgentData[0].addresses;
                    var localAgentAddress = void 0;
                    if (localAgentAddresses.length === 1) {
                        localAgentAddress = localAgentAddresses[0];
                    }
                    else {
                        localAgentAddress = localAgentAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (localAgentAddress) {
                        clearingFile.localAgent.address = new Address();
                        clearingFile.localAgent.address.line1 = localAgentAddress.line1;
                        clearingFile.localAgent.address.line2 = localAgentAddress.line2;
                        clearingFile.localAgent.address.line3 = localAgentAddress.line3;
                        clearingFile.localAgent.address.line4 = localAgentAddress.line4;
                        clearingFile.localAgent.address.zipCode = localAgentAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return EMPTY;
        }
    };
    AgentService.ngInjectableDef = i0.defineInjectable({ factory: function AgentService_Factory() { return new AgentService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: AgentService, providedIn: "root" });
    return AgentService;
}());
export { AgentService };
