import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClearingFile, ReportFile, PaymentFile, ClearingInstructionFile} from '../../models/clearingFile';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {Subscription} from 'rxjs';
import {ClearingFileService} from '../../services/clearing-file.service';
import {NotificationService} from '../../services/notification.service';
import {TransportMethod} from '../../models/file';

@Component({
  selector: 'digi-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  transportMethod: TransportMethod;

  clearingFileSubscription: Subscription;
  clearingFileServiceSubscription: Subscription;

  fileCover: ReportFile;
  deliveryInstruction: ReportFile;
  provisionalPayment: PaymentFile;
  clearingInstruction: ClearingInstructionFile;

  constructor(
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private clearingFileService: ClearingFileService,
    private messageService: NotificationService) { }

  ngOnInit() {
    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(
      (cf: ClearingFile) => {
        this.clearingFile = cf;
        this.transportMethod = this.clearingFile.clearingInstructions[0].transportMethod;
        if (this.clearingFile.reportFiles) {
          const foundFileCover = this.clearingFile.reportFiles.find(file => file.title === 'File Cover');
          if (foundFileCover) {
            this.fileCover = foundFileCover;
          } else {
            this.fileCover = new ReportFile();
            this.fileCover.title = 'File Cover';
          }
          const foundDeliveryInstruction = this.clearingFile.reportFiles.find(file => file.title === 'Delivery Instruction');
          if (foundDeliveryInstruction) {
            this.deliveryInstruction = foundDeliveryInstruction;
          } else {
            this.deliveryInstruction = new ReportFile();
            this.deliveryInstruction.title = 'Delivery Instruction';
          }
        } else {
          this.clearingFile.reportFiles = [];
        }
        if (this.clearingFile.paymentFiles) {
          const foundProvisionalPayment = this.clearingFile.paymentFiles.find(file => file.title === 'Provisional Payment');
          if (foundProvisionalPayment) {
            this.provisionalPayment = foundProvisionalPayment;
          } else {
            this.provisionalPayment = new PaymentFile();
            this.provisionalPayment.title = 'Provisional Payment';
          }
        } else {
          this.clearingFile.paymentFiles = [];
        }
        if (this.clearingFile.clearingInstructionFiles) {
          const foundClearingInstruction = this.clearingFile.clearingInstructionFiles.find(file => file.title === 'Clearing Instruction');
          if (foundClearingInstruction) {
            this.clearingInstruction = foundClearingInstruction;
          } else {
            this.clearingInstruction = new ClearingInstructionFile();
            this.clearingInstruction.title = 'Clearing Instruction';
          }
        } else {
          this.clearingFile.clearingInstructionFiles = [];
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.clearingFileSubscription) {
      this.clearingFileSubscription.unsubscribe();
    }

    if (this.clearingFileServiceSubscription) {
      this.clearingFileServiceSubscription.unsubscribe();
    }
  }

  saveClearingFile() {
    const foundFileCover = this.clearingFile.reportFiles.find(file => file.title === 'File Cover');
    const foundDeliveryInstruction = this.clearingFile.reportFiles.find(file => file.title === 'Delivery Instruction');
    const foundProvisionalPayment = this.clearingFile.paymentFiles.find(file => file.title === 'Provisional Payment');
    const foundClearingInstruction = this.clearingFile.clearingInstructionFiles.find(file => file.title === 'Clearing Instruction');
    if (foundFileCover) {
      foundFileCover.deliveryAddress = this.fileCover.deliveryAddress;
      foundFileCover.importerFaxNo = this.fileCover.importerFaxNo;
      foundFileCover.importerPhoneNo = this.fileCover.importerPhoneNo;
      foundFileCover.attention = this.fileCover.attention;
    } else {
      this.clearingFile.reportFiles.push(this.fileCover);
    }

    if (foundDeliveryInstruction) {
      foundDeliveryInstruction.deliveryAddress = this.deliveryInstruction.deliveryAddress;
      foundDeliveryInstruction.importerFaxNo = this.deliveryInstruction.importerFaxNo;
      foundDeliveryInstruction.importerPhoneNo = this.deliveryInstruction.importerPhoneNo;
      foundDeliveryInstruction.attention = this.deliveryInstruction.attention;
    } else {
      this.clearingFile.reportFiles.push(this.deliveryInstruction);
    }

    if (foundProvisionalPayment) {
      foundProvisionalPayment.clientName = this.provisionalPayment.clientName;
      foundProvisionalPayment.clientCode = this.provisionalPayment.clientCode;
      foundProvisionalPayment.clientReference = this.provisionalPayment.clientReference;
      foundProvisionalPayment.importer = this.provisionalPayment.importer;
      foundProvisionalPayment.exporter = this.provisionalPayment.exporter;
      foundProvisionalPayment.other = this.provisionalPayment.other;
      foundProvisionalPayment.section2AmountRands = this.provisionalPayment.section2AmountRands;
      foundProvisionalPayment.section2AmountCents = this.provisionalPayment.section2AmountCents;
      foundProvisionalPayment.section2AmountWords = this.provisionalPayment.section2AmountWords;
      foundProvisionalPayment.branch = this.provisionalPayment.branch;
      foundProvisionalPayment.reasonsForApplication = this.provisionalPayment.reasonsForApplication;
      foundProvisionalPayment.section5Mrn = this.provisionalPayment.section5Mrn;
      foundProvisionalPayment.section5Date = this.provisionalPayment.section5Date;
      foundProvisionalPayment.section5Supplier = this.provisionalPayment.section5Supplier;
      foundProvisionalPayment.section5OfCountry = this.provisionalPayment.section5OfCountry;
      foundProvisionalPayment.section5MarksAndNumbers = this.provisionalPayment.section5MarksAndNumbers;
      foundProvisionalPayment.section5Coo = this.provisionalPayment.section5Coo;
      foundProvisionalPayment.section5TariffSubheading = this.provisionalPayment.section5TariffSubheading;
      foundProvisionalPayment.section5DescriptionOfGoods = this.provisionalPayment.section5DescriptionOfGoods;
      foundProvisionalPayment.section5Value = this.provisionalPayment.section5Value;
      foundProvisionalPayment.section5DutyRands = this.provisionalPayment.section5DutyRands;
      foundProvisionalPayment.section5DutyCents = this.provisionalPayment.section5DutyCents;
      foundProvisionalPayment.section5VatRands = this.provisionalPayment.section5VatRands;
      foundProvisionalPayment.section5VatCents = this.provisionalPayment.section5VatCents;
    } else {
      this.clearingFile.paymentFiles.push(this.provisionalPayment);
    }

    if (!foundClearingInstruction) {
      this.clearingFile.clearingInstructionFiles.push(this.clearingInstruction);
    }

    this.clearingFileServiceSubscription = this.clearingFileService.sendClearingFile(this.clearingFile).subscribe(
      response => {
        console.log(response);
        this.messageService.successNotify('File Saved Successfully');
      }
    );
  }

  print() {
    window.print();
  }
}
