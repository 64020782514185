import { SupplierInvoiceLine, SupplierInvoice } from './../../models/file';
import { Component, OnInit, Input, IterableDiffers, KeyValueDiffers, IterableDiffer, KeyValueDiffer, DoCheck } from '@angular/core';

@Component({
  selector: 'digi-total-weight-quantity',
  templateUrl: './total-weight-quantity.component.html',
  styleUrls: ['./total-weight-quantity.component.css']
})
export class TotalWeightQuantityComponent implements OnInit, DoCheck {
  @Input() supplierInvoice: SupplierInvoice;
  invoicesDiffer: IterableDiffer<SupplierInvoiceLine>;
  invoiceDiffer: KeyValueDiffer<string, any>[] = new Array();

  constructor(
    private differs: KeyValueDiffers,
    private iterableDiffers: IterableDiffers
  ) {

  }

  ngOnInit() {
    console.log('in on init');
    this.invoicesDiffer = this.iterableDiffers.find(this.supplierInvoice.lines).create();
    this.supplierInvoice.lines.forEach(e => {
      this.invoiceDiffer.push(this.differs.find(e).create());
    });
  }

  ngDoCheck(): void {
    const changes = this.invoicesDiffer.diff(this.supplierInvoice.lines);
    if (changes) {
      console.log('Change in number of lines');
      this.supplierInvoice.lines.forEach(e => {
        this.invoiceDiffer.push(this.differs.find(e).create());
      });
    }
    for (let index = 0; index < this.invoiceDiffer.length; index++) {
      const e = this.invoiceDiffer[index];
      const change = e.diff(this.supplierInvoice.lines[index]);
      if (change) {
        console.log('Change in content of line');
        this.supplierInvoice.runningTotals.capturedValue = Math.round(this.supplierInvoice.lines
          .map(i => i.invoiceGrossValue)
          .reduce((sum, current) => current ? +sum + +current : +sum + 0) * 100) / 100;

        this.supplierInvoice.runningTotals.remainingValue =
          this.supplierInvoice.totalGrossIncomeValue -
          (this.supplierInvoice.runningTotals.capturedValue ? this.supplierInvoice.runningTotals.capturedValue : 0);

        this.supplierInvoice.runningTotals.capturedQuantity = Math.round(this.supplierInvoice.lines
          .map(i => i.lineWeight)
          .reduce((sum, current) => current ? +sum + +current : +sum + 0) * 100) / 100;

        this.supplierInvoice.runningTotals.remainingQuantity =
          this.supplierInvoice.quantity -
          (this.supplierInvoice.runningTotals.capturedQuantity ? this.supplierInvoice.runningTotals.capturedQuantity : 0);

        break;
      }
    }
  }

}
