<p-panel>
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input value="{{businessEntity?.code}}" type="text" pInputText readonly>
            <label>BE Code</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
            <input type="text" value="{{businessEntity?.code}}" pInputText readonly>
            <label>Warehouse Code</label>
          </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
             <span class="ui-float-label">
                <span class="md-inputfield">
                   <input id="customsWarehouseNumber"
                                [(ngModel)]="warehouse.customsWarehouseNumber" pInputText>
                  <label for="customsWarehouseNumber">Customs Warehouse Number</label>
                </span>
             </span>
      </span>
    </div>
  </div>
</p-panel>

