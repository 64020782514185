import {Inject, Injectable} from '@angular/core';
import {Address, ClearingInstruction, Warehouse} from '../models/file';
import {EMPTY, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }


  findWarehouseByCode(code) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=${code}&roleType=13&projection=flattenedWarehouse`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findWarehouseByWarehouseNumber(warehouseNumber) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byCustomsWarehouseNumberAndOnlyRoleType?warehouseNumber=${warehouseNumber}&roleType=13&projection=flattenedWarehouse`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findWarehouseFromClearingInstructionAndWarehouses(clearingInstruction: ClearingInstruction,
                                                    warehouses: Warehouse[]): Observable<Warehouse> {
    if (clearingInstruction) {
      if (warehouses.length === 0) {
        return this.returnDefault(clearingInstruction);
      } else if (clearingInstruction.warehouse.code) {
        return this.findWarehouseFromClearingInstruction(clearingInstruction, clearingInstruction.warehouse, false);
      } else if (warehouses && warehouses.length === 1) {
        return this.findWarehouseFromClearingInstruction(clearingInstruction, warehouses[0]);
      }
      return this.returnDefault(clearingInstruction);
    }
    return EMPTY;
  }

  private returnDefault(clearingInstruction: ClearingInstruction) {
    const warehouse = new Warehouse();
    warehouse.address = new Address();
    if (clearingInstruction) {
      clearingInstruction.warehouse = warehouse;
    }
    return of([warehouse]);
  }

  findWarehouseFromClearingInstruction(clearingInstruction: ClearingInstruction, warehouse: Warehouse, updateWarehouse: boolean = true) {
    if (clearingInstruction) {
      return this.findWarehouseByCode(warehouse.code).pipe(
        tap(warehouseData => {
          if (updateWarehouse) {
            clearingInstruction.warehouse = new Warehouse();
            clearingInstruction.warehouse.name = warehouse.name;
            clearingInstruction.warehouse.code = warehouse.code;
            clearingInstruction.warehouse.label = warehouse.label;
            clearingInstruction.warehouse.customsWarehouseNumber = warehouseData[0].customsWarehouseNumber;
          }
          if (warehouseData && (!clearingInstruction.warehouse.address
            || !clearingInstruction.warehouse.address.line1)) {
            const warehouseAddresses = warehouseData[0].addresses;
            let warehouseAddress;
            if (warehouseAddresses.length === 1) {
              warehouseAddress = warehouseAddresses[0];
            } else {
              warehouseAddress = warehouseAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'CUS';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (warehouseAddress) {
              clearingInstruction.warehouse.address = new Address();
              clearingInstruction.warehouse.address.line1 = warehouseAddress.line1;
              clearingInstruction.warehouse.address.line2 = warehouseAddress.line2;
              clearingInstruction.warehouse.address.line3 = warehouseAddress.line3;
              clearingInstruction.warehouse.address.line4 = warehouseAddress.line4;
              clearingInstruction.warehouse.address.zipCode = warehouseAddress.zipCode;
            }
          }

        }));
    } else {
      return this.returnDefault(clearingInstruction);
    }
  }
}
