<digi-clearing-file-section *ngIf="fileType==='imports'" step="supplierInvoice" (add)="addInvoiceLines()" [canAdd]="true">
  <div class="ui-g-12 ui-md-6">
    <div class="ui-inputgroup">
      <span class="md-inputfield ui-md-4">
          <input type="text"
                 value="{{selectedSupplierInvoice?.invoiceNumber}}"
                 pInputText
                 readonly>
          <label style="color: white;">Invoice Number</label>
        </span>
      <span class="md-inputfield ui-md-4">
          <input type="text" value="{{selectedSupplierInvoice?.supplier?.name}}"
                 pInputText
                 readonly>
          <label style="color: white;">Supplier</label>
        </span>
    </div>
  </div>
</digi-clearing-file-section>
<digi-exports-file-header-section *ngIf="fileType==='exports'" step="supplierInvoice" (add)="addInvoiceLines()">
  <div class="ui-g-12 ui-md-6">
    <div class="ui-inputgroup">
      <span class="md-inputfield ui-md-4">
          <input type="text"
                 value="{{selectedSupplierInvoice?.invoiceNumber}}"
                 pInputText
                 readonly>
          <label style="color: white;">Invoice Number</label>
        </span>
      <span class="md-inputfield ui-md-4">
          <input type="text" value="{{selectedSupplierInvoice?.supplier?.name}}"
                 pInputText
                 readonly>
          <label style="color: white;">Export Consignee</label>
        </span>
    </div>
  </div>
</digi-exports-file-header-section>
<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-3">
      <button *ngIf="isXE() || isZE()" pButton type="button" icon="fa fa-plus" (click)="addInvoiceLines()" [disabled]="selectedSupplierInvoice.manualExWarehouseLineCapture" label="Add Lines"></button>
    </div>
  </div>
  <div class="ui-g-2">
    <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           [(ngModel)]="mrnFilter"
                           placeholder="Search by MRN">
                </span>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-3">
            <span *ngIf="isXE() || isZE()" class="md-inputfield">
                <p-checkbox [(ngModel)]="selectedSupplierInvoice.manualExWarehouseLineCapture" label="Manual Capture Lines" binary="true" (onChange)="handleManualExWarehouseCaptureChange($event)"></p-checkbox>
              </span>
    </div>
  </div>
  <div *ngIf="hasSupplierInvoiceLine() || selectedSupplierInvoice.manualExWarehouseLineCapture" class="ui-g-nopad pt-2">
    <div class="ui-g-12">
      <p-table [columns]="cols" [value]="selectedSupplierInvoice.lines" selectionMode="single" columnResizeMode="expand"
               [(selection)]="selectedInvoiceLine"
               dataKey="lineNo"
               (onRowSelect)="selectInvoiceLine($event.data)" [paginator]="true" [rows]="5">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
            <th style="width: 4%">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoiceLine let-rowIndex="rowIndex">
          <tr [pSelectableRow]="invoiceLine" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
            <td>{{invoiceLine.lineNo}}</td>
            <td>{{invoiceLine.tariffCode.code | tariffCode}}{{invoiceLine.tariffCode.checkDigit}}</td>
            <td>{{invoiceLine.invoiceGrossValue}}</td>
            <td class="delete">
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="deleteLine(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-6">
      <p-panel *ngIf="selectedSupplierInvoice" header="Total Weight & Quantity">
        <digi-total-weight-quantity
          [supplierInvoice]="selectedSupplierInvoice"></digi-total-weight-quantity>
      </p-panel>
    </div>
    <div class="ui-g-6">
      <p-panel *ngIf="selectedSupplierInvoice && selectedInvoiceLine" header="Defaults">
        <digi-invoice-lines-defaults-section
          [invoiceLine]="selectedInvoiceLine"
          [userInterfaceDefaults]="selectedSupplierInvoice?.userInterfaceDefaults"></digi-invoice-lines-defaults-section>
      </p-panel>
    </div>
    <div class="ui-g-12">
      <p-panel header="ClearingInstruction Details">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-3">
            <p-autoComplete field="label"
                            [(ngModel)]="selectedCustomsPurpose"
                            [suggestions]="filteredCustomPurposes"
                            (completeMethod)="searchCustomsPurpose($event)"
                            (onSelect)="selectCustomsPurpose($event)"
                            [dropdown]="true"
                            minLength="3"
                            required="true">
              <ng-template let-option pTemplate="item">
                <div>{{option.customsPurpose.customsPurposeCode}} : {{option.customsPurpose.customsPurposeName}}</div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="ui-g-12 ui-md-2">
              <span class="md-inputfield">
                <input type="text" pInputText [(ngModel)]="clearingInstruction.customsProcedureCode" readonly>
                <label>Customs Procedure Code</label>
              </span>
          </div>
          <div class="ui-g-12 ui-md-3">
            <div class="ui-inputgroup">
              <button pButton
                      type="button"
                      label="View Instruction"
                      (click)="openClearingInstructionModel()"></button>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12">
      <p-panel *ngIf="selectedInvoiceLine" [style]="{'text-align':'center'}">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="ui-g">
              <div class="ui-g-12 ui-md-4 ui-md-offset-4">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Invoice Line Details</span>
              </div>
              <div class="ui-g-12 ui-md-4" style="text-align:right">
                <div class="ui-inputgroup" style="float:right">
                  <button pButton
                            type="button"
                            label="Add Line"
                            (click)="addInvoiceLines()" [disabled]="disableButtons"></button>

                </div>
              </div>
            </div>
          </div>
        </p-header>
        <digi-invoice-lines-section
          [supplierInvoice]="selectedSupplierInvoice"
          [supplierInvoiceLine]="selectedInvoiceLine"
          [clearingInstruction]="clearingInstruction"
          [businessEntityCode]="fileType==='imports'?file.importer.code:file.exporter.code"
          [fileType]="fileType"
          [date]="fileType==='imports'?transportDocument.mawbDate:transportDocument.exchangeRateDate"
        >
        </digi-invoice-lines-section>
      </p-panel>
    </div>
  </div>
</div>
<digi-invoice-lines-duty-paid-modal
  *ngIf="display" [display]="display"
  [clearingInstruction]="clearingInstruction"
  (displayChange)="onDialogClose($event)">
</digi-invoice-lines-duty-paid-modal>
<p-dialog header="Bills Of Entry"
          [(visible)]="showDialog"
          [modal]="true"
          [responsive]="true"
          [minY]="70"
          [minWidth]="200"
          [baseZIndex]="10000"
          [contentStyle]="{'height':'600px','width':'80vw'}"
          (onHide)="onClose()">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-12">
        <p-table [columns]="boeLinesCols" [value]="lines"
                 [(selection)]="selectedLines"
                 selectionMode="multiple"
                 [resizableColumns]="true"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="color: #000" *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
              <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              <td>
                {{rowData?.lineNo}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.customsBOENo}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.cpc}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.date}}
              </td>
              <td>
                {{rowData?.partNumber}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.remainingPacks}}
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.noOfPacks" (ngModelChange)="recalculateValues(rowData)">
              </td>
              <td>
                {{rowData?.packType?.code}}
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.customsValue">
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.lineQuantity">
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="createInvoiceLines()"
            label="Confirm"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()"
            label="Cancel"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="deselectBoes()"
            label="Deselect All"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
