<div class="ui-g-12 ui-g-nopad">
  <!-- Clearing Form -->
  <div class="ui-g-12 pt-0">
    <digi-clearing-file-section step="registration"></digi-clearing-file-section>
  </div>
  <!--/Clearing Form -->
  <div class="ui-g-12 ui-g-nopad">
    <p-fieldset legend="Select Required Documents">
      <div class="ui-g">
        <div class="ui-g-12">
          <p-pickList [source]="requiredDocuments" [target]="selectedRequiredDocuments" sourceHeader="STANDARD DOCUMENT LIST" targetHeader="DOCUMENT PLANNED FOR THE SHIPMENT"
                      [responsive]="true" [showSourceControls]="false" [showTargetControls]="false"
                      dragdrop="true" [sourceStyle]="{'height':'280px'}" [targetStyle]="{'height':'280px'}">
            <ng-template pTemplate="item">
              <div class="ui-helper-clearfix">
                <div></div>
              </div>
            </ng-template>
          </p-pickList>
        </div>
      </div>
    </p-fieldset>
  </div>
<br>
  <div class="ui-g-12 ui-g-nopad">
    <p-fieldset legend="Select Documents To Produce">
      <div class="ui-g">
          <div class="ui-g-12">
              <p-pickList [source]="producedDocuments" [target]="selectedProducedDocuments" sourceHeader="DOCUMENT LIST" targetHeader="DOCUMENT PLANNED FOR THE SHIPMENT"
                          [responsive]="true" [showSourceControls]="false" [showTargetControls]="false"
                          dragdrop="true" [sourceStyle]="{'height':'280px'}" [targetStyle]="{'height':'280px'}" >
                <ng-template pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <div></div>
                  </div>
                </ng-template>
              </p-pickList>
            </div>
      </div>
    </p-fieldset>
  </div>
</div>
