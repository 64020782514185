import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {Router} from '@angular/router';
import {BillOfEntry} from '../../digi-common/models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {NotificationService} from '../../digi-common/services/notification.service';
import {ShareDataService} from '../../digi-common/services/share-data.service';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {ValidationService} from '../../digi-common/services/validation.service';

@Component({
  selector: 'digi-exbond-dashboard',
  templateUrl: './exbond-dashboard.component.html',
  styles: [`
      .ui-steps-item {
          width: 14.2%
      }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ExbondDashboardComponent implements OnInit, OnDestroy {

  exbondFile: ExBondFile;
  billOfEntry: BillOfEntry;

  items: MenuItem[];
  activeIndex = 0;

  exbondFileSubscription: Subscription;
  billOfEntrySubscription: Subscription;
  constructor(private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private billOfEntryBehaviorSubject: BillOfEntryBehaviourSubject,
              private exBondFileService: ExBondFileService,
              private messageService: NotificationService,
              private router: Router,
              private shareDataService: ShareDataService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private validationService: ValidationService) { }

  ngOnInit() {
    this.exBondFileBehaviourSubject.addExBondFile(this.shareDataService.buildExBondFile());
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exbond'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    this.exbondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile()
      .subscribe(ef => this.exbondFile = ef);

    this.billOfEntrySubscription = this.billOfEntryBehaviorSubject.getBillOfEntry()
      .subscribe(billOfEntry => this.billOfEntry = billOfEntry);

    this.items = [
      {
        label: 'Registration',
        command: (event: any) => {
          this.activeIndex = 0;
          this.router.navigate(['/exbond/register']);
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Lines',
        command: (event: any) => {
          const validationResult = this.validationService.validateRegistration(this.exbondFile, 'exbond');
          if (validationResult.isValid) {
            this.activeIndex = 1;
            if (!this.isSaveDisabled()) {
              this.exBondFileService.save(this.exbondFile).subscribe(
                _ => {
                  this.saveConfirmation();
                  this.router.navigate(['/exbond/lines']);
                },
                _ => this.activeIndex = 0
              );
            } else {
              this.router.navigate(['/exbond/lines']);
            }
          } else {
            this.activeIndex = 0;
            this.showMessages(validationResult.messages);
          }
        },
        icon: 'fa fa-file'
      },
      {
        label: 'Bills of Entry',
        command: (event: any) => {
          this.activeIndex = 2;
          if (!this.isSaveDisabled()) {
            this.exBondFileService.save(this.exbondFile).subscribe(
              _ => {
                this.saveConfirmation();
                this.router.navigate(['/exbond/generate']);
              },
              _ => this.activeIndex = 1
            );
            this.saveConfirmation();
          } else {
            this.router.navigate(['/exbond/generate']);
          }

        },
        icon: 'fa fa-fw fa-support'
      },
      {
        label: 'Financials',
        command: (event: any) => {
          this.activeIndex = 3;
          if (!this.isSaveDisabled()) {
            this.exBondFileService.save(this.exbondFile).subscribe(
              _ => {
                this.saveConfirmation();
                this.router.navigate(['/exbond/financials']);
              },
              _ => this.activeIndex = 1
            );
            this.saveConfirmation();
          } else {
            this.router.navigate(['/exbond/financials']);
          }

        },
        icon: 'fa fa-fw fa-support'
      }
    ];
  }

  saveConfirmation(): void {
    this.messageService.successNotify('File Saved Successfully');
  }

  isSaveDisabled(): boolean {
    return !!(this.billOfEntry && this.billOfEntry.dateEdiSubmitted);
  }

  ngOnDestroy(): void {
    this.exbondFileSubscription.unsubscribe();
    this.billOfEntrySubscription.unsubscribe();
  }

  showMessages(messages: string[]): void {
    this.messageService.errorNotify('Validation Failed', messages);
  }

}
