import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ExBondBillOfEntryService = /** @class */ (function () {
    function ExBondBillOfEntryService(http, config) {
        this.http = http;
        this.config = config;
    }
    ExBondBillOfEntryService.prototype.generateBOE = function (billsOfEntry, exBondFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
            billsOfEntry.boes[0].exBondFile = exBondFile._links.self.href;
            return this.http.put(billsOfEntry.boes[0]._links.self.href, JSON.stringify(billsOfEntry.boes[0]), { headers: headers }).pipe(map(function (response) { return response['_embedded'].exBondBillOfEntries; }));
        }
        else {
            if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].exBondFile) {
                billsOfEntry.boes[0].exBondFile = exBondFile._links.self.href;
            }
            return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles/' + exBondFile.id + '/exBondBillOfEntries/generate', { headers: headers }).pipe(map(function (response) { return response['_embedded'].exBondBillOfEntries; }));
        }
    };
    ExBondBillOfEntryService.prototype.regenerateBOE = function (exBondFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + 'api/exbondFiles/' + exBondFile.id + '/exBondBillOfEntries/regenerate', { headers: headers }).pipe(map(function (response) { return response['_embedded'].exBondBillOfEntries; }));
    };
    ExBondBillOfEntryService.prototype.findPreviousBillOfEntry = function (lrn, sequence, isManuallySubmitted) {
        return this.http.get(this.config.filesApiEndpoint + "api/exBondBillOfEntries/search/findByLrnAndSequenceNumberAndMarkedAsSubmitted?lrn="
            + lrn + '&sequenceNumber=' + sequence + '&isManuallySubmitted=' + isManuallySubmitted);
    };
    ExBondBillOfEntryService.prototype.findParentExBondFile = function (billId, fileType) {
        return this.http.get(this.config.filesApiEndpoint + "api/exbondFileCorrections/" + billId + '/parent');
    };
    ExBondBillOfEntryService.prototype.findBillOfEntryById = function (billId) {
        return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/" + billId);
    };
    ExBondBillOfEntryService.prototype.saveBillOfEntry = function (billOfEntry, exBondFile) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (billOfEntry._links && billOfEntry._links.self) {
            billOfEntry.exBondFile = exBondFile._links.self.href;
            var url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
            if (billOfEntry.cusdecs) {
                billOfEntry.cusdecs = billOfEntry.cusdecs.filter(function (cusdec) { return cusdec !== null; });
            }
            return this.http.put(url, JSON.stringify(billOfEntry), { headers: headers });
        }
    };
    ExBondBillOfEntryService.prototype.loadFiles = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/document/" + id);
    };
    ExBondBillOfEntryService.prototype.loadUploadedFile = function (billOfEntry) {
        return this.http
            .get("" + billOfEntry._links.files.href);
    };
    ExBondBillOfEntryService.prototype.uploadFiles = function (files, billOfEntry) {
        var data = new FormData();
        data.append('file', files[0]);
        var headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });
        return this.http.post("" + billOfEntry._links.files.href, data, { headers: headers });
    };
    ExBondBillOfEntryService.prototype.retrieveBillOfEntry = function (currentBillOfEntry) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
        return this.http.get(url);
    };
    ExBondBillOfEntryService.prototype.findBillOfEntryByExBondFile_id = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/exBondBillOfEntries/search/findByExBondFile_Id?id=" + id).pipe(map(function (response) { return response['_embedded'].exBondBillOfEntries; }));
    };
    ExBondBillOfEntryService.prototype.findAllEdiMessages = function (page, size, indicator) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 10; }
        return this.http.get(this.config.filesApiEndpoint + "api/exBondBillOfEntries?projection=onlyEdiMessages&page=" + page + "&size=" + size + "&sort=dateEdiSubmitted,desc");
    };
    ExBondBillOfEntryService.ngInjectableDef = i0.defineInjectable({ factory: function ExBondBillOfEntryService_Factory() { return new ExBondBillOfEntryService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ExBondBillOfEntryService, providedIn: "root" });
    return ExBondBillOfEntryService;
}());
export { ExBondBillOfEntryService };
