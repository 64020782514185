import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { EMPTY, of } from 'rxjs';
import { Address, RebateUser } from '../models/file';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var RebateUserService = /** @class */ (function () {
    function RebateUserService(http, config) {
        this.http = http;
        this.config = config;
    }
    RebateUserService.prototype.findRebateUserByCode = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + code + '&roleType=9' + '&projection=flattenedRebateUser')
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    RebateUserService.prototype.findRebateUserFromClearingInstructionAndRebateUsers = function (clearingInstruction, rebateUsers) {
        if (clearingInstruction) {
            if (rebateUsers.length === 0) {
                return this.returnDefault(clearingInstruction);
            }
            else if (clearingInstruction.rebateUser.code) {
                return this.findRebateUserFromClearingInstruction(clearingInstruction, clearingInstruction.rebateUser, false);
            }
            else if (rebateUsers && rebateUsers.length === 1) {
                return this.findRebateUserFromClearingInstruction(clearingInstruction, rebateUsers[0]);
            }
            return this.returnDefault(clearingInstruction);
        }
        return EMPTY;
    };
    RebateUserService.prototype.returnDefault = function (clearingInstruction) {
        var rebateUser = new RebateUser();
        rebateUser.address = new Address();
        if (clearingInstruction) {
            clearingInstruction.rebateUser = rebateUser;
        }
        return of([rebateUser]);
    };
    RebateUserService.prototype.findRebateUserFromClearingInstruction = function (clearingInstruction, rebateUser, updateRebateUser) {
        if (updateRebateUser === void 0) { updateRebateUser = true; }
        if (clearingInstruction) {
            return this.findRebateUserByCode(rebateUser.code).pipe(tap(function (rebateUserData) {
                if (updateRebateUser) {
                    clearingInstruction.rebateUser = new RebateUser();
                    clearingInstruction.rebateUser.name = rebateUser.name;
                    clearingInstruction.rebateUser.code = rebateUser.code;
                    clearingInstruction.rebateUser.label = rebateUser.label;
                    clearingInstruction.rebateUser.customsCode = rebateUserData[0].customsCode;
                }
                if (rebateUserData && (!clearingInstruction.rebateUser.address
                    || !clearingInstruction.rebateUser.address.line1)) {
                    var rebateUserAddresses = rebateUserData[0].addresses;
                    var rebateUserAddress = void 0;
                    if (rebateUserAddresses.length === 1) {
                        rebateUserAddress = rebateUserAddresses[0];
                    }
                    else {
                        rebateUserAddress = rebateUserAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (rebateUserAddress) {
                        clearingInstruction.rebateUser.address = new Address();
                        clearingInstruction.rebateUser.address.line1 = rebateUserAddress.line1;
                        clearingInstruction.rebateUser.address.line2 = rebateUserAddress.line2;
                        clearingInstruction.rebateUser.address.line3 = rebateUserAddress.line3;
                        clearingInstruction.rebateUser.address.line4 = rebateUserAddress.line4;
                        clearingInstruction.rebateUser.address.zipCode = rebateUserAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return this.returnDefault(clearingInstruction);
        }
    };
    RebateUserService.ngInjectableDef = i0.defineInjectable({ factory: function RebateUserService_Factory() { return new RebateUserService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: RebateUserService, providedIn: "root" });
    return RebateUserService;
}());
export { RebateUserService };
