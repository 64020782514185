import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BillOfEntry, BillOfEntryLine, SchedulePart } from '../../models/billOfEntries';
import { SchedulePartService } from '../../services/schedule-part.service';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ShareDataService } from '../../services/share-data.service';
import { ScheduleBehaviourSubject } from '../../../../subjects/schedule-behaviour-subject';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-boe-line-schedule-amount',
  templateUrl: './digi-boe-line-schedule-amount.component.html',
  styleUrls: ['./digi-boe-line-schedule-amount.component.scss']
})
export class DigiBoeLineScheduleAmountComponent implements OnInit, OnDestroy {
  selectedBillOfEntryLine: BillOfEntryLine;
  display = false;
  scheduleParts: SchedulePart[];
  cols: any[];
  selectedSchedules: SchedulePart[];
  billOfEntry: BillOfEntry;
  billSubscription: Subscription;
  selectedSchedule: SchedulePart;

  constructor(
    private schedulePartService: SchedulePartService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private shareDataService: ShareDataService,
    private scheduleBehaviourSubject: ScheduleBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(boe => {
      this.billOfEntry = boe;
    });
    this.billSubscription = this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
        if (this.selectedBillOfEntryLine && this.selectedBillOfEntryLine.additionalSchedules
          && this.selectedBillOfEntryLine.additionalSchedules.length > 0) {
          this.populateSelectedSchedule();
        } else {
          this.selectedSchedule = new SchedulePart();
        }
        this.updateDisplay();
      });
  }

  private populateSelectedSchedule() {
    this.selectedSchedule = new SchedulePart();
    if (this.selectedBillOfEntryLine.additionalSchedules.length) {
      this.selectedSchedule = this.selectedBillOfEntryLine.additionalSchedules[0];
      this.selectedSchedules = [...this.selectedBillOfEntryLine.additionalSchedules];
    }
  }

  private updateDisplay() {
    this.schedulePartService.getScheduleParts().subscribe(scheduleParts => {
      this.scheduleParts = scheduleParts;
      if (this.selectedSchedules) {
        this.selectedSchedules.forEach(s => {
          const found = this.scheduleParts.find(schedulePart => schedulePart.code === s.code);
          if (found) {
            found.value = s.value;
          }
        });
      }
    });
  }

  addAdditionalSchedule() {
    this.display = true;
    this.cols = [
      {field: '', header: '', width: '5%'},
      {field: 'Code', header: 'Code', width: '15%'},
      {field: 'Description', header: 'Description', width: '50%'},
      {field: 'value', header: 'Value', width: '25%'}
    ];
  }

  select() {
    this.display = false;
    this.selectedBillOfEntryLine.additionalSchedules = [...this.selectedSchedules];
  }

  close() {
    this.populateSelectedSchedule();
    this.updateDisplay();
    this.display = false;
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }
}
