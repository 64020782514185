import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Haulier} from '../../models/business-entity';

@Component({
  selector: 'digi-haulier-role',
  templateUrl: './haulier-role.component.html',
  styleUrls: ['./haulier-role.component.scss']
})
export class HaulierRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() haulier: Haulier;
  constructor() { }

  ngOnInit() {
  }

}
