<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Delivery Instruction" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <p-toolbar class="toolbar">
          <div class="ui-toolbar-group-right">
            <button pButton type="button" label="Print"
                    icon="fa fa-print"
                    (click)="print()"></button>
            <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveClearingFile()"></button>
          </div>
        </p-toolbar>
        <digi-report-file title="Delivery Instruction" [reportFile]="deliveryInstruction" [clearingFile]="clearingFile" [transportMethod]="transportMethod.method"></digi-report-file>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="File Cover">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <p-toolbar class="toolbar">
          <div class="ui-toolbar-group-right">
            <button pButton type="button" label="Print"
                    icon="fa fa-print"
                    (click)="print()"></button>
            <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveClearingFile()"></button>
          </div>
        </p-toolbar>
        <digi-report-file title="File Cover" [reportFile]="fileCover" [clearingFile]="clearingFile" [transportMethod]="transportMethod.method"></digi-report-file>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Provisional Payment">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <p-toolbar class="toolbar">
          <div class="ui-toolbar-group-right">
            <button pButton type="button" label="Print"
                    icon="fa fa-print"
                    (click)="print()"></button>
            <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveClearingFile()"></button>
          </div>
        </p-toolbar>
        <digi-payment-file title="Provisional Payment" [paymentFile]="provisionalPayment" [clearingFile]="clearingFile"></digi-payment-file>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Clearing Instruction">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <p-toolbar class="toolbar">
          <div class="ui-toolbar-group-right">
            <button pButton type="button" label="Print"
                    icon="fa fa-print"
                    (click)="print()"></button>
            <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveClearingFile()"></button>
          </div>
        </p-toolbar>
        <digi-clearing-instruction-file title="Clearing Instruction" [clearingInstructionFile]="clearingInstruction" [clearingFile]="clearingFile" [transportMethod]="transportMethod.method"></digi-clearing-instruction-file>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
