import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {ManifestService} from '../../services/manifest.service';
import {RoadManifest, RoadManifestCorrection} from '../../models/roadManifest';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-manifest-sequence-modal',
  templateUrl: './manifest-sequence-modal.component.html',
  styleUrls: ['./manifest-sequence-modal.component.scss']
})
export class ManifestSequenceModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input('display') display;
  @Input() roadManifest: RoadManifest;
  @Output() displayChange = new EventEmitter();
  @Output() onUpdateRoadManifest = new EventEmitter();
  filteredFiles: SelectItem[] = [];
  selectedSequence: any;

  manifestServiceSubscription: Subscription;

  constructor(
    private manifestService: ManifestService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest && changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
      if (this.roadManifest.manifestFileNo) {
        this.findSequenceNumbers(this.roadManifest.manifestFileNo);
      }
    }
  }

  ngOnDestroy() {
    if (this.manifestServiceSubscription) {
      this.manifestServiceSubscription.unsubscribe();
    }
  }

  findSequenceNumbers(manifestFileNo) {
    const copyOfFilteredSequences = [];
    this.manifestServiceSubscription = this.manifestService.findAllSequences(manifestFileNo).subscribe(
      data => {
        data.forEach(function (value) {
          const manifestFile: SelectItem = new class implements SelectItem {
            label: string;
            value: number;
          };
          manifestFile.label = value.manifestFileNo;
          manifestFile.value = value.sequence;
          copyOfFilteredSequences.push(manifestFile);
        });
        this.filteredFiles = [...copyOfFilteredSequences];
      });
  }

  findSelectedSequenceNumber() {
    if (!this.selectedSequence) {
      this.selectedSequence = 0;
    }
    this.manifestServiceSubscription = this.manifestService
      .findByManifestFileNoAndSequence(this.roadManifest.manifestFileNo, this.selectedSequence)
      .subscribe(data => {
        const parent: RoadManifest = data[0];
        const correction = new RoadManifestCorrection();
        correction.type = 'RoadManifestCorrection';
        correction.parent = parent._links.self.href;
        correction.waybills = parent.waybills;
        correction.transporter = parent.transporter;
        correction.masterTransportDocDate = parent.masterTransportDocDate;
        correction.arrivalDate = parent.arrivalDate;
        correction.customOffice = parent.customOffice;
        correction.localAgent = parent.localAgent;
        correction.exitPort = parent.exitPort;
        correction.voyageDetails = parent.voyageDetails;
        correction.destination = parent.destination;
        correction.countryOfExport = parent.countryOfExport;
        correction.crew = parent.crew;
        correction.manifestFileNo = parent.manifestFileNo;
        correction.manifestNo = parent.manifestNo;
        correction.cuscarType = parent.cuscarType;
        correction.cargoType = parent.cargoType;
        correction.manifestType = parent.manifestType;
        correction.transportMethodCode = parent.transportMethodCode;
        const max = Math.max.apply(Math, this.filteredFiles.map(function (o) {
          return o.value;
        }));
        correction.sequence = max + 1;
        this.onUpdateRoadManifest.emit(correction);
      });
  }

  onClose() {
    this.displayChange.emit(false);
  }
}
