import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class InvoiceSectionService {

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) { }

  findInvoiceSection(value: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/invoiceSections/search/descStartsWith?desc=` + value)
      .pipe(
        map(response => response['_embedded'].invoiceSections)
      );
  }

  findInvoiceSubSectionsByInvoiceSectionId(id: number, value: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/invoiceSubSections/search/findByInvoiceSectionIDAndDesc?invoiceSectionID=${id}&invoiceSubSectionDesc=${value}&projection=InvoiceSubSectionDescription`)
      .pipe(
        map(response => response['_embedded'].invoiceSubSections)
      );
  }
}
