import {Injectable, Inject} from '@angular/core';
import {EMPTY, empty, Observable, of} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Address, Country, Currency, PurchaseTerm, Supplier, SupplierInvoice} from '../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {CountryService} from './country.service';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private countryService: CountryService,
              private http: HttpClient,
              private messageService: NotificationService,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findSupplierByCode(foundSupplier: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + foundSupplier + '&roleType=21' + '&projection=flattenedSupplier')
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findSupplierFromSupplierInvoiceAndSuppliers(supplierInvoice: SupplierInvoice, suppliers: Supplier[]) {
    if (supplierInvoice.supplier.code) { // Load the supplier from the invoice
      return this.findSupplierFromSupplierInvoice(supplierInvoice, supplierInvoice.supplier, false);
    }
    if (suppliers && suppliers.length === 1) { // Load the default supplier if any
      return this.findSupplierFromSupplierInvoice(supplierInvoice, suppliers[0]);
    }
    supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
    supplierInvoice.defaultValues.rooNumber = undefined;
    this.updateCountry(new Country());
    this.updateCurrency(new Currency());
    return EMPTY;
  }

  findSupplierFromSupplierInvoice(supplierInvoice: SupplierInvoice, supplier: Supplier, updateSupplier: boolean = true) {
    if (supplierInvoice) {

      return this.findSupplierByCode(supplier.code).pipe(
        tap(supplierData => {
          if (updateSupplier) {
            supplierInvoice.supplier = new Supplier();
            supplierInvoice.supplier.vdnWarning = supplier.vdnWarning;
            supplierInvoice.supplier.vdnNumber = supplier.vdnNumber;
            supplierInvoice.supplier.vdnMarkup = supplier.vdnMarkup;
            supplierInvoice.supplier.customsCode = supplier.customsCode;
            supplierInvoice.supplier.label = supplier.label;
            supplierInvoice.supplier.name = supplier.name;
            supplierInvoice.supplier.code = supplier.code;
            supplierInvoice.supplier.defaultValuationCode = supplier.defaultValuationCode;
            supplierInvoice.supplier.defaultValuationMethod = supplier.defaultValuationMethod;
            console.log(supplierInvoice.supplier);
            console.log(supplierData);
            if (supplierData[0].country) {
              supplierInvoice.defaultValues.country.name = supplierData[0].country.name;
              supplierInvoice.defaultValues.country.code = supplierData[0].country.code;
              supplierInvoice.defaultValues.country.label = supplierData[0].country.label;
            }
            if (supplierData[0].currency) {
              supplierInvoice.currency.code = supplierData[0].currency.code;
              supplierInvoice.currency.name = supplierData[0].currency.name;
              supplierInvoice.currency.label = supplierData[0].currency.label;
              supplierInvoice.currency = Object.assign({}, supplierInvoice.currency);
            }
            if (supplier.defaultPurchaseTerm) {
              supplierInvoice.defaultValues.purchaseTerm.description = supplier.defaultPurchaseTerm.description;
              supplierInvoice.defaultValues.purchaseTerm.code = supplier.defaultPurchaseTerm.code;
              supplierInvoice.defaultValues.purchaseTerm.label = supplier.defaultPurchaseTerm.label;
              supplierInvoice.defaultValues.purchaseTerm = Object.assign({}, supplier.defaultPurchaseTerm);
            }
            if (supplierData[0].rooNumber) {
              supplierInvoice.defaultValues.rooNumber = supplierData[0].rooNumber;
            } else {
              supplierInvoice.defaultValues.rooNumber = null;
            }
          }
          if (supplierData && (!supplierInvoice.supplier.address || !supplierInvoice.supplier.address.line1)) {
            const supplierAddresses = supplierData[0].addresses;
            let supplierAddress;
            if (supplierAddresses.length === 1) {
              supplierAddress = supplierAddresses[0];
            } else {
              supplierAddress = supplierAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'CUS';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (supplierAddress) {
              supplierInvoice.supplier.address = new Address();
              supplierInvoice.supplier.address.line1 = supplierAddress.line1;
              supplierInvoice.supplier.address.line2 = supplierAddress.line2;
              supplierInvoice.supplier.address.line3 = supplierAddress.line3;
              supplierInvoice.supplier.address.line4 = supplierAddress.line4;
              supplierInvoice.supplier.address.zipCode = supplierAddress.zipCode;
            }
          }
          this.loadDefaultsForSupplier(supplierInvoice);
        }));
    } else {
      supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
      supplierInvoice.defaultValues.rooNumber = undefined;
      this.updateCountry(new Country());
      this.updateCurrency(new Currency());
      return EMPTY;
    }
  }

  loadDefaultsForSupplier(supplierInvoice: SupplierInvoice) {
    if (supplierInvoice.defaultValues.country && supplierInvoice.defaultValues.country.code) {
      this.countryService.getCountry(supplierInvoice.defaultValues.country.code).subscribe(data => {
          supplierInvoice.defaultValues.country.tradeAgreements = data['tradeAgreements'];
          supplierInvoice.defaultValues.country.name = data['name'];
          supplierInvoice.defaultValues.country.code = data['code'];
          supplierInvoice.defaultValues.country.label = data['label'];
          supplierInvoice.defaultValues.country.defaultTradeAgreement = data['defaultTradeAgreement'];
        },
        (error) => {
          this.messageService.errorNotify(error);
        },
        () => {
          this.updateCountry(supplierInvoice.defaultValues.country);
        });
    }
    if (supplierInvoice.currency) {
      this.updateCurrency(supplierInvoice.currency);
    }
  }

  updateCountry(country: Country) {
    this.supplierInvoiceBehaviourSubject.addCountry(country);
  }

  updateCurrency(currency: Currency) {
    this.supplierInvoiceBehaviourSubject.addCurrency(currency);
  }
}
