import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class Da490ReportService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  generateDA490Report(): Observable<Blob> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/da490`, {responseType: 'blob'});
  }

}
