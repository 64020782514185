import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ScheduleBehaviourSubject = /** @class */ (function () {
    function ScheduleBehaviourSubject() {
        this.scheduleBehaviourSubject = new BehaviorSubject(null);
    }
    ScheduleBehaviourSubject.prototype.addSchedulePart = function (supplierInvoice) {
        this.scheduleBehaviourSubject.next(supplierInvoice);
    };
    ScheduleBehaviourSubject.prototype.getSchedulePart = function () {
        return this.scheduleBehaviourSubject.asObservable();
    };
    ScheduleBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function ScheduleBehaviourSubject_Factory() { return new ScheduleBehaviourSubject(); }, token: ScheduleBehaviourSubject, providedIn: "root" });
    return ScheduleBehaviourSubject;
}());
export { ScheduleBehaviourSubject };
