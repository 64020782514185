import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ClearingFile} from '../../digi-common/models/clearingFile';
import {ExportFile} from '../../digi-common/models/exportFile';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {BillOfEntry, ExBondBillOfEntry, ExportBillOfEntry} from '../../digi-common/models/billOfEntries';
import {Subscription} from 'rxjs';
import {FileType} from '../../digi-common/models/enumerations';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondBillOfEntryService} from '../services/ex-bond-bill-of-entry.service';
import {NotificationService} from '../../digi-common/services/notification.service';

@Component({
  selector: 'digi-exbond-boe-file-section',
  templateUrl: './boe-file-section.component.html',
  styleUrls: ['./boe-file-section.component.scss']
})
export class BoeFileSectionComponent implements OnInit, OnDestroy {
  file: ClearingFile|ExportFile|ExBondFile;
  billOfEntry: BillOfEntry|ExportBillOfEntry|ExBondBillOfEntry;
  @Output()
  selectedClearingFile: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  billOfEntrySubscription: Subscription;
  @Input() fileType: FileType;


  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private exBondBillOfEntryService: ExBondBillOfEntryService,
              private messageService: NotificationService) {
  }

  ngOnInit() {
    this.subscription = this.exBondFileBehaviourSubject
      .getExBondFile()
      .subscribe(ef => this.file = ef);
    this.billOfEntrySubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => this.billOfEntry = boe);
  }

  saveBillOfEntry() {
    this.exBondBillOfEntryService.saveBillOfEntry(this.billOfEntry, <ExBondFile>this.file).subscribe(data => {
      this.billOfEntryBehaviourSubject.addBillOfEntry(data);
      this.addSuccess('Bill Of Entry Saved');
    }, error => this.addFailure(error));
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  addSuccess(msg: any) {
    this.messageService.successNotify(
      'Success',
      msg
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.billOfEntrySubscription.unsubscribe();
  }
}
