import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExportBillOfEntry} from '../../../models/billOfEntries';
import {combineLatest, EMPTY, Subscription, throwError} from 'rxjs';
import {BillOfEntryBehaviourSubject} from '../../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryService} from '../../../services/bill-of-entry.service';
import {catchError} from 'rxjs/operators';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ExportFileService} from '../../../services/export-file.service';
import {ExportFile} from '../../../models/exportFile';

@Component({
  selector: 'digi-exports-generate',
  templateUrl: './exports-generate.component.html',
  styleUrls: ['./exports-generate.component.scss']
})
export class ExportsGenerateComponent implements OnInit, OnDestroy {
  exportFile: ExportFile;
  selectedBillOfEntry: ExportBillOfEntry;
  subscription: Subscription;
  files: any[];

  constructor(private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private exportFileService: ExportFileService,
              private billOfEntryService: BillOfEntryService
  ) {
  }

  ngOnInit() {
    const exportFileObservable = this.exportFileBehaviourSubject.getExportFile();
    const billOfEntryObservable = this.billOfEntryBehaviourSubject.getBillOfEntry();
    this.subscription = combineLatest(exportFileObservable, billOfEntryObservable).subscribe(([exportFile, billOfEntry]) => {
      this.exportFile = exportFile;
      this.selectedBillOfEntry = billOfEntry;
    });
  }

  loadUploadedFiles(event, tabview) {
    if (event.index === 2) {
      if (this.exportFile && this.selectedBillOfEntry) {
        this.billOfEntryService.loadUploadedFile(this.selectedBillOfEntry)
          .pipe(
            catchError(err => {
              console.log(err.status);
              if (err.status !== 404) {
                return throwError(err);
              }
              return EMPTY;
            })
          )
          .subscribe(uploadedFiles => {
            if (uploadedFiles && uploadedFiles['_embedded']) {
              this.files = [];
              this.files.push(...uploadedFiles._embedded.supportingDocuments);
              this.files = [...this.files];
            }
          });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
