<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu" (activeIndexChange)="handleIndexChange($event)">
    <p-tabPanel header="Company" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCompany()"></button>
            </div>
          </p-toolbar>
          <div class="ui-g-4 ui-md-4">
            <p-panel header="Company Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="company.name"
                                       pInputText class="ui-inputgroup"
                                       id="name">
                                <label for="name">Name</label>
                              </span>
                            </div>
                          </span>
                      </div>
                      <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="company.code"
                                       pInputText class="ui-inputgroup"
                                       id="code">
                                <label for="code">Code</label>
                              </span>
                            </div>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4 ui-md-4">
            <p-panel header="Company Defaults">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="company.companyDefaults.atv"
                                       pInputText class="ui-inputgroup"
                                       id="atv">
                                <label for="atv">ATV</label>
                              </span>
                            </div>
                          </span>
                      </div>
                      <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="company.companyDefaults.vat"
                                       pInputText class="ui-inputgroup"
                                       id="vat">
                                <label for="vat">VAT</label>
                              </span>
                            </div>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4 ui-md-4">
            <p-panel header="Company Logo">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div>
                        <label for="file-upload" pButton>
                          <i class="fa fa-plus"></i> Choose logo
                        </label>
                        <input id="file-upload" type="file" (change)="selectFiles($event)" accept="image/*"/>
                      </div>
                      <div class="ui-g-12 ui-md-12 logo" [style.background-image]="logo"></div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Branches">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" class="ui-button-secondary" label="New" icon="fa fa-file" (click)="createBranch()"></button>
            </div>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCompany()"></button>
            </div>
          </p-toolbar>
          <div class="ui-g-12 ui-md-12">
            <p-table [columns]="cols" [value]="company.branches" selectionMode="single"
                     (onRowSelect)="selectBranch($event.data)"
                     columnResizeMode="expand"
                     [(selection)]="branch" [paginator]="true" [rows]="5">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">{{col.header}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
                <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                  <td>{{selection.code}}</td>
                  <td>{{selection.name}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Branch Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.name"
                                       pInputText class="ui-inputgroup"
                                       id="branchName">
                                <label for="branchName">Name</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.code"
                                       pInputText class="ui-inputgroup"
                                       id="branchCode">
                                <label for="branchCode">Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.invoiceDetails.regNo"
                                       pInputText class="ui-inputgroup"
                                       id="regNo">
                                <label for="regNo">Reg. No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.invoiceDetails.vatNo"
                                       pInputText class="ui-inputgroup"
                                       id="vatNo">
                                <label for="vatNo">VAT No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.invoiceDetails.email"
                                       pInputText class="ui-inputgroup"
                                       id="email">
                                <label for="email">Email</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.invoiceDetails.fax"
                                       pInputText class="ui-inputgroup"
                                       id="fax">
                                <label for="fax">Fax</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.invoiceDetails.tel"
                                       pInputText class="ui-inputgroup"
                                       id="tel">
                                <label for="tel">Tel</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.emailForEdi"
                                       pInputText class="ui-inputgroup"
                                       id="emailForEdi">
                                <label for="emailForEdi">Email Address for EDI</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.companyWebsite"
                                       pInputText class="ui-inputgroup"
                                       id="companyWebsite">
                                <label for="companyWebsite">Website</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Physical Address">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.physicalAddress.line1"
                                       pInputText class="ui-inputgroup"
                                       id="line1">
                                <label for="line1">Line 1</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.physicalAddress.line2"
                                       pInputText class="ui-inputgroup"
                                       id="line2">
                                <label for="line2">Line 2</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.physicalAddress.line3"
                                       pInputText class="ui-inputgroup"
                                       id="line3">
                                <label for="line3">Line 3</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.physicalAddress.line4"
                                       pInputText class="ui-inputgroup"
                                       id="line4">
                                <label for="line4">Line 4</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.physicalAddress.zipCode"
                                       pInputText class="ui-inputgroup"
                                       id="zip">
                                <label for="zip">Zip Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Postal Address">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.postalAddress.line1"
                                       pInputText class="ui-inputgroup"
                                       id="line1Postal">
                                <label for="line1Postal">Line 1</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.postalAddress.line2"
                                       pInputText class="ui-inputgroup"
                                       id="line2Postal">
                                <label for="line2Postal">Line 2</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.postalAddress.line3"
                                       pInputText class="ui-inputgroup"
                                       id="line3Postal">
                                <label for="line3Postal">Line 3</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.postalAddress.line4"
                                       pInputText class="ui-inputgroup"
                                       id="line4Postal">
                                <label for="line4Postal">Line 4</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       [(ngModel)]="branch.invoiceDetails.postalAddress.zipCode"
                                       pInputText class="ui-inputgroup"
                                       id="zipPostal">
                                <label for="zipPostal">Zip Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Branch Defaults">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.localAgentCode"
                                       pInputText class="ui-inputgroup"
                                       id="localAgentCode">
                                <label for="localAgentCode">Default Local Agent Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.forwardingLocalAgentCode"
                                       pInputText class="ui-inputgroup"
                                       id="forwardingLocalAgentCode">
                                <label for="forwardingLocalAgentCode">Default Forwarding Local Agent Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.exporterCode"
                                       pInputText class="ui-inputgroup"
                                       id="exporterCode">
                                <label for="exporterCode">Default Exporter Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.importerCode"
                                       pInputText class="ui-inputgroup"
                                       id="importerCode">
                                <label for="importerCode">Default Importer Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.exitPortCode"
                                       pInputText class="ui-inputgroup"
                                       id="exitPortCode">
                                <label for="exitPortCode">Default Exit Port Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.landingOrderAccountNo"
                                       pInputText class="ui-inputgroup"
                                       id="landingOrderAccountNo">
                                <label for="landingOrderAccountNo">Landing Order Account No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.shippingOrderAccountNo"
                                       pInputText class="ui-inputgroup"
                                       id="shippingOrderAccountNo">
                                <label for="shippingOrderAccountNo">Shipping Order Account No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.freightCurrency"
                                       pInputText class="ui-inputgroup"
                                       id="freightCurrency">
                                <label for="freightCurrency">Freight Currency Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.freightAmount"
                                       pInputText class="ui-inputgroup"
                                       id="freightAmount">
                                <label for="freightAmount">Freight Amount</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.packageType"
                                       pInputText class="ui-inputgroup"
                                       id="packageType">
                                <label for="packageType">Package Type</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="branch.freightCollectPrepaid"
                                       pInputText class="ui-inputgroup"
                                       id="freightCollectPrepaid">
                                <label for="freightCollectPrepaid">Freight Collect/Prepaid Code</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <p-checkbox [(ngModel)]="branch.invoicingInUse" label="Invoicing In Use"
                                        binary="true"></p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <p-checkbox [(ngModel)]="branch.defaultSundryChargeNonDutiable" label="Sundry Charge Non-dutiable"
                                        binary="true"></p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <p-checkbox [(ngModel)]="branch.defaultQuantityTypeDisplay" label="Display Line Quantity Type"
                                        binary="true"></p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <p-checkbox [(ngModel)]="branch.customsValueInUse" label="Customs Value In Use"
                                        binary="true"></p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <p-checkbox [(ngModel)]="branch.defaultClearingInstruction" label="Default DP Clearing Instruction"
                                        binary="true"></p-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-8 ui-md-8">
              <div class="ui-g-12 ui-md-12">
                <p-table [columns]="customsOfficeDefaultsCols"
                         [value]="branch.customsOfficeDefaults"
                         selectionMode="single"
                         [(selection)]="customsOfficeDefault"
                         [paginator]="true"
                         [rows]="5">
                  <ng-template pTemplate="caption">
                    Customs Office Defaults
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <td>
                        {{rowData.transportMethod?.method}}
                      </td>
                      <td>
                        {{rowData.declarationType}}
                      </td>
                      <td style="text-transform: capitalize">
                        {{rowData.customsOfficeCode}}
                      </td>
                      <td>
                        <button pButton type="button" icon="fa fa-times" class="danger"
                                (click)="deleteCustomsOfficeDefault(rowIndex)" pTooltip="Delete"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left">
                      <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAddCustomsOfficeDefault()" label="Add"></button>
                    </div>
                  </ng-template>
                </p-table>
              </div>
              <div class="ui-g-12 ui-md-12">
                <p-table [columns]="bankingDetailsCols"
                         [value]="branch.invoiceDetails.bankingDetails"
                         selectionMode="single"
                         [(selection)]="bankingDetails"
                         [paginator]="true"
                         [rows]="5">
                  <ng-template pTemplate="caption">
                    Banking Details
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <td>
                        {{rowData.bank}}
                      </td>
                      <td>
                        {{rowData.branchCode}}
                      </td>
                      <td>
                        {{rowData.accountNo}}
                      </td>
                      <td>
                        <button pButton type="button" icon="fa fa-times" class="danger"
                                (click)="deleteBankingDetails(rowIndex)" pTooltip="Delete"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left">
                      <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAddBankingDetails()" label="Add"></button>
                    </div>
                  </ng-template>
                </p-table>
              </div>
              <div class="ui-g-12 ui-md-12">
                <p-table [columns]="genLedgerAccDetailsCols"
                         [value]="branch.invoiceDetails.genLedgerAccDetails"
                         selectionMode="single"
                         [(selection)]="genLedgerAccDetails"
                         [paginator]="true"
                         [rows]="5">
                  <ng-template pTemplate="caption">
                    General Ledger Account Details
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <td>
                        {{rowData.chargeType.code}}
                      </td>
                      <td>
                        {{rowData.genLedgerAccNo}}
                      </td>
                      <td>
                        <button pButton type="button" icon="fa fa-times" class="danger"
                                (click)="deleteGenLedgerAccDetails(rowIndex)" pTooltip="Delete"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left">
                      <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAddGenLedgerAccDetails()" label="Add"></button>
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <p-table [columns]="invoiceLinesCols"
                     [value]="branch.invoiceLines"
                     selectionMode="single"
                     [(selection)]="invoiceLine"
                     (onRowSelect)="selectDefaultInvoiceItem($event)"
                     [paginator]="true"
                     [rows]="5">
              <ng-template pTemplate="caption">
                Default Invoice Items
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td>
                    {{rowData.chargeType.code}}
                  </td>
                  <td>
                    {{rowData.tranType.code}}
                  </td>
                  <td>
                    {{rowData.taxType.code}}
                  </td>
                  <td>
                    {{rowData.invoiceSection.description}}
                  </td>
                  <td>
                    {{rowData.invoiceSubSection}}
                  </td>
                  <td>
                    <p-checkbox name="forAirImports"
                                binary="true"
                                [(ngModel)]="rowData.forAirImports">
                    </p-checkbox>
                  </td>
                  <td>
                    <p-checkbox name="forAirExports"
                                binary="true"
                                [(ngModel)]="rowData.forAirExports">
                    </p-checkbox>
                  </td>
                  <td>
                    <p-checkbox name="forSeaImports"
                                binary="true"
                                [(ngModel)]="rowData.forSeaImports">
                    </p-checkbox>
                  </td>
                  <td>
                    <p-checkbox name="forSeaExports"
                                binary="true"
                                [(ngModel)]="rowData.forSeaExports">
                    </p-checkbox>
                  </td>
                  <td>
                    <p-checkbox name="forRoadImports"
                                binary="true"
                                [(ngModel)]="rowData.forRoadImports">
                    </p-checkbox>
                  </td>
                  <td>
                    <p-checkbox name="forRoadExports"
                                binary="true"
                                [(ngModel)]="rowData.forRoadExports">
                    </p-checkbox>
                  </td>
                  <td>
                    <button pButton type="button" icon="fa fa-times" class="danger"
                            (click)="deleteInvoiceLine(rowIndex)" pTooltip="Delete"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary" let-rowData>
                <div style="text-align:left">
                  <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAddInvoiceLines()" label="Add"></button>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
<p-dialog header="Customs Office Defaults" [(visible)]="displayCustomsOfficeDefaults" [responsive]="true" showEffect="fade"
          [minY]="70"
          [modal]="true">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-6">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="transportMethod" [(ngModel)]="customsOfficeDefault.transportMethod"
                                  field="description"
                                  [suggestions]="methods"
                                  (completeMethod)="searchTransportMethod($event)"
                                  [autoHighlight]="true"
                                  [dropdown]="true"
                                  (onSelect)="selectTransportMethod($event)"
                                  minLength="3"
                                  required=true>
                            <ng-template let-option pTemplate="item">
                              <div>{{option.description}}</div>
                            </ng-template>
                          </p-autoComplete>
                <label>Transport Method</label>
              </span>
            </div>
          </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
            <p-dropdown [options]="declarationTypeOptions"
                        [(ngModel)]="customsOfficeDefault.declarationType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
            </p-dropdown>
             <label>Declaration Type</label>
          </span>
          </div>
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="customsOfficeDefault.customsOfficeCode"
                                       pInputText class="ui-inputgroup"
                                       id="customsOfficeCode">
                                <label for="customsOfficeCode">Customs Office Code</label>
                              </span>
                            </div>
                          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-check" (click)="saveCustomsOfficeDefault()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog header="Banking Details" [(visible)]="displayBankingDetailsDialog" [responsive]="true" showEffect="fade"
          [modal]="true">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-6">
        <div class="ui-g form-group">
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="bankingDetails.bank"
                                       pInputText class="ui-inputgroup"
                                       id="bank">
                                <label for="bank">Bank Name</label>
                              </span>
                            </div>
                          </span>
          </div>
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="bankingDetails.branchCode"
                                       pInputText class="ui-inputgroup"
                                       id="bankBranchCode">
                                <label for="bankBranchCode">Branch Code</label>
                              </span>
                            </div>
                          </span>
          </div>
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="bankingDetails.accountNo"
                                       pInputText class="ui-inputgroup"
                                       id="accountNo">
                                <label for="accountNo">Account No.</label>
                              </span>
                            </div>
                          </span>
          </div>
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="bankingDetails.swiftCode"
                                       pInputText class="ui-inputgroup"
                                       id="swiftCode">
                                <label for="swiftCode">Swift Code</label>
                              </span>
                            </div>
                          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-check" (click)="saveBankingDetails()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog header="General Ledger Account Details" [(visible)]="displayGenLedgerAccDetailsDialog" [responsive]="true" showEffect="fade"
          [modal]="true">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-6">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="glChargeType"
                                      [(ngModel)]="genLedgerAccDetails.chargeType"
                                      field="label"
                                      [suggestions]="filteredChargeTypes"
                                      (completeMethod)="searchChargeType($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="glChargeType">Charge Type</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g- ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" [(ngModel)]="genLedgerAccDetails.genLedgerAccNo"
                                       pInputText class="ui-inputgroup"
                                       id="genLedgerAccNo">
                                <label for="genLedgerAccNo">Account Number</label>
                              </span>
                            </div>
                          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-check" (click)="saveGenLedgerAccDetails()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog header="Default Invoice Items" [(visible)]="displayInvoiceLinesDialog" [responsive]="true" showEffect="fade"
          [modal]="true">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-6">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="chargeType"
                                      [(ngModel)]="invoiceLine.chargeType"
                                      field="label"
                                      [suggestions]="filteredChargeTypes"
                                      (completeMethod)="searchChargeType($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="chargeType">Charge Type</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="tranType"
                                      [(ngModel)]="invoiceLine.tranType"
                                      field="label"
                                      [suggestions]="filteredTranTypes"
                                      (completeMethod)="searchTranType($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="tranType">Tran. Type</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="taxType"
                                      [(ngModel)]="invoiceLine.taxType"
                                      field="label"
                                      [suggestions]="filteredTaxTypes"
                                      (completeMethod)="searchTaxTypeCode($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="taxType">Tax Type</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="invoiceSection"
                                      [(ngModel)]="invoiceLine.invoiceSection"
                                      field="description"
                                      [suggestions]="filteredInvoiceSections"
                                      (completeMethod)="searchInvoiceSection($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="invoiceSection">Invoice Section</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-inputgroup">
                <span class="ui-float-label ui-fluid">
                      <p-autoComplete id="invoiceSubSection"
                                      [(ngModel)]="invoiceLine.invoiceSubSection"
                                      [suggestions]="filteredInvoiceSubSections"
                                      (completeMethod)="searchInvoiceSubSection($event.query, invoiceLine.invoiceSection)"
                                      [dropdown]="true"
                                      [autoHighlight]="true">
                      </p-autoComplete>
                  <label for="invoiceSubSection">Invoice Sub-Section</label>
                </span>
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forAirImports"
                          label="Air (Imp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forAirImports">
              </p-checkbox>
            </div>
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forAirExports"
                          label="Air (Exp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forAirExports">
              </p-checkbox>
            </div>
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forSeaImports"
                          label="Sea (Imp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forSeaImports">
              </p-checkbox>
            </div>
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forSeaExports"
                          label="Sea (Exp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forSeaExports">
              </p-checkbox>
            </div>
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forRoadImports"
                          label="Road (Imp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forRoadImports">
              </p-checkbox>
            </div>
            <div class="ui-g-2 ui-md-2">
              <p-checkbox name="forRoadExports"
                          label="Road (Exp.)"
                          binary="true"
                          [(ngModel)]="invoiceLine.forRoadExports">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-check" (click)="saveInvoiceItem()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
