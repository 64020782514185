/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./planning.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./planning.component";
var styles_PlanningComponent = [i0.styles];
var RenderType_PlanningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanningComponent, data: {} });
export { RenderType_PlanningComponent as RenderType_PlanningComponent };
export function View_PlanningComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" planning works!\n"]))], null, null); }
export function View_PlanningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-planning", [], null, null, null, View_PlanningComponent_0, RenderType_PlanningComponent)), i1.ɵdid(1, 114688, null, 0, i2.PlanningComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanningComponentNgFactory = i1.ɵccf("digi-planning", i2.PlanningComponent, View_PlanningComponent_Host_0, {}, {}, []);
export { PlanningComponentNgFactory as PlanningComponentNgFactory };
