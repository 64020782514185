import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var UomService = /** @class */ (function () {
    function UomService(http, config) {
        this.http = http;
        this.config = config;
    }
    UomService.prototype.findUomByCodeStartWith = function (searchValue) {
        return this.http
            .get(this.config.apiEndpoint + "api/uoms/search/findByCustomsUomCodeOrUomCode?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].uoms; }));
    };
    UomService.prototype.findByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/uoms/search/findByCode?code=" + code);
    };
    UomService.prototype.findByCodeStartsWith = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/uoms/search/findByCodeStartsWith?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].uoms; }));
    };
    UomService.ngInjectableDef = i0.defineInjectable({ factory: function UomService_Factory() { return new UomService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: UomService, providedIn: "root" });
    return UomService;
}());
export { UomService };
