import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {EMPTY, Observable, of} from 'rxjs';
import {Address, ClearingInstruction, RebateUser} from '../models/file';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RebateUserService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  private findRebateUserByCode(code) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + code + '&roleType=9' + '&projection=flattenedRebateUser')
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findRebateUserFromClearingInstructionAndRebateUsers(clearingInstruction: ClearingInstruction,
                                                      rebateUsers: RebateUser[]): Observable<RebateUser> {
    if (clearingInstruction) {
      if (rebateUsers.length === 0) {
        return this.returnDefault(clearingInstruction);
      } else if (clearingInstruction.rebateUser.code) {
        return this.findRebateUserFromClearingInstruction(clearingInstruction, clearingInstruction.rebateUser, false);
      } else if (rebateUsers && rebateUsers.length === 1) {
        return this.findRebateUserFromClearingInstruction(clearingInstruction, rebateUsers[0]);
      }
      return this.returnDefault(clearingInstruction);
    }
    return EMPTY;
  }

  private returnDefault(clearingInstruction: ClearingInstruction) {
    const rebateUser = new RebateUser();
    rebateUser.address = new Address();
    if (clearingInstruction) {
      clearingInstruction.rebateUser = rebateUser;
    }
    return of([rebateUser]);
  }

  findRebateUserFromClearingInstruction(clearingInstruction: ClearingInstruction, rebateUser: RebateUser, updateRebateUser: boolean = true) {
    if (clearingInstruction) {
      return this.findRebateUserByCode(rebateUser.code).pipe(
        tap(rebateUserData => {
          if (updateRebateUser) {
            clearingInstruction.rebateUser = new RebateUser();
            clearingInstruction.rebateUser.name = rebateUser.name;
            clearingInstruction.rebateUser.code = rebateUser.code;
            clearingInstruction.rebateUser.label = rebateUser.label;
            clearingInstruction.rebateUser.customsCode = rebateUserData[0].customsCode;
          }
          if (rebateUserData && (!clearingInstruction.rebateUser.address
            || !clearingInstruction.rebateUser.address.line1)) {
            const rebateUserAddresses = rebateUserData[0].addresses;
            let rebateUserAddress;
            if (rebateUserAddresses.length === 1) {
              rebateUserAddress = rebateUserAddresses[0];
            } else {
              rebateUserAddress = rebateUserAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'CUS';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (rebateUserAddress) {
              clearingInstruction.rebateUser.address = new Address();
              clearingInstruction.rebateUser.address.line1 = rebateUserAddress.line1;
              clearingInstruction.rebateUser.address.line2 = rebateUserAddress.line2;
              clearingInstruction.rebateUser.address.line3 = rebateUserAddress.line3;
              clearingInstruction.rebateUser.address.line4 = rebateUserAddress.line4;
              clearingInstruction.rebateUser.address.zipCode = rebateUserAddress.zipCode;
            }
          }

        }));
    } else {
      return this.returnDefault(clearingInstruction);
    }
  }
}
