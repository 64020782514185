import {Component, OnDestroy, OnInit} from '@angular/core';
import {TariffService} from '../../services/tariff.service';
import {TariffCodeBehaviorSubject} from '../../../../subjects/tariffCode-behavior-subject';
import {TariffCodeReference, TaxType} from '../../models/TariffCode';
import {TaxTypeService} from '../../services/tax-type.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-tariff-main-maintenance',
  templateUrl: './tariff-main-maintenance.component.html',
  styleUrls: ['./tariff-main-maintenance.component.scss']
})
export class TariffMainMaintenanceComponent implements OnInit, OnDestroy {
  filteredTariffCodes: TariffCodeReference[];
  filteredTaxTypes = [];
  selectedTariffCode: TariffCodeReference;
  taxType: TaxType;
  isCreateNew: boolean;
  subscription: Subscription;

  constructor(private tariffService: TariffService, private tariffCodeBehaviorSubject: TariffCodeBehaviorSubject,
              private taxTypeService: TaxTypeService) {
  }

  ngOnInit() {
    this.subscription = this.tariffCodeBehaviorSubject
      .getTariffCode()
      .subscribe(tariff => {
        this.selectedTariffCode = tariff;
      });
  }

  searchTariffCode(event) {
    this.isCreateNew = false;
    this.tariffService.findTariffByCodeStartsWithCurrentlyEffective(event.query).subscribe((data: TariffCodeReference[]) => {
      this.filteredTariffCodes = data;
    });
  }

  searchTaxTypeCode(event) {
    this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(data => {
      this.filteredTaxTypes = data;
    });
  }

  select(value: any) {
    this.tariffCodeBehaviorSubject.addTariffCode(value);
    for (let i = 0; i < value.formulaList.length; i++) {
      value.formulae[i].formula = value.formulaList[i];
    }
    this.findTaxType(this.selectedTariffCode.taxTypeCode);
  }

  selectTaxType(value) {
    this.findTaxType(value.code);
  }

  createNewTariff() {
    this.isCreateNew = true;
    this.selectedTariffCode = this.tariffCodeBehaviorSubject.buildTariffCode();
    this.tariffCodeBehaviorSubject.addTariffCode(this.selectedTariffCode);
  }

  saveNewTariff() {
    this.selectedTariffCode.schedule = '11';
    this.tariffService.saveTariffCode(this.selectedTariffCode).subscribe(data => {
      this.tariffCodeBehaviorSubject.addTariffCode(data);
    });
  }

  findTaxType(value) {
    this.taxTypeService.findByCode(value).subscribe(data => {
      this.selectedTariffCode.taxTypeCode = data.code;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
