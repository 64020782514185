<div class="ui-fluid">
    <div class="ui-g form-group">
        <ng-container>
            <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                    <p-autoComplete [(ngModel)]="selectedAddress" field="line1" placeholder="Address" [suggestions]="filteredAddresses" (completeMethod)="search($event)" [dropdown]="true" [autoHighlight]="true" (onSelect)=selectAddress($event)>
                        <ng-template let-address pTemplate="item">
                            <div>{{address.line1}} - {{address.line2}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="ui-g-12 ui-g-nopad pt-2">
                <div class="ui-inputgroup">
                    <input readonly value="{{address?.line2}}" type="text" pInputText/>
                </div>
            </div>
            <div class="ui-g-12 ui-g-nopad pt-2">
                <div class="ui-inputgroup">
                    <input readonly value="{{address?.line3}}" type="text" pInputText/>
                </div>
            </div>
            <div class="ui-g-12 ui-g-nopad pt-2">
                <div class="ui-inputgroup">
                    <input readonly value="{{address?.line4}}" type="text" pInputText/>
                </div>
            </div>
            <div class="ui-g-12 ui-g-nopad pt-2">
                <div class="ui-inputgroup">
                    <input readonly value="{{address?.zipCode}}" type="text" pInputText/>
                </div>
            </div>
        </ng-container>
    </div>
</div>
