<p-toolbar>
  <div class="ui-toolbar-group-left">
    <div class="ui-g-12 pt-0">
      <digi-selected-tariff-section></digi-selected-tariff-section>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewDescription()"></button>
    <button pButton type="button" label="Save" icon="fa fa-save"
            (click)="saveNewDescription()"></button>
  </div>
</p-toolbar>
<p-table [columns]="cols" [value]="selectedTariffCode?.descriptions" selectionMode="single" columnResizeMode="expand"
         [(selection)]="selectedDescription"
         (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="5">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">{{col.header}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-description let-rowIndex="rowIndex">
    <tr [pSelectableRow]="description" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
      <td>{{description.id.descrEffectiveFrom}}</td>
      <td>{{description.tariffLDesc}}</td>
    </tr>
  </ng-template>
</p-table>
<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-panel header="Tariff Classification Unit Details">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-4">
                <span class="md-inputfield">
                    <span class="ui-float-label">
                      <p-inputMask id="tariffEffectiveFrom"
                                   mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="selectedDescription.id.descrEffectiveFrom">
                      </p-inputMask>
                      <label for="tariffEffectiveFrom">Description Effective From</label>
                    </span>
                </span>
              </div>
              <div class="ui-g-12 ui-md-4">
                 <span class="md-inputfield">
                    <span class="ui-float-label">
                      <p-inputMask id="descrEffectiveUntil"
                                   mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="selectedDescription.descrEffectiveUntil"
                                   [disabled]="true">
                      </p-inputMask>
                      <label for="descrEffectiveUntil">Description Effective Until</label>
                    </span>
                </span>
              </div>
              <div class="ui-g ui-fluid pt-2">
                <div class="ui-g-12 pt-2">
                  <p-panel header="Customs Description">
                    <div class="ui-g">
                      <div class="ui-fluid">
                    <textarea class="ui-inputtextarea hideResize" [(ngModel)]="selectedDescription.tariffLDesc" rows="5"
                              cols="250" pInputTextarea>
                    </textarea>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
