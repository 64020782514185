import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Table} from 'primeng/table';
import {PaymentsReportService} from '../../services/payments-report.service';
import {NotificationService} from '../../services/notification.service';
import {ImporterService} from '../../services/importer.service';
import {CustomOfficeService} from '../../services/custom-office.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';
import {CustomsOffice, Importer} from '../../../digi-business-entity/models/business-entity';
import {Subscription} from 'rxjs';
import {BillOfEntry, SequenceData} from '../../models/billOfEntries';
import {InvoiceService} from '../../services/invoice.service';
import {CsvExportInvoice} from '../../models/financials';

@Component({
  selector: 'digi-reprint-invoices',
  templateUrl: './reprint-invoices.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  `]
})
export class ReprintInvoicesComponent implements OnInit, OnDestroy {
  importer: Importer;
  filteredImporters: Importer[];
  invoiceNumber: string;
  fromDate: string;
  toDate: string;
  display = false;
  displayCsvTable = false;
  pdfSrc: string;
  disableButton: boolean;
  generatedFiles: any[];
  sequences: SequenceData[];
  filteredCustomsOffices: CustomsOffice[];
  csvExportInvoices: CsvExportInvoice[];
  csvExportCols: any[];
  @ViewChild('bidt') bidt: Table;
  private invoicesSubscription: Subscription;

  constructor(
    private invoiceService: InvoiceService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
  ) {
  }

  ngOnInit() {
  this.csvExportCols = [
        {field: 'chargeType', header: 'Charge Type'},
        {field: 'vatAmount', header: 'VAT Amount'},
        {field: 'vatCode', header: 'VAT Code'},
        {field: 'invoiceAmount', header: 'Invoice Amount'},
        {field: 'invoiceDate', header: 'Invoice Date'},
        {field: 'customerCode', header: 'Customer Code'},
        {field: 'orderNumber', header: 'Order Number'},
        {field: 'documentNumber', header: 'Document Number'}
      ];
  }

  searchImporter(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  generate() {
    let invoiceNumber = '';
    let from = '';
    let to = '';
    if (this.invoiceNumber) {
      invoiceNumber = this.invoiceNumber;
    }
    if (this.fromDate && this.toDate) {
      from = this.fromDate;
      to = this.toDate;
    }
    this.disableButton = true;
    this.invoicesSubscription = this.invoiceService.generateInvoiceReports(invoiceNumber, from, to).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showDialog();
      this.disableButton = false;
    }, error => {
      this.disableButton = false;
      this.notificationService.errorNotify(error.error.message, error.error.errors);
    });
  }

  generateCsvTable() {
    this.csvExportInvoices = [];
    this.invoicesSubscription = this.invoiceService.generateInvoiceCsv(this.fromDate, this.toDate).subscribe(invoices => {
      if (invoices.length){
      const exportArray = [];
         invoices.forEach(invoice => {
           invoice.lineDetails.forEach(line => {
              const exportInvoice: CsvExportInvoice = new CsvExportInvoice();
              exportInvoice.chargeType = line.chargeType.description;
              exportInvoice.vatAmount = line.localTax;
              exportInvoice.vatCode = line.taxType.code;
              exportInvoice.invoiceAmount = line.localAmount;
              exportInvoice.invoiceDate = invoice.invoiceDate;
              exportInvoice.customerCode = invoice.customsFile.debtor.code;
              exportInvoice.orderNumber = invoice.customsFile.reference.referenceFileNo;
              exportInvoice.documentNumber = invoice.invoiceNo;

              exportArray.push(exportInvoice);
             });
           });

           this.csvExportInvoices = exportArray;
           this.showTable();
        }
    }, error => {
      this.notificationService.errorNotify(error.error.message, error.error.errors);
    });
  }

  exportCsvData() {
    this.bidt.exportFilename = 'InvoiceDetailsExport' + '_' +
          new DatePipe('en-ZA').transform(Date.now(), 'MMM_dd_yyyy_hh_mm_ss', 'SAST') + 'Z';
    this.bidt.exportCSV();
    this.displayCsvTable = false;
  }

  showDialog() {
    this.display = true;
  }

  showTable() {
      this.displayCsvTable = true;
    }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  ngOnDestroy(): void {
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
  }
}
