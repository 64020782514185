import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {VoyageFile, VoyageMasterFile} from '../../models/voyageFile';
import {
  CollectPrepaid,
  Currency,
  CustomsRadioCallSign,
  DepotTerminal,
  LocalForwarder,
  OceanLine,
  OceanLineDetails,
  PortDetail
} from '../../models/file';
import {OceanlineService} from '../../../digi-ocean/services/oceanline.service';
import {CustomsRadioCallSignService} from '../../services/customs-radio-call-sign.service';
import {CurrencyService} from '../../services/currency.service';
import {CollectPrepaidService} from '../../services/collect-prepaid.service';
import {DepotTerminalService} from '../../services/depot-terminal.service';
import {AgentService} from '../../services/agent.service';
import {VoyageFileService} from '../../services/voyage-file.service';
import {
  BusinessEntity,
  Debtor,
  ExportConsignee, NominatedBusinessEntity,
  OverseasAgent, Role,
  RoleNomination,
  Supplier
} from '../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';

@Component({
  selector: 'digi-ocean-bol',
  templateUrl: './ocean-bol.component.html',
  styleUrls: ['./ocean-bol.component.scss']
})
export class OceanBolComponent implements OnInit, OnChanges {
  @Input() voyageFile: VoyageFile;
  @Input() indicator: string;

  @Output() onSaveVoyageFile = new EventEmitter();
  @Output() onUpdateVoyageFiles = new EventEmitter();
  @Output() onUpdateVoyageFile = new EventEmitter();

  voyageMasterFile: VoyageMasterFile;
  filteredOceanLines: OceanLine[];
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[];
  callPurposes: string[];
  filteredCurrencies: Currency[];
  filteredCollectPrepaids: CollectPrepaid[];
  filteredDepotTerminals: DepotTerminal[];
  filteredLocalForwarders: LocalForwarder[];
  newContainer: any;
  newPackage: any;

  filteredDebtors: RoleNomination[];
  filteredSuppliers: RoleNomination[];
  filteredOverseasAgents: RoleNomination[];
  filteredExportConsignees: RoleNomination[];
  displayNewButton: boolean;
  constructor(
    private oceanLineService: OceanlineService,
    private customsRadioCallSignService: CustomsRadioCallSignService,
    private currencyService: CurrencyService,
    private collectPrepaidService: CollectPrepaidService,
    private depotTerminalService: DepotTerminalService,
    private localAgentService: AgentService,
    private voyageFileService: VoyageFileService,
    private businessEntityService: BusinessEntityService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.voyageFile.currentValue) {
      this.voyageFile = changes.voyageFile.currentValue;
      if (this.voyageFile.voyageMasterFiles && this.voyageFile.voyageMasterFiles.length > 0) {
        this.voyageMasterFile = this.voyageFile.voyageMasterFiles[0];
      } else {
        this.voyageFile.voyageMasterFiles = [];
        this.createNewVoyageMasterFile();
      }

      this.setDefaultValues();
    }

    if (changes.indicator && changes.indicator.currentValue) {
      this.indicator = changes.indicator.currentValue;
    }
  }

  createNewVoyageMasterFile() {
    this.voyageMasterFile = this.voyageFileService.createNewVoyageMasterFile();
    this.voyageFile.voyageMasterFiles.push(this.voyageMasterFile);
    this.displayNewButton = false;
  }

  searchOceanLines(event: any) {
    this.oceanLineService.findAllOceanLineStartsWith(event.query).subscribe(data => {
      this.filteredOceanLines = data;
    });
  }

  searchRadioCallSigns(event, field) {
    this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(data => {
      this.filteredCustomsRadioCallSigns = data;
    });
  }

  selectDeparturePort(value) {
    this.voyageMasterFile.departurePort = value;
  }

  selectDestinationPort(value) {
    this.voyageMasterFile.destinationPort = value;
  }

  selectDischargePort(value) {
    this.voyageMasterFile.dischargePort = value;
  }

  selectDocsIssuedAtPort(value) {
    this.voyageMasterFile.docsIssuedAtPort = value;
  }

  searchCurrencies(event) {
    this.currencyService.findCurrencyStartWith(event.query).subscribe(data => {
      this.filteredCurrencies = data;
    });
  }

  searchCollectPrepaid(event) {
    this.collectPrepaidService.findCollectPrepaidStartWith(event.query).subscribe(data => {
      this.filteredCollectPrepaids = data;
    });
  }

  updateCustomsOffice(value) {
    this.voyageMasterFile.customsOffice = value;
  }

  searchDepotTerminal(event, depotTerminal) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminal).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  updateConsignee(value, roleId) {
    this.voyageMasterFile.consignee = value;
    this.displayNewButton = !!(this.voyageMasterFile.consignee && this.voyageMasterFile.consignee.code);
    const roleType = this.indicator === 'import' ? 6 : 12;
    if (this.voyageMasterFile.consignee && this.voyageMasterFile.consignee.roles) {
      const role = this.voyageMasterFile.consignee.roles.find(role => role.roleType === roleType);
      let roleNominations;

      if (role) {
        roleNominations = role.roleNominations;
      }

      if (roleNominations && roleNominations.length > 0) {
        this.filteredDebtors = roleNominations.filter(nomination => nomination.roleType === 1);
        if (this.voyageMasterFile.debtor && !this.voyageMasterFile.debtor.code && this.filteredDebtors.length === 1) {
          const debtor = new BusinessEntity();
          debtor.code = this.filteredDebtors[0].businessEntityCode;
          debtor.name = this.filteredDebtors[0].businessEntityName;
          debtor.label = this.filteredDebtors[0].label;
          this.voyageMasterFile.debtor = debtor;
        }

        this.filteredSuppliers = roleNominations.filter(nomination => nomination.roleType === 21);
        if (this.voyageMasterFile.consignor && !this.voyageMasterFile.consignor.code && this.filteredSuppliers.length === 1) {
          const supplier = new BusinessEntity();
          supplier.code = this.filteredSuppliers[0].businessEntityCode;
          supplier.name = this.filteredSuppliers[0].businessEntityName;
          supplier.label = this.filteredSuppliers[0].label;
          this.voyageMasterFile.consignor = supplier;
        }

        this.filteredOverseasAgents = roleNominations.filter(nomination => nomination.roleType === 5);
        if (this.voyageMasterFile.overseasAgent && !this.voyageMasterFile.overseasAgent.code && this.filteredOverseasAgents.length === 1) {
          const overseasAgent = new BusinessEntity();
          overseasAgent.code = this.filteredOverseasAgents[0].businessEntityCode;
          overseasAgent.name = this.filteredOverseasAgents[0].businessEntityName;
          overseasAgent.label = this.filteredOverseasAgents[0].label;
          this.voyageMasterFile.overseasAgent = overseasAgent;
        }
      } else {
        if ([6, 12].includes(roleId)) {
          this.filteredDebtors = [];
          this.voyageMasterFile.debtor = new BusinessEntity();
          if (this.indicator === 'import') {
            this.voyageMasterFile.debtor = Object.assign({}, this.voyageMasterFile.consignee);
          } else {
            this.voyageMasterFile.debtor = Object.assign({}, this.voyageMasterFile.consignor);
          }
          this.filteredOverseasAgents = [];
          this.voyageMasterFile.overseasAgent = new BusinessEntity();
          this.filteredExportConsignees = [];
          this.voyageMasterFile.consignor = new BusinessEntity();
        }
      }
    }

    if (value && [2, 21].includes(roleId)) {
      const code = this.indicator === 'import' ? this.voyageMasterFile.consignee.code : this.voyageMasterFile.consignor.code;
      this.businessEntityService.findBusinessEntityStartsWithAndRoleType(code, roleType)
        .subscribe(
          businessEntities => {
            const businessEntity = businessEntities[0];
            let consignee = new RoleNomination();
            if (this.indicator === 'import') {
              const importerRole: Role = businessEntity.roles.find(role => role.roleType === 6);
              const roleNominations = importerRole.roleNominations;
              consignee = roleNominations.find(nomination => nomination.roleType === 21 && nomination.businessEntityCode === this.voyageMasterFile.consignee.code);
              if (!consignee) {
                consignee = new RoleNomination();
                consignee.businessEntityCode = this.voyageMasterFile.consignee.code;
                consignee.businessEntityName = this.voyageMasterFile.consignee.name;
                consignee.roleType = 21;
                roleNominations.push(consignee);
                importerRole.roleNominations = [...roleNominations];
                this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                  data => console.log(data)
                );
              }
            }

            if (this.indicator === 'export') {
              const exporterRole: Role = businessEntity.roles.find(role => role.roleType === 12);
              let roleNominations;

              if (exporterRole) {
                roleNominations = exporterRole.roleNominations;
              }
              consignee = roleNominations.find(nomination => nomination.roleType === 2 && nomination.businessEntityCode === this.voyageMasterFile.consignee.code);
              if (!consignee) {
                consignee = new RoleNomination();
                consignee.businessEntityCode = this.voyageMasterFile.consignee.code;
                consignee.businessEntityName = this.voyageMasterFile.consignee.name;
                consignee.roleType = 2;
                roleNominations.push(consignee);
                exporterRole.roleNominations = [...roleNominations];
                this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                  data => console.log(data)
                );
              }
            }
          }
        );
    }
  }

  updateConsignor(value, roleId) {
    this.voyageMasterFile.consignor = value;
    this.displayNewButton = !!(this.voyageMasterFile.consignor && this.voyageMasterFile.consignor.code);
    const roleType = this.indicator === 'import' ? 6 : 12;
    if (this.voyageMasterFile.consignor && this.voyageMasterFile.consignor.roles) {
      const role = this.voyageMasterFile.consignor.roles.find(role => role.roleType === roleType);
      let roleNominations;

      if (role) {
        roleNominations = role.roleNominations;
      }
      if (roleNominations && roleNominations.length > 0) {
        this.filteredDebtors = roleNominations.filter(nomination => nomination.roleType === 1);
        if (this.voyageMasterFile.debtor && !this.voyageMasterFile.debtor.code && this.filteredDebtors.length === 1) {
          const debtor = new BusinessEntity();
          debtor.code = this.filteredDebtors[0].businessEntityCode;
          debtor.name = this.filteredDebtors[0].businessEntityName;
          debtor.label = this.filteredDebtors[0].label;
          this.voyageMasterFile.debtor = debtor;
        }

        this.filteredExportConsignees = roleNominations.filter(nomination => nomination.roleType === 2);
        if (this.voyageMasterFile.consignee && !this.voyageMasterFile.consignee.code && this.filteredExportConsignees.length === 1) {
          const consignee = new BusinessEntity();
          consignee.code = this.filteredExportConsignees[0].businessEntityCode;
          consignee.name = this.filteredExportConsignees[0].businessEntityName;
          consignee.label = this.filteredExportConsignees[0].label;
          this.voyageMasterFile.consignee = consignee;
        }

        this.filteredOverseasAgents = roleNominations.filter(nomination => nomination.roleType === 5);
        if (this.voyageMasterFile.overseasAgent && !this.voyageMasterFile.overseasAgent.code && this.filteredOverseasAgents.length === 1) {
          const overseasAgent = new BusinessEntity();
          overseasAgent.code = this.filteredOverseasAgents[0].businessEntityCode;
          overseasAgent.name = this.filteredOverseasAgents[0].businessEntityName;
          overseasAgent.label = this.filteredOverseasAgents[0].label;
          this.voyageMasterFile.overseasAgent = overseasAgent;
        }
      } else {
        if ([6, 12].includes(roleId)) {
          this.filteredDebtors = [];
          this.voyageMasterFile.debtor = new BusinessEntity();
          if (this.indicator === 'import') {
            this.voyageMasterFile.debtor = Object.assign({}, this.voyageMasterFile.consignee);
          } else {
            this.voyageMasterFile.debtor = Object.assign({}, this.voyageMasterFile.consignor);
          }
          this.filteredOverseasAgents = [];
          this.voyageMasterFile.overseasAgent = new BusinessEntity();
          this.filteredExportConsignees = [];
          this.voyageMasterFile.consignee = new BusinessEntity();
        }
      }
    }

    if (value && [2, 21].includes(roleId)) {
      const code = this.indicator === 'import' ? this.voyageMasterFile.consignee.code : this.voyageMasterFile.consignor.code;
      this.businessEntityService.findBusinessEntityStartsWithAndRoleType(code, roleType)
        .subscribe(
          businessEntities => {
            const businessEntity = businessEntities[0];
            let consignor = new RoleNomination();
            if (this.indicator === 'import') {
              const importerRole: Role = businessEntity.roles.find(role => role.roleType === 6);
              let roleNominations;
              if (importerRole) {
                roleNominations = importerRole.roleNominations;
              }
              consignor = roleNominations.find(nomination => nomination.roleType === 21 && nomination.businessEntityCode === this.voyageMasterFile.consignor.code);
              if (!consignor) {
                consignor = new RoleNomination();
                consignor.businessEntityCode = this.voyageMasterFile.consignor.code;
                consignor.businessEntityName = this.voyageMasterFile.consignor.name;
                consignor.roleType = 21;
                roleNominations.push(consignor);
                importerRole.roleNominations = [...roleNominations];
                this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                  data => console.log(data)
                );
              }
            }

            if (this.indicator === 'export') {
              const exporterRole: Role = businessEntity.roles.find(role => role.roleType === 12);
              const roleNominations = exporterRole.roleNominations;
              consignor = roleNominations.find(nomination => nomination.roleType === 2 && nomination.businessEntityCode === this.voyageMasterFile.consignor.code);
              if (!consignor) {
                consignor = new RoleNomination();
                consignor.businessEntityCode = this.voyageMasterFile.consignor.code;
                consignor.businessEntityName = this.voyageMasterFile.consignor.name;
                consignor.roleType = 2;
                roleNominations.push(consignor);
                exporterRole.roleNominations = [...roleNominations];
                this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                  data => console.log(data)
                );
              }
            }
          }
        );
    }
  }

  updateDebtor(value) {
    this.voyageMasterFile.debtor = value;

    const code = this.indicator === 'import' ? this.voyageMasterFile.consignee.code : this.voyageMasterFile.consignor.code;
    const roleType = this.indicator === 'import' ? 6 : 12;
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(code, roleType)
      .subscribe(
        businessEntities => {
          const businessEntity = businessEntities[0];
          const nominatedBusinessEntity = new NominatedBusinessEntity();
          let debtor = new RoleNomination();
          if (this.indicator === 'import') {
            const importerRole: Role = businessEntity.roles.find(role => role.roleType === 6);
            const roleNominations = importerRole.roleNominations;
            debtor = roleNominations.find(nomination => nomination.roleType === 1 && nomination.businessEntityCode === this.voyageMasterFile.debtor.code)
            if (!debtor) {
              debtor = new RoleNomination();
              debtor.businessEntityCode = this.voyageMasterFile.debtor.code;
              debtor.businessEntityName = this.voyageMasterFile.debtor.name;
              debtor.roleType = 1;
              roleNominations.push(debtor);
              importerRole.roleNominations = [...roleNominations];
              this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                data => console.log(data)
              );
            }
          }

          if (this.indicator === 'export') {
            const exporterRole: Role = businessEntity.roles.find(role => role.roleType === 12);
            const roleNominations = exporterRole.roleNominations;
            debtor = roleNominations.find(nomination => nomination.roleType === 1 && nomination.businessEntityCode === this.voyageMasterFile.debtor.code)
            if (!debtor) {
              debtor = new RoleNomination();
              debtor.businessEntityCode = this.voyageMasterFile.debtor.code;
              debtor.businessEntityName = this.voyageMasterFile.debtor.name;
              debtor.roleType = 1;
              roleNominations.push(debtor);
              exporterRole.roleNominations = [...roleNominations];
              this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                data => console.log(data)
              );
            }
          }
        }
      );
  }

  updateOverseasAgent(value) {
    this.voyageMasterFile.overseasAgent = value;

    const code = this.indicator === 'import' ? this.voyageMasterFile.consignee.code : this.voyageMasterFile.consignor.code;
    const roleType = this.indicator === 'import' ? 6 : 12;
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(code, roleType)
      .subscribe(
        businessEntities => {
          const businessEntity = businessEntities[0];
          let overseasAgent = new RoleNomination();
          if (this.indicator === 'import') {
            const importerRole: Role = businessEntity.roles.find(role => role.roleType === 6);
            const roleNominations = importerRole.roleNominations;
            overseasAgent = roleNominations.find(nomination => nomination.roleType === 5 && nomination.businessEntityCode === this.voyageMasterFile.overseasAgent.code);
            if (!overseasAgent) {
              overseasAgent = new RoleNomination();
              overseasAgent.businessEntityCode = this.voyageMasterFile.overseasAgent.code;
              overseasAgent.businessEntityName = this.voyageMasterFile.overseasAgent.name;
              overseasAgent.roleType = 5;
              roleNominations.push(overseasAgent);
              importerRole.roleNominations = [...roleNominations];
              this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                data => console.log(data)
              );
            }
          }

          if (this.indicator === 'export') {
            const exporterRole: Role = businessEntity.roles.find(role => role.roleType === 12);
            const roleNominations = exporterRole.roleNominations;
            overseasAgent = roleNominations.find(nomination => nomination.roleType === 5 && nomination.businessEntityCode === this.voyageMasterFile.overseasAgent.code);
            if (!overseasAgent) {
              overseasAgent = new RoleNomination();
              overseasAgent.businessEntityCode = this.voyageMasterFile.overseasAgent.code;
              overseasAgent.businessEntityName = this.voyageMasterFile.overseasAgent.name;
              overseasAgent.roleType = 5;
              roleNominations.push(overseasAgent);
              exporterRole.roleNominations = [...roleNominations];
              this.businessEntityService.saveBusinessEntity(businessEntity).subscribe(
                data => console.log(data)
              );
            }
          }
        }
      );
  }

  searchAgent(event) {
    this.localAgentService
      .findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(event.query, 'SEA')
      .subscribe(data => {
        this.filteredLocalForwarders = data;
      });
  }

  saveVoyageFile() {
    this.onSaveVoyageFile.emit(true);
  }

  updateVoyageFiles(voyageFiles) {
    this.onUpdateVoyageFiles.emit(voyageFiles);
  }

  updateVoyageFile(voyageFile) {
    this.onUpdateVoyageFile.emit(voyageFile);
  }

  createNewContainer() {
    this.newContainer = {};
  }

  createNewPackage() {
    this.newPackage = {};
  }

  updateMarksAndNumbers(value) {
    this.voyageMasterFile.marksAndNumbers = value;
  }

  private setDefaultValues() {
    if (this.voyageFile.radioCallSign && this.voyageMasterFile.voyageDetails && !this.voyageMasterFile.voyageDetails.customsRadioCallSign) {
      this.voyageMasterFile.voyageDetails.customsRadioCallSign = new CustomsRadioCallSign();
      this.voyageMasterFile.voyageDetails.customsRadioCallSign.vesselName = this.voyageFile.radioCallSign.vesselName;
      this.voyageMasterFile.voyageDetails.customsRadioCallSign.radioCallSign = this.voyageFile.radioCallSign.radioCallSign;
    }

    if (!this.voyageMasterFile.arrivalDate) {
      this.voyageMasterFile.arrivalDate = this.voyageFile.arrivalDate;
    }

    if (!this.voyageMasterFile.departureDate) {
      this.voyageMasterFile.departureDate = this.voyageFile.departureDate;
    }

    if (this.voyageFile.departurePort) {
      if (!this.voyageMasterFile.departurePort) {
        this.voyageMasterFile.departurePort = new PortDetail();
        this.voyageMasterFile.departurePort.code = this.voyageFile.departurePort.code;
        this.voyageMasterFile.departurePort.name = this.voyageFile.departurePort.name;
        this.voyageMasterFile.departurePort.label = this.voyageFile.departurePort.label;
      }

      if (!this.voyageMasterFile.docsIssuedAtPort) {
        this.voyageMasterFile.docsIssuedAtPort = new PortDetail();
        this.voyageMasterFile.docsIssuedAtPort.code = this.voyageFile.departurePort.code;
        this.voyageMasterFile.docsIssuedAtPort.name = this.voyageFile.departurePort.name;
        this.voyageMasterFile.docsIssuedAtPort.label = this.voyageFile.departurePort.label;
      }
    }

    if (this.voyageFile.destinationPort) {
      if (!this.voyageMasterFile.dischargePort) {
        this.voyageMasterFile.dischargePort = new PortDetail();
        this.voyageMasterFile.dischargePort.code = this.voyageFile.destinationPort.code;
        this.voyageMasterFile.dischargePort.name = this.voyageFile.destinationPort.name;
        this.voyageMasterFile.dischargePort.label = this.voyageFile.destinationPort.label;
      }

      if (!this.voyageMasterFile.destinationPort) {
        this.voyageMasterFile.destinationPort = new PortDetail();
        this.voyageMasterFile.destinationPort.code = this.voyageFile.destinationPort.code;
        this.voyageMasterFile.destinationPort.name = this.voyageFile.destinationPort.name;
        this.voyageMasterFile.destinationPort.label = this.voyageFile.destinationPort.label;
      }
    }

    if (!this.voyageMasterFile.voyageNo) {
      this.voyageMasterFile.voyageNo = this.voyageFile.voyageNo;
    }

    if (this.voyageFile.oceanLine) {
      if (!this.voyageMasterFile.oceanLineDetails.oceanLine) {
        this.voyageMasterFile.oceanLineDetails.oceanLine = new OceanLine();
        this.voyageMasterFile.oceanLineDetails.oceanLine.code = this.voyageFile.oceanLine.code;
        this.voyageMasterFile.oceanLineDetails.oceanLine.name = this.voyageFile.oceanLine.name;
        this.voyageMasterFile.oceanLineDetails.oceanLine.label = this.voyageFile.oceanLine.label;
      }

      if (!this.voyageMasterFile.oceanLineDetails.carrierCode ) {
        this.voyageMasterFile.oceanLineDetails.carrierCode = new OceanLine();
        this.voyageMasterFile.oceanLineDetails.carrierCode.code = this.voyageFile.oceanLine.code;
        this.voyageMasterFile.oceanLineDetails.carrierCode.name = this.voyageFile.oceanLine.name;
        this.voyageMasterFile.oceanLineDetails.carrierCode.label = this.voyageFile.oceanLine.label;
      }
    }
  }
}
