import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address, BusinessEntity, ImporterSupplier, PurchaseTerm, Role, RoleAddress} from '../../models/business-entity';
import {RoleAddressType, RoleMetaData} from '../../models/role-metadata.';

@Component({
  selector: 'app-importer-address',
  templateUrl: './role-addresses.component.html',
  styleUrls: ['./role-addresses.component.scss']
})
export class RoleAddressesComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() addressTypes: RoleAddressType[];
  @Input() activeRoleType: RoleMetaData;
  filteredAddressTypes: RoleAddressType[];
  selectedAddressType: RoleAddressType;
  selectedRoleAddress: RoleAddress;
  activeRole: Role;
  roleType: number;


  constructor() {
  }

  ngOnInit() {
    this.findActiveRole();
  }

  ngOnChanges() {
    this.findActiveRole();
  }

  private findActiveRole() {
    if (this.roleType === undefined) {
      this.roleType = this.activeRoleType.id;
    } else if (this.roleType !== this.activeRoleType.id) {
        this.selectedAddressType = new RoleAddressType();
        this.selectedRoleAddress = new RoleAddress();
        this.roleType = this.activeRoleType.id;
    }

    this.activeRole = this.businessEntity.roles.find(role => {
      if (role.roleType === this.activeRoleType.id) {
        return true;
      } else {
        return false;
      }
    });
  }

  addNewAddress() {
    this.selectedRoleAddress = undefined;
    if (this.selectedAddressType) {
      if (this.activeRole) {
        if (this.activeRole.roleAddresses) {
          this.selectedRoleAddress = this.activeRole.roleAddresses.find(next => {
            return next.addressType.id === this.selectedAddressType.addressType.id;
          });
        }

        if (!this.selectedRoleAddress) {
          this.selectedRoleAddress = new RoleAddress();
          this.selectedRoleAddress.addressType = this.selectedAddressType.addressType;

          if (this.activeRole.roleAddresses) {
            this.activeRole.roleAddresses.push(this.selectedRoleAddress);
          } else {
            this.activeRole.roleAddresses = [this.selectedRoleAddress];
          }
          this.activeRole.roleAddresses = [...this.activeRole.roleAddresses];
        }
      }
    }
  }

  search(event) {
    this.filteredAddressTypes = this.addressTypes.filter(v => v.addressType.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredAddressTypes = [...this.filteredAddressTypes];
  }

  selectAddress(address: Address) {
    this.selectedRoleAddress.address = address;
  }

  onRoleAddressSelected(event: any) {
    const roleAddress = event.data;
    if (roleAddress) {
      this.selectedAddressType = this.addressTypes.find(metaData => {
        if (roleAddress.addressType.id === metaData.addressType.id) {
          return true;
        } else {
          return false;
        }
      });
      this.selectedRoleAddress = roleAddress;
    }
  }

  onRoleAddressUnSelected(event: any) {
    this.selectedAddressType = undefined;
  }

  onRowDelete(index) {
    this.activeRole.roleAddresses.splice(index, 1);
    this.activeRole.roleAddresses = [...this.activeRole.roleAddresses];
    this.selectedAddressType = undefined;
    this.selectedRoleAddress = undefined;
  }
}
