import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UniqueConsignmentReference} from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class UniqueConsignmentBehaviourSubject {
  private uniqueConsignmentReferenceSubject = new BehaviorSubject<UniqueConsignmentReference>(null);

  constructor() {
  }

  addUniqueConsignmentReference(uniqueConsignmentReference: UniqueConsignmentReference  ) {
    this.uniqueConsignmentReferenceSubject.next(uniqueConsignmentReference);
  }

  getUniqueConsignmentReference(): Observable<UniqueConsignmentReference> {
    return this.uniqueConsignmentReferenceSubject.asObservable();
  }
}
