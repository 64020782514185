import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';
import { ClearingFile } from '../../../../digi-common/models/clearingFile';
import { TransportDocument } from '../../../../digi-common/models/file';
import { ShareDataService } from '../../../../digi-common/services/share-data.service';
import { TransportService } from '../../../services/transport.service';

@Component({
  selector: 'digi-hawb',
  templateUrl: './hawb.component.html',
  styleUrls: ['./hawb.component.css']
})
export class HawbComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  display = false;
  subscription: Subscription;
  transSubscription: Subscription;
  validationMessagesForHawbNo: any[] = [];
  validationMessagesForMawbNo: any[] = [];

  constructor(
    private transportService: TransportService,
    private shareDataService: ShareDataService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe((cf: ClearingFile) => {
        this.clearingFile = cf;
        if (this.clearingFile) {
          if (this.clearingFile.transportDocuments.length === 0) {
            const transportDocument: TransportDocument = this.shareDataService.addTransportDocument('imports');
            this.clearingFile.transportDocuments.push(transportDocument);
            this.clearingFile.transportDocuments = [...this.clearingFile.transportDocuments];
            this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
          } else {
            this.transportDocumentBehaviourSubject.addTransportDocument(this.clearingFile.transportDocuments[0]);
          }
        }
      });

    this.transSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => {
        if (td) {
          this.selectedTransportDocument = td;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.transSubscription.unsubscribe();
  }

  hasTransportDocument() {
    return this.clearingFile && this.clearingFile.transportDocuments && this.clearingFile.transportDocuments.length > 0;
  }

  addTransportDocument() {
    const transportDocument: TransportDocument = this.shareDataService.addTransportDocument('imports');
    this.clearingFile.transportDocuments.push(transportDocument);
    this.clearingFile.transportDocuments = [...this.clearingFile.transportDocuments];
    this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
  }

  openSundryChargeModel() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }
  navigateToSite() {
    window.open('https://www.track-trace.com/aircargo', '_blank');
  }

}
