<div class="ui-g-1">
  <p-dialog header="Generated Copy Invoices" [(visible)]="display" [modal]="true"
            [responsive]="true" [width]="600"
            [minWidth]="200" [minY]="70"
            [maximizable]="true" [baseZIndex]="10000">
    <ng-template pTemplate="content" [ngIf]="generatedFiles">
      <li *ngFor="let generatedDocument of generatedFiles">
        <a class="uploaded-file-color"
           [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
      </li>
    </ng-template>
    <p-footer>
      <button type="button" pButton icon="pi pi-close" (click)="display=false"
              label="Close"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</div>

<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Invoices" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-8">

              <div class="ui-g-3 ui-md-3">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                              <input type="text" style="color:white" pInputText [(ngModel)]="invoiceNumber" placeholder="Invoice Number"/>
                            </span>
                          </span>
              </div>
              <div class="ui-g-3 ui-md-3">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="fromDate"
                                             placeholder="From"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
              <div class="ui-g-3 ui-md-3">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="toDate"
                                             placeholder="To"
                                >
                                </p-inputMask>
                            </span>
                          </span>
              </div>
            </div>
            <div class="ui-toolbar-group-right ui-g-4">
              <div class="ui-g-12 ui-md-12">
                  <button pButton type="button" label="Generate Copy Invoices"
                          [disabled]="disableButton"
                          (click)="generate()"></button>
                  <button pButton type="button" label="Generate CSV Table"
                          (click)="generateCsvTable()"></button>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="ui-g-9 ui-md-9">
            <p-table *ngIf="displayCsvTable" #bidt [columns]="csvExportCols" [value]="csvExportInvoices" exportFilename={{exportFilename}}>
              <ng-template pTemplate="caption">
                <div class="flex">
                  <button *ngIf="csvExportInvoices && csvExportInvoices.length" pButton type="button" label="Export as CSV" icon="fa fa-file"  (click)="exportCsvData()" class="mr-2"></button>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">{{col.header}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-csvExportLine>
                <tr>
                  <td>{{csvExportLine.chargeType}}</td>
                  <td>{{csvExportLine.vatAmount}}</td>
                  <td>{{csvExportLine.vatCode}}</td>
                  <td>{{csvExportLine.invoiceAmount}}</td>
                  <td>{{csvExportLine.invoiceDate}}</td>
                  <td>{{csvExportLine.customerCode}}</td>
                  <td>{{csvExportLine.orderNumber}}</td>
                  <td>{{csvExportLine.documentNumber}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
