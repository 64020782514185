<p-dialog header="{{ getRoleHeader(roleID) }}"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1300"
          [minWidth]="200"
          [minY]="80"
          [maximizable]="false"
          [baseZIndex]="10000">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6">
          <p-panel header="Business Entity Details">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="businessEntity.name"
                                   pInputText>
                            <label>Trading Name</label>
                        </span>
                    </div>
                             <div class="ui-message ui-messages-error ui-corner-all" *ngIf="!businessEntity.name">
               This field is required
          </div>
                  </span>
              </div>
              <div class="ui-g-12 ui-md-12" *ngIf="businessEntity.code">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="businessEntity.code"
                                   pInputText>
                            <label>Code</label>
                        </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12" *ngIf="roleID===6 || roleID === 4">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="businessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Customs Code</label>
                        </span>
                </div>
              </div>
              <ng-container *ngIf="roleID===2">
                <div class="ui-g-12 ui-md-12">
                  <digi-country-of-origin [country]="country"
                                          label="Country"
                                          (onSelect)="selectCountry($event)"></digi-country-of-origin>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <digi-purchase-terms [purchaseTerm]="roleNomination?.defaultPurchaseTerm"></digi-purchase-terms>
                </div>
              </ng-container>
            </div>
          </p-panel>
        </div>
        <div class="ui-g-12 ui-md-6">
          <p-panel header="Address Section">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.line1"
                       pInputText>
                <label>Street</label>
              </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.line2"
                       pInputText>
              </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.line3"
                       pInputText>
              </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.line4"
                       pInputText>
                <label>State/Province</label>
              </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"  [(ngModel)]="selectedAddress.zipCode"
                       pInputText>
                <label>Zip/Postal Code</label>
              </span>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="onSave()" label="Save"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="onNew()" label="New"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()" label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
