<div class="content-section implementation">
  <p-table [columns]="cols" [value]="selectedTransportDocument.containers" selectionMode="single" [(selection)]="selectedContainer"
           [resizableColumns]="true" (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="5" >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn>
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td class="ui-resizable-column">
          <label>{{rowData.containerNo}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.containerType.code}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.nettWeight}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.freightTons}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.cbm}}</label>
        </td>
        <td class="ui-resizable-column">
          <label class="wrap">{{rowData?.terminal?.name}}</label>
        </td>
        <td class="ui-resizable-column">
          <label class="wrap">{{rowData?.depot?.name}}</label>
        </td>
        <td class="ui-resizable-column">
          <label></label>
        </td>
        <td>
          <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                  (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

