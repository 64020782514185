import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var DutyCalculationService = /** @class */ (function () {
    function DutyCalculationService(http, config) {
        this.http = http;
        this.config = config;
    }
    DutyCalculationService.prototype.calculateDuty = function (params) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.apiEndpoint + 'api/tariffCodes/calculateDuty', JSON.stringify(params), { headers: headers });
    };
    DutyCalculationService.ngInjectableDef = i0.defineInjectable({ factory: function DutyCalculationService_Factory() { return new DutyCalculationService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: DutyCalculationService, providedIn: "root" });
    return DutyCalculationService;
}());
export { DutyCalculationService };
