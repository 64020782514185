import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { BillOfEntry } from '../../models/billOfEntries';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'digi-boe-bank-details',
  templateUrl: './boe-bank-details.component.html',
  styleUrls: ['./boe-bank-details.component.scss']
})
export class BoeBankDetailsComponent implements OnInit, OnDestroy {
  selectedBillOfEntry: BillOfEntry;
  billSubscription: Subscription;
  ackIndicators: SelectItem[];
  @Input() isVoC = false;

  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.ackIndicators = [
      {
        label: '',
        value: null
      },
      {
        label: 'Accepted',
        value: 1
      },
      {
        label: 'Conditionally accepted (with dispute, agree on duties and taxes but not on penalties)',
        value: 2
      },
      {
        label: 'Conditionally accepted (with dispute but requires release, sureties to be lodged)',
        value: 3
      },
      {
        label: 'Rejected (abandon goods)',
        value: 4
      }
    ];
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;
      });
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }
}


