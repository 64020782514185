import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BillOfEntryMarksAndNumbers, ExportBillOfEntry} from '../../../digi-common/models/billOfEntries';
import {ExportsBillOfEntryService} from '../../../digi-common/services/exports-bill-of-entry.service';
import {RoadManifest, ManifestWaybill, PackType} from '../../../digi-common/models/roadManifest';
import {ExportConsignee, Exporter} from '../../../digi-common/models/exportFile';
import {
  Address,
  CargoType,
  Container, ContainerRunningTotals, ContainerSpecification,
  ContainerType, DepotTerminal,
  PortDetail, SealDetails, Solas
} from '../../../digi-common/models/file';
import {ManifestService} from '../../../digi-common/services/manifest.service';
import {PackTypeService} from '../../../digi-common/services/pack-type.service';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {Branch} from '../../../digi-common/models/company';
import {CompanyService} from '../../../digi-common/services/company.service';
import {ContainerTypeService} from '../../../digi-ocean/services/container-type.service';
import {DepotTerminalService} from '../../../digi-common/services/depot-terminal.service';
import {MasContainerTypeService} from '../../../digi-ocean/services/mas-container-type.service';
import {MasContainerSizeService} from '../../../digi-ocean/services/mas-container-size.service';
import {ContainerSizeService} from '../../../digi-ocean/services/container-size.service';
import {ContainerBehaviorSubject} from '../../../../subjects/container-behavior-subject';
import {NotificationService} from '../../../digi-common/services/notification.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {ShareDataService} from '../../../digi-common/services/share-data.service';

@Component({
  selector: 'digi-waybills',
  templateUrl: './waybills.component.html',
  styleUrls: ['./waybills.component.scss']
})
export class WaybillsComponent implements OnInit, OnChanges, OnDestroy {
  filteredBoes: ExportBillOfEntry[];
  cargoStatusCodes: SelectItem[];
  cols: any[];
  @Input() roadManifest: RoadManifest;
  @Output() onUpdateRoadManifest = new EventEmitter();
  selectedWaybill: ManifestWaybill = new ManifestWaybill();
  filteredPackTypes: PackType[];
  transportDocumentSubscription: Subscription;
  boeServiceSubscription: Subscription;
  packTypeServiceSubscription: Subscription;
  filteredContainerTypes: ContainerType[];
  filteredCargoTypes: CargoType[];
  cargoTypes: any[];
  sealStatuses: SelectItem[];
  sealingParties: SelectItem[];
  filteredDepotTerminals: DepotTerminal[];
  filteredContainerSpecifications: ContainerSpecification[];
  disableButton: boolean;
  manifestServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;
  listManifestFiles: any[];
  displayVocDialog: boolean;
  cuscarType: string;
  @ViewChild('correctionList') correctionList: OverlayPanel;
  filteredRoadManifests: RoadManifest[];
  containerSubscription: Subscription;
  selectedContainer: Container;
  constructor(
    private boeService: ExportsBillOfEntryService,
    private manifestService: ManifestService,
    private companyService: CompanyService,
    private packTypeService: PackTypeService,
    private containerTypeService: ContainerTypeService,
    private depotTerminalService: DepotTerminalService,
    private masContainerTypeService: MasContainerTypeService,
    private masContainerSizeService: MasContainerSizeService,
    private containerSizeService: ContainerSizeService,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private notificationService: NotificationService,
    private shareDataService: ShareDataService
  ) {
    this.cargoStatusCodes = [
      {label: '', value: null},
      {label: '5 - Part Shipment', value: '5'},
      {label: '9 - Full Shipment', value: '9'},
      {label: '10 - Final Part of Part Shipment', value: '10'}
    ];
    this.getBranchAndSetPackageType();

    this.cargoTypes = [
      {code: 'DB', description: 'Dry Bulk', label: 'DB - Dry Bulk'},
      {code: 'LB', description: 'Liquid Bulk', label: 'LB - Liquid Bulk'},
      {code: 'BB', description: 'Break Bulk', label: 'BB - Break Bulk'},
      {code: 'CN', description: 'Container', label: 'CN - Container'},
    ];
    this.sealingParties = [
      {label: '', value: null},
      {label: 'Carrier', value: 'Carrier'},
      {label: 'Customs', value: 'Customs'},
      {label: 'Shipper', value: 'Shipper'},
      {label: 'Terminal Operator', value: 'Terminal Operator'}
    ];
    this.sealStatuses = [
      {label: '', value: null},
      {label: 'Fine', value: 'Fine'},
      {label: 'Damaged', value: 'Damaged'}
    ];
  }

  ngOnInit() {
    this.cols = [
      {field: 'containerNo', header: 'LRN', width: '45%'},
      {field: 'hawbNo', header: 'Waybill No.', width: '45%'},
      {field: '', header: '', width: '10%'}
    ];
    this.createNewContainer();
    this.selectedContainer = new Container();
    this.selectedContainer.solas = new Solas();
    this.containerSubscription = this.containerBehaviorSubject.getContainer()
      .subscribe(ct => {
        if (ct) {
          this.selectedContainer = ct;
        } else if (this.selectedWaybill && this.selectedWaybill.containers && this.selectedWaybill.containers.length > 0) {
          this.selectedContainer = this.selectedWaybill.containers[0];
        }
      });
  }

  onTabChange(event) {
    if (event.index === 0) {
      this.cols = [
        {field: 'lrn', header: 'LRN', width: '45%'},
        {field: 'hawbNo', header: 'Waybill No.', width: '45%'},
        {field: '', header: '', width: '10%'}
      ];
    }
    if (event.index === 2) {
      this.cols = [
        {field: 'containerNo', header: 'Container No', width: '15%'},
        {field: 'sealNo', header: 'Seal No', width: '15%'},
        {field: 'containerType.code', header: 'Container Type', width: '15%'},
        {field: 'nettWeight', header: 'Gross Weight', width: '15%'},
        {field: 'noOfPackages', header: 'No Of Packages', width: '15%'},
        {field: 'cbm', header: 'CBM', width: '20%'}
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
      if (this.roadManifest.waybills.length > 0) {
        this.selectedWaybill = this.roadManifest.waybills[0];
      } else {
        this.createNewWaybill();
      }
      this.selectedContainer = new Container();
      this.selectedContainer.solas = new Solas();
      this.getBranchAndSetPackageType();
      if (!this.selectedWaybill.mawbDate) {
        this.selectedWaybill.mawbDate = this.roadManifest.masterTransportDocDate;
      }

      if (!this.selectedWaybill.mawbNo) {
        this.selectedWaybill.mawbNo = this.roadManifest.manifestNo;
      }
    }
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
      if (this.roadManifest.cuscarType) {
        this.cuscarType = this.roadManifest.cuscarType.type;
      }
    }
  }

  ngOnDestroy() {
    if (this.boeServiceSubscription) {
      this.boeServiceSubscription.unsubscribe();
    }

    if (this.packTypeServiceSubscription) {
      this.packTypeServiceSubscription.unsubscribe();
    }

    if (this.containerSubscription) {
      this.containerSubscription.unsubscribe();
    }
  }

  searchBoE(event: any) {
    this.boeServiceSubscription = this.boeService.findByHawbNoOrMawbNo(event.query).subscribe(
      data => this.filteredBoes = data
    );
  }

  addBoeToRoadManifest(boe: ExportBillOfEntry) {
    if (this.roadManifest.waybills.length === 0) {
      this.roadManifest.manifestNo = boe.mawbNumber;
    }
    const foundEntry = this.roadManifest.waybills.find(el => el.lrn === boe.lrn && boe.transportDocNo === el.hawbNo);
    if (!foundEntry) {
      this.manifestService.createWaybill(this.roadManifest, boe);
    }
  }

  selectWaybill(event) {
    this.selectedWaybill = event.data;
    if (!this.selectedWaybill.marksAndNumbers) {
      const marksAndNumbers = new BillOfEntryMarksAndNumbers();
      marksAndNumbers.marksAndNumbers = [];
      marksAndNumbers.endorsements = [];
      this.selectedWaybill.marksAndNumbers = [marksAndNumbers];
    }
  }

  deleteWaybill(index: any) {
    this.roadManifest.waybills = this.roadManifest.waybills.filter((val, i) => i !== index);
  }

  updateConsignor(value) {
    this.selectedWaybill.consignor = value;
    this.selectedWaybill.consignor.customsCode = value.defaultCustomsCode;
  }

  updateConsignee(value) {
    this.selectedWaybill.consignee = value;
    this.selectedWaybill.consignee.customsCode = value.defaultCustomsCode;
  }

  updateRoadManifest(value) {
    this.onUpdateRoadManifest.emit(value);
  }

  selectDeparturePort(value: PortDetail) {
    this.selectedWaybill.departurePort = value;
  }

  selectDestinationPort(value: PortDetail) {
    this.selectedWaybill.destinationPort = value;
  }

  createNewWaybill() {
    this.selectedWaybill = new ManifestWaybill();
    this.selectedWaybill.consignee = new ExportConsignee();
    this.selectedWaybill.consignee.address = new Address();
    this.selectedWaybill.consignor = new Exporter();
    this.selectedWaybill.consignor.address = new Address();

    const marksAndNumbers = new BillOfEntryMarksAndNumbers();
    marksAndNumbers.marksAndNumbers = [];
    marksAndNumbers.endorsements = [];
    this.selectedWaybill.marksAndNumbers = [marksAndNumbers];
    this.getBranchAndSetPackageType();
    this.roadManifest.waybills.push(this.selectedWaybill);

    if (!this.selectedWaybill.mawbDate) {
      this.selectedWaybill.mawbDate = this.roadManifest.masterTransportDocDate;
    }

    if (!this.selectedWaybill.mawbNo) {
      this.selectedWaybill.mawbNo = this.roadManifest.manifestNo;
    }
  }

  updateMarksAndNumbers(value) {
    this.selectedWaybill.marksAndNumbers = value;
  }
  private getBranchAndSetPackageType() {
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = this.roadManifest.branch ? companies[0].branches
          .find(el => el.code === this.roadManifest.branch) : companies[0].branches[0];
        for (let i = 0; i < this.roadManifest.waybills.length; i++) {
          // tslint:disable-next-line:max-line-length
          if (this.roadManifest.waybills[i].packageType === null || this.roadManifest.waybills[i].packageType === '' || typeof this.roadManifest.waybills[i].packageType === 'undefined') {
            this.roadManifest.waybills[i].packageType = branch.packageType;
          }
        }
      }
    });
  }
  searchContainerTypes(event) {
    this.containerTypeService
      .findAllContainerTypeStartsWith(event.query)
      .subscribe(data => {
        this.filteredContainerTypes = data;
      });
  }

  selectContainerType(value) {
    this.selectedContainer.containerType = value;
  }

  searchCargoTypes(event) {
    this.filteredCargoTypes = this.cargoTypes.filter(v => v.label
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredCargoTypes = [...this.filteredCargoTypes];
  }

  selectCargoType(event) {
    this.selectedContainer.cargoType = event;
  }


  createNewContainer(): Container {
    this.selectedContainer = this.shareDataService.addContainer();
    console.log(this.selectedContainer);
    return this.selectedContainer;
  }
  addContainer() {
    this.selectedContainer = this.createNewContainer();
    // this.selectedContainer.solas = new Solas();
    if (!this.selectedWaybill.containers) {
      this.selectedWaybill.containers = [];
    }
    this.selectedWaybill.containers.push(this.selectedContainer);
    this.selectedWaybill.containers = [...this.selectedWaybill.containers];
    this.containerBehaviorSubject.addContainer(this.selectedContainer);
    this.updateRunningTotals();
  }

  public updateRunningTotals() {
    this.selectedWaybill.containerRunningTotals = new ContainerRunningTotals();
    this.selectedWaybill.containerRunningTotals.capturedValue = this.selectedWaybill.containers.length;
    this.selectedWaybill.containerRunningTotals.remainingValue =
      this.selectedWaybill.noOfContainers -
      (this.selectedWaybill.containerRunningTotals.capturedValue ?
        this.selectedWaybill.containerRunningTotals.capturedValue : 0);

    this.selectedWaybill.containerRunningTotals.capturedGrossWeight = this.selectedWaybill.containers
      .map(c => c.nettWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0);

    this.selectedWaybill.containerRunningTotals.remainingGrossWeight =
      this.selectedWaybill.grossMass -
      (this.selectedWaybill.containerRunningTotals.capturedGrossWeight ?
        this.selectedWaybill.containerRunningTotals.capturedGrossWeight : 0);

    this.selectedWaybill.containerRunningTotals.capturedVolume = this.selectedWaybill.containers
      .map(c => c.cbm)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0);

    this.selectedWaybill.containerRunningTotals.remainingVolume =
      this.selectedWaybill.cbm -
      (this.selectedWaybill.containerRunningTotals.capturedVolume ?
        this.selectedWaybill.containerRunningTotals.capturedVolume : 0);

    this.selectedWaybill.containerRunningTotals.capturedNoOfPackages = this.selectedWaybill.containers
      .map(c => c.noOfPackages)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0);

    this.selectedWaybill.containerRunningTotals.remainingNoOfPackages =
      this.selectedWaybill.noOfPackages -
      (this.selectedWaybill.containerRunningTotals.capturedNoOfPackages ?
        this.selectedWaybill.containerRunningTotals.capturedNoOfPackages : 0);
  }

  searchContainerSpecs(event: any) {
    this.containerSizeService.findAllContainerSizeStartsWith(event.query)
      .subscribe(data => {
        this.filteredContainerSpecifications = data;
      });
  }

  selectContainerSpec(value) {
    this.selectedContainer.containerSpecification = value;
  }

  hasCarn() {
    return !!(this.roadManifest && this.roadManifest.carn);
  }
  saveContainer() {
      this.containerBehaviorSubject.addContainer(this.selectedContainer);
      this.notificationService.successNotify('Container Saved Successfully');
      this.updateRunningTotals();
      this.selectedContainer = new Container();
      this.selectedContainer.solas = new Solas();
  }

  selectRoadManifest(value) {
    if (this.roadManifest.manifestFileNo) {
      // tslint:disable-next-line:max-line-length
      this.manifestServiceSubscription = this.manifestService.loadAllCorrectionsIncludingOriginalManifestFile(this.roadManifest.manifestFileNo)
        .subscribe(data => {
          this.listManifestFiles = data;
        });
    }
    this.onUpdateRoadManifest.emit(value);
  }
  selectCorrection(value) {
    this.selectRoadManifest(value);
    this.correctionList.hide();
  }
  searchRoadManifestsByQuery(event: any) {
    this.manifestServiceSubscription = this.manifestService.findByHawbOrManifestFileNoOrManifestNo(event.query).subscribe(
      data => this.filteredRoadManifests = data
    );
  }
  onRowSelect(value) {
    this.containerBehaviorSubject.addContainer(this.selectedWaybill.containers[value.index]);
  }

  onRowDelete(index) {
    this.selectedWaybill.containers.splice(index, 1);
    this.selectedWaybill.containers = [...this.selectedWaybill.containers];

    if (this.selectedWaybill.containers.length === 0) {
      this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
    } else {
      this.containerBehaviorSubject.addContainer(this.selectedWaybill.containers[index - 1]);
    }
  }
  resetContainer() {
    this.selectedContainer = new Container();
    this.selectedContainer.solas = new Solas();
  }

}
