import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'digi-hawb-degroup-transit-section',
  templateUrl: './hawb-degroup-transit-section.component.html',
  styleUrls: ['./hawb-degroup-transit-section.component.css']
})
export class HawbDegroupTransitSectionComponent implements OnInit {
  @Input('transportDocument') transportDocument;
  @Input() isQuotation: boolean;

  constructor() {
  }
  ngOnInit() {
  }
}
