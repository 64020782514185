<div class="ui-g-12 ui-md-12" *ngIf="transportMethodCode === 'AIR'">
  <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
      <p-autoComplete id="portDetails" [ngModel]="selectedTransportDetail" field="label"
                      [suggestions]="filteredPortDetails"
                      (completeMethod)="search($event)"
                      [autoHighlight]="true"
                      (onSelect)="select($event)"
                      minLength="3"
                      required=true>
        <ng-template let-option pTemplate="item">
          <div>{{option.code}}: {{option.name}}</div>
        </ng-template>
      </p-autoComplete>
          <label for="portDetails">{{portLabel}}</label>
        </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="file && !file.quotation && isRequired && !selectedTransportDetail?.code || !file && isRequired && !selectedTransportDetail?.code"
         class="ui-message ui-messages-error ui-corner-all">This field is required</div>
  </span>
</div>
<span class="md-inputfield" *ngIf="(transportMethodCode === 'SEA' || transportMethodCode === 'ROA')">
  <div class="ui-inputgroup">
    <span class="ui-float-label">
    <p-autoComplete [ngModel]="selectedTransportDetail" field="label" [suggestions]="filteredPortDetails"
                    (completeMethod)="search($event)"
                    [autoHighlight]="true"
                    (onSelect)="select($event)"
                    minLength="3"
                    required=true>
      <ng-template let-option pTemplate="item">
        <div>{{option.code}}: {{option.name}}</div>
      </ng-template>
    </p-autoComplete>
       <label for="portDetails">{{portLabel}}</label>
        </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>
  <div *ngIf="!file?.quotation && isRequired && !selectedTransportDetail?.code"
       class="ui-message ui-messages-error ui-corner-all">This field is required</div>
</span>


