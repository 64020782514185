import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransporterService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findTransporterWithCodeOrName( code: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/transporters/search/codeOrNameStartsWith?transporterCode=${code}`)
      .pipe(
        map(response => response['_embedded'].transporters)
      );
  }
}
