import { Component, OnInit } from '@angular/core';
import { ClearingFile } from '../../../digi-common/models/clearingFile';
import { ClearingInstructionsService } from '../../../digi-common/services/clearing-instructions.service';
import { ShareDataService } from '../../../digi-common/services/share-data.service';


@Component({
  selector: 'digi-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  clearingFile: ClearingFile;
  clearingInstructionsObject = [];
  clearingInstructionsInfo = [];
  producedDocuments = [];
  requiredDocuments = [];
  selectedProducedDocuments = [];
  selectedRequiredDocuments = [];

  constructor() {
  }

  ngOnInit() {
  }


}
