import { Component, OnInit } from '@angular/core';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';
import { BillOfEntry, BillOfEntryLine, Destination } from '../../models/billOfEntries';
import { ShareDataService } from '../../services/share-data.service';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';

@Component({
  selector: 'digi-boe-lines-top-section',
  templateUrl: './boe-lines-top-section.component.html',
  styleUrls: ['./boe-lines-top-section.component.scss']
})
export class BoeLinesTopSectionComponent implements OnInit {
  destination: Destination;
  billOfEntry: BillOfEntry;
  selectedBillOfEntryLine: BillOfEntryLine;

  constructor(private shareDataService: ShareDataService,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.billOfEntry = boe;
      });
    this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
      });
  }
}
