<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="selectedTransportDocument.voyageDetails.voyageNo"
                           required>
                   <label>Voyage No</label>
                 </span>
              </div>
              <div *ngIf="!clearingFile.quotation && !selectedTransportDocument.voyageDetails.voyageNo"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
        </div>
        <div *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="selectedTransportDocument.voyageDetails.customsRadioCallSign"
                                field="vesselName"
                                [suggestions]="filteredCustomsRadioCallSigns"
                                (completeMethod)="search($event, 'vesselName')"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.vesselName}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Vessel Name</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
             <div *ngIf="!clearingFile.quotation && !selectedTransportDocument.voyageDetails.customsRadioCallSign.vesselName"
                  class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
        </div>
        <div *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="selectedTransportDocument.voyageDetails.customsRadioCallSign"
                                field="radioCallSign"
                                [suggestions]="filteredCustomsRadioCallSigns"
                                (completeMethod)="search($event, 'radioCallSign')"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.radioCallSign}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Radio Call Sign</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
             <div *ngIf="!clearingFile.quotation && !selectedTransportDocument.voyageDetails.customsRadioCallSign.radioCallSign"
                  class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
        </div>
        <div *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'ROA'" class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="selectedTransportDocument.voyageDetails.remover"
                                field="name"
                                [suggestions]="filteredRemovers"
                                (completeMethod)="searchRemover($event)"
                                [autoHighlight]="true" minLength="2"
                                required=true
                >
                  <ng-template let-option pTemplate="item">
                    <div>{{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Remover</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
             <div *ngIf="!selectedTransportDocument.voyageDetails.remover"
                  class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
          </span>
        </div>
        <div *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'ROA'" class="ui-g-12 ui-md-12">
          <span class="ui-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         pInputText
                         [(ngModel)]="selectedTransportDocument.voyageDetails.vehicleAndTrailerReg"
                         required>
                 <label>Vehicle & Trailer(s) Reg</label>
               </span>
            </div>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-port-details
            portLabel='Departure Port'
            [transportDetails]="selectedTransportDocument.departurePort"
            [file]="clearingFile"
            [transportMethodCode]="isSeaTransportDocument() ? 'SEA':'ROA'"
          >
          </digi-port-details>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-port-details
            portLabel='Docs issue at Port'
            [transportDetails]="selectedTransportDocument.docsIssuedAtPort"
            [file]="clearingFile" [transportMethodCode]="isSeaTransportDocument() ? 'SEA':'ROA'"></digi-port-details>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-port-details
            portLabel='Discharge Port'
            [transportDetails]="selectedTransportDocument.dischargePort"
            [file]="clearingFile"
            [transportMethodCode]="isSeaTransportDocument() ? 'SEA':'ROA'"
            (onSelect)="defaultDestinationPort($event)"></digi-port-details>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-port-details portLabel='Destination Port'
                             [transportDetails]="selectedTransportDocument.destinationPort"
                             [transportMethodCode]="isSeaTransportDocument() ? 'SEA':'ROA'"
                             [file]="clearingFile"></digi-port-details>
        </div>
          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="departureDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="selectedTransportDocument.departureDate">
                </p-inputMask>
                <label for="departureDate">Departure Date</label>
            </span>
          <div *ngIf="!clearingFile.quotation && !selectedTransportDocument.departureDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
          </div>
          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="arrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="selectedTransportDocument.arrivalDate">
            </p-inputMask>
            <label for="arrivalDate">Arrival Date</label>
        </span>
          <div *ngIf="!clearingFile.quotation && !selectedTransportDocument.arrivalDate" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
        </span>
          </div>
      </div>
    </div>
  </div>
</div>
