import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClearingInstructionBehaviorSubject} from '../../../../../../subjects/clearingInstruction-behavior-subject';
import {ImporterService} from '../../../../services/importer.service';
import {Address, ClearingInstruction, Consignee} from '../../../../models/file';
import {combineLatest, EMPTY, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'digi-clearing-instruction-importer-consignee',
  templateUrl: './clearing-instruction-importer-consignee.component.html',
  styleUrls: ['./clearing-instruction-importer-consignee.component.scss']
})
export class ClearingInstructionImporterConsigneeComponent implements OnInit, OnDestroy {
  clearingInstruction: ClearingInstruction;
  filteredConsignees: Consignee[];
  subscription: Subscription;
  subscribe: Subscription;
  consigneeAddresses = [];
  display: boolean;
  importerConsignees: Consignee[];
  importerConsigneeAddresses = [];

  constructor(private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private importerService: ImporterService) {
  }

  ngOnInit() {
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    const importerConsigneeObservable = this.clearingInstructionBehaviorSubject.getImporterConsignee();
    this.subscription = combineLatest(instructionsObservable, importerConsigneeObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingInstruction = tuple[0];
            if (this.clearingInstruction) {
              this.clearingInstruction.importerConsignee = Object.assign({}, this.clearingInstruction.importerConsignee);
            }
          }
          if (tuple[1]) {
            this.importerConsignees = tuple[1];
          }
        }),
      switchMap((tuple: any[]) => {
        if (this.clearingInstruction && this.importerConsignees) {
          return this.importerService.findImporterNameOrCodeStartsWith(this.importerConsignees[0].code);
        }
        return EMPTY;
      })
    ).subscribe(importer => {
      this.importerConsigneeAddresses = importer[0].addresses;
    });

    if (this.importerConsignees && this.importerConsignees.length === 1) {
      this.updateSelectedToConsignee(this.importerConsignees[0]);
    } else if (this.clearingInstruction) {
      this.clearingInstruction.importerConsignee = new Consignee();
    }
  }

  search(event) {
    this.filteredConsignees = [];
    if (this.importerConsignees.length > 0) {
      for (let i = 0; i < this.importerConsignees.length; i++) {
        const importerConsignee: Consignee = this.importerConsignees[i];
        if (
          importerConsignee.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
          importerConsignee.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        ) {
          this.filteredConsignees.push(importerConsignee);
        }
      }
    }
  }

  selectConsignee(importerConsignee) {
    this.updateSelectedToConsignee(importerConsignee);
  }

  private updateSelectedToConsignee(importerConsignee) {
    this.clearingInstruction.importerConsignee = new Consignee();
    this.clearingInstruction.importerConsignee.code = importerConsignee.code;
    this.clearingInstruction.importerConsignee.name = importerConsignee.name;
    this.clearingInstruction.importerConsignee.label = importerConsignee.label;
    this.consigneeAddresses = importerConsignee.addresses;

    this.importerService.findImporterNameOrCodeStartsWith(importerConsignee.code)
      .subscribe(importerConsignee => {
        if (importerConsignee) {
          this.clearingInstruction.importerConsignee.customsCode = importerConsignee[0].customsCode;
          this.clearingInstruction.importerConsignee.taxRegNo = importerConsignee[0].taxRegNo;

          this.clearingInstruction.importerConsignee.address = new Address();
          this.clearingInstruction.importerConsignee.address.line1 = importerConsignee[0].addresses[0].line1;
          this.clearingInstruction.importerConsignee.address.line2 = importerConsignee[0].addresses[0].line2;
          this.clearingInstruction.importerConsignee.address.line3 = importerConsignee[0].addresses[0].line3;
          this.clearingInstruction.importerConsignee.address.line4 = importerConsignee[0].addresses[0].line4;
          this.clearingInstruction.importerConsignee.address.zipCode = importerConsignee[0].addresses[0].zipCode;
          this.importerConsigneeAddresses = importerConsignee[0].addresses;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
