import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VoyageFile} from '../../models/voyageFile';
import {Subscription} from 'rxjs';
import {VoyageFileService} from '../../services/voyage-file.service';

@Component({
  selector: 'digi-ships-agent-header',
  templateUrl: './ships-agent-header.component.html',
  styleUrls: ['./ships-agent-header.component.scss']
})
export class ShipsAgentHeaderComponent implements OnInit {

  @Input() voyageFile: VoyageFile;
  @Input() indicator: string;
  @Input() showNewButton = true;
  filteredVoyageFiles: VoyageFile[];

  voyageFileServiceSubscription: Subscription;
  @Output() updateVoyageFiles = new EventEmitter();
  @Output() updateVoyageFile = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() createNew = new EventEmitter();
  disableButton: boolean;
  @Output() createNewVoyageFile = new EventEmitter();
  constructor(
    private voyageFileService: VoyageFileService
  ) { }

  ngOnInit() {
  }

  searchVoyageFiles(event: any) {
    this.disableButton = true;
    this.voyageFileServiceSubscription = this.voyageFileService.findVoyageFilesByVoyageFileNo(event.query, this.indicator).subscribe(
      (voyageFiles: VoyageFile[]) => {
        this.filteredVoyageFiles = voyageFiles;
        this.disableButton = false;
      },
    );
  }

  selectVoyageFile(voyageFile: VoyageFile) {
    this.voyageFileServiceSubscription = this.voyageFileService.findVoyageFilesByVoyageNo(voyageFile.voyageNo, this.indicator).subscribe(
      (voyageFiles: VoyageFile[]) => {
        this.voyageFile = voyageFiles.find(el => el.voyageFileNo === voyageFile.voyageFileNo)
        this.updateVoyageFile.emit(this.voyageFile);
        this.updateVoyageFiles.emit(voyageFiles);
      },
    );
  }

  onCreateNew() {
    this.createNew.emit(true);
  }

  onSave() {
    this.save.emit(true);
  }
}
