<div class="ui-g ui-fluid">
  <div class="ui-g-6">
    <p-panel header="Marks & Numbers">
      <p-scrollPanel [style]="{width: '100%', height: '200px'}">
        <p-dataView [value]="marksAndNumbers">
          <ng-template let-marksAndNumber pTemplate="listItem">
            <div class="ui-g" style="padding: 1em;border-bottom: 1px solid #d9d9d9">
              <label>{{marksAndNumber}}</label>
            </div>
          </ng-template>
        </p-dataView>
      </p-scrollPanel>
    </p-panel>
  </div>
  <div class="ui-g-6">
    <p-panel header="Endorsments">
      <p-scrollPanel [style]="{width: '100%', height: '200px'}">
        <p-dataView [value]="endorsements">
          <ng-template let-endorsement pTemplate="listItem">
            <div class="ui-g" style="padding: 1em;border-bottom: 1px solid #d9d9d9">
              {{endorsement}}
            </div>
          </ng-template>
        </p-dataView>
      </p-scrollPanel>
    </p-panel>
  </div>
</div>
