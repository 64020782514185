<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad pt-2">
    <div class="ui-inputgroup">
       <span class="ui-float-label">
      <p-autoComplete [(ngModel)]="portTo" field="name" [suggestions]="filteredPortTo"
                      (completeMethod)="search($event)"
                      [autoHighlight]="true" (onSelect)="select($event)" minLength="3">
        <ng-template let-option pTemplate="item">
          <div>{{option.code}}: {{option.name}}</div>
        </ng-template>
      </p-autoComplete>
         <label>Port to</label>
       </span>
      <span class="ui-inputgroup-addon">
          <i class="fa fa-search"></i>
        </span>
    </div>
  </div>
</div>
