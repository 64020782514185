import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaxTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findTaxTypeByCodeStartWith(searchValue) {

    return this.http
      .get(`${this.config.apiEndpoint}api/taxTypes/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].taxTypes)
      );
  }

  findByCode(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/taxTypes/search/findByCode?code=` + code);
  }
}
