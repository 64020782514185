<p-tabView orientation="left" styleClass="stepMenu" (activeIndexChange)="handleIndexChange($event)">
  <p-tabPanel header="Cost File" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
              <button class="ui-button-secondary" pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewCostingFile()" [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-12 ui-md-12">
        <p-table [columns]="cols" [value]="costingFiles" selectionMode="single"
                 columnResizeMode="expand"
                 [(selection)]="costingFile" [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.fileNumber}}</td>
              <td>{{selection.description}}</td>
            </tr>
          </ng-template>
        </p-table>

      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-panel header="Costing File Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-6">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText disabled
                                     [ngModel]="costingFile.fileNumber">
                              <label>Costing File Number</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="costingFile.description" [rows]="3" [cols]="50"
                                    placeholder="Description"
                                    style="resize: none">
                          </textarea>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="costingFile.invoiceMarkupPercentage"
                                   pInputText>
                            <label>Invoice Mark Up %</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="costingFile.landedCostMarkupPercentage"
                                   pInputText>
                            <label>Landed Cost Mark Up %</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                          <span class="ui-float-label">
                            <p-inputMask id="formulaEffectiveFrom"
                                   mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="costingFile.exchangeRateDate">
                            </p-inputMask>
                            <label for="formulaEffectiveFrom">Exchange Rate Date</label>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="For Insurance?" [(ngModel)]="costingFile.forInsurance"
                                    binary="true">
                        </p-checkbox>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="Use Client Reference No as Part Number?" [(ngModel)]="costingFile.useClientRefNoAsPartNo"
                                    binary="true">
                        </p-checkbox>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="Apportion Invoice Sundries by Housebill?" [(ngModel)]="costingFile.apportionSundriesByHousebill"
                                    binary="true">
                        </p-checkbox>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="Exclude All VAT?" [(ngModel)]="costingFile.excludeAllVat"
                                    binary="true">
                        </p-checkbox>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="Exclude BOE VAT?" [(ngModel)]="costingFile.excludeBoeVat"
                                    binary="true">
                        </p-checkbox>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <p-checkbox label="Include Duties & VAT for Warehouse Entries" [(ngModel)]="costingFile.includeDutiesAndVatForWarehouseEntries"
                                    binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Cost Line">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{costingFile?.fileNumber}}" readonly
                           style="color: white;">
                    <label style="color: white;">Costing File Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
              <button class="ui-button-secondary" pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewCostingFile()" [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12">
          <p-pickList [source]="availableLines" [target]="allocatedLines"
                      sourceHeader="Available Lines" targetHeader="Allocated Lines" [responsive]="true"
                      [showSourceControls]="false" [showTargetControls]="false"
                      (onMoveToTarget)="selectLine($event)"
                      (onMoveToSource)="onMoveToSource($event)"
                      [dragdrop]="true" dragdropScope="lines"
          >
            <ng-template let-line pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="ui-g">
                  <div class="ui-g-12 ui-md-12">
                    Invoice: {{line.supplierCode + " - " +line.invoiceNumber}}
                  </div>
                  <div class="ui-g-offset-1 ui-g-11 ui-md-11 ">
                    {{line.lineNo + " - " + line.tariffCode + " - " + line.partNumber}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-pickList>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Cost Units">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{costingFile?.fileNumber}}" readonly
                           style="color: white;">
                    <label style="color: white;">Costing File Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
              <button class="ui-button-secondary" pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewCostingFile()" [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-12 ui-md-12">
        <p-table [columns]="costUnitCols" [value]="costingFile.costingLines" selectionMode="single"
                 columnResizeMode="expand"
                 [(selection)]="costingLine"
                 (onRowSelect)="selectCostingLine($event.data)"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.invoiceNumber}}</td>
              <td>{{selection.lineNo}}</td>
              <td>{{selection.lineNo.toString().padStart(3, '0')}}</td>
              <td>{{selection.partNumber}}</td>
              <td>{{selection.tariffDescription}}</td>
              <td>{{selection.clientRefNo}}</td>
              <td>{{selection.additionalClientReference}}</td>
              <td>{{selection.costingUom?.uomCode}}</td>
              <td>{{selection.costingValue}}</td>
              <td>{{selection.invoiceMarkupPercentage}}</td>
              <td>{{selection.landedCostMarkupPercentage}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Costing Line Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="costingLine.clientRefNo">
                              <label>Client Ref. No.</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="costingLine.additionalClientReference">
                              <label>Additional Client Ref.</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="costingUom" [(ngModel)]="costingLine.costingUom"
                                                  field="uomCode"
                                                  [autoHighlight]="true"
                                                  [suggestions]="filteredUoms"
                                                  (completeMethod)="searchUoms($event)"
                                                  (onSelect)="selectUom($event)"
                                                  [dropdown]="true"
                                                  minLength="1">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="costingUom">Costing UOM</label>
                  </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="costingLine.costingValue">
                              <label>Number of Costing Units</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="costingLine.invoiceMarkupPercentage"
                                   pInputText>
                            <label>Invoice Mark Up %</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="costingLine.landedCostMarkupPercentage"
                                   pInputText>
                            <label>Landed Cost Mark Up %</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Exchange Rates">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{costingFile?.fileNumber}}" readonly
                           style="color: white;">
                    <label style="color: white;">Costing File Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-3 ui-md-3">
        <p-table [columns]="[{}]" [value]="exchangeRates"
                 columnResizeMode="expand"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="caption">
            Exchange Rates
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>Invoice Number</th>
              <th>Exchange Rate</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.invoiceNumber}}</td>
              <td><input pInputText [(ngModel)]="selection.exchangeRate" (ngModelChange)="calculateRevisedValue(selection.invoiceNumber, $event)"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-12 ui-md-12">
        <p-table [columns]="exchangeRatesCols" [value]="costingFile.costingLines" selectionMode="single"
                 columnResizeMode="expand"
                 [(selection)]="costingLine"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="caption">
            Costing Lines
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.lineNo}}</td>
              <td>{{rowIndex.toString().padStart(3, '0')}}</td>
              <td>{{selection.partNumber}}</td>
              <td>{{selection.clientRefNo}}</td>
              <td>{{selection.invoiceGrossValue}}</td>
              <td>{{selection.revisedValue}}</td>
              <td>{{selection.exchangeRate}}</td>
              <td>{{selection.localInvoiceGrossValue}}</td>
              <td>{{getCurrency(selection)}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Costing Line Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="costingLine.revisedValue">
                              <label>Revised Value</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="costingLine.exchangeRate"
                                     (ngModelChange)="calculateRevisedValue(costingLine.invoiceNumber, $event)">
                              <label>Exchange Rate</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="getSupplierName(costingLine)" disabled>
                              <label>Exporter</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="getCountry(costingLine)" disabled>
                              <label>Country</label>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Charges">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{costingFile?.fileNumber}}" readonly
                           style="color: white;">
                    <label style="color: white;">Costing File Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
              <button class="ui-button-secondary" pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewCost()" [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-8 ui-md-8">
        <p-table [columns]="fileCostsCols" [value]="costingFile.costs" selectionMode="single"
                 columnResizeMode="expand"
                 [(selection)]="selectedCost"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.chargeType?.description}}</td>
              <td>{{selection.amount}}</td>
              <td>{{selection.costingItem?.code}}</td>
              <td>
                <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                        (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-4 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-panel header="File Costs">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="chargeType" [(ngModel)]="selectedCost.chargeType"
                                                  field="label"
                                                  [suggestions]="filteredChargeTypes"
                                                  (completeMethod)="searchChargeTypes($event)"
                                                  [dropdown]="true"
                                                  minLength="1">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="costingUom">Charge Type</label>
                  </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="selectedCost.amount">
                              <label>Amount</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="costingItem" [(ngModel)]="selectedCost.costingItem"
                                                  field="label"
                                                  [suggestions]="filteredCostingItems"
                                                  (completeMethod)="searchCostingItems($event)"
                                                  (onSelect)="selectCostingItem($event)"
                                                  [dropdown]="true"
                                                  [autoHighlight]="true"
                                                  minLength="1">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="costingItem">Apportion By</label>
                  </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
      <div class="ui-g-8 ui-md-8">
        <p-table [columns]="supplierInvoiceCostsCols" [value]="costingFile.supplierInvoiceCosts" selectionMode="single"
                 columnResizeMode="expand"
                 [(selection)]="selectedSupplierInvoiceCost"
                 dataKey="lineNo"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
            <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
              <td>{{selection.invoiceNo}}</td>
              <td>{{selection.chargeType?.description}}</td>
              <td>{{selection.amount}}</td>
              <td>{{selection.costingItem?.code}}</td>
              <td>
                <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                        (click)="onSupplierInvoiceCostRowDelete(rowIndex)" pTooltip="Delete"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-4 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-panel header="Supplier Invoice Costs">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group" >
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [(ngModel)]="selectedSupplierInvoiceCost.amount">
                              <label>Amount</label>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="selectedSupplierInvoiceCostCostingItem" [(ngModel)]="selectedSupplierInvoiceCost.costingItem"
                                                  field="label"
                                                  [suggestions]="filteredCostingItems"
                                                  (completeMethod)="searchCostingItems($event)"
                                                  (onSelect)="selectCostingItem($event)"
                                                  [dropdown]="true"
                                                  minLength="1">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="selectedSupplierInvoiceCostCostingItem">Apportion By</label>
                  </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Generate">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <digi-clearing-file-search></digi-clearing-file-search>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{clearingFile?.importer?.name}}" readonly
                           style="color: white;">
                    <label style="color: white;">Importer</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText value="{{costingFile?.fileNumber}}" readonly
                           style="color: white;">
                    <label style="color: white;">Costing File Number</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCostingFile()"
                      [disabled]="disableButton"></button>
              <button class="ui-button-secondary" pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewCostingFile()" [disabled]="disableButton"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-1 ui-md-1">
          <button pButton type="button" icon="fa fa-print" pTooltip="Generate Costing Sheet"
                  tooltipPosition="top"
                  class="ui-button-secondary col-sm-12"
                  (click)="generate()" icon="fa fa-print" [disabled]="disableButton"></button>
          <br>
          <p-dialog header="Generated Documents" [(visible)]="display" [modal]="true"
                    [responsive]="true" [width]="600"
                    [minWidth]="200" [minY]="70"
                    [maximizable]="true" [baseZIndex]="10000">
            <ng-template pTemplate="content" [ngIf]="generatedFiles">
              <li *ngFor="let generatedDocument of generatedFiles">
                <a class="uploaded-file-color"
                   [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
              </li>
            </ng-template>
            <p-footer>
              <button type="button" pButton icon="pi pi-close" (click)="display=false"
                      label="Close"
                      class="ui-button-secondary"></button>
            </p-footer>
          </p-dialog>
        </div>
        <div class="ui-g-11 ui-md-11">
          <div class="ui-g-12 ui-md-12">
            <p-table [value]="costingFile.costingLines" selectionMode="single"
                     columnResizeMode="expand"
                     [(selection)]="costingLine"
                     [paginator]="true" [rows]="5">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th>Invoice</th>
                  <th>Inv. Line</th>
                  <th>Cost Line</th>
                  <th>Part Number</th>
                  <th>Client Ref. No.</th>
                  <th>Unit Cost</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
                <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                  <td>{{selection.invoiceNumber}}</td>
                  <td>{{selection.lineNo}}</td>
                  <td>{{selection.lineNo.toString().padStart(3, '0')}}</td>
                  <td>{{selection.partNumber}}</td>
                  <td>{{selection.clientRefNo}}</td>
                  <td>{{selection.unitCost}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-12 ui-md-12 ui-g-nopad">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-panel header="Totals">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-6">
                        <div class="ui-g form-group" >
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.invoicedCost">
                              <label>Invoiced Cost</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.invoiceMarkup">
                              <label>Invoiced Cost Mark Up</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.totalApportionedCost">
                              <label>Apportioned Cost</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.customsDuty">
                              <label>Customs Duty</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.customsVAT">
                              <label>Customs VAT</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.landedCostMarkup">
                              <label>Landed Cost Mark Up</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.totalCost">
                              <label>Total Cost</label>
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ui-g-6">
                        <div class="ui-g form-group" >
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.costingValue">
                              <label>No. of Units</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.costingUom?.uomCode">
                              <label>Costing UOM</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                              <input type="text" pInputText
                                     [ngModel]="costingLine.unitCost">
                              <label>Unit Cost</label>
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <p-table [value]="costingLine.apportionedFileCosts"
                     columnResizeMode="expand"
                     [paginator]="true" [rows]="5">
              <ng-template pTemplate="caption">
                File Apportioned Cost Details
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th>Charge Type</th>
                  <th>Apportioned by</th>
                  <th>Apportioned Cost</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
                <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                  <td>{{selection.chargeType?.description}}</td>
                  <td>{{selection.costingItem?.code}}</td>
                  <td>{{selection.amount}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

    </ng-template>
  </p-tabPanel>
</p-tabView>
