import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { BillOfEntry } from '../../models/billOfEntries';
import { Correction } from '../../models/file';
import { DifferenceValues } from '../../models/differenceValues';
import { BillOfEntryService } from '../../services/bill-of-entry.service';
import { ShareDataService } from '../../services/share-data.service';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';

@Component({
  selector: 'digi-before-and-after-payment-details',
  templateUrl: './before-and-after-payment-details.component.html',
  styleUrls: ['./before-and-after-payment-details.component.scss']
})
export class BeforeAndAfterPaymentDetailsComponent implements OnInit, OnDestroy {
  private sequence;
  previousBillOfEntry: BillOfEntry = new BillOfEntry();
  selectedBillOfEntry: BillOfEntry;
  @Input('isGenerated') isGenerated;
  differenceValues = new DifferenceValues();
  changesOnBillOfEntry: BillOfEntry;
  file: Correction;
  subscription: Subscription;
  billSubscription: Subscription;
  parent: any;
  @Input() fileType: FileType;
  currSch12b: number;
  prevSch12b: number;

  constructor(private billOfEntryService: BillOfEntryService, private shareDataService: ShareDataService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject
              ) {
  }

  ngOnInit() {
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject
        .getClearingFile()
        .subscribe(cf =>
          this.file = cf);
    } else {
      this.subscription = this.exportFileBehaviourSubject
        .getExportFile()
        .subscribe(ef =>
          this.file = ef);
    }
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(bill => {
        this.selectedBillOfEntry = bill;
        if (this.file.id !== undefined) {
          this.billOfEntryService.findParentClearingFile(this.file.id, this.fileType)
            .subscribe(data => {
              this.parent = data;
              if (this.parent.sequence !== undefined && this.selectedBillOfEntry.lrn !== undefined) {
                this.billOfEntryService.findPreviousBillOfEntry(this.selectedBillOfEntry.lrn, this.parent.sequence, this.selectedBillOfEntry.parentMarkedAsSubmitted)
                  .subscribe(previousData => {
                    this.previousBillOfEntry = previousData;
                    if (this.selectedBillOfEntry.id !== undefined) {
                      this.billOfEntryService.findBillOfEntry(this.selectedBillOfEntry)
                        .subscribe(currentData => {
                          this.changesOnBillOfEntry = currentData;
                          this.differenceValues.diffCIFCValue =
                            this.changesOnBillOfEntry.cifcValue - this.previousBillOfEntry.cifcValue;
                          this.differenceValues.diffAmountDue =
                            this.changesOnBillOfEntry.totalAmountDue - this.previousBillOfEntry.totalAmountDue;
                          this.differenceValues.diffCustomsDuty =
                            this.changesOnBillOfEntry.totalCustomsDuty - this.previousBillOfEntry.totalCustomsDuty;
                          this.differenceValues.diffCustomsVAT =
                            this.changesOnBillOfEntry.totalCustomsVAT - this.previousBillOfEntry.totalCustomsVAT;
                          this.differenceValues.diffCustomValue =
                            this.changesOnBillOfEntry.totalCustomsValue - this.previousBillOfEntry.totalCustomsValue;

                          const currSch12b = this.changesOnBillOfEntry.schedulePartTotals.find(schedule => schedule.schedulePart.code === '12B');
                          const prevSch12b = this.previousBillOfEntry.schedulePartTotals.find(schedule => schedule.schedulePart.code === '12B');
                          this.currSch12b = currSch12b ? currSch12b.totalValue : 0.00;
                          this.prevSch12b = prevSch12b ? prevSch12b.totalValue : 0.00;
                          this.differenceValues.diffSch12b = this.currSch12b - this.prevSch12b;
                        });
                    }
                  });
              }
            });
        }
      });
  }

  hasRemovalTransportMethod() {
    return this.file && this.file.clearingInstructions
      && this.file.clearingInstructions[0].removalTransportMethod
      && this.file.clearingInstructions[0].removalTransportMethod.method === 'ROA';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.billSubscription.unsubscribe();
  }
}
