import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {TariffCodeReference, TariffDescriptionHistory} from '../models/TariffCode';
import {FileService} from './file.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig,
              private fileService: FileService) {
  }

  findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(searchValue) {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/codeOrDescriptionStartsWithCurrentlyEffectiveAndIsTariff?code=`
        + searchValue + '&date=' +  moment().format('DD/MM/YYYY') + '&' + 'projection=tariffCodeWithFullDescription')
      .pipe(
        map(response => response['_embedded'].tariffCodes)
      ).pipe(
        tap(_ => this.fileService.setIsLoadingTariffData(false))
      );
  }

  findByCodeList(codes: string[], date: string) {
    return this.http
      .post(`${this.config.apiEndpoint}api/tariffCodes/search/findByPartDescription?&mawbDate=${date}&projection=tariffCodeWithFullDescription`, codes)
      .pipe(
        map(response => response['_embedded'].tariffCodes)
      );
  }

  findTariffByCodeStartsWithCurrentlyEffective(searchValue) {
    if (searchValue === '') {
      return of([]);
    }

    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/codeOrDescriptionStartsWithCurrentlyEffective?codeOrDescription=`
        + searchValue + '&' + 'projection=tariffCodeWithFullDescription')
      .pipe(
        map(response => response['_embedded'].tariffCodes)
      );
  }

  findByTariffCodeSchedulePartAndDependentCode(tariffCode, schedulePart, dependantCode) {
    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/codeStartsWithCurrentlyEffectiveAndScheduleCode?code=`
        + tariffCode + '&schedule=' + schedulePart + '&dependantCode=' + dependantCode + '&projection=tariffCodeWithFullDescription')
      .pipe(
        map(response => response['_embedded'].tariffCodes)
      );
  }

  saveTariffDescriptionHistory(tariffDescriptionHistory: TariffDescriptionHistory): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.apiEndpoint + 'api/tariffDescriptionHistories', tariffDescriptionHistory, {headers});
  }

  saveTariffFormula(tariffFormulaAndTariffParameter: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.apiEndpoint + 'api/tariffFormula', tariffFormulaAndTariffParameter, {headers});
  }

  saveTariffCode(tariffCode: TariffCodeReference): Observable<any> {
    console.log(tariffCode);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.apiEndpoint + 'api/tariffCodes', tariffCode, {headers});
  }

  findTariffCodeByTariffId(tariffId: number) {
    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/findByTariffId?tariffID=${tariffId}&projection=tariffCodeWithFullDescription`)
      .pipe(
        map(response => response)
      );
  }

  findTariffCodeByCodeAndCheckDigit(code, checkDigit) {
    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/findByCodeAndCheckDigit?code=${code}&checkDigit=${checkDigit}`);
  }

  findTariffByCodeStartsWith(code) {
    return this.http
      .get(`${this.config.apiEndpoint}api/tariffCodes/search/codeStartsWith?code=`
        + code  + '&' + 'projection=tariffCodeWithFullDescription')
      .pipe(
        map(response => response['_embedded'].tariffCodes)
      );
  }
}
