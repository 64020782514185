<div class="ui-g-12">
  <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false" (activeIndexChange)="saveRoadManifest()"></p-steps>
</div>
<ng-container *ngIf="view==='register'">
  <div class="ui-g-12">
    <p-tabView orientation="left" styleClass="stepMenu">
      <p-tabPanel header="Register" [selected]="true">
        <ng-template pTemplate="content">
          <div class="ui-g-12">
            <digi-header [roadManifest]="roadManifest"
                         (onUpdateRoadManifest)="updateRoadManifest($event)"
                         (onCreateNew)="createNewRoadManifest()"></digi-header>
            <digi-manifest-register [roadManifest]="roadManifest"
                                    [selectedCrewMember]="selectedCrewMember"></digi-manifest-register>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</ng-container>
<ng-container *ngIf="view==='waybills'">
  <digi-waybills [roadManifest]="roadManifest" (onUpdateRoadManifest)="updateRoadManifest($event)"></digi-waybills>
</ng-container>
<ng-container *ngIf="view==='manifest'">
  <div class="ui-g-12">
    <p-tabView orientation="left" styleClass="stepMenu">
      <p-tabPanel header="Generate">
        <ng-template pTemplate="content">
          <div class="ui-g-12">
            <digi-header [roadManifest]="roadManifest" (onUpdateRoadManifest)="updateRoadManifest($event)"></digi-header>
            <digi-manifest-edi [view]="'review'" [roadManifest]="roadManifest" (updateRoadManifest)="updateRoadManifest($event)"></digi-manifest-edi>
          </div>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Confirm Manifest">
        <ng-template pTemplate="content">
          <div class="ui-g-12">
            <digi-header [roadManifest]="roadManifest" (onUpdateRoadManifest)="updateRoadManifest($event)"></digi-header>
            <digi-manifest-edi [view]="'edi'" [roadManifest]="roadManifest" (updateRoadManifest)="updateRoadManifest($event)"></digi-manifest-edi>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</ng-container>
