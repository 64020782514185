<div class="content-section implementation">
  <p-table [columns]="cols" [value]="billsOfEntry.boes" selectionMode="single" [(selection)]="selectedBillOfEntry" [resizableColumns]="true"
    (onRowSelect)="onRowSelect($event)" [resizableColumns]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn>
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body"   let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td  class="ui-resizable-column">
          <label>{{rowData.houseNumber}}</label>
        </td>
        <td  class="ui-resizable-column">
          <label>{{rowData.lrn}}</label>
        </td>
        <td  class="ui-resizable-column">
          <label>{{rowData.serialNumber}}</label>
        </td>
        <td  class="ui-resizable-column">
          <label>{{rowData.cpc}}</label>
        </td>
        <td *ngIf="fileType!=='exbond'" class="ui-resizable-column">
          <label>{{rowData.transportDocNo}}</label>
        </td>
        <td *ngIf="fileType!=='exbond'" class="ui-resizable-column">
          <label>{{rowData.assessmentDate}}</label>
        </td>
        <td  class="ui-resizable-column">
          <label>{{rowData.noOfLines}}</label>
        </td>
        <td *ngIf="fileType!=='exbond'" class="ui-resizable-column">
          <label>{{rowData.export.countryCode}}</label>
        </td>
        <td *ngIf="fileType!=='exbond'" class="ui-resizable-column">
          <label>{{rowData.destination.code}}</label>
        </td>
        <td *ngIf="fileType!=='exbond'" class="ui-resizable-column">
          <label>{{rowData.sobDate}}</label>
        </td>
        <td *ngIf="fileType==='exbond'" class="ui-resizable-column">
          <label>{{rowData.localAgent?.code}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
