import {ClearingFile} from '../../models/clearingFile';
import {SupplierInvoice} from '../../models/file';
import {PurchaseTermService} from '../../services/purchase-term.service';
import {CountryService} from '../../services/country.service';
import {CurrencyService} from '../../services/currency.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShareDataService} from '../../services/share-data.service';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-invoice-supplier-importer-details',
  templateUrl: './invoice-supplier-importer-details.component.html',
  styleUrls: ['./invoice-supplier-importer-details.component.css']
})
export class InvoiceSupplierImporterDetailsComponent implements OnInit, OnDestroy {
  @Input() clearingFile: ClearingFile;
  @Input() supplierInvoice: SupplierInvoice;
  selectedSupplierInvoice: SupplierInvoice;
  unsubscribe: Subscription;

  constructor(
    private shareDataService: ShareDataService,
    private currencyService: CurrencyService,
    private countryService: CountryService,
    private purchaseTermService: PurchaseTermService,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.unsubscribe = this.supplierInvoiceBehaviourSubject
      .getSupplierInvoice()
      .subscribe(si => {
        if (si) {
          this.selectedSupplierInvoice = si;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }
}
