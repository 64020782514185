import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CostingFileService = /** @class */ (function () {
    function CostingFileService(http, config) {
        this.http = http;
        this.config = config;
    }
    CostingFileService.prototype.saveCostingFile = function (costingFile, clearingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        costingFile.clearingFile = clearingFile._links.self.href;
        if (costingFile._links && costingFile._links.self) {
            return this.http.put(costingFile._links.self.href, JSON.stringify(costingFile), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/costingFiles', JSON.stringify(costingFile), { headers: headers });
        }
    };
    CostingFileService.prototype.getCostingFiles = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/costingFiles/search/findByClearingFile_Id?id=" + id)
            .pipe(map(function (response) { return response['_embedded'].costingFiles; }));
    };
    CostingFileService.prototype.findCostingItemByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/costingItems/search/findByCode?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].costingItems; }));
    };
    CostingFileService.prototype.loadFiles = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/costingFiles/" + id + '/costingSheet');
    };
    CostingFileService.ngInjectableDef = i0.defineInjectable({ factory: function CostingFileService_Factory() { return new CostingFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CostingFileService, providedIn: "root" });
    return CostingFileService;
}());
export { CostingFileService };
