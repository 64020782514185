import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TariffCode} from '../../../models/file';
import {SchedulePartService} from '../../../services/schedule-part.service';

@Component({
  selector: 'digi-dependants',
  templateUrl: './dependants.component.html',
  styleUrls: ['./dependants.component.scss']
})
export class DependantsComponent implements OnInit, OnChanges {

  @Input() tariffCode = new TariffCode();
  cols: any[];
  scheduleParts: TariffCode[];
  constructor(
    private schedulePartService: SchedulePartService
  ) { }

  ngOnInit() {
    this.cols = [
      {field: 'scheduleCode', header: 'Schedule', width: '5%'},
      {field: 'dependantCode', header: 'Rebate Code', width: '15%'},
      {field: 'dependantCheckDigit', header: 'Check Digit', width: '5%'},
      {field: 'scheduleDescription', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'}
    ];

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tariffCode && changes.tariffCode.currentValue) {
      this.tariffCode = changes.tariffCode.currentValue;
      this.schedulePartService.findRebateOrScheduleByTariffCodeAndChargeRebate(this.tariffCode.code, 'R')
        .subscribe(scheduleParts => this.scheduleParts = scheduleParts);
    }
  }

}
