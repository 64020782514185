<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-left">
    <div class="ui-inputgroup">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <p-autoComplete id="clearingFileNumber" [(ngModel)]="exportFile" field="clearingFileNumber"
                            [suggestions]="filteredExportFiles"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectInitial($event)"
                            minLength="2"
                            delay="600"
                            [inputStyle]="{color:'white'}"
                            placeholder="File No. / Ref. File No.">
              <ng-template let-option pTemplate="item">
                <div>{{option.clearingFileNumber}}</div>
              </ng-template>
            </p-autoComplete>
            <span class="sequence ui-inputgroup-addon" (click)="correctionList.toggle($event)">{{exportFile.sequence}}</span>
            <p-overlayPanel #correctionList [dismissable]="true">
              <ng-template ngFor let-option [ngForOf]="listExportFiles" pTemplate="item">
                <div id="sequence" class="ui-overlaypanel-content" (click)="selectCorrection(option)">
                  {{option.clearingFileNumber}}/{{option.sequence}}
                </div>
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="canDisable && (isSaveDisabled() || disableButtons)" (click)="saveExportFile()"></button>
    <button *ngIf="canAdd" pButton type="button" label="New" icon="fa fa-file" (click)="addAction()"
            class="ui-button-secondary"></button>
    <button pButton type="button" *ngIf="canCreateVoC && hasExportFile() && hasMrn()" label="Create VOC" icon="fa fa-plus"
            (click)="createVoc()"></button>
  </div>
</p-toolbar>
