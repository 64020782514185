import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'digi-road-export-registration',
  templateUrl: './road-export-registration.component.html',
  styleUrls: ['./road-export-registration.component.scss']
})
export class RoadExportRegistrationComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
