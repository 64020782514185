<p-toolbar>
  <div class="ui-toolbar-group-left">

  </div>

  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" (click)="newRate($event)" icon="pi pi-plus"></button>
    <button pButton type="button" label="Save" (click)="saveRate($event)" icon="pi pi-check"
      class="ui-button-success"></button>
  </div>
</p-toolbar>
<div class="ui-g-4">
  <p-panel header="Exchange Rate Section">
    <div class="ui-g ui-fluid">
      <div class="ui-g-12">
        <span class="first">View By</span>
        <p-selectButton [options]="views" [(ngModel)]="viewBy"></p-selectButton>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Exchange Rate Type: </span>
          <p-autoComplete [suggestions]="filteredExhangeRateTypes" field="label" [dropdown]="true"
            [autoHighlight]="true" (completeMethod)="searchExchangeRateType($event)" [minLength]="1"
            [(ngModel)]="selectedExchangeRateType" [forceSelection]="true" required=true>
            <ng-template let-option pTemplate="item">
              <div>{{option.type}}:{{option.description}}</div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Effective Date</span>
          <p-calendar placeholder="dd/mm/yyyy" [(ngModel)]="effectiveDate" dateFormat="dd/mm/yy" dataType="string"
            (onSelect)="selectDate($event)" (onBlur)="selectDate($event)" [showIcon]="true">
          </p-calendar>
        </div>
      </div>
    </div>
  </p-panel>
</div>
<div class="ui-g-4">
  <p-panel header="Exchange Rate Details">
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Currency : </span>
          <p-autoComplete [(ngModel)]="selectedExchangeRate.currency" field="label" [suggestions]="filteredCurrencies"
                          (completeMethod)="searchCurrency($event)" [dropdown]="true" [autoHighlight]="true" minLength="1"
                          [forceSelection]="true" required=true>
            <ng-template let-option pTemplate="item">
              <div>{{option.code}}: {{option.name}}</div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Exchange Rate (/):</span>
          <input pInputText [(ngModel)]="selectedExchangeRate.rate" (change)="updateMultiplierRate()" />
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Exchange Rate (*):</span>
          <input pInputText [(ngModel)]="multiplierRate" (change)="updateRate()" />
        </div>
      </div>
    </div>
  </p-panel>
</div>
<div class="ui-g-4">
  <p-panel header="Copy of Exchange Rate Type">
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Exchange Rate Type </span>
          <p-autoComplete [suggestions]="filteredCopyExhangeRateTypes" field="label" [dropdown]="true"
            [autoHighlight]="true" (completeMethod)="searchExchangeRateTypeForRange($event)" [minLength]="1"
            [(ngModel)]="selectExchangeRateType" [forceSelection]="true" required=true>
            <ng-template let-option pTemplate="item">
              <div>{{option.type}}:{{option.description}}</div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">From Date:</span>
          <p-calendar placeholder="dd/mm/yyyy" [(ngModel)]="fromDate" dateFormat="dd/mm/yy" dataType="string"
            (onSelect)="selectFromDate($event)" (onBlur)="selectFromDate($event)" [showIcon]="true">
          </p-calendar>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">To Date:</span>
          <p-calendar placeholder="dd/mm/yyyy" [(ngModel)]="toDate"  dateFormat="dd/mm/yy" dataType="string"
            (onSelect)="selectToDate($event)" (onBlur)="selectToDate($event)" [showIcon]="true">
          </p-calendar>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad pt-2">
        <button pButton type="button" (click)="copyRange(selectExchangeRateType,fromDate,toDate)" label="Copy Range"></button>
      </div>
    </div>
  </p-panel>
</div>
<div class="ui-g-12">
  <p-table [columns]="cols" [value]="exchangeRates" selectionMode="single" [(selection)]="selectedExchangeRate"
           (onRowSelect)="selectRate($event.data)"
  dataKey="lineNo">
    <ng-template pTemplate="header">
      <tr>
        <th>Code</th>
        <th>/Rate</th>
        <th>*Rate</th>
        <th>EffectiveDate</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-exchangeRate>
      <tr [pSelectableRow]="exchangeRate">
        <td>
          <label>{{exchangeRate.id?.currencyCode}}</label>
        </td>
        <td>
          <label>{{exchangeRate.rate}}</label>
        </td>
        <td>
          <label>{{getMultiplier(exchangeRate)}}</label>
        </td>
        <td>
          <label>{{exchangeRate.id?.effectiveDate}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
