import { Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnInit } from '@angular/core';
import { ShareDataService } from '../../services/share-data.service';

@Component({
  selector: 'digi-value-based-charge',
  templateUrl: './value-based-charge.component.html',
  styleUrls: ['./value-based-charge.component.scss']
})
export class ValueBasedChargeComponent implements OnInit, DoCheck {
  @Input('hasSundryCharge') hasSundryCharge;
  @Input('valueBasedCharges') valueBasedCharges;
  valueBasedChargesDiffers: IterableDiffer<any>;

  constructor(private shareDataService: ShareDataService,
              private iterableDiffers: IterableDiffers) {
  }

  ngOnInit() {
  }

  ngDoCheck(): void {
    if (this.hasSundryCharge) {
      if (this.valueBasedChargesDiffers) {
        const valueBasedChargesChanges = this.valueBasedChargesDiffers.diff(this.hasSundryCharge.valueBasedCharges);
        if (valueBasedChargesChanges) {
          this.createChangeDetectionForValueBasedCharges();
        }
      }
    }
  }

  private createChangeDetectionForValueBasedCharges() {
    this.valueBasedChargesDiffers = this.iterableDiffers.find(this.hasSundryCharge.valueBasedCharges).create();
  }

  addValueBasedCharge() {
    if (this.hasSundryCharge) {
      this.valueBasedCharges = this.shareDataService.addValueBasedCharge(this.hasSundryCharge);
      if (!this.valueBasedChargesDiffers) {
        this.createChangeDetectionForValueBasedCharges();
      }
    }
  }

  onRowDelete(index) {
    this.valueBasedCharges.splice(index, 1);
  }
}

