import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-exports-financials',
  templateUrl: './exports-financials.component.html',
  styleUrls: ['./exports-financials.component.scss']
})
export class ExportsFinancialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
