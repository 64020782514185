import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VoyageMasterFile} from '../../models/voyageFile';
import {
  CargoType,
  Container,
  ContainerRunningTotals, ContainerSpecification,
  ContainerType,
  DepotTerminal,
  MasContainerSize,
  MasContainerType,
  SealDetails
} from '../../models/file';
import {ContainerTypeService} from '../../../digi-ocean/services/container-type.service';
import {SelectItem} from 'primeng/api';
import {DepotTerminalService} from '../../services/depot-terminal.service';
import {MasContainerTypeService} from '../../../digi-ocean/services/mas-container-type.service';
import {MasContainerSizeService} from '../../../digi-ocean/services/mas-container-size.service';
import {ContainerSizeService} from '../../../digi-ocean/services/container-size.service';

@Component({
  selector: 'digi-ships-agent-containers',
  templateUrl: './ships-agent-containers.component.html',
  styleUrls: ['./ships-agent-containers.component.scss']
})
export class ShipsAgentContainersComponent implements OnInit, OnChanges {

  @Input() voyageMasterFile = new VoyageMasterFile();
  @Input() newContainer: any;
  container: Container;
  filteredContainerTypes: ContainerType[];
  filteredCargoTypes: CargoType[];
  cargoTypes: any[];
  sealStatuses: SelectItem[];
  sealingParties: SelectItem[];
  filteredDepotTerminals: DepotTerminal[];
  filteredMasContainerTypes: MasContainerType[];
  filteredMasContainerSizes: MasContainerSize[];
  filteredContainerSpecifications: ContainerSpecification[];

  constructor(
    private containerTypeService: ContainerTypeService,
    private depotTerminalService: DepotTerminalService,
    private masContainerTypeService: MasContainerTypeService,
    private masContainerSizeService: MasContainerSizeService,
    private containerSizeService: ContainerSizeService
  ) {
    this.cargoTypes = [
      {code: 'DB', description: 'Dry Bulk', label: 'DB - Dry Bulk'},
      {code: 'LB', description: 'Liquid Bulk', label: 'LB - Liquid Bulk'},
      {code: 'BB', description: 'Break Bulk', label: 'BB - Break Bulk'},
      {code: 'CN', description: 'Container', label: 'CN - Container'},
    ];
    this.sealingParties = [
      {label: '', value: null},
      {label: 'Carrier', value: 'Carrier'},
      {label: 'Customs', value: 'Customs'},
      {label: 'Shipper', value: 'Shipper'},
      {label: 'Terminal Operator', value: 'Terminal Operator'}
    ];
    this.sealStatuses = [
      {label: '', value: null},
      {label: 'Fine', value: 'Fine'},
      {label: 'Damaged', value: 'Damaged'}
    ];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.voyageMasterFile && changes.voyageMasterFile.currentValue) {
      this.voyageMasterFile = changes.voyageMasterFile.currentValue;
      if (this.voyageMasterFile.containers && this.voyageMasterFile.containers.length > 0) {
        this.container = this.voyageMasterFile.containers[0];
      } else {
        this.voyageMasterFile.containers = [];
        this.createNewContainer();
      }
      this.updateRunningTotals();
    }

    if (changes.newContainer && changes.newContainer.currentValue) {
      this.createNewContainer();
    }

  }

  searchContainerTypes(event) {
    this.containerTypeService
      .findAllContainerTypeStartsWith(event.query)
      .subscribe(data => {
        this.filteredContainerTypes = data;
      });
  }

  selectContainerType(value) {
    this.container.containerType = value;
  }

  searchCargoTypes(event) {
    this.filteredCargoTypes = this.cargoTypes.filter(v => v.label
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredCargoTypes = [...this.filteredCargoTypes];
  }

  selectCargoType(event) {
    this.container.cargoType = event;
  }

  searchDepotTerminal(event, depotTerminal) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminal).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  createNewContainer() {
    this.container = new Container();
    this.container.sealDetails = new SealDetails();
    this.voyageMasterFile.containers.push(this.container);
    this.updateRunningTotals();
  }

  searchMasContainerTypes(event: any) {
    this.masContainerTypeService
      .findAllMASContainerTypeStartsWith(event.query)
      .subscribe(data => {
        this.filteredMasContainerTypes = data;
      });
  }


  searchMasContainerSizes(event: any) {
    this.masContainerSizeService
      .findAllMASContainerSizeStartsWith(event.query)
      .subscribe(data => {
        this.filteredMasContainerSizes = data;
      });
  }

  selectMasContainerSize(value: MasContainerSize) {
    this.container.masContainerSize = value;
  }

  selectMasContainerType(value: MasContainerType) {
    this.container.masContainerType = value;
  }

  private updateRunningTotals() {
    this.voyageMasterFile.containerRunningTotals = new ContainerRunningTotals();
    this.voyageMasterFile.containerRunningTotals.capturedValue = this.voyageMasterFile.containers.length;
    this.voyageMasterFile.containerRunningTotals.remainingValue =
      this.voyageMasterFile.measures.noOfContainers -
      (this.voyageMasterFile.containerRunningTotals.capturedValue ?
        this.voyageMasterFile.containerRunningTotals.capturedValue : 0);

    this.voyageMasterFile.containerRunningTotals.capturedGrossWeight = this.voyageMasterFile.containers
      .map(c => c.nettWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum);

    this.voyageMasterFile.containerRunningTotals.remainingGrossWeight =
      this.voyageMasterFile.measures.grossWeight -
      (this.voyageMasterFile.containerRunningTotals.capturedGrossWeight ?
        this.voyageMasterFile.containerRunningTotals.capturedGrossWeight : 0);

    this.voyageMasterFile.containerRunningTotals.capturedVolume = this.voyageMasterFile.containers
      .map(c => c.cbm)
      .reduce((sum, current) => current ? +sum + +current : +sum);

    this.voyageMasterFile.containerRunningTotals.remainingVolume =
      this.voyageMasterFile.measures.volume -
      (this.voyageMasterFile.containerRunningTotals.capturedVolume ?
        this.voyageMasterFile.containerRunningTotals.capturedVolume : 0);

    this.voyageMasterFile.containerRunningTotals.capturedNoOfPackages = this.voyageMasterFile.containers
      .map(c => c.noOfPackages)
      .reduce((sum, current) => current ? +sum + +current : +sum);

    this.voyageMasterFile.containerRunningTotals.remainingNoOfPackages =
      this.voyageMasterFile.measures.numberOfPackages -
      (this.voyageMasterFile.containerRunningTotals.capturedNoOfPackages ?
        this.voyageMasterFile.containerRunningTotals.capturedNoOfPackages : 0);
  }

  searchContainerSpecs(event: any) {
    this.containerSizeService.findAllContainerSizeStartsWith(event.query)
      .subscribe(data => {
        this.filteredContainerSpecifications = data;
      });
  }

  selectContainerSpec(value) {
    this.container.containerSpecification = value;
  }
}
