<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
          <div class="ui-inputgroup">
             <span class="ui-float-label">
                <p-autoComplete id="supplier" [(ngModel)]="selectedSupplierInvoice.supplier" field="label"
                                [suggestions]="filteredSuppliers"
                                (completeMethod)="search($event)"
                                [autoHighlight]="true" (onSelect)="select($event)" minLength="3"
                                required="true">
                  <ng-template let-option pTemplate="item">
                    <div>{{option.code}}: {{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                 <label for="supplier">Supplier</label>
            </span>
            <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
          </div>
            <div *ngIf="!isQuotation && !selectedSupplierInvoice?.supplier?.code" class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
          </span>
        </div>
      </div>
      <digi-address [addresses]="supplierAddresses"
                    [address]="selectedSupplierInvoice.supplier.address"></digi-address>
      <div class="ui-g form-group">
        <div class="ui-md-12">
          <div class="ui-inputgroup">
            <button pButton
                    type="button"
                    label="Add New Supplier" (click)="openSupplierModal()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<digi-supplier-modal [display]="displaySupplierModal"
    (created)="onSupplierCreated($event)"></digi-supplier-modal>
