<p-dialog header="Additional Information"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1200"
          [minWidth]="200"
          [minY]="80"
          [maximizable]="false"
          (onHide)="onHide($event)"
          [baseZIndex]="10000">
  <p-panel header="Clearing Instruction">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" class="ui-md-2" pInputText
                      value="{{supplierInvoiceLine.tariffCode.code |
                      tariffCode}}">
                    <input type="text" pInputText class="ui-md-2"
                      value="{{supplierInvoiceLine.tariffCode.checkDigit}}">
                    <label>Tariff Heading</label>
                  </span>
                </div>
              </div>
      </div>
      <div class="ui-g-12 ui-md-4 pt-2">
        <digi-country-of-origin (onSelect)="selectCountryOfOrigin($event)"
                                [country]="supplierInvoiceLine.countryOfOrigin">
        </digi-country-of-origin>
      </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel header="Tariff Description">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="ui-g form-group">
            <div class="ui-g-7">
              <textarea [rows]="3" [cols]="30" placeholder="Tariff Description"
                pInputTextarea autoResize="autoResize"></textarea>
            </div>
            <div class="ui-g-5">
              <div class="ui-g-4">
                <div class="ui-inputgroup">
                  <button pButton type="button" label="Customs Description"
                    (click)="displayCustomsDescription()"></button>
                </div>
              </div>
              <div class="ui-g-4">
                <div class="ui-inputgroup">
                  <button pButton type="button" label="Short Description"
                    (click)="displayShortDescription()"></button>
                </div>
              </div>
              <div class="ui-g-4">
                <div class="ui-inputgroup">
                  <button pButton type="button" label="SuppInv Description"
                    (click)="displaySupplierInvDescription()">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
  <div class="ui-g-12">
    <p-panel header="Rebate Description">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-8">
                <textarea rows="3" cols="30" placeholder="Rebate Description"
                  pInputTextarea autoResize="autoResize"></textarea>
              </div>
              <div class="ui-g-4">
                <div class="ui-g-6">
                  <div class="ui-inputgroup">
                    <button pButton type="button" label="Customs Description"></button>
                  </div>
                </div>
                <div class="ui-g-6">
                  <div class="ui-inputgroup">
                    <button pButton type="button" label="Short Description"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-6">
    <p-panel header="Additional Duty Parameters">
      <p-table [columns]="cols" [value]="dutiesAndRebates" dataKey="vin">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
            </th>
            <th *ngFor="let col of columns">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
            </td>
            <td *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()" label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
