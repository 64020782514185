<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Generate">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator"
                                 [showNewButton]="false"
                                 (save)="saveVoyageMasterFile()"
        >
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           style="color: white"
                           [ngModel]="selectedVoyageMasterFile.voyageMasterFileNo">
                   <label style="color: white">Voyage Master File No.</label>
                 </span>
              </div>
          </span>
        </digi-ships-agent-header>
        <div class="ui-g-12">
          <div class="ui-g-1">
            <div class="ui-g-12">
              <button pButton type="button" pTooltip="Generate Reports"
                      tooltipPosition="top"
                      class="ui-button-secondary col-sm-12"
                      (click)="generateReports()" icon="fa fa-print" [disabled]="disableButton"></button>
            </div>
            <div class="ui-g-12">
              <button pButton type="button" pTooltip="Send Manifest"
                      tooltipPosition="top"
                      class="ui-button-secondary col-sm-12"
                      (click)="sendManifestToSARS()" icon="fa fa-paper-plane"
                      [disabled]="hasCarn() || disableButton"></button>
            </div>
          </div>
          <div class="ui-g-11">
            <div class="ui-g-12">
              <p-table [columns]="[{}]" [value]="voyageFile.voyageMasterFiles" [(selection)]="selectedVoyageMasterFile"
                       (onRowSelect)="selectVoyageMasterFile($event.data)"
                       dataKey="voyageMasterFileNo"
                       [paginator]="true" [rows]="4"
                       selectionMode="single">
                <ng-template pTemplate="header">
                  <tr>
                    <th>MBOL No.</th>
                    <th>Voyage Master File No.</th>
                    <th>Overseas Agent</th>
                    <th>Local Forwarder</th>
                    <th>SOB Date</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-voyageMasterFile>
                  <tr [pSelectableRow]="voyageMasterFile">
                    <td class="ui-resizable-column">
                      <label>{{voyageMasterFile.bolNo}}</label>
                    </td>
                    <td class="ui-resizable-column">
                      <label>{{voyageMasterFile.voyageMasterFileNo}}</label>
                    </td>
                    <td class="ui-resizable-column">
                      <label>{{voyageMasterFile.overseasAgent?.code}}</label>
                    </td>
                    <td class="ui-resizable-column">
                      <label>{{voyageMasterFile.localForwarder?.code}}</label>
                    </td>
                    <td class="ui-resizable-column">
                      <label>{{voyageMasterFile.sobDate}}</label>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g">
                <div class="ui-g-4">
                  <p-panel header="Manifest Details">
                    <div class="ui-fluid">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <div class="ui-g form-group">
                            <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="cuscarType"
                            [(ngModel)]="selectedVoyageMasterFile.cuscarType"
                            field="label"
                            [suggestions]="filteredCuscarTypes"
                            (completeMethod)="searchCuscarType($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectCuscarType($event)"
                            minLength="2"
                            [dropdown]="true"
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.label}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="cuscarType">Cuscar Type</label>
            </span>
    </div>
      <div *ngIf="!selectedVoyageMasterFile.cuscarType?.type" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
                            </div>
                            <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-dropdown [options]="manifestTypeOptions"
                        [(ngModel)]="selectedVoyageMasterFile.manifestType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Manifest Type</label>
            </span>
    </div>
      <div *ngIf="!selectedVoyageMasterFile.manifestType" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
                            </div>
                            <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-dropdown [options]="cargoTypeOptions"
                        [(ngModel)]="selectedVoyageMasterFile.cargoType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Cargo Type</label>
            </span>
    </div>
      <div *ngIf="!selectedVoyageMasterFile.cargoType" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Confirm Manifest">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           style="color: white"
                           [ngModel]="selectedVoyageMasterFile.voyageMasterFileNo">
                   <label style="color: white">Voyage Master File No.</label>
                 </span>
              </div>
          </span>
        </digi-ships-agent-header>
        <div class="ui-g-12">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <button pButton type="button" label="Refresh" (click)="refresh()" class="col-sm-2"></button>
              </div>

              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-4 ui-md-4">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{cusres.customsStatus}}" [disabled]="true" class="disabled-status">
              <label>STATUS</label>
            </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12">
                <p-table [columns]="colsForCuscar" [value]="cuscars" [paginator]="true" [rows]="1">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th style="width: 3em"></th>
                      <th *ngFor="let col of columns">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                               let-expanded="expanded" let-columns="columns">
                    <tr>
                      <td>
                        <a href="#" [pRowToggler]="rowData">
                          <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                      </td>
                      <td *ngFor="let col of columns">
                        {{rowData}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-rowData
                               let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                      <td [attr.colspan]="columns.length + 1">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                          <div class="ui-g-12 ui-md-9">
                            <div class="ui-g">
                              <div class="ui-g-12">
                                <pre>{{rowData}}</pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

              <div class="ui-g-12">
                <p-table [columns]="colsForContrl" [value]="contrl.messages"
                         dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="4"
                         pColumnHeaders="CUSRES">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th style="width: 3em"></th>
                      <th *ngFor="let col of columns">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                               let-expanded="expanded" let-columns="columns">
                    <tr>
                      <td>
                        <a href="#" [pRowToggler]="rowData">
                          <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                      </td>
                      <td *ngFor="let col of columns">
                        {{rowData}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-rowData
                               let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                      <td [attr.colspan]="columns.length + 1">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                          <div class="ui-g-12 ui-md-9">
                            <div class="ui-g">
                              <div class="ui-g-12">
                                <pre>{{rowData}}</pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

              <div class="ui-g-12">
                <p-table [columns]="colsForCusres" [value]="cusres.messages"
                         dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="4"
                         pColumnHeaders="CUSRES">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th style="width: 3em"></th>
                      <th *ngFor="let col of columns">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                               let-expanded="expanded" let-columns="columns">
                    <tr>
                      <td>
                        <a href="#" [pRowToggler]="rowData">
                          <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                      </td>
                      <td *ngFor="let col of columns">
                        {{rowData}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-rowData
                               let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                      <td [attr.colspan]="columns.length + 1">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                          <div class="ui-g-12 ui-md-9">
                            <div class="ui-g">
                              <div class="ui-g-12">
                                <pre>{{rowData}}</pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
<p-dialog header="Generated Documents" [(visible)]="display" [modal]="true"
          [responsive]="true" [width]="400"
          [minWidth]="200" [minY]="70"
          [maximizable]="true" [baseZIndex]="10000">
  <ng-template pTemplate="content" [ngIf]="generatedFiles">
    <li *ngFor="let generatedDocument of generatedFiles">
      <a class="uploaded-file-color"
         [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
    </li>
  </ng-template>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="display=false"
            label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
