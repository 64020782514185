import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ExportBillsOfEntry} from '../../models/billsOfEntry';
import {BillOfEntry, ExportBillOfEntry, SequenceData} from '../../models/billOfEntries';
import {Subscription} from 'rxjs';
import {FileType} from '../../models/enumerations';
import {ShareDataService} from '../../services/share-data.service';
import {SendEdiToSarsService} from '../../services/send-edi-to-sars.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {NotificationService} from '../../services/notification.service';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {ExportsBillOfEntryService} from '../../services/exports-bill-of-entry.service';
import {ConfirmationService, SelectItem} from 'primeng/api';

@Component({
  selector: 'digi-export-bill-of-entry',
  templateUrl: './export-bill-of-entry.component.html',
  styleUrls: ['./export-bill-of-entry.component.scss'],
  providers: [ConfirmationService]
})
export class ExportBillOfEntryComponent implements OnInit, OnDestroy {
  billsOfEntry: ExportBillsOfEntry = new ExportBillsOfEntry();
  selectedBillOfEntry: ExportBillOfEntry;
  @Output() billOfEntryId: EventEmitter<any> = new EventEmitter();
  exportFile: ExportFile;
  subscription: Subscription;
  billSubscription: Subscription;
  display = false;
  generatedFiles: any = [];
  billsSubscription: Subscription;
  disableButton: boolean;
  displayProgress = false;
  @Input() fileType: FileType;
  sequences: SequenceData[];
  displayManualSubmission: boolean;
  cols: any[];
  selectedBoes: BillOfEntry[] = [];
  agreed: boolean;

  constructor(
    private billOfEntryService: ExportsBillOfEntryService,
    private shareDataService: ShareDataService,
    private sendEdiToSarsService: SendEdiToSarsService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private messageService: NotificationService,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'houseNumber', header: 'In-House No'},
      {field: 'cpc', header: 'CPC'},
      {field: 'destination', header: 'Dest'},
      {field: 'serialNumber', header: 'Serial No'},
    ];
    this.disableButton = false;
    this.subscription = this.exportFileBehaviourSubject
      .getExportFile()
      .subscribe(ef => this.exportFile = ef);
    this.billsSubscription = this.billOfEntryBehaviourSubject
      .getBillsOfEntry()
      .subscribe(boes => {
        this.billsOfEntry.boes = boes;
        this.defaultSelection();
      });
  }

  generate() {
    this.disableButton = true;
    if (this.billsOfEntry.boes.length === 0 || !this.billsOfEntry.boes[0].id) {
      this.billOfEntryService.generateBOE(this.billsOfEntry, this.exportFile).subscribe(data => {
          this.billsOfEntry.boes = data._embedded.exportBillOfEntries;
          this.billOfEntryId.emit(this.billsOfEntry.boes[0]);
          this.billOfEntryBehaviourSubject.addBillOfEntry(this.billsOfEntry.boes[0]);
          this.billOfEntryBehaviourSubject.addBillsOfEntry(this.billsOfEntry.boes);
          this.defaultSelection();
          this.boeGenerationConfirmation();
          this.billsOfEntry.boes.forEach(boe => boe.vatIndicator = !!(this.exportFile.exporter && this.exportFile.exporter.taxRegNo));
          this.disableButton = false;
        },
        _ => {
          this.disableButton = false;
        },
      );
    } else {
      this.billOfEntryService.regenerateBOE(this.exportFile).subscribe(data => {
          this.billsOfEntry.boes = data._embedded.exportBillOfEntries;
          this.billOfEntryId.emit(this.billsOfEntry.boes[0]);
          this.billOfEntryBehaviourSubject.addBillOfEntry(this.billsOfEntry.boes[0]);
          this.billOfEntryBehaviourSubject.addBillsOfEntry(this.billsOfEntry.boes);
          this.defaultSelection();
          this.boeGenerationConfirmation();
          this.billsOfEntry.boes.forEach(boe => boe.vatIndicator = !!(this.exportFile.exporter && this.exportFile.exporter.taxRegNo));
          this.disableButton = false;
        },
        _ => {
          this.disableButton = false;
        }
      );
    }
  }

  selectedBillOfEntryHeader(index: number) {
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.billsOfEntry.boes[index]);
    this.defaultSelection();
  }

  defaultSelection() {
    this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;
        const found = this.billsOfEntry.boes.find(billOfEntry => billOfEntry.lrn === boe.lrn);
        const index = this.billsOfEntry.boes.indexOf(found);
        this.billsOfEntry.boes[index] = found; // update boe
        this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(this.selectedBillOfEntry.lines[0]);
      });
  }

  sendBillOfEntryToSARS() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to send EDI?',
      accept: () => {
        this.disableButton = true;
        this.messageService.successNotify('Sending EDI...');
        this.sendEdiToSarsService
          .sendBillOfEntry(this.exportFile, 'exports')
          .subscribe(data => {
              this.sendEDIConfirmation();
              this.retrieveBillOfEntryAfterEDI();
              this.disableButton = false;
            },
            _ => this.disableButton = false);
      }});
  }

  retrieveBillOfEntryAfterEDI() {
    this.billOfEntryService.findBillOfEntryByClearingFile_id(this.exportFile.id)
      .subscribe((data) => {
          this.billOfEntryBehaviourSubject.addBillsOfEntry(data._embedded.exportBillOfEntries);
          this.billOfEntryBehaviourSubject.addBillOfEntry(data._embedded.exportBillOfEntries[0]);
        },
        error => this.addFailure(error));
  }

  isCorrection() {
    return this.exportFile.type === 'ExportFileCorrection';
  }

  boeGenerationConfirmation() {
    this.messageService.successNotify('Bill of Entry Generated');
  }

  sendEDIConfirmation() {
    this.messageService.successNotify('EDI sent');
  }

  isEdiSent() {
    if (this.billsOfEntry && this.billsOfEntry.boes && this.billsOfEntry.boes.length) {
      const found = this.billsOfEntry.boes.find(boe => !boe.dateEdiSubmitted);
      return !found;
    }
    return true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.billsSubscription.unsubscribe();
  }

  showDialog() {
    this.display = true;
  }

  loadGeneratedFiles() {
    this.disableButton = true;
    this.billOfEntryService.loadFiles(this.selectedBillOfEntry.id).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.createSequenceData(this.generatedFiles);
      this.display = true;
      this.showDialog();
      this.disableButton = false;
    }, _ => this.disableButton = false);
  }

  hasRemovalTransportMethod() {
    return this.exportFile && this.exportFile.clearingInstructions
      && this.exportFile.clearingInstructions[0].removalTransportMethod
      && this.exportFile.clearingInstructions[0].removalTransportMethod.method === 'ROA';
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  createSequenceData(generatedFiles: any[]) {
    this.sequences = [];
    generatedFiles.forEach(el => {
      const entry = new SequenceData();
      const matches = el.fileName.match(/_\d+.pdf/gmi);
      const sequence = matches ? matches[0].toLowerCase().substr(1, matches[0].toLowerCase().indexOf('.pdf') - 1) : '';
      const found = this.sequences.find(data => data.sequence === sequence);
      if (found) {
        found.generatedFiles.push(el);
        found.generatedFiles.sort(function(a, b) {
          if (a.fileName.toLowerCase() > b.fileName.toLowerCase()) {
            return 1;
          }
          if (a.fileName.toLowerCase() < b.fileName.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      } else {
        entry.sequence = sequence;
        entry.generatedFiles = [el];
        this.sequences.push(entry);
      }
      this.sequences.sort(function(a, b) {
        if (a.sequence > b.sequence) {
          return 1;
        }
        if (a.sequence < b.sequence) {
          return -1;
        }
        return 0;
      });
    });
  }

  submitManually() {
    this.displayManualSubmission = true;
  }

  markBoeAsSubmitted() {
    if (this.agreed) {
      this.billOfEntryService.markBoesAsSubmitted(this.exportFile.id, this.selectedBoes).subscribe(
        boes => {
          this.selectedBoes = boes;
          this.selectedBoes.forEach(boe => {
            const found = this.billsOfEntry.boes.find(el => el.id === boe.id);
            if (found) {
              found.markedAsSubmitted = boe.markedAsSubmitted;
            }
          });
          this.selectedBillOfEntry = this.billsOfEntry.boes.find(el => el.id === this.selectedBillOfEntry.id);
          this.displayManualSubmission = false;
        },
        _ => this.displayManualSubmission = false
      );
    }
  }

  deselectBoes() {
    this.selectedBoes = [];
  }
}
