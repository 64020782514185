/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forwarding-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/table/table";
import * as i5 from "../../../../node_modules/primeng/components/table/table.ngfactory";
import * as i6 from "primeng/components/button/button";
import * as i7 from "primeng/components/utils/objectutils";
import * as i8 from "primeng/components/common/shared";
import * as i9 from "./forwarding-dashboard.component";
import * as i10 from "../services/agent.service";
import * as i11 from "../services/importer.service";
import * as i12 from "../services/bill-of-entry.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../subjects/authorisation-behaviour-subject";
import * as i15 from "../services/forwarding-file.service";
import * as i16 from "../../../subjects/clearingfile-behaviour-subject";
import * as i17 from "../../../subjects/transport-document-behaviour-subject";
import * as i18 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i19 from "../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i20 from "../saveClearingFile";
import * as i21 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i22 from "../services/notification.service";
import * as i23 from "../../digi-business-entity/services/business-entity.service";
import * as i24 from "../services/company.service";
import * as i25 from "../../../subjects/clearingInstruction-behavior-subject";
var styles_ForwardingDashboardComponent = [i0.styles];
var RenderType_ForwardingDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForwardingDashboardComponent, data: {} });
export { RenderType_ForwardingDashboardComponent as RenderType_ForwardingDashboardComponent };
function View_ForwardingDashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "col", [], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.width; _ck(_v, 0, 0, currVal_0); }); }
function View_ForwardingDashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "colgroup", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForwardingDashboardComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ForwardingDashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "th", [["pResizableColumn", ""]], [[2, "ui-sortable-column", null], [2, "ui-state-highlight", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(2, 212992, null, 0, i4.SortableColumn, [i4.Table, i3.DomHandler], { field: [0, "field"] }, null), i1.ɵdid(3, 4341760, null, 0, i4.ResizableColumn, [i4.Table, i1.ElementRef, i3.DomHandler, i1.NgZone], null, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p-sortIcon", [], null, null, null, i5.View_SortIcon_0, i5.RenderType_SortIcon)), i1.ɵdid(6, 245760, null, 0, i4.SortIcon, [i4.Table], { field: [0, "field"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.field; _ck(_v, 2, 0, currVal_2); var currVal_4 = _v.context.$implicit.field; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isEnabled(); var currVal_1 = i1.ɵnov(_v, 2).sorted; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.header; _ck(_v, 4, 0, currVal_3); }); }
function View_ForwardingDashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForwardingDashboardComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ForwardingDashboardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onForwardingFileNumberClick(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.fileNumber), _v.context.$implicit.hawbNo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "td", [["class", "truncate wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.fileNumber) || " "); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.masterBill == null) ? null : _v.parent.context.$implicit.masterBill.mawbNo)) || " "); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.hawbNo || " "); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_v.context.$implicit.customsValue == null) ? null : _v.context.$implicit.customsValue.localAmount) || " "); _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.cusres == null) ? null : _v.parent.context.$implicit.cusres.customsStatus)) || " "); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_v.context.$implicit.clearingFileNumber || " "); _ck(_v, 13, 0, currVal_5); }); }
function View_ForwardingDashboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForwardingDashboardComponent_6)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.houseBills; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ForwardingDashboardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { correctionList: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "card card-w-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["icon", "fa fa-search"], ["label", "File Enquiry"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSearchCriteriaModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(4, 4341760, null, 0, i6.ButtonDirective, [i1.ElementRef, i3.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SARS Messages"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "p-table", [], null, [[null, "onLazyLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLazyLoad" === en)) {
        var pd_0 = (_co.onLazyLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_Table_0, i5.RenderType_Table)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵprd(512, null, i7.ObjectUtils, i7.ObjectUtils, []), i1.ɵprd(512, null, i4.TableService, i4.TableService, []), i1.ɵdid(11, 1294336, null, 1, i4.Table, [i1.ElementRef, i3.DomHandler, i7.ObjectUtils, i1.NgZone, i4.TableService], { paginator: [0, "paginator"], rows: [1, "rows"], lazy: [2, "lazy"], lazyLoadOnInit: [3, "lazyLoadOnInit"], responsive: [4, "responsive"], loading: [5, "loading"], value: [6, "value"], columns: [7, "columns"], totalRecords: [8, "totalRecords"] }, { onLazyLoad: "onLazyLoad" }), i1.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ForwardingDashboardComponent_1)), i1.ɵdid(14, 16384, [[2, 4]], 0, i8.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ForwardingDashboardComponent_3)), i1.ɵdid(16, 16384, [[2, 4]], 0, i8.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ForwardingDashboardComponent_5)), i1.ɵdid(18, 16384, [[2, 4]], 0, i8.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "File Enquiry"; var currVal_1 = "fa fa-search"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = true; var currVal_3 = 10; var currVal_4 = true; var currVal_5 = true; var currVal_6 = true; var currVal_7 = _co.loading; var currVal_8 = _co.forwardingFiles; var currVal_9 = _co.cols; var currVal_10 = _co.totalRecords; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "colgroup"; _ck(_v, 14, 0, currVal_11); var currVal_12 = "header"; _ck(_v, 16, 0, currVal_12); var currVal_13 = "body"; _ck(_v, 18, 0, currVal_13); }, null); }
export function View_ForwardingDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-forwarding-dashboard", [], null, null, null, View_ForwardingDashboardComponent_0, RenderType_ForwardingDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i9.ForwardingDashboardComponent, [i10.AgentService, i11.ImporterService, i12.BillOfEntryService, i13.Router, i13.ActivatedRoute, i14.AuthorisationService, i15.ForwardingFileService, i16.ClearingFileBehaviourSubject, i17.TransportDocumentBehaviourSubject, i18.SupplierInvoiceBehaviourSubject, i19.SupplierInvoiceLineBehaviourSubject, i20.SaveClearingFile, i21.BillOfEntryBehaviourSubject, i22.NotificationService, i23.BusinessEntityService, i24.CompanyService, i25.ClearingInstructionBehaviorSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForwardingDashboardComponentNgFactory = i1.ɵccf("digi-forwarding-dashboard", i9.ForwardingDashboardComponent, View_ForwardingDashboardComponent_Host_0, { transportMethod: "transportMethod", billOfEntry: "billOfEntry", file: "file", businessEntities: "businessEntities", isNomination: "isNomination" }, { selectedClearingFile: "selectedClearingFile", selectedBusinessEntity: "selectedBusinessEntity" }, []);
export { ForwardingDashboardComponentNgFactory as ForwardingDashboardComponentNgFactory };
