import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var AwbTypeService = /** @class */ (function () {
    function AwbTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    AwbTypeService.prototype.findAllAirWaybillTypeStartsWith = function (searchValue) {
        return this.http
            .get(this.config.apiEndpoint + "api/awbTypes/search/codeStartsWith?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].awbTypes; }));
    };
    AwbTypeService.ngInjectableDef = i0.defineInjectable({ factory: function AwbTypeService_Factory() { return new AwbTypeService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: AwbTypeService, providedIn: "root" });
    return AwbTypeService;
}());
export { AwbTypeService };
