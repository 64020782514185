import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var DepartmentService = /** @class */ (function () {
    function DepartmentService(http, config) {
        this.http = http;
        this.config = config;
    }
    DepartmentService.prototype.findDepartmentsByCodeStartWith = function (value) {
        return this.http
            .get(this.config.apiEndpoint + "api/departments/search/codeStartsWith?code=" + value)
            .pipe(map(function (response) { return response['_embedded'].departments; }));
    };
    DepartmentService.ngInjectableDef = i0.defineInjectable({ factory: function DepartmentService_Factory() { return new DepartmentService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: DepartmentService, providedIn: "root" });
    return DepartmentService;
}());
export { DepartmentService };
