<div class="card mb-2" id="detailOptions">
    <div class="card-body">
      <div class="row">
        <div class="form-inline mb-2 form-control">
          <div class="input-group input-group-sm col-sm-3">
            <div class="input-group-prepend">
              <span for="paymentCode" class="input-group-text">BOE No:</span>
            </div>
            <input type="text" class="dropdown form-control input-sm col-sm-6" id="paymentCode" value="1010001503" readonly>
          </div>
          <div class="input-group input-group-sm col-sm-3">
            <div class="input-group-prepend">
              <span for="paymentCode" class="input-group-text">Purpose:</span>
            </div>
            <input type="text" class="dropdown form-control input-sm col-sm-3" id="paymentCode" value="DP" readonly>
          </div>
          <div class="input-group input-group-sm col-sm-3">
            <div class="input-group-prepend">
              <span for="paymentCode" class="input-group-text">Country of Export:</span>
            </div>
            <input type="text" class="dropdown form-control input-sm col-sm-3" id="paymentCode" value="USA" readonly>
          </div>
          <div class="input-group input-group-sm col-sm-3">
            <div class="input-group-prepend">
              <span for="paymentCode" class="input-group-text">Destination:</span>
            </div>
            <input type="text" class="dropdown form-control input-sm col-sm-8" id="paymentCode" value="ZA" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary" type="button">Show Consolidation Details (may take a while)</button>&nbsp;
  <button class="btn btn-primary" type="button">
    <i class="fa fa-print"></i>
  </button>
  <div class="mb-2 mt-2">
    <digi-consolidation-details-table></digi-consolidation-details-table>
  </div>
