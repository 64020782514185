import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ClearingFile } from '../../models/clearingFile';
import {FileType} from '../../models/enumerations';
import {ExportFile} from '../../models/exportFile';

@Component({
  selector: 'digi-boe-marks-numbers',
  templateUrl: './boe-marks-numbers.component.html',
  styleUrls: ['./boe-marks-numbers.component.scss']
})
export class BoeMarksNumbersComponent implements OnInit, OnChanges {
  @Input() file: ClearingFile|ExportFile;
  @Input() fileType: FileType;
  endorsements: string[];
  marksAndNumbers: string[];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.file = changes.file.currentValue;
    if (this.fileType === 'imports') {
      this.file.transportDocuments.forEach(t => {
        this.endorsements = t.endorsements;
        this.marksAndNumbers = t.marksAndNumbers;
      });
    } else if (this.fileType === 'exports') {
      if (this.file.transportDocument) {
        this.endorsements = this.file.transportDocument.endorsements;
        this.marksAndNumbers = this.file.transportDocument.marksAndNumbers;
      }
    } else {
      this.endorsements = this.file.endorsements ? this.file.endorsements : [];
      this.marksAndNumbers = this.file.marksAndNumbers ? this.file.marksAndNumbers : [];
    }
  }
}
