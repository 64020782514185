import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CustomsOffice, Importer} from '../../digi-business-entity/models/business-entity';
import {InvoiceService} from '../services/invoice.service';
import {NotificationService} from '../services/notification.service';
import {ImporterService} from '../services/importer.service';
import {CustomOfficeService} from '../services/custom-office.service';
import {Da490ReportService} from '../services/da490-report.service';

@Component({
  selector: 'digi-da490',
  templateUrl: './da490.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white input{
      color: white !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  .page {
      border: 9px solid transparent !important;
      background-clip: content-box !important;
      -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
  }
  `],
  encapsulation: ViewEncapsulation.None
})
export class DA490Component implements OnInit {
  display = false;
  pdfSrc: string;
  disableButton: boolean;

  constructor(
    private da490ReportService: Da490ReportService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  generate() {
    this.disableButton = true;
    this.da490ReportService.generateDA490Report().subscribe(data => {
        const blob = new Blob([data], {type: 'application/pdf'});
        this.pdfSrc = URL.createObjectURL(blob);
        this.disableButton = false;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrc = null;
        this.disableButton = false;
      });
  }


  openLink() {
    const link = document.createElement('a');
    link.href = this.pdfSrc;
    link.download = 'DA490.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);  }
}
