<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-g-nopad pt-2" *ngIf="transportMethod === 'SEA'">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="oceanLine" [(ngModel)]="selectedTransportDocument.oceanLineDetails.oceanLine"
                                  field="label"
                                  [suggestions]="filteredOceanlines"
                                  (completeMethod)="search($event)"
                                  (onSelect)="defaultCarrierCode($event)"
                                  [autoHighlight]="true" minLength="2"
                                  required=true
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}:{{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="oceanLine">Master Carrier</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
            <div *ngIf="!selectedTransportDocument.oceanLineDetails.oceanLine.code && !clearingFile.quotation"
                 class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
          </span>
        </div>
        <div class="ui-g-12 ui-g-nopad pt-2" *ngIf="transportMethod === 'ROA'">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                <p-autoComplete id="transporter" [(ngModel)]="selectedTransportDocument.transporter"
                                field="label"
                                [suggestions]="filteredTransporters"
                                (completeMethod)="searchTransporters($event)"
                                (onSelect)="selectTransporter($event)"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                </p-autoComplete>
                <label for="transporter">Transporter</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
             <div *ngIf="!selectedTransportDocument?.transporter?.transporterCode" class="ui-message ui-messages-error ui-corner-all" >
               This field is required
          </div>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="transportMethod === 'ROA' || transportMethod === 'RAI'">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         [(ngModel)]="selectedTransportDocument.mawbNo"
                         (change)="changeMAWBNo($event)"
                         (focusout)="validateMawbNo()"
                         pInputText required>
                     <label>Manifest No</label>
                </span>
              </div>
              <div *ngIf="!selectedTransportDocument.mawbNo"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
              </div>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="transportMethod === 'ROA' || transportMethod === 'RAI'">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <div *ngIf="selectedTransportDocument.straight; then custom else copy"></div>
                <ng-template #copy>
                  <span class="md-inputfield">
                    <input type="text"
                           [(ngModel)]="selectedTransportDocument.hawbNo"
                           (focusout)="validateHawbNo()"
                           pInputText
                           class="ui-inputgroup">
                    <label>Waybill No</label>
                  </span>
                </ng-template>
                <ng-template #custom>
                  <span class="md-inputfield">
                    <input [(ngModel)]="selectedTransportDocument.hawbNo"
                           [readonly]="selectedTransportDocument.straight"
                           type="text"
                           pInputText
                           class="ui-inputgroup">
                    <label>Waybill No</label>
                  </span>
                </ng-template>
              </div>
              <div *ngIf="!selectedTransportDocument.hawbNo"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
        </div>
        <div class="ui-g-12 ui-g-nopad pt-2" *ngIf="transportMethod === 'SEA'">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                <p-autoComplete id="carrierCode" [(ngModel)]="selectedTransportDocument.oceanLineDetails.carrierCode"
                                field="label"
                                [suggestions]="filteredOceanlines"
                                (completeMethod)="search($event)"
                                [autoHighlight]="true" minLength="2"
                                required=true
                >
                  <ng-template let-option pTemplate="item">
                    <div>{{option.code}}:{{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label for="oceanLine">Carrier Code</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
             <div *ngIf="!selectedTransportDocument.oceanLineDetails.carrierCode.code && !clearingFile.quotation"
                  class="ui-message ui-messages-error ui-corner-all">
               This field is required</div>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="transportMethod === 'SEA'">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         [(ngModel)]="selectedTransportDocument.mawbNo"
                         (change)="changeMAWBNo($event)"
                         (focusout)="validateMawbNo()"
                         pInputText required>
                     <label>MOBL No</label>
                </span>
              </div>
              <div *ngIf="!selectedTransportDocument.mawbNo && !clearingFile.quotation"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
              </div>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="transportMethod === 'SEA'">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         [(ngModel)]="selectedTransportDocument.subMAWBNo"
                         (change)="changeMAWBNo($event)"
                         pInputText required>
                     <label>Sub MOBL No</label>
                </span>
              </div>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="transportMethod === 'SEA'">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <div *ngIf="selectedTransportDocument.straight; then custom else copy"></div>
                <ng-template #copy>
                  <span class="md-inputfield">
                    <input type="text"
                           [(ngModel)]="selectedTransportDocument.hawbNo"
                           (focusout)="validateHawbNo()"
                           pInputText
                           class="ui-inputgroup"
                           id="txtHawbNo">
                    <label>HBOL No</label>
                  </span>
                </ng-template>
                <ng-template #custom>
                  <span class="md-inputfield">
                    <input [(ngModel)]="selectedTransportDocument.hawbNo"
                           [readonly]="selectedTransportDocument.straight"
                           type="text"
                           pInputText
                           class="ui-inputgroup"
                           id="txtHawbNo">
                    <label>HBOL No</label>
                  </span>
                </ng-template>
              </div>
              <div *ngIf="!selectedTransportDocument.hawbNo && !clearingFile.quotation"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <p-inputMask id="mawbDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             (change)="defaultDates()"
                             [(ngModel)]="selectedTransportDocument.mawbDate">

                </p-inputMask>
                   <label for="mawbDate">SOB Date</label>
              </span>
              <div *ngIf="!selectedTransportDocument.mawbDate"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
                <span class="ui-float-label">
                  <p-inputMask id="hawbDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="selectedTransportDocument.hawbDate"
                               [readonly]="selectedTransportDocument.straight">
                  </p-inputMask>
                      <label for="hawbDate">{{transportMethod === 'RAI' ? 'HAWB Date' : 'HBOL Date'}}</label>
                </span>
                <div *ngIf="!selectedTransportDocument.hawbDate && !clearingFile.quotation"
                     class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-12">
              <p-checkbox name="straight"
                          label="Straight"
                          (onChange)="straightCheck($event)"
                          [(ngModel)]="selectedTransportDocument.straight"
                          binary="true">

              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





