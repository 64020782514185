import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) { }

  findDepartmentsByCodeStartWith(value: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/departments/search/codeStartsWith?code=` + value)
      .pipe(
        map(response => response['_embedded'].departments)
      );
  }
}
