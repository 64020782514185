import { of } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var PartService = /** @class */ (function () {
    function PartService(http, config) {
        this.http = http;
        this.config = config;
    }
    PartService.prototype.findPartByPartNumberStartsWithForBranchAndBusinessEntity = function (partNumber, businessEntityCode, importExportIndicator) {
        if (partNumber === '') {
            return of([]);
        }
        var baseUrl = this.config.filesApiEndpoint + "api/parts/search/findByPartNumberAndBusinessEntityCode";
        return this.http
            .get(baseUrl + "?partNumber=" + partNumber + "&businessEntityCode=" + businessEntityCode + "&importExportIndicator=" + importExportIndicator)
            .pipe(map(function (response) { return response['_embedded'].parts; }));
    };
    PartService.prototype.savePart = function (part) {
        if (part._links && part._links.self) {
            return this.http.put(part._links.self.href, part);
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + "api/parts", part);
        }
    };
    PartService.prototype.findByPartNumber = function (query, importExportIndicator) {
        query = query.replace(/\+/g, "%2b");
        return this.http.get(this.config.filesApiEndpoint + "api/parts/search/findByPartNumber?partNumber=" + query + "&indicator=" + importExportIndicator).pipe(map(function (response) { return response['_embedded'].parts; }));
    };
    PartService.prototype.findByPartDescription = function (query, importExportIndicator) {
        query = query.replace(/\+/g, "%2b");
        return this.http.get(this.config.filesApiEndpoint + "api/parts/search/findByPartDescription?description=" + query + "&indicator=" + importExportIndicator).pipe(map(function (response) { return response['_embedded'].parts; }));
    };
    PartService.prototype.saveParts = function (partDetails) {
        return this.http.post(this.config.filesApiEndpoint + "api/parts/upload", partDetails);
    };
    PartService.ngInjectableDef = i0.defineInjectable({ factory: function PartService_Factory() { return new PartService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: PartService, providedIn: "root" });
    return PartService;
}());
export { PartService };
