import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile} from '../../../digi-common/models/clearingFile';
import {ExportTransportDocument, PortDetail, TransportDocument} from '../../../digi-common/models/file';
import {combineLatest, Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {ExitPortService} from '../../services/exit-port.service';
import {ValidationService} from '../../services/validation.service';

@Component({
  selector: 'digi-hawb-flight-detail-section',
  templateUrl: './hawb-flight-detail-section.component.html',
  styleUrls: ['./hawb-flight-detail-section.component.css']
})
export class HawbFlightDetailSectionComponent implements OnInit, OnDestroy {
  transportDocument: TransportDocument | ExportTransportDocument;
  file: ClearingFile | ExportFile;
  subscription: Subscription;
  @Input() fileType: FileType;
  filteredPortDetails: PortDetail[];

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private clearingFileBehaviorSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private exitPortService: ExitPortService,
              public validationService: ValidationService) {
  }

  ngOnInit() {

    const transactionDocumentObservable = this.transportDocumentBehaviourSubject.getTransportDocuments();
    if (this.fileType === 'imports') {
      const clearingFileObservable = this.clearingFileBehaviorSubject.getClearingFile();

      this.subscription = combineLatest(clearingFileObservable, transactionDocumentObservable).subscribe((tuple: any[]) => {
        if (tuple[0]) {
          this.file = tuple[0];
        }

        if (tuple[1]) {
          this.transportDocument = tuple[1];
        }
      });
    } else {
      const exportFileObservable = this.exportFileBehaviourSubject.getExportFile();

      this.subscription = combineLatest(exportFileObservable, transactionDocumentObservable).subscribe((tuple: any[]) => {
        if (tuple[0]) {
          this.file = tuple[0];
        }

        if (tuple[1]) {
          this.transportDocument = tuple[1];
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCargoCarrierCode(value) {
    this.transportDocument.localForwarder.cargoCarrierCode = value;
  }

  isAirTransport() {
    return this.file.clearingInstructions[0].transportMethod.method === 'AIR';
  }

  selectExitPort(value) {
    this.transportDocument.exitPort = value;
  }

  searchExitPort(event) {
    this.exitPortService.findExitPortByNameOrCode(event.query).subscribe(
      ports => this.filteredPortDetails = ports
    );
  }
}
