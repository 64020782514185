import { InjectionToken } from '@angular/core';
import { TenantService } from './tenant/tenant.service';
import * as i0 from "@angular/core";
import * as i1 from "./tenant/tenant.service";
export var APP_CONFIG = new InjectionToken('app.config');
var AppConfig = /** @class */ (function () {
    function AppConfig(tenantService) {
        this.tenantService = tenantService;
        this.apiEndpoint = 'http://localhost:8080/';
        this.filesApiEndpoint = 'http://localhost:8081/';
        this.branchCode = 11;
        this.zipRequests = false;
        this.enableTracking = false;
        this.apiEndpoint = this.tenantService.apiEndpoint;
        this.filesApiEndpoint = this.tenantService.filesApiEndpoint;
        this.branchCode = this.tenantService.branchCode;
        this.zipRequests = this.tenantService.zipRequests;
        this.enableTracking = this.tenantService.enableTracking;
        console.log('constructed AppConfig: apiEndpoint=%s, filesApiEndpoint=%s, branchCode=%s', this.apiEndpoint, this.filesApiEndpoint, this.branchCode);
    }
    AppConfig.ngInjectableDef = i0.defineInjectable({ factory: function AppConfig_Factory() { return new AppConfig(i0.inject(i1.TenantService)); }, token: AppConfig, providedIn: "root" });
    return AppConfig;
}());
export { AppConfig };
// export const APP_DI_CONFIG: AppConfig = {
//   apiEndpoint: environment.apiEndpoint,
//   filesApiEndpoint: environment.filesApiEndpoint,
//   branchCode: environment.branchCode,
//   tenantService: null
// };
var AppConfigModule = /** @class */ (function () {
    function AppConfigModule() {
    }
    return AppConfigModule;
}());
export { AppConfigModule };
