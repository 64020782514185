import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'digi-da65',
  templateUrl: './da65.component.html',
  encapsulation: ViewEncapsulation.None
})
export class Da65Component implements OnInit {
  display = false;
  pdfSrc: string;
  disableButton: boolean;
  constructor(
  ) {
  }

  ngOnInit() {
  }

  print() {
    window.print();
  }

}
