import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { Correction } from '../../models/file';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExBondFileBehaviourSubject} from '../../../../subjects/exbondfile-behaviour-subject';

@Component({
  selector: 'digi-correction',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.scss']
})
export class CorrectionComponent implements OnInit, OnDestroy {
  correction: Correction;
  subscription: Subscription;
  @Input() fileType: FileType;

  constructor(
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject
    ) {
  }

  ngOnInit() {
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject
        .getClearingFile()
        .subscribe(correction => this.correction = correction);
    } else if (this.fileType === 'exbond') {
      this.subscription = this.exBondFileBehaviourSubject
        .getExBondFile()
        .subscribe(correction => this.correction = correction);
    } else {
      this.subscription = this.exportFileBehaviourSubject
        .getExportFile()
        .subscribe(correction => this.correction = correction);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
