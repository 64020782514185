<p-toolbar>
  <div class="ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-6">
      <digi-exbond-file-search></digi-exbond-file-search>
    </div>
    <div class="ui-g-12 ui-md-6">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="md-inputfield" *ngIf="fileType==='imports'">
              <input type="text" pInputText
                     value="{{file?.importer?.name}}" readonly style="color: white;">
              <label style="color: white;">Importer</label>
            </span>
            <span class="md-inputfield" *ngIf="fileType==='exports'">
              <input type="text" pInputText
                     value="{{file?.exporter?.name}}" readonly style="color: white;">
              <label style="color: white;">Exporter</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <div class="ui-md-4 pt-2">
      <button pButton type="button" label="Save" icon="fa fa-save"
              (click)="saveBillOfEntry()"></button>
    </div>
  </div>
</p-toolbar>
