import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var Da490ReportService = /** @class */ (function () {
    function Da490ReportService(http, config) {
        this.http = http;
        this.config = config;
    }
    Da490ReportService.prototype.generateDA490Report = function () {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/da490", { responseType: 'blob' });
    };
    Da490ReportService.ngInjectableDef = i0.defineInjectable({ factory: function Da490ReportService_Factory() { return new Da490ReportService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: Da490ReportService, providedIn: "root" });
    return Da490ReportService;
}());
export { Da490ReportService };
