import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ContainerService = /** @class */ (function () {
    function ContainerService(http, config) {
        this.http = http;
        this.config = config;
    }
    ContainerService.prototype.uploadContainerSpreadsheet = function (file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.config.filesApiEndpoint + 'api/containers/csvUpload', formData);
    };
    ContainerService.ngInjectableDef = i0.defineInjectable({ factory: function ContainerService_Factory() { return new ContainerService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ContainerService, providedIn: "root" });
    return ContainerService;
}());
export { ContainerService };
