import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var TransportDocumentBehaviourSubject = /** @class */ (function () {
    function TransportDocumentBehaviourSubject() {
        this.transportDocumentSubject = new BehaviorSubject(null);
    }
    TransportDocumentBehaviourSubject.prototype.addTransportDocument = function (transportDocument) {
        this.transportDocumentSubject.next(transportDocument);
    };
    TransportDocumentBehaviourSubject.prototype.getTransportDocuments = function () {
        return this.transportDocumentSubject.asObservable();
    };
    TransportDocumentBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function TransportDocumentBehaviourSubject_Factory() { return new TransportDocumentBehaviourSubject(); }, token: TransportDocumentBehaviourSubject, providedIn: "root" });
    return TransportDocumentBehaviourSubject;
}());
export { TransportDocumentBehaviourSubject };
