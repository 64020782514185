import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomOfficeService} from 'src/app/digi-common/services/custom-office.service';
import {DepotTerminalService} from 'src/app/digi-common/services/depot-terminal.service';
import {PaymentTypeService} from 'src/app/digi-common/services/payment-type.service';
import {
  BusinessEntity,
  CreditorNominated,
  CustomOffice,
  DepotTerminal,
  LocalAgent, LocalAgentCustomOfficeBond,
  LocalAgentCustomOfficeCreditor,
  PaymentType,
} from '../../models/business-entity';
import {BusinessEntityService} from '../../services/business-entity.service';

@Component({
  selector: 'app-local-agent-role',
  templateUrl: './local-agent-role.component.html',
  styleUrls: ['./local-agent-role.component.scss']
})
export class LocalAgentRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() localAgent: LocalAgent;
  filteredDepotTerminals: DepotTerminal[];

  paymentTypes: PaymentType[];
  filteredCustomsOffices: CustomOffice[];
  filteredBusinessEntity = [];
  localAgentCustomOffice: LocalAgentCustomOfficeCreditor;
  localAgentCustomOfficeBond: LocalAgentCustomOfficeBond;
  selectedLocalAgentCustomOffice: LocalAgentCustomOfficeCreditor;
  selectedLocalAgentCustomBond: LocalAgentCustomOfficeBond;
  showCreditorDetalsPanel = false;
  showBondDetalsPanel = false;

  constructor(private depotTerminalService: DepotTerminalService,
              private paymentTypeService: PaymentTypeService,
              private customOfficeService: CustomOfficeService,
              private businessEntityService: BusinessEntityService) {
  }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
  }

  ngOnChanges(simple: SimpleChanges) {
    this.copyDefaultsFromBusinessEntity();
  }


  private copyDefaultsFromBusinessEntity() {
    this.localAgent = this.businessEntity.roles.find(role => {
      if (this.localAgent.roleType === role.roleType) {
        return true;
      }
      return false;
    });
  }

  selectBusinessEntity(event) {
    this.localAgentCustomOffice.creditorNominated.businessEntityName = event.name;
    this.localAgentCustomOffice.creditorNominated.businessEntityCode = event.code;
    this.localAgentCustomOffice.creditorNominated.roleType = event.roles[0].roleType;
  }

  searchEntityByRole(event) {
    this.businessEntityService
      .findBusinessEntityStartsWithAndRoleType(event.query, 11).subscribe(data => {
      this.filteredBusinessEntity = data;
    });
  }

  searchDeportTerminal(event) {
    this.depotTerminalService.findAllDepotTerminalsStartsWith(event.query).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  filterPaymentTypes(event) {
    this.paymentTypeService.getPaymentType().subscribe(data => {
      this.paymentTypes = data._embedded.paymentType;
    });
  }

  searchCustomOffice(event) {
    this.customOfficeService.findAllCustomsOfficeStartWith(event.query).subscribe(data => {
      this.filteredCustomsOffices = data;
    });
  }

  createNewCredtiorLink() {
    this.showCreditorDetalsPanel = true;
    if (this.localAgent.localAgentCustomOfficeCreditors === undefined) {
      this.selectedLocalAgentCustomOffice = new LocalAgentCustomOfficeCreditor();
      this.localAgent.localAgentCustomOfficeCreditors = Array<LocalAgentCustomOfficeCreditor>();
      this.localAgentCustomOffice = new LocalAgentCustomOfficeCreditor();
      this.localAgentCustomOffice.customsOffice = new CustomOffice();
      this.localAgentCustomOffice.creditorNominated = new CreditorNominated();
      this.localAgentCustomOffice.paymentType = new PaymentType();
    } else {
      this.selectedLocalAgentCustomOffice = new LocalAgentCustomOfficeCreditor();
      this.localAgentCustomOffice = new LocalAgentCustomOfficeCreditor();
      this.localAgentCustomOffice.customsOffice = new CustomOffice();
      this.localAgentCustomOffice.creditorNominated = new CreditorNominated();
      this.localAgentCustomOffice.paymentType = new PaymentType();

    }
    this.localAgent.localAgentCustomOfficeCreditors.push(this.localAgentCustomOffice);
    this.localAgent.localAgentCustomOfficeCreditors = [...this.localAgent.localAgentCustomOfficeCreditors];

  }

  onCreditorRoleSelected(value) {
    this.showCreditorDetalsPanel = true;
    this.localAgentCustomOffice = value.data;
    this.selectedLocalAgentCustomOffice = value.data;
  }

  onCreditorRoleUnSelected(event) {
    this.showCreditorDetalsPanel = true;
    this.localAgentCustomOffice = new LocalAgentCustomOfficeCreditor();
  }

  onBondRoleSelected(value) {
    this.showBondDetalsPanel = true;
    this.localAgentCustomOfficeBond = value.data;
    this.selectedLocalAgentCustomBond = value.data;
  }

  onBondRoleUnSelected(event) {
    this.showBondDetalsPanel = true;
    this.localAgentCustomOfficeBond = new LocalAgentCustomOfficeBond();
  }

  createNewBondLink() {
    this.showBondDetalsPanel = true;
    if (this.localAgent.localAgentCustomOfficeBonds === undefined) {
      this.selectedLocalAgentCustomBond = new LocalAgentCustomOfficeBond();
      this.localAgent.localAgentCustomOfficeBonds = Array<LocalAgentCustomOfficeBond>();
      this.localAgentCustomOfficeBond = new LocalAgentCustomOfficeBond();
      this.localAgentCustomOfficeBond.customsOffice = new CustomOffice();

    } else {
      this.selectedLocalAgentCustomBond = new LocalAgentCustomOfficeBond();
      this.localAgentCustomOfficeBond = new LocalAgentCustomOfficeBond();
      this.localAgentCustomOfficeBond.customsOffice = new CustomOffice();
    }
    this.localAgent.localAgentCustomOfficeBonds.push(this.localAgentCustomOfficeBond);
    this.localAgent.localAgentCustomOfficeBonds = [...this.localAgent.localAgentCustomOfficeBonds];
  }
}
