<div class="ui-g">
  <div class="ui-g-12 ui-md-12">
    <p-panel>
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-4 ui-md-4">
                <p-panel header="Parameters">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="customsValue">
                    <label>Customs Value</label>
                    </span>
                  </div>
                </span>
                          </div>
                          <digi-currency class="ui-g-12 ui-md-12" [currency]="currency" (onSelect)="selectCurrency($event)"></digi-currency>
                          <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                          <div class="ui-float-label">
                            <span class="md-inputfield">
                              <p-inputMask id="exchangeRateDate" mask="99/99/9999"
                                           slotChar="dd/mm/yyyy"
                                           [(ngModel)]="exchangeRateDate">

                              </p-inputMask>
                            <label for="exchangeRateDate">Exchange Rate Date</label>
                            </span>
                          </div>
                        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText [(ngModel)]="uomValue">
                                <label>Number of : {{tariffCode?.formula?.statisticalUOM}}</label>
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-4 ui-md-4">
                <p-panel header="Rates Used">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="divideValue">
                                <label>Divide</label>
                                </span>
                              </div>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="multiplyValue">
                                <label>Multiply</label>
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-4 ui-md-4">
                <p-panel header="Calculated Values">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="totalDuty">
                                <label>Total Duty</label>
                                </span>
                              </div>
                            </span>
                          </div>
                          <div *ngFor="let schedule of schedules" class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="schedule.value">
                                <label>{{schedule.code}}</label>
                                </span>
                              </div>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="customsVat">
                                <label>Customs VAT</label>
                                </span>
                              </div>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text" pInputText disabled [ngModel]="totalDue">
                                <label>Total Due</label>
                                </span>
                              </div>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <button pButton type="button" (click)="calculate()" label="Calculate Duty" icon="fa fa-calculator"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
          <div class="ui-g-12">
            <digi-additional-schedule-tariff [(selectedAdditionalScheduleTariffs)]="selectedAdditionalScheduleTariffs"
                                             [tariffCode]="tariffCode"
                                             [clearingInstruction]="clearingInstruction"
            ></digi-additional-schedule-tariff>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
