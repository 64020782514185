import { BehaviorSubject, Observable } from 'rxjs';
import * as i0 from "@angular/core";
var AuthorisationService = /** @class */ (function () {
    function AuthorisationService() {
        this.loggedInUser = new BehaviorSubject(null);
    }
    AuthorisationService.prototype.addLoggedInUser = function (person) {
        this.loggedInUser.next(person);
    };
    AuthorisationService.prototype.getLoggedInUser = function () {
        return this.loggedInUser.asObservable();
    };
    AuthorisationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthorisationService_Factory() { return new AuthorisationService(); }, token: AuthorisationService, providedIn: "root" });
    return AuthorisationService;
}());
export { AuthorisationService };
