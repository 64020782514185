<div class=" ui-md-12 ui-g-12 base-text payment-file print">
  <div class="ui-g ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="pp-big-rect border-top border-bottom border-right border-left">
        <img src="/assets/img/sars-logo.png" id="sars-logo">
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="pp-big-rect h1-text border-top border-bottom border-right">
        <span>APPLICATION TO MAKE</span><br>
        <span>PROVISIONAL</span><br>
        <span>PAYMENT</span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="pp-big-rect h1-text border-top border-bottom border-right">
        <div class="title">
          <span>DA70</span>
        </div>
        <span>Page 1 of 2</span>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  1. Application Details (*Select appropriate box)</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Name:</span>
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="clientName" [(ngModel)]="paymentFile.clientName" class="ui-g-6 ui-md-6" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Client Code No:</span>
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="clientCode" [(ngModel)]="paymentFile.clientCode" class="ui-g-6 ui-md-6" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Client Reference No:</span>
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="clientReferenceNo" [(ngModel)]="paymentFile.clientReference" class="ui-g-6 ui-md-6" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Importer:    </span>
          <p-checkbox [binary]="true" [(ngModel)]="paymentFile.importer"></p-checkbox>
        </div>
      </div>
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <span>  Exporter:    </span>
          <p-checkbox [binary]="true" [(ngModel)]="paymentFile.exporter"></p-checkbox>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <span>  Other (specify):    </span>
          <p-checkbox [binary]="true" [(ngModel)]="paymentFile.other"></p-checkbox>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  2. Payment Details: (*Insert only the applicable purpose code)</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Purpose:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <span>  Code:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <span>  Registration no. and date received:</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Possible Penalty (PEN):</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Forfeiture (FOR):</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Other (OTH):</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-right"></div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Amount:</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-right align-center">
          <span>  Rand:</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-right align-center">
          <span>  Cent:</span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-top border-right align-center">
          <span>  Amount In Words:</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-right border-left"></div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="amountRands" [(ngModel)]="paymentFile.section2AmountRands" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="amountCents" [(ngModel)]="paymentFile.section2AmountCents" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="amountWords" [(ngModel)]="paymentFile.section2AmountWords" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right border-left">
          <span>  Branch:</span>
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect border-top border-right">
          <input id="clientBranch" [(ngModel)]="paymentFile.branch" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  3. Circumstances of or reasons for application</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="med-rect small-text border-top border-right border-left">
        <span>  Circumstances of or reason for the application (including, in the case of deposit as contemplated in section 91 of the Customs and Excise Act, 1964, the section(s) contravented or not compiled with) and a description fo the transaction involved.</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="med1-rect border-top border-right border-left">
        <textarea id="circumstancesOrReasons" [(ngModel)]="paymentFile.reasonsForApplication" class="ui-g-11 ui-md-11" rows="6"></textarea>
<!--        <input id="circumstancesOrReasons" [(ngModel)]="paymentFile.reasonsForApplication" class="ui-g-11 ui-md-11" type="text" pInputText/>-->
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  4. Declaration</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect small-text border-top border-right border-left">
        <div class="ui-md-1 ui-g-1 ui-g-nopad"> I</div>
        <input id="section4SigningParty" [(ngModel)]="paymentFile.section4SigningParty" class="ui-md-3 ui-g-3 dotted" type="text" pInputText/>
        <div class="ui-md-2 ui-g-2 ui-g-nopad"> ,for and on behalf of</div>
        <input id="section4BusinessParty" [(ngModel)]="paymentFile.section4BusinessParty" class="ui-md-5 ui-g-5 dotted" type="text" pInputText/>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="med-rect small-text border-right border-left">
        <span>  being duly authorised to sign this declaration, hereby undertake to comply with the requirements of the Customs and Excise Act, 1964, and the rules in respect of the goods or circumstances to which this payment relates within the understated period determined by the Branch Manager.</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="signage-rect border-right border-left">
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section4Signature" class="ui-md-6 ui-g-6 dotted" type="text" readonly pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section4Capacity" [(ngModel)]="paymentFile.section4Capacity" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section4Location" [(ngModel)]="paymentFile.section4Location" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section4Date" [(ngModel)]="paymentFile.section4Date" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect border-right border-left">
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Signature</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Capacity</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Place</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Date  (CCYYMMDD)</div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  5. Clearance Details</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect small-text border-top border-right border-left">
          <span>  Movement Reference No (MRN):</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect smaller-text border-top border-right">
          <input id="mrn" [(ngModel)]="paymentFile.section5Mrn" class="ui-g-11 ui-md-11" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
        <div class="pp-small-rect small-text border-top border-right">
          <span>  Date(CCYYMMDD):</span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect smallest-text border-top border-right">
          <input id="dateReference" [(ngModel)]="paymentFile.section5Date" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect small-text border-top border-right border-left">
          <span>  Supplier:</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect smallest-text border-top border-right">
          <input id="supplierReference" [(ngModel)]="paymentFile.section5Supplier" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
        <div class="pp-small-rect small-text border-top border-right">
          <span>  of (Country):    </span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect small-text border-top border-right">
          <input id="countryReference" [(ngModel)]="paymentFile.section5OfCountry" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect border-top border-right"></div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-big-rect small-text border-top border-right border-left">
          <span>  Marks and numbers,quantity and description of goods.</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-big-rect smaller-text border-top border-right">
            <span>Country of<br>origin</span>
          </div>
        </div>
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-big-rect smaller-text border-top border-right">
            <span>Tariff subheading</span>
          </div>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
        <div class="pp-big-rect smaller-text border-top border-right">
          <span>Description and particulars of goods for duty and VAT purposes.</span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad align-center">
        <div class="pp-big-rect small-text border-top border-right">
          <span>  Value R</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  Duty</span>
          </div>
        </div>
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  VAT</span>
          </div>
        </div>

        <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  Rand</span>
          </div>
        </div>
        <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  Cent</span>
          </div>
        </div>
        <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  Rand</span>
          </div>
        </div>
        <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
          <div class="pp-small-rect border-top border-right">
            <span>  Cent</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect small-text border-top border-right border-left">
          <input id="marksNumbersDescription" [(ngModel)]="paymentFile.section5MarksAndNumbers" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-small-rect small-text border-top border-right">
            <input id="countryOfOrigin" [(ngModel)]="paymentFile.section5Coo" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-6 ui-g-6 ui-g-nopad align-center">
          <div class="pp-small-rect smaller-text border-top border-right">
            <input id="tariffSubheading" [(ngModel)]="paymentFile.section5TariffSubheading" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect smallest-text border-top border-right">
          <input id="goodsDescription" [(ngModel)]="paymentFile.section5DescriptionOfGoods" class="ui-g-11 ui-md-11" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad align-center">
        <div class="pp-small-rect smaller-text border-top border-right">
          <input id="clearanceValueReference" [(ngModel)]="paymentFile.section5Value" class="ui-g-10 ui-md-10" type="text" pInputText/>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
          <div class="pp-small-rect smaller-text border-top border-right">
            <input id="clearanceDutyRands" [(ngModel)]="paymentFile.section5DutyRands" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
          <div class="pp-small-rect smaller-text border-top border-right">
            <input id="clearanceDutyCents" [(ngModel)]="paymentFile.section5DutyCents" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
          <div class="pp-small-rect smaller-text border-top border-right">
            <input id="clearanceVatRands" [(ngModel)]="paymentFile.section5VatRands" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-2 ui-g-2 ui-g-nopad align-center">
          <div class="pp-small-rect smaller-text border-top border-right">
            <input id="clearanceVatCents" [(ngModel)]="paymentFile.section5VatCents" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text border-top border-right border-left">
        <span>  6. Application in terms of section 91.</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect small-text border-top border-right border-left">
<!--        <div class="ui-md-6 ui-g-6 ui-g-nopad">-->
<!--          <div class="ui-md-6 ui-g-6 ui-g-nopad"> For the purpose of this section 91, I</div>-->
<!--          <input id="section6SigningParty" [(ngModel)]="paymentFile.section6SigningParty" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>-->
<!--        </div>-->
<!--        <div class="ui-md-6 ui-g-6 ui-g-nopad">-->
<!--          <div class="ui-md-3 ui-g-3 ui-g-nopad"> ,for and on behalf of</div>-->
<!--          <input id="section6BusinessParty" [(ngModel)]="paymentFile.section6BusinessParty" class="ui-md-8 ui-g-8 dotted" type="text" pInputText/>-->
<!--        </div>-->

        <div class="ui-md-4 ui-g-4 ui-g-nopad"> For the purpose of this section 91, I</div>
        <input id="section6SigningParty" [(ngModel)]="paymentFile.section6SigningParty" class="ui-md-2 ui-g-2 dotted" type="text" pInputText/>
        <div class="ui-md-2 ui-g-2 ui-g-nopad"> ,for and on behalf of</div>
        <input id="section6BusinessParty" [(ngModel)]="paymentFile.section6BusinessParty" class="ui-md-3 ui-g-3 dotted" type="text" pInputText/>
      </div>
    </div>


    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="bigger-rect small-text border-right border-left">
        <span>  being duly authorised to sign this declaration, hereby-</span><br>
        <span>  (a) apply for the matter stated in the circumstances column above to be determined by the commissioner.</span><br>
        <span>  (b) agree to abide by the commissioner's decision;and</span><br>
        <span>  (c) deposit the amount required by the Commissioner.</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="signage-rect border-right border-left">
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section6Signature" class="ui-md-6 ui-g-6 dotted" type="text" readonly pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section6Capacity" [(ngModel)]="paymentFile.section6Capacity" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section6Location" [(ngModel)]="paymentFile.section6Location" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad">
          <input id="section6Date" [(ngModel)]="paymentFile.section6Date" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect border-right border-left">
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Signature</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Capacity</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Place</div>
        <div class="ui-md-3 ui-g-3 ui-g-nopad"> Date (CCYYMMDD)</div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-bottom border-right border-left">
          <span>  DA70 Date:</span>
        </div>
      </div>
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-bottom border-right"></div>
      </div>
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-bottom border-right">
          <span>  DA70 Number:</span>
        </div>
      </div>
      <div class="ui-md-3 ui-g-3 ui-g-nopad">
        <div class="pp-small-rect border-top border-bottom border-right"></div>
      </div>
    </div>
  </div>
</div>
