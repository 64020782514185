import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var DepotTerminalService = /** @class */ (function () {
    function DepotTerminalService(http, config) {
        this.http = http;
        this.config = config;
    }
    DepotTerminalService.prototype.findAllDepotTerminalStartsWith = function (searchValue, depotTerminal) {
        return this.http
            .get(this.config.apiEndpoint + "api/depotTerminals/search/codeStartsWithAndDepotTerminalNonPageable?code=" + searchValue + '&depotTerminal=' + depotTerminal)
            .pipe(map(function (response) { return response['_embedded'].depotTerminals; }));
    };
    DepotTerminalService.prototype.findAllDepotTerminalsStartsWith = function (searchValue) {
        return this.http
            .get(this.config.apiEndpoint + "api/depotTerminals/search/codeStartsWith?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].depotTerminals; }));
    };
    DepotTerminalService.ngInjectableDef = i0.defineInjectable({ factory: function DepotTerminalService_Factory() { return new DepotTerminalService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: DepotTerminalService, providedIn: "root" });
    return DepotTerminalService;
}());
export { DepotTerminalService };
