import { Component, Input, KeyValueDiffers, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { ClearingFile } from '../../models/clearingFile';
import { Currency } from '../../models/file';
import { CurrencyService } from '../../services/currency.service';
import { ShareDataService } from '../../services/share-data.service';

@Component({
  selector: 'digi-currency-nested',
  templateUrl: './currency.nested.component.html',
  styleUrls: ['./currency.nested.component.scss']
})
export class CurrencyNestedComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currency') currency: Currency;
  @Input() label;
  clearingFile: ClearingFile;
  selectedCurrency: Currency;
  filteredCurrencies = [];
  subscription: Subscription;

  constructor(
    private sharedDataService: ShareDataService,
    private currencyService: CurrencyService,
    private differs: KeyValueDiffers,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.selectedCurrency = this.currency;
    this.subscription = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => this.clearingFile = cf);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedCurrency = this.currency;
  }

  search(event) {
    this.currencyService.findCurrencyStartWith(event.query).subscribe(data => {
      this.filteredCurrencies = data;
    });
  }

  select(value) {
    this.currency.code = value.code;
    this.currency.name = value.name;
    this.getExchangeRate(this.currency.code);
  }

  getExchangeRate(currencyCode) {
    this.clearingFile.transportDocuments.forEach(t => {
      this.currencyService.getEffectiveCurrencyAndType(currencyCode, t.mawbDate).subscribe(data => {
        this.currency.exchangeRate = data;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
