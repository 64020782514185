import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { BusinessEntity, RebateUser } from '../../models/business-entity';

@Component({
  selector: 'app-rebate-user-role',
  templateUrl: './rebate-user-role.component.html',
  styleUrls: ['./rebate-user-role.component.scss']
})
export class RebateUserRoleComponent implements OnInit, OnChanges {
@Input() businessEntity: BusinessEntity;
@Input() rebateUser: RebateUser;
  constructor() { }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
  }

  ngOnChanges(){
    this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if (!this.rebateUser.customsCode) {
      this.rebateUser.customsCode = this.businessEntity.defaultCustomsCode;
    }

  }
}
