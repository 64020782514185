/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i4 from "primeng/components/dom/domhandler";
import * as i5 from "primeng/components/toast/toast";
import * as i6 from "primeng/components/common/messageservice";
import * as i7 from "primeng/components/common/shared";
import * as i8 from "./notification.component";
import * as i9 from "../../services/notification.service";
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.detail; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "pi pi-exclamation-triangle"], ["style", "font-size: 3em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.detail; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.summary; _ck(_v, 3, 0, currVal_0); }); }
export function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p-toast", [["key", "error"], ["position", "top-right"]], null, null, null, i3.View_Toast_0, i3.RenderType_Toast)), i1.ɵprd(512, null, i4.DomHandler, i4.DomHandler, []), i1.ɵdid(2, 5488640, null, 1, i5.Toast, [i6.MessageService, i4.DomHandler], { key: [0, "key"], baseZIndex: [1, "baseZIndex"], style: [2, "style"], position: [3, "position"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpod(4, { marginTop: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_NotificationComponent_1)), i1.ɵdid(6, 16384, [[1, 4]], 0, i7.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "p-toast", [["key", "success"], ["position", "top-right"]], null, null, null, i3.View_Toast_0, i3.RenderType_Toast)), i1.ɵprd(512, null, i4.DomHandler, i4.DomHandler, []), i1.ɵdid(9, 5488640, null, 1, i5.Toast, [i6.MessageService, i4.DomHandler], { key: [0, "key"], baseZIndex: [1, "baseZIndex"], style: [2, "style"], position: [3, "position"] }, null), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵpod(11, { marginTop: 0 })], function (_ck, _v) { var currVal_0 = "error"; var currVal_1 = 99999; var currVal_2 = _ck(_v, 4, 0, "80px"); var currVal_3 = "top-right"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "message"; _ck(_v, 6, 0, currVal_4); var currVal_5 = "success"; var currVal_6 = 99999; var currVal_7 = _ck(_v, 11, 0, "80px"); var currVal_8 = "top-right"; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "digi-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵprd(512, null, i6.MessageService, i6.MessageService, []), i1.ɵdid(2, 245760, null, 0, i8.NotificationComponent, [i6.MessageService, i9.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NotificationComponentNgFactory = i1.ɵccf("digi-notification", i8.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
