import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var AdditionalInformationService = /** @class */ (function () {
    function AdditionalInformationService(http, config) {
        this.http = http;
        this.config = config;
    }
    AdditionalInformationService.prototype.getAdditionalInformation = function (pageNumber) {
        return this.http.get(this.config.apiEndpoint + "api/boeAddInfoTypes/search/findByFileTypeId?fileTypeId=2&page=" + pageNumber + "&size=20&projection=flattened");
    };
    AdditionalInformationService.ngInjectableDef = i0.defineInjectable({ factory: function AdditionalInformationService_Factory() { return new AdditionalInformationService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: AdditionalInformationService, providedIn: "root" });
    return AdditionalInformationService;
}());
export { AdditionalInformationService };
