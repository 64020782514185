<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12"
             *ngIf="transportDocument.previousBOEDetails">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="transportDocument.previousBOEDetails.customsBOENo">
              <label>Customs BOE No (MRN)</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
              <p-inputMask id="date" mask="99/99/9999" slotChar="dd/mm/yyyy"
                           [(ngModel)]="transportDocument.previousBOEDetails.date">
              </p-inputMask>
              <label for="date">Date</label>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
             <p-autoComplete id="cpc" [ngModel]="transportDocument.previousBOEDetails.cpc"
                             [suggestions]="customsProcedureCodes"
                             [autoHighlight]="true"
                             [forceSelection]="true"
                             (onSelect)="select($event)"
                             (completeMethod)="search()"
                             [dropdown]="true">
              <ng-template let-option pTemplate="item">
                <div>{{option}}</div>
              </ng-template>
            </p-autoComplete>
            <label for="cpc">Customs Procedure Code</label>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-customs-office [customOffice]="transportDocument.previousBOEDetails.customOffice"
                               [isRequired]="false"
                                (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-hawb-port-to portLabel='Destination Port'
                             [portTo]="transportDocument.previousBOEDetails.portTo" (onSelect)="selectPortTo($event)"></digi-hawb-port-to>
        </div>
      </div>
    </div>
  </div>
</div>

