/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boe-permits-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/button/button";
import * as i5 from "../../pipes/permit-numbers.pipe";
import * as i6 from "../../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i7 from "primeng/components/panel/panel";
import * as i8 from "../../../../../node_modules/primeng/components/table/table.ngfactory";
import * as i9 from "primeng/components/utils/objectutils";
import * as i10 from "primeng/components/table/table";
import * as i11 from "primeng/components/common/shared";
import * as i12 from "./boe-permits-section.component";
import * as i13 from "../../services/share-data.service";
import * as i14 from "../../../../subjects/billOfEntry-behaviour-subject";
var styles_BoePermitsSectionComponent = [i0.styles];
var RenderType_BoePermitsSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoePermitsSectionComponent, data: {} });
export { RenderType_BoePermitsSectionComponent as RenderType_BoePermitsSectionComponent };
function View_BoePermitsSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "col", [], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.width; _ck(_v, 0, 0, currVal_0); }); }
function View_BoePermitsSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "colgroup", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoePermitsSectionComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BoePermitsSectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Permit No."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lines"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Value"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quantity"]))], null, null); }
function View_BoePermitsSectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "ui-resizable-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "td", [["class", "ui-resizable-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [["class", "ui-resizable-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "td", [["class", "ui-resizable-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.number; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.lineNumbers)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.permitLineValue; _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.permitLineQuantity; _ck(_v, 13, 0, currVal_3); }); }
function View_BoePermitsSectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoePermitsSectionComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.number; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BoePermitsSectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["label", "Allocate IRCC's/DCC's"], ["name", "open"], ["pButton", ""], ["type", "button"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i4.ButtonDirective, [i1.ElementRef, i3.DomHandler], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Allocate IRCC's/DCC's"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BoePermitsSectionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.PermitNumbersPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "ui-g ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 18, "p-panel", [["header", "Permits"]], null, null, null, i6.View_Panel_0, i6.RenderType_Panel)), i1.ɵdid(5, 49152, null, 1, i7.Panel, [i1.ElementRef], { header: [0, "header"] }, null), i1.ɵqud(335544320, 1, { footerFacet: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 12, "div", [["class", "content-section implementation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "p-table", [["selectionMode", "single"]], null, [[null, "selectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChange" === en)) {
        var pd_0 = ((_co.selectedPermit = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_Table_0, i8.RenderType_Table)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵprd(512, null, i9.ObjectUtils, i9.ObjectUtils, []), i1.ɵprd(512, null, i10.TableService, i10.TableService, []), i1.ɵdid(12, 1294336, null, 1, i10.Table, [i1.ElementRef, i3.DomHandler, i9.ObjectUtils, i1.NgZone, i10.TableService], { selectionMode: [0, "selectionMode"], resizableColumns: [1, "resizableColumns"], value: [2, "value"], selection: [3, "selection"] }, { selectionChange: "selectionChange" }), i1.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_BoePermitsSectionComponent_1)), i1.ɵdid(15, 16384, [[2, 4]], 0, i11.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_BoePermitsSectionComponent_3)), i1.ɵdid(17, 16384, [[2, 4]], 0, i11.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_BoePermitsSectionComponent_4)), i1.ɵdid(19, 16384, [[2, 4]], 0, i11.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(20, 0, null, 1, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_BoePermitsSectionComponent_6)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Permits"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "single"; var currVal_2 = true; var currVal_3 = _co.selectedBillOfEntry.permits; var currVal_4 = _co.selectedPermit; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "colgroup"; _ck(_v, 15, 0, currVal_5); var currVal_6 = "header"; _ck(_v, 17, 0, currVal_6); var currVal_7 = "body"; _ck(_v, 19, 0, currVal_7); var currVal_8 = (_co.fileType === "imports"); _ck(_v, 22, 0, currVal_8); }, null); }
export function View_BoePermitsSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-boe-permits-section", [], null, null, null, View_BoePermitsSectionComponent_0, RenderType_BoePermitsSectionComponent)), i1.ɵdid(1, 245760, null, 0, i12.BoePermitsSectionComponent, [i13.ShareDataService, i14.BillOfEntryBehaviourSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoePermitsSectionComponentNgFactory = i1.ɵccf("digi-boe-permits-section", i12.BoePermitsSectionComponent, View_BoePermitsSectionComponent_Host_0, { fileType: "fileType" }, {}, []);
export { BoePermitsSectionComponentNgFactory as BoePermitsSectionComponentNgFactory };
