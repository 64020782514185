import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var TariffRefService = /** @class */ (function () {
    function TariffRefService(http, config) {
        this.http = http;
        this.config = config;
    }
    TariffRefService.prototype.findByCode = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/tariffRefs/search/findByReferenceCode?ReferenceCode=" + code);
    };
    TariffRefService.prototype.findByReferenceCodeStartWith = function (code) {
        if (code === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/tariffRefs/search/findByReferenceCodeStartWith?referenceCode="
            + code)
            .pipe(map(function (response) { return response['_embedded'].tariffRefs; }));
    };
    TariffRefService.prototype.findAll = function () {
        return this.http
            .get(this.config.apiEndpoint + "api/formulae/search/findByFormulaNoNonPageable")
            .pipe(map(function (response) { return response['_embedded'].formulae; }));
    };
    TariffRefService.ngInjectableDef = i0.defineInjectable({ factory: function TariffRefService_Factory() { return new TariffRefService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: TariffRefService, providedIn: "root" });
    return TariffRefService;
}());
export { TariffRefService };
