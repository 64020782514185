<div class="ui-g-12 ui-md-12">
  <span class="md-inputfield">
    <div class="ui-inputgroup">
        <span class="ui-float-label">
          <p-autoComplete id="customsTraderType" [(ngModel)]="selectedTradeType" field="description"
                          [dropdown]="true"
                          [suggestions]="filteredTradeTypes"
                          (completeMethod)="search($event)"
                          [autoHighlight]="true" (onSelect)="select($event)" minLength="1"
                          required="true">

          </p-autoComplete>
      <label for="customsTraderType">Registration Type</label>
    </span>
    </div>
    <div *ngIf="!selectedTradeType"
         class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
  </span>
</div>
