import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import {BillOfEntry, ExportBillOfEntry, Permit} from '../../models/billOfEntries';
import { ShareDataService } from '../../services/share-data.service';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-boe-permits-section',
  templateUrl: './boe-permits-section.component.html',
  styleUrls: ['./boe-permits-section.component.scss']
})
export class BoePermitsSectionComponent implements OnInit, OnDestroy {
  selectedBillOfEntry: BillOfEntry|ExportBillOfEntry;
  cols: any;
  selectedPermit: Permit;
  billSubscription: Subscription;
  @Input() fileType: FileType;

  constructor(private shareDataService: ShareDataService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;
      });

  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }
}
