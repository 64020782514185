import {Component, Input, OnInit} from '@angular/core';
import {Country, CrewMember} from '../../models/file';
import {Gender, Occupation, ReasonForMovement, TravelDocumentType, TravelerType} from '../../models/enumerations';

@Component({
  selector: 'digi-crew-member',
  templateUrl: './crew-member.component.html',
  styleUrls: ['./crew-member.component.scss']
})
export class CrewMemberComponent implements OnInit {
  @Input() selectedCrewMember: CrewMember;
  @Input() showRole: boolean;
  genderOptions: any[];
  occupationOptions: any[];
  reasonForMovementOptions: any[];
  travelerDocTypeOptions: any[];
  travelerTypeOptions: any[];

  constructor() { }

  ngOnInit() {
    const defaultValue = [{'label': '', 'value': ''}];
    this.genderOptions = defaultValue.concat(Object.keys(Gender).map(key => ({ 'label': Gender[key], 'value': key })));
    this.occupationOptions = defaultValue.concat(Object.keys(Occupation).map(key => ({ 'label': Occupation[key], 'value': key })));
    this.reasonForMovementOptions = defaultValue.concat(Object.keys(ReasonForMovement)
      .map(key => ({ 'label': ReasonForMovement[key], 'value': key })));
    this.travelerDocTypeOptions = defaultValue.concat(Object.keys(TravelDocumentType)
      .map(key => ({ 'label': TravelDocumentType[key], 'value': key })));
    this.travelerTypeOptions = defaultValue.concat(Object.keys(TravelerType).map(key => ({ 'label': TravelerType[key], 'value': key })));
  }

  format(value) {
    return value.replace(/_/g, ' ').toLowerCase();
  }

  selectCountryOfResidence(value) {
    this.selectedCrewMember.countryOfResidence = new Country();
    this.selectedCrewMember.countryOfResidence.label = value.label;
    this.selectedCrewMember.countryOfResidence.code = value.code;
    this.selectedCrewMember.countryOfResidence.name = value.name;
  }

  selectNationality(value) {
    this.selectedCrewMember.nationality = new Country();
    this.selectedCrewMember.nationality.label = value.label;
    this.selectedCrewMember.nationality.code = value.code;
    this.selectedCrewMember.nationality.name = value.name;
  }

  selectTravelDocCountryOfIssue(value) {
    this.selectedCrewMember.travelDocCountryOfIssue = new Country();
    this.selectedCrewMember.travelDocCountryOfIssue.label = value.label;
    this.selectedCrewMember.travelDocCountryOfIssue.code = value.code;
    this.selectedCrewMember.travelDocCountryOfIssue.name = value.name;
  }

}
