import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {BillsOfEntry} from '../../digi-common/models/billsOfEntry';
import {Observable} from 'rxjs';
import {ExBondBillOfEntry} from '../../digi-common/models/billOfEntries';
import {map} from 'rxjs/operators';
import {ExBondFile} from '../../digi-common/models/exBondFile';

@Injectable({
  providedIn: 'root'
})
export class ExBondBillOfEntryService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {

  }

  generateBOE(billsOfEntry: BillsOfEntry, exBondFile: ExBondFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
      billsOfEntry.boes[0].exBondFile = exBondFile._links.self.href;
      return this.http.put(
        billsOfEntry.boes[0]._links.self.href,
        JSON.stringify(billsOfEntry.boes[0]),
        {headers}
      ).pipe(
        map(response => response['_embedded'].exBondBillOfEntries)
      );
    } else {
      if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].exBondFile) {
        billsOfEntry.boes[0].exBondFile = exBondFile._links.self.href;
      }
      return this.http.post(
        this.config.filesApiEndpoint + 'api/exbondFiles/' + exBondFile.id + '/exBondBillOfEntries/generate',
        {headers}
      ).pipe(
        map(response => response['_embedded'].exBondBillOfEntries)
      );
    }
  }

  regenerateBOE(exBondFile: ExBondFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.put(this.config.filesApiEndpoint + 'api/exbondFiles/' + exBondFile.id + '/exBondBillOfEntries/regenerate', {headers}
    ).pipe(
      map(response => response['_embedded'].exBondBillOfEntries)
    );
  }

  findPreviousBillOfEntry(lrn: String, sequence: number, isManuallySubmitted: boolean): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exBondBillOfEntries/search/findByLrnAndSequenceNumberAndMarkedAsSubmitted?lrn=`
      + lrn + '&sequenceNumber=' + sequence + '&isManuallySubmitted=' + isManuallySubmitted);
  }

  findParentExBondFile(billId, fileType): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exbondFileCorrections/` + billId + '/parent');
  }

  findBillOfEntryById(billId): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/importBillOfEntries/` + billId);
  }

  saveBillOfEntry(billOfEntry: ExBondBillOfEntry, exBondFile: ExBondFile): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    if (billOfEntry._links && billOfEntry._links.self) {
      billOfEntry.exBondFile = exBondFile._links.self.href;
      const url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
      if (billOfEntry.cusdecs) {
        billOfEntry.cusdecs = billOfEntry.cusdecs.filter(cusdec => cusdec !== null);
      }
      return this.http.put(url, JSON.stringify(billOfEntry), {headers});
    }
  }

  loadFiles(id: String): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/document/` + id);
  }

  loadUploadedFile(billOfEntry: ExBondBillOfEntry): Observable<any> {
    return this.http
      .get(`${billOfEntry._links.files.href}`);
  }

  uploadFiles(files: File[], billOfEntry: ExBondBillOfEntry) {
    const data = new FormData();
    data.append('file', files[0]);
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(`${billOfEntry._links.files.href}`, data, {headers});
  }

  retrieveBillOfEntry(currentBillOfEntry): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
    return this.http.get(url);
  }

  findBillOfEntryByExBondFile_id(id): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exBondBillOfEntries/search/findByExBondFile_Id?id=` + id).pipe(
      map(response => response['_embedded'].exBondBillOfEntries)
    );
  }

  findAllEdiMessages(page = 0, size = 10, indicator): Observable<any> {
    return this.http.get(
      `${this.config.filesApiEndpoint}api/exBondBillOfEntries?projection=onlyEdiMessages&page=${page}&size=${size}&sort=dateEdiSubmitted,desc`
    );
  }
}
