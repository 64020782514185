import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { DiagnosticTest } from './diagnostic-test.model';
import {AppConfig} from '../../app.config.module';
import { BusinessEntityService } from 'src/app/digi-business-entity/services/business-entity.service';
import { CurrencyService } from '../services/currency.service';
import { SendEdiToSarsService } from '../services/send-edi-to-sars.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private businessEntityService: BusinessEntityService,
    private currencyService: CurrencyService,
    private sendEdiToSarsService: SendEdiToSarsService) { }

  getDiagnosticServiceTests():DiagnosticTest[] {
    let diagnosticTests = [];
    diagnosticTests.push(new DiagnosticTest(1, 'Business Entity Config Validation',
      null
    ));
    diagnosticTests.push(new DiagnosticTest(2, 'Auth API connectivity test',
      null
    ));
    diagnosticTests.push(new DiagnosticTest(3, 'File REST API connectivity test',
      this.businessEntityService.findBusinessEntityStartsWith('') )
    );
    diagnosticTests.push(new DiagnosticTest(4, 'Entity REST API connectivity test',
      this.currencyService.getExchangeRateTypes()
    ));
    diagnosticTests.push(new DiagnosticTest(5, 'EDI REST API connectivity test',
      null
    ));
    diagnosticTests.push(new DiagnosticTest(6, 'EDI end-to-end test [Check EDI responses on server /home/pipelines/inbox/]',
      this.sendEdiToSarsService.sendEdiTest()
    ));
    return diagnosticTests;
  }
}
