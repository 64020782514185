import * as i0 from "@angular/core";
var TenantService = /** @class */ (function () {
    function TenantService() {
        this.oauthClientId = 'freight-angular-ui';
        this.branchCode = 4;
        this.init();
    }
    TenantService.prototype.init = function () {
        this.tenantId = this.getTenantForHostname(location.hostname);
        console.log('initialising tenant with ID: ' + this.tenantId);
        if (this.tenantId === 'localhost') {
            this.initLocalHost();
            this.enableTracking = true;
        }
        else {
            this.initWithTenantIdPrefix();
        }
        console.log('oauthEndpoint=%s, oauthRealm=%s, apiEndpoint=%s, filesApiEndpoint=%s, production=%s', this.oauthEndpoint, this.oauthRealm, this.apiEndpoint, this.filesApiEndpoint, this.production);
    };
    TenantService.prototype.initLocalHost = function () {
        //  this.initWithTenantIdPrefix(); // TODO: JUST FOR TESTING!!!
        this.oauthEndpoint = 'http://localhost:8090/auth';
        this.oauthRealm = 'freight-services';
        this.apiEndpoint = 'http://localhost:8080/';
        this.filesApiEndpoint = 'http://localhost:8081/';
        this.production = false;
        // TEST tenant functions
        this.getTenantForHostname('beta.mylocal.digi-freight.co.za');
        this.getTenantForHostname('mylocal.digi-freight.co.za');
    };
    /*
       apiEndpoint: 'http://jayden.digi-freight.co.za/entities/',
       filesApiEndpoint: 'http://jayden.digi-freight.co.za/files/',
       endpoint: 'http://jayden.digi-freight.co.za/auth',
       realm: 'jayden-freight-services',
       clientId: 'freight-angular-ui',
    */
    TenantService.prototype.initWithTenantIdPrefix = function () {
        this.oauthEndpoint = "http://" + this.tenantId + ".digi-freight.co.za/auth";
        this.oauthRealm = this.tenantId + "-freight-services";
        this.apiEndpoint = "http://" + this.tenantId + ".digi-freight.co.za/entities/";
        this.filesApiEndpoint = "http://" + this.tenantId + ".digi-freight.co.za/files/";
        if (this.tenantId.startsWith('beta')) {
            this.production = false;
            this.oauthRealm = this.oauthRealm.substring(5); // trim beta off from realm name
            if (this.tenantId === 'beta.evashin') {
                this.branchCode = 1;
            }
        }
        else {
            this.production = true;
        }
        if (this.tenantId === 'beta.evashin' || this.tenantId === 'intercargo') {
            this.zipRequests = true;
        }
        if (this.tenantId === 'beta.evashin' || this.tenantId === 'rtclearing' || this.tenantId === 'beta.sarles') {
            this.enableTracking = true;
        }
    };
    TenantService.prototype.getTenantForHostname = function (hostname) {
        if (hostname === 'localhost') {
            return hostname;
        }
        var baseUrl = '.digi-freight.co.za';
        var tenantId = hostname.substring(0, hostname.indexOf(baseUrl));
        console.log('tenantId=%s for hostname=%s', tenantId, hostname);
        return tenantId;
        // return this.getTenantForHost(hostname.split(".")[0]);
    };
    TenantService.prototype.getTenantForString = function (s) {
        /*for (const e in Tenant) {
          if (e.toLowerCase() === s.toLowerCase()) {
            return Tenant[e] as Tenant;
          }
        }*/
        return s;
    };
    TenantService.prototype.getTenantForHost = function (host) {
        return this.getTenantForString(host);
    };
    TenantService.prototype.addTenantToHeaders = function (headers) {
        return headers.append('X-Tenant-ID', this.tenantId);
    };
    TenantService.ngInjectableDef = i0.defineInjectable({ factory: function TenantService_Factory() { return new TenantService(); }, token: TenantService, providedIn: "root" });
    return TenantService;
}());
export { TenantService };
