import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subscription, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { ClearingFile } from '../../models/clearingFile';
import { Correction } from '../../models/file';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileService } from '../../services/clearing-file.service';
import { ShareDataService } from '../../services/share-data.service';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {ExportFileService} from '../../services/export-file.service';
import {ExBondFileBehaviourSubject} from '../../../../subjects/exbondfile-behaviour-subject';
import {ExBondFile} from '../../models/exBondFile';
import {ExBondFileService} from '../../../digi-exbond/services/ex-bond-file.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-sequence-modal',
  templateUrl: './sequence-modal.component.html',
  styleUrls: ['./sequence-modal.component.scss']
})
export class SequenceModalComponent implements OnInit, OnDestroy {
  @Input('display') display;
  @Input() fileType: FileType;
  file: ClearingFile|ExportFile;
  @Output() displayChange = new EventEmitter();
  filteredFiles: SelectItem[] = [];
  selectedSequence: any;
  subscription: Subscription;
  private assessmentDate: string;
  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileService: ClearingFileService,
              private exportFileService: ExportFileService,
              private exBondFileService: ExBondFileService,
              private authorisationService: AuthorisationService,
              private shareDataService: ShareDataService) {

  }

  ngOnInit() {
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject
        .getClearingFile()
        .subscribe((cr: ClearingFile) => {
          this.file = cr;
          if (this.file && this.file.clearingFileNumber) {
            this.findSequenceNumbers(this.file.clearingFileNumber);
          }
        });
    } else if (this.fileType === 'exports') {
      this.subscription = this.exportFileBehaviourSubject
        .getExportFile()
        .subscribe((ef: ExportFile) => {
          this.file = ef;
          if (this.file && this.file.clearingFileNumber) {
            this.findSequenceNumbers(this.file.clearingFileNumber);
          }
        });
    } else {
      this.subscription = this.exBondFileBehaviourSubject
        .getExBondFile()
        .subscribe((ef: ExBondFile) => {
          this.file = ef;
          if (this.file && this.file.clearingFileNumber) {
            this.findSequenceNumbers(this.file.clearingFileNumber);
          }
        });
    }
    this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(boe => {
      if (boe && boe.sequenceNumber === 0 && boe.dateEdiSubmitted){
        this.assessmentDate = boe.dateEdiSubmitted;
      }
    });
  }

  findSequenceNumbers(clearingFileNumber) {
    const copyOfFiltedSquences = [];
    if (this.fileType === 'imports') {
      this.clearingFileService.findAllSequences(clearingFileNumber).subscribe(
        data => {
          data.forEach(function (value) {
            const clearingFile: SelectItem = new class implements SelectItem {
              label: string;
              value: number;
            };
            clearingFile.label = value.clearingFileNumber;
            clearingFile.value = value.sequence;
            copyOfFiltedSquences.push(clearingFile);
          });
          this.filteredFiles = [...copyOfFiltedSquences];
        });
    } else if (this.fileType === 'exports') {
      this.exportFileService.findAllSequences(clearingFileNumber).subscribe(
        data => {
          data.forEach(function (value) {
            const exportFile: SelectItem = new class implements SelectItem {
              label: string;
              value: number;
            };
            exportFile.label = value.clearingFileNumber;
            exportFile.value = value.sequence;
            copyOfFiltedSquences.push(exportFile);
          });
          this.filteredFiles = [...copyOfFiltedSquences];
        });
    } else {
      this.exBondFileService.findAllSequences(clearingFileNumber).subscribe(
        data => {
          data.forEach(function (value) {
            const exportFile: SelectItem = new class implements SelectItem {
              label: string;
              value: number;
            };
            exportFile.label = value.clearingFileNumber;
            exportFile.value = value.sequence;
            copyOfFiltedSquences.push(exportFile);
          });
          this.filteredFiles = [...copyOfFiltedSquences];
        });
    }
  }

  onClose() {
    this.displayChange.emit(false);
  }

  findSelectedSequenceNumber() {
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry(this.fileType));
    if (this.selectedSequence === undefined) {
      this.selectedSequence = 0;
      this.getClearingFileByNumberAndSequence(this.selectedSequence);
    } else {
      if (this.selectedSequence) {
        this.getClearingFileByNumberAndSequence(this.selectedSequence);
      }
    }
  }

  private getClearingFileByNumberAndSequence(selectedSequence) {
    console.log(selectedSequence);
    if (this.fileType === 'imports') {
      this.clearingFileService
        .findByClearingFileNumberAndSequence(this.file.clearingFileNumber, selectedSequence)
        .subscribe(data => {
          const parent: ClearingFile = data[0];
          const correction: Correction = Object.assign(new Correction(), parent);
          correction.id = undefined;
          correction.claimVat = true;
          correction.type = 'Correction';
          correction._links = undefined;
          correction.parent = parent._links.self.href;
          const max = Math.max.apply(Math, this.filteredFiles.map(function (o) {
            return o.value;
          }));
          correction.sequence = max + 1;
          correction.assessmentDate = this.assessmentDate;
          this.getCurrentUser().subscribe(user => correction.reference.channel = user);
          this.clearingFileBehaviourSubject.addClearingFile(correction);
          this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry(this.fileType));
          this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        });
    } else if (this.fileType === 'exports') {
      this.exportFileService
        .findByClearingFileNumberAndSequence(this.file.clearingFileNumber, selectedSequence)
        .subscribe(data => {
          const parent: ExportFile = data[0];
          const correction: Correction = Object.assign(new Correction(), parent);
          correction.id = undefined;
          correction.type = 'ExportFileCorrection';
          correction._links = undefined;
          correction.parent = parent._links.self.href;
          const max = Math.max.apply(Math, this.filteredFiles.map(function (o) {
            return o.value;
          }));
          correction.sequence = max + 1;
          correction.assessmentDate = this.assessmentDate;
          this.getCurrentUser().subscribe(user => correction.reference.channel = user);
          this.exportFileBehaviourSubject.addExportFile(correction);
          this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry(this.fileType));
          this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        });
    } else {
      this.exBondFileService
        .findByClearingFileNumberAndSequence(this.file.clearingFileNumber, selectedSequence)
        .subscribe(data => {
          const parent: ExBondFile = data[0];
          const correction: Correction = Object.assign(new Correction(), parent);
          correction.id = undefined;
          correction.type = 'ExBondFileCorrection';
          correction._links = undefined;
          correction.parent = parent._links.self.href;
          const max = Math.max.apply(Math, this.filteredFiles.map(function (o) {
            return o.value;
          }));
          correction.sequence = max + 1;
          correction.assessmentDate = this.assessmentDate;
          this.getCurrentUser().subscribe(user => correction.reference.channel = user);
          this.exBondFileBehaviourSubject.addExBondFile(correction);
          this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry(this.fileType));
          this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        });
    }
  }

  private getCurrentUser(): Observable<string> {
      return this.authorisationService.getLoggedInUser().pipe(
        map(user => {
         if (user) {
           if (user.firstName && user.lastName) {
             return user.firstName + " " + user.lastName;
           } else {
             return user.name;
           }
         } else {
            return "";
         }
        })
      );
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.displayChange.unsubscribe();
  }
}

