import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BillOfEntryLine } from '../../models/billOfEntries';

@Component({
  selector: 'digi-boe-lines-table-section',
  templateUrl: './boe-lines-table-section.component.html',
  styleUrls: ['./boe-lines-table-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoeLinesTableSectionComponent implements OnInit {
  @Input() billOfEntryLines: BillOfEntryLine[];
  @Output() selectedBillOfEntryLine: EventEmitter<any> = new EventEmitter();
  @Input('defaultSelectedBillOfEntryLine') defaultSelectedBillOfEntryLine;

  constructor() {
  }

  ngOnInit() {
  }

  onRowSelect(event) {
    this.selectedBillOfEntryLine.emit(event.index);
  }
}
