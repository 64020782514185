import { AppConfig } from '../..//app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CountryService = /** @class */ (function () {
    function CountryService(http, config) {
        this.http = http;
        this.config = config;
    }
    CountryService.prototype.getCountry = function (code) {
        return this.http.get(this.config.apiEndpoint + "api/countries/" + code + "?projection=countryWithTradeAgreement");
    };
    CountryService.prototype.findCountryStartsWith = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/countries/search/codeOrNameStartsWithNonPageable?code=" + code + "&projection=countryWithTradeAgreement")
            .pipe(map(function (response) { return response['_embedded'].countries; }));
    };
    CountryService.ngInjectableDef = i0.defineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
export { CountryService };
