import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';
import {Address} from '../../models/file';
import {BusinessEntity, Country, Currency, ExportConsignee, Exporter} from '../../../digi-business-entity/models/business-entity';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'digi-manifest-business-entity-search',
  templateUrl: './manifest-business-entity-search.component.html',
  styleUrls: ['./manifest-business-entity-search.component.scss']
})
export class ManifestBusinessEntitySearchComponent implements OnInit, OnChanges {

  @Input() businessEntity;
  @Input() businessEntities;
  @Input() roleType: number;
  @Input() label: string;
  @Input() dropdown = false;
  @Output() updateBusinessEntity = new EventEmitter();
  filteredBusinessEntities: any[];
  addresses: Address[];
  display: boolean;
  address = new Address();
  newBusinessEntity = new BusinessEntity();
  @Input() displayNewButton = false;
  currency: Currency = new Currency();
  country: Country = new Country();

  constructor(
    private businessEntityService: BusinessEntityService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.businessEntity && changes.businessEntity.currentValue) {
      this.businessEntity = changes.businessEntity.currentValue;
      if (this.businessEntity) {
        const code = this.businessEntity.code ? this.businessEntity.code : this.businessEntity.businessEntityCode;
        if (code) {
          this.businessEntityService.findBusinessEntityStartsWithAndRoleType(code, this.roleType)
            .subscribe(
              businessEntities => this.selectBusinessEntity(businessEntities[0])
            );
        } else {
          this.newBusinessEntity = new BusinessEntity();
        }
      }
    }

    if (changes.businessEntities) {
      this.filteredBusinessEntities = changes.businessEntities.currentValue;
    }

    if (changes.displayNewButton) {
      this.displayNewButton = changes.displayNewButton.currentValue;
    }
  }

  selectBusinessEntity(value) {
    if (value) {
      this.businessEntity.code = value.code;
      this.businessEntity.name = value.name;
      this.businessEntity.label = value.label;
      this.businessEntity.customsRegCode = value.customsRegCode ? value.customsRegCode : value.defaultCustomsCode;
      this.addresses = value.addresses;
      if (this.addresses && this.addresses.length > 0) {
        this.businessEntity.address = this.addresses[0];
      }

      this.updateBusinessEntity.emit(this.businessEntity);
    }
  }


  searchBusinessEntity(event) {
    if (event.query !== '' && ![1, 2, 5, 21].includes(this.roleType)) {
      this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, this.roleType).subscribe(
        businessEntities => this.filteredBusinessEntities = businessEntities
      );
    } else if (this.filteredBusinessEntities && event.query) {
      this.filteredBusinessEntities = this.businessEntities.filter(be => be.code && be.code.toLowerCase().startsWith(event.query.toLowerCase()) ||
        be.businessEntityCode && be.businessEntityCode.toLowerCase().startsWith(event.query.toLowerCase()));
    } else {
      this.filteredBusinessEntities = [...this.filteredBusinessEntities];
    }
  }

  openBusinessEntityModal() {
    this.display = true;
    this.newBusinessEntity = new BusinessEntity();
    this.address = new Address();
  }

  onSave() {
    let role;
    if (this.newBusinessEntity && !this.newBusinessEntity.id) {
      this.newBusinessEntity.registeredName = this.newBusinessEntity.name;
      this.newBusinessEntity.roles = [];
      if (this.roleType === 12) {
        role = new Exporter();
      } else {
        role = new ExportConsignee();
      }
      role.roleType = this.roleType;
      role.country = this.country;
      role.currency = this.currency;
    }
    this.newBusinessEntity.roles.push(role);
    this.newBusinessEntity.roles = [...this.newBusinessEntity.roles];

    this.businessEntityService.saveBusinessEntity(this.newBusinessEntity)
      .subscribe(data => {
        this.newBusinessEntity = data;
        this.updateBusinessEntity.emit(this.newBusinessEntity);
        this.notificationService.successNotify('Business Entity Saved');
        if (this.address && this.address.line1) {
          this.newBusinessEntity.addresses = [this.address];
          this.businessEntityService.saveBusinessEntity(this.newBusinessEntity).subscribe(
            be => {
              this.newBusinessEntity = be;
              this.newBusinessEntity.address = this.address;
              this.updateBusinessEntity.emit(this.newBusinessEntity);
            }
          );
        }
      }, error => {
        this.notificationService.errorNotify(error.error.message, [error.error.errors]);
      });
  }

  onClose() {
    this.display = false;
  }

  selectCurrency(value: Currency) {
    this.currency.code = value.code;
    this.currency.name = value.name;
    this.currency.label = value.label;
  }

  selectCountry(value: Country) {
    this.country.code = value.code;
    this.country.name = value.name;
    this.country.label = value.label;
  }
}
