import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LineDetails, ShippingOrder} from '../../models/exportFile';
import {BillOfEntryMarksAndNumbers} from '../../models/billOfEntries';

@Component({
  selector: 'digi-shipping-order',
  templateUrl: './shipping-order.component.html',
  styleUrls: ['./shipping-order.component.scss']
})
export class ShippingOrderComponent implements OnInit, OnChanges {
  @Input() shippingOrder = new ShippingOrder();
  lineDetailsCols: any[];
  marksAndNumbers: BillOfEntryMarksAndNumbers[];
  constructor() { }

  ngOnInit() {
    this.lineDetailsCols = [
      {
        header: 'No. Of Packs',
        width: '10%'
      },
      {
        header: 'Description of Goods',
      },
      {
        header: 'Mass (kg)',
        width: '10%'
      },
      {
        header: 'Length',
        width: '10%'
      },
      {
        header: 'Width',
        width: '10%'
      },
      {
        header: 'Height',
        width: '10%'
      },
      {
        header: '',
        width: '5%'
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shippingOrder && changes.shippingOrder.currentValue) {
      this.shippingOrder = changes.shippingOrder.currentValue;
      this.marksAndNumbers = [
        {
          marksAndNumbers: this.shippingOrder.marksAndNumbers,
          endorsements: []
        }
      ];
    }
  }

  deleteLineDetail(rowIndex: any) {
    this.shippingOrder.lineDetails.splice(rowIndex, 1);
    this.shippingOrder.lineDetails = [...this.shippingOrder.lineDetails];
  }

  addLineDetail() {
    if (this.shippingOrder.lineDetails) {
      this.shippingOrder.lineDetails.push(new LineDetails());
    } else {
      this.shippingOrder.lineDetails = [new LineDetails()];
    }
  }
}
