<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-panel header="Nomination">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-10">
            <div class="ui-inputgroup">
                            <span class="ui-float-label">
                                <p-autoComplete id="nomination"
                                                [suggestions]="filteredNominationTypes"
                                                [dropdown]="true" field="displayText"
                                                [(ngModel)]="selectedNominationType"
                                                (completeMethod)=searchRoleNominationTypes($event)
                                                [autoHighlight]="true">
                                    <ng-template let-option pTemplate="item">
                                        <div>{{option.displayText}}</div>
                                    </ng-template>
                                </p-autoComplete>
                                <label for="nomination">Nomination</label>
                            </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-2">
            <button pButton type="button" (click)="addNewNomination()" label="New" icon="fa
                            fa-file">
            </button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12">
      <p-table [value]="activeRole?.roleNominations" [(selection)]="selectedRoleNomination" selectionMode="single"
               [resizableColumns]="true" (onRowSelect)="onRoleSelected($event)"
               (onRowUnselect)="onRoleUnSelected($event)">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" colspan="3">
              Code
            </th>
            <th scope="col" colspan="3">
              Name
            </th>
            <th scope="col" colspan="3">
              Role Name
            </th>
            <th scope="col" colspan="3">
              Role Type
            </th>
            <th style="width: 4%">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td scope="row" colspan="3">
              <label>{{rowData?.businessEntityCode}}</label>
            </td>
            <td colspan="3">
              <label>{{rowData?.businessEntityName}}</label>
            </td>
            <td colspan="3">
              <label>{{getRoleName(rowData?.roleType)}}</label>
            </td>
            <td colspan="3">
              <label>{{rowData?.roleType}}</label>
            </td>
            <td>
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>

      </p-table>

      <div class="ui-g-12" *ngIf="selectedNominationType && selectedRoleNomination">
        <p-panel header="{{selectedNominationType?.displayText}}">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-6">
              <div class="" style="color: black;">
                <div class="ui-g-12 ui-md-12">
                                   <span class="md-inputfield">
                                    <div class="ui-inputgroup">
                                        <span class="ui-float-label">
                                        <p-autoComplete [suggestions]="filteredBusinessEntity"
                                                        [(ngModel)]="nominatedBusinessEntity" field="label"
                                                        (onSelect)="selectBusinessEntity($event)" [minLength]="3"
                                                        (completeMethod)="searchEntityByRole($event)"
                                                        [autoHighlight]="true">
                                            <ng-template let-option pTemplate="item">
                                                <div>{{option.code}}:{{option.name}}</div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <label>Search</label>

                                        </span>
                                      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                                    </div>
                                      <div *ngIf="!nominatedBusinessEntity?.code"
                                           class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
                                   </span>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                    <input type="text" [(ngModel)]="selectedRoleNomination.vdnNumber" pInputText>
                                    <label>VDN Number</label>
                                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                        <input type="text" [(ngModel)]="selectedRoleNomination.vdnMarkup" pInputText>
                                        <label>VDN Markup %</label>
                                    </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21 || selectedRoleNomination?.roleType === 2">
              <digi-purchase-terms [purchaseTerm]="selectedRoleNomination?.defaultPurchaseTerm"></digi-purchase-terms>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                    <input type="text" [(ngModel)]="selectedRoleNomination.expCustomsCode" pInputText>
                                    <label>Customs Code</label>
                                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
            <span class="ui-float-label">
              <p-autoComplete [suggestions]="filteredValuationMethodTypes"
                              [(ngModel)]="selectedDefaultValidationMethod" field="label"
                              (onSelect)="selectValuationMethod($event)" [minLength]="1"
                              (completeMethod)=searchValuationMethods($event)
                              [autoHighlight]="true"
                              [dropdown]="true">
                                        </p-autoComplete>
              <label>Default Valuation Method</label>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
            <span class="ui-float-label">
              <p-autoComplete [suggestions]="filteredValuationCodes"
                              [(ngModel)]="selectedDefaultValidationCode" field="label"
                              (onSelect)="selectValuationCode($event)" [minLength]="1"
                              (completeMethod)=searchValuationCodes($event)
                              [autoHighlight]="true"
                              [dropdown]="true">
                                        </p-autoComplete>
              <label>Default Valuation Code</label>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 21">
              <div class="ui-inputgroup">
                <p-checkbox [(ngModel)]="selectedRoleNomination.vdnWarning" label="VDN Warning"
                            binary="true"></p-checkbox>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="selectedRoleNomination?.roleType === 13">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                        <input type="text" [(ngModel)]="selectedRoleNomination.customsWarehouseNumber" pInputText>
                                        <label>Customs Warehouse Number</label>
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</div>
