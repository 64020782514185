import {map, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {EMPTY} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {ClearingFile} from '../models/clearingFile';
import {Address} from '../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ExportFile} from '../models/exportFile';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntitySearchService {

  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findBusinessEntityNameAndRole(name: string, role: number) {
    let projection;
    switch (role) {
      case 9:
        projection = 'flattenedRebateUser';
        break;
      case 6:
        projection = 'flattenedImporter';
        break;
      case 21:
        projection = 'flattenedSupplier';
        break;
      case 1:
        projection = 'flattenedDebtor';
        break;
      case 4:
        projection = 'flattenedLocalAgent';
        break;
      case 8:
        projection = 'flattenedRemover';
        break;
      case 13:
        projection = 'flattenedWarehouse';
        break;
      case 11:
         projection = 'flattenedCreditor';
        break;
      case 3:
        projection = 'flattenedConsignor';
        break;
      case 30:
        projection = 'flattenedGuarantor';
        break;
      case 22:
        projection = 'flattenedHaulier';
        break;
      case 12:
        projection = 'flattenedExporter';
        break;
      case 2:
        projection = 'flattenedExportConsignee';
        break;
      case 5:
        projection = 'flattenedOverseasAgent';
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + name + `&roleType=${role}&projection=${projection}`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }
}
