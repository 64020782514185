/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/table/table";
import * as i3 from "../../../../node_modules/primeng/components/table/table.ngfactory";
import * as i4 from "primeng/components/utils/objectutils";
import * as i5 from "primeng/components/common/shared";
import * as i6 from "../../../../node_modules/primeng/components/toolbar/toolbar.ngfactory";
import * as i7 from "primeng/components/toolbar/toolbar";
import * as i8 from "../exbond-file-search/exbond-file-search.component.ngfactory";
import * as i9 from "../exbond-file-search/exbond-file-search.component";
import * as i10 from "../../../subjects/exbondfile-behaviour-subject";
import * as i11 from "../services/ex-bond-file.service";
import * as i12 from "../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i13 from "primeng/components/fileupload/fileupload";
import * as i14 from "@angular/platform-browser";
import * as i15 from "./documents.component";
import * as i16 from "../../../subjects/clearingfile-behaviour-subject";
import * as i17 from "../services/ex-bond-bill-of-entry.service";
import * as i18 from "../../digi-common/services/notification.service";
import * as i19 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i20 from "../../digi-common/components/loading.service";
var styles_DocumentsComponent = [];
var RenderType_DocumentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentsComponent, data: {} });
export { RenderType_DocumentsComponent as RenderType_DocumentsComponent };
function View_DocumentsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "th", [["colspan", "4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" File Name "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "th", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Date "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "th", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Size "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "th", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Status "]))], null, null); }
function View_DocumentsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "tr", [], [[2, "ui-state-highlight", null]], [[null, "click"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouchEnd($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(2, 212992, null, 0, i2.SelectableRow, [i2.Table, i1.DomHandler, i2.TableService], { data: [0, "data"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p-tableCheckbox", [], null, null, null, i3.View_TableCheckbox_0, i3.RenderType_TableCheckbox)), i0.ɵdid(5, 245760, null, 0, i2.TableCheckbox, [i2.Table, i1.DomHandler, i2.TableService], { value: [0, "value"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " KB "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" File Uploaded Successfully "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).selected; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.fileName; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.date; _ck(_v, 9, 0, currVal_4); var currVal_5 = _v.context.$implicit.size; _ck(_v, 11, 0, currVal_5); }); }
function View_DocumentsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [["colspan", "10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No Files Found"]))], null, null); }
function View_DocumentsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "p-table", [["selectionMode", "multiple"]], null, [[null, "selectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChange" === en)) {
        var pd_0 = ((_co.selectedFiles = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Table_0, i3.RenderType_Table)), i0.ɵprd(512, null, i1.DomHandler, i1.DomHandler, []), i0.ɵprd(512, null, i4.ObjectUtils, i4.ObjectUtils, []), i0.ɵprd(512, null, i2.TableService, i2.TableService, []), i0.ɵdid(4, 1294336, null, 1, i2.Table, [i0.ElementRef, i1.DomHandler, i4.ObjectUtils, i0.NgZone, i2.TableService], { selectionMode: [0, "selectionMode"], value: [1, "value"], selection: [2, "selection"] }, { selectionChange: "selectionChange" }), i0.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_DocumentsComponent_2)), i0.ɵdid(7, 16384, [[2, 4]], 0, i5.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_DocumentsComponent_3)), i0.ɵdid(9, 16384, [[2, 4]], 0, i5.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_DocumentsComponent_4)), i0.ɵdid(11, 16384, [[2, 4]], 0, i5.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "multiple"; var currVal_1 = _co.listOfFiles; var currVal_2 = _co.selectedFiles; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "header"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "body"; _ck(_v, 9, 0, currVal_4); var currVal_5 = "emptymessage"; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_DocumentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "p-toolbar", [], null, null, null, i6.View_Toolbar_0, i6.RenderType_Toolbar)), i0.ɵdid(4, 49152, null, 0, i7.Toolbar, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "div", [["class", "ui-g-12 ui-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "digi-exbond-file-search", [], null, null, null, i8.View_ExbondFileSearchComponent_0, i8.RenderType_ExbondFileSearchComponent)), i0.ɵdid(7, 114688, null, 0, i9.ExbondFileSearchComponent, [i10.ExBondFileBehaviourSubject, i11.ExBondFileService], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 5, "p-fileUpload", [["multiple", "multiple"], ["name", "file"]], null, [[null, "onUpload"], [null, "onError"], [null, "onProgress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpload" === en)) {
        var pd_0 = (_co.afterFileUpload() !== false);
        ad = (pd_0 && ad);
    } if (("onError" === en)) {
        var pd_1 = (_co.onFileUploadError($event) !== false);
        ad = (pd_1 && ad);
    } if (("onProgress" === en)) {
        var pd_2 = (_co.beforeFileUpload() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i12.View_FileUpload_0, i12.RenderType_FileUpload)), i0.ɵprd(512, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(11, 5488640, null, 1, i13.FileUpload, [i0.ElementRef, i1.DomHandler, i14.DomSanitizer, i0.NgZone], { name: [0, "name"], url: [1, "url"], multiple: [2, "multiple"] }, { onUpload: "onUpload", onError: "onError", onProgress: "onProgress" }), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_DocumentsComponent_1)), i0.ɵdid(14, 16384, [[1, 4]], 0, i5.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_0 = "file"; var currVal_1 = _co.billOfEntry._links.files.href; var currVal_2 = "multiple"; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "content"; _ck(_v, 14, 0, currVal_3); }, null); }
export function View_DocumentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-upload-documents", [], null, null, null, View_DocumentsComponent_0, RenderType_DocumentsComponent)), i0.ɵdid(1, 770048, null, 0, i15.DocumentsComponent, [i16.ClearingFileBehaviourSubject, i10.ExBondFileBehaviourSubject, i17.ExBondBillOfEntryService, i18.NotificationService, i19.BillOfEntryBehaviourSubject, i20.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentsComponentNgFactory = i0.ɵccf("digi-upload-documents", i15.DocumentsComponent, View_DocumentsComponent_Host_0, { files: "files" }, {}, []);
export { DocumentsComponentNgFactory as DocumentsComponentNgFactory };
