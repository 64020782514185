import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../..//app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getCountry(code) {
    return this.http.get(`${this.config.apiEndpoint}api/countries/${code}?projection=countryWithTradeAgreement` );
  }

  findCountryStartsWith(code) {
    return this.http
      .get(`${this.config.apiEndpoint}api/countries/search/codeOrNameStartsWithNonPageable?code=${code}&projection=countryWithTradeAgreement`)
      .pipe(
        map(response => response['_embedded'].countries)
      );
  }
}
