import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ExportFile} from '../../models/exportFile';
import {Airline, PortDetail, RouteDetails} from '../../models/file';
import {TransportService} from '../../../digi-air/services/transport.service';

@Component({
  selector: 'digi-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss']
})
export class RoutingComponent implements OnInit, OnChanges {
  @Input() exportFile: ExportFile;
  cols: any[];
  routeDetails: RouteDetails;
  filteredAirlines: Airline[];

  constructor(
    private transportService: TransportService,
  ) { }

  ngOnInit() {
    this.cols = [
      {field: 'sequenceNo', header: 'Sequence No.'},
      {field: 'destinationCountry', header: 'Destination Country'},
      {field: 'destinationPort', header: 'Destination Airport'},
      {field: 'airlineCode', header: 'Airline Code'},
      {field: 'flightNo', header: 'Flight No.'},
      {field: 'flightDate', header: 'Flight Date'},
      {field: '', header: ''},
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exportFile && changes.exportFile.currentValue) {
      this.exportFile = changes.exportFile.currentValue;
      if ((this.exportFile.transportDocument.routes && this.exportFile.transportDocument.routes.length === 0)
        || !this.exportFile.transportDocument.routes) {
        this.createDefaultRoutes();

      } else {
        this.routeDetails = this.exportFile.transportDocument.routes[0];
      }
    }
  }

  private createDefaultRoutes() {
    this.routeDetails = new RouteDetails();
    this.routeDetails.destinationPort = this.exportFile.transportDocument.destinationPort;
    this.routeDetails.departurePort = this.exportFile.transportDocument.departurePort;
    this.routeDetails.airline = this.exportFile.transportDocument.airline;
    this.routeDetails.flightNo = this.exportFile.transportDocument.flight;
    this.routeDetails.flightDate = this.exportFile.transportDocument.departureDate;
    this.exportFile.transportDocument.routes = [this.routeDetails];

    if (this.exportFile.transportDocument.destinationPort.code !== this.exportFile.transportDocument.finalDestinationPort.code) {
      const finalDestRoute = new RouteDetails();
      finalDestRoute.destinationPort = this.exportFile.transportDocument.finalDestinationPort;
      finalDestRoute.departurePort = this.exportFile.transportDocument.destinationPort;
      finalDestRoute.airline = this.exportFile.transportDocument.airline;
      finalDestRoute.flightNo = this.exportFile.transportDocument.flight;
      finalDestRoute.flightDate = this.exportFile.transportDocument.departureDate;

      this.exportFile.transportDocument.routes.push(finalDestRoute);
    }
  }

  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectAirline(value: Airline) {
    this.exportFile.transportDocument.airline.prefix = value.airlinePrefix;
  }

  createNewRoute() {
    this.routeDetails = new RouteDetails();
    this.routeDetails.departurePort = new PortDetail();
    this.routeDetails.destinationPort = new PortDetail();
    this.routeDetails.airline = new Airline();
    if (this.exportFile && this.exportFile.transportDocument) {
      if (this.exportFile.transportDocument.routes) {
        this.exportFile.transportDocument.routes.push(this.routeDetails);
      } else {
        this.exportFile.transportDocument.routes = [this.routeDetails];
      }
    }
  }

  deleteRouteDetail(index) {
    this.exportFile.transportDocument.routes.splice(index, 1);
    this.exportFile.transportDocument.routes = [...this.exportFile.transportDocument.routes];
  }
}
