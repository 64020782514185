import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { TransportDocument } from '../../../digi-common/models/file';

@Component({
  selector: 'digi-digi-hawb-shipment-detail-section',
  templateUrl: './digi-hawb-shipment-detail-section.component.html',
  styleUrls: ['./digi-hawb-shipment-detail-section.component.scss']
})
export class DigiHawbShipmentDetailSectionComponent implements OnInit, OnDestroy {
  selectedTransportDocument: TransportDocument;
  transportDocumentSubscription: Subscription;
  display: boolean;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject) {
  }

  ngOnInit() {
    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
          if (td) {
            this.selectedTransportDocument = td;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.transportDocumentSubscription.unsubscribe();
  }

  getCargoCarrierCode(value) {
    this.selectedTransportDocument.localForwarder.cargoCarrierCode = value;
  }

  onDialogClose(event) {
    this.display = event;
  }

  openSundryChargeModel() {
    this.display = true;
  }
}
