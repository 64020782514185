<div class="ui-g form-group">
  <ng-container>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="ui-float-label">
          <p-autoComplete id="address" [suggestions]="filteredAddress" field="line1"
                          [(ngModel)]="selectedAddress"
                          (onSelect)="selectedAddressFromDropDown($event)"
                          (completeMethod)=searchAddress($event)
                          [dropdown]="true">
          </p-autoComplete>
          <label for="address">Select Address</label>
          </span>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div class="ui-g-12 ui-g-nopad pt-2">
      <div class="ui-inputgroup">
        <input [ngModel]="selectedAddress?.line2" type="text" pInputText/>
      </div>
    </div>
    <div class="ui-g-12 ui-g-nopad pt-2">
      <div class="ui-inputgroup">
        <input [ngModel]="selectedAddress?.line3" type="text" pInputText/>
      </div>
    </div>
    <div class="ui-g-12 ui-g-nopad pt-2">
      <div class="ui-inputgroup">
        <input [ngModel]="selectedAddress?.line4" type="text" pInputText/>
      </div>
    </div>
    <div class="ui-g-12 ui-g-nopad pt-2">
      <div class="ui-inputgroup">
        <input [ngModel]="selectedAddress?.zipCode" type="text" pInputText/>
      </div>
    </div>
  </ng-container>
</div>
