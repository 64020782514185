<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text"
                 [(ngModel)]="transportDocument.forwardFile.forwardingFileNumber"
                 pInputText>
          <label>Forwarding File No</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text"
                 [(ngModel)]="transportDocument.forwardFile.externalUniqueId"
                 pInputText>
          <label>External Unique ID</label>
        </span>
      </div>
    </div>
  </div>
</div>
