import {SchedulePart} from './file';

export class TariffCodeReference {
  additionalInvoice?: boolean;
  additionalType?: string;
  changeDesc?: boolean;
  changeEffDate?: string;
  changeExplode?: boolean;
  changeImplode?: boolean;
  changeRate?: boolean;
  changeRelated?: string;
  changeTariffDelete?: boolean;
  changeUOM?: boolean;
  changenewTariff?: boolean;
  clientComment?: string;
  coreFreightComment?: string;
  dependantCheckDigit?: string;
  dependantCode?: string;
  taxTypeCode?: string;
  externalHooks: number;
  effectiveTradeAgreementFormulae: {
    STANDARD: {
      chargeString?: string;
      description?: string;
      effectiveDate?: string;
      free?: boolean;
      hasDcc?: boolean;
      hasRcc?: boolean;
      noOfParameters?: number;
      number?: number;
      statisticalUOM?: string;
      rebate?: boolean;
      rebateExcludes12B?: boolean;
      tradeGroupCode?: string;
      prodatFormulaNo?: string;
      computations: [{
        number?: number;
        formulaNo?: number;
        computation?: string;
        consolidateAllowed?: boolean;
        computationBase?: string;

      }];
      parameters: [{
        dataType?: string;
        formulaNo?: number;
        incInRebate?: string;
        number?: number;
        value?: string;
      }];
    };
  };
  effectiveTradeAgreementParameters: [{
    effectiveDate?: string;
    number?: number;
    tariffId?: number;
    tradeGroupCode?: string;
  }];
  fullDescription?: string;
  inQA?: boolean;
  industry?: string;
  lastUpdated?: string;
  linkSchedPart?: string;
  mandatoryLink?: boolean;
  qa1Date?: string;
  qa1User?: string;
  qa2Date?: string;
  qa2User?: string;
  replacementID?: number;
  schedule?: string;
  tariff?: boolean;
  tariffCheckDigit?: number;
  code?: string;
  tariffEffectiveFrom?: string;
  tariffEffectiveUntil?: string;
  tariffID?: number;
  tariffLevel?: number;
  tariffSDesc?: string;
  updateDate?: string;
  updateUser?: string;
  uuid?: number;
  valuationExempt?: boolean;
  schedulePart: SchedulePart;
  descriptions?: TariffDescriptionHistory[];
  formulae?: TariffFormula[];
  tariffParameters?: TariffParameter[];

}

export class TariffDescriptionHistory {
  id: TariffDescriptionHistoryPK;
  descrEffectiveUntil?: string;
  tariffLDesc?: string;
}

export class TariffDescriptionHistoryPK {
  tariffId?: number;
  descrEffectiveFrom?: string;
}

export class TariffParameter {
  id?: TariffParameterPK;
  value1?: string;
  value2?: string;
}

export class TariffParameterPK {
  tariffId?: number;
  tradeGroupType?: string;
  tradeGroupCode?: string;
  effectiveDate?: string;
  number?: number;
}

export class TariffFormula {
  id?: TariffFormulaPK;
  referenceCode?: string;
  uomCode?: string;
  filledFormulaString1?: string;
  filledFormulastring2?: string;
  uuid?: number;
  externalHooks?: number;
  formulaEffUntilDate?: string;
  tradeReference?: string;
  formula: Formula;
}

export class TariffFormulaPK {
  tariffId?: number;
  tradeGroupType?: string;
  tradeGroupCode?: string;
  formulaEffDate?: string;
  label?: string;
}

export class Formula {
  number?: number;
  description?: string;
  parameters: FormulaParameter[];
  string?: string;
  noOfParams?: number;
  chargeString?: string;
  free?: boolean;
  rebate?: boolean;
  rebateExcludes12B?: boolean;
  hasDcc?: boolean;
  hasRcc?: boolean;
  prodatFormulaNo?: string;
}

export class TaxType {
  code?: string;
  description?: string;
  sarsTaxCode?: string;
  label?: string;
}

export class FormulaParameter {
  id?: FormulaParamPK;
  value?: string;
  dataType?: string;
  incInRebate?: string;
}

export class FormulaParamPK {
  formulaNo?: number;
  number?: number;
}

export class Uom {
  uomCode?: string;
  uomDesc?: string;
  uomEffectiveUntil?: string;
  uuid?: number;
  externalHooks?: number;
  customsUomCode?: string;
  appData?: boolean;
  prodatUom?: string;
  uomConventions?: UOMConversion[];
}

export class UOMConversion {
  convFactor?: number;
  id?: UOMConversionPK;
}

export class UOMConversionPK {
  uomCode?: string;
  toUomCode?: string;
}

export class TariffRef {
  referenceCode?: string;
  gazetteNo?: string;
  suppNo?: number;
  gazetteDate?: string;
  userCode?: string;
  govnNoticeNo?: string;
  refApproved?: boolean;
  uuid?: number;
  externalHooks?: number;
  gazette?: number;
}

