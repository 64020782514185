import { SupplierInvoiceLine, UserInterfaceDefaults } from '../../models/file';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'digi-invoice-lines-defaults-section',
  templateUrl: './invoice-lines-defaults-section.component.html',
  styleUrls: ['./invoice-lines-defaults-section.component.css']
})
export class InvoiceLinesDefaultsSectionComponent implements OnInit {
  @Input() userInterfaceDefaults: UserInterfaceDefaults;
  @Input() invoiceLine: SupplierInvoiceLine;


  constructor() {
  }

  ngOnInit() {
  }


  tariffModeSelected(value) {
    this.invoiceLine.userInterfaceCheckbox.tariffMode = value;
    if (value) {
      // Update both check box values as this is like a master switch, and the values are not mutually exclusive
      this.userInterfaceDefaults.quickPart = false;
      this.invoiceLine.userInterfaceCheckbox.quickPart = false;
    }
  }

  quickPartSelected(value) {
    this.invoiceLine.userInterfaceCheckbox.quickPart = value;
    if (value) {
      // Update both check box values as this is like a master switch, and the values are not mutually exclusive
      this.userInterfaceDefaults.tariffMode = false;
      this.invoiceLine.userInterfaceCheckbox.tariffMode = false;
    }
  }
}
