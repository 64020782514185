import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var UniqueConsignmentReferenceService = /** @class */ (function () {
    function UniqueConsignmentReferenceService(http, config) {
        this.http = http;
        this.config = config;
    }
    UniqueConsignmentReferenceService.prototype.saveUCR = function (ucr) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (ucr.id) {
            return this.http.put(this.config.filesApiEndpoint + ("api/uniqueConsignmentReferences/" + ucr.id), JSON.stringify(ucr), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/uniqueConsignmentReferences', JSON.stringify(ucr), { headers: headers });
        }
    };
    UniqueConsignmentReferenceService.prototype.findUCRWithNumberAndCodeAndRoleType = function (number, code, roleType) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/uniqueConsignmentReferences/search/findByNumberAndCodeAndRole?number=" + number + "&businessEntityCode=" + code + "&businessEntityRole=" + roleType)
            .pipe(map(function (response) { return response['_embedded'].uniqueConsignmentReferences; }));
    };
    UniqueConsignmentReferenceService.prototype.findPaymentTerm = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/ucrPayementTerms/search/codeOrDescriptionStartsWith?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].ucrPayementTerms; }));
    };
    UniqueConsignmentReferenceService.ngInjectableDef = i0.defineInjectable({ factory: function UniqueConsignmentReferenceService_Factory() { return new UniqueConsignmentReferenceService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: UniqueConsignmentReferenceService, providedIn: "root" });
    return UniqueConsignmentReferenceService;
}());
export { UniqueConsignmentReferenceService };
