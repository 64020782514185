<div class="ui-g">
    <div class="ui-g-12">
        <p-table [value]="businessEntity?.addresses" [(selection)]="selectedAddress" selectionMode="single" (onRowUnselect)="onRoleUnSelected($event)" [resizableColumns]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" colspan="4">
                        Street
                    </th>
                    <th scope="col" colspan="4">
                        State/Province
                    </th>
                    <th scope="col" colspan="4">
                        Zip/Postal Code
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td scope="row" colspan="4">
                        <label>{{rowData?.line1}}</label>
                    </td>
                    <td colspan="4">
                        <label>{{rowData?.line4}}</label>
                    </td>
                    <td colspan="4">
                        <label>{{rowData?.zipCode}}</label>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="12">
                        <label>No addresses found</label>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="ui-g-4">
        <button type="button" pButton icon="fa fa-file" (click)="addNewAddress()" label="New Address"></button>
    </div>
</div>

<div class="ui-g ui-fluid" *ngIf="selectedAddress">
    <div class="ui-g-12 ui-md-9">
        <p-panel>
            <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"
                [(ngModel)]="selectedAddress.line1" pInputText maxlength="35">
                <label>Street</label>
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"
                [(ngModel)]="selectedAddress.line2" pInputText maxlength="35">
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"
                [(ngModel)]="selectedAddress.line3" pInputText maxlength="35">
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"
                [(ngModel)]="selectedAddress.line4" pInputText maxlength="35">
                <label>State/Province</label>
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.zipCode"
                 pInputText>
                <label>Zip/Postal Code</label>
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.telephone" pInputText>
                <label>Telephone</label>
              </span>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedAddress.description" pInputText>
                <label>Description</label>
              </span>
                    </div>
                </div>
            </div>
        </p-panel>
    </div>
</div>
