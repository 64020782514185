import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class DebtorBehaviourSubject {
  private debtorsSubject = new Subject<Debtor[]>();

  constructor() {
  }

  addDebtors(debtors: Debtor[]) {
    this.debtorsSubject.next(debtors);
  }

  getDebtors(): Observable<Debtor[]> {
    return this.debtorsSubject.asObservable();
  }
}
