<div class="ui-g-12 ui-md-12">
   <span class="md-inputfield">
     <span class="ui-float-label">
        <p-autoComplete id="collectPrepaid" [(ngModel)]="freight.collectPrepaid"
                        field="label" [dropdown]="true"
                        [suggestions]="filteredCollectPrepaids"
                        (completeMethod)="search($event)"
                        [autoHighlight]="true" (onSelect)="select($event)" minLength="1"
                        [forceSelection]="true"
                        required=true>
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}: {{option.description}}</div>
          </ng-template>
        </p-autoComplete>
        <label for="collectPrepaid">Collect/Prepaid</label>
     </span>
     <div *ngIf="!freight?.collectPrepaid?.code&&required"
          class="ui-message ui-messages-error ui-corner-all">This field is required</div>
   </span>
</div>
