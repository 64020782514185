import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Country, Currency} from '../../../digi-business-entity/models/business-entity';
import {LocalAgent, UCRPaymentTerm, UniqueConsignmentReference} from '../../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {UniqueConsignmentReferenceService} from '../../services/unique-consignment-reference.service';
import {NotificationService} from '../../services/notification.service';
import {Exporter} from '../../models/exportFile';
import {FileType} from '../../models/enumerations';
import * as moment from 'moment';

@Component({
  selector: 'digi-unique-consignment-reference',
  templateUrl: './unique-consignment-reference.component.html',
  styleUrls: ['./unique-consignment-reference.component.scss']
})
export class UniqueConsignmentReferenceComponent implements OnInit, OnChanges {
  @Input('display') display;
  @Input() fileType: FileType;
  @Input('businessEntity') businessEntity: LocalAgent | Exporter;
  @Output() created = new EventEmitter();
  entityTypes: any[];
  selectedEntityType: any;
  referenceTypes: any[];
  selectedReferenceType: any;
  scopeTypes: any[];
  selectedScopeType: any;
  uniqueConsignmentReference = new UniqueConsignmentReference();
  filteredPaymentTerms: UCRPaymentTerm[];
  localAgent: LocalAgent;
  @Input() ucrNumber: string;
  @Input() roleType: string;
  @Input() customsCode: string;
  @Input() invoiceNo: string;
  @Input() invoiceValue: number;
  @Input() currency: Currency;

  constructor(private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private uniqueConsignmentReferenceService: UniqueConsignmentReferenceService,
              private messageService: NotificationService) {
    this.entityTypes = [
      {entityType: 'Customs Code', code: 'C', label: 'C = Customs Code'},
      {entityType: 'Tax Code [TIN]', code: 'T', label: 'T = Tax Code [TIN]'},
      {entityType: 'Company Registration number', code: 'I', label: 'I = Company Registration number'},
      {entityType: 'ID number/Passport number', code: 'P', label: 'P = ID number/Passport number'}
    ];

    this.referenceTypes = [
      {referenceType: 'Invoice', code: 'INV', label: 'INV = Invoice'},
      {referenceType: 'Contract', code: 'CON', label: 'CON = Contract'},
      {referenceType: 'Customs Generated', code: 'CUS', label: 'CUS = Customs Generated'},
      {referenceType: 'Declarant Generated', code: 'DCl', label: 'DCl = Declarant Generated'},
      {referenceType: 'Delivery', code: 'DEL', label: 'DEL = Delivery'},
      {referenceType: 'Informal', code: 'INF', label: 'INF = Informal'},
      {referenceType: 'Other', code: 'OTH', label: 'OTH = Other'},
      {referenceType: 'Purchase Order', code: 'PON', label: 'PON = Purchase Order'}
    ];

    this.scopeTypes = [
      {scopeType: 'Single', code: 'S', label: 'S = Single'},
      {scopeType: 'Multiple', code: 'M', label: 'M = Multiple'}
    ];
  }

  ngOnInit() {
    if (!this.ucrNumber) {
      this.createNewUcr();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ucrNumber && changes.ucrNumber.currentValue) {
      this.uniqueConsignmentReferenceService.findUCRWithNumberAndCodeAndRoleType(this.ucrNumber, this.businessEntity.code, this.roleType).subscribe(
        ucrs => {
          if (ucrs && ucrs.length) {
            this.uniqueConsignmentReference = ucrs[0];
            this.selectedEntityType =  this.entityTypes.find(el => el.code === this.uniqueConsignmentReference.entityType);
            this.selectedReferenceType = this.referenceTypes.find(el => el.code === this.uniqueConsignmentReference.referenceType);
            this.selectedScopeType = this.scopeTypes.find(el => el.code === this.uniqueConsignmentReference.scope);
          } else {
            this.createNewUcr();
          }
        }
      );
    }

    if (changes.invoiceNo && changes.invoiceNo.currentValue && this.uniqueConsignmentReference) {
      this.uniqueConsignmentReference.referenceNumber = changes.invoiceNo.currentValue;
    }

    if (changes.invoiceValue && changes.invoiceValue.currentValue && this.uniqueConsignmentReference) {
      this.uniqueConsignmentReference.transactionValue = changes.invoiceValue.currentValue;
    }

    if (changes.currency && changes.currency.currentValue && this.uniqueConsignmentReference) {
      this.uniqueConsignmentReference.transactionCurrency = new Currency();
      this.uniqueConsignmentReference.transactionCurrency.code = changes.currency.currentValue.code;
      this.uniqueConsignmentReference.transactionCurrency.name = changes.currency.currentValue.name;
      this.uniqueConsignmentReference.transactionCurrency.label = changes.currency.currentValue.label;
    }
  }

  selectCountry(value: Country) {
    this.uniqueConsignmentReference.country = value;
  }

  onClose() {
    this.created.emit(this.uniqueConsignmentReference);
  }

  selectTransactionCurrency(value: Currency) {
    this.uniqueConsignmentReference.transactionCurrency = value;
  }

  selectAdvancedPaymentCurrency(value: Currency) {
    this.uniqueConsignmentReference.advancedPaymentCurrency = value;
  }

  searchPaymentTerm(event) {
    this.uniqueConsignmentReferenceService.findPaymentTerm(event.query).subscribe(data => {
      this.filteredPaymentTerms = data;
    });
  }

  onSave() {
    // After clicking new , a new Unique Consignment Reference Object is created
    // here we are setting the code, roleType and customs code after new button is clicked
    if (!this.uniqueConsignmentReference.businessEntityCode && !this.uniqueConsignmentReference.businessEntityRole
      && !this.uniqueConsignmentReference.customsCode) {
      this.uniqueConsignmentReference.customsCode = this.customsCode;
      this.uniqueConsignmentReference.businessEntityRole = this.roleType;
      this.uniqueConsignmentReference.businessEntityCode = this.businessEntity.code;
    }

    if (!this.uniqueConsignmentReference.date) {
      this.addFailure(['Please add Date']);
    } else if (!this.selectedEntityType) {
      this.addFailure(['Please add Entity Type']);
    } else if (!this.uniqueConsignmentReference.referenceNumber) {
      this.addFailure(['Please add Reference Number']);
    } else if (!this.selectedReferenceType) {
      this.addFailure(['Please add Reference Type']);
    } else if (!this.uniqueConsignmentReference.country) {
      this.addFailure(['Please add Country']);
    } else if (!this.selectedScopeType) {
      this.addFailure(['Please add Scope']);
    } else if (!this.uniqueConsignmentReference.customsCode) {
      this.addFailure(['Please make sure that your Local Agent has a customs code']);
    } else {
      this.uniqueConsignmentReference.scope = this.selectedScopeType.code;
      this.uniqueConsignmentReference.referenceType = this.selectedReferenceType.code;
      this.uniqueConsignmentReference.entityType = this.selectedEntityType.code;

      this.uniqueConsignmentReferenceService.saveUCR(this.uniqueConsignmentReference)
        .subscribe(data => {
            this.uniqueConsignmentReference = data;
            this.ucrConfirmation();
          },
          error => {
          }
        );
    }
  }

  createNewUcr() {
    this.uniqueConsignmentReference = new UniqueConsignmentReference();
    this.uniqueConsignmentReference.paymentTerm = new UCRPaymentTerm();
    this.uniqueConsignmentReference.date = moment().format('DD/MM/YYYY');

    if (this.businessEntity) {
      this.uniqueConsignmentReference.customsCode = this.customsCode;
      this.uniqueConsignmentReference.businessEntityCode = this.businessEntity.code;
      this.uniqueConsignmentReference.businessEntityRole = this.roleType;
    }

    this.selectedEntityType = {entityType: 'Customs Code', code: 'C', label: 'C = Customs Code'};
    this.selectedReferenceType = {referenceType: 'Invoice', code: 'INV', label: 'INV = Invoice'};
    this.uniqueConsignmentReference.referenceNumber = this.invoiceNo;
    this.selectedScopeType = {scopeType: 'Single', code: 'S', label: 'S = Single'};
    this.uniqueConsignmentReference.transactionValue = this.invoiceValue;
    this.uniqueConsignmentReference.paymentTerm = new UCRPaymentTerm();
    this.uniqueConsignmentReference.paymentTerm.code = '030';
    this.uniqueConsignmentReference.paymentTerm.description = '30 Days';
    this.uniqueConsignmentReference.paymentTerm.label = '030: 30 Days';


    this.uniqueConsignmentReference.country = new Country();
    this.uniqueConsignmentReference.country.code = 'ZA ';
    this.uniqueConsignmentReference.country.name = 'South Africa';
    this.uniqueConsignmentReference.country.label = 'ZA : SOUTH AFRICA';

    this.uniqueConsignmentReference.transactionCurrency = new Currency();
    if (this.currency) {
      this.uniqueConsignmentReference.transactionCurrency.code = this.currency.code;
      this.uniqueConsignmentReference.transactionCurrency.name = this.currency.name;
      this.uniqueConsignmentReference.transactionCurrency.label = this.currency.label;
    }
  }

  ucrConfirmation() {
    this.messageService.successNotify('Unique Consignment Reference Saved');
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }
}
