import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GoodsDescription, Package, VoyageMasterFile} from '../../models/voyageFile';
import {PackType} from '../../models/roadManifest';
import {PackTypeService} from '../../services/pack-type.service';
import {ContainerType, TariffCode} from '../../models/file';
import {TariffService} from '../../services/tariff.service';
import {ContainerTypeService} from '../../../digi-ocean/services/container-type.service';

@Component({
  selector: 'digi-ships-agent-packages',
  templateUrl: './ships-agent-packages.component.html',
  styleUrls: ['./ships-agent-packages.component.scss']
})
export class ShipsAgentPackagesComponent implements OnInit, OnChanges {

  @Input() voyageMasterFile = new VoyageMasterFile();
  @Input() newPackage: any;
  package = new Package();
  filteredPackTypes: PackType[];
  filteredTariffCodes: TariffCode[];
  filteredContainerTypes: ContainerType[];

  constructor(
    private packTypeService: PackTypeService,
    private tariffService: TariffService,
    private containerTypeService: ContainerTypeService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.voyageMasterFile && changes.voyageMasterFile.currentValue) {
      this.voyageMasterFile = changes.voyageMasterFile.currentValue;
      if (this.voyageMasterFile.voyageMasterFilePackages && this.voyageMasterFile.voyageMasterFilePackages.length) {
        this.package = this.voyageMasterFile.voyageMasterFilePackages[0];
      } else {
        this.voyageMasterFile.voyageMasterFilePackages = [];
        this.createNewPackage();
      }
    }
    if (changes.newPackage && changes.newPackage.currentValue) {
      this.createNewPackage();
    }
  }

  searchPackTypes(event) {
    this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      data => this.filteredPackTypes = data
    );
  }

  selectPackType(value) {
    this.package.packageType = value;
  }

  searchTariffCode(event) {
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value) {
    this.package.tariffCode = value;
  }

  searchContainerTypes(event) {
    this.containerTypeService
      .findAllContainerTypeStartsWith(event.query)
      .subscribe(data => {
        this.filteredContainerTypes = data;
      });
  }

  selectContainerType(value) {
    this.package.containerType = value;
  }

  private createNewPackage() {
    this.package = new Package();
    this.package.goodsDescription = new GoodsDescription();
    this.package.tariffCode = new TariffCode();
    this.package.packageType = new PackType();
    this.package.containerType = new ContainerType();
    this.voyageMasterFile.voyageMasterFilePackages.push(this.package);
  }
}
