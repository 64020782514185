import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multilineAddress'
})
export class MultilineAddressPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.replace(/,/g, '<br>');
    } else {
      return '';
    }
  }

}
