<div class="ui-g-12 pt-0">
  <digi-exports-file-header-section step="registration" [canAdd]="true" (add)="createNewRoute()">
  </digi-exports-file-header-section>
</div>
<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel header="Original Departure Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="exportFile.transportDocument.departurePort.label" disabled>
                                  <label>Airport</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="exportFile.transportDocument.departureDate" disabled>
                                  <label>Date</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Final Destination Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="exportFile.transportDocument.finalDestinationPort.label" disabled>
                                  <label>Airport</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="exportFile.transportDocument.arrivalDate" disabled>
                                  <label>Date</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12">
      <p-table [columns]="cols" [value]="exportFile.transportDocument.routes" selectionMode="single"
               [(selection)]="routeDetails"
               compareSelectionBy="equals"
               [paginator]="true" [rows]="5"
               [resizableColumns]="true">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pResizableColumn>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"   let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td  class="ui-resizable-column">
              <label>{{rowIndex + 1}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.destinationPort?.countryCode}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.destinationPort?.code}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.airline?.code}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.flightNo}}</label>
            </td>
            <td  class="ui-resizable-column">
              <label>{{rowData.flightDate}}</label>
            </td>
            <td>
              <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                      (click)="deleteRouteDetail(rowIndex)" pTooltip="Delete"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-4">
      <p-panel header="Flight Details">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="routeAirline"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="routeDetails.airline"
                                                  field="label"
                                                  [suggestions]="filteredAirlines"
                                                  (completeMethod)="searchAirline($event)"
                                                  [autoHighlight]="true" minLength="2"
                                                  (onSelect)="selectAirline($event)"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option.code}}: {{option.name}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="routeAirline">Airline</label>
                                </span>
                                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="routeDetails.flightNo">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                            </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy"
                                           [(ngModel)]="routeDetails.flightDate"></p-inputMask>
                              <label>Date</label>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Depature">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <digi-port-details portLabel='Airport'
                                     [transportDetails]="routeDetails.departurePort"
                                     [file]="exportFile"
                                     [transportMethodCode]="'AIR'"
                                     [isRequired]="false"
                  ></digi-port-details>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="routeDetails.departurePort.countryName" disabled>
                                  <label>Country</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="Destination">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <digi-port-details portLabel='Airport'
                                     [transportDetails]="routeDetails.destinationPort"
                                     [file]="exportFile"
                                     [transportMethodCode]="'AIR'"
                                     [isRequired]="false"
                  ></digi-port-details>
                </div>
                <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [ngModel]="routeDetails.destinationPort.countryName" disabled>
                                  <label>Country</label>
                                </span>
                              </div>
                            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
