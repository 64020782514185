import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  public tenantId: string;
  public oauthEndpoint: string;
  public oauthRealm: string;
  public readonly oauthClientId: string = 'freight-angular-ui';

  public apiEndpoint: string;
  public filesApiEndpoint: string;
  public production: boolean;
  public branchCode = 4;
  public zipRequests: boolean;
  public enableTracking: boolean;

  constructor() {
    this.init();
   }

   init(): void {
    this.tenantId = this.getTenantForHostname(location.hostname);
    console.log('initialising tenant with ID: ' + this.tenantId);
    if (this.tenantId === 'localhost') {
      this.initLocalHost();
      this.enableTracking = true;
    } else {
      this.initWithTenantIdPrefix();
    }

    console.log('oauthEndpoint=%s, oauthRealm=%s, apiEndpoint=%s, filesApiEndpoint=%s, production=%s',
      this.oauthEndpoint, this.oauthRealm, this.apiEndpoint, this.filesApiEndpoint, this.production);
   }

   initLocalHost(): void {
    //  this.initWithTenantIdPrefix(); // TODO: JUST FOR TESTING!!!
     this.oauthEndpoint = 'http://localhost:8090/auth';
     this.oauthRealm = 'freight-services';
     this.apiEndpoint = 'http://localhost:8080/';
     this.filesApiEndpoint = 'http://localhost:8081/';
     this.production = false;

     // TEST tenant functions
     this.getTenantForHostname('beta.mylocal.digi-freight.co.za');
     this.getTenantForHostname('mylocal.digi-freight.co.za');
   }

   /*
      apiEndpoint: 'http://jayden.digi-freight.co.za/entities/',
      filesApiEndpoint: 'http://jayden.digi-freight.co.za/files/',
      endpoint: 'http://jayden.digi-freight.co.za/auth',
      realm: 'jayden-freight-services',
      clientId: 'freight-angular-ui',
   */
   initWithTenantIdPrefix(): void {
    this.oauthEndpoint = `http://${this.tenantId}.digi-freight.co.za/auth`;
    this.oauthRealm = `${this.tenantId}-freight-services`;
    this.apiEndpoint = `http://${this.tenantId}.digi-freight.co.za/entities/`;
    this.filesApiEndpoint = `http://${this.tenantId}.digi-freight.co.za/files/`;

    if (this.tenantId.startsWith('beta')) {
      this.production = false;
      this.oauthRealm = this.oauthRealm.substring(5); // trim beta off from realm name
      if (this.tenantId === 'beta.evashin') {
        this.branchCode = 1;
      }
    } else {
      this.production = true;
    }

     if (this.tenantId === 'beta.evashin' || this.tenantId === 'intercargo') {
       this.zipRequests = true;
     }

     if (this.tenantId === 'beta.evashin' || this.tenantId === 'rtclearing' || this.tenantId === 'beta.sarles') {
        this.enableTracking = true;
     }
   }

  private getTenantForHostname(hostname: string): string {
    if (hostname === 'localhost') {
      return hostname;
    }
    const baseUrl = '.digi-freight.co.za';
    const tenantId = hostname.substring(0, hostname.indexOf(baseUrl));
    console.log('tenantId=%s for hostname=%s', tenantId, hostname);
    return tenantId;
    // return this.getTenantForHost(hostname.split(".")[0]);
  }

  private getTenantForString(s: string) {
    /*for (const e in Tenant) {
      if (e.toLowerCase() === s.toLowerCase()) {
        return Tenant[e] as Tenant;
      }
    }*/
    return s;
  }

  private getTenantForHost(host: string): string {
    return this.getTenantForString(host);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append('X-Tenant-ID', this.tenantId);
  }
}

/*export enum Tenant {
  CLIENT1 = "client1",
  CLIENT2 = "client2"
}*/
