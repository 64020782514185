import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, EMPTY, Subscription, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BillOfEntryBehaviourSubject} from '../../../../../subjects/billOfEntry-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {BillOfEntry} from '../../../models/billOfEntries';
import {ClearingFile} from '../../../models/clearingFile';
import {BillOfEntryService} from '../../../services/bill-of-entry.service';
import {ClearingFileService} from '../../../services/clearing-file.service';


@Component({
  selector: 'digi-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss']
})
export class GenerateComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedBillOfEntry: BillOfEntry;
  subscription: Subscription;
  files: any[];

  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileService: ClearingFileService,
              private billOfEntryService: BillOfEntryService
  ) {
  }

  ngOnInit() {
    const clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
    const billOfEntryObservable = this.billOfEntryBehaviourSubject.getBillOfEntry();
    this.subscription = combineLatest(clearingFileObservable, billOfEntryObservable).subscribe(([clearingFile, billOfEntry]) => {
      this.clearingFile = clearingFile;
      this.selectedBillOfEntry = billOfEntry;
    });
  }

  loadUploadedFiles(event, tabview) {
    if (event.index === 2) {
      if (this.clearingFile && this.selectedBillOfEntry) {
        this.billOfEntryService.loadUploadedFile(this.selectedBillOfEntry)
          .pipe(
            catchError(err => {
              console.log(err.status);
              if (err.status !== 404) {
                return throwError(err);
              }
              return EMPTY;
            })
          )
          .subscribe(uploadedFiles => {
            if (uploadedFiles && uploadedFiles['_embedded']) {
              this.files = [];
              this.files.push(...uploadedFiles._embedded.supportingDocuments);
              this.files = [...this.files];
            }
          });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
