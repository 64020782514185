<div class="ui-g-8 ui-md-6 ui-md-offset-6">
  <p-panel header="Total Weight & Quantity">
    <div class="ui-g ui-fluid">
      <div class="ui-g-3 pt-2">
      </div>
      <div class="ui-g-2 pt-2">
        <span class="ui-inputgroup-addon">Value</span>
      </div>
      <div class="ui-g-2 pt-2">
        <span class="ui-inputgroup-addon">Packs</span>
      </div>
      <div class="ui-g-2 pt-2">
        <span class="ui-inputgroup-addon">Volume</span>
      </div>
      <div class="ui-g-3 pt-2">
        <span class="ui-inputgroup-addon">Gross Weight (KG)</span>
      </div>
      <div class="ui-g-3 pt-2">
        <span class="ui-inputgroup-addon">Total</span>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile?.measures?.noOfContainers}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile?.measures?.numberOfPackages}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile?.measures?.volume}}" readonly>
      </div>
      <div class="ui-g-3 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile?.measures?.grossWeight}}" readonly>
      </div>
      <div class="ui-g-3 pt-2">
        <span class="ui-inputgroup-addon">Captured</span>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.capturedValue}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.capturedNoOfPackages}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.capturedVolume}}" readonly>
      </div>
      <div class="ui-g-3 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.capturedGrossWeight}}" readonly>
      </div>
      <div class="ui-g-3 pt-2">
        <span class="ui-inputgroup-addon">Balance</span>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.remainingValue}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.remainingNoOfPackages}}" readonly>
      </div>
      <div class="ui-g-2 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.remainingVolume}}" readonly>
      </div>
      <div class="ui-g-3 pt-2">
        <input type="text" pInputText value="{{voyageMasterFile.containerRunningTotals?.remainingGrossWeight}}" readonly>
      </div>
    </div>
  </p-panel>
</div>
<div class="ui-g-12">
  <p-table [columns]="[{}]" [value]="voyageMasterFile.containers" [(selection)]="container" dataKey="containerNo"
           [paginator]="true" [rows]="4"
           selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th>Container No.</th>
        <th>Type</th>
        <th>Gross Weight</th>
        <th>Terminal</th>
        <th>Depot</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-container>
      <tr [pSelectableRow]="container">
        <td class="ui-resizable-column">
          <label>{{container.containerNo}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{container.containerType?.code}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{container.nettWeight}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{container.terminal?.name}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{container.depot?.name}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel header="General Container Details">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="container.containerType" field="description"
                                  [suggestions]="filteredContainerTypes"
                                  (completeMethod)="searchContainerTypes($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true"
                                  required=true (onSelect)="selectContainerType($event)"
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}: {{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                   <label>Container Type</label>
            </span>
              </div>
            </span>
            </div>
            <div class="ui-g-12 ui-md-12" *ngIf="container.containerType && container.containerType.description === 'Bulk'">
              <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="cargoType" [(ngModel)]="container.cargoType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredCargoTypes"
                                    (completeMethod)="searchCargoTypes($event)"
                                    (onSelect)="selectCargoType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="cargoType">Cargo Type</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-8">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                    <p-autoComplete id="containerSpecification" [(ngModel)]="container.containerSpecification"
                                    field="label"
                                    [suggestions]="filteredContainerSpecifications"
                                    (completeMethod)="searchContainerSpecs($event)"
                                    [autoHighlight]="true" minLength="1"
                                    [dropdown]="true"
                                    [forceSelection]="true"
                                    required=true (onSelect)="selectContainerSpec($event)"
                    >
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}}: {{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                   <label for="containerSpecification">Container Size</label>
                </span>
              </div>
          </span>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
            <span class="md-inputfield">
            <input type="text"
                   placeholder=""
                   pInputText
                   [ngModel]="container?.containerSpecification?.containerSize?.size"
                   readonly>
              <label>Size(ft)</label>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="container.containerNo">
                      <label>Container No.</label>
                    </span>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="container.serialNo">
                  <label>Seal No.</label>
                </span>
            </div>
            </div>
          </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="General Weights & Measures">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="container.noOfPackages">
                  <label>No of Packages</label>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="container.nettWeight"
                         pInputText>
                  <label>Gross Weight</label>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="container.cbm"
                         pInputText>
                  <label>CBM</label>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="container.freightTons"
                         pInputText>
                  <label>Freight tons</label>
                </span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="General Terminal & Depot Section">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete [(ngModel)]="container.terminal" field="label"
                                    [suggestions]="filteredDepotTerminals"
                                    (completeMethod)="searchDepotTerminal($event, 'T')"
                                    [autoHighlight]="true" minLength="1"
                                    [dropdown]="true"
                                    [forceSelection]="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.label}}</div>
                      </ng-template>
                    </p-autoComplete>
                    <label>Terminal</label>
                  </span>
                </div>
              </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete [(ngModel)]="container.depot" field="label"
                                      [suggestions]="filteredDepotTerminals"
                                      (completeMethod)="searchDepotTerminal($event, 'D')"
                                      [autoHighlight]="true" minLength="1"
                                      [dropdown]="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                    <label>Depot</label>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="General Seal Section">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="container.zone">
                  <label>Zone</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="ui-float-label">
                <p-dropdown [options]="sealingParties"
                            [(ngModel)]="container.sealDetails.sealingParty"
                            [autoWidth]="false">
                </p-dropdown>
                 <label>Sealing Party</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="ui-float-label">
                  <p-dropdown [options]="sealStatuses"
                              [(ngModel)]="container.sealDetails.sealStatus"
                              [autoWidth]="false">
                </p-dropdown>
                <label>Seal Status</label>
              </span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4">
      <p-panel header="General MAS Section">
        <div class="ui-fluid">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                    <p-autoComplete id="masContainerSize" [ngModel]="container?.masContainerSize" field="label"
                                    [suggestions]="filteredMasContainerSizes"
                                    (completeMethod)="searchMasContainerSizes($event)"
                                    [autoHighlight]="true"
                                    minLength="1"
                                    required=true
                                    (onSelect)="selectMasContainerSize($event)">
                          <ng-template let-option pTemplate="item">
                        <div>{{option.code}}: {{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                   <label for="masContainerSize">MAS Code</label>
                  </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete id="masContainerType" [ngModel]="container?.masContainerType" field="label"
                            [suggestions]="filteredMasContainerTypes"
                            (completeMethod)="searchMasContainerTypes($event)"
                            [autoHighlight]="true" minLength="1"
                            required=true (onSelect)="selectMasContainerType($event)">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.type}}: {{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="masContainerType">MAS Type</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
