<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-md-8">
    <p-table [value]="businessEntity?.roles" [(selection)]="activeRole" selectionMode="single" [resizableColumns]="true"
             (onRowSelect)="onRoleSelected($event)" (onRowUnselect)="onRoleUnSelected($event)">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" colspan="4">Code</th>
          <th scope="col" colspan="4">Name</th>
          <th scope="col" colspan="4">Role</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
        <tr [pSelectableRow]="role" [pSelectableRowIndex]="rowIndex">
          <td scope="row" colspan="4"><label>{{businessEntity?.code}}</label></td>
          <td colspan="4"><label>{{businessEntity?.name}}</label></td>
          <td colspan="4"><label>{{getRoleDescription(role.roleType)}}</label></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="12">
            <label>No Roles</label>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-12 ui-md-8">
    <p-panel>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
                    <span class="ui-float-label">
            <p-autoComplete field="description"
                            [suggestions]="filteredRoleTypes" [(ngModel)]="selectedRoleMetaData"
                            (onSelect)="selectedRoleMetaDataFromDropDown($event)" [dropdown]="true"
                            (completeMethod)="searchRoleMetaData($event)"
                            [autoHighlight]="true">
            </p-autoComplete>
            <label>Role Type</label>
          </span>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-md-12">
    <div class="" [ngSwitch]="selectedRoleMetaData?.description">
      <div class="" *ngSwitchCase="'Debtor'">
        <digi-debtor-role [businessEntity]="businessEntity" [debtor]="activeRole"></digi-debtor-role>
      </div>
      <div class="" *ngSwitchCase="'Local Agent'">
        <app-local-agent-role [businessEntity]="businessEntity" [localAgent]="activeRole"></app-local-agent-role>
      </div>
      <div class="" *ngSwitchCase="'Rebate User'">
        <app-rebate-user-role [businessEntity]="businessEntity" [rebateUser]="activeRole">
        </app-rebate-user-role>
      </div>
      <div class="" *ngSwitchCase="'Creditor'">
        <app-creditor-role [businessEntity]="businessEntity" [creditor]="activeRole"></app-creditor-role>
      </div>
      <div class="" *ngSwitchCase="'Branch'">
        <app-branch-role [businessEntity]="businessEntity" [branch]="activeRole"></app-branch-role>
      </div>
      <div class="" *ngSwitchCase="'Supplier'">
        <digi-supplier-role [businessEntity]="businessEntity" [supplier]="activeRole">
        </digi-supplier-role>
      </div>
      <div class="" *ngSwitchCase="'Importer'">
        <app-importer-role [businessEntity]="businessEntity" [importer]="activeRole">
        </app-importer-role>
      </div>
      <div class="" *ngSwitchCase="'Guarantor'">
        <digi-app-guarantor-role [businessEntity]="businessEntity" [guarantor]="activeRole"></digi-app-guarantor-role>
      </div>
      <div class="" *ngSwitchCase="'Remover'">
        <digi-app-remover-role [businessEntity]="businessEntity" [remover]="activeRole"></digi-app-remover-role>
      </div>
      <div class="" *ngSwitchCase="'Warehouse'">
        <digi-app-warehouse-role [businessEntity]="businessEntity" [warehouse]="activeRole"></digi-app-warehouse-role>
      </div>
      <div class="" *ngSwitchCase="'Haulier'">
        <digi-haulier-role [businessEntity]="businessEntity" [haulier]="activeRole"></digi-haulier-role>
      </div>
      <div class="" *ngSwitchCase="'Exporter'">
        <digi-exporter-role [businessEntity]="businessEntity" [exporter]="activeRole"></digi-exporter-role>
      </div>
      <div class="" *ngSwitchCase="'Export Consignee'">
        <digi-sea-export-consignee-role [businessEntity]="businessEntity" [consignee]="activeRole"></digi-sea-export-consignee-role>
      </div>
      <div class="" *ngSwitchCase="'Overseas Agent'">
        <digi-overseas-agent-role [businessEntity]="businessEntity" [overseasAgent]="activeRole"></digi-overseas-agent-role>
      </div>
      <div class="" *ngSwitchCase="'Consignor'">
        <digi-consignor-role [businessEntity]="businessEntity" [consignor]="activeRole"></digi-consignor-role>
      </div>
      <div class="" *ngSwitchDefault>
        <span *ngIf="selectedRoleMetaData">{{selectedRoleMetaData?.description}} not supported</span>
      </div>
    </div>
  </div>
</div>
