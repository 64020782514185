<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-g-nopad pt-2">
      <digi-currency label="Currency" [required]="required" [currency]="freight.currency">
      </digi-currency>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText
            [(ngModel)]="freight.amount">
          <label>Amount</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-g-nopad pt-2">
      <digi-collect-or-prepaid-search [freight]="freight" [required]="required"></digi-collect-or-prepaid-search>
    </div>
  </div>
</div>
