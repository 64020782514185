import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Address } from '../../models/file';

@Component({
  selector: 'digi-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent implements OnInit, OnChanges {
  @Input() address;
  @Input() addressLabel = 'Lines';
  @Input() addresses: Array<Address>;
  selectedAddress: Address;
  filteredAddresses: any[];


  constructor() {
  }

  ngOnInit() {
    this.selectedAddress = this.address;
  }


  ngOnChanges() {
    this.selectedAddress = this.address;
  }

  search(event) {
    this.filteredAddresses = [];
    if(this.addresses) {
      for (let i = 0; i < this.addresses.length; i++) {
        const address: Address = this.addresses[i];
        if (address.line1.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredAddresses.push(address);
        }
      }
    }
  }

  selectAddress(value) {
    this.address.line1 = value.line1;
    this.address.line2 = value.line2;
    this.address.line3 = value.line3;
    this.address.line4 = value.line4;
    this.address.zipCode = value.zipCode;

  }
}
