<div class="ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.boeInvoiceDetails[0]?.bankInvoiceNumber}}"
          readonly>
        <label> Bank Invoice No</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text"
          [(ngModel)]="selectedBillOfEntry.financialAccountNumber"  pInputText>
        <label> Financial Account No (FAN)</label>
      </span>
    </div>
    <div *ngIf="isVoC" class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-dropdown [options]="ackIndicators"
                        [(ngModel)]="selectedBillOfEntry.acknowledgementIndicator"
                        [autoWidth]="false">
            </p-dropdown>
              <label>Change Acknowledgement Indicator</label>
            </span>
      </div>
    </div>
  </div>
</div>
