import { Pipe, PipeTransform } from "@angular/core";
import { Country } from "./../models/file";

@Pipe({
  name: "country",
  pure: false
})
export class CountryPipe implements PipeTransform {
  transform(value: Country): string {
    if (value && value.code) {
      return value.code.concat(":", value.name);
    } else {
      return "";
    }
  }
}
