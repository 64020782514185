import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company.service';
import {ClearingFile, PaymentFile} from '../../../models/clearingFile';
import {BillOfEntry} from '../../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../../subjects/billOfEntry-behaviour-subject';

@Component({
  selector: 'digi-payment-file',
  templateUrl: './payment-file.component.html',
  styleUrls: ['./payment-file.component.scss']
})
export class PaymentFileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clearingFile: ClearingFile;
  @Input() paymentFile: PaymentFile;
  @Input() title: string;
  companySubscription: Subscription;
  boeSubscription: Subscription;
  boe: BillOfEntry;

  constructor(
    private companyService: CompanyService,
    private boeBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          const branch = this.clearingFile.branch ? companies[0].branches.find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
          this.paymentFile.clientName = companies[0].name;
          this.paymentFile.section4BusinessParty = companies[0].name;
          this.paymentFile.section6BusinessParty = companies[0].name;
        }
      }
    );
    this.boeSubscription = this.boeBehaviourSubject.getBillOfEntry().subscribe(
      (boe: BillOfEntry) => this.boe = boe
    );

    this.paymentFile.clientCode = this.clearingFile.localAgent.customsRegCode;
    this.paymentFile.importer = true;
    this.paymentFile.branch = this.clearingFile.customOffice.districtOfficeName;

    if (this.boe) {
      this.paymentFile.section5Mrn = this.boe.mrn;
      const tariffCode = this.boe.line.tariffCode.code;
      const customsDutyStrArr = this.boe.totalCustomsDuty.toString().split(".");
      const customsVatStrArr = this.boe.totalCustomsVAT.toString().split(".");
      let customsDutyRands = "0";
      let customsDutyCents = "0";
      let customsVatRands = "0";
      let customsVatCents = "0";

      if (customsDutyStrArr.length > 1) {
        customsDutyRands = customsDutyStrArr[0];
        customsDutyCents = customsDutyStrArr[1].length === 2 ? customsDutyStrArr[1] : customsDutyStrArr[1] + "0";
      } else {
        customsDutyRands = customsDutyStrArr[0];
      }
      if (customsVatStrArr.length > 1) {
        customsVatRands = customsVatStrArr[0];
        customsVatCents = customsVatStrArr[1].length === 2 ? customsVatStrArr[1] : customsVatStrArr[1] + "0";
      } else {
        customsVatRands = customsVatStrArr[0];
      }
      this.paymentFile.section5TariffSubheading = tariffCode.slice(0, 4) + "." + tariffCode.slice(4, 6);
      this.paymentFile.section5DutyRands = customsDutyRands;
      this.paymentFile.section5DutyCents = customsDutyCents;
      this.paymentFile.section5VatRands = customsVatRands;
      this.paymentFile.section5VatCents = customsVatCents;
      if (this.boe.cusres) {
        this.paymentFile.section5Date = moment(this.boe.cusres.receivedDate, 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD');
      }
    }
    if (this.clearingFile.transportDocument.marksAndNumbers !== null && this.clearingFile.transportDocument.marksAndNumbers.length > 0) {
      this.paymentFile.section5MarksAndNumbers = this.clearingFile.transportDocument.marksAndNumbers[0];
    }
    if (this.clearingFile.transportDocument.supplierInvoices.length > 0) {
      this.paymentFile.section5Value = this.clearingFile.transportDocument.supplierInvoice.amount;
      this.paymentFile.section5OfCountry = this.clearingFile.transportDocument.supplierInvoice.defaultValues.country.code.trim();
      this.paymentFile.section5Coo = this.clearingFile.transportDocument.supplierInvoice.defaultValues.country.code.trim();
      this.paymentFile.section5Supplier = this.clearingFile.transportDocument.supplierInvoice.supplier.name;
    }
    this.paymentFile.clientReference = this.clearingFile.reference.referenceFileNo;
    this.paymentFile.section4SigningParty = this.clearingFile.reference.channel;
    this.paymentFile.section4Capacity = "Operations";
    this.paymentFile.section4Location = "Johannesburg";
    this.paymentFile.section4Date = this.getDate();
    this.paymentFile.section6SigningParty = this.clearingFile.reference.channel;
    this.paymentFile.section6Capacity = "Operations";
    this.paymentFile.section6Location = "Johannesburg";
    this.paymentFile.section6Date = this.getDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearingFile) {
      this.clearingFile = changes.clearingFile.currentValue;
    }

    if (changes.paymentFile) {
      this.paymentFile = changes.paymentFile.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.boeSubscription) {
      this.boeSubscription.unsubscribe();
    }
  }

  getDate() {
    return moment().format('YYYY/MM/DD');
  }
}
