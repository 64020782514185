import {Injectable} from '@angular/core';
import {ForwardingFile} from '../models/ForwardingFile';
import {ValidationResult} from './validation.service';
import {ForwardingFileService} from './forwarding-file.service';

@Injectable({
  providedIn: 'root'
})
export class ForwardingFileValidationService {

  constructor(private forwardingFileService: ForwardingFileService) {
  }

  async validateManifests(forwardingFile: ForwardingFile, transportMethod: string): Promise<ValidationResult> {
    if (transportMethod.toLowerCase() === 'air') {
      let validationResult: ValidationResult = this.validateFlightInformationForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateAirportInformationForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateAgentInformationForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateCustomsOfficeForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateWeightsAndMeasuresForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = await this.validateHouseBillNumbersForAir(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }
      validationResult = await this.validateHousebillInformation(forwardingFile);

      validationResult = await this.validateDebtorHousebillInformation(forwardingFile);

      if (!validationResult.isValid) {
        return validationResult;
      }

    } else {
      let validationResult: ValidationResult = this.validateVoyageInformationForSea(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validatePortInformationForSea(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateAgentInformationForSea(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateCustomsOfficeForSea(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }

      validationResult = this.validateWeightsAndMeasuresForSea(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }
      validationResult = await this.validateDebtorHousebillInformation(forwardingFile);
      if (!validationResult.isValid) {
        return validationResult;
      }
    }
    return {isValid: true, messages: []};
  }

  async validateHouseBillNumbersForAir(forwardingFile: ForwardingFile): Promise<ValidationResult> {
    const result: ValidationResult = {isValid: true, messages: []};
    const errorMessages = await this.forwardingFileService.validateHouseBills(forwardingFile.houseBills).toPromise();
    if (errorMessages.length) {
      this.fail(result, errorMessages);
    }
    return result;
  }

  validateWeightsAndMeasuresForAir(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.measures.numberOfPackages) {
      this.fail(result, 'Please provide No. of Packages.');
    }

    if (!forwardingFile.masterBill.measures.grossWeight) {
      this.fail(result, 'Please provide Gross Weight (kg).');
    }

    if (!forwardingFile.masterBill.measures.chargeableWeight) {
      this.fail(result, 'Please provide Chargeable Weight (kg).');
    }
    return result;
  }

  validateCustomsOfficeForSea(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.customOffice) {
      this.fail(result, 'Please select Custom Office');
    }
    return result;
  }

  validateAgentInformationForSea(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.localForwarder) {
      this.fail(result, 'Please select Forwarding Agent.');
    }
    return result;
  }

  validatePortInformationForSea(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.departurePort.code) {
      this.fail(result, 'Please select Departure Port.');
    }

    if (!forwardingFile.masterBill.docsIssuedAtPort.code) {
      this.fail(result, 'Please select Docs Issued At Port.');
    }

    if (!forwardingFile.masterBill.dischargePort.code) {
      this.fail(result, 'Please select Discharge Port.');
    }

    if (!forwardingFile.masterBill.destinationPort.code) {
      this.fail(result, 'Please select Destination Port.');
    }

    if (!forwardingFile.masterBill.callPurpose) {
      this.fail(result, 'Please select Call Purpose.');
    }
    return result;
  }

  validateVoyageInformationForSea(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};

    if (!forwardingFile.masterBill.oceanLineDetails.oceanLine.code) {
      this.fail(result, 'Please select Ocean BOL No.');
    }

    if (!forwardingFile.masterBill.mawbDate) {
      this.fail(result, 'Please provide SOB Date.');
    }

    if (!forwardingFile.masterBill.departureDate) {
      this.fail(result, 'Please provide E.T.S');
    }

    if (!forwardingFile.masterBill.arrivalDate) {
      this.fail(result, 'Please provide E.T.A');
    }
    return result;
  }

  validateWeightsAndMeasuresForSea(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.measures.numberOfPackages) {
      this.fail(result, 'Please provide No. of Packages.');
    }
    if (!forwardingFile.masterBill.measures.grossWeight) {
      this.fail(result, 'Please provide Gross Weight (kg).');
    }
    if (!forwardingFile.masterBill.measures.chargeableWeight) {
      this.fail(result, 'Please provide Chargeable Weight (kg).');
    }
    return result;
  }

  validateCustomsOfficeForAir(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.customOffice.districtOfficeCode) {
      this.fail(result, 'Please select Custom Office.');
    }
    return result;
  }

  validateAgentInformationForAir(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.localForwarder) {
      this.fail(result, 'Please select Forwarding Agent.');
    }
    return result;
  }

  validateAirportInformationForAir(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (!forwardingFile.masterBill.departurePort.code) {
      this.fail(result, 'Please select Departure Airport.');
    }

    if (!forwardingFile.masterBill.destinationPort.code) {
      this.fail(result, 'Please select Destination Airport.');
    }

    if (!forwardingFile.masterBill.callPurpose) {
      this.fail(result, 'Please select Call Purpose.');
    }
    return result;
  }

  validateHousebillInformation(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};

    forwardingFile.houseBills.forEach(houseBill => {
      if (houseBill && forwardingFile.fileNumber) {
        if (!houseBill.localAgent) {
          this.fail(result, 'Please check all Housebills and add Clearing Agents');
        }
      }
    });
    return result;
  }

  validateDebtorHousebillInformation(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    forwardingFile.houseBills.forEach(houseBill => {
      if (houseBill && forwardingFile.fileNumber && houseBill.invoicingInUse) {
        if (!houseBill.debtor) {
          this.fail(result, 'Please check all Housebills and add debtors');
        }
      }
    });
    return result;
  }

  validateFlightInformationForAir(forwardingFile: ForwardingFile): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};

    if (!forwardingFile.masterBill.airline) {
      this.fail(result, 'Please select Airline');
    }

    if (!forwardingFile.masterBill.masterAirline) {
      this.fail(result, 'Please select Master CC');
    }

    if (forwardingFile.masterBill.mawbNo) {
      const validMawbNo = forwardingFile.masterBill.mawbNo.split('').length;
      if (validMawbNo !== 8) {
        this.fail(result, 'MAWB No should be at least 8 digits long');
      }
    } else {
      this.fail(result, 'Please provide MAWB No');
    }

    if (!forwardingFile.masterBill.mawbDate) {
      this.fail(result, 'Please provide MAWB Date');
    }

    if (!forwardingFile.masterBill.flight) {
      this.fail(result, 'Please provide Flight No.');
    }

    if (!forwardingFile.masterBill.departureDate) {
      this.fail(result, 'Please provide Departure Date');
    }

    if (!forwardingFile.masterBill.arrivalDate) {
      this.fail(result, 'Please provide Arrival Date');
    }
    return result;
  }

  fail(result: ValidationResult, message: string): any {
    result.isValid = false;
    result.messages.push(message);
  }
}
