import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SchedulePart, TariffCode, TariffFormula } from '../../models/file';
import { SupplierInvoiceLineBehaviourSubject } from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../../../subjects/supplierInvoice-behaviour-subject';
import { SchedulePartService } from '../../services/schedule-part.service';
var AdditionalScheduleTariffComponent = /** @class */ (function () {
    function AdditionalScheduleTariffComponent(supplierInvoiceLineBehaviourSubjectComponent, supplierInvoiceBehaviourSubject, schedulePartService) {
        this.supplierInvoiceLineBehaviourSubjectComponent = supplierInvoiceLineBehaviourSubjectComponent;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.schedulePartService = schedulePartService;
        this.selectedAdditionalScheduleTariffsChange = new EventEmitter();
        this.display = false;
    }
    AdditionalScheduleTariffComponent.prototype.ngOnInit = function () {
        this.rebateTariffs = [];
        this.searchedAdditionalScheduleTariffs = [];
        this.additionalScheduleTariffs = [];
        this.selectedRebate = new TariffCode();
        this.updateRebateTableDisplay();
        this.updateTableDisplay();
        this.cols = [
            { field: 'scheduleCode', header: '', width: '5%' },
            { field: 'scheduleCode', header: 'Schedule', width: '5%' },
            { field: 'dependantCode', header: 'Rebate Code', width: '15%' },
            { field: 'dependantCheckDigit', header: 'Check Digit', width: '5%' },
            { field: 'scheduleDescription', header: 'Description', width: '30%' },
            { field: 'description', header: 'Formula', width: '20%' }
        ];
        this.dutyCols = [
            { field: '', header: '', width: '2%' },
            { field: 'code', header: 'S/P', width: '2%' },
            { field: 'dependantCode', header: 'Dependant Code', width: '8%' },
            { field: 'tariffCheckDigit', header: '', width: '2%' },
            { field: 'tariffSDesc', header: 'Description', width: '30%' },
            { field: 'description', header: 'Formula', width: '20%' }
        ];
    };
    AdditionalScheduleTariffComponent.prototype.ngOnChanges = function (changes) {
        if (changes.tariffCode && changes.tariffCode.currentValue) {
            this.tariffCode = changes.tariffCode.currentValue;
        }
        if (changes.clearingInstruction && changes.clearingInstruction.currentValue) {
            this.clearingInstruction = changes.clearingInstruction.currentValue;
        }
        if (changes.selectedAdditionalScheduleTariffs && changes.selectedAdditionalScheduleTariffs.currentValue) {
            this.selectedAdditionalScheduleTariffs = changes.selectedAdditionalScheduleTariffs.currentValue;
            this.selectedRebate = new TariffCode();
        }
        this.updateRebateTableDisplay();
        this.updateTableDisplay();
    };
    AdditionalScheduleTariffComponent.prototype.updateTableDisplay = function () {
        var _this = this;
        this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(this.clearingInstruction.customsPurpose.customsPurposeCode, this.tariffCode.code, 'C')
            .subscribe(function (data) {
            var countryCode;
            if (_this.countryOfOriginCode) {
                countryCode = _this.countryOfOriginCode;
            }
            else {
                _this.supplierInvoiceLineBehaviourSubjectComponent.getCountry()
                    .subscribe(function (country) {
                    if (country) {
                        countryCode = country.code.trim();
                    }
                });
            }
            _this.searchedAdditionalScheduleTariffs = data.filter(function (tariff) { return tariff.schedule.trim() !== '21' || tariff.effectiveTradeAgreementFormulae['ANTI'.concat(countryCode)]; }).map(function (tariffCode) {
                var additionalSchedule = new TariffCode();
                additionalSchedule.schedulePart = new SchedulePart();
                additionalSchedule.formula = new TariffFormula();
                additionalSchedule.code = tariffCode.code;
                additionalSchedule.checkDigit = tariffCode.tariffCheckDigit;
                additionalSchedule.dependantCode = tariffCode.dependantCode;
                additionalSchedule.schedule = tariffCode.schedule;
                additionalSchedule.fullDescription = tariffCode.fullDescription;
                additionalSchedule.tariff = tariffCode.tariff;
                additionalSchedule.tariffID = tariffCode.tariffID;
                additionalSchedule.dependantCheckDigit = tariffCode.dependantCheckDigit;
                additionalSchedule.schedulePart.code = tariffCode.schedulePart.code;
                additionalSchedule.schedulePart.description = tariffCode.schedulePart.description;
                additionalSchedule.schedulePart.calcOrder = tariffCode.schedulePart.calcOrder;
                additionalSchedule.schedulePart.chargeRebate = tariffCode.schedulePart.chargeRebate;
                if (tariffCode.effectiveTradeAgreementFormulae['STANDARD']) {
                    additionalSchedule.formula.description = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].description;
                    additionalSchedule.formula.computations = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].computations;
                    additionalSchedule.formula.parameters = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].parameters;
                }
                else if (tariffCode.effectiveTradeAgreementFormulae['ANTI'.concat(countryCode)]) {
                    additionalSchedule.formula.description = tariffCode.effectiveTradeAgreementFormulae['ANTI'.concat(countryCode)].description;
                    additionalSchedule.formula.computations = tariffCode.effectiveTradeAgreementFormulae['ANTI'.concat(countryCode)].computations;
                    additionalSchedule.formula.parameters = tariffCode.effectiveTradeAgreementFormulae['ANTI'.concat(countryCode)].parameters;
                }
                additionalSchedule.parameters = [];
                if (tariffCode.effectiveTradeAgreementParameters['STANDARD']) {
                    Object.keys(tariffCode.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
                        additionalSchedule.parameters.push(tariffCode.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
                    });
                }
                else if (tariffCode.effectiveTradeAgreementParameters['ANTI'.concat(countryCode)]) {
                    Object.keys(tariffCode.effectiveTradeAgreementParameters['ANTI'.concat(countryCode)]).forEach(function (effectiveParameterKeys) {
                        additionalSchedule.parameters.push(tariffCode.effectiveTradeAgreementParameters['ANTI'.concat(countryCode)][effectiveParameterKeys]);
                    });
                }
                else {
                    additionalSchedule.parameters = [];
                }
                return additionalSchedule;
            });
            _this.additionalScheduleTariffs = _this.searchedAdditionalScheduleTariffs.slice();
            if (_this.selectedAdditionalScheduleTariffs) {
                var newArr_1 = [];
                var rebates = _this.selectedAdditionalScheduleTariffs.filter(function (t) { return t.schedulePart.chargeRebate === 'R'; });
                (_a = _this.additionalScheduleTariffs).push.apply(_a, rebates);
                _this.selectedAdditionalScheduleTariffs.forEach(function (additionalSchedule) {
                    var found = _this.additionalScheduleTariffs.find(function (tariff) { return tariff.schedule === additionalSchedule.schedule && tariff.dependantCheckDigit === additionalSchedule.dependantCheckDigit; });
                    if (found) {
                        newArr_1.push(found);
                    }
                });
                _this.selectedAdditionalScheduleTariffs = newArr_1.slice();
                _this.update();
            }
            _this.additionalScheduleTariffs = _this.additionalScheduleTariffs.slice();
            var _a;
        });
    };
    AdditionalScheduleTariffComponent.prototype.updateRebateTableDisplay = function () {
        var _this = this;
        this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(this.clearingInstruction.customsPurpose.customsPurposeCode, this.tariffCode.code, 'R')
            .subscribe(function (data) { return _this.scheduleParts = data; });
    };
    AdditionalScheduleTariffComponent.prototype.openRebatesModel = function () {
        this.display = true;
    };
    AdditionalScheduleTariffComponent.prototype.onRowSelectRebate = function (event) {
        var _this = this;
        var rebate = this.updateRebate(event.data);
        if (this.selectedAdditionalScheduleTariffs) {
            this.selectedAdditionalScheduleTariffs = this.selectedAdditionalScheduleTariffs
                .filter(function (tariff) { return !_this.rebateTariffs.includes(tariff); });
        }
        this.additionalScheduleTariffs.push(rebate);
        this.additionalScheduleTariffs = this.additionalScheduleTariffs.slice();
        this.selectedAdditionalScheduleTariffs.push(rebate);
        this.selectedAdditionalScheduleTariffs = this.selectedAdditionalScheduleTariffs.slice();
        this.update();
    };
    AdditionalScheduleTariffComponent.prototype.updateRebate = function (data) {
        return this.updateSchedulePartFormula(data);
    };
    AdditionalScheduleTariffComponent.prototype.updateSchedulePartFormula = function (data) {
        var rebate = data;
        rebate.formula = new TariffFormula();
        rebate.formula.description =
            data.effectiveTradeAgreementRebateFormulae['STANDARD'].description;
        rebate.formula.computations =
            data.effectiveTradeAgreementRebateFormulae['STANDARD'].computations;
        rebate.formula.parameters =
            data.effectiveTradeAgreementRebateFormulae['STANDARD'].parameters;
        rebate.parameters = [];
        if (data.effectiveTradeAgreementParameters.STANDARD) {
            Object.keys(data.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
                rebate.parameters.push(data.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
            });
        }
        else {
            rebate.parameters = [];
        }
        return rebate;
    };
    AdditionalScheduleTariffComponent.prototype.update = function () {
        this.selectedAdditionalScheduleTariffsChange.emit(this.selectedAdditionalScheduleTariffs);
    };
    return AdditionalScheduleTariffComponent;
}());
export { AdditionalScheduleTariffComponent };
