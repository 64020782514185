<p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false" styleClass="steps-custom"></p-steps>
<div class="ui-g">
  <div class="ui-g-12">
    <p-toolbar>
      <app-business-entity-toolbar [businessEntity]="businessEntity"
                                   (selectBusinessEntity)="onSelectBusinessEntity($event)"
                                   [businessEntityStepActive]="businessEntityStepActive"
                                   [activeRoleType]="activeRoleType">
      </app-business-entity-toolbar>
    </p-toolbar>
    <ng-container *ngIf="businessEntityStepActive; then forBusinessEntity; else forRole">
    </ng-container>
    <ng-template #forBusinessEntity>
      <div class="ui-g-12">
        <p-tabView orientation="left" styleClass="stepMenu">
          <p-tabPanel header="Main">
            <ng-template pTemplate="content">
              <div class="">
                <app-main-business-entity-details [businessEntity]="businessEntity"></app-main-business-entity-details>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Address" [disabled]="!businessEntity.code">
            <ng-template pTemplate="content">
              <div class="">
                <app-main-business-entity-address [businessEntity]="businessEntity"></app-main-business-entity-address>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Upload Export Consignees">
            <ng-template pTemplate="content">
              <div *ngIf="disableButton" class="ui-g-12">
                <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
              </div>
              <div class="ui-g-12">
                <p-fileUpload (onSelect)="selectFiles($event)"
                              (uploadHandler)="uploadExportConsigneeDetails()"
                              [customUpload]="true"
                              accept=".xlsx">
                </p-fileUpload>
              </div>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-template>
    <ng-template #forRole>
      <div class="ui-g-12">
        <p-tabView orientation="left" styleClass="stepMenu">
          <p-tabPanel header="Main">
            <ng-template pTemplate="content">
              <digi-app-business-entity-roles [businessEntity]="businessEntity" [selectedRoleMetaData]="activeRoleType"
                                              [rolesMetaData]="rolesMetaData"
                                              (selectRoleMetaData)="onSelectedRoleMetaData($event)"
                                              (selectActiveRole)="onSelectedRole($event)">
              </digi-app-business-entity-roles>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Address" [disabled]="!activeRole">
            <ng-template pTemplate="content">
              <app-importer-address [activeRoleType]="activeRoleType" [businessEntity]="businessEntity"
                                    [addressTypes]="activeRoleType?.addressTypes"></app-importer-address>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Nominations" [disabled]="!activeRole">
            <ng-template pTemplate="content">
              <div class="" [ngSwitch]="activeRoleType?.description">
                <div class="" *ngSwitchCase="'Importer'">
                  <digi-app-role-nominations [activeRoleType]="activeRoleType"
                                             [businessEntity]="businessEntity"></digi-app-role-nominations>
                </div>
                <div *ngSwitchCase="'Exporter'">
                  <digi-app-role-nominations [activeRoleType]="activeRoleType"
                                             [businessEntity]="businessEntity"></digi-app-role-nominations>
                </div>
                <div class="" *ngSwitchDefault>
                  <span *ngIf="activeRoleType">{{activeRoleType?.description}} not supported</span>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-template>
  </div>
</div>
