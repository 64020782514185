import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ShareDataService} from '../../digi-common/services/share-data.service';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-ocean-dashboard',
  templateUrl: './ocean-dashboard.component.html',
  styleUrls: ['./ocean-dashboard.component.css'],
})
export class OceanDashboardComponent implements OnInit {

  constructor(private router: Router,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService) {
  }

  ngOnInit() {
    this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile('SEA'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== '/sea/register/') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['sea', 'register']);
    }
  }
}
