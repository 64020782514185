<p-panel>
    <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                        pInputText readonly>
                    <label>Rebate User Code</label>
                </span>
            </div>
        </div>
        <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.name}}"
                        pInputText readonly>
                    <label>Rebate User Name</label>
                </span>
            </div>
        </div>
        <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" [(ngModel)]="rebateUser.customsCode"
                        pInputText>
                    <label>Customs Code</label>
                </span>
            </div>
        </div>
    </div>
</p-panel>