<div class="ui-g-12 ui-g-nopad">
  <p-tabView>
    <p-tabPanel [header]="isHouseBill?'House Bill':'Master Bill'">
      <div class="ui-g ui-g-nopad ui-fluid">
        <div *ngIf="disableButton" class="ui-g-12">
          <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
        </div>
        <div class="ui-g-1">
          <button type="button" (click)="loadGeneratedFiles()" pButton icon="fa fa-print"
                  class="ui-button-secondary col-sm-12" [disabled]="disableButton"></button>
        </div>
        <div class="ui-g-11">
          <div class="ui-g-4">
            <p-panel [header]="isHouseBill?'House Bill Details':'Master Bill Details'">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span *ngIf="!isHouseBill"
                                      class="ui-inputgroup-addon">{{exportFile.transportDocument?.airline?.prefix}}</span>
                                    <span *ngIf="!isHouseBill" class="md-inputfield">
                <input id="txtMAWBPartTwo" type="text" class="ui-inputgroup"
                       [(ngModel)]="exportFile.transportDocument.mawbNo"
                       disabled
                       pInputText required minlength="8">
                     <label>MAWB No</label>
             </span>
                                    <span *ngIf="isHouseBill" class="ui-float-label">
                                  <p-autoComplete id="hawbNo"
                                                  class="ng-dirty ng-invalid"
                                                  [(ngModel)]="exportFile.transportDocument.hawbNo"
                                                  [suggestions]="filteredWaybillNumbers"
                                                  (completeMethod)="searchWaybillNumber()"
                                                  (onSelect)="updateWaybillPool($event)"
                                                  [dropdown]="true"
                                                  required=true>
                                    <ng-template let-option pTemplate="item">
                                      <div>{{option}}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="hawbNo">HAWB No</label>
                                </span>
            </div>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <digi-port-details portLabel='Departure Airport'
                                           [transportDetails]="exportFile.transportDocument.departurePort"
                                           [file]="exportFile"
                                           [transportMethodCode]="'AIR'"
                                           [isRequired]="false"
                        ></digi-port-details>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <digi-port-details portLabel='Destination Airport'
                                           *ngIf="exportFile.transportDocument"
                                           [transportDetails]="exportFile.transportDocument.destinationPort"
                                           [file]="exportFile"
                                           [transportMethodCode]="'AIR'"
                                           [isRequired]="false"
                        ></digi-port-details>
                      </div>
                      <ng-container *ngIf="isHouseBill">
                        <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                <input id="importer" type="text" class="ui-inputgroup"
                       [ngModel]="exportFile.exportConsignee.name"
                       disabled
                       pInputText>
                     <label>Importer</label>
             </span>
            </div>
          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                <input id="consignor" type="text" class="ui-inputgroup"
                       [ngModel]="exportFile.exporter.name"
                       disabled
                       pInputText>
                     <label>Consignor</label>
             </span>
            </div>
          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                <input id="debtor" type="text" class="ui-inputgroup"
                       [ngModel]="exportFile.debtor.name"
                       disabled
                       pInputText>
                     <label>Debtor</label>
             </span>
            </div>
          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Consignee Details">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <digi-business-entity-search *ngIf="!isHouseBill" [businessEntity]="exportFile.exportConsignee"
                                                 [showAddress]="true"
                                                 [roleName]="'Consignee'"
                                                 [roleID]="2"
                                                 [isDropdown]="true"
                                                 [businessEntities]="filteredExportConsignees"
                                                 (selectedBusinessEntity)="selectConsignee($event)"
                    ></digi-business-entity-search>
                    <digi-business-entity-search *ngIf="isHouseBill" [businessEntity]="exportFile.localAgent"
                                                 [roleName]="'Local Agent'"
                                                 [roleID]="4"
                                                 [showAddress]="true"
                                                 (selectedBusinessEntity)="selectLocalAgent($event)"
                    >
                    </digi-business-entity-search>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Weights & Measures">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text"
                             pInputText
                             [(ngModel)]="exportFile.transportDocument.measures.numberOfPackages">
                      <label>No. of Packages</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText [(ngModel)]="exportFile.transportDocument.measures.volume">
                        <label>Volume</label>
                      </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="exportFile.transportDocument.measures.grossWeight">
                      <label>Gross Weight (kg)</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" pInputText
                               [(ngModel)]="exportFile.transportDocument.measures.chargeableWeight">
                        <label>Chargeable Weight (kg)</label>
                      </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12" *ngIf="isHouseBill">
                    <p-checkbox name="rates"
                                label="Rates As Agreed"
                                binary="true"
                                [(ngModel)]="exportFile.transportDocument.ratesAsAgreed">
                    </p-checkbox>
                  </div>
                </div>
              </div>
            </p-panel>
            <br>
            <p-panel header="Freight">
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-g-nopad pt-2">
                    <digi-currency label="Currency"
                                   [currency]="exportFile.transportDocument.freight.currency">
                    </digi-currency>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
        <span class="md-inputfield">
          <input type="text" pInputText
                 [(ngModel)]="exportFile.transportDocument.freight.amount">
          <label>Amount</label>
        </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-g-nopad pt-2">
                    <div class="ui-g-12 ui-md-12">
   <span class="md-inputfield">
     <span class="ui-float-label">
        <p-autoComplete id="collectPrepaid" [(ngModel)]="exportFile.transportDocument.freight.collectPrepaid"
                        field="label" [dropdown]="true"
                        [suggestions]="filteredCollectPrepaids"
                        (completeMethod)="searchCollectOrPrepaid($event)"
                        [autoHighlight]="true" (onSelect)="selectCollectOrPrepaid($event)" minLength="1"
                        [forceSelection]="true"
                        required=true>
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}: {{option.description}}</div>
          </ng-template>
        </p-autoComplete>
        <label for="collectPrepaid">Collect/Prepaid</label>
     </span>
   </span>
                    </div>

                  </div>
                </div>
              </div>

            </p-panel>
          </div>
          <div class="ui-g-12">
            <div class="ui-g-5">
              <p-panel header="Handling Information">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.handlingInformation.line1">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.handlingInformation.line2">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.handlingInformation.line3">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.handlingInformation.line4">
                                </span>
                              </div>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-5">
              <p-panel header="Accounting Information">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.accountingInformation.line1">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.accountingInformation.line2">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.accountingInformation.line3">
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="exportFile.transportDocument.accountingInformation.line4">
                                </span>
                              </div>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-2">
              <button pButton type="submit" label="MAWB Other Charges" class="ui-button-secondary"
                      (click)="openSundryChargeModel()"></button>
              <br>
              <p-table [value]="sundryCharges" dataKey="code" [style]="{'margin-bottom':'20px'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Code</th>
                    <th>Amount</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td>{{rowData.code}}</td>
                    <td>{{rowData.amount}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<digi-sundry-charges [display]="displaySundryModal" (displayChange)="closeSundryChargesModal()"
                     [hasSundryCharge]="exportFile.transportDocument"></digi-sundry-charges>
<p-dialog header="Generated Documents" [(visible)]="showFilesDialog" [modal]="true"
          [responsive]="true" [width]="400"
          [minWidth]="200" [minY]="70"
          [maximizable]="true" [baseZIndex]="10000">
  <ng-template pTemplate="content" [ngIf]="generatedFiles">
    <li *ngFor="let generatedDocument of generatedFiles">
      <a class="uploaded-file-color"
         [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
    </li>
  </ng-template>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="showFilesDialog=false"
            label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
