import {Component, DoCheck, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {ClearingFile} from '../../models/clearingFile';
import {PortDetail, TransportMethod} from '../../models/file';
import {InternationalPortService} from '../../services/international-port.service';
import {CountryService} from '../../services/country.service';
import {Subscription} from 'rxjs';
import {ExportFile} from '../../models/exportFile';

@Component({
  selector: 'digi-port-details',
  templateUrl: './port-details.component.html',
  styleUrls: ['./port-details.component.scss']
})
export class PortDetailsComponent implements OnInit, DoCheck {
  @Input() portLabel = 'Departure Airport';
  @Input('transportDetails') transportDetails;
  @Input() file: ClearingFile | ExportFile;
  @Input() transportMethodCode: string;
  @Input() isRequired = true;
  selectedTransportDetail: PortDetail;
  filteredPortDetails = [];
  airportDiffer: KeyValueDiffer<string, any>;
  aiportUpdated = false;
  @Output() onSelect = new EventEmitter();

  constructor(private internationalPortService: InternationalPortService,
              private countryService: CountryService,
              private differs: KeyValueDiffers
  ) {
  }

  ngOnInit() {
    this.selectedTransportDetail = this.transportDetails;
    this.airportDiffer = this.differs.find(this.transportDetails).create();
  }

  search(event) {
    switch (this.transportMethodCode) {
      case 'AIR':
        this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsAir(event.query).subscribe(data => {
          this.filteredPortDetails = data;
        });
        break;
      case 'SEA':
        this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsNotAir(event.query).subscribe(data => {
          this.filteredPortDetails = data;
        });
        break;
      case 'ROA':
        this.internationalPortService.findAllInternationalPortsWithCodeOrNameStartsWith(event.query).subscribe(data => {
          this.filteredPortDetails = data;
        });
        break;
    }
  }

  ngDoCheck(): void {
    const changes = this.airportDiffer.diff(this.transportDetails);
    if (changes) {
      this.airPortChanged();
    }
  }

  airPortChanged() {
    if (this.transportDetails.code) {
      this.selectedTransportDetail = this.transportDetails;
      if (this.aiportUpdated === false) {
        this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsAir(this.transportDetails.code)
          .subscribe(data => {
            this.select(data[0]);
            this.aiportUpdated = true;
          });
      }
    } else {
      this.selectedTransportDetail = null;
    }
  }

  select(value) {
    if (value) {
      this.transportDetails.code = value.code;
      this.transportDetails.name = value.name;
      this.transportDetails.label = value.label;
      this.updatePortCountry(value.countryCode);
      this.transportDetails.transportMethod = value.transportMethod;
      this.transportDetails.masPortCode = value.masPortCode;
    }
  }

  private updatePortCountry(countryCode) {
    this.countryService.findCountryStartsWith(countryCode).subscribe(data => {
      this.transportDetails.countryCode = data[0].code;
      this.transportDetails.countryName = data[0].name;
      this.onSelect.emit(this.transportDetails);
    });
  }
}
