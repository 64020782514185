import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {EMPTY, empty, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {ClearingFile} from '../models/clearingFile';
import {Address} from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findLocalAgentNameOrCodeStartsWith(code): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=${code}&roleType=4&projection=flattenedLocalAgent`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(nameOrCargoCarrierCode: string, transportMethod: string): Observable<any> {
    return this.http
      .get(`${this.config.apiEndpoint}api/customscargocarriercodes/search/nameOrCargoCarrierCodeStartsWithAndTransportMethod?localAgentName=${nameOrCargoCarrierCode}&cargoCarrierCode=${nameOrCargoCarrierCode}&transportMethod=${transportMethod}&projection=FlattenedCustomsCargoCarrierCode`)
      .pipe(
        map(response => response['_embedded'].customscargocarriercodes)
      );
  }

  findLocalAgentFromClearingAgent(clearingFile: ClearingFile) {
    if (clearingFile.localAgent && clearingFile.localAgent.code) {
      return this.findLocalAgentNameOrCodeStartsWith(clearingFile.localAgent.code).pipe(
        tap(
          localAgentData => {
            if (localAgentData && (!clearingFile.localAgent.address || !clearingFile.localAgent.address.line1)) {
              const localAgentAddresses = localAgentData[0].addresses;
              let localAgentAddress;
              if (localAgentAddresses.length === 1) {
                localAgentAddress = localAgentAddresses[0];
              } else {
                localAgentAddress = localAgentAddresses.find(address => {
                  const addressType = address.addressTypes.find(addressType => {
                    return addressType.id === 'CUS';
                  });
                  if (addressType) {
                    return true;
                  } else {
                    return false;
                  }
                });
              }

              if (localAgentAddress) {
                clearingFile.localAgent.address = new Address();
                clearingFile.localAgent.address.line1 = localAgentAddress.line1;
                clearingFile.localAgent.address.line2 = localAgentAddress.line2;
                clearingFile.localAgent.address.line3 = localAgentAddress.line3;
                clearingFile.localAgent.address.line4 = localAgentAddress.line4;
                clearingFile.localAgent.address.zipCode = localAgentAddress.zipCode;
              }
            }
          }
        )
      );
    } else {
      return EMPTY;
    }
  }
}
