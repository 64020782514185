import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
  Branch,
  BusinessEntity, Consignor,
  Creditor,
  Debtor, ExportConsignee,
  Exporter,
  Guarantor, Haulier,
  Importer,
  LocalAgent, OverseasAgent,
  RebateUser, Remover,
  Role, Warehouse
} from '../../models/business-entity';
import {RoleMetaData} from '../../models/role-metadata.';
import {Consignee, Supplier} from '../../../digi-common/models/file';

@Component({
  selector: 'digi-app-business-entity-roles',
  templateUrl: './business-entity-roles.component.html',
  styleUrls: ['./business-entity-roles.component.scss']
})
export class BusinessEntityRolesComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() selectedRoleMetaData: RoleMetaData;
  @Input() rolesMetaData: RoleMetaData[];
  @Output() selectRoleMetaData = new EventEmitter();
  @Output() selectActiveRole = new EventEmitter();
  activeRole: Role;
  filteredRoleTypes = [];

  constructor() {
  }

  ngOnInit() {
    if (!this.activeRole) {
      this.selectActiveRoleFromMetaData(this.selectedRoleMetaData);
    }
  }

  ngOnChanges() {
    if (!this.activeRole) {
      this.selectActiveRoleFromMetaData(this.selectedRoleMetaData);
    }
  }

  private selectActiveRoleFromMetaData(activeRoleMetaData: RoleMetaData) {
    if (activeRoleMetaData) {
      this.activeRole = this.businessEntity.roles.find(role => {
        if (role.roleType === activeRoleMetaData.id) {
          return true;
        } else {
          return false;
        }
      });
      if (!this.activeRole) {
        switch (activeRoleMetaData.id) {
          case 6: {
            this.activeRole = new Importer();
            break;
          }
          case 1: {
            this.activeRole = new Debtor();
            break;
          }
          case 12: {
            this.activeRole = new Exporter();
          }
          case 21: {
            this.activeRole = new Supplier();
            break;
          }
          case 9: {
            this.activeRole = new RebateUser();
            break;
          }
          case 11: {
            this.activeRole = new Creditor();
            break;
          }
          case 4: {
            this.activeRole = new LocalAgent();
            break;
          }
          case 18: {
            this.activeRole = new Branch();
            break;
          }
          case 30: {
            this.activeRole = new Guarantor();
            break;
          }
          case 8: {
            this.activeRole = new Remover();
            break;
          }
          case 13: {
            this.activeRole = new Warehouse();
            break;
          }
          case 22: {
            this.activeRole = new Haulier();
            break;
          }
          case 2: {
            this.activeRole = new ExportConsignee();
            break;
          }
          case 5: {
            this.activeRole = new OverseasAgent();
            break;
          }
          case 3: {
            this.activeRole = new Consignor();
            break;
          }
          default: {
            this.activeRole = undefined;
          }
        }
        if (this.activeRole) {
          this.activeRole.roleType = activeRoleMetaData.id;
          this.businessEntity.roles.push(this.activeRole);
          this.businessEntity.roles = [...this.businessEntity.roles];
        }
      }
    }
  }

  getRoleDescription(roleTypeId: number) {
    const roleMetadata = this.rolesMetaData.find(metaData => {
      if (metaData.id === roleTypeId) {
        return true;
      } else {
        return false;
      }
    });
    if (roleMetadata) {
      return roleMetadata.description;
    }
    return undefined;
  }

  selectedRoleMetaDataFromDropDown(value) {
    this.selectActiveRoleFromMetaData(value);
    this.selectActiveRole.emit(this.activeRole);
    this.selectRoleMetaData.emit(value);
  }

  searchRoleMetaData(event) {
    this.filteredRoleTypes = this.rolesMetaData.filter(role => role.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
  }

  onRoleSelected(event: any) {
    const role = event.data;
    if (role) {
      this.selectedRoleMetaData = this.rolesMetaData.find(metaData => {
        if (role.roleType === metaData.id) {
          return true;
        } else {
          return false;
        }
      });
      this.selectActiveRole.emit(role);
      this.selectRoleMetaData.emit(this.selectedRoleMetaData);
    } else {
      this.selectActiveRole.emit(undefined);
      this.selectRoleMetaData.emit(undefined);
    }
  }

  onRoleUnSelected(event: any) {
    this.selectActiveRole.emit(undefined);
    this.selectRoleMetaData.emit(undefined);
  }
}
