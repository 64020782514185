import { NgModule } from '@angular/core';
import { DigiCommonModule } from '../digi-common/digi-common.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CheckboxModule} from 'primeng/checkbox';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {
  CardModule,
  MenubarModule,
  MenuModule,
  PanelMenuModule,
  ScrollPanelModule,
  SelectButtonModule,
  SidebarModule,
  TabMenuModule,
  InputMaskModule
} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DataViewModule} from 'primeng/dataview';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {TreeModule} from 'primeng/tree';
import {TooltipModule} from 'primeng/tooltip';
import {ToolbarModule} from 'primeng/toolbar';
import {FileUploadModule} from 'primeng/fileupload';
import {StepsModule} from 'primeng/steps';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { BusinessEntityLayoutComponent } from './business-entity-layout/business-entity-layout.component';
import { BusinessEntityRolesComponent } from './business-entity-roles/business-entity-roles/business-entity-roles.component';
import { DebtorRoleComponent } from './business-entity-roles/debtor-role/debtor-role.component';
import { LocalAgentRoleComponent } from './business-entity-roles/local-agent-role/local-agent-role.component';
import { CustomsOfficeRoleComponent } from './business-entity-roles/customs-office-role/customs-office-role.component';
import { RebateUserRoleComponent } from './business-entity-roles/rebate-user-role/rebate-user-role.component';
import { CreditorRoleComponent } from './business-entity-roles/creditor-role/creditor-role.component';
import { BranchRoleComponent } from './business-entity-roles/branch-role/branch-role.component';
import { SupplierRoleComponent } from './business-entity-roles/supplier-role/supplier-role.component';
import { RoleAddressesComponent } from './business-entity-roles/role-addresses/role-addresses.component';
import { RoleNominationsComponent } from './business-entity-roles/role-nominations/role-nominations.component';
import { BusinessEntityAddressesComponent } from './business-entity/business-entity-address/business-entity-addresses.component';
import { BusinessEntityDetailsComponent } from './business-entity/business-entity-details/business-entity-details.component';
import { CommonModule } from '@angular/common';
import { COMMON_ROUTES } from '../digi-common/digi-common.routes';
import { BusinessEntityAddressSearchComponent } from './business-entity-address-search/business-entity-address-search.component';
import { BusinessEntityToolbarComponent } from './business-entity-toolbar/business-entity-toolbar.component';
import { ImporterRoleComponent } from './business-entity-roles/importer-role/importer-role.component';
import { GuarantorRoleComponent } from './business-entity-roles/guarantor-role/guarantor-role.component';
import { RemoverRoleComponent } from './business-entity-roles/remover-role/remover-role.component';
import { WarehouseRoleComponent } from './business-entity-roles/warehouse-role/warehouse-role.component';
import { HaulierRoleComponent } from './business-entity-roles/haulier-role/haulier-role.component';
import { ExporterRoleComponent } from './business-entity-roles/exporter-role/exporter-role.component';
import { ExportConsigneeRoleComponent } from './business-entity-roles/export-consignee-role/export-consignee-role.component';
import { OverseasAgentRoleComponent } from './business-entity-roles/overseas-agent-role/overseas-agent-role.component';
import { ConsignorRoleComponent } from './business-entity-roles/consignor-role/consignor-role.component';

@NgModule({
  imports: [
    DigiCommonModule,
    CommonModule,
    RouterModule.forChild(COMMON_ROUTES),
    PanelMenuModule,
    SidebarModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    TableModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    InputTextModule,
    ButtonModule,
    PanelModule,
    CalendarModule,
    DialogModule,
    DropdownModule,
    OverlayPanelModule,
    DataViewModule,
    ScrollPanelModule,
    TabMenuModule,
    TabViewModule,
    CardModule,
    AccordionModule,
    TreeModule,
    TooltipModule,
    ToolbarModule,
    FileUploadModule,
    InputTextareaModule,
    StepsModule,
    RadioButtonModule,
    SelectButtonModule,
    MenubarModule,
    MenuModule,
    InputMaskModule
  ],
  declarations: [
    BusinessEntityLayoutComponent,
    BusinessEntityRolesComponent,
    DebtorRoleComponent,
    LocalAgentRoleComponent,
    CustomsOfficeRoleComponent,
    RebateUserRoleComponent,
    CreditorRoleComponent,
    BranchRoleComponent,
    SupplierRoleComponent,
    RoleAddressesComponent,
    RoleNominationsComponent,
    BusinessEntityAddressesComponent,
    BusinessEntityDetailsComponent,
    BusinessEntityAddressSearchComponent,
    BusinessEntityToolbarComponent,
    ImporterRoleComponent,
    GuarantorRoleComponent,
    RemoverRoleComponent,
    WarehouseRoleComponent,
    HaulierRoleComponent,
    ExporterRoleComponent,
    ExportConsigneeRoleComponent,
    OverseasAgentRoleComponent,
    ConsignorRoleComponent

  ]
})
export class DigiBusinessEntityModule { }
