import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ShareDataService} from '../../../digi-common/services/share-data.service';

@Component({
  selector: 'digi-exports-rail-dashboard',
  templateUrl: './exports-rail-dashboard.component.html',
  styleUrls: ['./exports-rail-dashboard.component.scss']
})
export class ExportsRailDashboardComponent implements OnInit {

  constructor(private router: Router,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService) {
  }

  ngOnInit() {
    this.exportFileBehaviourSubject.addExportFile(this.shareDataService.buildExportFile('RAI'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== 'export/rail/register') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['export', 'rail', 'register']);
    }
  }

}
