import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, EMPTY, Subscription, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ExBondBillOfEntry} from '../../digi-common/models/billOfEntries';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondBillOfEntryService} from '../services/ex-bond-bill-of-entry.service';
import {ExBondFileService} from '../services/ex-bond-file.service';

@Component({
  selector: 'digi-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss']
})
export class GenerateComponent implements OnInit, OnDestroy {
  exBondFile: ExBondFile;
  selectedBillOfEntry: ExBondBillOfEntry;
  subscription: Subscription;
  files: any[];

  constructor(private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private exBondFileService: ExBondFileService,
              private exBondBillOfEntryService: ExBondBillOfEntryService
  ) {
  }

  ngOnInit() {
      const exBondFileObservable = this.exBondFileBehaviourSubject.getExBondFile();
      const billOfEntryObservable = this.billOfEntryBehaviourSubject.getBillOfEntry();
      this.subscription = combineLatest(exBondFileObservable, billOfEntryObservable).subscribe(([exBondFile, billOfEntry]) => {
        this.exBondFile = exBondFile;
        this.selectedBillOfEntry = billOfEntry;
      });
    }

  loadUploadedFiles(event, tabview) {
    if (event.index === 2) {
      if (this.exBondFile && this.selectedBillOfEntry) {
        this.exBondBillOfEntryService.loadUploadedFile(this.selectedBillOfEntry)
          .pipe(
            catchError(err => {
              console.log(err.status);
              if (err.status !== 404) {
                return throwError(err);
              }
              return EMPTY;
            })
          )
          .subscribe(uploadedFiles => {
            if (uploadedFiles && uploadedFiles['_embedded']) {
              this.files = [];
              this.files.push(...uploadedFiles._embedded.supportingDocuments);
              this.files = [...this.files];
            }
          });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
