import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {Freight, TransportDocument} from '../../models/file';

@Component({
  selector: 'digi-hawb-freight-section',
  templateUrl: './hawb-freight-section.component.html',
  styleUrls: ['./hawb-freight-section.component.css']
})
export class HawbFreightSectionComponent implements OnChanges {
  transportDocument: TransportDocument;
  subscription: Subscription;
  @Input() freight: Freight;
  @Input() required = true;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.freight && changes.freight.currentValue) {
      this.freight = changes.freight.currentValue;
    }
  }

}
