import { Observable, throwError } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";

export class DiagnosticTest {
  startTime: string;
  endTime: string;
  duration: number;
  status: boolean;
  statusDescription: string;
  testToRun: Observable<any>;

  constructor(
    public id: number,
    private description: string,
    private testToRunInternal: Observable<any>
  ) {
    this.initialise();
    this.initialiseTestToRun();
  }

  initialise() {
    this.duration = null;
    this.endTime = null;
    this.startTime = null;
    this.status = null;
    this.statusDescription = "Not run yet";
  }

  initialiseTestToRun() {
    if (!this.isImplemented()) {
      console.log("testToRunInternal is NULL");
      this.statusDescription = "Not implemented yet";
    } else {
      let start;
      this.testToRun = new Observable( observer => { // wrap internal observable with another observable to run code before subscribe
        // run before internal observable
        // runs before outer subscribe method called
        console.log("Runs before internal observable: ", this.id);
        this.initialise();
        this.statusDescription = 'Running...';
        this.startTime = this.getCurrentDateTimeFormatted();
        start = Date.now();

        // run internal observer
        return this.testToRunInternal.pipe(
          map(
            result => {
              console.log("Map Completed test: ", this.id);
              this.statusDescription = "Success";
              this.status = true;
              this.endTime = this.getCurrentDateTimeFormatted();
              this.duration = this.findDuration(start);
              return result;
            }
          ),
          catchError(error => {
            console.log("catchError with test: ", this.id);
            this.statusDescription = "Fail";
            this.status = false;
            this.endTime = this.getCurrentDateTimeFormatted();
            this.duration = this.findDuration(start);
            return throwError(error);
          })
        ).subscribe(observer);
      });
    }
  }

  isImplemented(): boolean {
    return this.testToRunInternal !== null;
  }

  getCurrentDateTimeFormatted(): string {
    // return this.datePipe.transform(new Date(), "yyyy/MM/dd hh:mm:ss");
    return new Date().toLocaleString('en-ZA');
  }

  findDuration(start: number): number {
    let end: number = Date.now();
    return end - start;
  }
}
