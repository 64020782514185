import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Country} from '../../models/file';
import {CountryService} from '../../services/country.service';
import {ShareDataService} from '../../services/share-data.service';

@Component({
  selector: 'digi-country-of-origin',
  templateUrl: './country-of-origin.component.html',
  styleUrls: ['./country-of-origin.component.css']
})
export class CountryOfOriginComponent implements OnInit, OnChanges {
  @Input('country') country: Country;
  @Input() isQuotation: boolean;
  @Input() label = 'Country of Origin';
  @Output() onSelect = new EventEmitter;
  selectedCountry: Country;
  filteredCountries: Country[];

  constructor(
    private sharedDataService: ShareDataService,
    private countryService: CountryService
  ) {
  }

  ngOnInit() {
    this.selectedCountry = this.country;

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedCountry = this.country;
  }

  search(event) {
    this.countryService.findCountryStartsWith(event.query).subscribe(data => {
      this.filteredCountries = data;
    });
  }

  select(value) {
    this.onSelect.emit(value);
  }

}

