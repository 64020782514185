<div class="ui-g-12">
  <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
</div>
<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Create AWB" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-fluid ui-g-8">
              <div class="ui-g-4 ui-md-4">
                <div class="ui-inputgroup">
                  <p-autoComplete id="airwaybill" [(ngModel)]="airWaybill.airline" field="label"
                                  [suggestions]="filteredAirWaybills"
                                  (completeMethod)="searchAirWaybill($event)"
                                  (onSelect)="selectAirWaybill($event)"
                                  [autoHighlight]="true"
                                  minLength="2"
                                  [inputStyle]="{color:'white'}"
                                  placeholder="Airline">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.airline.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewAirWaybill()"
                      class="ui-button-secondary" [disabled]="disableButton"></button>
              <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="disableButton"
                      (click)="saveAirWaybill()"></button>
            </div>
          </p-toolbar>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4">
              <p-panel header="Air Waybill Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="boes"
                            [(ngModel)]="airWaybill.airline"
                            field="label"
                            [suggestions]="filteredAirlines"
                            (completeMethod)="searchAirline($event)"
                            (onSelect)="selectAirline($event)"
                            [autoHighlight]="true">
            </p-autoComplete>
        <label for="boes">Airline</label>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
  <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="awbType" [(ngModel)]="airWaybill.awbType"
                                  field="label"
                                  [suggestions]="filteredAwbTypes"
                                  (completeMethod)="searchAwbType($event)"
                                  [autoHighlight]="true" minLength="2" [dropdown]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="awbType">AWB Type</label>
                </span>
</div>
</span></div>
                        <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" [(ngModel)]="airWaybill.agentAccountNo"
                     pInputText class="ui-inputgroup"
                     id="agentAccountNo">
              <label>Agent Account No</label>
            </span>
          </div>
        </span>
                        </div>
                        <div class="ui-g-8 ui-md-8">
        <span class="md-inputfield">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" [(ngModel)]="airWaybill.startNumber"
                     pInputText class="ui-inputgroup"
                     (change)="updateCheckDigits()"
                     (keydown)="airWaybill.waybillNumbers=[]"
                     id="startNumber">
              <label>Start Number</label>
            </span>
          </div>
        </span>
                        </div>
                        <div class="ui-g-4 ui-md-4">
                          {{startNumberCheckDigit}} = check digit
                        </div>
                        <div class="ui-g-8 ui-md-8">
        <span class="md-inputfield">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" [(ngModel)]="airWaybill.endNumber"
                     pInputText class="ui-inputgroup"
                     (change)="updateCheckDigits()"
                     (keydown)="airWaybill.waybillNumbers=[]"
                     id="endNumber">
              <label>End Number</label>
            </span>
          </div>
        </span>
                        </div>
                        <div class="ui-g-4 ui-md-4">
                          {{endNumberCheckDigit}} = check digit
                        </div>
                        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="mawbDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="airWaybill.creationDate">

                  </p-inputMask>
                     <label for="mawbDate">Date Created</label>
                </span>
              </span>
            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-6">
              <p-panel header="Air Waybill Numbers">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <p-dataScroller [value]="airWaybill.waybillNumbers" [rows]="20" [inline]="true" scrollHeight="300px">
                        <ng-template let-waybill pTemplate="item">
                          <div class="ui-fluid">
                            <div class="ui-g">
                              <div class="ui-g-6">
                                {{waybill.number}}
                              </div>
                              <div class="ui-g-2">
                                <button *ngIf="!waybill.waybillReservation" pButton type="button" label="Reserve" (click)="reserveWaybill(waybill)"
                                        class="ui-button-secondary"></button>
                                <button *ngIf="waybill.waybillReservation" pButton type="button" label="Unreserve" (click)="unreserveWaybill(waybill)"
                                        class="ui-button-secondary"></button>
                              </div>
                              <div class="ui-g-2" *ngIf="waybill.waybillReservation">
                                <button pButton type="button" label="Reservation" (click)="showReservation(waybill)"
                                        class="ui-button-secondary"></button>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </p-dataScroller>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 width="425"
                 [rejectLabel]="'No'"
                 [acceptLabel]="'Yes'"
></p-confirmDialog>
<digi-awb-reservation-modal [display]="displayReservationModal"
                            [reservation]="selectedWaybillNumber.waybillReservation"
                            [mawbNo]="selectedWaybillNumber.number"
                            (displayChange)="closeModal()"
                            [airline]="airWaybill.airline"
                            [poolDate]="airWaybill.creationDate"
></digi-awb-reservation-modal>
