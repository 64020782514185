import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, OverseasAgent} from '../../models/business-entity';

@Component({
  selector: 'digi-overseas-agent-role',
  templateUrl: './overseas-agent-role.component.html',
  styleUrls: ['./overseas-agent-role.component.scss']
})
export class OverseasAgentRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() overseasAgent: OverseasAgent;

  constructor() { }

  ngOnInit() {
  }

  selectCountry(country: any) {
    this.overseasAgent.country = country;
  }

  selectCurrency(currency: any) {
    this.overseasAgent.currency = currency;
  }
}
