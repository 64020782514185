import { Component, OnInit, Input } from '@angular/core';
import { Branch, BusinessEntity } from '../../models/business-entity';

@Component({
  selector: 'app-branch-role',
  templateUrl: './branch-role.component.html',
  styleUrls: ['./branch-role.component.scss']
})
export class BranchRoleComponent implements OnInit {
@Input() businessEntity: BusinessEntity;
@Input() branch: Branch;
  constructor() { }

  ngOnInit() {
  }

}
