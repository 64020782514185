import { EventEmitter, OnInit } from '@angular/core';
import { BillOfEntryService } from '../services/bill-of-entry.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import { ImporterService } from '../services/importer.service';
import { AgentService } from '../services/agent.service';
import { BusinessEntity, CustomsTraderType, RoleAddress } from '../../digi-business-entity/models/business-entity';
import { OverlayPanel } from 'primeng/primeng';
import { ForwardingFile } from '../models/ForwardingFile';
import { ForwardingFileService } from '../services/forwarding-file.service';
import { Airline, File, Freight, LocalForwarder, Measures, PreviousBoeDetails, SupplierInvoice, SupplierInvoiceLine, TransportDocument, PortDetail, CustomsValue, DepotTerminal, ForwardFile, Address, ClearingInstruction } from '../models/file';
import { forkJoin } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../subjects/transport-document-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SaveClearingFile } from '../saveClearingFile';
import { BillOfEntryBehaviourSubject } from '../../../subjects/billOfEntry-behaviour-subject';
import { BillOfEntry } from '../models/billOfEntries';
import { NotificationService } from '../services/notification.service';
import { BusinessEntityService } from '../../digi-business-entity/services/business-entity.service';
import { CompanyService } from '../services/company.service';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
var TransportMethods;
(function (TransportMethods) {
    TransportMethods["SC"] = "sea";
    TransportMethods["SE"] = "sea";
    TransportMethods["AC"] = "air";
    TransportMethods["AE"] = "air";
    TransportMethods["RC"] = "road";
    TransportMethods["RE"] = "road";
    TransportMethods["TE"] = "rail";
})(TransportMethods || (TransportMethods = {}));
var ForwardingDashboardComponent = /** @class */ (function () {
    function ForwardingDashboardComponent(localAgentService, importerService, billOfEntryService, router, route, authorisationService, forwardingFileService, clearingFileBehaviourSubject, transportDocumentBehaviourSubject, supplierInvoiceBehaviourSubject, supplierInvoiceLineBehaviourSubject, onSaveClearingFile, billOfEntryBehaviourSubject, messageService, businessEntityService, companyService, clearingInstructionBehaviorSubject) {
        var _this = this;
        this.localAgentService = localAgentService;
        this.importerService = importerService;
        this.billOfEntryService = billOfEntryService;
        this.router = router;
        this.route = route;
        this.authorisationService = authorisationService;
        this.forwardingFileService = forwardingFileService;
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.supplierInvoiceLineBehaviourSubject = supplierInvoiceLineBehaviourSubject;
        this.onSaveClearingFile = onSaveClearingFile;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.messageService = messageService;
        this.businessEntityService = businessEntityService;
        this.companyService = companyService;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.selectedClearingFile = new EventEmitter();
        this.selectedBusinessEntity = new EventEmitter();
        this.filteredBusinessEntities = [];
        this.isNomination = true;
        this.selectedInstructions = [];
        this.router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                _this.indicator = _this.route.snapshot.paramMap.get('indicator');
                _this.loadBillOfEntries();
            }
        });
    }
    ForwardingDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cols = [
            { field: 'fileNumber', header: 'FILE No.', width: '10%' },
            { field: 'masterBill.mawbNo', header: 'MAWB No.', width: '10%' },
            { field: 'houseBills.hawbNo', header: 'HAWB No.', width: '10%' },
            { field: 'houseBills.customsValue.localAmount', header: 'CUSTOMS VALUE', width: '10%' },
            { field: 'cusres.customsStatus', header: 'STATUS', width: '10%' },
            { field: 'houseBills.clearingFileNumber', header: 'CLEARING FILE No', width: '10%' },
        ];
        this.subscription = this.clearingFileBehaviourSubject
            .getClearingFile()
            .subscribe(function (cf) {
            if (cf) {
                _this.clearingFile = cf;
                if (_this.clearingInstructionsSubscription) {
                    _this.clearingInstructionsSubscription.unsubscribe();
                }
                _this.initializeClearingInstructions();
                _this.clearingInstructionsSubscription = _this.clearingInstructionBehaviorSubject
                    .getClearingInstructions()
                    .subscribe(function (ci) {
                    ci = _this.clearingFile.clearingInstructions;
                    if (ci) {
                        _this.selectedInstructions = ci;
                    }
                });
            }
        });
        this.billSubscription = this.billOfEntryBehaviourSubject.getBillOfEntry()
            .subscribe(function (boe) { return _this.billOfEntry = boe; });
        this.transportDocumentBehaviourSubject.getTransportDocuments()
            .subscribe(function (td) { return (_this.selectedTransportDocument = td); });
        this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
            .subscribe(function (si) { return (_this.selectedSupplierInvoice = si); });
        this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
            .subscribe(function (line) { return _this.selectedSupplierInvoiceLine = line; });
        this.forwardingFileService.getTotalForwardingFilesCount().subscribe(function (number) {
            _this.totalRecords = number;
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            console.error('Error fetching total count:', err);
        });
    };
    ForwardingDashboardComponent.prototype.onLazyLoad = function (event) {
        var _this = this;
        this.authorisationService.getLoggedInUser().subscribe(function (user) {
            if (user) {
                _this.loading = true;
                var pageNumber = event.first / event.rows;
                if (user.attributes && user.attributes['branch']) {
                    _this.branchCode = user.attributes['branch'][0];
                }
                _this.forwardingFileService.getAllForwardingFiles(pageNumber, event.rows).subscribe(function (forwardingFiles) {
                    _this.forwardingFiles = forwardingFiles;
                    _this.loading = false;
                }, function (err) {
                    _this.loading = false;
                    console.error('Error fetching forwarding files:', err);
                });
            }
        });
    };
    ForwardingDashboardComponent.prototype.loadBillOfEntries = function () {
        this.onLazyLoad({ first: 0, rows: 2 });
    };
    ForwardingDashboardComponent.prototype.openSearchCriteriaModal = function () {
        this.displaySearchModal = true;
    };
    ForwardingDashboardComponent.prototype.onForwardingFileNumberClick = function (fileNumber, hawbNo) {
        var _this = this;
        var forwardingFile$ = this.forwardingFileService.findByForwardingFileNo(fileNumber);
        var houseBill$ = this.forwardingFileService.getHouseBillByHouseBillNumber(hawbNo);
        houseBill$.subscribe(function (houseBil) {
            if (houseBil.clearingFileNumber) {
                if (fileNumber.startsWith('AF')) {
                    _this.router.navigate(['air', 'register', houseBil.clearingFileNumber, 0]);
                }
            }
            else {
                if (fileNumber.startsWith('AF')) {
                    _this.router.navigate(['/air/register', 'nq']);
                }
                else if (fileNumber.startsWith('SF')) {
                    _this.router.navigate(['/sea/register', 'nq']);
                }
                forkJoin([forwardingFile$, houseBill$]).subscribe(function (_a) {
                    var forwardingFile = _a[0], houseBill = _a[1];
                    if (forwardingFile) {
                        // REGISTRATION -> CLEARING FILE
                        if (fileNumber.startsWith('AF')) {
                            _this.clearingFile.customOffice.districtOfficeCode = 'JSA';
                            _this.clearingFile.customOffice.districtOfficeName = 'JSA - Customs Office JOHANNESBURG INT AIRPORT';
                        }
                        _this.clearingFile.fromForwarding = true;
                        // TRANSPORT DOCUMENTS
                        var td = new TransportDocument();
                        td.airline = forwardingFile.masterBill.airline || new Airline();
                        td.localForwarder = forwardingFile.masterBill.localForwarder || new LocalForwarder();
                        td.mawbNo = forwardingFile.masterBill.mawbNo || '';
                        td.mawbDate = forwardingFile.masterBill.mawbDate || '';
                        td.hawbDate = forwardingFile.masterBill.mawbDate || '';
                        td.flight = forwardingFile.masterBill.flight || '';
                        td.departurePort = forwardingFile.masterBill.departurePort || new PortDetail();
                        td.destinationPort = forwardingFile.masterBill.destinationPort;
                        td.docsIssuedAtPort = forwardingFile.masterBill.docsIssuedAtPort || new PortDetail();
                        td.dischargePort = forwardingFile.masterBill.dischargePort || new PortDetail();
                        td.freight = forwardingFile.masterBill.freight || new Freight();
                        td.customsValue = forwardingFile.masterBill.customsValue || new CustomsValue();
                        td.subMAWBNo = forwardingFile.masterBill.subMAWBNo || '';
                        td.clientRefNo = forwardingFile.masterBill.clientRefNo || '';
                        td.arrivalDate = forwardingFile.masterBill.arrivalDate || '';
                        td.depotTerminal = forwardingFile.masterBill.depotTerminal || new DepotTerminal();
                        td.forwardFile = forwardingFile.forwardFile || new ForwardFile();
                        td.forwardFile.forwardingFileNumber = fileNumber || '';
                        td.destinationPort = forwardingFile.masterBill.destinationPort || new PortDetail();
                        td.finalDestinationPort = forwardingFile.masterBill.finalDestinationPort || new PortDetail();
                        td.straight = typeof forwardingFile.masterBill.straight === 'boolean' ? forwardingFile.masterBill.straight : false;
                        td.marksAndNumbers = forwardingFile.masterBill.marksAndNumbers || [];
                        td.measures = forwardingFile.masterBill.measures || new Measures();
                        td.endorsements = forwardingFile.masterBill.endorsements || [];
                        td.departureDate = forwardingFile.masterBill.departureDate || '';
                        td.rateBasedCharges = [];
                        td.valueBasedCharges = [];
                        td.containers = [];
                        td.previousBOEDetails = new PreviousBoeDetails();
                        td.measures.numberOfPackages = forwardingFile.masterBill.measures.numberOfPackages || 0;
                        td.measures.grossWeight = forwardingFile.masterBill.measures.grossWeight || 0;
                        td.freight.currency = forwardingFile.masterBill.freight.currency || '';
                        td.freight.collectPrepaid = forwardingFile.masterBill.freight.collectPrepaid || '';
                        td.freight.amount = forwardingFile.masterBill.freight.amount || 0;
                        td.depotTerminal = forwardingFile.masterBill.depot || new DepotTerminal();
                        _this.clearingFile.transportDocuments.push(td);
                    }
                    if (houseBill) {
                        if (_this.clearingFile.transportDocuments.length === 0) {
                            _this.clearingFile.transportDocuments.push({ supplierInvoices: [] });
                        }
                        if (!_this.clearingFile.transportDocuments[0].supplierInvoices) {
                            _this.clearingFile.transportDocuments[0].supplierInvoices = [];
                        }
                        if (_this.clearingFile.transportDocuments[0].supplierInvoices.length === 0) {
                            var newSupplierInvoice = {
                                quantity: houseBill.grossWeight || 0,
                                valueBasedCharges: [],
                                userInterfaceDefaults: {},
                                invoiceDate: houseBill.invoiceDate || '',
                                invoiceNumber: houseBill.invoiceNumber || '',
                                totalGrossIncomeValue: houseBill.localAmount || 0,
                                sequence: 0,
                                runningTotals: {},
                                apportionWeightOnLines: false,
                                manualExWarehouseLineCapture: false,
                                supplier: houseBill.supplier || {},
                                currency: forwardingFile.masterBill.freight.currency || {},
                                rateBasedCharges: [],
                                lines: [
                                    {
                                        lineNo: 1,
                                        description: '',
                                        fullDescription: '',
                                        excludeFromCosting: false,
                                        costingUom: {},
                                        countryOfOrigin: {
                                            code: 'ZA ',
                                            name: 'SOUTH AFRICA',
                                            defaultTradeAgreement: {
                                                code: 'SADC      ',
                                                description: 'SADC Trade Agreement 2000                         '
                                            },
                                            tradeAgreements: [
                                                {
                                                    code: 'SADC      ',
                                                    description: 'SADC Trade Agreement 2000                         '
                                                },
                                                {
                                                    code: 'STANDARD  ',
                                                    description: 'No Trade Agreement - Standard Tariffs             '
                                                }
                                            ]
                                        },
                                        lineQuantity: houseBill.numberOfPackages,
                                        lineWeight: houseBill.grossWeight,
                                        partNumber: '',
                                        tariffCode: {
                                            parameters: [],
                                            formula: {
                                                statisticalUOM: '',
                                                description: '',
                                                effectiveDate: '',
                                                computations: [],
                                                rebate: false,
                                                parameters: [],
                                                hasDcc: false,
                                                number: 0,
                                                rebateExcludes12B: false,
                                                chargeString: '',
                                                prodatFormulaNo: '',
                                                free: false,
                                                hasRcc: false,
                                                noOfParameters: 0,
                                            },
                                            checkDigit: 0,
                                            fullDescription: '',
                                            tariffSDesc: '',
                                            code: '',
                                            description: '',
                                            schedule: '',
                                            tariff: false,
                                            tariffID: 0,
                                            dependantCode: '',
                                            dependantCheckDigit: '',
                                            schedulePart: {},
                                            scheduleDescription: '',
                                            calculatedValue: 0,
                                        },
                                        noChargeItem: false,
                                        repair: false,
                                        usedGoods: false,
                                        da59: false,
                                        customsVAT: true,
                                        used: false,
                                        invoiceGrossValue: houseBill.localAmount,
                                        invoiceValue: houseBill.localAmount,
                                        discountPercent: 0,
                                        discountValue: 0,
                                        customsValue: houseBill.localAmount,
                                        tradeAgreement: {
                                            code: 'STANDARD  ',
                                            description: 'No Trade Agreement - Standard Tariffs             '
                                        },
                                        globalRebates: [],
                                        additionalScheduleTariffs: [],
                                        userInterfaceCheckbox: {
                                            tariffMode: false,
                                            quickPart: false
                                        },
                                        instructionIndex: 0,
                                        instructionUUID: _this.createClearingInstruction_UUID(),
                                        certificate: {},
                                        additionalUoms: [],
                                        noOfPacks: 0,
                                        itemsPerPack: 0,
                                        previousBoeDetails: {
                                            sequenceNo: 0,
                                            lineNo: 0,
                                            noOfPacks: 0
                                        }
                                    }
                                ],
                                ucrNumber: '',
                                ucrCreditTerms: '',
                                certificate: {},
                                ucr: {},
                                defaultValues: {
                                    country: {
                                        code: 'ZA',
                                        name: 'SOUTH AFRICA',
                                        defaultTradeAgreement: {
                                            code: 'SADC',
                                            description: 'SADC Trade Agreement 2000',
                                        },
                                        tradeAgreements: [
                                            {
                                                code: 'SADC',
                                                description: 'SADC Trade Agreement 2000',
                                            },
                                            {
                                                code: 'STANDARD',
                                                description: 'No Trade Agreement - Standard Tariffs',
                                            },
                                        ],
                                    },
                                    purchaseTerm: {
                                        code: 'FOB',
                                        description: 'Free on Board',
                                    },
                                },
                            };
                            _this.clearingFile.transportDocuments[0].supplierInvoices.push(newSupplierInvoice);
                        }
                        // REGISTRATION
                        _this.clearingFile.localAgent = houseBill.localAgent;
                        _this.clearingFile.localAgent.customsRegCode = houseBill.localAgent.customsRegCode || '';
                        _this.clearingFile.importer = houseBill.importer;
                        _this.clearingFile.importer.customsCode = houseBill.importer.customsCode;
                        _this.clearingFile.importer.registrationType = houseBill.importer.registrationType || new CustomsTraderType();
                        _this.clearingFile.importer.idNumber = houseBill.importer.idNumber || '';
                        _this.clearingFile.importer.passportNumber = houseBill.importer.passportNumber || '';
                        var ftd = _this.clearingFile.transportDocuments[0];
                        ftd.hawbNo = houseBill.houseBillNumber;
                    }
                    _this.businessEntityService.findBusinessEntityStartsWith(houseBill.importer.name).subscribe(function (bee) {
                        if (houseBill.importer.name === bee[0].name) {
                            _this.messageService.errorNotify('Importer already exists.Please check the business entities');
                        }
                    });
                    // create importer
                    var be = new BusinessEntity();
                    _this.businessEntity = be;
                    _this.businessEntity.registeredName = houseBill.importer.name;
                    _this.businessEntity.name = houseBill.importer.name;
                    var role = { roleType: 6 };
                    _this.businessEntity.roles = [];
                    _this.businessEntity.roles.push(role);
                    _this.businessEntity.roles = _this.businessEntity.roles.slice();
                    var address = Object.assign({}, houseBill.importer.address);
                    _this.businessEntityService.saveBusinessEntity(_this.businessEntity)
                        .subscribe(function (bee) {
                        _this.businessEntity = bee;
                        if (address && address.line1) {
                            _this.businessEntity.addresses = [];
                            _this.businessEntity.addresses.push(address);
                            _this.businessEntityService.saveBusinessEntity(_this.businessEntity).subscribe(function (updatedBE) {
                                _this.businessEntity = updatedBE;
                                var beRole = _this.businessEntity.roles.find(function (el) { return el.roleType === 6; });
                                var roleAddress = new RoleAddress();
                                roleAddress.address = _this.businessEntity.addresses[0];
                                roleAddress.addressType = { id: 'CUS', description: 'Customs Address' };
                                beRole.roleAddresses = [roleAddress];
                                _this.businessEntityService.saveBusinessEntity(_this.businessEntity).subscribe(function (beWithRoleAddress) {
                                    _this.businessEntity = beWithRoleAddress;
                                    _this.clearingFile.importer.code = _this.businessEntity.code;
                                    _this.onSaveClearingFile.saveClearingFile(_this.clearingFile, _this.selectedTransportDocument, _this.selectedSupplierInvoice, _this.selectedSupplierInvoiceLine).subscribe(function (value) {
                                        _this.messageService.successNotify('Importer Saved');
                                    });
                                    _this.select(_this.businessEntity);
                                });
                            });
                        }
                    }, function (error) {
                        _this.messageService.errorNotify(error.error.message, [error.error.errors]);
                    });
                    // create supplier
                    var be1 = new BusinessEntity();
                    _this.businessEntity = be1;
                    _this.businessEntity.registeredName = houseBill.supplier.name;
                    _this.businessEntity.name = houseBill.supplier.name;
                    var role1 = { roleType: 21 };
                    _this.businessEntity.roles = [];
                    _this.businessEntity.roles.push(role1);
                    _this.businessEntity.roles = _this.businessEntity.roles.slice();
                    var address1 = Object.assign({}, houseBill.supplier.address);
                    _this.businessEntityService.saveBusinessEntity(_this.businessEntity)
                        .subscribe(function (bee) {
                        _this.businessEntity = bee;
                        if (address1 && address1.line1) {
                            _this.businessEntity.addresses = [];
                            _this.businessEntity.addresses.push(address1);
                            _this.businessEntityService.saveBusinessEntity(_this.businessEntity).subscribe(function (updatedBE) {
                                _this.businessEntity = updatedBE;
                                var beRole = _this.businessEntity.roles.find(function (el) { return el.roleType === 21; });
                                var roleAddress = new RoleAddress();
                                roleAddress.address = _this.businessEntity.addresses[0];
                                roleAddress.addressType = { id: 'CUS', description: 'Customs Address' };
                                beRole.roleAddresses = [roleAddress];
                                _this.businessEntityService.saveBusinessEntity(_this.businessEntity).subscribe(function (beWithRoleAddress) {
                                    _this.businessEntity = beWithRoleAddress;
                                    _this.clearingFile.transportDocuments[0].supplierInvoices[0].supplier.code = _this.businessEntity.code;
                                    _this.onSaveClearingFile.saveClearingFile(_this.clearingFile, _this.selectedTransportDocument, _this.selectedSupplierInvoice, _this.selectedSupplierInvoiceLine).subscribe(function (value) {
                                        _this.messageService.successNotify('Supplier Saved');
                                    });
                                    _this.select(_this.businessEntity);
                                });
                            });
                        }
                    }, function (error) {
                        _this.messageService.errorNotify(error.error.message, [error.error.errors]);
                    });
                    _this.onSaveClearingFile.saveClearingFile(_this.clearingFile, _this.selectedTransportDocument, _this.selectedSupplierInvoice, _this.selectedSupplierInvoiceLine).subscribe(function (value) {
                        _this.saveConfirmation();
                        forwardingFile.houseBills.forEach(function (hb) {
                            if (hb.hawbNo === houseBill.houseBillNumber) {
                                hb.clearingFileNumber = _this.clearingFile.clearingFileNumber;
                                houseBill.clearingFileNumber = _this.clearingFile.clearingFileNumber;
                            }
                        });
                        _this.forwardingFileService.save(forwardingFile).subscribe(function (fd) {
                            _this.saveConfirmation();
                        });
                    });
                });
            }
        });
    };
    ForwardingDashboardComponent.prototype.saveConfirmation = function () {
        this.messageService.successNotify('File Saved Successfully');
    };
    ForwardingDashboardComponent.prototype.addFailure = function (msg) {
        this.messageService.errorNotify('Warning', msg);
    };
    ForwardingDashboardComponent.prototype.initializeClearingInstructions = function () {
        var _this = this;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.defaultClearingInstruction) {
                    _this.clearingFile.clearingInstructions[0].procedureCategoryCode = 'A';
                    // tslint:disable-next-line:max-line-length
                    _this.clearingFile.clearingInstructions[0].pcDescription = 'Home use (including goods intended for home use for which there is a relief of duties and taxes).';
                    _this.clearingFile.clearingInstructions[0].customsProcedureCode = 11;
                    // tslint:disable-next-line:max-line-length
                    _this.clearingFile.clearingInstructions[0].customsProcDesc = 'Clearance of goods for Home Use, and free circulation. [Note: includes imported goods, exempt from VAT).';
                    // Initialize customsPurpose
                    _this.clearingFile.clearingInstructions[0].customsPurpose = {
                        customsPurposeCode: 'DP',
                        customsPurposeName: 'Duty Paid',
                        customsPurposeFile: {
                            fileTypeId: 2,
                            documentCode: 'DA500',
                            vocDocumentCode: 'DA504'
                        }
                    };
                    _this.clearingFile.clearingInstructions[0].destination = {
                        code: 'ZA',
                        name: 'South Africa',
                        label: 'ZA : South Africa'
                    };
                    _this.clearingFile.clearingInstructions[0].customVatRequired = true;
                    _this.clearingFile.clearingInstructions[0].consolidateBoeLines = false;
                    _this.clearingFile.clearingInstructions[0].transportMethod = {
                        method: 'AIR',
                        code: '4',
                        description: 'AIR'
                    };
                    _this.clearingFile.clearingInstructions[0].customVatRequired = true;
                    _this.clearingFile.clearingInstructions[0].uuid = _this.createClearingInstruction_UUID();
                    _this.clearingInstructionBehaviorSubject.addClearingInstructions(_this.clearingFile.clearingInstructions);
                }
            }
        });
    };
    ForwardingDashboardComponent.prototype.createClearingInstruction_UUID = function () {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };
    ForwardingDashboardComponent.prototype.select = function (value) {
        this.businessEntity = value;
    };
    return ForwardingDashboardComponent;
}());
export { ForwardingDashboardComponent };
