import { BehaviorSubject, Subject } from 'rxjs';
import { ShareDataService } from '../app/digi-common/services/share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/services/share-data.service";
var ForwardingFileBehaviourSubject = /** @class */ (function () {
    function ForwardingFileBehaviourSubject(shareDataService) {
        this.shareDataService = shareDataService;
        this.forwardingFileSubject = new BehaviorSubject(this.shareDataService.buildForwardingFile());
        this.debtorsSubject = new Subject();
    }
    ForwardingFileBehaviourSubject.prototype.addForwardingFile = function (forwardingFile) {
        this.forwardingFileSubject.next(forwardingFile);
    };
    ForwardingFileBehaviourSubject.prototype.getForwardingFile = function () {
        return this.forwardingFileSubject.asObservable();
    };
    ForwardingFileBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function ForwardingFileBehaviourSubject_Factory() { return new ForwardingFileBehaviourSubject(i0.inject(i1.ShareDataService)); }, token: ForwardingFileBehaviourSubject, providedIn: "root" });
    return ForwardingFileBehaviourSubject;
}());
export { ForwardingFileBehaviourSubject };
