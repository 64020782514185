import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Observable} from 'rxjs';
import {CostingFile, CostingItem} from '../models/costingFile';
import {ClearingFile} from '../models/clearingFile';
import {Invoice} from '../models/financials';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CostingFileService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  saveCostingFile(costingFile: CostingFile, clearingFile: ClearingFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    costingFile.clearingFile = clearingFile._links.self.href;
    if (costingFile._links && costingFile._links.self) {
      return this.http.put(costingFile._links.self.href, JSON.stringify(costingFile), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/costingFiles', JSON.stringify(costingFile), {headers});
    }
  }

  getCostingFiles(id: string): Observable<CostingFile[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/costingFiles/search/findByClearingFile_Id?id=` + id)
      .pipe(
        map(response => response['_embedded'].costingFiles)
      );
  }

  findCostingItemByCode(code): Observable<CostingItem[]> {
    return this.http.get(`${this.config.apiEndpoint}api/costingItems/search/findByCode?code=` + code)
      .pipe(
        map(response => response['_embedded'].costingItems)
      );
  }

  loadFiles(id: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/costingFiles/` + id + '/costingSheet');
  }
}
