<div class="ui-g ui-fluid">
  <div class="ui-g-12">
    <p-panel>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-1">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">After</span>
            </div>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='exports'">
            <span class="md-inputfield">
              <input type="text" value="{{changesOnBillOfEntry?.totalCustomsValue}}"
                     pInputText readonly>
              <label>FOB</label>
            </span>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" value="{{changesOnBillOfEntry?.cifcValue}}"
                     pInputText readonly>
              <label>C.I.F.&C</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{changesOnBillOfEntry?.totalCustomsValue}}" readonly>
              <label>Customs Value</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{changesOnBillOfEntry?.totalCustomsDuty}}" readonly>
              <label>Customs Duty</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{currSch12b}}" readonly>
              <label>Sch 1 pt 2 B</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{changesOnBillOfEntry?.totalCustomsVAT}}" readonly>
              <label>Value Added Tax</label>
            </span>
          </div>
          <span class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{changesOnBillOfEntry?.totalAmountDue}}" readonly>
              <label>Amount Due</label>
            </span>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-1">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">Before</span>
            </div>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='exports'">
            <span class="md-inputfield">
              <input type="text" value="{{previousBillOfEntry?.totalCustomsValue}}"
                     pInputText readonly>
              <label>FOB</label>
            </span>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" value="{{previousBillOfEntry?.cifcValue}}"
                     pInputText readonly>
              <label>C.I.F.&C</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{previousBillOfEntry?.totalCustomsValue}}" readonly>
              <label>Customs Value</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{previousBillOfEntry?.totalCustomsDuty}}" readonly>
              <label>Customs Duty</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{prevSch12b}}" readonly>
              <label>Sch 1 pt 2 B</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{previousBillOfEntry?.totalCustomsVAT}}" readonly>
              <label>Value Added Tax</label>
            </span>
          </div>
          <span class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{previousBillOfEntry?.totalAmountDue}}" readonly>
              <label>Amount Due</label>
            </span>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-1">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">Difference</span>
            </div>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='exports'">
            <span class="md-inputfield">
              <input type="text" value="{{differenceValues?.diffCustomValue |
                number}}"
                     pInputText readonly>
              <label>FOB</label>
            </span>
          </div>
          <div class="ui-g-1" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" value="{{differenceValues?.diffCIFCValue |
                number}}"
                     pInputText readonly>
              <label>C.I.F.&C</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{differenceValues?.diffCustomValue | number}}" readonly>
              <label>Customs Value</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{differenceValues?.diffCustomsDuty | number}}" readonly>
              <label>Customs Duty</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{differenceValues?.diffSch12b | number}}" readonly>
              <label>Sch 1 pt 2 B</label>
            </span>
          </div>
          <div class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{differenceValues?.diffCustomsVAT | number}}"
                     readonly>
              <label>Value Added Tax</label>
            </span>
          </div>
          <span class="ui-g-2" *ngIf="fileType==='imports'">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{differenceValues?.diffAmountDue | number}}" readonly>
              <label>Amount Due</label>
            </span>
          </span>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-6">
    <p-panel header="Bill Of Entry">
      <div class="ui-g ui-fluid">
        <div class="ui-g-6">
          <digi-boe-payment-details [fileType]="fileType"></digi-boe-payment-details>
        </div>
        <div class="ui-g-6">
          <digi-boe-package-section></digi-boe-package-section>
          <digi-boe-bank-details [isVoC]="true"></digi-boe-bank-details>
        </div>
        <div class="ui-g-12 ui-md-6" *ngIf="hasRemovalTransportMethod()">
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedBillOfEntry.bondNo"
                     readonly>
              <label> Bond Number</label>
            </span>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-md-6">
    <div class="ui-g-12 ui-md-12">
      <p-panel>
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-mi-6">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedBillOfEntry.caseNumber">
              <label>Amending case No</label>
            </span>
          </div>
          <div class="ui-g-12 ui-mi-6">
            <span class="md-inputfield">
              <input type="text"
                     pInputText value="0" readonly>
              <label>Amending version No</label>
            </span>
          </div>
        </div>
    </p-panel>
  </div>
    <div class="ui-g-12 ui-md-12">
      <digi-boe-permits-section></digi-boe-permits-section>
    </div>
  </div>

</div>
