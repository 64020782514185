<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Ocean BOL" [selected]="true">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator"
                                 (createNew)="createNewVoyageMasterFile()"
                                 (save)="saveVoyageFile()"
                                 (updateVoyageFiles)="updateVoyageFiles($event)"
                                 (updateVoyageFile)="updateVoyageFile($event)">

        </digi-ships-agent-header>
        <div class="ui-g-12">
          <p-table [columns]="[{}]" [value]="voyageFile.voyageMasterFiles" [(selection)]="voyageMasterFile"
                   dataKey="voyageMasterFileNo"
                   [paginator]="true" [rows]="4"
                   selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th>MBOL No.</th>
                <th>Voyage Master File No.</th>
                <th>Overseas Agent</th>
                <th>Local Forwarder</th>
                <th>SOB Date</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-voyageMasterFile>
              <tr [pSelectableRow]="voyageMasterFile">
                <td class="ui-resizable-column">
                  <label>{{voyageMasterFile.bolNo}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{voyageMasterFile.voyageMasterFileNo}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{voyageMasterFile.overseasAgent?.code}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{voyageMasterFile.localForwarder?.code}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{voyageMasterFile.sobDate}}</label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Voyage Information">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" class="ui-inputgroup" pInputText
                                       [(ngModel)]="voyageMasterFile.voyageMasterFileNo" disabled>
                                <label>Voyage Master File No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup" pInputText
                                       [(ngModel)]="voyageMasterFile.bolNo">
                                <label>Ocean BOL No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-inputMask id="sobDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                           [(ngModel)]="voyageMasterFile.sobDate">
                              </p-inputMask>
                              <label for="sobDate">SOB Date</label>
                            </span>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-g-nopad">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="oceanLine"
                                                [(ngModel)]="voyageMasterFile.oceanLineDetails.oceanLine"
                                                field="label"
                                                [suggestions]="filteredOceanLines"
                                                (completeMethod)="searchOceanLines($event)"
                                                [autoHighlight]="true"
                                                minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}:{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="oceanLine">Master Cargo Carrier</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-g-nopad">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="carrierCode"
                                                [(ngModel)]="voyageMasterFile.oceanLineDetails.carrierCode"
                                                field="label"
                                                [suggestions]="filteredOceanLines"
                                                (completeMethod)="searchOceanLines($event)"
                                                [autoHighlight]="true" minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}:{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="oceanLine">Carrier</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.voyageNo">
                                <label>Voyage No.</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="voyageMasterFile.voyageDetails.customsRadioCallSign"
                                                field="vesselName"
                                                [suggestions]="filteredCustomsRadioCallSigns"
                                                (completeMethod)="searchRadioCallSigns($event, 'vesselName')"
                                                [autoHighlight]="true" minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.vesselName}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Vessel Name</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="voyageMasterFile.voyageDetails.customsRadioCallSign"
                                                field="radioCallSign"
                                                [suggestions]="filteredCustomsRadioCallSigns"
                                                (completeMethod)="searchRadioCallSigns($event, 'radioCallSign')"
                                                [autoHighlight]="true" minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.radioCallSign}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Radio Call Sign</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-inputMask id="departureDate"
                                           mask="99/99/9999"
                                           slotChar="dd/mm/yyyy"
                                           [(ngModel)]="voyageMasterFile.departureDate">
                              </p-inputMask>
                              <label for="departureDate">E.T.S</label>
                            </span>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-inputMask id="arrivalDate"
                                           mask="99/99/9999"
                                           slotChar="dd/mm/yyyy"
                                           [(ngModel)]="voyageMasterFile.arrivalDate">
                              </p-inputMask>
                              <label for="arrivalDate">E.T.A</label>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Port Information">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port
                            label='Departure Port'
                            transportMethod="SEA"
                            [isRequired]="false"
                            (portChange)="selectDeparturePort($event)"
                            [port]="voyageMasterFile.departurePort">
                          </digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port
                            label='Docs Issued At'
                            transportMethod="SEA"
                            [isRequired]="false"
                            (portChange)="selectDocsIssuedAtPort($event)"
                            [port]="voyageMasterFile.docsIssuedAtPort">
                          </digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port
                            label='Discharge Port'
                            transportMethod="SEA"
                            [isRequired]="false"
                            (portChange)="selectDischargePort($event)"
                            [port]="voyageMasterFile.dischargePort">
                          </digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port
                            label='Destination Port'
                            transportMethod="SEA"
                            [isRequired]="false"
                            (portChange)="selectDestinationPort($event)"
                            [port]="voyageMasterFile.destinationPort">
                          </digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.clientRef">
                                <label>Client Reference</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="callPurpose"
                                                [(ngModel)]="voyageMasterFile.callPurpose"
                                                [suggestions]="callPurposes"
                                                [dropdown]="true"
                                                (completeMethod)="callPurposes = ['Loading', 'Unloading']">
                                </p-autoComplete>
                                <label for="callPurpose">Call Purpose</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Freight">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-autoComplete id="currency"
                                              [(ngModel)]="voyageMasterFile.freight.currency" field="label"
                                              [dropdown]="true"
                                              [suggestions]="filteredCurrencies"
                                              (completeMethod)="searchCurrencies($event)"
                                              [autoHighlight]="true"
                                              minLength="1"
                                              [forceSelection]="true">
                                <ng-template let-option pTemplate="item">
                                  <div>{{option.label}}</div>
                                </ng-template>
                              </p-autoComplete>
                              <label for="currency">Currency</label>
                            </span>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.freight.amount">
                                <label>Amount</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-autoComplete id="collectPrepaid"
                                              [(ngModel)]="voyageMasterFile.freight.collectPrepaid"
                                              field="label" [dropdown]="true"
                                              [suggestions]="filteredCollectPrepaids"
                                              (completeMethod)="searchCollectPrepaid($event)"
                                              [autoHighlight]="true" minLength="1"
                                              [forceSelection]="true"
                                              required=true>
                                <ng-template let-option pTemplate="item">
                                  <div>{{option.code}}: {{option.description}}</div>
                                </ng-template>
                              </p-autoComplete>
                              <label for="collectPrepaid">Collect/Prepaid</label>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Weights & Measures">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.measures.numberOfPackages">
                                <label>No. Of Packages</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.measures.noOfContainers">
                                <label>No. Of Containers</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.measures.volume">
                                <label>CBM</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.measures.grossWeight">
                                <label>Gross Weight</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="voyageMasterFile.measures.chargeableWeight">
                                <label>Chargeable Weight (Freight Tons)</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Customs Office">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-customs-office [customOffice]="voyageMasterFile.customsOffice"
                                               [isRequired]=true
                                               (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Terminal & Depot">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="voyageMasterFile.depot"
                                                field="label"
                                                [suggestions]="filteredDepotTerminals"
                                                (completeMethod)="searchDepotTerminal($event, 'D')"
                                                [autoHighlight]="true"
                                                minLength="1"
                                                [dropdown]="true">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.label}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Unpack Depot</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="voyageMasterFile.terminal" field="label"
                                                [suggestions]="filteredDepotTerminals"
                                                (completeMethod)="searchDepotTerminal($event, 'T')"
                                                [autoHighlight]="true" minLength="1"
                                                [dropdown]="true"
                                                [forceSelection]="true">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.label}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Discharge Terminal</label>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel *ngIf="voyageFile.type === 'import'" header="Importer Details">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.consignee"
                                                      [roleType]="6"
                                                      label="Importer"
                                                      (updateBusinessEntity)="updateConsignee($event, 6)"
                                                      [displayNewButton]="true"
                ></digi-manifest-business-entity-search>
              </p-panel>
              <p-panel *ngIf="voyageFile.type === 'export'" header="Exporter Details">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.consignor"
                                                      [roleType]="12"
                                                      label="Exporter"
                                                      [displayNewButton]="true"
                                                      (updateBusinessEntity)="updateConsignor($event, 12)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel *ngIf="voyageFile.type === 'import'" header="Supplier Details">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.consignor"
                                                      [roleType]="21"
                                                      label="Supplier"
                                                      [businessEntities]="filteredSuppliers"
                                                      [dropdown]="true"
                                                      [displayNewButton]="displayNewButton"
                                                      (updateBusinessEntity)="updateConsignor($event, 21)"
                ></digi-manifest-business-entity-search>
              </p-panel>
              <p-panel *ngIf="voyageFile.type === 'export'" header="Consignee Details">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.consignee"
                                                      [roleType]="2"
                                                      label="Consignee"
                                                      [businessEntities]="filteredExportConsignees"
                                                      [dropdown]="true"
                                                      [displayNewButton]="displayNewButton"
                                                      (updateBusinessEntity)="updateConsignee($event, 2)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Debtor">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.debtor"
                                                      [roleType]="1"
                                                      label="Debtor"
                                                      [businessEntities]="filteredDebtors"
                                                      [dropdown]="true"
                                                      [displayNewButton]="displayNewButton"
                                                      (updateBusinessEntity)="updateDebtor($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Overseas Details">
                <digi-manifest-business-entity-search [businessEntity]="voyageMasterFile.overseasAgent"
                                                      [roleType]="5"
                                                      label="Overseas Agent"
                                                      [businessEntities]="filteredOverseasAgents"
                                                      [dropdown]="true"
                                                      [displayNewButton]="displayNewButton"
                                                      (updateBusinessEntity)="updateOverseasAgent($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Local Forwarder Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="localForwarder"
                                                [(ngModel)]="voyageMasterFile.localForwarder" field="name"
                                                [suggestions]="filteredLocalForwarders"
                                                (completeMethod)="searchAgent($event)"
                                                [autoHighlight]="true"
                                                minLength="3">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="localForwarder">Local Forwarder</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Release Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="releaseAgent" [(ngModel)]="voyageMasterFile.releaseAgent"
                                                field="name"
                                                [suggestions]="filteredLocalForwarders"
                                                (completeMethod)="searchAgent($event)"
                                                [autoHighlight]="true"
                                                minLength="3">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="releaseAgent">Release Agent</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </span>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" class="ui-inputgroup" pInputText [(ngModel)]="voyageMasterFile.releaseTo">
                                <label>Release To</label>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-float-label">
                              <p-inputMask id="releaseDate"
                                           mask="99/99/9999 99:99"
                                           slotChar="dd/mm/yyyy HH:mm"
                                           [(ngModel)]="voyageMasterFile.releaseDate">
                              </p-inputMask>
                              <label for="releaseDate">Release Date & Time</label>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Commodity">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="voyageMasterFile.commodity" [rows]="5" [cols]="50"
                                    placeholder="Commodity"
                                    style="resize: none">
                          </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Containers">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator"
                                 (createNew)="createNewContainer()"
                                 (save)="saveVoyageFile()"
                                 (updateVoyageFiles)="updateVoyageFiles($event)"
                                 (updateVoyageFile)="updateVoyageFile($event)">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text"
                       class="ui-inputgroup"
                       pInputText
                       style="color: white"
                       [ngModel]="voyageMasterFile.voyageMasterFileNo">
                <label style="color: white">Voyage Master File No.</label>
              </span>
            </div>
          </span>
        </digi-ships-agent-header>
        <digi-ships-agent-containers [voyageMasterFile]="voyageMasterFile"
                                     [newContainer]="newContainer"></digi-ships-agent-containers>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Marks & Numbers">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator"
                                 (save)="saveVoyageFile()"
                                 (updateVoyageFiles)="updateVoyageFiles($event)"
                                 (updateVoyageFile)="updateVoyageFile($event)">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text"
                       class="ui-inputgroup"
                       pInputText
                       style="color: white"
                       [ngModel]="voyageMasterFile.voyageMasterFileNo">
                <label style="color: white">Voyage Master File No.</label>
              </span>
            </div>
          </span>
        </digi-ships-agent-header>
        <div class="ui-g-12">
          <digi-manifest-marks-and-numbers [marksAndNumbers]="voyageMasterFile.marksAndNumbers"
                                           (updateMarksAndNumbers)="updateMarksAndNumbers($event)"></digi-manifest-marks-and-numbers>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Packages">
      <ng-template pTemplate="content">
        <digi-ships-agent-header [voyageFile]="voyageFile"
                                 [indicator]="indicator"
                                 (createNew)="createNewPackage()"
                                 (save)="saveVoyageFile()"
                                 (updateVoyageFiles)="updateVoyageFiles($event)"
                                 (updateVoyageFile)="updateVoyageFile($event)">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text"
                       class="ui-inputgroup"
                       pInputText
                       style="color: white"
                       [ngModel]="voyageMasterFile.voyageMasterFileNo">
               <label style="color: white">Voyage Master File No.</label>
             </span>
            </div>
          </span>
        </digi-ships-agent-header>
        <digi-ships-agent-packages [voyageMasterFile]="voyageMasterFile"
                                   [newPackage]="newPackage"></digi-ships-agent-packages>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
