import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {File, Reference} from '../../models/file';

@Component({
  selector: 'digi-registration-references-section',
  templateUrl: './registration-references-section.component.html',
  styleUrls: ['./registration-references-section.component.css']
})
export class RegistrationReferencesSectionComponent implements OnChanges {
  @Input() file: File;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && changes.file.currentValue) {
      this.file = changes.file.currentValue;
      if (!this.file.reference) {
        this.file.reference = new Reference();
      }
    }
  }
}
