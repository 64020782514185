<div class="ui-g ui-fluid">
  <div class="ui-g-8">
    <button pButton type="button" label="Provisional Payment Type" (click)="addAdditionalSchedule()"></button>
  </div>
</div>
<p-dialog header="Provisional Payment Types Available" [(visible)]="display" [responsive]="true" [width]="600"
  [minWidth]="200" [minY]="70" [baseZIndex]="10000" [contentStyle]="{ 'max-height': '200px' }" #additionalSchedule>
  <p-table [columns]="cols"  [value]="scheduleParts"  [(selection)]="selectedSchedules"
           selectionMode="multiple"
           dataKey="code">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width"/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td class="ui-resizable-column">
          <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
        </td>
        <td class="ui-resizable-column">{{ rowData?.code }}</td>
        <td class="ui-resizable-column">{{ rowData?.description}}</td>
        <td>
          <input type="text" style="height: 20px;" pInputText [(ngModel)]="rowData.value"/>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button pButton type="button" label="select" [disabled]="!selectedSchedules?.length" (click)="select()"></button>
    <button pButton type="button" label="cancel" (click)="close()"></button>
  </p-footer>
</p-dialog>
<p-table [columns]="cols"
         [value]="selectedBillOfEntryLine?.additionalSchedules"
         selectionMode="single" [(selection)]="selectedSchedule">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Code</th>
      <th>Value</th>
      <th>Exclude From Duties</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr [pSelectableRow]="rowData">
      <td>
        {{rowData?.code}}
      </td>
      <td>
        {{rowData?.value}}
      </td>
      <td>
        <p-checkbox binary="true" [(ngModel)]="rowData.excludedFromDuties"></p-checkbox>
      </td>
    </tr>
  </ng-template>
</p-table>

