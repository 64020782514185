/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i2 from "primeng/components/steps/steps";
import * as i3 from "./forwarding-steps-navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../subjects/forwarding-file-behaviour-subject";
import * as i6 from "../../services/notification.service";
import * as i7 from "../../services/forwarding-file-validation.service";
import * as i8 from "../../services/forwarding-file.service";
var styles_ForwardingStepsNavigationComponent = ["\n    .ui-steps-item {\n      width: 16.6%\n    }\n  "];
var RenderType_ForwardingStepsNavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForwardingStepsNavigationComponent, data: {} });
export { RenderType_ForwardingStepsNavigationComponent as RenderType_ForwardingStepsNavigationComponent };
export function View_ForwardingStepsNavigationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Steps_0, i1.RenderType_Steps)), i0.ɵdid(1, 49152, null, 0, i2.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"] }, { activeIndexChange: "activeIndexChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ForwardingStepsNavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-forwarding-steps-navigation", [], null, null, null, View_ForwardingStepsNavigationComponent_0, RenderType_ForwardingStepsNavigationComponent)), i0.ɵdid(1, 245760, null, 0, i3.ForwardingStepsNavigationComponent, [i4.Router, i5.ForwardingFileBehaviourSubject, i6.NotificationService, i7.ForwardingFileValidationService, i8.ForwardingFileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForwardingStepsNavigationComponentNgFactory = i0.ɵccf("digi-forwarding-steps-navigation", i3.ForwardingStepsNavigationComponent, View_ForwardingStepsNavigationComponent_Host_0, { transportMethod: "transportMethod" }, {}, []);
export { ForwardingStepsNavigationComponentNgFactory as ForwardingStepsNavigationComponentNgFactory };
