<div class="ui-g-12">
  <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false" (activeIndexChange)="saveVoyageFile()"></p-steps>
</div>
<ng-container *ngIf="view==='register'">
  <div class="ui-g-12">
    <p-tabView orientation="left" styleClass="stepMenu">
      <p-tabPanel header="Register" [selected]="true">
        <ng-template pTemplate="content">
          <digi-ships-agent-header [voyageFile]="voyageFile"
                                   [indicator]="indicator"
                                   (updateVoyageFiles)="updateVoyageFiles($event)"
                                   (updateVoyageFile)="updateVoyageFile($event)"
                                   (createNew)="createNewVoyageFile()"
                                   (save)="saveVoyageFile()"></digi-ships-agent-header>
          <div class="ui-g-12">
            <p-table [columns]="[{}]" [value]="voyageFiles" [(selection)]="voyageFile" dataKey="voyageFileNo"
                     [paginator]="true" [rows]="4"
                     selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ocean Line</th>
                  <th>Voyage</th>
                  <th>Voyage File No</th>
                  <th>Vessel Name</th>
                  <th>Port From</th>
                  <th>Port To</th>
                  <th>Departure</th>
                  <th>Arrival</th>
                  <th style="width:4em"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-voyageFile let-rowIndex="rowIndex">
                <tr [pSelectableRow]="voyageFile">
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.oceanLine?.code}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.voyageNo}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.voyageFileNo}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.radioCallSign?.vesselName}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.departurePort?.code}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.destinationPort?.code}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.departureDate}}</label>
                  </td>
                  <td class="ui-resizable-column">
                    <label>{{voyageFile.arrivalDate}}</label>
                  </td>
                  <td>
                    <button pButton type="button" icon="fa fa-times" class="danger"
                            (click)="deleteVoyageFile(rowIndex)" pTooltip="Delete"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <div class="ui-g">
              <div class="ui-g-4">
                <p-panel header="Voyage Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-g-nopad pt-2">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="oceanLine" [(ngModel)]="voyageFile.oceanLine"
                                  field="label"
                                  [suggestions]="filteredOceanLines"
                                  (completeMethod)="searchOceanLines($event)"
                                  [autoHighlight]="true" minLength="2"
                                  required=true
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}:{{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="oceanLine">Ocean Line</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
          </span>
                          </div>
                          <div  class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="voyageFile.voyageNo"
                           required>
                   <label>Voyage No.</label>
                 </span>
              </div>
          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="voyageFile.radioCallSign"
                                field="vesselName"
                                [suggestions]="filteredCustomsRadioCallSigns"
                                (completeMethod)="searchRadioCallSigns($event, 'vesselName')"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.vesselName}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Vessel Name</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="voyageFile.radioCallSign"
                                field="radioCallSign"
                                [suggestions]="filteredCustomsRadioCallSigns"
                                (completeMethod)="searchRadioCallSigns($event, 'radioCallSign')"
                                [autoHighlight]="true" minLength="2"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.radioCallSign}}</div>
                  </ng-template>
                </p-autoComplete>
                  <label>Radio Call Sign</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <digi-manifest-port
                              label='Departure Port'
                              transportMethod="SEA"
                              [isRequired]="false"
                              (portChange)="selectDeparturePort($event)"
                              [port]="voyageFile.departurePort">
                            </digi-manifest-port>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <digi-manifest-port
                              label='Destination Port'
                              transportMethod="SEA"
                              [isRequired]="false"
                              (portChange)="selectDestinationPort($event)"
                              [port]="voyageFile.destinationPort">
                            </digi-manifest-port>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="departureDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             (focusout)="updateOriginalDepartureETDAndSailingDates()"
                             [(ngModel)]="voyageFile.departureDate">
                </p-inputMask>
                <label for="departureDate">Departure Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="arrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         (focusout)="updateOriginalArrivalDockingAndETADates()"
                         [(ngModel)]="voyageFile.arrivalDate">
            </p-inputMask>
            <label for="arrivalDate">Arrival Date</label>
        </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="originalDepartureDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.originalDepartureDate">
                </p-inputMask>
                <label for="originalDepartureDate">Original Departure Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="originalArrivalDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.originalArrivalDate">
                </p-inputMask>
                <label for="originalArrivalDate">Original Arrival Date</label>
            </span>
        </span>
                          </div>
                          <div  class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="voyageFile.weekNo"
                           required>
                   <label>Week No.</label>
                 </span>
              </div>
          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-4">
                <p-panel header="Date Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="sailingDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.sailingDate">
                </p-inputMask>
                <label for="sailingDate">Sailing Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="dockingDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.dockingDate">
                </p-inputMask>
                <label for="dockingDate">Docking Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="storageCommencementDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.storageCommencementDate">
                </p-inputMask>
                <label for="storageCommencementDate">Storage Commencement Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="lastFreeDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.lastFreeDate">
                </p-inputMask>
                <label for="lastFreeDate">Last Free Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="firmStackOpenDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.firmStackOpenDate">
                </p-inputMask>
                <label for="firmStackOpenDate">Firm Stack Open Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="provisionalStackOpenDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.provisionalStackOpenDate">
                </p-inputMask>
                <label for="provisionalStackOpenDate">Provisional Stack Open Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="stackCloseDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.stackCloseDate">
                </p-inputMask>
                <label for="stackCloseDate">Stack Close Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="dischargeCommencementDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.dischargeCommencementDate">
                </p-inputMask>
                <label for="dischargeCommencementDate">Discharge Commencement Date</label>
            </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask id="dischargeCompletedDate" mask="99/99/9999"
                             slotChar="dd/mm/yyyy"
                             [(ngModel)]="voyageFile.dischargeCompletedDate">
                </p-inputMask>
                <label for="dischargeCompletedDate">Discharge Completed Date</label>
            </span>
        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-4">
                <p-panel header="Trans-Shipment Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div  class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="voyageFile.feederVessel"
                           required>
                   <label>Feeder Vessel</label>
                 </span>
              </div>
          </span>
                          </div>
                          <div  class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           [(ngModel)]="voyageFile.feederVoyageNo"
                           required>
                   <label>Feeder Voyage No.</label>
                 </span>
              </div>
          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <digi-manifest-port
                              label='Transfer Port'
                              transportMethod="SEA"
                              [isRequired]="false"
                              (portChange)="selectTransferPort($event)"
                              [port]="voyageFile.transferPort">
                            </digi-manifest-port>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="transShipmentDepartureDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="voyageFile.transShipmentDepartureDate">
            </p-inputMask>
            <label for="transShipmentDepartureDate">ETD</label>
        </span>
        </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="transShipmentArrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="voyageFile.transShipmentArrivalDate">
            </p-inputMask>
            <label for="transShipmentArrivalDate">ETA</label>
        </span>
        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
                <br>
                <p-panel header="Comments">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="voyageFile.comments" [rows]="3" [cols]="50"
                                    placeholder="Your Comments"
                                    style="resize: none">
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</ng-container>
<ng-container *ngIf="view==='bol'">
  <digi-ocean-bol [indicator]="indicator" [voyageFile]="voyageFile" (onSaveVoyageFile)="saveVoyageFile()"></digi-ocean-bol>
</ng-container>
<ng-container *ngIf="view==='reports'">
  <digi-ships-agent-reports [voyageFile]="voyageFile" (updateVoyageMasterFile)="updateVoyageMasterFile($event)"></digi-ships-agent-reports>
</ng-container>
