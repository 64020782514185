/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./air-forwarding-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../digi-common/components/forwarding-steps-navigation/forwarding-steps-navigation.component.ngfactory";
import * as i3 from "../../../digi-common/components/forwarding-steps-navigation/forwarding-steps-navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../subjects/forwarding-file-behaviour-subject";
import * as i6 from "../../../digi-common/services/notification.service";
import * as i7 from "../../../digi-common/services/forwarding-file-validation.service";
import * as i8 from "../../../digi-common/services/forwarding-file.service";
import * as i9 from "./air-forwarding-dashboard.component";
import * as i10 from "../../../digi-common/services/share-data.service";
var styles_AirForwardingDashboardComponent = [i0.styles];
var RenderType_AirForwardingDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AirForwardingDashboardComponent, data: {} });
export { RenderType_AirForwardingDashboardComponent as RenderType_AirForwardingDashboardComponent };
export function View_AirForwardingDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "digi-forwarding-steps-navigation", [["class", "col-md-12 secondary-nav"]], null, null, null, i2.View_ForwardingStepsNavigationComponent_0, i2.RenderType_ForwardingStepsNavigationComponent)), i1.ɵdid(2, 245760, null, 0, i3.ForwardingStepsNavigationComponent, [i4.Router, i5.ForwardingFileBehaviourSubject, i6.NotificationService, i7.ForwardingFileValidationService, i8.ForwardingFileService], { transportMethod: [0, "transportMethod"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "air"; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_AirForwardingDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-air-forwarding-dashboard", [], null, null, null, View_AirForwardingDashboardComponent_0, RenderType_AirForwardingDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i9.AirForwardingDashboardComponent, [i4.Router, i5.ForwardingFileBehaviourSubject, i10.ShareDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AirForwardingDashboardComponentNgFactory = i1.ɵccf("digi-air-forwarding-dashboard", i9.AirForwardingDashboardComponent, View_AirForwardingDashboardComponent_Host_0, {}, {}, []);
export { AirForwardingDashboardComponentNgFactory as AirForwardingDashboardComponentNgFactory };
