<div class="ui-toolbar-group-left" style="color: black;">
    <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
            <span class="ui-float-label">
        <p-autoComplete [suggestions]="filteredBusinessEntities"
                        [ngModel]="businessEntity" field="label"
                        (onSelect)="selectedBusinessEntityFromDropDown($event)" [minLength]="3"
                        (completeMethod)="searchBusinessEntity($event)"
                        [forceSelection]="true"
                        [autoHighlight]="true"
                          required = true>
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}:{{option.name}}</div>
          </ng-template>
        </p-autoComplete>
        <label>Search Business Entity</label>
      </span>
          <span class="ui-float-label">
            <p-autoComplete [suggestions]="filteredBusinessEntities"
                            [ngModel]="businessEntity" field="label"
                            (onSelect)="selectedBusinessEntityFromDropDown($event)" [minLength]="8"
                            (completeMethod)="searchImporterEntityCustomsCode($event)">
            <ng-template let-option pTemplate="item">
              <div>{{option.code}}:{{option.name}}</div>
            </ng-template>
          </p-autoComplete>
        <label>Search Importer Code</label>
      </span>
            <span *ngIf="activeRoleType" class="ui-float-label">
        <input pInputText readonly="readonly" value="{{activeRoleType?.description}}"/>
        <label>Current Role</label>
      </span>
        </div>
    </div>
</div>
<div class="ui-toolbar-group-right" style="color: black;">
    <button *ngIf="businessEntityStepActive" pButton type="button" (click)="newBusinessEntity()" label="New" icon="fa fa-file" class="ui-button-secondary"></button>
    <button pButton type="button" (click)="saveBusinessEntity()" label="Save" icon="fa fa-save">
  </button>
</div>
