<p-toolbar>
  <div class="ui-toolbar-group-left ui-g-8">
    <div class="ui-g-4 ui-md-4 ui-g-nopad">
  <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="importers" [(ngModel)]="importers"
                            class="color-white"
                            field="label"
                            [suggestions]="filteredImporters"
                            (completeMethod)="searchImporter($event)"
                            [autoHighlight]="true"
                            [multiple]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Importers">
              <ng-template let-value pTemplate="selectedItem">
          <span style="font-size:14px">{{value.code}}</span>
        </ng-template>
            </p-autoComplete>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
    </div>
    <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="fromDate"
                                             placeholder="From"
                                >
                                </p-inputMask>
                            </span>
                          </span>
    </div>
    <div class="ui-g-2 ui-md-2">
      <span class="md-inputfield">
                            <span class="ui-float-label">
                                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                             [(ngModel)]="toDate"
                                             placeholder="To"
                                >
                                </p-inputMask>
                            </span>
                          </span>
    </div>
    <div class="ui-g-3 ui-md-3">
  <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="customsOffice" [(ngModel)]="customsOffice"
                            class="color-white"
                            field="label"
                            [suggestions]="filteredCustomsOffices"
                            (completeMethod)="searchCustomsOffice($event)"
                            (onSelect)="selectCustomsOffice($event)"
                            [autoHighlight]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Customs Office">
              <ng-template let-value pTemplate="selectedItem">
          <div>{{value.districtOfficeCode}}: {{value.districtOfficeName}}</div>
        </ng-template>
            </p-autoComplete>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
    </div>
  </div>
  <div class="ui-toolbar-group-right ui-g-4">
    <div class="ui-md-12 ui-g-12">
      <div class="ui-md-6 ui-g-6">
        <button pButton type="button" label="Generate Imports Report"
                [disabled]="disableButton"
                (click)="generate('clearingFile')"></button>
        <button *ngIf="pdfSrcImports" pButton type="button" label="Print/Download" (click)="openLinkImports()"></button>
      </div>
      <div class="ui-md-6 ui-g-6">
        <button pButton type="button" label="Generate Ex Bond Report"
                [disabled]="disableButton"
                (click)="generate('exBondFile')"></button>
        <button *ngIf="pdfSrcExBond" pButton type="button" label="Print/Download" (click)="openLinkExBond()"></button>
      </div>
    </div>
  </div>
</p-toolbar>
<div *ngIf="disableButton" class="ui-g-12">
  <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
</div>
<div class="card card-w-title">
  <div class="ui-g">
    <pdf-viewer class="ui-g-12 ui-md-12"
                *ngIf="pdfSrcImports"
                [src]="pdfSrcImports"
                [render-text]="true"
                [original-size]="true"
                [show-all]="true"
                [stick-to-page]="true"
                (after-load-complete)="disableButton=false"
    ></pdf-viewer>
    <pdf-viewer class="ui-g-12 ui-md-12"
                *ngIf="pdfSrcExBond"
                [src]="pdfSrcExBond"
                [render-text]="true"
                [original-size]="true"
                [show-all]="true"
                [stick-to-page]="true"
                (after-load-complete)="disableButton=false"
    ></pdf-viewer>
  </div>
</div>
