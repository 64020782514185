import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrackingDetails} from '../../models/ForwardingFile';
import {SelectItem} from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'digi-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  @Input('display') display;
  @Input('isHouseBill') isHouseBill;
  @Input('displayTrackingProgressBar') displayTrackingProgressBar;
  @Input('currentStatus') currentStatus;
  @Output() displayChange = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() sendParcelUpdate = new EventEmitter();
  @Output() updateHAWBsArrivedAtJNBOPSFields = new EventEmitter();
  @Output() updateHAWBsReceivedAtAOTIAFields = new EventEmitter();
  @Input('trackingDetails') trackingDetails: TrackingDetails;
  commentStatusList: SelectItem[];
  isCustomsReleased: boolean;
  isStoppedForInspection: boolean;
  isConditionallyReleased: boolean;
  isShipmentDetainedForOGA: boolean;
  isSupportingDocumentsRequired: boolean;
  isShipmentReferredForChecking: boolean;
  isOGASupportingDocuments: boolean;
  isOGAReleased: boolean;
  isExamScheduleFor: boolean;
  isExamComplete: boolean;
  isReportReceived: boolean;
  isSupportingDocumentsReceived: boolean;
  isOutcomeOfInspection: boolean;
  isProvisionPaymentOrVOClodged: boolean;
  isStoppedShipmentReleased: boolean;
  isGeneralQueryChecked: boolean;

  constructor() {
  }

  ngOnInit() {
    this.isCustomsReleased = this.trackingDetails.statusCustomsReleased ? true : false;
    this.isStoppedForInspection = this.trackingDetails.statusStoppedForInspection ? true : false;
    this.isConditionallyReleased = this.trackingDetails.statusConditionallyReleased ? true : false;
    this.isShipmentDetainedForOGA = this.trackingDetails.statusShipmentDetainedForOGA ? true : false;
    this.isSupportingDocumentsRequired = this.trackingDetails.statusSupportingDocumentsRequired ? true : false;
    this.isShipmentReferredForChecking = this.trackingDetails.statusShipmentReferredForChecking ? true : false;
    this.isOGASupportingDocuments = this.trackingDetails.statusOGASupportingDocuments ? true : false;
    this.isOGAReleased = this.trackingDetails.statusOGAReleased ? true : false;
    this.isExamScheduleFor = this.trackingDetails.statusExamScheduleFor ? true : false;
    this.isExamComplete = this.trackingDetails.statusExamComplete ? true : false;
    this.isReportReceived = this.trackingDetails.statusReportReceived ? true : false;
    this.isSupportingDocumentsReceived = this.trackingDetails.statusSupportingDocumentsReceived ? true : false;
    this.isOutcomeOfInspection = this.trackingDetails.statusOutcomeOfInspection ? true : false;
    this.isProvisionPaymentOrVOClodged = this.trackingDetails.statusProvisionPaymentOrVOClodged ? true : false;
    this.isStoppedShipmentReleased = this.trackingDetails.statusStoppedShipmentReleased ? true : false;
    this.isGeneralQueryChecked = this.trackingDetails.statusGeneralQueryTimestamp ? true : false;
  }

  onSave() {
    this.save.emit(true);
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onUpdateHAWBSArrivedATORTIA() {
    this.updateHAWBsReceivedAtAOTIAFields.emit(true);
  }

  onUpdateHAWBsArrivedAtJNBOPSFields() {
    this.updateHAWBsArrivedAtJNBOPSFields.emit(true);
  }

  onSendParcelUpdate() {
    this.sendParcelUpdate.emit(this.getNumericStatus());
  }

  onSendParcelUpdateDisabled() {
    return (this.getNumericStatus() !== 3) ? true : false;
  }

  setStatusCommentTime(event, statusComment: string) {
      switch (statusComment) {
        case 'statusCustomsReleased':
          if (event === true) {
            this.trackingDetails.statusCustomsReleased = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusCustomsReleased = null;
          }
          this.isCustomsReleased = event;
          break;
        case 'statusStoppedForInspection':
          if (event === true) {
            this.trackingDetails.statusStoppedForInspection = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusStoppedForInspection = null;
          }
          this.isStoppedForInspection = event;
          break;
        case 'statusConditionallyReleased':
          if (event === true) {
            this.trackingDetails.statusConditionallyReleased = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusConditionallyReleased = null;
          }
          this.isConditionallyReleased = event;
          break;
        case 'statusShipmentDetainedForOGA':
          if (event === true) {
            this.trackingDetails.statusShipmentDetainedForOGA = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusShipmentDetainedForOGA = null;
          }
          this.isShipmentDetainedForOGA = event;
          break;
        case 'statusSupportingDocumentsRequired':
          if (event === true) {
            this.trackingDetails.statusSupportingDocumentsRequired = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusSupportingDocumentsRequired = null;
          }
          this.isSupportingDocumentsRequired = event;
          break;
        case 'statusShipmentReferredForChecking':
          if (event === true) {
            this.trackingDetails.statusShipmentReferredForChecking = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusShipmentReferredForChecking = null;
          }
          this.isShipmentReferredForChecking = event;
          break;
        case 'statusOGASupportingDocuments':
          if (event === true) {
            this.trackingDetails.statusOGASupportingDocuments = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusOGASupportingDocuments = null;
          }
          this.isOGASupportingDocuments = event;
          break;
        case 'statusOGAReleased':
          if (event === true) {
            this.trackingDetails.statusOGAReleased  = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusOGAReleased  = null;
          }
          this.isOGAReleased = event;
          break;
        case 'statusExamScheduleFor':
          if (event === true) {
            this.trackingDetails.statusExamScheduleFor = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusExamScheduleFor = null;
          }
          this.isExamScheduleFor = event;
          break;
        case 'statusExamComplete' :
          if (event === true) {
            this.trackingDetails.statusExamComplete = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusExamComplete = null;
          }
          this.isExamComplete = event;
          break;
        case 'statusReportReceived' :
          if (event === true) {
            this.trackingDetails.statusReportReceived = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusReportReceived = null;
          }
          this.isReportReceived  = event;
          break;
        case 'statusSupportingDocumentsReceived' :
          if (event === true) {
            this.trackingDetails.statusSupportingDocumentsReceived = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusSupportingDocumentsReceived = null;
          }
          this.isSupportingDocumentsReceived = event;
          break;
        case 'statusOutcomeOfInspection' :
          if (event === true) {
            this.trackingDetails.statusOutcomeOfInspection = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusOutcomeOfInspection = null;
          }
          this.isOutcomeOfInspection = event;
          break;
        case 'statusProvisionPaymentOrVOClodged' :
          if (event === true) {
            this.trackingDetails.statusProvisionPaymentOrVOClodged = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusProvisionPaymentOrVOClodged = null;
          }
          this.isProvisionPaymentOrVOClodged = event;
          break;
        case 'statusStoppedShipmentReleased' :
          if (event === true) {
            this.trackingDetails.statusStoppedShipmentReleased = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusStoppedShipmentReleased = null;
          }
          this.isStoppedShipmentReleased = event;
          break;
        case 'statusGeneralQueryTimestamp':
          if (event === true) {
            this.trackingDetails.statusGeneralQueryTimestamp = moment().format('DD/MM/YYYY HH:mm');
          } else {
            this.trackingDetails.statusGeneralQueryTimestamp = null;
          }
          this.isGeneralQueryChecked = event;
          break;
      }
  }

  getNumericStatus(): number {
    switch (this.currentStatus) {
      case 'Received At JNB OPS':
        return  3;
      case 'Received At Customs':
        return 2;
      case 'Sent To Customs':
        return 1;
    }
  }
}
