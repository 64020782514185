import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-tariff-maintenance',
  templateUrl: './tariff-maintenance.component.html',
  styleUrls: ['./tariff-maintenance.component.scss']
})
export class TariffMaintenanceComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
