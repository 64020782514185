import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingInstructionsService } from '../../services/clearing-instructions.service';
import { TransportDocument } from '../../models/file';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';

@Component({
  selector: 'digi-hawb-boe-details-section',
  templateUrl: './hawb-boe-details-section.component.html',
  styleUrls: ['./hawb-boe-details-section.component.css']
})
export class HawbBoeDetailsSectionComponent implements OnInit, OnDestroy {
  transportDocument: TransportDocument;
  customsProcedureCodes = [];
  subscription: Subscription;

  constructor(private clearingInstructionsService: ClearingInstructionsService,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject) {
  }

  ngOnInit() {
    this.subscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
          if (td) {
            this.transportDocument = td;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search() {
    this.clearingInstructionsService.findCustomProceduresForPreviousBOE().subscribe(
      data => {
        this.customsProcedureCodes = data.map(e => e.customsProcedureCode);
      });
  }

  select(customsProcedureCode) {
    this.transportDocument.previousBOEDetails.cpc = customsProcedureCode;
  }

  updateCustomsOffice(customsOffice) {
    this.transportDocument.previousBOEDetails.customOffice = customsOffice;
  }

  selectPortTo(portTo) {
    this.transportDocument.previousBOEDetails.portTo.code = portTo.code;
    this.transportDocument.previousBOEDetails.portTo.name = portTo.name;
  }
}
