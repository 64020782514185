import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../../subjects/clearingInstruction-behavior-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../../../subjects/supplierInvoice-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { BillOfEntry } from '../../models/billOfEntries';
import { SupplierInvoice, SupplierInvoiceLine, TransportDocument } from '../../models/file';
import { ClearingFile } from '../../models/clearingFile';
import { SaveClearingFile } from '../../saveClearingFile';
import { ClearingFileService } from '../../services/clearing-file.service';
import { NotificationService } from '../../services/notification.service';
import { ShareDataService } from '../../services/share-data.service';
import { ValidationService } from '../../services/validation.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'digi-clearing-file-section',
  templateUrl: './clearing-file-section.component.html',
  styleUrls: ['./clearing-file-section.component.css'],
})
export class ClearingFileSectionComponent implements OnInit, OnDestroy {
  @Input() step: string;
  @Input() transportMethod: string;
  @Input() billOfEntry: BillOfEntry;
  @Input() canAdd = false;
  @Input() showClearingFileSection = true;
  @Input() canDisable = true;
  @Input() canCreateVoC = true;
  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() display: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  billSubscription: Subscription;
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;
  disableButtons = false;

  constructor(
    private clearingFileService: ClearingFileService,
    private shareDataService: ShareDataService,
    private router: Router,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private onSaveClearingFile: SaveClearingFile,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private messageService: NotificationService,
    private validationService: ValidationService,
  ) {
    this.validationService.setComponent(this);
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryBehaviourSubject.getBillOfEntry()
      .subscribe(boe => this.billOfEntry = boe);

    this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe((cf: ClearingFile) => this.clearingFile = cf);

    this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));

    this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);

  }

  isSaveDisabled() {
    if (this.billOfEntry) {
      if (this.billOfEntry.dateEdiSubmitted) {
        let hasStatus = this.billOfEntry.cusres && this.billOfEntry.cusres.customsStatus;
        if (hasStatus && this.billOfEntry.cusres.customsStatus === 'REJECT_TO_CLEARER' || hasStatus && this.billOfEntry.cusres.customsStatus === 'OGA_DETAIN_DISCARD_REJECT') {
          return false;
        }

        if (this.billOfEntry.contrl && (this.billOfEntry.contrl.messageStatus === 'REJECTED' ||
          this.billOfEntry.contrl.messageStatus === 'UNB_OR_UNZ_REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'UNB_OR_UNZ_REJECTED')) {
          return false;
        }
        return true;
      }
      if (this.billOfEntry.markedAsSubmitted) {
        return true;
      }
    }
    return false;
  }

  saveClearingFile() {
    this.disableButtons = true;
    this.clearingFile.transportDocuments.forEach(transportDoc => {
      if (!transportDoc.freight.amount) {
        transportDoc.freight.amount = 0;
      }
    });

    if (this.clearingFile.type !== 'Correction' && !this.clearingFile.quotation) {
      this.validateClearingFile().subscribe(
        failed => {
          if (!failed) {
            this.saveConfirmation();
          }
          this.disableButtons = false;
        },
        error => {
          this.disableButtons = false;
        },
        () => {
          this.disableButtons = false;
        },
      )
    } else {
      this.onSaveClearingFile.saveClearingFile(
        this.clearingFile,
        this.selectedTransportDocument,
        this.selectedSupplierInvoice,
        this.selectedSupplierInvoiceLine,
      ).subscribe(
        value => {
          this.saveConfirmation();
          this.disableButtons = false;
        },
        error => {
          this.disableButtons = false;
        },
        () => {
          this.disableButtons = false;
        },
      );
    }
  }

  validateClearingFile(): Observable<boolean> {
    let failed = false;

    if (this.isSaveDisabled() && this.canDisable) {
      failed = this.showErrors('Cannot save as EDI has already been submitted.');
    }
    if (!failed) {
      failed = this.validationService.validateFileBasedOnStep(this.step, this.clearingFile, 'imports',
        (header: string, messages: string[]) => this.showErrors(header, messages));

      if (!failed) { // Only save once
        return this.onSaveClearingFile.saveClearingFile(
          this.clearingFile,
          this.selectedTransportDocument,
          this.selectedSupplierInvoice,
          this.selectedSupplierInvoiceLine).pipe(
          switchMap(value => of(failed)),
        );
      } else {
        return throwError('Unable to save');
      }
    }
  }

  addAction() {
    this.add.emit();
  }

  createVoc() {

    if (!this.hasMrn()) {
      this.showErrors('Cannot create a VOC as no MRN was received for previous submission.');
    } else {
      this.display.emit(true);
    }
  }


  hasClearingFile() {
    return this.clearingFile.type === 'ClearingFile' || this.clearingFile.type === 'Correction';
  }

  createFileFromQuote() {
    this.clearingFile.clearingFileNumber = undefined;
    this.clearingFile.quotation = false;
    this.onSaveClearingFile.saveClearingFile(
      this.clearingFile,
      this.selectedTransportDocument,
      this.selectedSupplierInvoice,
      this.selectedSupplierInvoiceLine,
    ).subscribe(
      value => {
        this.saveConfirmation();
        this.disableButtons = false;

        if (value.clearingFileNumber) {
          const transportCode = this.clearingFile.clearingInstructions[0].transportMethod.method.toLowerCase();
          this.router.navigate([transportCode, 'register', this.clearingFile.clearingFileNumber, 0]);
        }
      },
      error => {
        this.disableButtons = false;
      },
      () => {
        this.disableButtons = false;
      },
    );
  }

  hasMrn() {
    return !!(this.billOfEntry && this.billOfEntry.mrn);
  }

  hasBoe() {
    return !!(this.billOfEntry && this.billOfEntry.id);
  }

  showErrors(label: string, messages?: string[]) {
    if (messages) {
      this.messageService.errorNotify(label, messages);
    } else {
      this.messageService.errorNotify(label);
    }
    return true;
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.billSubscription.unsubscribe();
  }
}
