import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Container, SupplierInvoice } from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class ContainerBehaviorSubject {
  private containersSubject = new BehaviorSubject<Container>(null);

  constructor() {
  }

  getContainer(): Observable<Container> {
    return this.containersSubject.asObservable();
  }

  addContainer(container: Container) {
    this.containersSubject.next(container);
  }
}

