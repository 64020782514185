import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var RateClassService = /** @class */ (function () {
    function RateClassService(http, config) {
        this.http = http;
        this.config = config;
    }
    RateClassService.prototype.findByCode = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/rateClasses/search/findByCodeStartsWithIgnoreCase?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].rateClasses; }));
    };
    RateClassService.ngInjectableDef = i0.defineInjectable({ factory: function RateClassService_Factory() { return new RateClassService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: RateClassService, providedIn: "root" });
    return RateClassService;
}());
export { RateClassService };
