import {Component, Input, KeyValueDiffers, OnChanges, OnDestroy, ViewChild, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {SupplierInvoiceLineBehaviourSubject} from 'src/subjects/supplier-invoice-line-behaviour-subject.service';
import {SupplierInvoiceBehaviourSubject} from 'src/subjects/supplierInvoice-behaviour-subject';
import {TransportDocumentBehaviourSubject} from 'src/subjects/transport-document-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {AdditionalScheduleTariffComponent} from '../additional-schedule-tariff/additional-schedule-tariff.component';
import {
  BusinessEntity,
  Certificate,
  CertificateType,
  ClearingInstruction,
  Permit, PreviousBoeDetails,
  SupplierInvoice,
  SupplierInvoiceLine,
  TariffCode,
  TradeAgreement
} from '../../models/file';
import {ClearingFile} from '../../models/clearingFile';
import {Part} from '../../models/part';
import {PartService} from '../../services/part.service';
import {ShareDataService} from '../../services/share-data.service';
import {CompanyService} from '../../services/company.service';
import {TariffService} from '../../services/tariff.service';
import {TariffCodeFormulaeService} from '../../services/tariffcode-formulae.service';
import {UniqueConsignmentReferenceService} from '../../services/unique-consignment-reference.service';
import {UniqueConsignmentBehaviourSubject} from '../../../../subjects/unique-consignment-reference-subject';
import {PermitsService} from '../../permits/services/permits.service';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {CertificateTypeService} from '../../services/certificate-type.service';
import {Uom} from '../../models/TariffCode';
import {UomService} from '../../services/uom.service';
import {NotificationService} from '../../services/notification.service';
import {FileService} from '../../services/file.service';
import {PackType} from '../../models/roadManifest';
import {Branch} from '../../models/company';
import {PackTypeService} from '../../services/pack-type.service';

@Component({
  selector: 'digi-invoice-lines-section',
  templateUrl: './invoice-lines-section.component.html',
  styleUrls: ['./invoice-lines-section.component.css']
})
export class InvoiceLinesSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() supplierInvoiceLine: SupplierInvoiceLine;
  @Input() supplierInvoice: SupplierInvoice;
  @Input() businessEntityCode;
  @ViewChild(AdditionalScheduleTariffComponent) additionalSchedulesChildComp: AdditionalScheduleTariffComponent;
  tariffCode = new TariffCode();
  @Input() clearingInstruction: ClearingInstruction;
  quantityTypes: SelectItem[];
  partNumber: string;
  effectiveFormulae;
  effectiveParameters;
  private code: number;
  tradeAgreement: TradeAgreement;
  file: ClearingFile | ExportFile;
  isVDNPercentageUpdated = false;
  vdnPercentDiscountedValue;
  selectedPartNumber = new Part();
  filteredPartNumbers: Part[] = [];
  filteredTariffCodes: TariffCode[];
  partNumbers = [];
  display = false;
  fileSubscription: Subscription;
  subscription: Subscription;
  permitsSubscription: Subscription;
  roleType = '4';
  permitServiceSubscription: Subscription;
  permits: Permit[];
  @Input() fileType: FileType;
  filteredCertificateTypes: CertificateType[];
  certificateTypesServiceSubscription: Subscription;
  certificateSubscription: Subscription;
  cols: any[];
  filteredUoms: Uom[];
  @Input() date: string;
  openPartNumberDialog: boolean;
  isPartNumberUpdate = false;
  showTariffDialog: boolean;
  tariff: string;
  filteredPackTypes: PackType[];
  rooRequired = true;
  displayUnitType = false;
  tariffCodeTree: any[];
  selectedTariff: any;
  additionalScheduleTariffs: TariffCode[];

  constructor(
    private partService: PartService,
    private tariffService: TariffService,
    private shareDataService: ShareDataService,
    private tariffCodeFormulaeService: TariffCodeFormulaeService,
    private permitService: PermitsService,
    private differs: KeyValueDiffers,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private transportDocumentBehaviorSubject: TransportDocumentBehaviourSubject,
    private uniqueConsignmentReferenceService: UniqueConsignmentReferenceService,
    private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject,
    private certificateTypesService: CertificateTypeService,
    private uomService: UomService,
    private notificationService: NotificationService,
    private fileService: FileService,
    private packTypeService: PackTypeService,
    private companyService: CompanyService
  ) {
    this.quantityTypes = [
      {label: '', value: ''},
      {label: 'TO', value: 'TO'},
      {label: 'KG', value: 'KG'},
      {label: 'EA', value: 'EA'},
      {label: 'MT', value: 'MT'}
    ];
  }

  ngOnInit() {
    this.cols = [
      {
        header: 'UOM Code',
      },
      {
        'header': 'value'
      }
    ];
    if (this.fileType === 'imports') {
      this.fileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
        this.file = cf;
        this.businessEntityCode = this.file.importer.code;
      });
    } else {
      this.roleType = '12';
      this.fileSubscription = this.exportFileBehaviourSubject.getExportFile().subscribe(ef => {
        this.file = ef;
        this.businessEntityCode = this.file.exporter.code;
      });
      this.companyService.getCompany().subscribe(companies => {
        if (companies && companies.length) {
          const branch: Branch = companies[0].branches[0];
          if (branch.defaultQuantityTypeDisplay) {
            this.displayUnitType = true;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date && changes.date.currentValue) {
      this.date = changes.date.currentValue;
    }

    if (changes.businessEntityCode && changes.businessEntityCode.currentValue) {
      this.businessEntityCode = changes.businessEntityCode.currentValue;
    }

    if (changes.supplierInvoiceLine && changes.supplierInvoiceLine.currentValue) {
      this.supplierInvoiceLine = changes.supplierInvoiceLine.currentValue;
      this.additionalScheduleTariffs = this.supplierInvoiceLine.additionalScheduleTariffs;
      this.checkIfRooIsRequired();
    }

    if (this.supplierInvoiceLine.tariffCode) {
      this.tariffCode = this.supplierInvoiceLine.tariffCode;
      this.selectedPartNumber = new Part();
      this.selectedPartNumber.partNumber = this.supplierInvoiceLine.partNumber;
      this.loadPermits();
      this.checkIfRooIsRequired();
    }

    if (changes.clearingInstruction && changes.clearingInstruction.currentValue) {
      this.clearingInstruction = changes.clearingInstruction.currentValue;
      if (this.clearingInstruction.customsProcedureCode === 67 && !this.supplierInvoiceLine.previousBoeDetails) {
        this.supplierInvoiceLine.previousBoeDetails = new PreviousBoeDetails();
      }
    }

    if (!this.supplierInvoiceLine.certificate) {
      this.supplierInvoiceLine.certificate = new Certificate();
    }

    const supplierInvoiceCountryObservable = this.supplierInvoiceBehaviourSubject.getCountry();

    this.subscription = supplierInvoiceCountryObservable.subscribe(
      (country) => {
        if (this.supplierInvoice.supplier.code) {
          if (this.supplierInvoice && this.supplierInvoice.defaultValues && this.supplierInvoice.defaultValues.country
            && (!this.supplierInvoiceLine.countryOfOrigin || !this.supplierInvoiceLine.countryOfOrigin.code)) {
            this.supplierInvoiceLine.countryOfOrigin = country;
          }
        } else if (this.file && !this.file.quotation || country !== null) {
          this.supplierInvoiceLine.countryOfOrigin = country;
        }
      }
    );

    this.certificateSubscription = this.supplierInvoiceBehaviourSubject.getCertificate().subscribe(
      certificate => this.supplierInvoiceLine.certificate = certificate
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
    }
    if (this.permitsSubscription) {
      this.permitsSubscription.unsubscribe();
    }
    if (this.permitServiceSubscription) {
      this.permitServiceSubscription.unsubscribe();
    }

    if (this.certificateSubscription) {
      this.certificateSubscription.unsubscribe();
    }

  }

  tariffChecked(value) {
    this.supplierInvoiceLine.userInterfaceCheckbox.tariffMode = value;
    if (value) {
      // Update check box values as the values are not mutually exclusive
      this.supplierInvoiceLine.userInterfaceCheckbox.quickPart = false;
    }
  }

  quickPartChecked(value) {
    this.supplierInvoiceLine.userInterfaceCheckbox.quickPart = value;
    if (value) {
      // Update check box values as the values are not mutually exclusive
      this.supplierInvoiceLine.userInterfaceCheckbox.tariffMode = false;
    }
  }

  updateWeight(event) {
    if (this.supplierInvoiceLine.tariffCode && this.supplierInvoiceLine.tariffCode.formula && this.supplierInvoiceLine.tariffCode.formula.statisticalUOM.trim() === 'KG') {
      this.supplierInvoiceLine.lineWeight = Number(event);
    }
  }

  updatedTariff(value: TariffCode) {
    if (value) {
      this.effectiveFormulae = value.effectiveTradeAgreementFormulae;
      this.tariffCodeFormulaeService.effectiveTradeAgreementFormulae(
        this.effectiveFormulae,
        this.supplierInvoiceLine
      );
      this.effectiveParameters = value.effectiveTradeAgreementParameters;
      this.tariffCodeFormulaeService.effectiveTradeAgreementParameters(
        this.effectiveParameters,
        this.supplierInvoiceLine
      );
      this.checkIfRooIsRequired(this.effectiveFormulae);
      this.supplierInvoiceLine.tariffCode.description = value.tariffSDesc;
      this.supplierInvoiceLine.tariffCode.checkDigit = value.tariffCheckDigit;
      this.supplierInvoiceLine.tariffCode.code = value.code;
      this.supplierInvoiceLine.tariffCode.fullDescription = value.fullDescription;

      if (value.additionalUoms && value.additionalUoms.length > 0) {
        value.additionalUoms.forEach(uom => {
          if (this.supplierInvoiceLine.additionalUoms) {
            const foundUom = this.supplierInvoiceLine.additionalUoms.find(el => el.uomCode === uom.uomCode);
            if (!foundUom) {
              this.supplierInvoiceLine.additionalUoms.push({uomCode: uom.uomCode});
            }
          } else {
            this.supplierInvoiceLine.additionalUoms = [];
            this.supplierInvoiceLine.additionalUoms.push({uomCode: uom.uomCode});
          }
        });
      } else {
        this.supplierInvoiceLine.additionalUoms = [];
      }

      if (!this.supplierInvoiceLine.fullDescription) {
        this.supplierInvoiceLine.fullDescription = value.fullDescription;
      }
    }
  }

  discountPercentChanged(value: number) {
    this.supplierInvoiceLine.discountPercent = value;
    if (this.supplierInvoiceLine.invoiceGrossValue) {
      this.supplierInvoiceLine.discountValue =
        this.supplierInvoiceLine.invoiceGrossValue *
        (this.supplierInvoiceLine.discountPercent / 100);
      this.updateInvoiceValues();
    }
  }

  vdnPercentChanged(value: number) {
    this.supplierInvoiceLine.vdnPercent = value;
    this.convertToVdnPercentage(this.supplierInvoiceLine.vdnPercent);
    this.isVDNPercentageUpdated = true;
    this.updateInvoiceValues();
  }

  convertToVdnPercentage(value) {
    this.vdnPercentDiscountedValue =
      this.supplierInvoiceLine.invoiceGrossValue * (value / 100);
  }

  discountValueChanged(value: number) {
    this.supplierInvoiceLine.discountValue = value;
    if (this.supplierInvoiceLine.invoiceGrossValue) {
      this.supplierInvoiceLine.discountPercent =
        (this.supplierInvoiceLine.discountValue * 100) /
        this.supplierInvoiceLine.invoiceGrossValue;
      this.updateInvoiceValues();
    }
  }

  updateInvoiceValues() {
    if (this.supplierInvoiceLine) {
      this.supplierInvoiceLine.invoiceValue =
        this.supplierInvoiceLine.invoiceGrossValue -
        this.supplierInvoiceLine.discountValue;
      this.supplierInvoiceLine.customsValue = this.supplierInvoiceLine.invoiceValue;

      if (this.isVDNPercentageUpdated) {
        this.supplierInvoiceLine.invoiceValue += this.vdnPercentDiscountedValue;
        this.supplierInvoiceLine.customsValue = this.supplierInvoiceLine.invoiceValue;
      }
    }
  }

  defaultCalculation() {
    if (this.supplierInvoiceLine.invoiceGrossValue) {
      this.supplierInvoiceLine.discountValue =
        this.supplierInvoiceLine.invoiceGrossValue *
        (this.supplierInvoiceLine.discountPercent / 100);
      this.supplierInvoiceLine.invoiceValue =
        this.supplierInvoiceLine.invoiceGrossValue -
        this.supplierInvoiceLine.discountValue;
      this.supplierInvoiceLine.customsValue = this.supplierInvoiceLine.invoiceValue;
      if (this.supplierInvoiceLine.vdnPercent > 0) {
        this.vdnPercentDiscountedValue =
          this.supplierInvoiceLine.invoiceGrossValue *
          (this.supplierInvoiceLine.vdnPercent / 100);
        this.supplierInvoiceLine.invoiceValue += this.vdnPercentDiscountedValue;
        this.supplierInvoiceLine.customsValue = this.supplierInvoiceLine.invoiceValue;
        this.isVDNPercentageUpdated = true;
      }
    }
  }

  invoiceGrossValueChanged(value) {
    this.supplierInvoiceLine.invoiceGrossValue = value;
    if (this.supplierInvoiceLine.invoiceGrossValue && this.supplierInvoice.apportionWeightOnLines) {
      const lineWeight = ((this.supplierInvoiceLine.invoiceGrossValue * 100) / (this.supplierInvoice.totalGrossIncomeValue * 100)) * this.supplierInvoice.quantity;
      this.supplierInvoiceLine.lineWeight = Math.round(lineWeight * 100) / 100;

      if (this.supplierInvoiceLine.tariffCode.formula.statisticalUOM && this.supplierInvoiceLine.tariffCode.formula.statisticalUOM.trim() === 'KG') {
        this.supplierInvoiceLine.lineQuantity = this.supplierInvoiceLine.lineWeight;
      }
      this.defaultCalculation();
    }
  }

  searchPartNumber(event) {
    this.partService
      .findByPartNumber(event.query, this.fileType === 'imports' ? 'I' : 'E')
      .subscribe(data => this.filteredPartNumbers = data);
  }

  selectPartNumber(value: Part) {
    this.selectedPartNumber = value;
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(this.selectedPartNumber.tariffCode)
      .subscribe(data => {
        this.selectTariffCode(data[0]);
      });
  }

  searchTariffCode(event) {
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value) {
    if (this.supplierInvoiceLine.tariffCode.code !== value.code) {
      this.supplierInvoiceLine.permit = undefined;
    }
    if (!this.supplierInvoiceLine.userInterfaceCheckbox.quickPart) {
      this.selectedPartNumber = new Part();
      this.selectedPartNumber.partNumber = value.code;
      this.selectedPartNumber.description = value.tariffSDesc;
      this.selectedPartNumber.businessEntity = Object.assign({}, this.fileType === 'imports' ? this.file.importer : this.file.exporter);
      this.selectedPartNumber.importExportIndicator = this.fileType === 'imports' ? 'I' : 'E';
    }
    this.supplierInvoiceLine.partNumber = this.selectedPartNumber.partNumber;
    this.supplierInvoiceLine.description = this.selectedPartNumber.description;
    this.supplierInvoiceLine.fullDescription = value.fullDescription;
    this.supplierInvoiceLine.tariffCode.tariffID = value.tariffID;
    this.tariffCode = new TariffCode();
    this.tariffCode.code = value.code;

    const formula = value.formulae.find(formula => {
      if (formula.uomCode) {
        return true;
      }
      return false;
    });

    if (formula) {
      this.supplierInvoiceLine.tariffCode.formula.statisticalUOM = formula.uomCode;
    }
    this.updatedTariff(value);

    this.loadPermits();
    this.checkIfRooIsRequired(value.effectiveTradeAgreementFormulae);
    if (value.effectiveTradeAgreementFormulae["STANDARD"] && value.effectiveTradeAgreementFormulae["STANDARD"].free) {
      let defaultedTradeAgreement = new TradeAgreement();
      defaultedTradeAgreement.code = "STANDARD  ";
      defaultedTradeAgreement.description = "No Trade Agreement - Standard Tariffs             ";
      this.updateTradeAgreement(defaultedTradeAgreement);
    }
  }

  private loadPermits() {
    const roleType = this.fileType === 'imports' ? 6 : 12;
    this.permitsSubscription = this.permitService.findPermitsByTariffCodeAndBusinessEntityRef(this.supplierInvoiceLine.tariffCode.code, this.businessEntityCode, roleType).subscribe(
      (permits: Permit[]) => this.permits = permits
    );
  }

  selectCountryOfOrigin(event) {
    this.supplierInvoiceLine.countryOfOrigin = Object.assign({}, event);
    this.supplierInvoiceLineBehaviourSubjectComponent.addCountry(this.supplierInvoiceLine.countryOfOrigin);
  }

  selectPermit(permit) {
    if (permit) {
      if (permit.valueBased) {
        permit.appliedValueForeign = this.supplierInvoiceLine.customsValue;
        permit.balanceValue -= permit.appliedValueForeign;
        if (this.permitServiceSubscription) {
          this.permitServiceSubscription.unsubscribe();
        }

        this.permitServiceSubscription = this.permitService
          .getAppliedLocalValue(this.supplierInvoice.currency.code, this.date,
            permit.appliedValueForeign).subscribe(appliedValueLocal => {
            permit.appliedValueLocal = appliedValueLocal;
          });
      }
      if (permit.quantityBased) {
        permit.appliedQuantity = this.supplierInvoiceLine.lineQuantity;
        permit.balanceQuantity -= permit.appliedQuantity;
      }
    }
    this.supplierInvoiceLine.permit = permit;
  }

  searchCertificateTypes(event) {
    this.certificateTypesServiceSubscription = this.certificateTypesService.findByCode(event.query).subscribe(
      (certificateTypes: CertificateType[]) => this.filteredCertificateTypes = certificateTypes,
    );
  }

  selectCertificateType(value) {
    this.supplierInvoiceLine.certificate.type = value;
  }

  clearCertificate() {
    this.supplierInvoiceLine.certificate = new Certificate();
  }

  selectUom(value: Uom) {
    this.supplierInvoiceLine.costingUom = value;
  }

  searchUoms(event: any) {
    this.uomService.findByCodeStartsWith(event.query).subscribe(
      data => this.filteredUoms = data
    );
  }

  selectBusinessEntity(value) {
    if (value) {
      this.selectedPartNumber.businessEntity = new BusinessEntity();
      this.selectedPartNumber.businessEntity.code = value.code;
      this.selectedPartNumber.businessEntity.name = value.name;
      this.selectedPartNumber.businessEntity.label = value.label;
    }
  }

  savePart() {
    this.openPartNumberDialog = false;
    this.isPartNumberUpdate = false;
    this.partService.savePart(this.selectedPartNumber).subscribe(
      part => {
        this.notificationService.successNotify('Part Number Updated Successfully');
        this.selectPartNumber(part);
      },
      _ => this.openPartNumberDialog = false
    );
  }

  showPopup() {
    this.openPartNumberDialog = true;
    this.isPartNumberUpdate = false;
    this.selectedPartNumber = new Part();
    this.selectedPartNumber.businessEntity = Object.assign({}, this.fileType === 'imports' ? this.file.importer : this.file.exporter);
    this.selectedPartNumber.importExportIndicator = this.fileType === 'imports' ? 'I' : 'E';
    this.selectedPartNumber = Object.assign({}, this.selectedPartNumber);
  }

  showPartUpdatePopup() {
    this.isPartNumberUpdate = true;
    this.openPartNumberDialog = true;
    this.selectedPartNumber.businessEntity = Object.assign({}, this.fileType === 'imports' ? this.file.importer : this.file.exporter);
    this.selectedPartNumber.importExportIndicator = this.fileType === 'imports' ? 'I' : 'E';
  }

  searchPackTypes(event) {
    this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      data => this.filteredPackTypes = data
    );
  }

  selectPackType(value) {
    this.supplierInvoiceLine.packType = value;
  }

  private checkIfRooIsRequired(effectiveFormulae?) {
    if (effectiveFormulae) {
      this.setRooRequired(this.effectiveFormulae);
    } else {
      if (this.supplierInvoiceLine.tariffCode && this.supplierInvoiceLine.tariffCode.code) {
        this.tariffService.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(this.supplierInvoiceLine.tariffCode.code)
          .subscribe(data => {
            this.setRooRequired(data[0].effectiveTradeAgreementFormulae);
          });
      }
    }
  }

  private setRooRequired(effectiveFormulae) {
    if (this.file && !this.file.quotation || this.supplierInvoiceLine.countryOfOrigin) {
      const standard = effectiveFormulae['STANDARD'];
      const eutrade = effectiveFormulae['EUTRADE'];
      const efta = effectiveFormulae['EFTA'];
      const sadc = effectiveFormulae['SADC'];
      const countryHasEutrade = this.supplierInvoiceLine.countryOfOrigin.tradeAgreements.find(el => el.code.trim() === 'EUTRADE');
      const countryHasEfta = this.supplierInvoiceLine.countryOfOrigin.tradeAgreements.find(el => el.code.trim() === 'EFTA');
      const countryHasSadc = this.supplierInvoiceLine.countryOfOrigin.tradeAgreements.find(el => el.code.trim() === 'SADC');
      if ((countryHasEutrade && eutrade && standard && standard.description.toLowerCase() !== eutrade.description.toLowerCase()) ||
        (countryHasEfta && efta && standard && standard.description.toLowerCase() !== efta.description.toLowerCase()) ||
        (countryHasSadc && sadc && standard && standard.description.toLowerCase() !== sadc.description.toLowerCase())) {
        this.rooRequired = true;
      } else {
        this.rooRequired = false;
        this.supplierInvoiceLine.rooNumber = null;
      }
    }
  }

  updateROO(value): void {
    this.supplierInvoiceLine.tradeAgreement = value;
    if ((!!value.code && value.code.includes('EUTRADE')) || (!!value.code && value.code.includes('EFTA'))
      || (!!value.code && value.code.includes('SADC') && !!this.supplierInvoiceLine.certificate && this.supplierInvoiceLine.certificate.type !== null)) {
      this.rooRequired = true;
      if (!this.supplierInvoiceLine.rooNumber) {
        this.supplierInvoiceLine.rooNumber = this.supplierInvoice.defaultValues.rooNumber;
      }
    } else {
      this.rooRequired = false;
      this.supplierInvoiceLine.rooNumber = null;
    }
  }

  isWarehouse() {
    return this.clearingInstruction.customsProcedureCode === 40 || this.clearingInstruction.customsProcedureCode === 42;
  }

  isXE() {
    return this.clearingInstruction.customsProcedureCode === 67;
  }
  isZE() {
    return this.clearingInstruction.customsProcedureCode === 68;
  }
  updateCustomsOffice(customOffice) {
    this.supplierInvoiceLine.previousBoeDetails.customOffice = customOffice;
  }

  updateTradeAgreement(value) {
    this.supplierInvoiceLine.tradeAgreement = value;
    if (this.supplierInvoiceLine.tariffCode && this.supplierInvoiceLine.tariffCode.code) {
      this.tariffService.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(this.supplierInvoiceLine.tariffCode.code)
        .subscribe(data => {
          this.effectiveFormulae = data[0].effectiveTradeAgreementFormulae;
          this.effectiveParameters = data[0].effectiveTradeAgreementParameters;
          this.tariffCodeFormulaeService.effectiveTradeAgreementFormulae(this.effectiveFormulae, this.supplierInvoiceLine);
          this.tariffCodeFormulaeService.effectiveTradeAgreementParameters(this.effectiveParameters, this.supplierInvoiceLine);
          this.checkIfRooIsRequired();
        });
    }
  }

  showTariffPopup() {
    this.showTariffDialog = true;
  }

  searchTariffs() {
    this.tariffCodeTree = [];
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(this.tariff)
      .subscribe((tariffCodes: TariffCode[]) => {
        const codes = tariffCodes.map(el => el.code.substring(0, 4));
        this.tariffService
          .findByCodeList(codes, this.date)
          .subscribe((tariffs: TariffCode[]) => {
            this.filteredTariffCodes = tariffs;
            tariffs.forEach(el => {
              const node = {'label': el.code.substring(0, 4), children: [], data: el.code, selectable: false};
              const foundNode = this.tariffCodeTree.find(e => e.label === node.label);
              if (foundNode) {
                foundNode.children.push({label: el.code, children: [{label: el.tariffSDesc}], expanded: true, selectable: true, data: el.code});
              } else {
                node.children = [{label: el.code, children: [{label: el.tariffSDesc}], expanded: true, selectable: true, data: el.code}];
                this.tariffCodeTree.push(node);
              }
            });
          });
      });
  }

  selectTariff() {
    this.showTariffDialog = false;
    if (this.selectedTariff && this.selectedTariff.data) {
      this.selectTariffCode(this.filteredTariffCodes.find(tariff => tariff.code === this.selectedTariff.data));
    }
  }

  updateAdditionalSchedules(schedules: TariffCode[]) {
    this.supplierInvoiceLine.additionalScheduleTariffs = schedules;
  }
}
