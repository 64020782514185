/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clearing-file-section.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../clearing-file-search/clearing-file-search.component.ngfactory";
import * as i3 from "../clearing-file-search/clearing-file-search.component";
import * as i4 from "../../services/importer.service";
import * as i5 from "../../services/clearing-file.service";
import * as i6 from "../../../../subjects/clearingfile-behaviour-subject";
import * as i7 from "../../../../subjects/transport-document-behaviour-subject";
import * as i8 from "../../../../subjects/supplierInvoice-behaviour-subject";
import * as i9 from "../../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i10 from "../../services/bill-of-entry.service";
import * as i11 from "../../../../subjects/billOfEntry-behaviour-subject";
import * as i12 from "../../../../subjects/addittional-info-behaviour-subject";
import * as i13 from "../../../../subjects/billOfEntryLine-behaviour-subject";
import * as i14 from "../../../../subjects/clearingInstruction-behavior-subject";
import * as i15 from "../../../../subjects/container-behavior-subject";
import * as i16 from "../../services/share-data.service";
import * as i17 from "../../../../subjects/authorisation-behaviour-subject";
import * as i18 from "primeng/components/dom/domhandler";
import * as i19 from "primeng/components/button/button";
import * as i20 from "../../../../../node_modules/primeng/components/toolbar/toolbar.ngfactory";
import * as i21 from "primeng/components/toolbar/toolbar";
import * as i22 from "@angular/common";
import * as i23 from "./clearing-file-section.component";
import * as i24 from "@angular/router";
import * as i25 from "../../saveClearingFile";
import * as i26 from "../../services/notification.service";
import * as i27 from "../../services/validation.service";
var styles_ClearingFileSectionComponent = [i0.styles];
var RenderType_ClearingFileSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClearingFileSectionComponent, data: {} });
export { RenderType_ClearingFileSectionComponent as RenderType_ClearingFileSectionComponent };
function View_ClearingFileSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-toolbar-group-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "digi-clearing-file-search", [], null, null, null, i2.View_ClearingFileSearchComponent_0, i2.RenderType_ClearingFileSearchComponent)), i1.ɵdid(3, 245760, null, 0, i3.ClearingFileSearchComponent, [i4.ImporterService, i5.ClearingFileService, i6.ClearingFileBehaviourSubject, i7.TransportDocumentBehaviourSubject, i8.SupplierInvoiceBehaviourSubject, i9.SupplierInvoiceLineBehaviourSubject, i10.BillOfEntryService, i11.BillOfEntryBehaviourSubject, i12.AdditionalInfoBehaviourSubject, i13.BillOfEntryLineBehaviourSubject, i14.ClearingInstructionBehaviorSubject, i15.ContainerBehaviorSubject, i16.ShareDataService, i1.KeyValueDiffers, i17.AuthorisationService], { isQuotation: [0, "isQuotation"], transportMethod: [1, "transportMethod"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clearingFile.quotation; var currVal_1 = _co.transportMethod; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ClearingFileSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "ui-button-secondary"], ["icon", "fa fa-file"], ["label", "New"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i18.DomHandler, i18.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i19.ButtonDirective, [i1.ElementRef, i18.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "New"; var currVal_2 = "fa fa-file"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableButtons; _ck(_v, 0, 0, currVal_0); }); }
function View_ClearingFileSectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["icon", "fa fa-plus"], ["label", "Create VOC"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createVoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i18.DomHandler, i18.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i19.ButtonDirective, [i1.ElementRef, i18.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Create VOC"; var currVal_1 = "fa fa-plus"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ClearingFileSectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["icon", "fa fa-plus"], ["label", "Create File from Quote"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createFileFromQuote() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i18.DomHandler, i18.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i19.ButtonDirective, [i1.ElementRef, i18.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Create File from Quote"; var currVal_1 = "fa fa-plus"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ClearingFileSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "p-toolbar", [["class", "toolbar"]], null, null, null, i20.View_Toolbar_0, i20.RenderType_Toolbar)), i1.ɵdid(1, 49152, null, 0, i21.Toolbar, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ClearingFileSectionComponent_1)), i1.ɵdid(3, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 9, "div", [["class", "ui-toolbar-group-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["icon", "fa fa-save"], ["label", "Save"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveClearingFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i18.DomHandler, i18.DomHandler, []), i1.ɵdid(7, 4341760, null, 0, i19.ButtonDirective, [i1.ElementRef, i18.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClearingFileSectionComponent_2)), i1.ɵdid(9, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClearingFileSectionComponent_3)), i1.ɵdid(11, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClearingFileSectionComponent_4)), i1.ɵdid(13, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showClearingFileSection; _ck(_v, 3, 0, currVal_0); var currVal_2 = "Save"; var currVal_3 = "fa fa-save"; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.canAdd; _ck(_v, 9, 0, currVal_4); var currVal_5 = ((_co.canCreateVoC && _co.hasClearingFile()) && _co.hasMrn()); _ck(_v, 11, 0, currVal_5); var currVal_6 = ((_co.hasClearingFile() && _co.clearingFile.quotation) && _co.hasBoe()); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.canDisable && (_co.isSaveDisabled() || _co.disableButtons)); _ck(_v, 5, 0, currVal_1); }); }
export function View_ClearingFileSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-clearing-file-section", [], null, null, null, View_ClearingFileSectionComponent_0, RenderType_ClearingFileSectionComponent)), i1.ɵdid(1, 245760, null, 0, i23.ClearingFileSectionComponent, [i5.ClearingFileService, i16.ShareDataService, i24.Router, i6.ClearingFileBehaviourSubject, i7.TransportDocumentBehaviourSubject, i8.SupplierInvoiceBehaviourSubject, i9.SupplierInvoiceLineBehaviourSubject, i25.SaveClearingFile, i11.BillOfEntryBehaviourSubject, i14.ClearingInstructionBehaviorSubject, i26.NotificationService, i27.ValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClearingFileSectionComponentNgFactory = i1.ɵccf("digi-clearing-file-section", i23.ClearingFileSectionComponent, View_ClearingFileSectionComponent_Host_0, { step: "step", transportMethod: "transportMethod", billOfEntry: "billOfEntry", canAdd: "canAdd", showClearingFileSection: "showClearingFileSection", canDisable: "canDisable", canCreateVoC: "canCreateVoC" }, { add: "add", display: "display" }, ["*"]);
export { ClearingFileSectionComponentNgFactory as ClearingFileSectionComponentNgFactory };
