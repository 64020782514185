<div class="ui-g-12 ui-md-12">
  <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="port" field="label"
                                  [suggestions]="filteredPortDetails"
                                  (completeMethod)="searchPort($event)"
                                  (onSelect)="selectPort($event)"
                                  [autoHighlight]="true" minLength="1">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                <label>{{label}}</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
  <div *ngIf="!port && isRequired"
       class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
          </span>
</div>
