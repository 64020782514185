import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Invoice} from '../models/financials';
import {ClearingFile} from '../models/clearingFile';
import {map} from 'rxjs/operators';
import {ExportFile} from '../models/exportFile';
import {ExBondFile} from '../models/exBondFile';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  saveInvoice(invoice: Invoice, file: ClearingFile | ExportFile | ExBondFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (file.type === 'ClearingFile' || file.type === 'Correction') {
      invoice.clearingFile = file._links.self.href;
      invoice.type = 'Invoice';
    } else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
      invoice.exportFile = file._links.self.href;
      invoice.type = 'ExportInvoice';
    } else {
      invoice.exBondFile = file._links.self.href;
      invoice.type = 'ExBondInvoice';
    }
    if (invoice._links && invoice._links.self) {
      return this.http.put(invoice._links.self.href, JSON.stringify(invoice), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/invoices', JSON.stringify(invoice), {headers});
    }
  }

  getInvoices(file: ClearingFile | ExportFile | ExBondFile): Observable<Invoice[]> {
    if (file.type === 'ClearingFile' || file.type === 'Correction') {
      return this.http.get(`${this.config.filesApiEndpoint}api/importInvoices/search/findByClearingFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].importInvoices)
        );
    } else if (file.type === 'ExportFile' || file.type === 'ExportFileCorrection') {
      return this.http.get(`${this.config.filesApiEndpoint}api/exportInvoices/search/findByExportFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].exportInvoices)
        );
    } else {
      return this.http.get(`${this.config.filesApiEndpoint}api/exBondInvoices/search/findByExBondFile_Id?id=` + file.id)
        .pipe(
          map(response => response['_embedded'].exBondInvoices)
        );
    }
  }

  generateInvoiceReports(invoiceNumber: string, from: string, to: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/invoices?invoiceNumber=${invoiceNumber}&from=${from}&to=${to}`);
  }

  generateInvoiceRegisterReport(from: string, to: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/invoiceRegister?from=${from}&to=${to}`);
  }

  generateInvoiceCsv(from: string, to: string): Observable<any> {
      return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/invoicesCsv?from=${from}&to=${to}`);
    }

  findAllInvoicesStartingWith(invoiceNo: string, indicator: string): Observable<Invoice[]> {
    if (indicator === 'imports') {
      return this.http
        .get(`${this.config.filesApiEndpoint}api/importInvoices/search/findByInvoiceNumberStartsWith?invoiceNo=${invoiceNo}&projection=flattenedInvoice`)
        .pipe(
          map(response => response['_embedded'].importInvoices)
        );
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exportInvoices/search/findByInvoiceNumberStartsWith?invoiceNo=${invoiceNo}&projection=flattenedInvoice`)
      .pipe(
        map(response => response['_embedded'].exportInvoices)
      );
  }

  findClearingFileByInvoiceSelfRefLink(invoice: Invoice): Observable<ClearingFile> {
    return this.http
      .get(invoice._links.clearingFile.href.replace('\{\?projection\}', ''));
  }
}
