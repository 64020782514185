import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Container} from '../models/file';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  uploadContainerSpreadsheet(file: File): Observable<Container[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Container[]>(this.config.filesApiEndpoint + 'api/containers/csvUpload', formData);
  }
}
