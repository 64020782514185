<div class="ui-g-12 ui-md-12">
  <br>
    <span class="md-inputfield">
      <span class="ui-inputgroup">
           <span class="ui-float-label">
                <p-autoComplete id="localForwarder" [(ngModel)]="selectedTransportDocument.localForwarder" field="name"
                                [suggestions]="filteredLocalForwarders"
                                (completeMethod)="search($event)"
                                (onSelect)="select($event)"
                                [autoHighlight]="true"
                                minLength="3"
                                required='!selectedTransportDocument.straight'>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="localForwarder">Local Forwarder</label>
           </span>
            <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
      </span>
       <div *ngIf="!clearingFile.quotation && !selectedTransportDocument?.localForwarder.name && !selectedTransportDocument.straight" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span><br><br>
  <span class="md-inputfield">
      <span class="ui-inputgroup">
           <span class="ui-float-label">
                <p-autoComplete id="cargoCarrierCode" [(ngModel)]="selectedTransportDocument.localForwarder" field="cargoCarrierCode"
                                [suggestions]="filteredLocalForwarders"
                                (completeMethod)="search($event)"
                                (onSelect)="select($event)"
                                [autoHighlight]="true"
                                minLength="3"
                                required='!selectedTransportDocument.straight'>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="cargoCarrierCode">Cargo Carrier Code</label>
           </span>
            <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
      </span>
       <div *ngIf="!clearingFile.quotation && !selectedTransportDocument?.localForwarder.cargoCarrierCode && !selectedTransportDocument.straight" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
</div>
