<p-table [columns]="cols" [value]="selectedTransportDocument.supplierInvoices" selectionMode="single"
         columnResizeMode="expand"
         [(selection)]="selectedInvoice"
         (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="5">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">{{col.header}}</th>
      <th style="width: 4%">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-supplierInvoice let-rowIndex="rowIndex">
    <tr [pSelectableRow]="supplierInvoice" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
      <td>{{supplierInvoice.invoiceNumber}}</td>
      <td>{{supplierInvoice.supplier.name}}</td>
      <td>{{supplierInvoice.defaultValues.country | country}}</td>
      <td>
        <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
