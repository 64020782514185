import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ForwardingFile} from '../../models/ForwardingFile';
import {ForwardingFileService} from '../../services/forwarding-file.service';
import {Subscription} from 'rxjs';
import {ForwardingFileBehaviourSubject} from '../../../../subjects/forwarding-file-behaviour-subject';
import {NotificationService} from '../../services/notification.service';
import {ForwardingFileValidationService} from '../../services/forwarding-file-validation.service';
import {ValidationResult} from '../../services/validation.service';

@Component({
  selector: 'digi-forwarding-file-header-section',
  templateUrl: './forwarding-file-header-section.component.html',
  styleUrls: ['./forwarding-file-header-section.component.scss']
})
export class ForwardingFileHeaderSectionComponent implements OnInit, OnDestroy {

  forwardingFile: ForwardingFile;
  filteredForwardingFiles: any[];
  forwardingFileServiceSubscription: Subscription;
  @Input() transportMethodCode: string;
  @Output() add: EventEmitter<any> = new EventEmitter();
  disableButtons: boolean;
  constructor(
    private forwardingFileService: ForwardingFileService,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private messageService: NotificationService,
    private forwardingFileValidationService: ForwardingFileValidationService
  ) { }

  ngOnInit() {
    this.forwardingFileServiceSubscription = this.forwardingFileBehaviourSubject.getForwardingFile().subscribe(file => this.forwardingFile = file);
  }

  search(event: any) {
    this.forwardingFileService.loadForwardingFile(event.query, this.transportMethodCode).subscribe(data => this.filteredForwardingFiles = data);
  }

  searchMawb(event: any) {
    this.forwardingFileService.loadForwardingFilefromMawb(event.query).subscribe(data => this.filteredForwardingFiles = data);
  }

  select(value: ForwardingFile) {
    this.forwardingFileService.selectForwardingFile(value);
  }

  addAction() {
    this.add.emit();
  }

  ngOnDestroy(): void {
    if (this.forwardingFileServiceSubscription) {
      this.forwardingFileServiceSubscription.unsubscribe();
    }
  }

  async saveForwardingFile(): Promise<void> {
    const validationResult: ValidationResult = await this.forwardingFileValidationService.validateManifests(this.forwardingFile, this.forwardingFile.transportMethodCode);
    if (validationResult.isValid) {
      this.forwardingFileService.save(this.forwardingFile).subscribe(file => {
        this.messageService.successNotify('File Saved Successfully');
        this.forwardingFileBehaviourSubject.addForwardingFile(file);
      });
    } else {
      this.showMessages(validationResult.messages);
    }
  }

  showMessages(messages: string[]) {
    this.messageService.errorNotify('Validation Failed', messages);
  }
}
