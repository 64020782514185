import { AfterViewInit, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ScrollPanel } from 'primeng/primeng';
import { AppComponent } from './app.component';
import { BreadcrumbService } from './breadcrumb.service';
var AppMenuComponent = /** @class */ (function () {
    function AppMenuComponent(app, router, breadcrumbService) {
        this.app = app;
        this.router = router;
        this.breadcrumbService = breadcrumbService;
    }
    AppMenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.layoutMenuScrollerViewChild.moveBar();
        }, 100);
    };
    AppMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.model = [
            {
                label: 'Home', icon: 'home', routerLink: ['/'], command: function (event) {
                    var innerItems = [];
                    _this.items = [];
                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                    _this.items.push(_this.home);
                    _this.breadcrumbService.setItems(_this.items);
                }
            },
            {
                label: 'SARS Dashboard',
                items: [
                    {
                        label: 'Imports / File Enquiry',
                        icon: 'file_download',
                        command: function (event) {
                            _this.router.navigate(['/common/sars-dashboard/imports']);
                        },
                    },
                    {
                        label: 'Exports / File Enquiry',
                        icon: 'file_upload',
                        command: function (event) {
                            _this.router.navigate(['/common/sars-dashboard/exports']);
                        }
                    },
                    {
                        label: 'Ex Bond',
                        icon: 'https',
                        command: function (event) {
                            _this.router.navigate(['/common/sars-dashboard/exbond']);
                        }
                    },
                    {
                        label: 'Forwarding',
                        icon: 'https',
                        command: function (event) {
                            _this.router.navigate(['/common/forwarding-dashboard/forwarding']);
                        }
                    }
                ],
                icon: 'list'
            },
            {
                label: 'Import', icon: 'file_download',
                items: [
                    {
                        label: 'Air', icon: 'airplanemode_active', command: function (event) {
                            _this.router.navigate(['/air/register', 'nq']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-airplanemode-active', routerLink: '/air/register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Sea', icon: 'directions_boat',
                        items: [
                            {
                                label: 'File',
                                command: function (event) {
                                    _this.router.navigate(['/sea/register', 'nq']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-directions-boat', routerLink: '/sea/register' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                },
                            },
                            {
                                label: 'Ships Agent',
                                command: function (event) {
                                    _this.router.navigate(['/sea/ships-agent']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'description', routerLink: '/sea/ships-agent' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            }
                        ]
                    },
                    {
                        label: 'Road', icon: 'directions_bus', command: function (event) {
                            _this.router.navigate(['/road/register']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-directions-bus', routerLink: '/road/register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Rail', icon: 'directions_railway', command: function (event) {
                        }
                    },
                    {
                        label: 'Ex Bond', icon: 'https', command: function (event) {
                            _this.router.navigate(['/exbond/register']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-https', routerLink: '/exbond/register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Reports', icon: 'description',
                        items: [
                            {
                                label: 'Bond Store Report',
                                command: function (event) {
                                    _this.router.navigate(['/common/reports/import-bond-store-reports']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/import/import-bond-store-reports' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            }
                        ]
                    }
                ]
            },
            {
                label: 'Export', icon: 'file_upload',
                items: [
                    {
                        label: 'Air', icon: 'airplanemode_active', command: function (event) {
                            _this.router.navigate(['export/air/register']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-airplanemode-active', routerLink: 'export/air/register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Sea', icon: 'directions_boat',
                        items: [
                            {
                                label: 'File',
                                command: function (event) {
                                    _this.router.navigate(['/export/sea/register']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-directions-boat', routerLink: 'export/sea/register' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                },
                            },
                            {
                                label: 'Ships Agent',
                                command: function (event) {
                                    _this.router.navigate(['/export/sea/ships-agent']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'description', routerLink: '/export/sea/ships-agent' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            }
                        ]
                    },
                    {
                        label: 'Road', icon: 'directions_bus',
                        items: [
                            {
                                label: 'File',
                                command: function (event) {
                                    _this.router.navigate(['export/road/register']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-directions-bus', routerLink: '/road/register' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                },
                            },
                            {
                                label: 'Manifests',
                                command: function (event) {
                                    _this.router.navigate(['/export/road/manifest']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'description', routerLink: '/export/road/manifest' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            }
                        ]
                    },
                    {
                        label: 'Rail', icon: 'directions_railway', command: function (event) {
                            _this.router.navigate(['/export/rail/register']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-directions-railway-active', routerLink: '/export/rail/register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Reports', icon: 'description',
                        items: [
                            {
                                label: 'Registration Report',
                                command: function (event) {
                                    _this.router.navigate(['/common/reports/export-registration-reports']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/export/export-registration-reports' };
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            }
                        ]
                    }
                ]
            },
            {
                label: 'Forwarding / RCG', icon: 'forward',
                items: [
                    {
                        label: 'Air', icon: 'airplanemode_active', command: function (event) {
                            _this.router.navigate(['/forwarding/air/manifests']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-airplanemode-active', routerLink: '/forwarding/air/manifests' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Sea', icon: 'directions_boat', command: function (event) {
                            _this.router.navigate(['/forwarding/sea/manifests']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-direction-boat', routerLink: '/forwarding/sea/manifests' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    }
                    // ,
                    // {
                    //   label: 'Tracking', icon: 'gps_fixed', command: (event) => {
                    //     this.router.navigate(['/parcel-tracking']);
                    //     const innerItems = [];
                    //     this.items = [];
                    //     this.item = <MenuItem>{icon: 'gps_fixed', routerLink: '/parcel-tracking'};
                    //     innerItems.push(this.item);
                    //     this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                    //     this.items.push(this.home);
                    //     this.breadcrumbService.setItems(this.items);
                    //   }
                    // }
                ]
            },
            {
                label: 'Quotation', icon: 'description',
                items: [
                    {
                        label: 'Import', icon: 'file_download',
                        items: [
                            {
                                label: 'Air', icon: 'airplanemode_active', command: function (event) {
                                    _this.router.navigate(['/air/register', 'iq']);
                                    var innerItems = [];
                                    _this.items = [];
                                    _this.item = { icon: 'ui-icon-airplanemode-active', routerLink: '/air/register' }; //Todo : need to edit this link (maybe fixes issue where naving from nq -> iq doesnt behave correctly) ?
                                    innerItems.push(_this.item);
                                    _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                    _this.items.push(_this.home);
                                    _this.breadcrumbService.setItems(_this.items);
                                }
                            },
                            {
                                label: 'Sea', icon: 'directions_boat',
                                items: [
                                    {
                                        label: 'File',
                                        command: function (event) {
                                            _this.router.navigate(['/sea/register', 'iq']);
                                            var innerItems = [];
                                            _this.items = [];
                                            _this.item = { icon: 'ui-icon-directions-boat', routerLink: '/sea/register' };
                                            innerItems.push(_this.item);
                                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                                            _this.items.push(_this.home);
                                            _this.breadcrumbService.setItems(_this.items);
                                        }
                                    }
                                ]
                            },
                        ]
                    }
                    //           {
                    //             label: 'Export', icon: 'file_upload',
                    //             items: [
                    //               {
                    //                 label: 'Air', icon: 'airplanemode_active', command: (event) => {
                    //                   this.router.navigate(['export/air/register']);
                    //                   const innerItems = [];
                    //                   this.items = [];
                    //                   this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: 'export/air/register'};
                    //                   innerItems.push(this.item);
                    //                   this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                    //                   this.items.push(this.home);
                    //                   this.breadcrumbService.setItems(this.items);
                    //                 }
                    //               },
                    //               {
                    //                 label: 'Sea', icon: 'directions_boat',
                    //                 items: [
                    //                   {
                    //                     label: 'File',
                    //                     command: (event) => {
                    //                       this.router.navigate(['/export/sea/register']);
                    //                       const innerItems = [];
                    //                       this.items = [];
                    //                       this.item = <MenuItem>{icon: 'ui-icon-directions-boat', routerLink: 'export/sea/register'};
                    //                       innerItems.push(this.item);
                    //                       this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                    //                       this.items.push(this.home);
                    //                       this.breadcrumbService.setItems(this.items);
                    //                     },
                    //                   }
                    //                 ]
                    //               }
                    //             ]
                    //           }
                ]
            },
            {
                label: 'Reports',
                icon: 'description',
                items: [
                    {
                        label: 'Deferred Payments',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/deferred-payments']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/deferred-payments' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'VAT Payments',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/vat-payments']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/vat-payments' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Reprint Invoices',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/reprint-invoices']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/reprint-invoices' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Invoice Register',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/invoice-register']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/invoice-register' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'VOC report',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/voc-report']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/voc-report' };
                        }
                    },
                    {
                        label: 'DA490',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/da490']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/da490' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'DA65',
                        icon: 'attach_money',
                        command: function (event) {
                            _this.router.navigate(['/common/reports/da65']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-attach-money', routerLink: '/common/reports/da65' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                ]
            },
            {
                label: 'Configuration', icon: 'settings',
                items: [
                    {
                        label: 'Business Entity', icon: 'people', command: function (event) {
                            _this.router.navigate(['/common/business-entity']);
                        },
                    },
                    {
                        label: 'Tariff Maintenance', icon: 'tune', command: function (event) {
                            _this.router.navigate(['/common/tariff-mantainance/tariff']);
                        }
                    },
                    {
                        label: 'Exchange Rate', icon: 'trending_up', command: function (event) {
                            _this.router.navigate(['/common/exchange-rates']);
                        }
                    },
                    {
                        label: 'Permits', icon: 'description', command: function (event) {
                            _this.router.navigate(['/common/permits']);
                        }
                    },
                    {
                        label: 'AWB Pool', icon: 'collections', command: function (event) {
                            _this.router.navigate(['/common/awb-pool']);
                        },
                    },
                    {
                        label: 'Parts Maintenance', icon: 'tune',
                        items: [
                            {
                                label: 'Imports',
                                icon: 'file_download',
                                command: function (event) {
                                    _this.router.navigate(['/common/parts-maintenance/imports']);
                                },
                            },
                            {
                                label: 'Exports',
                                icon: 'file_upload',
                                command: function (event) {
                                    _this.router.navigate(['/common/parts-maintenance/exports']);
                                }
                            }
                        ]
                    },
                    {
                        label: 'Crew Maintenance', icon: 'person', command: function (event) {
                            _this.router.navigate(['/common/crew-maintenance']);
                        },
                    },
                    {
                        label: 'Diagnostics', icon: 'tune', command: function (event) {
                            _this.router.navigate(['/common/diagnostics']);
                        }
                    },
                    {
                        label: 'Company', icon: 'business', command: function (event) {
                            _this.router.navigate(['/common/company']);
                        }
                    },
                ]
            },
            {
                label: 'Utilities',
                icon: 'info_outline',
                items: [
                    {
                        label: 'CPC Enquiry',
                        command: function (event) {
                            _this.router.navigate(['/common/enquiries/cpc-enquiry']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-info', routerLink: '/common/enquiries/cpc-enquiry' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Tariff Code Enquiry',
                        command: function (event) {
                            _this.router.navigate(['/common/enquiries/tariff-code-enquiry']);
                            var innerItems = [];
                            _this.items = [];
                            _this.item = { icon: 'ui-icon-info', routerLink: '/common/enquiries/tariff-code-enquiry' };
                            innerItems.push(_this.item);
                            _this.home = { icon: 'ui-icon-home', routerLink: '/', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Explanatory Notes',
                        command: function (event) {
                            window.open('https://www.sars.gov.za/?s=explanatory+notes', '_blank');
                            var innerItems = [];
                            _this.items = [];
                            _this.home = { icon: 'ui-icon-home', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Air Track Trace',
                        command: function (event) {
                            window.open('https://www.track-trace.com/aircargo', '_blank');
                            var innerItems = [];
                            _this.items = [];
                            _this.home = { icon: 'ui-icon-home', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Navis Cont. Enquiry',
                        command: function (event) {
                            window.open('http://41.160.4.49:9080/apex/cap.zul', '_blank');
                            var innerItems = [];
                            _this.items = [];
                            _this.home = { icon: 'ui-icon-home', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                    {
                        label: 'Prohibited Goods',
                        command: function (event) {
                            // tslint:disable-next-line:max-line-length
                            window.open('https://www.sars.gov.za/wp-content/uploads/Docs/ProhibitedGoods/Prohibited-and-Restricted-Imports-and-Exports-list-25-Jan-2024.xlsx', '_blank');
                            var innerItems = [];
                            _this.items = [];
                            _this.home = { icon: 'ui-icon-home', items: innerItems };
                            _this.items.push(_this.home);
                            _this.breadcrumbService.setItems(_this.items);
                        }
                    },
                ]
            }
        ];
    };
    AppMenuComponent.prototype.changeTheme = function (theme) {
        var themeLink = document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    };
    AppMenuComponent.prototype.changeLayout = function (theme) {
        var layoutLink = document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    };
    return AppMenuComponent;
}());
export { AppMenuComponent };
var AppSubMenuComponent = /** @class */ (function () {
    function AppSubMenuComponent(app, router, location, appMenu) {
        this.app = app;
        this.router = router;
        this.location = location;
        this.appMenu = appMenu;
    }
    AppSubMenuComponent.prototype.itemClick = function (event, item, index) {
        var _this = this;
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex === index) ? -1 : index;
        }
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(function () {
                _this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }
            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            }
            else {
                this.app.resetMenu = false;
            }
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    };
    AppSubMenuComponent.prototype.onMouseEnter = function (index) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    };
    AppSubMenuComponent.prototype.isActive = function (index) {
        return this.activeIndex === index;
    };
    Object.defineProperty(AppSubMenuComponent.prototype, "reset", {
        get: function () {
            return this._reset;
        },
        set: function (val) {
            this._reset = val;
            if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSubMenuComponent.prototype, "parentActive", {
        get: function () {
            return this._parentActive;
        },
        set: function (val) {
            this._parentActive = val;
            if (!this._parentActive) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AppSubMenuComponent;
}());
export { AppSubMenuComponent };
