import { Inject, Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Part} from '../models/part';
import {PartDetail} from '../models/partDetail';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findPartByPartNumberStartsWithForBranchAndBusinessEntity(partNumber: string, businessEntityCode: string, importExportIndicator: string) {
    if (partNumber === '') {
      return of([]);
    }

    const baseUrl = `${this.config.filesApiEndpoint}api/parts/search/findByPartNumberAndBusinessEntityCode`;
    return this.http
      .get(`${baseUrl}?partNumber=${partNumber}&businessEntityCode=${businessEntityCode}&importExportIndicator=${importExportIndicator}`)
      .pipe(
        map(response => response['_embedded'].parts)
      );
  }

  savePart(part: Part): Observable<any> {
    if (part._links && part._links.self) {
      return this.http.put(part._links.self.href, part);
    } else {
      return this.http.post(`${this.config.filesApiEndpoint}api/parts`, part);
    }
  }

  findByPartNumber(query: string, importExportIndicator: string): Observable<any> {
    query = query.replace(/\+/g, "%2b");
    return this.http.get(`${this.config.filesApiEndpoint}api/parts/search/findByPartNumber?partNumber=${query}&indicator=${importExportIndicator}`).pipe(
      map(response => response['_embedded'].parts));
  }

  findByPartDescription(query: string, importExportIndicator: string): Observable<any> {
      query = query.replace(/\+/g, "%2b");
      return this.http.get(`${this.config.filesApiEndpoint}api/parts/search/findByPartDescription?description=${query}&indicator=${importExportIndicator}`).pipe(
        map(response => response['_embedded'].parts));
    }

  saveParts(partDetails: PartDetail[]) {
    return this.http.post(`${this.config.filesApiEndpoint}api/parts/upload`, partDetails);
  }
}
