/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./app.topbar.component";
import * as i4 from "./app.component";
import * as i5 from "keycloak-angular";
import * as i6 from "./digi-common/services/notification.service";
import * as i7 from "../subjects/authorisation-behaviour-subject";
var styles_AppTopBarComponent = [];
var RenderType_AppTopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppTopBarComponent, data: {} });
export { RenderType_AppTopBarComponent as RenderType_AppTopBarComponent };
function View_AppTopBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "topbar-logo"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AppTopBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "div", [["class", "layout-topbar no-print"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTopBarComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["class", "menu-btn"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.app.onMenuButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\uE5D2"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["class", "topbar-menu-btn"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.app.onTopbarMobileMenuButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\uE853"])), (_l()(), i0.ɵeld(9, 0, null, null, 18, "div", [["class", "layout-topbar-menu-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 17, "ul", [["class", "topbar-menu fadeInDown"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.app.onTopbarMenuClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(12, { "topbar-menu-active": 0 }), (_l()(), i0.ɵeld(13, 0, [["profile", 1]], null, 14, "li", [["class", "profile-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.app.onTopbarRootItemClick($event, i0.ɵnov(_v, 13)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(15, { "active-topmenuitem": 0 }), (_l()(), i0.ɵeld(16, 0, null, null, 4, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "profile-image-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "img", [["src", "assets/layout/images/blank-avatar.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [["class", "topbar-item-name profile-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(20, null, ["", ""])), (_l()(), i0.ɵeld(21, 0, null, null, 6, "ul", [["class", "fadeInDown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 5, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["exit_to_app"])), (_l()(), i0.ɵeld(26, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.app.isHorizontal() && !_co.app.isMobile()); _ck(_v, 2, 0, currVal_0); var currVal_1 = "topbar-menu fadeInDown"; var currVal_2 = _ck(_v, 12, 0, _co.app.topbarMenuActive); _ck(_v, 11, 0, currVal_1, currVal_2); var currVal_3 = "profile-item"; var currVal_4 = _ck(_v, 15, 0, (_co.app.activeTopbarItem === i0.ɵnov(_v, 13))); _ck(_v, 14, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = ((_co.loggedInUser == null) ? null : _co.loggedInUser.name); _ck(_v, 20, 0, currVal_5); }); }
export function View_AppTopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-app-topbar", [], null, null, null, View_AppTopBarComponent_0, RenderType_AppTopBarComponent)), i0.ɵdid(1, 114688, null, 0, i3.AppTopBarComponent, [i4.AppComponent, i5.KeycloakService, i6.NotificationService, i7.AuthorisationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppTopBarComponentNgFactory = i0.ɵccf("digi-app-topbar", i3.AppTopBarComponent, View_AppTopBarComponent_Host_0, {}, {}, []);
export { AppTopBarComponentNgFactory as AppTopBarComponentNgFactory };
