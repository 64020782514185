import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomsOffice, Importer} from '../../../digi-business-entity/models/business-entity';
import {PaymentsReportService} from '../../services/payments-report.service';
import {NotificationService} from '../../services/notification.service';
import {ImporterService} from '../../services/importer.service';
import {CustomOfficeService} from '../../services/custom-office.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {Subscription} from 'rxjs';
import {BillOfEntry} from '../../models/billOfEntries';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-vat-payments',
  templateUrl: './vat-payments.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  .page {
      border: 9px solid transparent !important;
      background-clip: content-box !important;
      -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
  }
  `]
})
export class VatPaymentsComponent implements OnInit, OnDestroy {
  importer: Importer;
  filteredImporters: Importer[];
  fromDate: string;
  toDate: string;
  receiptDate: string;
  sarsPrn: string;
  receiptRef: string;
  customsOffice: CustomsOffice;
  display = false;
  pdfSrc: string;
  generatedFiles: any[];
  disableButton: boolean;
  filteredCustomsOffices: CustomsOffice[];
  private boesSubscription: Subscription;
  private customsOfficesSubscription: Subscription;
  private reportSubscription: Subscription;
  boes: BillOfEntry[];
  cols: any[];

  constructor(
    private paymentsReportService: PaymentsReportService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
    private customsOfficeService: CustomOfficeService,
    private billOfEntryService: BillOfEntryService,
    private authorisationService: AuthorisationService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {
        header: 'LRN'
      },
      {
        header: 'MRN'
      },
      {
        header: 'VAT',
        width: '15%'
      },
      {
        header: 'PRN'
      },
      {
        header: 'Receipt Ref.'
      },
      {
        header: 'Receipt Date'
      }
    ];
  }

  searchImporter(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  generate() {
    this.disableButton = true;
    this.reportSubscription = this.paymentsReportService.generateVatReport(this.fromDate, this.toDate, this.importer ? this.importer.code : null,
      this.customsOffice ? this.customsOffice.districtOfficeCode : null, this.sarsPrn ? this.sarsPrn : null, this.receiptDate ? this.receiptDate : null, this.receiptRef ? this.receiptRef : null).subscribe(data => {
        this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`; // to refresh link
        this.generatedFiles = data._embedded.supportingDocuments;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrc = null;
        this.disableButton = false;
      });
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  selectCustomsOffice(value: CustomsOffice) {
    this.customsOffice = value;
  }

  searchCustomsOffice(event) {
    this.customsOfficesSubscription = this.customsOfficeService.findAllCustomsOfficeStartWith(event.query).subscribe(data => {
      this.filteredCustomsOffices = data;
    });
  }

  loadBoes() {
    this.disableButton = true;
    this.authorisationService.getLoggedInUser().subscribe(user => {
      const branch = user && user.attributes && user.attributes['branch'] ? user.attributes['branch'][0] : null;
        this.boesSubscription = this.billOfEntryService.findBOEsWithVAT(this.importer.code, this.customsOffice.districtOfficeCode.trim(), this.fromDate, this.toDate, branch)
        .subscribe(boes => {
          this.boes = boes;
          this.disableButton = false;
        });
    });
  }

  saveBoes() {
    this.disableButton = true;
    const customBoes = this.boes.map(el => {
      return {id: el.id, receiptDate: el.receiptDate, receiptReference: el.receiptReference};
    });

    this.billOfEntryService.updateReceiptInfo(customBoes).subscribe(_ => this.disableButton = false);
  }

  ngOnDestroy(): void {
    if (this.boesSubscription) {
      this.boesSubscription.unsubscribe();
    }

    if (this.boesSubscription) {
      this.customsOfficesSubscription.unsubscribe();
    }

    if (this.boesSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }
}
