import {Inject, Injectable} from '@angular/core';
import {VoyageFile, VoyageMasterFile} from '../models/voyageFile';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BusinessEntity} from '../../digi-business-entity/models/business-entity';
import {Freight, Measures, OceanLineDetails, VoyageDetails} from '../models/file';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class VoyageFileService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private authorisationService: AuthorisationService
  ) {
  }

  saveVoyageFile(voyageFile: VoyageFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (voyageFile._links && voyageFile._links.self) {
      return this.http.put(voyageFile._links.self.href.replace('\{\?projection\}', ''), JSON.stringify(voyageFile), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/voyageFiles', JSON.stringify(voyageFile), {headers});
    }
  }

  findVoyageFilesByVoyageFileNo(fileNumber: string, type: string): Observable<VoyageFile[]> {
    if (fileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/voyageFiles/search/voyageFileNoStartsWith?voyageFileNo=${fileNumber}&type=${type}&projection=flattenedVoyageFile`)
      .pipe(
        map(response => response['_embedded'].voyageFiles)
      );
  }

  findVoyageFilesByVoyageNo(voyageNo: string, type: string): Observable<VoyageFile[]> {
    if (voyageNo === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/voyageFiles/search/findByVoyageNo?voyageNo=${voyageNo}&type=${type}&projection=flattenedVoyageFile`)
      .pipe(
        map(response => response['_embedded'].voyageFiles)
      );
  }

  createNewVoyageMasterFile(): VoyageMasterFile {
    const voyageMasterFile = new VoyageMasterFile();
    this.authorisationService.getLoggedInUser().pipe(
      map(user => {
        if (user && user.attributes && user.attributes['branch']) {
          voyageMasterFile.branch  = user.attributes['branch'][0];
        }
      })
    );
    voyageMasterFile.overseasAgent = new BusinessEntity();
    voyageMasterFile.localForwarder = new BusinessEntity();
    voyageMasterFile.debtor = new BusinessEntity();
    voyageMasterFile.consignee = new BusinessEntity();
    voyageMasterFile.consignor = new BusinessEntity();
    voyageMasterFile.releaseAgent = new BusinessEntity();
    voyageMasterFile.oceanLineDetails = new OceanLineDetails();
    voyageMasterFile.measures = new Measures();
    voyageMasterFile.freight = new Freight();
    voyageMasterFile.voyageDetails = new VoyageDetails();
    return voyageMasterFile;
  }

  deleteVoyageFile(id: string) {
    return this.http.delete(`${this.config.filesApiEndpoint}api/voyageFiles/${id}`);
  }
}
