import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntry} from '../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {FileType} from '../../models/enumerations';
import {File} from '../../models/file';

@Component({
  selector: 'digi-boe-package-section',
  templateUrl: './boe-package-section.component.html',
  styleUrls: ['./boe-package-section.component.scss']
})
export class BoePackageSectionComponent implements OnInit, OnChanges, OnDestroy {
  selectedBillOfEntry: BillOfEntry;
  billSubscription: Subscription;
  @Input() fileType: FileType;
  @Input() file: File;

  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && changes.file.currentValue) {
      this.file = changes.file.currentValue;
    }
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }

  isCancellation() {
    return this.file && this.file.cancellation;
  }
}
