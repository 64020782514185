import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  BusinessEntity,
  ImporterSupplier,
  Role,
  RoleNomination,
  PurchaseTerm,
  ImporterDebtor,
  ImporterRebateUser,
  ImporterRemover,
  ImporterWarehouse,
  ImporterGuarantor,
  ImporterConsignee,
  ValuationCode,
  ValuationMethod,
  ExportConsignee, OverseasAgent, Debtor, ExporterConsignee, ExporterOverseasAgent, ExporterDebtor, ExporterWarehouse, ExporterConsignor
} from '../../models/business-entity';
import {RoleMetaData, RoleNominationType} from '../../models/role-metadata.';
import {BusinessEntityService} from '../../services/business-entity.service';

@Component({
  selector: 'digi-app-role-nominations',
  templateUrl: './role-nominations.component.html',
  styleUrls: ['./role-nominations.component.scss']
})
export class RoleNominationsComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() activeRoleType: RoleMetaData;

  activeRole: Role;
  nominationTypes: RoleNominationType[];
  selectedNominationType: RoleNominationType;
  selectedRoleNomination: RoleNomination;
  filteredBusinessEntity = [];
  nominatedBusinessEntity: BusinessEntity;
  filteredNominationTypes: RoleNominationType[];
  valuationMethodTypes: any[];
  valuationCodeTypes: any[];
  filteredValuationMethodTypes = [];
  filteredValuationCodes = [];
  importerSupplier: ImporterSupplier;
  exporterConsignee: ExporterConsignee;
  selectedDefaultValidationMethod: any;
  selectedDefaultValidationCode: any;

  constructor(private businessEntityService: BusinessEntityService) {
    this.valuationMethodTypes = [
      {code: 1, description: 'Method 1', label: 'Method 1'},
      {code: 2, description: 'Method 2', label: 'Method 2'},
      {code: 3, description: 'Method 3', label: 'Method 3'},
      {code: 4, description: 'Method 4', label: 'Method 4'},
      {code: 5, description: 'Method 5', label: 'Method 5'},
      {code: 6, description: 'Method 6', label: 'Method 6'}
    ] ,
      this.valuationCodeTypes = [
        {code: 'N', description: 'Not Related', label: 'N - Not Related'},
        {code: 'R ', description: 'Related', label: 'R - Related'}
      ];
  }

  ngOnInit() {
    this.initialise();
    this.selectedDefaultValidationMethod = new ValuationMethod();
    this.selectedDefaultValidationCode = new ValuationCode();
  }

  ngOnChanges(simple: SimpleChanges) {
    if (simple.businessEntity.currentValue && simple.businessEntity.previousValue) {
      if (simple.businessEntity.currentValue.code !== simple.businessEntity.previousValue.code) {
        this.initialise();
      }
    }
  }

  private initialise() {
    this.nominationTypes = this.activeRoleType.nominations;
    this.filteredNominationTypes = this.nominationTypes;
    this.selectedRoleNomination = undefined;
    this.nominatedBusinessEntity = undefined;
    this.activeRole = this.businessEntity.roles.find(role => {
      if (role.roleType === this.activeRoleType.id) {
        return true;
      } else {
        return false;
      }
    });
  }

  addNewNomination() {
    this.initialise();
    if (this.selectedNominationType) {
      const nominatedRoleTypeId = this.selectedNominationType.id.roleId;
      if (this.activeRoleType.id === 6) {
        if (nominatedRoleTypeId === 21) {
          this.importerSupplier = new ImporterSupplier();
          this.importerSupplier.defaultPurchaseTerm = new PurchaseTerm();
          this.selectedRoleNomination = this.importerSupplier;
        }

        if (nominatedRoleTypeId === 1) {
          this.selectedRoleNomination = new ImporterDebtor();
        }
        if (nominatedRoleTypeId === 9) {
          this.selectedRoleNomination = new ImporterRebateUser();
        }
        if (nominatedRoleTypeId === 8) {
          this.selectedRoleNomination = new ImporterRemover();
        }
        if (nominatedRoleTypeId === 13) {
          this.selectedRoleNomination = new ImporterWarehouse();
        }
        if (nominatedRoleTypeId === 30) {
          this.selectedRoleNomination = new ImporterGuarantor();
        }
        if (nominatedRoleTypeId === 6) {
          this.selectedRoleNomination = new ImporterConsignee();
        }
      }

      if (this.activeRoleType.id === 12) {
        if (nominatedRoleTypeId === 2) {
          this.exporterConsignee = new ExporterConsignee();
          this.exporterConsignee.defaultPurchaseTerm = new PurchaseTerm();
          this.selectedRoleNomination = this.exporterConsignee;
        }

        if (nominatedRoleTypeId === 5) {
          this.selectedRoleNomination = new ExporterOverseasAgent();
        }

        if (nominatedRoleTypeId === 1) {
          this.selectedRoleNomination = new ExporterDebtor();
        }

        if (nominatedRoleTypeId === 13) {
          this.selectedRoleNomination = new ExporterWarehouse();
        }

        if (nominatedRoleTypeId === 3) {
          this.selectedRoleNomination = new ExporterConsignor();
        }

        if (nominatedRoleTypeId === 8) {
          this.selectedRoleNomination = new ImporterRemover();
        }

        if (nominatedRoleTypeId === 30) {
          this.selectedRoleNomination = new ImporterGuarantor();
        }
      }

      if (this.selectedRoleNomination) {
        this.selectedRoleNomination.roleType = this.selectedNominationType.id.roleId;

        if (this.activeRole) {
          if (this.activeRole.roleNominations) {
            this.activeRole.roleNominations.push(this.selectedRoleNomination);
          } else {
            this.activeRole.roleNominations = [this.selectedRoleNomination];
          }
          this.activeRole.roleNominations = [...this.activeRole.roleNominations];
        }
      }
    }
  }

  searchRoleNominationTypes(event) {
    const query = event.query;
    this.filteredNominationTypes = this.nominationTypes
      .filter(nomination => nomination.displayText.toLowerCase().indexOf(query.toLowerCase()) === 0);
  }

  searchEntityByRole(event) {
    this.businessEntityService
      .findBusinessEntityStartsWithAndRoleType(event.query, this.selectedNominationType.id.roleId).subscribe(data => {
      this.filteredBusinessEntity = data;
    });
  }

  selectBusinessEntity(businessEntity: BusinessEntity) {
    this.selectedRoleNomination.businessEntityCode = businessEntity.code;
    this.selectedRoleNomination.businessEntityName = businessEntity.name;
  }

  onRoleSelected(event: any) {
    const nomination = event.data;
    if (nomination) {
      this.selectedNominationType = this.nominationTypes.find(metaData => {
        if (nomination.roleType === metaData.id.roleId) {
          return true;
        } else {
          return false;
        }
      });
      this.selectedRoleNomination = nomination;

      //Incase defaults are missing somehow.
      if (this.activeRoleType.id === 12) {
        if (this.selectedRoleNomination.roleType === 2) {
          if (!this.selectedRoleNomination.defaultPurchaseTerm) {
            this.selectedRoleNomination.defaultPurchaseTerm = new PurchaseTerm();
          }
        }
      }

      this.nominatedBusinessEntity = new BusinessEntity();
      this.nominatedBusinessEntity.label = this.selectedRoleNomination.businessEntityCode + ' : ' + this.selectedRoleNomination.businessEntityName;
      this.nominatedBusinessEntity.code = this.selectedRoleNomination.businessEntityCode;
      this.nominatedBusinessEntity.name = this.selectedRoleNomination.businessEntityName;
      // this.selectedDefaultValidationCode = nomination.defaultValuationCode;
      //  this.selectedDefaultValidationMethod = nomination.defaultValuationMethod;
      this.valuationMethodTypes.find(method => {
        if (method.code === nomination.defaultValuationMethod) {
          this.selectedDefaultValidationMethod = method;
          return true;
        }
        return false;
      });

      this.valuationCodeTypes.find(method => {
        if (method.code === nomination.defaultValuationCode) {
          this.selectedDefaultValidationCode = method;
          return true;
        }
        return false;
      });
    }
  }

  onRoleUnSelected(event: any) {
    this.selectedNominationType = undefined;
    this.selectedDefaultValidationCode = undefined;
    this.selectedDefaultValidationCode = undefined;
  }

  onRowDelete(index) {
    this.activeRole.roleNominations.splice(index, 1);
    this.activeRole.roleNominations = [...this.activeRole.roleNominations];
    this.selectedRoleNomination = undefined;
    this.selectedNominationType = undefined;
  }

  getRoleName(roleType: any) {
    return this.nominationTypes.find(metaData => {
      if (roleType === metaData.id.roleId) {
        return true;
      } else {
        return false;
      }
    }).displayText;
  }

  searchValuationMethods(event) {
    this.filteredValuationMethodTypes = this.valuationMethodTypes.filter(v => v.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredValuationMethodTypes = [...this.filteredValuationMethodTypes];
  }

  selectValuationMethod(event) {
    this.selectedRoleNomination.defaultValuationMethod = this.selectedDefaultValidationMethod.code;
  }

  searchValuationCodes(event) {
    this.filteredValuationCodes = this.valuationCodeTypes.filter(v => v.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredValuationCodes = [...this.filteredValuationCodes];
  }

  selectValuationCode(event) {
    this.selectedRoleNomination.defaultValuationCode = this.selectedDefaultValidationCode.code;
  }
}
