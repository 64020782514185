import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var FormulaService = /** @class */ (function () {
    function FormulaService(http, config) {
        this.http = http;
        this.config = config;
    }
    FormulaService.prototype.findByFormulaNo = function (number) {
        return this.http.get(this.config.apiEndpoint + "api/formulae/search/findByFormulaNo?number=" + number);
    };
    FormulaService.ngInjectableDef = i0.defineInjectable({ factory: function FormulaService_Factory() { return new FormulaService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: FormulaService, providedIn: "root" });
    return FormulaService;
}());
export { FormulaService };
