import {Component, Input, OnInit} from '@angular/core';
import {BillOfEntry, BillOfEntryLine, ExportBillOfEntry, ExportBillOfEntryLine} from '../../models/billOfEntries';
import { ShareDataService } from '../../services/share-data.service';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { BillOfEntryLineBehaviourSubject } from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-boe-lines',
  templateUrl: './boe-lines.component.html',
  styleUrls: ['./boe-lines.component.scss']
})
export class BoeLinesComponent implements OnInit {
  billOfEntry: BillOfEntry|ExportBillOfEntry;
  selectedBillOfEntryLine: BillOfEntryLine|ExportBillOfEntryLine;
  @Input() fileType: FileType;
  @Input() markedAndSubmitted: boolean;

  constructor(private shareDataService: ShareDataService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject) {
  }

  ngOnInit() {
    this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.billOfEntry = boe;
        this.defaultSelectedLine();
      });
  }

  selectBillOfEntryLine(index: number) {
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(this.billOfEntry.lines[index]);
  }

  defaultSelectedLine() {
    this.billOfEntryLineBehaviourSubject
      .getBOELine()
      .subscribe(boeLine => {
        this.selectedBillOfEntryLine = boeLine;
      });
  }
}
