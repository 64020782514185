import {Component, OnDestroy, OnInit} from '@angular/core';
import {Container} from '../../../models/file';
import {MasContainerSizeService} from '../../../../digi-ocean/services/mas-container-size.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-mas-container-size',
  templateUrl: './mas-container-size.component.html',
  styleUrls: ['./mas-container-size.component.scss']
})
export class MasContainerSizeComponent implements OnInit, OnDestroy {
  filteredMASContainerSizes = [];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private masContainerSizeService: MasContainerSizeService,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => this.selectedContainer = ct);
  }

  search(code) {
    this.masContainerSizeService
      .findAllMASContainerSizeStartsWith(code.query)
      .subscribe(data => {
        this.filteredMASContainerSizes = data;
      });
  }

  select(value) {
    this.selectedContainer.masContainerSize = value;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
