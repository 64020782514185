<div class="ui-g-12 ui-md-12">
  <p-toolbar class="toolbar">
    <div class="ui-toolbar-group-left">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <p-autoComplete id="permitNo"
                            [(ngModel)]="selectedPermit"
                            field="permitNo"
                            [suggestions]="filteredPermits"
                            (completeMethod)="searchPermit($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectPermit($event)"
                            minLength="2"
                            [inputStyle]="{color:'white'}"
                            placeholder="Permit Number">
              <ng-template let-option pTemplate="item">
                <div>{{option.permitNo}}</div>
              </ng-template>
            </p-autoComplete>
            <span class="ui-inputgroup-addon"><i class="fa fa-search" style="color: white"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-toolbar-group-right">
      <button pButton type="button" label="Save" icon="fa fa-save" (click)="savePermit()"></button>
      <button pButton type="button" label="New" icon="fa fa-file"
              (click)="createNewPermit()"></button>
    </div>
  </p-toolbar>
</div>
<div class="ui-g-12 ui-md-12">
  <div class="ui-g-3 ui-md-3">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-g-12 ui-md-12 ui-fluid">
        <p-panel header="Permit Details">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text" pInputText [(ngModel)]="selectedPermit.permitNo">
                  <label>Permit Number</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <span class="ui-float-label">
                  <p-inputMask id="invoiceDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                 [(ngModel)]="selectedPermit.expiryDate">
                  </p-inputMask>
                  <label for="invoiceDate">Expiry Date</label>
                </span>
              </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                  <p-autoComplete [(ngModel)]="selectedPermit.type"
                                  [dropdown]="true"
                                  field="label"
                                  [suggestions]="filteredPermitTypes"
                                  (completeMethod)="searchPermitType($event)"
                                  [autoHighlight]="true"
                                  minLength="0"
                                  required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                  <label>Type</label>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                  <p-autoComplete [(ngModel)]="selectedPermit.rccType"
                                  [dropdown]="true"
                                  field="label"
                                  [suggestions]="filteredRccTypes"
                                  (completeMethod)="searchRccType($event)"
                                  [autoHighlight]="true"
                                  minLength="0"
                                  required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                  <label>RCC Type</label>
                </span>
            </div>
            <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                  <p-autoComplete [(ngModel)]="selectedPermit.businessEntityReference"
                                  [dropdown]="true"
                                  field="label"
                                  [suggestions]="filteredBusinessEntityReferences"
                                  (completeMethod)="searchBusinessEntity($event)"
                                  [autoHighlight]="true"
                                  (onSelect)="selectBusinessEntity($event)"
                                  minLength="0"
                                  required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                  <label>{{selectedPermit.type?.importExportIndicator==='E' ? 'Exporter' : 'Importer'}}</label>
                </span>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
  <div class="ui-g-9 ui-md-9">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-g-12 ui-fluid form-group">
        <p-panel header="Tariff Details">
          <div class="ui-g">
            <div class="ui-md-2 ui-g-2">
              <button pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewPermitTariff()"></button>
            </div>
          </div>
          <div class="ui-g form-group">
            <div class="ui-g-4 ui-md-4">
              <p-table [columns]="cols" [value]="selectedPermit.allocations" selectionMode="single"
                       columnResizeMode="expand"
                       [(selection)]="selectedPermitTariff" [paginator]="true" [rows]="5" (onRowSelect)="loadTariffDescription($event)">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns">{{col.header}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                  <tr *ngIf="selectedPermit.allocations" [pSelectableRow]="rowData">
                    <td>
                      {{rowData['tariffCodeReference'].code}}
                    </td>
                    <td>
                      {{rowData['tariffCodeReference'].checkDigit}}
                    </td>
                    <td>
                      <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                              (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="ui-g-8 ui-md-8">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-6 ui-md-6">
                  <span class="md-inputfield">
                          <p-autoComplete [ngModel]="selectedPermitTariff.tariffCodeReference"
                                          [dropdown]="true"
                                          field="code"
                                          [suggestions]="filteredTariffCodes"
                                          (completeMethod)="searchTariffCode($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="selectTariffCodeReference($event)"
                                          minLength="0"
                                          required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.code}}</div>
                        </ng-template>
                      </p-autoComplete>
                          <label>Tariff Code</label>
                        </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [ngModel]="tariffCodeDescription">
                      <label>Tariff Description</label>
                    </span>
                </div>
              </div>
              <p-checkbox class="ui-g-12 ui-md-12" [(ngModel)]="selectedPermitTariff.valueBased" binary="true"
                          label="Value Based?"></p-checkbox>
              <ng-container *ngIf="selectedPermitTariff">
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.value">
                      <label>Value</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.systemBalance">
                      <label>System Balance</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.allocatedValue" readonly>
                      <label>Allocated Value</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.customsBalance" readonly>
                      <label>Customs Balance</label>
                    </span>
                  </div>
                </div>
              </ng-container>
              <p-checkbox class="ui-g-12 ui-md-12" [(ngModel)]="selectedPermitTariff.quantityBased" binary="true"
                          label="Quantity Based?"></p-checkbox>
              <ng-container *ngIf="selectedPermitTariff">
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.quantityValue">
                      <label>Value</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.quantitySystemBalance">
                      <label>System Balance</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.quantityAllocatedValue" readonly>
                      <label>Allocated Quantity</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedPermitTariff.quantityCustomsBalance" readonly>
                      <label>Customs Balance</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                  <div class="ui-inputgroup">
                    <span class="ui-float-label">
                        <p-autoComplete id="uom"
                                        [ngModel]="selectedPermitTariff.uom"
                                        (completeMethod)="searchUnitOfMeasure($event)"
                                        (onSelect)="selectUnitOfMeasure($event)"
                                        [autoHighlight]="true"
                                        minLength="1"
                                        [suggestions]="filteredUoms"
                                        required=true>
                          <ng-template let-option pTemplate="item">
                            <div>{{option.label}}</div>
                          </ng-template>
                        </p-autoComplete>
                        <label for="uom">Permit UOM</label>
                    </span>
                     <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </div>
               </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</div>
