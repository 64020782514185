<div class="ui-fluid">
 <p-panel header="Bill of Entry">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-3">
      <span class="md-inputfield">
        <input type="text" pInputText value="1010001503" readonly>
        <label>BOE No</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-3">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{billOfEntry?.customsPurposeCode}}" readonly>
        <label>Purpose</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-3">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntryLine?.countryOfOrigin?.code}}" readonly>
        <label>Country of Export</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-3">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{billOfEntry?.destination?.code}}" readonly>
        <label>Destination</label>
      </span>
    </div>
  </div>
</p-panel>
</div>
