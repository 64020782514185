import {Component, OnDestroy} from '@angular/core';
import {BreadcrumbService} from './breadcrumb.service';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/primeng';
import {KeycloakService} from 'keycloak-angular';
import {NotificationService} from './digi-common/services/notification.service';

@Component({
  selector: 'digi-app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

  subscription: Subscription;

  items: MenuItem[];

  constructor(public breadcrumbService: BreadcrumbService,
              private keycloakService: KeycloakService,
              private messageService: NotificationService) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      if (response) {
      this.items = response;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.keycloakService.logout().then(() => {
        this.messageService.successNotify('Successfully Logged Out');
      }
    ).catch(function (fallback) {
      this.messageService.errorNotify('Error Logging Out!!!');
    });
  }
}
