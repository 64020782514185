import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Address,
  ClearingInstruction, Country, CustomsPurpose, CustomsPurposeFile,
  Debtor, LocalAgent,
  RebateUser, Supplier,
  SupplierInvoice, SupplierInvoiceLine,
  TransportDocument, TransportMethod
} from '../../../digi-common/models/file';
import {ClearingFile, CustomsPurposeNames} from '../../../digi-common/models/clearingFile';
import {Subscription} from 'rxjs';
import {DebtorService} from '../../../digi-common/services/debtor.service';
import {ClearingInstructionsService} from '../../../digi-common/services/clearing-instructions.service';
import {ShareDataService} from '../../../digi-common/services/share-data.service';
import {TransportMethodsService} from '../../../digi-common/services/transport-methods.service';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {AdditionalInfoBehaviourSubject} from '../../../../subjects/addittional-info-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {RebateUserService} from '../../../digi-common/services/rebate-user.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../../subjects/container-behavior-subject';
import {ActivatedRoute} from '@angular/router';
import {ClearingFileService} from '../../../digi-common/services/clearing-file.service';
import {Branch} from '../../../digi-common/models/company';
import {CompanyService} from '../../../digi-common/services/company.service';

@Component({
  selector: 'digi-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  display = false;
  openSequence = false;
  selected = false;
  showCustomsProcedures = false;
  clearingFile: ClearingFile;
  customsProcedures = [];
  rebateUserAddresses = [];
  transportMethods = [];
  availableInstructions: ClearingInstruction[];
  debtors: Debtor[];
  rebateUsers: RebateUser[];
  selectedInstructions: ClearingInstruction[] = [];
  transportDocument: TransportDocument;
  supplierInvoice: SupplierInvoice;
  supplierInvoiceLine: SupplierInvoiceLine;
  subscription: Subscription;
  clearingInstructionsSubscription: Subscription;
  suppliers: Supplier[];
  transportDocumentSubscription: Subscription;
  supplierInvoiceSubscription: Subscription;
  supplierInvoiceLineSubscription: Subscription;
  clearingFileServiceSubscription: Subscription;
  transportMethod: TransportMethod;
  clearingInstruction: ClearingInstruction;

  constructor(private debtorService: DebtorService,
              private clearingInstructionService: ClearingInstructionsService,
              private shareDataService: ShareDataService,
              private transportMethodsService: TransportMethodsService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private rebateUserService: RebateUserService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private containerBehaviorSubject: ContainerBehaviorSubject,
              private route: ActivatedRoute,
              private clearingFileService: ClearingFileService,
              private companyService: CompanyService
  ) {
  }

  ngOnInit() {
    this.findAllClearingInstructions();
    this.subscription = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
          this.companyService.getCompany().subscribe(companies => {
            if (companies && companies.length) {
              const branch: Branch = companies[0].branches[0];
              if (branch.invoicingInUse) {
                this.clearingFile.invoicingInUse = branch.invoicingInUse;
              }
            }
          });
          if (this.clearingInstructionsSubscription) {
            this.clearingInstructionsSubscription.unsubscribe();
          }
          this.clearingInstructionsSubscription = this.clearingInstructionBehaviorSubject
            .getClearingInstructions()
            .subscribe(ci => {
              if (ci) {
                this.selectedInstructions = ci;
                if (this.selectedInstructions && this.selectedInstructions.length > 0) {
                  this.transportMethodsService.findTransportMethods().subscribe(
                    transportMethods => {
                      this.transportMethods = transportMethods;
                      this.transportMethods.forEach(method => {
                        if (method.method === 'ROA') {
                          this.transportMethod = method;
                          this.clearingFile.clearingInstructions.forEach(clearingInstruction => {
                            if (clearingInstruction && !clearingInstruction.transportMethod) {
                              clearingInstruction.transportMethod = method;
                            }
                          });
                        }
                      });
                    });
                }
              } else {
                this.selectedInstructions = undefined;
              }
            });
        }
      });

    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject
      .getTransportDocuments()
      .subscribe(t => {
        if (t) {
          this.transportDocument = t;
        }
      });

    this.supplierInvoiceSubscription = this.supplierInvoiceBehaviourSubject
      .getSupplierInvoice()
      .subscribe(s => {
        if (s) {
          this.supplierInvoice = s;
        }
      });

    this.supplierInvoiceLineSubscription = this.supplierInvoiceLineBehaviourSubjectComponent
      .getSupplierInvoiceLine()
      .subscribe(l => {
        if (l) {
          this.supplierInvoiceLine = l;
        }
      });

    const clearingFileNumber = this.route.snapshot.paramMap.get('clearingFileNumber');
    const sequenceNumber = this.route.snapshot.paramMap.get('sequenceNumber');
    if (clearingFileNumber) {
      if (Number.parseInt(sequenceNumber) === 0) {
        this.clearingFileServiceSubscription = this.clearingFileService
          .loadClearingFile(clearingFileNumber, 'ROA')
          .subscribe(data => {
            const clearingFile =  data.find(cf => cf.sequence === Number.parseInt(sequenceNumber));
            this.clearingFileService.createNewFile();
            this.clearingFileService.selectClearingFile(clearingFile);
          });
      } else {
        this.clearingFileServiceSubscription = this.clearingFileService
          .loadAllCorrectionsIncludingOriginalClearingFile(clearingFileNumber, 'ROA')
          .subscribe(data => {
            const clearingFile =  data.find(cf => cf.sequence === Number.parseInt(sequenceNumber));
            this.clearingFileService.createNewFile();
            this.clearingFileService.selectClearingFile(clearingFile);
          });
      }
    }
  }

  private createClearingInstruction_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  findAllClearingInstructions() {
    this.clearingInstructionService.findAllClearingInstructions().subscribe(
      data => {
        this.availableInstructions = data._embedded.customsPurposes;
        this.updateAvailableClearingInstructions(data);
      });
  }

  private updateAvailableClearingInstructions(data) {
    // Map the clearingIntruction Object
    for (let x = 0; x < data._embedded.customsPurposes.length; x++) {
      this.availableInstructions[x].customsPurpose = new CustomsPurpose();
      this.availableInstructions[x].customsPurpose.customsPurposeName = data._embedded.customsPurposes[x].customsPurposeName;
      this.availableInstructions[x].customsPurpose.customsPurposeCode = data._embedded.customsPurposes[x].customsPurposeCode;
    }
  }

  selectClearingOption(value) {
    if (value.items.length === 1) {
      const customsPurposeFiles = value.items[0].customsPurposeFiles;
      const customsProcedures = value.items[0].customsProcedures;
      this.updateCustomsProcedure(customsProcedures);
      this.updateCustomsPurposeFile(customsPurposeFiles, this.clearingFile);
      switch (value.items[0].customsPurposeName) {
        case CustomsPurposeNames.DF:
        case CustomsPurposeNames.DP:
        case CustomsPurposeNames.WH:
        case CustomsPurposeNames.WE:
          this.setCustomsPurposeOnClearingInstruction(value);
          this.updateDestinationCountry();
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.showDialog();
          break;
        case CustomsPurposeNames.GR:
          this.setCustomsPurposeOnClearingInstruction(value);
          this.updateDestinationCountry();
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.showDialog();
          break;
        case CustomsPurposeNames.IR:
          this.setCustomsPurposeOnClearingInstruction(value);
          this.updateDestinationCountry();
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);

          this.showDialog();
          break;
        case CustomsPurposeNames.RIB:
          this.setCustomsPurposeOnClearingInstruction(value);
          this.clearingInstruction.customVatRequired = true;
          this.clearingInstruction.consolidateBoeLines = true;
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.showDialog();
          break;
        case CustomsPurposeNames.RIT:
          this.setCustomsPurposeOnClearingInstruction(value);
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.showDialog();
          break;
          case CustomsPurposeNames.IM:
            this.setCustomsPurposeOnClearingInstruction(value);
            this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
            this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.showDialog();
          break;
      }
    }
    this.findAllClearingInstructions();
  }

  private setCustomsPurposeOnClearingInstruction(value) {
    const uuid = this.createClearingInstruction_UUID();
    this.clearingFile.clearingInstructions.find(ci => {
      if (ci && (!ci.customsPurpose && !ci.customsPurpose.customsPurposeCode || !ci.destination)) {
        this.clearingInstruction = new ClearingInstruction();
        ci.uuid = uuid;
        ci.customsPurpose.customsPurposeName = value.items[0].customsPurposeName;
        ci.customsPurpose.customsPurposeCode = value.items[0].customsPurposeCode;
        ci.transportMethod = this.transportMethod;
        this.clearingInstruction = ci;
        return true;
      }
      return false;
    });
  }

  private updateCustomsProcedure(customsProcedures) {
    if (customsProcedures && customsProcedures.length === 1) {
      this.clearingFile.clearingInstructions.find(cl => {
        if (cl && (!cl.customsProcedureCode && !cl.customsProcDesc && !cl.procedureCategoryCode && !cl.pcDescription || !cl.destination)) {
          cl.customsProcedureCode = customsProcedures[0].customsProcedureCode;
          cl.customsProcDesc = customsProcedures[0].customsProcDesc;
          cl.procedureCategoryCode = customsProcedures[0].procedureCategory.procedureCategoryCode;
          cl.pcDescription = customsProcedures[0].procedureCategory.pcDescription;
          return true;
        }
        return false;
      });
    } else {
      this.customsProcedures = customsProcedures;
      this.showCustomsProcedures = true;
    }
  }

  private updateDestinationCountry() {
    this.clearingFile.clearingInstructions.find(cl => {
      if (cl && !cl.destination) {
        cl.destination = new Country();
        cl.destination.code = 'ZA';
        cl.destination.name = 'South Africa';
        cl.destination.label = 'ZA : SOUTH AFRICA';
        cl.customVatRequired = true;
        return true;
      }
      return false;
    });
  }

  private updateCustomsPurposeFile(customsPurposeFiles, clearingFile) {
    if (customsPurposeFiles) {
      customsPurposeFiles.forEach(function (customsPurposeFile) {
        if (customsPurposeFile.id.fileTypeId === 2) {
          clearingFile.clearingInstructions.find(cl => {
            if (cl.customsPurpose && cl.customsPurpose.customsPurposeFile && !cl.customsPurpose.customsPurposeFile.vocDocumentCode) {
              cl.customsPurpose.customsPurposeFile = new CustomsPurposeFile();
              cl.customsPurpose.customsPurposeFile.customsPurposeCode = customsPurposeFile.id.customsPurposeCode;
              cl.customsPurpose.customsPurposeFile.fileTypeId = customsPurposeFile.id.fileTypeId;
              cl.customsPurpose.customsPurposeFile.documentCode = customsPurposeFile.documentCode;
              cl.customsPurpose.customsPurposeFile.vocDocumentCode = customsPurposeFile.vocDocumentCode;
            }
          });
        }
      });
    }
  }

  selectedClearingInstruction(value) {
    let clearingInstruction = new ClearingInstruction();
    clearingInstruction = value.items[0];
    switch (value.items[0].customsPurpose.customsPurposeName) {
      case CustomsPurposeNames.DF:
      case CustomsPurposeNames.DP:
      case CustomsPurposeNames.WH:
      case CustomsPurposeNames.WE:
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
        this.showDialog();
        break;
      case CustomsPurposeNames.GR:
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
        this.showDialog();
        break;
      case CustomsPurposeNames.IR:
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
        this.showDialog();
        break;
      case CustomsPurposeNames.RIB:
        clearingInstruction.customVatRequired = true;
        clearingInstruction.consolidateBoeLines = true;
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
        this.showDialog();
        break;
      case CustomsPurposeNames.RIT:
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
        this.showDialog();
        break;
        case CustomsPurposeNames.IM:
        this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
          this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
          this.showDialog();
        break;
    }
  }

  onMoveToSource(value) {
    const index: number = this.clearingFile.clearingInstructions.indexOf(value);
    if (index !== -1) {
      this.clearingFile.clearingInstructions.splice(index, 1);
    }
    this.customsProcedures = [];
    this.findAllClearingInstructions();
  }

  showDialog() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  onCloseCustomProcedureModal(event) {
    this.showCustomsProcedures = event;
  }

  isCorrection() {
    return this.clearingFile.type === 'Correction';
  }

  openSequenceModal(value) {
    this.openSequence = value;
  }

  onSequenceClose(event) {
    this.openSequence = event;
  }

  createNewFile() {
    this.clearingFileService.createNewFile('ROA');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clearingInstructionsSubscription.unsubscribe();
    this.supplierInvoiceLineSubscription.unsubscribe();
    this.supplierInvoiceSubscription.unsubscribe();
    this.transportDocumentSubscription.unsubscribe();
    if (this.clearingFileServiceSubscription) {
      this.clearingFileServiceSubscription.unsubscribe();
    }
  }

  updateCustomsOffice(customOffice) {
    this.clearingFile.customOffice = customOffice;
  }

  updateLocalAgent(value: LocalAgent) {
    this.clearingFile.localAgent = value;
  }
}
