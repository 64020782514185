import * as i0 from "@angular/core";
var TariffCodeFormulaeService = /** @class */ (function () {
    function TariffCodeFormulaeService() {
    }
    TariffCodeFormulaeService.prototype.ngOnit = function () {
    };
    TariffCodeFormulaeService.prototype.effectiveTradeAgreementParameters = function (effectiveParameters, supplierInvoiceLine) {
        if (!this.isEmpty(effectiveParameters)) {
            supplierInvoiceLine.tariffCode.parameters = [];
            var trimmedCode_1 = supplierInvoiceLine.tradeAgreement.code ? supplierInvoiceLine.tradeAgreement.code.trim() : null;
            if (effectiveParameters[trimmedCode_1]) {
                Object.keys(effectiveParameters[trimmedCode_1]).forEach(function (effectiveParameterKeys) {
                    supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters[trimmedCode_1][effectiveParameterKeys]);
                });
            }
            else if (Object.keys(effectiveParameters).length === 1) {
                var tradeAgreementKeys_1 = Object.keys(effectiveParameters)[0];
                Object.keys(effectiveParameters[tradeAgreementKeys_1]).forEach(function (effectiveParameterKeys) {
                    supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters[tradeAgreementKeys_1][effectiveParameterKeys]);
                });
            }
            else if (trimmedCode_1 !== null) {
                Object.keys(effectiveParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
                    supplierInvoiceLine.tariffCode.parameters.push(effectiveParameters['STANDARD'][effectiveParameterKeys]);
                });
            }
        }
    };
    TariffCodeFormulaeService.prototype.effectiveTradeAgreementFormulae = function (effectiveFormulae, supplierInvoiceLine) {
        if (!this.isEmpty(effectiveFormulae)) {
            var trimmedCode = supplierInvoiceLine.tradeAgreement.code ? supplierInvoiceLine.tradeAgreement.code.trim() : null;
            if (effectiveFormulae[trimmedCode]) {
                var effectiveFormula = effectiveFormulae[trimmedCode];
                supplierInvoiceLine.tariffCode.formula = effectiveFormula;
            }
            else if (Object.keys(effectiveFormulae).length === 1) {
                var key = Object.keys(effectiveFormulae)[0];
                var effectiveFormula = effectiveFormulae[key];
                supplierInvoiceLine.tariffCode.formula = effectiveFormula;
            }
            else if (trimmedCode !== null) {
                var effectiveFormula = effectiveFormulae['STANDARD'];
                supplierInvoiceLine.tariffCode.formula = effectiveFormula;
            }
        }
    };
    TariffCodeFormulaeService.prototype.isEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    TariffCodeFormulaeService.ngInjectableDef = i0.defineInjectable({ factory: function TariffCodeFormulaeService_Factory() { return new TariffCodeFormulaeService(); }, token: TariffCodeFormulaeService, providedIn: "root" });
    return TariffCodeFormulaeService;
}());
export { TariffCodeFormulaeService };
