import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PortTo } from '../../../digi-common/models/file';
import { InternationalPortService } from '../../../digi-common/services/international-port.service';

@Component({
  selector: 'digi-hawb-port-to',
  templateUrl: './hawb-port-to.component.html',
  styleUrls: ['./hawb-port-to.component.scss']
})
export class HawbPortToComponent implements OnChanges {
  @Input() portTo: PortTo;
  @Output() onSelect = new EventEmitter();
  filteredPortTo: PortTo[];

  constructor(private internationalPortService: InternationalPortService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.portTo && changes.portTo.currentValue) {
      this.portTo = changes.portTo.currentValue;
    }
  }

  search(event) {
    this.internationalPortService.findAllInternationalPortStartWith(event.query).subscribe(data => {
      this.filteredPortTo = data;
    });
  }

  select(value: PortTo) {
    this.onSelect.emit(value);
  }

}
