import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class ExportsReportService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  generateExportRegistrationReports(from: string, to: string, isSea: boolean): Observable<any> {
    if (isSea) {
      return this.http.get(`${this.config.filesApiEndpoint}api/seaRegistrationReports?from=${from}&to=${to}`);
    } else {
      return this.http.get(`${this.config.filesApiEndpoint}api/registrationReports?from=${from}&to=${to}`);
    }
  }

  generateExportPackingListReport(id: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportPackingList?id=${id}`);
  }
}
