/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tariff-formulae.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tariff-formulae-table/tariff-formulae-table.component.ngfactory";
import * as i3 from "../tariff-formulae-table/tariff-formulae-table.component";
import * as i4 from "../../../../subjects/tariffCode-behavior-subject";
import * as i5 from "../../services/trade-agreement.service";
import * as i6 from "../../services/uom.service";
import * as i7 from "../../services/tariff-ref.service";
import * as i8 from "../../services/formula.service";
import * as i9 from "../../services/tariff.service";
import * as i10 from "../../../../subjects/TariffFormula-subject-behavior";
import * as i11 from "../../services/notification.service";
import * as i12 from "./tariff-formulae.component";
var styles_TariffFormulaeComponent = [i0.styles];
var RenderType_TariffFormulaeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TariffFormulaeComponent, data: {} });
export { RenderType_TariffFormulaeComponent as RenderType_TariffFormulaeComponent };
export function View_TariffFormulaeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui-g-12 ui-g-nopad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-12 pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "digi-tariff-formulae-table", [], null, null, null, i2.View_TariffFormulaeTableComponent_0, i2.RenderType_TariffFormulaeTableComponent)), i1.ɵdid(3, 245760, null, 0, i3.TariffFormulaeTableComponent, [i4.TariffCodeBehaviorSubject, i5.TradeAgreementService, i6.UomService, i7.TariffRefService, i8.FormulaService, i9.TariffService, i10.TariffFormulaSubjectBehavior, i11.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_TariffFormulaeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-tariff-formulae", [], null, null, null, View_TariffFormulaeComponent_0, RenderType_TariffFormulaeComponent)), i1.ɵdid(1, 114688, null, 0, i12.TariffFormulaeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TariffFormulaeComponentNgFactory = i1.ɵccf("digi-tariff-formulae", i12.TariffFormulaeComponent, View_TariffFormulaeComponent_Host_0, {}, {}, []);
export { TariffFormulaeComponentNgFactory as TariffFormulaeComponentNgFactory };
