<div class="layout-topbar no-print">

  <a routerLink="/" *ngIf="app.isHorizontal() && !app.isMobile()" class="topbar-logo">
<!--    <img alt="logo" src="assets/layout/images/logo-slim.png"/>-->
<!--    <span class="app-name">SERENITY</span>-->
  </a>

<!--  <img alt="logo" src="assets/layout/images/logo-slim.png" class="mobile-logo"/>-->

  <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)">
    <i class="material-icons">&#xE5D2;</i>
  </a>

  <a href="#" class="topbar-menu-btn" (click)="app.onTopbarMobileMenuButtonClick($event)">
    <i class="material-icons">&#xE853;</i>
  </a>

  <div class="layout-topbar-menu-wrapper">
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-active': app.topbarMenuActive}"
        (click)="app.onTopbarMenuClick($event)">
      <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}"
          (click)="app.onTopbarRootItemClick($event, profile)">
        <a href="#">
                    <span class="profile-image-wrapper">
                        <img src="assets/layout/images/blank-avatar.png"/>
                    </span>
          <span class="topbar-item-name profile-name">{{loggedInUser?.name}}</span>
        </a>
        <ul class="fadeInDown">
          <li role="menuitem">
            <a (click)="logout()">
              <i class="material-icons">exit_to_app</i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
