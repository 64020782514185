import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { ExchangeRate } from '../models/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getCurrency(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/currencies/` + code);
  }

  findCurrencyStartWith(code) {
    return this.http
      .get(`${this.config.apiEndpoint}api/currencies/search/codeStartsWithNonPageable?code=` + code)
      .pipe(
        map(response => response['_embedded'].currencies)
      );
  }

  getEffectiveCurrencyAndType(code, effectiveDate): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/exchangeRates/search/effectiveAtForCurrencyAndType?code=` + code + '&type=cus&' +
      'effectiveDate=' + effectiveDate);
  }

  getTypeAndEffective(type, effectiveDate): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/exchangeRates/search/effectiveAtForType?type=` + type +
      '&effectiveDate=' + effectiveDate);

  }

  getExchangeRateTypes(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/exchangeRatesTypes`);
  }

  saveExchangeRate(rate: ExchangeRate): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (rate._links && rate._links.self) {
      return this.http.put(`${this.config.apiEndpoint}api/exchangeRates`, JSON.stringify(rate), {headers});
    } else {
      return this.http.post(`${this.config.apiEndpoint}api/exchangeRates`, JSON.stringify(rate), {headers});
    }
  }

  saveExchangeRateRange(selectExchangeRateType,fromDate,toDate): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${this.config.apiEndpoint}api/exchangeRates/` + selectExchangeRateType + '/' + fromDate + '/' + toDate +'/copy', {headers});
  }
}



