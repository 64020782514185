import {Component, OnDestroy, OnInit} from '@angular/core';
import {TariffCode} from '../../models/file';
import {TariffService} from '../../services/tariff.service';
import {PermitsService} from '../services/permits.service';
import {BusinessEntityReference, Permit, PermitAllocation, PermitType, RCCType} from '../../models/permit';
import {NotificationService} from '../../services/notification.service';
import {UomService} from '../../services/uom.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrls: ['./permits.component.scss']
})
export class PermitsComponent implements OnInit, OnDestroy {
  selectedPermit: Permit;
  cols: any[];
  filteredPermitTypes: PermitType[];
  filteredRccTypes: RCCType[];
  filteredBusinessEntityReferences: BusinessEntityReference[];
  selectedPermitTariff = new PermitAllocation();
  filteredTariffCodes: TariffCode[];
  roleType = 6;
  filteredUoms: any;
  tariffCodeDescription: string;
  filteredPermits: Permit[];
  permitsSubscription: Subscription;
  permitTypeSubscription: Subscription;
  rccTypeSubscription: Subscription;
  businessEntityRefSubscription: Subscription;
  tariffCodeSubscription: Subscription;
  uomSubscription: Subscription;
  tariffDescSubscription: Subscription;

  constructor(
    private tariffService: TariffService,
    private permitsService: PermitsService,
    private messageService: NotificationService,
    private uomService: UomService
  ) {
  }

  ngOnInit() {

    this.createNewPermit();

    this.cols = [
      {
        field: 'code',
        header: 'Tariff Code'
      },
      {
        field: 'checkDigit',
        header: 'Check Digit'
      },
      {
        header: ''
      }
    ];
  }

  ngOnDestroy() {
    if (this.permitsSubscription) {
      this.permitsSubscription.unsubscribe();
    }

    if (this.permitTypeSubscription) {
      this.permitTypeSubscription.unsubscribe();
    }

    if (this.rccTypeSubscription) {
      this.rccTypeSubscription.unsubscribe();
    }

    if (this.businessEntityRefSubscription) {
      this.businessEntityRefSubscription.unsubscribe();
    }

    if (this.tariffCodeSubscription) {
      this.tariffCodeSubscription.unsubscribe();
    }

    if (this.uomSubscription) {
      this.uomSubscription.unsubscribe();
    }

    if (this.tariffDescSubscription) {
      this.tariffDescSubscription.unsubscribe();
    }
  }

  searchPermitType(event) {
    this.permitTypeSubscription = this.permitsService.findPermitTypeByCode(event.query).subscribe(
      permitTypes => this.filteredPermitTypes = permitTypes
    );
  }

  searchRccType(event) {
    this.rccTypeSubscription = this.permitsService.findRCCTypeByCode(event.query).subscribe(
      rccTypes => this.filteredRccTypes = rccTypes
    );
  }

  searchBusinessEntity(event) {
    const roleType = this.selectedPermit.type && this.selectedPermit.type.importExportIndicator === 'E' ? 12 : 6;
    this.businessEntityRefSubscription = this.permitsService.findBusinessEntity(event.query, roleType).subscribe(
      (businessEntityReferences: BusinessEntityReference[]) => this.filteredBusinessEntityReferences = businessEntityReferences
    );
  }

  searchTariffCode(event) {
    this.tariffCodeSubscription = this.tariffService
      .findTariffByCodeStartsWith(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  createNewPermit() {
    this.selectedPermit = new Permit();
    this.selectedPermit.allocations = [];
    this.createNewPermitTariff();
  }

  savePermit() {
    this.permitsSubscription = this.permitsService.savePermit(this.selectedPermit).subscribe(
      result => {
        this.selectedPermit = result;
        this.messageService.successNotify('Permit Saved Successfully');
      },
      error => {
        if (error.status === 409) {
          this.messageService.errorNotify('A permit with this permit number already exists');
        }
      }
    );
  }

  createNewPermitTariff() {
    this.selectedPermitTariff = new PermitAllocation();
    this.tariffCodeDescription = '';
    this.selectedPermit.allocations.push(this.selectedPermitTariff);
    this.selectedPermit.allocations = [...this.selectedPermit.allocations];
  }

  searchPermit(event) {
    this.permitsSubscription = this.permitsService.findPermitsByCode(event.query).subscribe(
      (permits: Permit[]) => this.filteredPermits = permits
    );
  }

  selectBusinessEntity(event) {
    this.selectedPermit.businessEntityReference = new BusinessEntityReference();
    this.selectedPermit.businessEntityReference.code = event.code;
    this.selectedPermit.businessEntityReference.name = event.name;
    this.selectedPermit.businessEntityReference.roleType = this.selectedPermit.type.importExportIndicator === 'I' ? 6 : 12;
    this.selectedPermit.businessEntityReference.label = event.label;
  }

  searchUnitOfMeasure(event) {
    this.uomSubscription = this.uomService.findUomByCodeStartWith(event.query)
      .subscribe(data => {
        this.filteredUoms = data;
      });
  }

  selectUnitOfMeasure(event) {
    this.selectedPermitTariff.uom = event.uomCode;
  }

  selectTariffCodeReference(event) {
    this.selectedPermitTariff.tariffCodeReference.code = event.code;
    this.selectedPermitTariff.tariffCodeReference.checkDigit = event.tariffCheckDigit;
    this.selectedPermitTariff.tariffCodeReference.tariffId = event.tariffID;
    this.tariffCodeDescription = event.tariffSDesc;
  }

  loadTariffDescription(event) {
    this.tariffDescSubscription = this.tariffService
      .findTariffCodeByCodeAndCheckDigit(event.data.tariffCodeReference.code, event.data.tariffCodeReference.checkDigit)
      .subscribe((tariffCode: TariffCode) => {
        this.tariffCodeDescription = tariffCode.tariffSDesc;
      });
  }

  selectPermit(event: Permit) {
    this.selectedPermit = event;
    this.roleType = event.businessEntityReference.roleType;
  }

  onRowDelete(rowIndex: number) {
    this.selectedPermit.allocations.splice(rowIndex, 1);
    this.selectedPermit.allocations = [...this.selectedPermit.allocations];
    this.selectedPermitTariff = new PermitAllocation();
    this.tariffCodeDescription = '';
  }
}
