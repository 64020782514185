<div class="row">
  <!-- <digi-air-context-navigation>
    <a routerLink="/air/register" class="nav-link text-white" title="Hawbs">
      <i class="fa fa-user-plus"></i>Register
    </a>
    <a routerLink="/air/planning" class="nav-link text-white" title="Marks Numbers">
      <i class="fa fa-calendar"></i>Planning
    </a>
    <a routerLink="/air/documents" class="nav-link text-white" title="Marks Numbers">
      <i class="fa fa-calendar"></i>Documents
    </a>
  </digi-air-context-navigation> -->
  Planning
</div>
