import {Airline, Commodity, RouteDetails} from './file';

export class AWBType {
  code?: string;
  description?: string;
}

export class WaybillUsage {
  fileNumber?: string;
}

export class Exporter {
  code?: string;
  name?: string;
  label?: string;
}
export class WaybillReservation {
  exporter?: Exporter;
  description?: string;
  commodity?: Commodity;
  weight?: number;
  routeDetails1?: RouteDetails;
  routeDetails2?: RouteDetails;
  routeDetails3?: RouteDetails;
}

export class WaybillNumber {
  number?: string;
  waybillUsages?: WaybillUsage[];
  waybillReservation?: WaybillReservation;
}

export class AirWaybill {
  airline?: Airline;
  startNumber?: string;
  endNumber?: string;
  creationDate?: string;
  agentAccountNo?: string;
  waybillNumbers?: WaybillNumber[];
  awbType?: AWBType;
  [k: string]: any;
}
