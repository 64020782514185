import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillOfEntry } from '../../models/billOfEntries';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-bill-of-entry-table',
  templateUrl: './bill-of-entry-table.component.html',
  styleUrls: ['./bill-of-entry-table.component.scss']
})
export class BillOfEntryTableComponent implements OnInit {
  @Input('billsOfEntry') billsOfEntry;
  @Input() fileType: FileType;
  @Output() selectedBillOfEntryHeader: EventEmitter<any> = new EventEmitter();
  @Input('selectedBillOfEntry') selectedBillOfEntry;
  boes: BillOfEntry[] = new Array<BillOfEntry>();

  cols: any[];

  constructor() {
  }

  ngOnInit() {
    if (this.fileType === 'exbond') {
      this.cols = [
        {field: 'houseNumber', header: 'In-House No', width: '11%'},
        {field: 'lrn', header: 'LRN', width: '20%'},
        {field: 'serialNumber', header: 'Serial No', width: '8%'},
        {field: 'cpc', header: 'CPC', width: '8%'},
        {field: 'noOfLines', header: 'Lines', width: '5%'},
        {field: 'localAgent', header: 'Local Agent', width: '5%'}
      ];
    } else {
      this.cols = [
        {field: 'houseNumber', header: 'In-House No', width: '11%'},
        {field: 'lrn', header: 'LRN', width: '20%'},
        {field: 'serialNumber', header: 'Serial No', width: '8%'},
        {field: 'cpc', header: 'CPC', width: '8%'},
        {field: 'transportDocNo', header: 'Trans Doc', width: '9%'},
        {field: 'assessmentDate', header: 'Assessment', width: '11%'},
        {field: 'noOfLines', header: 'Lines', width: '5%'},
        {field: 'export', header: 'Exp', width: '5%'},
        {field: 'destination', header: 'Dest', width: '5%'},
        {field: 'sobDate', header: 'SOB Date', width: '11%'},
      ];
    }
  }

  onRowSelect(value): void {
    this.selectedBillOfEntryHeader.emit(value.index);
  }


}
