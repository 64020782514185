import {Component, Input, OnInit} from '@angular/core';
import {Country, TariffCode, TradeAgreement} from '../../models/file';
import {TariffService} from '../../services/tariff.service';
import {MenuItem} from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'digi-tariff-enquiry',
  templateUrl: './tariff-enquiry.component.html',
  styleUrls: ['./tariff-enquiry.component.scss']
})
export class TariffEnquiryComponent implements OnInit {
  activeIndex: any;
  items: MenuItem[];
  tariffCode = new TariffCode();
  selectedTariffCode = new TariffCode();
  filteredTariffCodes: TariffCode[];
  effectiveDate: string;
  tradeAgreement: TradeAgreement = { code: 'STANDARD'};
  filteredTradeAgreements: TradeAgreement[] = [];

  constructor(
    private tariffService: TariffService
  ) { }

  ngOnInit() {
    this.effectiveDate = moment().format('DD/MM/YYYY');
  }

  searchTariffCodes(query: any) {
    this.tariffService.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(query)
      .subscribe(tariffCodes => this.filteredTariffCodes = tariffCodes);
  }

  selectTariffCode(value: any) {
    this.selectedTariffCode = value;
    if (this.tariffCode.effectiveTradeAgreementFormulae) {
      this.filteredTradeAgreements = Object.keys(this.tariffCode.effectiveTradeAgreementFormulae).map(el => {
        return {'code': el};
      });
      this.filteredTradeAgreements = [...this.filteredTradeAgreements];
    }
  }

  selectTradeAgreement(event: any) {
  }

  searchTradeAgreement() {
    this.filteredTradeAgreements = [...this.filteredTradeAgreements];
  }

  updateTariffCode() {
    if (moment(this.effectiveDate, 'DD/MM/YYYY').isValid() && this.selectedTariffCode && this.selectedTariffCode.code) {
      this.searchTariffCodes(this.selectedTariffCode.code);
    }
  }
}
