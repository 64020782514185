var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BusinessEntity = /** @class */ (function () {
    function BusinessEntity() {
    }
    return BusinessEntity;
}());
export { BusinessEntity };
var Address = /** @class */ (function () {
    function Address() {
    }
    return Address;
}());
export { Address };
var Role = /** @class */ (function () {
    function Role() {
    }
    return Role;
}());
export { Role };
var Importer = /** @class */ (function (_super) {
    __extends(Importer, _super);
    function Importer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Importer;
}(Role));
export { Importer };
var Debtor = /** @class */ (function (_super) {
    __extends(Debtor, _super);
    function Debtor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Debtor;
}(Role));
export { Debtor };
var Currency = /** @class */ (function () {
    function Currency() {
    }
    return Currency;
}());
export { Currency };
var Country = /** @class */ (function () {
    function Country() {
    }
    return Country;
}());
export { Country };
var TradeAgreement = /** @class */ (function () {
    function TradeAgreement() {
    }
    return TradeAgreement;
}());
export { TradeAgreement };
var RebateUser = /** @class */ (function (_super) {
    __extends(RebateUser, _super);
    function RebateUser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RebateUser;
}(Role));
export { RebateUser };
var ReceivingBank = /** @class */ (function () {
    function ReceivingBank() {
    }
    return ReceivingBank;
}());
export { ReceivingBank };
var Bank = /** @class */ (function () {
    function Bank() {
    }
    return Bank;
}());
export { Bank };
var Exporter = /** @class */ (function (_super) {
    __extends(Exporter, _super);
    function Exporter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Exporter;
}(Role));
export { Exporter };
var Supplier = /** @class */ (function (_super) {
    __extends(Supplier, _super);
    function Supplier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Supplier;
}(Role));
export { Supplier };
var ExportConsignee = /** @class */ (function (_super) {
    __extends(ExportConsignee, _super);
    function ExportConsignee() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExportConsignee;
}(Role));
export { ExportConsignee };
var OverseasAgent = /** @class */ (function (_super) {
    __extends(OverseasAgent, _super);
    function OverseasAgent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OverseasAgent;
}(Role));
export { OverseasAgent };
var CustomsTraderType = /** @class */ (function () {
    function CustomsTraderType() {
    }
    return CustomsTraderType;
}());
export { CustomsTraderType };
var RoleNomination = /** @class */ (function () {
    function RoleNomination() {
    }
    return RoleNomination;
}());
export { RoleNomination };
var ImporterSupplier = /** @class */ (function (_super) {
    __extends(ImporterSupplier, _super);
    function ImporterSupplier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterSupplier;
}(RoleNomination));
export { ImporterSupplier };
var ValuationMethod = /** @class */ (function () {
    function ValuationMethod() {
    }
    return ValuationMethod;
}());
export { ValuationMethod };
var ValuationCode = /** @class */ (function () {
    function ValuationCode() {
    }
    return ValuationCode;
}());
export { ValuationCode };
var Creditor = /** @class */ (function (_super) {
    __extends(Creditor, _super);
    function Creditor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Creditor;
}(Role));
export { Creditor };
var LocalAgent = /** @class */ (function (_super) {
    __extends(LocalAgent, _super);
    function LocalAgent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LocalAgent;
}(Role));
export { LocalAgent };
var LocalAgentCustomOfficeBond = /** @class */ (function () {
    function LocalAgentCustomOfficeBond() {
    }
    return LocalAgentCustomOfficeBond;
}());
export { LocalAgentCustomOfficeBond };
var CustomsOffice = /** @class */ (function () {
    function CustomsOffice() {
    }
    return CustomsOffice;
}());
export { CustomsOffice };
var Branch = /** @class */ (function (_super) {
    __extends(Branch, _super);
    function Branch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Branch;
}(Role));
export { Branch };
var PurchaseTerm = /** @class */ (function () {
    function PurchaseTerm() {
    }
    return PurchaseTerm;
}());
export { PurchaseTerm };
var RoleAddress = /** @class */ (function () {
    function RoleAddress() {
    }
    return RoleAddress;
}());
export { RoleAddress };
var AddressType = /** @class */ (function () {
    function AddressType() {
    }
    return AddressType;
}());
export { AddressType };
var ImporterDebtor = /** @class */ (function (_super) {
    __extends(ImporterDebtor, _super);
    function ImporterDebtor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterDebtor;
}(RoleNomination));
export { ImporterDebtor };
var ImporterRebateUser = /** @class */ (function (_super) {
    __extends(ImporterRebateUser, _super);
    function ImporterRebateUser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterRebateUser;
}(RoleNomination));
export { ImporterRebateUser };
var ImporterRemover = /** @class */ (function (_super) {
    __extends(ImporterRemover, _super);
    function ImporterRemover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterRemover;
}(RoleNomination));
export { ImporterRemover };
var ImporterWarehouse = /** @class */ (function (_super) {
    __extends(ImporterWarehouse, _super);
    function ImporterWarehouse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterWarehouse;
}(RoleNomination));
export { ImporterWarehouse };
var ImporterGuarantor = /** @class */ (function (_super) {
    __extends(ImporterGuarantor, _super);
    function ImporterGuarantor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterGuarantor;
}(RoleNomination));
export { ImporterGuarantor };
var ImporterConsignee = /** @class */ (function (_super) {
    __extends(ImporterConsignee, _super);
    function ImporterConsignee() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImporterConsignee;
}(RoleNomination));
export { ImporterConsignee };
var ExporterConsignee = /** @class */ (function (_super) {
    __extends(ExporterConsignee, _super);
    function ExporterConsignee() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExporterConsignee;
}(RoleNomination));
export { ExporterConsignee };
var ExporterOverseasAgent = /** @class */ (function (_super) {
    __extends(ExporterOverseasAgent, _super);
    function ExporterOverseasAgent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExporterOverseasAgent;
}(RoleNomination));
export { ExporterOverseasAgent };
var ExporterDebtor = /** @class */ (function (_super) {
    __extends(ExporterDebtor, _super);
    function ExporterDebtor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExporterDebtor;
}(RoleNomination));
export { ExporterDebtor };
var ExporterWarehouse = /** @class */ (function (_super) {
    __extends(ExporterWarehouse, _super);
    function ExporterWarehouse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExporterWarehouse;
}(RoleNomination));
export { ExporterWarehouse };
var ExchangeRateType = /** @class */ (function () {
    function ExchangeRateType() {
    }
    return ExchangeRateType;
}());
export { ExchangeRateType };
var TaxType = /** @class */ (function () {
    function TaxType() {
    }
    return TaxType;
}());
export { TaxType };
var PaymentType = /** @class */ (function () {
    function PaymentType() {
    }
    return PaymentType;
}());
export { PaymentType };
var DepotTerminal = /** @class */ (function () {
    function DepotTerminal() {
    }
    return DepotTerminal;
}());
export { DepotTerminal };
var CustomOffice = /** @class */ (function () {
    function CustomOffice() {
    }
    return CustomOffice;
}());
export { CustomOffice };
var LocalAgentCustomOfficeCreditor = /** @class */ (function () {
    function LocalAgentCustomOfficeCreditor() {
    }
    return LocalAgentCustomOfficeCreditor;
}());
export { LocalAgentCustomOfficeCreditor };
var CreditorNominated = /** @class */ (function () {
    function CreditorNominated() {
    }
    return CreditorNominated;
}());
export { CreditorNominated };
var Guarantor = /** @class */ (function (_super) {
    __extends(Guarantor, _super);
    function Guarantor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Guarantor;
}(Role));
export { Guarantor };
var GuaranteeType = /** @class */ (function () {
    function GuaranteeType() {
    }
    return GuaranteeType;
}());
export { GuaranteeType };
var Remover = /** @class */ (function (_super) {
    __extends(Remover, _super);
    function Remover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Remover;
}(Role));
export { Remover };
var Warehouse = /** @class */ (function (_super) {
    __extends(Warehouse, _super);
    function Warehouse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Warehouse;
}(Role));
export { Warehouse };
var Haulier = /** @class */ (function (_super) {
    __extends(Haulier, _super);
    function Haulier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Haulier;
}(Role));
export { Haulier };
var NominatedBusinessEntity = /** @class */ (function () {
    function NominatedBusinessEntity() {
    }
    return NominatedBusinessEntity;
}());
export { NominatedBusinessEntity };
var ExporterConsignor = /** @class */ (function (_super) {
    __extends(ExporterConsignor, _super);
    function ExporterConsignor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExporterConsignor;
}(RoleNomination));
export { ExporterConsignor };
var Consignor = /** @class */ (function (_super) {
    __extends(Consignor, _super);
    function Consignor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Consignor;
}(Role));
export { Consignor };
