import {BusinessEntity} from './file';

export class ReferenceCategory {
  code?: string;
  description?: string;
}

export class Industry {
  code?: string;
  description?: string;
}

export class TariffCode {
  code?: string;
  checkDigit?: string;
  description?: string;
  tariffID?: number;
  statisticalUOM?: string;
}

export class Part {
  id: string;
  partNumber: string;
  interfaceDate: Date;
  importExportIndicator?: string;
  businessEntity?: BusinessEntity;
  referenceCategory?: ReferenceCategory;
  industry?: Industry;
  description?: string;
  tariffCode?: string;

  [k: string]: any;
}














