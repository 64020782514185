import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var TransportMethodsService = /** @class */ (function () {
    function TransportMethodsService(http, config) {
        this.http = http;
        this.config = config;
    }
    TransportMethodsService.prototype.findTransportMethods = function () {
        return this.http.get(this.config.apiEndpoint + "api/transportMethods").pipe(map(function (response) { return response['_embedded'].transportMethods.map(function (method) {
            var transportMethod = {
                code: String,
                method: String,
                description: String
            };
            transportMethod.code = method.boetransportCode;
            transportMethod.method = method.transportMethod;
            transportMethod.description = method.transportMethodDesc;
            return transportMethod;
        }); }));
    };
    TransportMethodsService.ngInjectableDef = i0.defineInjectable({ factory: function TransportMethodsService_Factory() { return new TransportMethodsService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: TransportMethodsService, providedIn: "root" });
    return TransportMethodsService;
}());
export { TransportMethodsService };
