<button pButton type="button" icon="pi pi-plus" title="Add Column" (click)="addRateBasedCharge()" style="left: 95%"></button>
<div class="ui-g">
  <div class="ui-g-2"><span class="ui-inputgroup-addon">Charge</span></div>
  <div class="ui-g-2"><span class="ui-inputgroup-addon">Currency</span></div>
  <div class="ui-g-1"><span class="ui-inputgroup-addon">Collect</span></div>
  <div class="ui-g-1"><span class="ui-inputgroup-addon">Base Amount/Value</span></div>
  <div class="ui-g-1"><span class="ui-inputgroup-addon">Rate</span></div>
  <div class="ui-g-2"><span class="ui-inputgroup-addon">Amount</span></div>
  <div class="ui-g-1"><span class="ui-inputgroup-addon">Dutiable</span></div>
  <div class="ui-g-1"><span class="ui-inputgroup-addon">Non Dutiable</span></div>
</div>
<div class="ui-g ui-g-nopad ui-fluid" *ngFor="let rateBasedCharge of rateBasedCharges; let i=index">
  <div class="ui-g-2">
    <digi-sundry-search [sundryCharge]="rateBasedCharges[i].sundryCharge" [sundryChargeType]="2"></digi-sundry-search>
  </div>
  <div class="ui-g-2">
    <digi-currency-nested [currency]="rateBasedCharges[i].currency"></digi-currency-nested>
  </div>
  <div class="ui-g-1">
    <div class="ui-g-12">
      <p-checkbox binary="true" [(ngModel)]="rateBasedCharges[i].collect"></p-checkbox>
    </div>
  </div>
  <div class="ui-g-1">
    <div class="ui-inputgroup">
      <input type="text" pInputText [(ngModel)]="rateBasedCharges[i].baseAmount">
    </div>
  </div>
  <div class="ui-g-1">
    <div class="ui-inputgroup">
      <input type="text" pInputText [(ngModel)]="rateBasedCharges[i].rate">
    </div>
  </div>
  <div class="ui-g-2">
    <div class="ui-inputgroup">
      <input type="text" pInputText [(ngModel)]="rateBasedCharges[i].amount">
    </div>
  </div>
  <div class="ui-g-1 pt-1">
    <div class="ui-g-12">
      <p-checkbox binary="true" [(ngModel)]="rateBasedCharges[i].dutiable"></p-checkbox>
    </div>
  </div>
  <div class="ui-g-1 pt-1">
    <div class="ui-g-12">
      <p-checkbox binary="true" [(ngModel)]="rateBasedCharges[i].nonDutiable"></p-checkbox>
    </div>
  </div>
  <div class="ui-g-1 pt-1">
    <div class="ui-g-6">
      <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
              (click)="onRowDelete(i)" pTooltip="Delete"></button>
    </div>
  </div>
</div>
