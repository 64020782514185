<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad pt-2">
    <div class="ui-inputgroup">
      <span *ngIf="label" class="ui-inputgroup-addon">{{label}}</span>
      <p-autoComplete [(ngModel)]="selectedCurrency" appendTo="body" [baseZIndex]="10000" field="label" [dropdown] = "true" [suggestions]="filteredCurrencies"
                      (completeMethod)="search($event)" [autoHighlight]="true" (onSelect)="select($event)"
                      minLength="1"
                      [forceSelection]="true"
                      required=true>
        <ng-template let-option pTemplate="item">
          <div>{{option.code}}: {{option.name}}</div>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
</div>
