import { BehaviorSubject } from 'rxjs';
import { ShareDataService } from '../app/digi-common/services/share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/services/share-data.service";
var BillOfEntryBehaviourSubject = /** @class */ (function () {
    function BillOfEntryBehaviourSubject(shareDataService) {
        this.shareDataService = shareDataService;
        this.billOfEntrySubject = new BehaviorSubject(null);
        this.billsOfEntrySubject = new BehaviorSubject([]);
    }
    BillOfEntryBehaviourSubject.prototype.addBillOfEntry = function (billOfEntry) {
        this.billOfEntrySubject.next(billOfEntry);
    };
    BillOfEntryBehaviourSubject.prototype.getBillOfEntry = function () {
        return this.billOfEntrySubject.asObservable();
    };
    BillOfEntryBehaviourSubject.prototype.addBillsOfEntry = function (billOfEntry) {
        this.billsOfEntrySubject.next(billOfEntry);
    };
    BillOfEntryBehaviourSubject.prototype.getBillsOfEntry = function () {
        return this.billsOfEntrySubject.asObservable();
    };
    BillOfEntryBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function BillOfEntryBehaviourSubject_Factory() { return new BillOfEntryBehaviourSubject(i0.inject(i1.ShareDataService)); }, token: BillOfEntryBehaviourSubject, providedIn: "root" });
    return BillOfEntryBehaviourSubject;
}());
export { BillOfEntryBehaviourSubject };
