<div class="ui-g-12">
  <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
</div>
<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Enquiry" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-fluid ui-g-8">
              <div class="ui-g-4 ui-md-4">
                <div class="ui-inputgroup">
                  <p-autoComplete id="tariffCode" [(ngModel)]="tariffCode" field="code"
                                  [suggestions]="filteredTariffCodes"
                                  (completeMethod)="searchTariffCodes($event.query)"
                                  [autoHighlight]="true"
                                  (onSelect)="selectTariffCode($event)"
                                  minLength="2"
                                  [inputStyle]="{color:'white'}"
                                  placeholder="Tariff Code">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}</div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
            </div>
          </p-toolbar>
          <div class="ui-g-12 ui-md-12">
            <p-panel>
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <div class="ui-g form-group">
                      <div class="ui-g-4 ui-md-4">
                        <span class="md-inputfield">
                          <div class="ui-inputgroup">
                            <span class="ui-float-label">
                              <p-autoComplete id="tradeAgreement" [(ngModel)]="tradeAgreement" field="code"
                                              [suggestions]="filteredTradeAgreements"
                                              (completeMethod)="searchTradeAgreement()"
                                              [autoHighlight]="true" (onSelect)="selectTradeAgreement($event)" minLength="2"
                                              [dropdown]="true"
                                              required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}</div>
                                  </ng-template>
                              </p-autoComplete>
                              <label for="tradeAgreement">Trade Agreement</label>
                            </span>
                          </div>
                        </span>
                      </div>
                      <div class="ui-g-4 ui-md-4">
                        <span class="md-inputfield">
                          <div class="ui-float-label">
                            <span class="md-inputfield">
                              <p-inputMask id="effectiveDate" mask="99/99/9999"
                                         slotChar="dd/mm/yyyy"
                                           (change)="updateTariffCode()"
                                         [(ngModel)]="effectiveDate">

                              </p-inputMask>
                            <label for="effectiveDate">Effective Date</label>
                            </span>
                          </div>
                        </span>
                      </div>
                      <div class="ui-g-4 ui-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <p-tabView>
            <p-tabPanel header="Summary">
              <digi-summary [tariffCode]="selectedTariffCode" [tradeAgreement]="tradeAgreement"></digi-summary>
            </p-tabPanel>
            <p-tabPanel header="Dependants">
              <digi-dependants [tariffCode]="selectedTariffCode"></digi-dependants>
            </p-tabPanel>
            <p-tabPanel header="Formula History">
              <digi-tariff-history [tariffCode]="selectedTariffCode"></digi-tariff-history>
            </p-tabPanel>
            <p-tabPanel header="Calculations">
              <digi-tariff-calculations [tariffCode]="selectedTariffCode"></digi-tariff-calculations>
            </p-tabPanel>
          </p-tabView>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
