import {Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {BillOfEntry} from '../../digi-common/models/billOfEntries';
import {ClearingFile} from '../../digi-common/models/clearingFile';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {ExBondBillOfEntryService} from '../services/ex-bond-bill-of-entry.service';
import {NotificationService} from '../../digi-common/services/notification.service';
import {LoadingService} from '../../digi-common/components/loading.service';

@Component({
  selector: 'digi-upload-documents',
  templateUrl: './documents.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class DocumentsComponent implements OnInit, OnDestroy, OnChanges {
  clearingFile: ClearingFile;
  exBondFile: ExBondFile;
  @Input() files;
  selectedFiles: any[];
  cols: any[];
  subscription: Subscription;
  billOfEntrySubscription: Subscription;
  listOfFiles: any[];
  billOfEntry: BillOfEntry;

  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private exBondBillOfEntryService: ExBondBillOfEntryService,
              private messageService: NotificationService,
              private billOfEntryBehaviorSubject: BillOfEntryBehaviourSubject,
              private loadingSubject: LoadingService
  ) {
  }

  ngOnInit() {
    this.setListOfFiles();
    this.billOfEntrySubscription = this.billOfEntryBehaviorSubject.getBillOfEntry()
      .subscribe(billOfEntry => {
        this.billOfEntry = billOfEntry;
      });
  }

  ngOnChanges() {
    this.setListOfFiles();
  }

  private setListOfFiles() {
    if (this.files) {
      this.listOfFiles = this.files;
    }
  }

  afterFileUpload() {
    this.loadUpLoadedFiles();
    this.loadingSubject.addLoader(false);
    this.messageService.successNotify('File Uploaded');
  }

  onFileUploadError(event) {
    const error = JSON.parse(event.xhr.response);
    this.loadUpLoadedFiles();
    this.loadingSubject.addLoader(false);
    this.messageService.errorNotify(error.message, [error.errors[0]]);
  }

  private loadUpLoadedFiles() {
    this.exBondBillOfEntryService.loadUploadedFile(this.billOfEntry)
      .subscribe(uploadedFiles => {
        if (uploadedFiles && uploadedFiles['_embedded']) {
          this.files = [];
          this.files.push(...uploadedFiles._embedded.supportingDocuments);
          this.listOfFiles = this.files;
          this.files = [...this.files];
        }
      });
  }

  beforeFileUpload() {
    this.loadingSubject.addLoader(true);
  }

  ngOnDestroy() {
    this.billOfEntrySubscription.unsubscribe();
  }
}
