import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var MasContainerTypeService = /** @class */ (function () {
    function MasContainerTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    MasContainerTypeService.prototype.findAllMASContainerTypeStartsWith = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/masContainerTypes/search/typeStartsWith?code=" + searchValue)
            .pipe(map(function (response) { return response['_embedded'].masContainerTypes; }));
    };
    MasContainerTypeService.ngInjectableDef = i0.defineInjectable({ factory: function MasContainerTypeService_Factory() { return new MasContainerTypeService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: MasContainerTypeService, providedIn: "root" });
    return MasContainerTypeService;
}());
export { MasContainerTypeService };
