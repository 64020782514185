import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var BillOfEntryService = /** @class */ (function () {
    function BillOfEntryService(http, config) {
        this.http = http;
        this.config = config;
    }
    BillOfEntryService.prototype.generateBOE = function (billsOfEntry, clearingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
            billsOfEntry.boes[0].clearingFile = clearingFile._links.self.href;
            return this.http.put(billsOfEntry.boes[0]._links.self.href, JSON.stringify(billsOfEntry.boes[0]), { headers: headers }).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
        }
        else {
            if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].clearingFile) {
                billsOfEntry.boes[0].clearingFile = clearingFile._links.self.href;
            }
            return this.http.post(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id + '/billOfEntries/generate', { headers: headers }).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
        }
    };
    BillOfEntryService.prototype.regenerateBOE = function (clearingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id + '/billOfEntries/regenerate', { headers: headers }).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
    };
    BillOfEntryService.prototype.findPreviousBillOfEntry = function (lrn, sequence, isManuallySubmitted) {
        return this.http.get(this.config.filesApiEndpoint + "api/billOfEntries/search/findByLrnAndSequenceNumberAndMarkedAsSubmitted?lrn="
            + lrn + '&sequenceNumber=' + sequence + '&isManuallySubmitted=' + isManuallySubmitted);
    };
    BillOfEntryService.prototype.findParentClearingFile = function (billId, fileType) {
        if (fileType === 'imports') {
            return this.http.get(this.config.filesApiEndpoint + "api/corrections/" + billId + '/parent');
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/exportFileCorrections/" + billId + '/parent');
        }
    };
    BillOfEntryService.prototype.findBillOfEntry = function (boe) {
        return this.http.get(boe._links.self.href.replace('\{\?projection\}', ''));
    };
    BillOfEntryService.prototype.findBOEsWithVAT = function (importerCode, customsOfficeCode, fromDate, toDate, branchCode) {
        var params = "importerCode=" + importerCode + "&customsOfficeCode=" + customsOfficeCode + "&from=" + fromDate + "&to=" + toDate;
        if (branchCode) {
            params += "&branchCode=" + branchCode;
        }
        return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/search/byImporterCodeCustomsOfficeAndDateRange?" + params).pipe(map(function (response) { return response['_embedded'] ? response['_embedded'].importBillOfEntries : []; }));
    };
    BillOfEntryService.prototype.saveBillOfEntry = function (billOfEntry, clearingFile) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (billOfEntry._links && billOfEntry._links.self) {
            billOfEntry.clearingFile = clearingFile._links.self.href;
            var url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
            if (billOfEntry.cusdecs) {
                billOfEntry.cusdecs = billOfEntry.cusdecs.filter(function (cusdec) { return cusdec !== null; });
            }
            return this.http.put(url, JSON.stringify(billOfEntry), { headers: headers });
        }
    };
    BillOfEntryService.prototype.loadFiles = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/document/" + id);
    };
    BillOfEntryService.prototype.loadUploadedFile = function (billOfEntry) {
        return this.http
            .get("" + billOfEntry._links.files.href);
    };
    BillOfEntryService.prototype.uploadFiles = function (files, billOfEntry) {
        var data = new FormData();
        data.append('file', files[0]);
        var headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });
        return this.http.post("" + billOfEntry._links.files.href, data, { headers: headers });
    };
    BillOfEntryService.prototype.retrieveBillOfEntry = function (currentBillOfEntry) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
        return this.http.get(url);
    };
    BillOfEntryService.prototype.findBillOfEntryByClearingFile_id = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/search/findByClearingFile_Id?id=" + id).pipe(map(function (response) { return response['_embedded'].importBillOfEntries; }));
    };
    BillOfEntryService.prototype.findBillOfEntryByExportFile_id = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries/search/findByExportFile_Id?id=" + id);
    };
    BillOfEntryService.prototype.findAllEdiMessages = function (page, size, indicator, branchCode) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 10; }
        var endpoint = '?';
        if (branchCode) {
            endpoint = "/search/findByBranchCode?branchCode=" + branchCode + "&";
        }
        if (indicator === 'imports') {
            return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries" + endpoint + "projection=onlyEdiMessages&page=" + page + "&size=" + size + "&sort=dateEdiSubmitted,desc");
        }
        else if (indicator === 'exports') {
            return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries" + endpoint + "projection=onlyEdiMessages&page=" + page + "&size=" + size + "&sort=dateEdiSubmitted,desc");
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/exBondBillOfEntries" + endpoint + "projection=onlyEdiMessages&page=" + page + "&size=" + size + "&sort=dateEdiSubmitted,desc");
        }
    };
    BillOfEntryService.prototype.findBoesByParameters = function (mawbNo, declarantName, foreignDeclarantName, containerNo, lrnNo, fileNo, referenceNo, mrnNo, caseNo, supplierInvNo, clearingFileId, fromDate, toDate, branch, fileType) {
        var params = this.buildParams({ mawbNo: mawbNo, declarantName: declarantName, foreignDeclarantName: foreignDeclarantName, containerNo: containerNo, lrnNo: lrnNo, fileNo: fileNo, referenceNo: referenceNo, mrnNo: mrnNo, caseNo: caseNo, supplierInvNo: supplierInvNo,
            clearingFileId: clearingFileId, fromDate: fromDate, toDate: toDate, branch: branch }); // will be an object when we add more to criteria
        if (fileType === 'imports') {
            return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&" + params).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
        }
        else if (fileType === 'exports') {
            return this.http.get(this.config.filesApiEndpoint + "api/exportBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&" + params).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].exportBillOfEntries ? response['_embedded'].exportBillOfEntries : []; }));
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/exBondBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&" + params).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].exBondBillOfEntries ? response['_embedded'].exBondBillOfEntries : []; }));
        }
    };
    BillOfEntryService.prototype.markBoesAsSubmitted = function (clearingFileId, selectedBoes) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var ids = selectedBoes.map(function (el) { return el.id; });
        return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFileId + '/billOfEntries/submit', ids, { headers: headers }).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
    };
    BillOfEntryService.prototype.buildParams = function (paramObj) {
        var params = [];
        for (var key in paramObj) {
            if (paramObj[key]) {
                params.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramObj[key]));
            }
        }
        return params.join('&');
    };
    BillOfEntryService.prototype.findByCustomsProcedureCodeAndMrn = function (cpc, mrn) {
        if (mrn && mrn !== null && mrn.trim() !== '') {
            return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/search/findByCustomsProcedureCodeAndMrn?customsProcedureCode=" + cpc + "&mrn=" + mrn).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/importBillOfEntries/search/findByCustomsProcedureCode?customsProcedureCode=" + cpc).pipe(map(function (response) { return response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : []; }));
        }
    };
    BillOfEntryService.prototype.updateReceiptInfo = function (customBoes) {
        return this.http.post(this.config.filesApiEndpoint + "api/importBillOfEntries/updateReceiptInfo", customBoes);
    };
    BillOfEntryService.ngInjectableDef = i0.defineInjectable({ factory: function BillOfEntryService_Factory() { return new BillOfEntryService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: BillOfEntryService, providedIn: "root" });
    return BillOfEntryService;
}());
export { BillOfEntryService };
