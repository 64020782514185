import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new Subject();

  constructor() {
  }

  addLoader(loader: Boolean) {
    this.loadingSubject.next(loader);
  }

  getLoader(): Observable<boolean> {
    const loader = this.loadingSubject.asObservable();
    // @ts-ignore
    return loader;
  }
}
