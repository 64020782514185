/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boe-lines-top-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i3 from "primeng/components/panel/panel";
import * as i4 from "primeng/components/inputtext/inputtext";
import * as i5 from "@angular/forms";
import * as i6 from "./boe-lines-top-section.component";
import * as i7 from "../../services/share-data.service";
import * as i8 from "../../../../subjects/billOfEntryLine-behaviour-subject";
import * as i9 from "../../../../subjects/billOfEntry-behaviour-subject";
var styles_BoeLinesTopSectionComponent = [i0.styles];
var RenderType_BoeLinesTopSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoeLinesTopSectionComponent, data: {} });
export { RenderType_BoeLinesTopSectionComponent as RenderType_BoeLinesTopSectionComponent };
export function View_BoeLinesTopSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 27, "p-panel", [["header", "Bill of Entry"]], null, null, null, i2.View_Panel_0, i2.RenderType_Panel)), i1.ɵdid(2, 49152, null, 1, i3.Panel, [i1.ElementRef], { header: [0, "header"] }, null), i1.ɵqud(335544320, 1, { footerFacet: 0 }), (_l()(), i1.ɵeld(4, 0, null, 1, 24, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "ui-g-12 ui-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"], ["value", "1010001503"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i5.NgModel]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["BOE No"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "ui-g-12 ui-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i5.NgModel]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Purpose"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "ui-g-12 ui-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i5.NgModel]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Country of Export"])), (_l()(), i1.ɵeld(23, 0, null, null, 5, "div", [["class", "ui-g-12 ui-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i5.NgModel]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Destination"]))], function (_ck, _v) { var currVal_0 = "Bill of Entry"; _ck(_v, 2, 0, currVal_0); _ck(_v, 8, 0); _ck(_v, 14, 0); _ck(_v, 20, 0); _ck(_v, 26, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 8).filled; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", ((_co.billOfEntry == null) ? null : _co.billOfEntry.customsPurposeCode), ""); var currVal_7 = true; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = i1.ɵnov(_v, 14).filled; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = i1.ɵinlineInterpolate(1, "", ((_co.selectedBillOfEntryLine == null) ? null : ((_co.selectedBillOfEntryLine.countryOfOrigin == null) ? null : _co.selectedBillOfEntryLine.countryOfOrigin.code)), ""); var currVal_13 = true; var currVal_14 = true; var currVal_15 = true; var currVal_16 = true; var currVal_17 = i1.ɵnov(_v, 20).filled; _ck(_v, 19, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = i1.ɵinlineInterpolate(1, "", ((_co.billOfEntry == null) ? null : ((_co.billOfEntry.destination == null) ? null : _co.billOfEntry.destination.code)), ""); var currVal_19 = true; var currVal_20 = true; var currVal_21 = true; var currVal_22 = true; var currVal_23 = i1.ɵnov(_v, 26).filled; _ck(_v, 25, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23); }); }
export function View_BoeLinesTopSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-boe-lines-top-section", [], null, null, null, View_BoeLinesTopSectionComponent_0, RenderType_BoeLinesTopSectionComponent)), i1.ɵdid(1, 114688, null, 0, i6.BoeLinesTopSectionComponent, [i7.ShareDataService, i8.BillOfEntryLineBehaviourSubject, i9.BillOfEntryBehaviourSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoeLinesTopSectionComponentNgFactory = i1.ɵccf("digi-boe-lines-top-section", i6.BoeLinesTopSectionComponent, View_BoeLinesTopSectionComponent_Host_0, {}, {}, []);
export { BoeLinesTopSectionComponentNgFactory as BoeLinesTopSectionComponentNgFactory };
