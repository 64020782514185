<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
                        <span class="ui-float-label">
              <p-autoComplete id="debtor"
                              [(ngModel)]="clearingFile.debtor"
                              field="label" [suggestions]="filteredDebtors"
                              (completeMethod)="search($event)"
                              [dropdown]="true"
                              [autoHighlight]="true"
                              (onSelect)=selectDebtor($event)>
                <ng-template let-option pTemplate="item">
                  <div>{{option.code}}: {{option.name}}</div>
                </ng-template>
              </p-autoComplete>
              <label for="debtor">Debtor</label>
            </span>
          </div>
        </div>
      </div>
      <digi-address [addresses]="debtorAddresses" [address]="clearingFile.debtor.address"></digi-address>
    </div>
  </div>
</div>
