/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../digi-common/components/export-registration/export-registration.component.ngfactory";
import * as i3 from "../../digi-common/components/export-registration/export-registration.component";
import * as i4 from "../../../subjects/exportfile-behaviour-subject";
import * as i5 from "../../digi-common/services/export-file.service";
import * as i6 from "../../digi-common/services/exporter.service";
import * as i7 from "../../digi-common/services/international-port.service";
import * as i8 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i9 from "../../digi-common/services/transport-methods.service";
import * as i10 from "../../digi-common/services/clearing-instructions.service";
import * as i11 from "@angular/router";
import * as i12 from "../../digi-common/services/validation.service";
import * as i13 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i14 from "../../../subjects/transport-document-behaviour-subject";
import * as i15 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i16 from "../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i17 from "../../digi-common/services/notification.service";
import * as i18 from "../../../subjects/debtor-behaviour-subject";
import * as i19 from "../../digi-common/services/share-data.service";
import * as i20 from "../../digi-common/services/company.service";
import * as i21 from "./registration.component";
var styles_RegistrationComponent = [i0.styles];
var RenderType_RegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationComponent, data: {} });
export { RenderType_RegistrationComponent as RenderType_RegistrationComponent };
export function View_RegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-export-registration", [], null, null, null, i2.View_ExportRegistrationComponent_0, i2.RenderType_ExportRegistrationComponent)), i1.ɵdid(1, 245760, null, 0, i3.ExportRegistrationComponent, [i4.ExportFileBehaviourSubject, i5.ExportFileService, i6.ExporterService, i7.InternationalPortService, i8.ClearingInstructionBehaviorSubject, i9.TransportMethodsService, i10.ClearingInstructionsService, i11.ActivatedRoute, i12.ValidationService, i13.BillOfEntryBehaviourSubject, i14.TransportDocumentBehaviourSubject, i15.SupplierInvoiceBehaviourSubject, i16.SupplierInvoiceLineBehaviourSubject, i17.NotificationService, i18.DebtorBehaviourSubject, i19.ShareDataService, i20.CompanyService], { transportMethodCode: [0, "transportMethodCode"] }, null)], function (_ck, _v) { var currVal_0 = "RAI"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration", [], null, null, null, View_RegistrationComponent_0, RenderType_RegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i21.RegistrationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationComponentNgFactory = i1.ɵccf("app-registration", i21.RegistrationComponent, View_RegistrationComponent_Host_0, {}, {}, []);
export { RegistrationComponentNgFactory as RegistrationComponentNgFactory };
