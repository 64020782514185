import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  getAdditionalInformation(pageNumber: number): Observable<any> {
    return this.http.get(
      `${this.config.apiEndpoint}api/boeAddInfoTypes/search/findByFileTypeId?fileTypeId=2&page=${pageNumber}&size=20&projection=flattened`);
  }
}
