import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Invoice, SupplierInvoiceLine} from '../../../models/financials';
import {TreeNode} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CreditNoteService} from '../../../services/credit-note.service';
import {CompanyService} from '../../../services/company.service';
import {Entry} from '../fin-supplrinv-headers/fin-supplrinv-headers.component';
import {Company} from '../../../models/company';
import {File, Address} from '../../../models/file';
import {NotificationService} from '../../../services/notification.service';
import {FileType} from '../../../models/enumerations';
import {TaxType} from '../../../models/TariffCode';
import {KeyValue} from '@angular/common';
import {BankingDetails} from '../../../models/company';

@Component({
  selector: 'digi-commercial-invoice',
  templateUrl: './commercial-invoice.component.html',
  styleUrls: ['./commercial-invoice.component.scss']
})
export class CommercialInvoiceComponent implements OnInit, OnChanges {
  @Input() file: File;
  @Input() transportMethod: string;
  logo: string;
  companyAddress: string;
  companyName: string;
  companySubscription: Subscription;
  bankAccountNumber: string;
  bankSwiftCode: string;
  bankBranchCode: string;
  currencyCode: string;

  constructor(
    private companyService: CompanyService) {
    }

  ngOnInit() {
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          this.logo = 'data:image/png;base64,' + companies[0].logo;
          const branch = this.file.branch ? companies[0].branches.find(el => el.code === this.file.branch) : companies[0].branches[0];
          this.companyAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.companyName = companies[0].name;
          if (this.file.exportConsignee && this.file.exportConsignee.hasBankingDetails) {
            this.bankAccountNumber = this.file.exportConsignee.commercialBankAccNo ? this.file.exportConsignee.commercialBankAccNo : '';
            this.bankSwiftCode = this.file.exportConsignee.commercialBankSwiftCode ? this.file.exportConsignee.commercialBankSwiftCode : '';
            this.bankBranchCode = this.file.exportConsignee.commercialBankCode ? this.file.exportConsignee.commercialBankCode : '';
          } else if (branch.invoiceDetails.bankingDetails) {
            this.bankAccountNumber = branch.invoiceDetails.bankingDetails[0] ? branch.invoiceDetails.bankingDetails[0].accountNo : '';
            this.bankSwiftCode = branch.invoiceDetails.bankingDetails[0] ? branch.invoiceDetails.bankingDetails[0].swiftCode : '';
            this.bankBranchCode = branch.invoiceDetails.bankingDetails[0] ? branch.invoiceDetails.bankingDetails[0].branchCode : '';
          }
        }
      }
    );
    if (this.file && this.file.transportDocument && this.file.transportDocument.supplierInvoices.length > 0) {
      this.currencyCode = this.file.transportDocument.supplierInvoices[0].currency ? this.file.transportDocument.supplierInvoices[0].currency.code : '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file.currentValue) {
      this.file = changes.file.currentValue;
    }
  }

  ngOnDestroy() {
      if (this.companySubscription) {
        this.companySubscription.unsubscribe();
      }
    }

  getLineAmount(quantity, price) {
    if (!quantity) {
      quantity = 0;
    }
    if (!price) {
      price = 0;
    }
    return Number(quantity * price).toFixed(2);
  }

  getDecimalAmount(value) {
    if (value) {
      return Number(value).toFixed(2);
    } else {
      return 0.00;
    }
  }

  getInvLinesTotal() {
    var lineArr = this.file.transportDocument.supplierInvoices[0].lines;
    var sum = 0;

    lineArr.forEach(el => {
      el.quantity && el.unitPrice ? sum += Number(el.quantity * el.unitPrice) : sum += 0;
    });
    return sum.toFixed(2);
  }

  getGrandTotal() {
      var sundryChargesArr = this.file.transportDocument.supplierInvoices[0].valueBasedCharges;
      var sum = 0;
      var grandTotal = 0;

      sundryChargesArr.forEach(el => {
        el.amount ? sum += Number(el.amount) : sum += 0;
      });
      grandTotal = sum += Number(this.getInvLinesTotal());
      return grandTotal.toFixed(2);
    }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }

  print() {
    window.print();
  }
}
