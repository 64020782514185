import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AwbTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllAirWaybillTypeStartsWith(searchValue) {
    return this.http
      .get(`${this.config.apiEndpoint}api/awbTypes/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].awbTypes)
      );
  }
}
