import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EDIMessage, ErrorDetail, EDIErrorDetails} from '../../../models/billOfEntries';
import {ForwardingFileService} from '../../../services/forwarding-file.service';
import {ManifestType} from '../../../models/voyageFile';
import {CuscarTypeService} from '../../../services/cuscar-type.service';
import {NotificationService} from '../../../services/notification.service';
import {ForwardingFile} from '../../../models/ForwardingFile';
import {ForwardingFileBehaviourSubject} from '../../../../../subjects/forwarding-file-behaviour-subject';
import {Subscription} from 'rxjs';
import {SendEdiToSarsService} from '../../../services/send-edi-to-sars.service';
import {CuscarType} from '../../../models/roadManifest';

@Component({
  selector: 'digi-edi',
  templateUrl: './edi.component.html',
  styleUrls: ['./edi.component.scss']
})
export class EdiComponent implements OnInit, OnDestroy {
  forwardingFile: ForwardingFile;
  disableButton: boolean;
  pdfSrc: string;
  cols: any[];
  colsForCuscar: any[];
  cuscars: string[];
  colsForContrl: any[];
  colsForCusres: any[];
  contrl: EDIMessage;
  cusres: EDIMessage;
  timerId: any;
  filteredCuscarTypes: CuscarType[];
  display = false;
  generatedFiles: any = [];
  colsForErrors: any[];
  errors: ErrorDetail[];
  activeIndex: number;
  manifestTypeOptions: any[];
  manifestAmendmentOrCancellationOptions: any[];

  forwardingFileSubscription: Subscription;
  forwardingFileServiceSubscription: Subscription;
  sendToSarsServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;
  controlFailed: boolean;

  constructor(
    private notificationService: NotificationService,
    private sendEdiToSarsService: SendEdiToSarsService,
    private cuscarTypeService: CuscarTypeService,
    private forwardingFileService: ForwardingFileService,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject
  ) { }

  ngOnInit() {
    this.forwardingFileSubscription = this.forwardingFileBehaviourSubject.getForwardingFile().subscribe(file => {
      this.forwardingFile = file;

      if (!this.forwardingFile.cuscarType || (this.forwardingFile.cuscarType && !this.forwardingFile.cuscarType.type)) {
        if (this.forwardingFile.transportMethodCode === 'AIR') {
          this.forwardingFile.cuscarType = {
            type: 'HAB       ',
            description: 'House Air Waybill',
            label: 'HAB : House Air Waybill'
          };
        }
      }

      if (!this.forwardingFile.manifestType) {
        this.forwardingFile.manifestType = 'IMPORT';
      }

      if (this.forwardingFile.contrl) {
        this.forwardingFile.contrl.messages.reverse();
        this.contrl = this.forwardingFile.contrl;
        this.checkContrlFailure(this.contrl.messages[0]);
      } else {
        this.contrl = new EDIMessage();
        this.contrl.messages = [];
      }

      if (this.forwardingFile.cusres) {
        this.forwardingFile.cusres.messages.reverse();
        this.cusres = this.forwardingFile.cusres;
        if (this.cusres.errorDetails) {
          this.errors = this.getErrors(this.cusres.errorDetails);
        }
      } else {
        this.cusres = new EDIMessage();
        this.cusres.messages = [];
      }

      if (this.forwardingFile.cuscars) {
        this.cuscars = this.forwardingFile.cuscars;
        this.cuscars.reverse();
      } else {
        this.cuscars = [];
      }
    });

    this.forwardingFileService.save(this.forwardingFile).subscribe(forwardingFile => {
      this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
    });

    this.colsForContrl = [
      {header: 'Contrl message(s)'}
    ];

    this.colsForCuscar = [
      {header: 'Cuscar message(s)'}
    ];

    this.colsForCusres = [
      {header: 'Cusres message(s)'}
    ];

    this.colsForErrors = [
      {field: 'code', header: 'Error Code', width: '20%'},
      {field: 'lineNumber', header: 'Line Number', width: '20%'},
      {field: 'description', header: 'Description', width: '60%'}
    ];

    const defaultValue = [{'label': '', 'value': ''}];
    this.manifestTypeOptions = defaultValue.concat(Object.keys(ManifestType).map(key => ({ 'label': ManifestType[key], 'value': key })));
    this.manifestAmendmentOrCancellationOptions = [
      {label: '', value: ''},
      {label: 'Cancellation', value: 'Cancellation'},
      {label: 'Change', value: 'Change'}
    ];
  }

  ngOnDestroy() {
    if (this.forwardingFileServiceSubscription) {
      this.forwardingFileServiceSubscription.unsubscribe();
    }

    if (this.sendToSarsServiceSubscription) {
      this.sendToSarsServiceSubscription.unsubscribe();
    }

    if (this.cuscarTypeServiceSubscription) {
      this.cuscarTypeServiceSubscription.unsubscribe();
    }
  }

  startRefreshPoller() {
    console.log('starting refresh poller...');
    // repeat with the interval of 5 seconds
    this.timerId = setInterval(() => this.refresh(), 5000);

    // after 60 seconds stop
    setTimeout(() => {
      clearInterval(this.timerId);
      console.log('stopped refresh poller after 64 seconds');
    }, 64000);
  }

  generateManifest() {
    if (this.forwardingFile && this.forwardingFile.id) {
      this.disableButton = true;
      this.forwardingFileServiceSubscription = this.forwardingFileService.generate(this.forwardingFile.id).subscribe(
        data => {
          this.generatedFiles = data._embedded.supportingDocuments;
          this.display = true;
          this.disableButton = false;
        },
        _ => this.disableButton = false
      );
    }
  }

  sendManifestToSARS() {
    if (this.forwardingFile.cuscarType  && this.forwardingFile.cuscarType.type) {
      if (this.forwardingFile && this.forwardingFile.id) {
        this.disableButton = true;
        this.notificationService.successNotify('Sending Manifest...');
        this.sendToSarsServiceSubscription = this.sendEdiToSarsService
          .sendForwardingFile(this.forwardingFile)
          .subscribe(data => {
              this.notificationService.successNotify('Forwarding File sent');
              this.refresh();
              this.disableButton = false;
            },
            _ => this.disableButton = false);
      }
    } else {
      this.notificationService.errorNotify(
        'Validation',
        ['Please Select Cuscar Type'],
      );
    }
  }

  refresh() {
    setTimeout(() => {
      this.retrieveRoadManifestAfterEDI();
    }, 2000);
  }

  retrieveRoadManifestAfterEDI() {
    this.forwardingFileServiceSubscription = this.forwardingFileService.loadForwardingFile(this.forwardingFile.fileNumber, this.forwardingFile.transportMethodCode)
      .subscribe(data => {
          this.forwardingFile = data[0];

          this.cuscars = this.forwardingFile.cuscars ? [...this.forwardingFile.cuscars] : [];

          if (this.forwardingFile.contrl) {
            this.forwardingFile.contrl.messages.reverse();
            this.contrl = Object.assign({}, this.forwardingFile.contrl);
          } else {
            this.contrl = new EDIMessage();
            this.contrl.messages = [];
          }

          if (this.forwardingFile.cusres) {
            this.forwardingFile.cusres.messages.reverse();
            this.cusres = Object.assign(this.forwardingFile.cusres);
          } else {
            this.cusres = new EDIMessage();
            this.cusres.messages = [];
          }

          this.forwardingFileBehaviourSubject.addForwardingFile(this.forwardingFile);
        },
        _ => this.disableButton = false
      );
  }

  getErrors(errorDetails: EDIErrorDetails[]) {
      const errors: ErrorDetail[] = [];
      const regex = /UNZ\+(\d+)\+([^']*)/gm;
      const cusres = this.cusres.messages[0];
      const matches = regex.exec(cusres);
      const messageRefNumber = matches[2];
      errorDetails.filter(el => el.messageRefNumber === messageRefNumber).forEach((el) => {
        const error = new ErrorDetail();
        error.code = el.errorNumber[0].errorCode;
        error.description = el.errorDescriptions[0];
        error.lineNumber = el.errorPointDetails.lineNumber;
        errors.push(error);
      });
      return errors;
    }

  hasCarn() {
    return !!(this.forwardingFile && this.forwardingFile.carn);
  }

  hasSuccessfulResponseOrIsAwaitingResponse() {
    if (this.forwardingFile) {
      // tslint:disable-next-line:max-line-length
      if ((this.forwardingFile.manifestAmendmentOrCancellation === 'Cancellation' || this.forwardingFile.manifestAmendmentOrCancellation === 'Change') && this.forwardingFile.cusres && this.forwardingFile.cusres.customsStatus === 'RECEIVED_AND_UPDATED') {
        return false;
      } else if (this.forwardingFile.cusres && this.forwardingFile.cusres.customsStatus !== 'REJECT_TO_CLEARER') {
        return true;
      } else if (this.forwardingFile.cusres && this.forwardingFile.cusres.customsStatus === 'REJECT_TO_CLEARER') {
        return false;
      } else if (this.forwardingFile.contrl && this.forwardingFile.contrl.interchangeStatus !== 'REJECTED' && !this.forwardingFile.cusres) {
        return true;
        // tslint:disable-next-line:max-line-length
      } else if (this.forwardingFile.dateEdiSubmitted && (!this.forwardingFile.contrl || !this.forwardingFile.cusres && this.forwardingFile.contrl.interchangeStatus !== 'REJECTED')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  searchCuscarType(event: any) {
    this.cuscarTypeServiceSubscription = this.cuscarTypeService.findByTypeOrDescription(event.query).subscribe(
      data => this.filteredCuscarTypes = data
    );
  }

  selectCuscarType(value: CuscarType) {
    this.forwardingFile.cuscarType = value;
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  format(value) {
    return value ? value.replace(/_/g, ' ').toLowerCase() : '';
  }

  private checkContrlFailure(message: string) {
      message = message.replace(/\r\n/g, '');
      const segments = message.split('\'');
      const uciSegment = segments.find(el => el.startsWith('UCI'));
      const ucmSegment = segments.find(el => el.startsWith('UCM'));

      if (uciSegment.includes('+4+') || ucmSegment.includes('+4+')) {
        this.controlFailed = true;
      }
    }
}
