import { map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { Address } from '../models/file';
import { ExportFileBehaviourSubject } from '../../../subjects/exportfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../../subjects/exportfile-behaviour-subject";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i4 from "../../app.config.module";
var ExporterService = /** @class */ (function () {
    function ExporterService(exportFileBehaviourSubject, http, clearingInstructionBehaviorSubject, config) {
        this.exportFileBehaviourSubject = exportFileBehaviourSubject;
        this.http = http;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.config = config;
    }
    ExporterService.prototype.findExporterNameOrCodeStartsWith = function (name) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + name + '&roleType=12' + '&projection=flattenedExporter')
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    ExporterService.prototype.findExporterFromExportFile = function (exportFile) {
        var _this = this;
        if (exportFile.exporter && exportFile.exporter.code) {
            return this.findExporterNameOrCodeStartsWith(exportFile.exporter.code).pipe(tap(function (exporterData) {
                if (exporterData) {
                    _this.exportFileBehaviourSubject.addExportConsignees(exporterData[0].exporterConsignees);
                    _this.exportFileBehaviourSubject.addDebtors(exporterData[0].debtors);
                    _this.exportFileBehaviourSubject.addOverseasAgents(exporterData[0].overseasAgents);
                    if (exporterData && (!exportFile.exporter.address || !exportFile.exporter.address.line1)) {
                        var exporterAddresses = exporterData[0].addresses;
                        var exporterAddress = void 0;
                        if (exporterAddresses.length === 1) {
                            exporterAddress = exporterAddresses[0];
                        }
                        else {
                            exporterAddress = exporterAddresses.find(function (address) {
                                var addressType = address.addressTypes.find(function (addressType) {
                                    return addressType.id === 'CUS';
                                });
                                if (addressType) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            });
                        }
                        if (exporterAddress) {
                            exportFile.exporter.address = new Address();
                            exportFile.exporter.address.line1 = exporterAddress.line1;
                            exportFile.exporter.address.line2 = exporterAddress.line2;
                            exportFile.exporter.address.line3 = exporterAddress.line3;
                            exportFile.exporter.address.line4 = exporterAddress.line4;
                            exportFile.exporter.address.zipCode = exporterAddress.zipCode;
                        }
                    }
                }
            }));
        }
        else {
            return EMPTY;
        }
    };
    ExporterService.ngInjectableDef = i0.defineInjectable({ factory: function ExporterService_Factory() { return new ExporterService(i0.inject(i1.ExportFileBehaviourSubject), i0.inject(i2.HttpClient), i0.inject(i3.ClearingInstructionBehaviorSubject), i0.inject(i4.APP_CONFIG)); }, token: ExporterService, providedIn: "root" });
    return ExporterService;
}());
export { ExporterService };
