import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupplierInvoiceLine, Country } from '../app/digi-common/models/file';

@Injectable({
  providedIn: 'root'
})
export class SupplierInvoiceLineBehaviourSubject {

  private supplierInvoiceLineSubject = new BehaviorSubject<SupplierInvoiceLine>(null);
  private countryOfOriginSubject = new BehaviorSubject<Country>(null);
  constructor() {
  }

  addSupplierInvoiceLine(supplierInvoice: SupplierInvoiceLine) {
    this.supplierInvoiceLineSubject.next(supplierInvoice);
  }

  getSupplierInvoiceLine(): Observable<SupplierInvoiceLine> {
    return this.supplierInvoiceLineSubject.asObservable();
  }

  addCountry(countryOfOrigin: Country) {
    this.countryOfOriginSubject.next(countryOfOrigin);
  }

  getCountry(): Observable<Country> {
    return this.countryOfOriginSubject.asObservable();
  }
}

