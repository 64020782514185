<p-dialog header="Add Unique Consignment Reference"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1300"
          [minWidth]="200"
          [minY]="80"
          (onHide)="onClose()"
          [maximizable]="false"
          [baseZIndex]="10000">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g ui-fluid">
        <p-panel header="Unique Consignment Details">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-6">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" [(ngModel)]="uniqueConsignmentReference.number"  pInputText>
                <label>UCR Number </label>
              </span>
            </div>
          </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-6">
              <span class="md-inputfield">
             <span class="ui-float-label">
                <span class="md-inputfield">
                   <p-inputMask id="date" mask="99/99/9999" [(ngModel)]="uniqueConsignmentReference.date">
                   </p-inputMask>
                  <label for="date">Registered Date (dd/mm/yyyy)</label>
                  <div *ngIf="!uniqueConsignmentReference.date" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                </span>
             </span>
      </span>
              </div>
              <div class="ui-g-12 column ui-md-6">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
              <span class="ui-float-label">
              <p-dropdown [options]="entityTypes" [(ngModel)]="selectedEntityType"
                          optionLabel="label" editable="true"
                          [autoWidth]="false"
                          placeholder="">
              </p-dropdown>
              <label>Entity Type</label>
            </span>
                </div>
              <div *ngIf="!selectedEntityType" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-6">
              <span class="md-inputfield">
                <input type="text" pInputText [(ngModel)]="businessEntity.label" readonly>
                <label *ngIf="fileType==='imports'">Local Agent</label>
                <label *ngIf="fileType=='exports'">Exporter</label>
              </span>
              </div>
              <div class="ui-g-12 column ui-md-6">
                <span class="md-inputfield">
                <div class="ui-inputgroup">
                    <span class="ui-float-label">
              <p-dropdown [options]="referenceTypes" [(ngModel)]="selectedReferenceType"
                          optionLabel="label" editable="true"
                          [autoWidth]="false"
                          placeholder=""
                          required="true"></p-dropdown>
              <label>Reference Type</label>
            </span>
                  </div>
                  <div *ngIf="!selectedReferenceType" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                  </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-6">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" pInputText [(ngModel)]="uniqueConsignmentReference.customsCode" readonly>
                <label>Customs Code :</label>
              </span>
            </div>
          </span>
              </div>
              <div class="ui-g-12 ui-md-6">
              <span class="md-inputfield">
             <span class="ui-float-label">
                <span class="md-inputfield">
                   <input id="ucrReferenceNumber" pInputText
                                [(ngModel)]="uniqueConsignmentReference.referenceNumber" maxlength="19">
                  <label for="ucrReferenceNumber">UCR Reference Number</label>
                  <div *ngIf="!uniqueConsignmentReference.referenceNumber" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                </span>
             </span>
      </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6">
                <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
            <p-autoComplete id="paymentTerm" [(ngModel)]="uniqueConsignmentReference.paymentTerm"
                            field="description"
                            [dropdown]="true"
                            [suggestions]="filteredPaymentTerms"
                            (completeMethod)="searchPaymentTerm($event)"
                            [autoHighlight]="true" minLength="1"
                            required="true">
              <ng-template let-option pTemplate="item">
                <div>{{option.label}}</div>
              </ng-template>
            </p-autoComplete>
        <label for="paymentTerm">Terms Code</label>
      </span>
    </div>
                </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <digi-country-of-origin [country]="uniqueConsignmentReference?.country"
                                      (onSelect)="selectCountry($event)"></digi-country-of-origin>
            </div>
            <div class="ui-g-12 ui-md-6">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" [(ngModel)]="uniqueConsignmentReference.transactionValue" pInputText>
                <label> Transaction Value </label>
              </span>
            </div>
          </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <digi-currency [currency]="uniqueConsignmentReference?.transactionCurrency"
                             [required]=false
                             (onSelect)="selectTransactionCurrency($event)"></digi-currency>
            </div>
            <div class="ui-g-12 ui-md-6">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" [(ngModel)]="uniqueConsignmentReference.advancedPaymentValue" pInputText>
                <label> Advanced Payment Value </label>
              </span>
            </div>
          </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <digi-currency [currency]="uniqueConsignmentReference?.advancedPaymentCurrency"
                             [required]=false
                             (onSelect)="selectAdvancedPaymentCurrency($event)"></digi-currency>
            </div>
            <div class="ui-g-12 column ui-md-6">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
            <span class="ui-float-label">
              <p-dropdown appendTo="body" [baseZIndex]="10000" [options]="scopeTypes" [(ngModel)]="selectedScopeType"
                          optionLabel="label" editable="true"
                          [autoWidth]="false"
                          placeholder=""
                          scrollHeight="300px"></p-dropdown>
              <label>Scope</label>
            </span>
              </div>
                <div *ngIf="!selectedScopeType" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
              </span>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="onSave()" label="Save"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="createNewUcr()" label="New"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()" label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
