import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ShareDataService } from '../app/digi-common/services/share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../app/digi-common/services/share-data.service";
var ClearingFileBehaviourSubject = /** @class */ (function () {
    function ClearingFileBehaviourSubject(shareDataService) {
        this.shareDataService = shareDataService;
        this.clearingFileSubject = new BehaviorSubject(this.shareDataService.buildClearingFile());
        this.debtorsSubject = new Subject();
    }
    ClearingFileBehaviourSubject.prototype.addClearingFile = function (clearingFile) {
        this.clearingFileSubject.next(clearingFile);
    };
    ClearingFileBehaviourSubject.prototype.getClearingFile = function () {
        return this.clearingFileSubject.asObservable();
    };
    ClearingFileBehaviourSubject.prototype.addDebtors = function (debtors) {
        this.debtorsSubject.next(debtors);
    };
    ClearingFileBehaviourSubject.prototype.getDebtors = function () {
        return this.debtorsSubject.asObservable();
    };
    ClearingFileBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function ClearingFileBehaviourSubject_Factory() { return new ClearingFileBehaviourSubject(i0.inject(i1.ShareDataService)); }, token: ClearingFileBehaviourSubject, providedIn: "root" });
    return ClearingFileBehaviourSubject;
}());
export { ClearingFileBehaviourSubject };
