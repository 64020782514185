<div class="ui-g-12 ui-md-12">
  <br>
  <span class="md-inputfield">
      <span class="ui-inputgroup">
           <span class="ui-float-label">
                <p-autoComplete id="vesselsAgent" [(ngModel)]="selectedTransportDocument.vesselsAgent" field="label"
                                [suggestions]="filteredVesselsAgents"
                                (completeMethod)="search($event)"
                                (onSelect)="select($event)"
                                [autoHighlight]="true"
                                minLength="3"
                >
                  <ng-template let-option pTemplate="item">
                    <div>{{option.label}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="vesselsAgent">Vessels Agent</label>
           </span>
        <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
      </span>
  </span><br><br>
</div>
