import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {BusinessEntity, Consignor} from '../../models/business-entity';

@Component({
  selector: 'digi-consignor-role',
  templateUrl: './consignor-role.component.html',
  styleUrls: ['./consignor-role.component.scss']
})
export class ConsignorRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() consignor: Consignor;

  constructor() {
  }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
  }

  ngOnChanges() {
    this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if (!this.consignor.customsCode) {
      this.consignor.customsCode = this.businessEntity.defaultCustomsCode;
    }

  }

}
