import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../../app.config.module';
import {map, tap} from 'rxjs/operators';
import {Permit} from '../../models/permit';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClearingFile} from '../../models/clearingFile';
import {Invoice} from '../../models/financials';
import * as moment from 'moment';
import {FileService} from '../../services/file.service';

@Injectable({
  providedIn: 'root'
})
export class PermitsService {
  private permitsBehaviourSubject = new BehaviorSubject([]);
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private fileService: FileService
  ) { }

  findPermitTypeByCode(code) {
    return this.http.get(`${this.config.apiEndpoint}api/permitTypes/search/codeStartsWith?code=${code}`).pipe(
      map(response => response['_embedded'].permitTypes)
    );
  }

  findRCCTypeByCode(code) {
    return this.http.get(`${this.config.apiEndpoint}api/rccTypes/search/codeStartWith?code=${code}`).pipe(
      map(response => response['_embedded'].rccTypes)
    );
  }

  findBusinessEntity(nameOrCode, roleType) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + nameOrCode + `&roleType=${roleType}`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  savePermit(permit: Permit): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (permit._links && permit._links.self) {
      return this.http.put(permit._links.self.href, JSON.stringify(permit), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/permits', JSON.stringify(permit), {headers});
    }
  }

  findPermitsByCode(code) {
    return this.http.get(`${this.config.filesApiEndpoint}api/permits/search/findByPermitNoStartWith?permitNo=${code}`)
      .pipe(
        map(response => response['_embedded'].permits)
      );
  }

  findPermitsByTariffCodeAndBusinessEntityRef(tariffCode, businessEntityRefCode, roleType) {
    this.fileService.setIsLoadingPermit(true);
    const date = moment().format('DD/MM/YYYY');
    return this.http
      .get(`${this.config.filesApiEndpoint}api/permits/search/findByTariffCodeAndBusinessEntityRef`
      + `?tariffCode=${tariffCode}`
      + `&BERefCode=${businessEntityRefCode}`
      + `&roleType=${roleType}`
      + `&date=${date}`
      + `&projection=flattenedPermit`)
      .pipe(
        map(response => response['_embedded'] ? response['_embedded'].permits : [])
      ).pipe(
        tap(_ => this.fileService.setIsLoadingPermit(false))
      );
  }

  findPermitsByTariffCode(tariffCode) {
    const date = moment().format('DD/MM/YYYY');
    return this.http
      .get(`${this.config.filesApiEndpoint}api/permits/search/findByTariffCode`
        + `?tariffCode=${tariffCode}`
        + `&date=${date}`)
      .pipe(
        map(response => response['_embedded'] ? response['_embedded'].permits : [])
      );
  }

  getAppliedLocalValue(currencyCode: string, date , appliedValueForeign: number): Observable<any> {
    return this.http
      .post(`${this.config.apiEndpoint}api/exchangeRates/calculateLocalValue?code=${currencyCode}&type=cus&date=${date}`,
        appliedValueForeign);
  }

  getPermits() {
    return this.permitsBehaviourSubject.asObservable();
  }

  addPermits(value) {
    this.permitsBehaviourSubject.next(value);
  }
}
