<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
         <p-autoComplete id="businessEntity"
                         [(ngModel)]="businessEntity"
                         field="label"
                         [suggestions]="filteredBusinessEntities"
                         (completeMethod)="searchBusinessEntity($event)"
                         [autoHighlight]="true"
                         [dropdown]="dropdown"
                         (onSelect)="selectBusinessEntity($event)"
                         minLength="3"
         >
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>
        <label for="businessEntity">{{label}}</label>
      </span>
        <span class="ui-inputgroup-addon" *ngIf="!dropdown"><i class="fa fa-search"></i></span>
    </div>
    </span>
        </div>
        <digi-address [addresses]="addresses" [address]="businessEntity.address"></digi-address>
      </div>
    </div>
  </div>
  <div *ngIf="displayNewButton" class="ui-g form-group">
    <div class="ui-md-12">
      <div class="ui-inputgroup">
        <button pButton
                type="button"
                label="Add New" (click)="openBusinessEntityModal()"></button>
      </div>
    </div>
  </div>
  <p-dialog header="Add New Business Entity"
            [(visible)]="display"
            [modal]="true"
            [responsive]="true"
            [width]="1300"
            [minWidth]="200"
            [minY]="80"
            [maximizable]="false"
            [baseZIndex]="10000">
    <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-6">
            <p-panel header="Business Entity Details">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="newBusinessEntity.name"
                                   pInputText>
                            <label>Trading Name</label>
                        </span>
                    </div>
                             <div class="ui-message ui-messages-error ui-corner-all" *ngIf="!newBusinessEntity.name">
               This field is required
          </div>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12" *ngIf="newBusinessEntity.code">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="newBusinessEntity.code"
                                   pInputText>
                            <label>Code</label>
                        </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="newBusinessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Customs Code</label>
                        </span>
                  </div>
                </div>
                <div *ngIf="roleType === 21" class="ui-g-12 ui-md-12">
                  <digi-currency [currency]="currency" (onSelect)="selectCurrency($event)"></digi-currency>
                </div>
                <div *ngIf="roleType === 21 || roleType === 2" class="ui-g-12 ui-md-12">
                  <digi-country-of-origin [country]="country"
                                          [label]="roleType === 21?'Country of Origin':'Country of Destination'"
                                          (onSelect)="selectCountry($event)"></digi-country-of-origin>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-12 ui-md-6">
            <p-panel header="Address Section">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="address.line1"
                       pInputText>
                <label>Street</label>
              </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="address.line2"
                       pInputText>
              </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="address.line3"
                       pInputText>
              </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text" [(ngModel)]="address.line4"
                       pInputText>
                <label>State/Province</label>
              </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                <input type="text"  [(ngModel)]="address.zipCode"
                       pInputText>
                <label>Zip/Postal Code</label>
              </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-close" (click)="onSave()" label="Save"
              class="ui-button-secondary"></button>
      <button type="button" pButton icon="pi pi-close" (click)="onClose()" label="Close"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</div>
