var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var CuscarType = /** @class */ (function () {
    function CuscarType() {
    }
    return CuscarType;
}());
export { CuscarType };
var PackType = /** @class */ (function () {
    function PackType() {
    }
    return PackType;
}());
export { PackType };
var ManifestWaybill = /** @class */ (function () {
    function ManifestWaybill() {
    }
    return ManifestWaybill;
}());
export { ManifestWaybill };
var RoadManifest = /** @class */ (function () {
    function RoadManifest() {
    }
    return RoadManifest;
}());
export { RoadManifest };
var RoadManifestCorrection = /** @class */ (function (_super) {
    __extends(RoadManifestCorrection, _super);
    function RoadManifestCorrection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoadManifestCorrection;
}(RoadManifest));
export { RoadManifestCorrection };
