import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';
import {ClearingFile} from '../app/digi-common/models/clearingFile';
import {ShareDataService} from '../app/digi-common/services/share-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClearingFileBehaviourSubject {
  private clearingFileSubject = new BehaviorSubject<ClearingFile>(this.shareDataService.buildClearingFile());
  private debtorsSubject = new Subject<Debtor[]>();

  constructor(private shareDataService: ShareDataService) {
  }

  addClearingFile(clearingFile: ClearingFile) {
    this.clearingFileSubject.next(clearingFile);
  }

  getClearingFile(): Observable<ClearingFile> {
    return this.clearingFileSubject.asObservable();
  }

  addDebtors(debtors: Debtor[]) {
    this.debtorsSubject.next(debtors);
  }

  getDebtors(): Observable<Debtor[]> {
    return this.debtorsSubject.asObservable();
  }
}
