import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AirWaybill, AWBType, WaybillNumber, WaybillReservation} from '../../../digi-common/models/AirWaybill';
import {Airline, PortDetail, RouteDetails} from '../../../digi-common/models/file';
import * as moment from 'moment';
import {TransportService} from '../../services/transport.service';
import {AwbTypeService} from '../../../digi-common/services/awb-type.service';
import {AirWaybillService} from '../../../digi-common/services/air-waybill.service';
import {NotificationService} from '../../../digi-common/services/notification.service';
import {ConfirmationService} from 'primeng/primeng';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'digi-awb-pool',
  templateUrl: './awb-pool.component.html',
  styleUrls: ['./awb-pool.component.scss']
})
export class AwbPoolComponent implements OnInit {
  items: MenuItem[];
  activeIndex = 0;
  airWaybill: AirWaybill;
  disableButton: boolean;
  filteredAirlines: Airline[];
  filteredAirWaybills: AirWaybill[];
  filteredAwbTypes: AWBType[];
  startNumberCheckDigit: number;
  endNumberCheckDigit: number;
  waybillNumbers: WaybillNumber[];
  displayReservationModal: boolean;
  selectedWaybillNumber: WaybillNumber = new WaybillNumber();

  constructor(
    private transportService: TransportService,
    private awbTypeService: AwbTypeService,
    private airWayBillService: AirWaybillService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.createNewAirWaybill();
    this.items = [
      {
        label: 'Management',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-user-plus'
      }
    ];
  }

  createNewAirWaybill() {
    this.airWaybill = new AirWaybill();
    this.airWaybill.airline = new Airline();
    this.airWaybill.awbType = new AWBType();
    this.airWaybill.creationDate = moment().format('DD/MM/YYYY');
    this.airWaybill.waybillNumbers = [];
    this.airWaybill.waybillNumbers = [...this.airWaybill.waybillNumbers];
    this.updateCheckDigits();
  }

  saveAirWaybill() {
    if (!this.airWaybill.waybillNumbers || (this.airWaybill.waybillNumbers && this.airWaybill.waybillNumbers.length === 0)) {
      const waybillNumbers = this.createWaybillNumbers(this.airWaybill);
      this.confirmationService.confirm({
        message: `You are about to add ${waybillNumbers.length} AWB numbers. Continue?`,
        accept: () => {
          this.airWaybill.waybillNumbers = waybillNumbers;
          this.airWaybill.waybillNumbers = [...this.airWaybill.waybillNumbers];
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    this.disableButton = true;
    this.airWayBillService.save(this.airWaybill).subscribe(
      data => {
        this.disableButton = false;
        this.notificationService.successNotify('Air Waybill Saved Successfully');
        this.airWaybill = data;
        this.airWaybill.waybillNumbers = [...data.waybillNumbers];
        this.updateCheckDigits();
      },
      _ => this.disableButton = false
    );
  }

  createWaybillNumbers(airWaybill: AirWaybill): WaybillNumber[] {
    const start = airWaybill.startNumber + this.startNumberCheckDigit;
    const end = airWaybill.endNumber + this.endNumberCheckDigit;
    const waybillNumbers: WaybillNumber[] = [{number: start}];

    while (+waybillNumbers[waybillNumbers.length - 1].number < +end) {
      const waybillNo = String(Number(waybillNumbers[waybillNumbers.length - 1].number) + 11).padStart(8, '0');
      waybillNumbers.push({number: waybillNo});
    }

    if (start !== end) {
      waybillNumbers.push({number: end});
    }

    return waybillNumbers;
  }

  searchAirWaybill(event: any) {
    this.airWayBillService.findByAirlineCode(event.query).subscribe(
      data => this.filteredAirWaybills = data
    );
  }

  selectAirWaybill(value) {
    if (value) {
      this.airWaybill = value;
      this.airWaybill.waybillNumbers = [...value.waybillNumbers];
      this.updateCheckDigits();
    }
  }

  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectAirline(airline: Airline) {
    this.airWaybill.airline.prefix = airline.airlinePrefix;
    this.airWayBillService.findByAirlineCode(airline.code).subscribe(
      data => this.selectAirWaybill(data[0])
    );
  }

  searchAwbType(event: any) {
    this.awbTypeService.findAllAirWaybillTypeStartsWith(event.query).subscribe(
      data => this.filteredAwbTypes = data
    );
  }

  updateCheckDigits() {
    if (this.airWaybill.startNumber) {
      this.startNumberCheckDigit = this.calculateCheckDigit(this.airWaybill.startNumber);
    } else {
      this.startNumberCheckDigit = null;
    }
    if (this.airWaybill.endNumber) {
      this.endNumberCheckDigit = this.calculateCheckDigit(this.airWaybill.endNumber);
    } else {
      this.endNumberCheckDigit = null;
    }
  }

  calculateCheckDigit(value) {
    const firstSeven = value.toString().substring(0, 7);
    const convertToNumber = +firstSeven;
    const dividedNumber = convertToNumber / 7;
    const roundedValue = Math.floor(dividedNumber);
    const multipliedNumber = roundedValue * 7;
    return convertToNumber - multipliedNumber;
  }

  reserveWaybill(waybillNumber: WaybillNumber) {
    this.selectedWaybillNumber = waybillNumber;
    this.selectedWaybillNumber.waybillReservation = new WaybillReservation();
    this.displayReservationModal = true;
  }

  unreserveWaybill(waybill: WaybillNumber) {
    waybill.waybillReservation = null;
  }

  closeModal() {
    this.displayReservationModal = false;
  }

  showReservation(waybillNumber: WaybillNumber) {
    this.selectedWaybillNumber = waybillNumber;
    this.displayReservationModal = true;
  }
}
