<div class="ui-g-12 ui-md-12">
  <div class="ui-g-12" *ngIf="isCorrection()">
    <p-panel header="Voucher Of Corrections">
      <div class="ui-g ui-fluid">
        <div class="ui-g-3">
          <div class="ui-g-12 ui-md-12"><p-checkbox binary="true" label="Manifest Cancelled?" [(ngModel)]="roadManifest.cancellation"></p-checkbox></div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-4">
    <p-panel header="Manifest Details">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="boes"
                            [(ngModel)]="roadManifest.manifestNo"
                            [suggestions]="filteredMawbNumbers"
                            (completeMethod)="searchBoes($event)"
                            (onSelect)="selectBoe($event)"
                            [autoHighlight]="true">
            </p-autoComplete>
        <label for="boes">Manifest No.</label>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
                      <div *ngIf="!roadManifest.manifestNo"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
  </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
  <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="cuscarType" [(ngModel)]="roadManifest.cuscarType"
                                  field="label"
                                  [suggestions]="filteredCuscarTypes"
                                  (completeMethod)="searchCuscarType($event)"
                                  (onSelect)="selectCuscarType($event)"
                                  [autoHighlight]="true" minLength="2" [dropdown]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="cuscarType">Cuscar Type</label>
                </span>
    <div *ngIf="!roadManifest.cuscarType.type"
         class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
</div>
</span></div>
              <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
            <p-dropdown [options]="manifestTypeOptions"
                        [(ngModel)]="roadManifest.manifestType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Manifest Type</label>
          </span>
                <div *ngIf="!roadManifest.manifestType"
                     class="ui-message ui-messages-error ui-corner-all">
                  This field is required</div>
              </div>
              <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
            <p-dropdown [options]="cargoTypeOptions"
                        [(ngModel)]="roadManifest.cargoType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Cargo Type</label>
          </span>
                <div *ngIf="!roadManifest.cargoType"
                     class="ui-message ui-messages-error ui-corner-all">
                  This field is required</div>
            </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
    <br>
    <p-panel header="Transporter Details">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
  <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="transporter" [(ngModel)]="roadManifest.transporter"
                                  field="label"
                                  [suggestions]="filteredTransporters"
                                  (completeMethod)="searchTransporters($event)"
                                  (onSelect)="selectTransporter($event)"
                                  [autoHighlight]="true" minLength="2">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="transporter">Transporter</label>
                </span>
<span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
</div>
                  <div *ngIf="!roadManifest.transporter.transporterCode"
                       class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-4">
    <p-panel header="Voyage Details">
      <div class="ui-fluid">
        <div class="ui-g form-group">
          <div class="ui-g-12">
            <span class="ui-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         pInputText
                         [(ngModel)]="roadManifest.voyageDetails.vehicleAndTrailerReg"
                         required>
                 <label>Vehicle & Trailer(s) Reg</label>
               </span>
            </div>
          </span>
          </div>
          <div class="ui-g-12">
            <span class="ui-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         pInputText
                         [(ngModel)]="roadManifest.voyageDetails.trailerOne"
                         required>
                 <label>Trailer 1</label>
               </span>
            </div>
          </span>
          </div>
          <div class="ui-g-12">
            <span class="ui-inputfield">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                  <input type="text"
                         class="ui-inputgroup"
                         pInputText
                         [(ngModel)]="roadManifest.voyageDetails.trailerTwo"
                         required>
                 <label>Trailer 2</label>
               </span>
            </div>
          </span>
          </div>
          <digi-country-of-origin class="ui-g-12" label="Country of Export" [country]="roadManifest.countryOfExport" (onSelect)="selectCountryOfExport($event)"></digi-country-of-origin>
          <digi-country-of-origin class="ui-g-12" label="Country of Destination" [country]="roadManifest.destination" (onSelect)="selectDestination($event)"></digi-country-of-origin>
          <div class="ui-g-12 ui-md-12">
            <digi-manifest-port label="Place of Exit" [isExitPort]="true" [transportMethod]="roadManifest.transportMethodCode"
                                [port]="roadManifest.exitPort"
                                (portChange)="selectExitPort($event)"
            ></digi-manifest-port>
          </div>
          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="arrivalDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         [(ngModel)]="roadManifest.arrivalDate">
            </p-inputMask>
            <label for="arrivalDate">Arrival Date</label>
        </span>
          <div *ngIf="!roadManifest.arrivalDate"
               class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
        </span>
          </div>
          <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
        <span class="ui-float-label">
            <p-inputMask id="masterTransportDocumentDate" mask="99/99/9999"
                         slotChar="dd/mm/yyyy"
                         required
                         [(ngModel)]="roadManifest.masterTransportDocDate">
            </p-inputMask>
            <label for="masterTransportDocumentDate">Master Transport Doc. Date</label>
        </span>
          <div *ngIf="!roadManifest.masterTransportDocDate"
               class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
        </span>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-4">
    <p-panel header="Declarant Details">
      <digi-manifest-business-entity-search [businessEntity]="roadManifest.localAgent"
                                            roleType="4"
                                            label="Local Agent"
                                            (updateBusinessEntity)="updateLocalAgent($event)"
      ></digi-manifest-business-entity-search>
    </p-panel>
    <br>
    <p-panel header="Customs Office">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <digi-customs-office [isRequired]="true" [customOffice]="roadManifest.customOffice"
                                     (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
<div class="ui-g-12 ui-md-12">
  <p-table [columns]="crewCols"
           [value]="roadManifest.crew"
           selectionMode="single"
           [(selection)]="selectedCrewMember"
           (onRowSelect)="onRowSelect($event)"
           [paginator]="true"
           [rows]="5">
    <ng-template pTemplate="caption">
      Crew
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td>
          {{rowData.surname}}
        </td>
        <td>
          {{rowData.firstNames}}
        </td>
        <td style="text-transform: capitalize">
          {{format(rowData.role)}}
        </td>
        <td>
          <button pButton type="button" icon="fa fa-times" class="danger"
                  (click)="deleteCrewMember(rowIndex)" pTooltip="Delete"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary" let-rowData>
      <div style="text-align:left">
        <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
      </div>
    </ng-template>
  </p-table>
</div>
<p-dialog header="Crew Member" [(visible)]="displayCrewMemberDialog" [responsive]="true" showEffect="fade"
          [modal]="true" [width]="1200">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g form-group">
          <div class="ui-g-6 ui-md-6">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="selectedCrewMember" field="label"
                                  [suggestions]="filteredCrewMembers"
                                  (completeMethod)="searchCrewMember($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.label}}</div>
                    </ng-template>
                  </p-autoComplete>
                <label>Search Crew Member</label>
              </span>
            </div>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <digi-crew-member [selectedCrewMember]="selectedCrewMember" [showRole]="true"></digi-crew-member>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-close" (click)="onCancel()" label="Cancel"></button>
      <button type="button" pButton icon="fa fa-check" (click)="saveCrewMember()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
