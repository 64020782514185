import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClearingFile} from '../../../models/clearingFile';
import {ExportTransportDocument, OceanLine, TransportDocument, Transporter} from '../../../models/file';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {OceanlineService} from '../../../../digi-ocean/services/oceanline.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {TransporterService} from '../../../services/transporter.service';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {ClearingFileService} from '../../../services/clearing-file.service';
import {FileType} from '../../../models/enumerations';
import {ExportFileService} from '../../../services/export-file.service';


@Component({
  selector: 'digi-road-oceanline-details',
  templateUrl: './road-oceanline-details.component.html',
  styleUrls: ['./road-oceanline-details.component.scss']
})
export class RoadOceanlineDetailsComponent implements OnInit, OnChanges, OnDestroy {
  selectedTransportDocument: TransportDocument|ExportTransportDocument;
  transportDocumentSubscription: Subscription;
  exportTransportDocumentSubscription: Subscription;
  filteredOceanlines: OceanLine[] = [];
  clearingFile: ClearingFile;
  clearingFileSubscription: Subscription;
  clearingInstructionSubscription: Subscription;
  filteredTransporters: Transporter[];
  transportMethod: string;
  @Input() validationMessagesForMawbNo;
  @Input() validationMessagesForHawbNo;
  @Input() fileType: FileType;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private oceanlineService: OceanlineService,
              private clearingFileBehaviour: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private transportService: TransporterService,
              private clearingInstructionBehaviourSubject: ClearingInstructionBehaviorSubject,
              private clearingFileService: ClearingFileService,
              private exportFileService: ExportFileService) {
  }

  ngOnInit() {
    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject
      .getTransportDocuments()
      .subscribe(td => {
          if (td) {
            this.selectedTransportDocument = td;
          }
        }
      );

    this.clearingFileSubscription = this.clearingFileBehaviour.getClearingFile().subscribe(cf => {
      if (cf) {
        this.clearingFile = cf;
      }
    });

    this.clearingInstructionSubscription = this.clearingInstructionBehaviourSubject.getClearingInstructions().subscribe(
      clearingInstructions => {
        this.transportMethod = clearingInstructions[0].transportMethod.method;
      }
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validationMessagesForMawbNo.currentValue) {
      this.validationMessagesForMawbNo = changes.validationMessagesForMawbNo.currentValue;
    }
    if (changes.validationMessagesForHawbNo.currentValue) {
      this.validationMessagesForHawbNo = changes.validationMessagesForHawbNo.currentValue;
    }
  }

  ngOnDestroy(): void {
    if (this.transportDocumentSubscription) {
      this.transportDocumentSubscription.unsubscribe();
    }

    if (this.clearingFileSubscription) {
      this.clearingFileSubscription.unsubscribe();
    }

    if (this.clearingInstructionSubscription) {
      this.clearingInstructionSubscription.unsubscribe();
    }
  }

  search(event) {
    this.oceanlineService.findAllOceanLineStartsWith(event.query).subscribe(data => {
      this.filteredOceanlines = data;
    });
  }

  straightCheck(value) {
    this.selectedTransportDocument.straight = value;
    if (this.selectedTransportDocument.straight) {
      this.selectedTransportDocument.hawbNo = this.selectedTransportDocument.mawbNo;
      this.selectedTransportDocument.hawbDate = this.selectedTransportDocument.mawbDate;
    } else {
      this.selectedTransportDocument.hawbNo = '';
      this.selectedTransportDocument.hawbDate = '';
    }
  }

  changeMAWBNo(value) {
    if (this.selectedTransportDocument.straight) {
      this.selectedTransportDocument.hawbNo = this.selectedTransportDocument.mawbNo;
    }
  }

  defaultDates() {
    if (!this.selectedTransportDocument.hawbDate) {
      this.selectedTransportDocument.hawbDate = this.selectedTransportDocument.mawbDate;
    }
    if (!this.selectedTransportDocument.departureDate) {
      this.selectedTransportDocument.departureDate = this.selectedTransportDocument.mawbDate;
    }
  }

  selectTransporter(event) {
    console.log(event);
    this.selectedTransportDocument.transporter.transporterCode = event.transporterCode;
    this.selectedTransportDocument.transporter.transporterName = event.transporterName;
    this.selectedTransportDocument.transporter.customsRegCode = event.customsRegCode;
    this.selectedTransportDocument.transporter.label = event.customsRegCode;
  }

  searchTransporters(event) {
    this.transportService.findTransporterWithCodeOrName(event.query).subscribe(data => {
      this.filteredTransporters = data;
      console.log(data);
    });
  }

  defaultCarrierCode(value) {
    this.selectedTransportDocument.oceanLineDetails.carrierCode = value;
  }

  validateMawbNo() {
    if (this.fileType === 'imports') {
      this.clearingFileService.findClearingFileNumbersByMawbNo(this.selectedTransportDocument.mawbNo, this.clearingFile.clearingFileNumber).subscribe(
        res => {
          if (res.length > 0) {
            const message = 'MAWB No. used in [' + res.join(', ') + ']';
            const foundDetail = this.validationMessagesForMawbNo.find(el => el.detail === message);
            if (!foundDetail) {
              this.validationMessagesForMawbNo.push({severity: 'warn', summary: 'Duplicate MAWB No.', detail: message});
            }
          } else {
            this.validationMessagesForMawbNo.length = 0;
          }
        },
        _ => this.validationMessagesForMawbNo.length = 0
      );
    } else {
      this.exportFileBehaviourSubject.getExportFile().subscribe(
        ef => {
          this.exportFileService.findClearingFileNumbersByMawbNo(this.selectedTransportDocument.mawbNo, ef.clearingFileNumber).subscribe(
            res => {
              if (res.length > 0) {
                const message = 'MOBL No. used in [' + res.join(', ') + ']';
                const foundDetail = this.validationMessagesForMawbNo.find(el => el.detail === message);
                if (!foundDetail) {
                  this.validationMessagesForMawbNo.push({severity: 'warn', summary: 'Duplicate MOBL No.', detail: message});
                }
              } else {
                this.validationMessagesForMawbNo.length = 0;
              }
            },
            _ => this.validationMessagesForMawbNo.length = 0
          );
        }
      );
    }
  }

  validateHawbNo() {
    if (!this.selectedTransportDocument.straight) {
      if (this.fileType === 'imports') {
        this.clearingFileService.findClearingFileNumbersByHawbNo(this.selectedTransportDocument.hawbNo, this.clearingFile.clearingFileNumber).subscribe(
          res => {
            if (res.length > 0) {
              const message = 'HBOL No. used in [' + res.join(', ') + ']';
              const foundDetail = this.validationMessagesForHawbNo.find(el => el.detail === message);
              if (!foundDetail) {
                this.validationMessagesForHawbNo.push({severity: 'warn', summary: 'Duplicate HBOL No.', detail: message});
              }
            } else {
              this.validationMessagesForHawbNo.length = 0;
            }
          },
          _ => this.validationMessagesForHawbNo.length = 0
        );
      } else {
        this.exportFileBehaviourSubject.getExportFile().subscribe(
          ef => {
            this.exportFileService.findClearingFileNumbersByHawbNo(this.selectedTransportDocument.hawbNo, ef.clearingFileNumber).subscribe(
              res => {
                if (res.length > 0) {
                  const message = 'HBOL No. used in [' + res.join(', ') + ']';
                  const foundDetail = this.validationMessagesForHawbNo.find(el => el.detail === message);
                  if (!foundDetail) {
                    this.validationMessagesForHawbNo.push({severity: 'warn', summary: 'Duplicate HBOL No.', detail: message});
                  }
                } else {
                  this.validationMessagesForHawbNo.length = 0;
                }
              },
              _ => this.validationMessagesForHawbNo.length = 0
            );
          }
        );
      }
    }
  }
}
