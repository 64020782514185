<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-lg-12">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield ui-md-4">Total</span>
        <span class="md-inputfield ui-md-4">
          <input type="text"
                 value="{{supplierInvoice?.totalGrossIncomeValue}}"
                 pInputText
                 readonly>
          <label>Value</label>
        </span>
        <span class="md-inputfield ui-md-4">
          <input type="text" value="{{supplierInvoice?.quantity}}"
                 pInputText
                 readonly>
          <label>Quantity(KG)</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield ui-md-4">Captured</span>
        <span class="md-inputfield ui-md-4">
          <input type="text"
                 value="{{supplierInvoice.runningTotals.capturedValue}}"
                 pInputText
                 readonly>
        </span>
        <span class="md-inputfield ui-md-4">
          <input type="text" value="{{supplierInvoice.runningTotals.capturedQuantity}}"
                 pInputText
                 readonly>
        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
        <span class="md-inputfield ui-md-4">Balance</span>
        <span class="md-inputfield ui-md-4">
          <input type="text"
                 value="{{supplierInvoice.runningTotals.remainingValue}}"
                 pInputText
                 readonly>
        </span>
        <span class="md-inputfield ui-md-4">
          <input type="text" value="{{supplierInvoice.runningTotals.remainingQuantity}}"
                 pInputText
                 readonly>
        </span>
      </div>
    </div>
  </div>
</div>
