import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var AirWaybillService = /** @class */ (function () {
    function AirWaybillService(http, config) {
        this.http = http;
        this.config = config;
    }
    AirWaybillService.prototype.save = function (airWaybill) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (airWaybill._links && airWaybill._links.self) {
            return this.http.put(airWaybill._links.self.href, JSON.stringify(airWaybill), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/airWaybills', JSON.stringify(airWaybill), { headers: headers });
        }
    };
    AirWaybillService.prototype.findByAirlineCode = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/airWaybills/search/findByAirlineCode?code=" + code)
            .pipe(map(function (response) { return response['_embedded'].airWaybills; }));
    };
    AirWaybillService.prototype.filterWaybillNumbers = function (airwaybill, exporterCode, fileNumber) {
        var availableWaybillNumbers = [];
        airwaybill.waybillNumbers.forEach(function (number) {
            var reserved = false;
            var used = false;
            if (number.waybillReservation) {
                reserved = !(number.waybillReservation.exporter && number.waybillReservation.exporter.code === exporterCode);
            }
            if (number.waybillUsages) {
                used = !!number.waybillUsages.find(function (usage) { return usage.fileNumber !== fileNumber; });
            }
            if (!reserved && !used) {
                availableWaybillNumbers.push(number);
            }
        });
        return availableWaybillNumbers.map(function (availableWaybillNumber) { return availableWaybillNumber.number; });
    };
    AirWaybillService.ngInjectableDef = i0.defineInjectable({ factory: function AirWaybillService_Factory() { return new AirWaybillService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: AirWaybillService, providedIn: "root" });
    return AirWaybillService;
}());
export { AirWaybillService };
