import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'digi-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [MessageService]
})
export class NotificationComponent implements OnInit, OnDestroy {

  error: any;
  success: any;
  errorSubscription: Subscription;
  successSubscription: Subscription;

  constructor(private messageService: MessageService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.errorSubscription = this.notificationService.errorNotificationChange
      .subscribe(notification => {
        // @ts-ignore
        this.addFailure(notification.summary, notification.detail);
      });
    this.successSubscription = this.notificationService.successNotificationChange
      .subscribe(notification => {
        // @ts-ignore
        this.addSuccess(notification.summary, notification.detail);
      });
  }

  addSuccess(summary: string, detail: any) {
    this.messageService.add({
      key: 'success',
      severity: 'info',
      summary: summary,
      detail: detail
    });
  }

  addFailure(summary: string, detail: any) {
    this.messageService.add({
      key: 'error',
      severity: 'warn',
      summary: summary,
      detail: detail,
      sticky: true
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.successSubscription.unsubscribe();
  }

}
