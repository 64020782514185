import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SundryChargesService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllSundryCharges(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/sundryCharges`);
  }

  findAllSundryChargesStartWith(searchValue, sundryChargeType) {
    console.log(sundryChargeType);
    return this.http
      .get(`${this.config.apiEndpoint}api/sundryCharges/search/codeOrNameStartsWithAndType?code=` +
        searchValue + '&chargeType=' + sundryChargeType)
      .pipe(
        map(response => response['_embedded'].sundryCharges)
      );
  }
}
