import { Component, Input, OnInit } from '@angular/core';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { SupplierInvoice, TransportDocument } from '../../../digi-common/models/file';
import { ClearingFile } from '../../../digi-common/models/clearingFile';
import { ShareDataService } from '../../../digi-common/services/share-data.service';


@Component({
  selector: 'digi-invoice-lines-transport-docs',
  templateUrl: './invoice-lines-transport-docs.component.html',
  styleUrls: ['./invoice-lines-transport-docs.component.scss']
})
export class InvoiceLinesTransportDocsComponent implements OnInit, TreeNode {
  @Input() clearingFile: ClearingFile;
  files: TreeNode[];
  data: any;
  label: string;
  transportDocuments: TreeNode[] = new Array();

  constructor(private shareDataService: ShareDataService,
              private treeDragDropService: TreeDragDropService) {
  }

  ngOnInit() {
    this.clearingFile.transportDocuments.forEach(
      e => {
        const parentTransportDocuments = {
          label: 'House Bill : ' + e.mawbNo + ' - ' + e.hawbNo,
          data: e,
          expandedIcon: 'fa fa-home',
          collapsedIcon: '',
          draggable: false,
          children: new Array()
        };
        e.supplierInvoices.forEach(i => {
          // tslint:disable-next-line:no-unused-expression
          i.supplier + '-' + i.invoiceNumber;
          const childrenSupplierInvoices = {
            label: 'Invoice : ' + i.supplier.code + ' - ' + i.invoiceNumber,
            data: i,
            expandedIcon: 'fa fa-invoice',
            collapsedIcon: 'fa fa-file-text',
            droppable: false
          };
          parentTransportDocuments.children.push(childrenSupplierInvoices);
        });
        this.transportDocuments.push(parentTransportDocuments);
      });
  }

  onNodeDrop(value) {
    const draggedParent: TransportDocument = value.dragNode.parent.data;
    const dragged: SupplierInvoice = value.dragNode.data;
    const dropped: TransportDocument = value.dropNode.data;
    dropped.supplierInvoices.push(dragged);
    const index = draggedParent.supplierInvoices.indexOf(dragged);
    if (index > -1) {
      draggedParent.supplierInvoices.splice(index, 1);
    }
  }
}
