<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                <p-autoComplete id="airline" class="ng-dirty ng-invalid"
                                [(ngModel)]="transportDocument.airline" field="name"
                                [suggestions]="filteredAirlines"
                                (completeMethod)="search($event)"
                                [autoHighlight]="true" minLength="2"
                                (onSelect)="select($event)"
                                required=true>
                  <ng-template let-option pTemplate="item">
                    <div>{{option.code}}: {{option.name}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="airline">Airline</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>
             <div *ngIf="!isQuotation && !transportDocument.airline.code" class="ui-message ui-messages-error ui-corner-all">
               This field is required
          </div>
          </span>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span *ngIf="transportDocument.airline" class="ui-inputgroup-addon">{{transportDocument.airline?.prefix}}</span>
              <span class="md-inputfield">
                <input id="txtMAWBPartTwo" type="text" class="ui-inputgroup"
                       (change)="changeMAWBNo($event)"
                       (focusout)="validateMawbNo()"
                       [(ngModel)]="transportDocument.mawbNo"
                       pInputText required minlength="8">
                     <label>MAWB No</label>
             </span>
               <span class="ui-inputgroup-addon">
                  <p-checkbox [(ngModel)]="transportDocument.validateMawbNo" binary="true"></p-checkbox>
                </span>
            <span class="ui-inputgroup-addon">Validate MAWB No</span>
            </div>
            <div *ngIf="!isQuotation && !transportDocument.mawbNo" class="ui-message ui-messages-error ui-corner-all">
                This field is required
            </div>
          </span>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input id="txtSubMAWBPartTwo" type="text" class="ui-inputgroup"
                         [(ngModel)]="transportDocument.subMAWBNo" pInputText>
                  <label> Sub MAWB No</label>
                </span>
            <span class="ui-inputgroup-addon">
                  <p-checkbox [(ngModel)]="transportDocument.postalClearing" binary="true"></p-checkbox>
                </span>
            <span class="ui-inputgroup-addon">Postal Clearing</span>
          </div>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div *ngIf="transportDocument.straight; then custom else copy"></div>
          <ng-template #copy>
                <span class="md-inputfield">
                   <div class="ui-inputgroup">
                     <span class="md-inputfield">
                         <input
                           type="text" [(ngModel)]="transportDocument.hawbNo" pInputText
                           (focusout)="validateHawbNo()"
                           class="ui-inputgroup"
                           id="txtHawbNo">
                       <label>HAWB No</label>
                     </span>
                   </div>
                   <div *ngIf="!isQuotation && !transportDocument.hawbNo" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                </span>
                <span *ngIf="!isAirExports" class="ui-inputgroup-addon">
                    <p-checkbox
                      (onChange)="validateHawbNo()"
                      [(ngModel)]="transportDocument.validateHawbNo" binary="true"></p-checkbox>
                </span>
            <span *ngIf="!isAirExports" class="ui-inputgroup-addon">Validate HAWB No</span>
          </ng-template>
          <ng-template #custom>
                   <span class="md-inputfield">
                     <div class="ui-inputgroup">
                        <span class="md-inputfield">
                              <input [(ngModel)]="transportDocument.hawbNo"
                                     [readonly]="transportDocument.straight"
                                     type="text" pInputText
                                     class="ui-inputgroup"
                                     id="txtHawbNo">
                          <label>HAWB No</label>
                        </span>
                     </div>
                     <div *ngIf="!isQuotation && !transportDocument.hawbNo" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                   </span>
          </ng-template>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" [(ngModel)]="transportDocument.clientRefNo"
                     pInputText class="ui-inputgroup"
                     id="txtClientRefNo">
              <label>Client Ref No</label>
            </span>
          </div>
        </span>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="mawbDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               (change)="defaultDates()"
                               [(ngModel)]="transportDocument.mawbDate">

                  </p-inputMask>
                     <label for="mawbDate">MAWB Date</label>
                </span>
              </span>
              <div *ngIf="!transportDocument.mawbDate"
                   class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
        </div>
      </div>
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
                <span class="ui-float-label">
                   <span class="md-inputfield">
                    <p-inputMask id="hawbDate" mask="99/99/9999"
                                 slotChar="dd/mm/yyyy"
                                 [(ngModel)]="transportDocument.hawbDate"
                                 [readonly]="transportDocument.straight">
                    </p-inputMask>
                        <label for="hawbDate">HAWB Date</label>
                   </span>
                </span>
                <div *ngIf="!isQuotation && !transportDocument.hawbDate"
                     class="ui-message ui-messages-error ui-corner-all">This field is required
                </div>
            </span>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-12">
              <p-checkbox name="straight"
                          label="Straight"
                          (onChange)="straightCheck($event)"
                          [(ngModel)]="transportDocument.straight"
                          binary="true">

              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


