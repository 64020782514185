export class RoleMetaData{
    id?: number;
    description?: string;
    parent: number;
    group: string;
    autoUpdateName: boolean;
    contactMandatory: boolean;
    addressTypes: RoleAddressType[];
    nominations?: RoleNominationType[];
}

export class RoleAddressType{
    id?: RoleAddressTypePK;
    mandatory?: boolean;
    addressType?: AddressType;
    addressQuantity?: AddressQuantity;
}

export class RoleAddressTypePK{
    addressTypeId?: string;
    roleId?: string;
}

export class AddressQuantity{
    addressQuantity?: number;
    addressQuantityDesc?: string;
}

export class AddressType{
    id?: string;
    description?: string;
}

export class RoleNominationType{
    id?: RoleNominationPK;
    canBeSelf?: boolean;
    displayText?: string;
    selfText?: string;
    mandatory?: boolean;
}

export class RoleNominationPK{
    parentRoleId?: number;
    roleId?: number;
}