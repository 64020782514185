/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terminal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/primeng/components/autocomplete/autocomplete.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/utils/objectutils";
import * as i5 from "primeng/components/autocomplete/autocomplete";
import * as i6 from "@angular/forms";
import * as i7 from "primeng/components/common/shared";
import * as i8 from "@angular/common";
import * as i9 from "./terminal.component";
import * as i10 from "../../../../services/depot-terminal.service";
import * as i11 from "../../../../../../subjects/container-behavior-subject";
var styles_TerminalComponent = [i0.styles];
var RenderType_TerminalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TerminalComponent, data: {} });
export { RenderType_TerminalComponent as RenderType_TerminalComponent };
function View_TerminalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_TerminalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ui-message ui-messages-error ui-corner-all"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This field is required "]))], null, null); }
export function View_TerminalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 20, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 19, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "span", [["class", "ui-float-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "p-autoComplete", [["field", "label"], ["minLength", "1"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "completeMethod"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedContainer.terminal = $event) !== false);
        ad = (pd_0 && ad);
    } if (("completeMethod" === en)) {
        var pd_1 = (_co.search($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AutoComplete_0, i2.RenderType_AutoComplete)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵprd(512, null, i4.ObjectUtils, i4.ObjectUtils, []), i1.ɵdid(11, 9879552, null, 1, i5.AutoComplete, [i1.ElementRef, i3.DomHandler, i1.Renderer2, i4.ObjectUtils, i1.ChangeDetectorRef, i1.IterableDiffers], { minLength: [0, "minLength"], autoHighlight: [1, "autoHighlight"], forceSelection: [2, "forceSelection"], field: [3, "field"], dropdown: [4, "dropdown"], suggestions: [5, "suggestions"] }, { completeMethod: "completeMethod" }), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.AutoComplete]), i1.ɵdid(14, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(16, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_TerminalComponent_1)), i1.ɵdid(18, 16384, [[1, 4]], 0, i7.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terminal"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [["class", "ui-inputgroup-addon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TerminalComponent_2)), i1.ɵdid(24, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "1"; var currVal_10 = true; var currVal_11 = true; var currVal_12 = "label"; var currVal_13 = true; var currVal_14 = _co.filteredDepotTerminals; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.selectedContainer.terminal; _ck(_v, 14, 0, currVal_15); var currVal_16 = "item"; _ck(_v, 18, 0, currVal_16); var currVal_17 = (!((_co.selectedContainer == null) ? null : ((_co.selectedContainer.depot == null) ? null : _co.selectedContainer.depot.code)) && !((_co.selectedContainer == null) ? null : ((_co.selectedContainer.terminal == null) ? null : _co.selectedContainer.terminal.code))); _ck(_v, 24, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).filled; var currVal_1 = (i1.ɵnov(_v, 11).focus && !i1.ɵnov(_v, 11).disabled); var currVal_2 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 16).ngClassValid; var currVal_7 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TerminalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-app-terminal", [], null, null, null, View_TerminalComponent_0, RenderType_TerminalComponent)), i1.ɵdid(1, 245760, null, 0, i9.TerminalComponent, [i10.DepotTerminalService, i11.ContainerBehaviorSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TerminalComponentNgFactory = i1.ɵccf("digi-app-terminal", i9.TerminalComponent, View_TerminalComponent_Host_0, { filteredDepotTerminals: "filteredDepotTerminals" }, {}, []);
export { TerminalComponentNgFactory as TerminalComponentNgFactory };
