import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {ForwardingFile} from '../../models/ForwardingFile';
import {ForwardingFileBehaviourSubject} from '../../../../subjects/forwarding-file-behaviour-subject';
import {ForwardingFileValidationService} from '../../services/forwarding-file-validation.service';
import {ForwardingFileService} from '../../services/forwarding-file.service';

@Component({
  selector: 'digi-forwarding-steps-navigation',
  templateUrl: './forwarding-steps-navigation.component.html',
  styles: [`
    .ui-steps-item {
      width: 16.6%
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ForwardingStepsNavigationComponent implements OnInit, OnDestroy {

  @Input() transportMethod;
  items: MenuItem[];
  forwardingFile: ForwardingFile;
  activeIndex = 0;
  flag: boolean;

  forwardingFileSubscription: Subscription;

  constructor(
    private router: Router,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private messageService: NotificationService,
    private forwardingFileValidationService: ForwardingFileValidationService,
    private forwardingFileService: ForwardingFileService
  ) {
  }

  ngOnInit() {
    this.forwardingFileSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
      .subscribe(file => this.forwardingFile = file);

    this.items = [
      {
        label: 'Manifests',
        command: (event: any) => {
          this.activeIndex = 0;
          this.router.navigate([`/forwarding/${this.transportMethod}/manifests`]);
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'EDI',
        command: async (event: any) => {
          const validationResult = await this.forwardingFileValidationService.validateManifests(this.forwardingFile, this.transportMethod);
          if (validationResult.isValid) {
            this.flag = true;
            this.activeIndex = 1;
            this.forwardingFileService.save(this.forwardingFile).subscribe(
              _ => {
                this.router.navigate([`/forwarding/${this.transportMethod}/edi`]);
                this.saveConfirmation();
              }
            );
          } else {
            this.activeIndex = 0;
            this.showMessages(validationResult.messages);
          }
        },
        icon: 'fa fa-user-plus'
      }
    ];
  }

  ngOnDestroy(): void {
    this.forwardingFileSubscription.unsubscribe();
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  showMessages(messages: string[]) {
    this.messageService.errorNotify('Validation Failed', messages);
    this.flag = true;
  }

}
