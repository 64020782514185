import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class VesselsAgentService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }


  findVesselsAgentWithCodeOrName( code: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/vesselsAgents/search/codeOrNameStartsWith?code=${code}`)
      .pipe(
        map(response => response['_embedded'].vesselsAgents)
      );
  }


}
