/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hawb-degroup-transit-section.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../sub-components/degroup-search/degroup-search.component.ngfactory";
import * as i3 from "../sub-components/degroup-search/degroup-search.component";
import * as i4 from "../../services/depot-terminal.service";
import * as i5 from "../../../../subjects/transport-document-behaviour-subject";
import * as i6 from "./hawb-degroup-transit-section.component";
var styles_HawbDegroupTransitSectionComponent = [i0.styles];
var RenderType_HawbDegroupTransitSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HawbDegroupTransitSectionComponent, data: {} });
export { RenderType_HawbDegroupTransitSectionComponent as RenderType_HawbDegroupTransitSectionComponent };
export function View_HawbDegroupTransitSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "digi-degroup-search", [], null, null, null, i2.View_DegroupSearchComponent_0, i2.RenderType_DegroupSearchComponent)), i1.ɵdid(4, 245760, null, 0, i3.DegroupSearchComponent, [i4.DepotTerminalService, i5.TransportDocumentBehaviourSubject], { isQuotation: [0, "isQuotation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isQuotation; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_HawbDegroupTransitSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-hawb-degroup-transit-section", [], null, null, null, View_HawbDegroupTransitSectionComponent_0, RenderType_HawbDegroupTransitSectionComponent)), i1.ɵdid(1, 114688, null, 0, i6.HawbDegroupTransitSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HawbDegroupTransitSectionComponentNgFactory = i1.ɵccf("digi-hawb-degroup-transit-section", i6.HawbDegroupTransitSectionComponent, View_HawbDegroupTransitSectionComponent_Host_0, { transportDocument: "transportDocument", isQuotation: "isQuotation" }, {}, []);
export { HawbDegroupTransitSectionComponentNgFactory as HawbDegroupTransitSectionComponentNgFactory };
