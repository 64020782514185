import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CrewMember, PortDetail, Transporter} from '../../../digi-common/models/file';
import {ValidationResult} from '../../../digi-common/services/validation.service';
import {CrewMemberService} from '../../../digi-common/services/crew-member.service';
import {RoadManifest, CuscarType} from '../../../digi-common/models/roadManifest';
import {NotificationService} from '../../../digi-common/services/notification.service';
import {TransporterService} from '../../../digi-common/services/transporter.service';
import {CuscarTypeService} from '../../../digi-common/services/cuscar-type.service';
import {ExportsBillOfEntryService} from '../../../digi-common/services/exports-bill-of-entry.service';
import {ManifestService} from '../../../digi-common/services/manifest.service';
import {Export, ExportBillOfEntry} from '../../../digi-common/models/billOfEntries';
import {Subscription} from 'rxjs';
import {CargoType, ManifestType} from '../../../digi-common/models/voyageFile';

@Component({
  selector: 'digi-manifest-register',
  templateUrl: './manifest-register.component.html',
  styleUrls: ['./manifest-register.component.scss']
})
export class ManifestRegisterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() roadManifest: RoadManifest;
  displayCrewMemberDialog: boolean;
  @Input() selectedCrewMember: CrewMember;
  newCrewMember: boolean;
  filteredCrewMembers: CrewMember[];
  crewCols: any[];
  filteredTransporters: Transporter[];
  filteredCuscarTypes: CuscarType[];
  manifestTypeOptions: any[];
  cargoTypeOptions: any[];

  crewMemberServiceSubscription: Subscription;
  transportServiceSubscription: Subscription;
  cuscarTypeServiceSubscription: Subscription;
  exportsBillOfEntryServiceSubscription: Subscription;
  filteredMawbNumbers: ExportBillOfEntry[];

  constructor(
    private crewMemberService: CrewMemberService,
    private notificationService: NotificationService,
    private transportService: TransporterService,
    private cuscarTypeService: CuscarTypeService,
    private exportsBillOfEntryService: ExportsBillOfEntryService,
    private manifestService: ManifestService
  ) { }

  ngOnInit() {
    const defaultValue = [{'label': '', 'value': ''}];
    this.manifestTypeOptions = defaultValue.concat(Object.keys(ManifestType).map(key => ({ 'label': ManifestType[key], 'value': key })));
    this.cargoTypeOptions = defaultValue.concat(Object.keys(CargoType).map(key => ({ 'label': CargoType[key], 'value': key })));
    this.crewCols = [
      {field: 'surname', header: 'Surname', width: '30%'},
      {field: 'firstNames', header: 'First Names', width: '30%'},
      {field: 'crewMemberRole', header: 'Role', width: '30%'},
      {field: '', header: '', width: '10%'}
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.crewMemberServiceSubscription) {
      this.crewMemberServiceSubscription.unsubscribe();
    }
    if (this.transportServiceSubscription) {
      this.transportServiceSubscription.unsubscribe();
    }
    if (this.cuscarTypeServiceSubscription) {
      this.cuscarTypeServiceSubscription.unsubscribe();
    }
    if (this.exportsBillOfEntryServiceSubscription) {
      this.exportsBillOfEntryServiceSubscription.unsubscribe();
    }
  }

  showDialogToAdd() {
    this.newCrewMember = true;
    this.selectedCrewMember = new CrewMember();
    this.displayCrewMemberDialog = true;
  }

  saveCrewMember() {
    if (this.isValidCrewMember()) {
      if (this.selectedCrewMember.id) {
        this.addCrewMember();
      } else {
        this.crewMemberServiceSubscription = this.crewMemberService.saveCrewMember(this.selectedCrewMember).subscribe((data: CrewMember) => {
          this.selectedCrewMember.id = data.id;
          this.addCrewMember();
        });
      }
    }
  }

  private addCrewMember() {
    const crew = [...this.roadManifest.crew];
    if (this.newCrewMember) {
      crew.push(this.selectedCrewMember);
    } else {
      crew[this.roadManifest.crew.indexOf(this.selectedCrewMember)] = this.selectedCrewMember;
    }
    this.displayCrewMemberDialog = false;
    this.roadManifest.crew = crew;
  }

  deleteCrewMember(index) {
    this.roadManifest.crew = this.roadManifest.crew.filter((val, i) => i !== index);
    this.displayCrewMemberDialog = false;
  }

  onRowSelect(event: any) {
    this.newCrewMember = false;
    this.displayCrewMemberDialog = true;
  }

  selectCrewMember(value) {
    this.selectedCrewMember = value;
  }

  searchCrewMember(event) {
    this.crewMemberServiceSubscription = this.crewMemberService.findBySurnameOrFirstNamesStartsWith(event.query)
      .subscribe(crewMembers => this.filteredCrewMembers = crewMembers);
  }

  onCancel() {
    this.displayCrewMemberDialog = false;
    this.selectedCrewMember = new CrewMember();
  }

  format(value) {
    return value ? value.replace(/_/g, ' ').toLowerCase() : '';
  }

  isValidCrewMember() {
    const validationResult: ValidationResult = this.crewMemberService.isValidCrewMember(this.selectedCrewMember, {
      isValid: true,
      messages: []
    });
    if (!this.selectedCrewMember.role) {
      validationResult.isValid = false;
      validationResult.messages.push('Please Enter Crew Member Role');
    }
    if (!validationResult.isValid) {
      this.addFailure(validationResult.messages);
    }
    return validationResult.isValid;
  }

  addFailure(msg: any) {
    this.notificationService.errorNotify(
      'Warning',
      msg,
    );
  }

  selectCountryOfExport(country) {
    this.roadManifest.countryOfExport.code = country.code;
    this.roadManifest.countryOfExport.name = country.name;
  }

  selectDestination(country) {
    this.roadManifest.destination.code = country.code;
    this.roadManifest.destination.name = country.name;
    this.roadManifest.destination.label = country.label;
  }

  updateCustomsOffice(value) {
    if (value) {
      this.roadManifest.customOffice.districtOfficeCode = value.districtOfficeCode;
      this.roadManifest.customOffice.districtOfficeName = value.districtOfficeName;
    }
  }

  searchTransporters(event) {
    this.transportServiceSubscription = this.transportService.findTransporterWithCodeOrName(event.query).subscribe(data => {
      this.filteredTransporters = data;
    });
  }

  selectTransporter(value) {
    this.roadManifest.transporter.transporterCode = value.transporterCode;
    this.roadManifest.transporter.transporterName = value.transporterName;
    this.roadManifest.transporter.label = value.label;
    this.roadManifest.transporter.customsRegCode = value.customsRegCode;
  }

  updateLocalAgent(value) {
    this.roadManifest.localAgent = value;
  }

  searchCuscarType(event: any) {
    this.cuscarTypeServiceSubscription = this.cuscarTypeService.findByTypeOrDescription(event.query).subscribe(
      data => this.filteredCuscarTypes = data
    );
  }

  selectCuscarType(value: CuscarType) {
    this.roadManifest.cuscarType = value;
  }

  findBoesByMawbNo(manifestNo: string) {
    this.exportsBillOfEntryServiceSubscription = this.exportsBillOfEntryService.findByMawbNo(manifestNo).subscribe(
      (boes: ExportBillOfEntry[]) => {
        if (boes) {
          boes.forEach(boe => this.manifestService.createWaybill(this.roadManifest, boe));
        }
      }
    );
  }

  searchBoes(event) {
    this.roadManifest.manifestNo = event.query;
    this.exportsBillOfEntryServiceSubscription = this.exportsBillOfEntryService.findByMawbNo(event.query).subscribe(
      (boes: ExportBillOfEntry[]) => {
        this.filteredMawbNumbers = boes.map(el => el.mawbNumber);
        console.log(this.filteredMawbNumbers);
      }
    );
  }

  isCorrection() {
    return this.roadManifest.type === 'RoadManifestCorrection';
  }

  selectExitPort(value: PortDetail) {
    this.roadManifest.exitPort = value;
  }

  selectBoe(value) {
    this.roadManifest.manifestNo = value;
    this.findBoesByMawbNo(value);
  }
}
