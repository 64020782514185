import { VoyageMasterFile } from '../models/voyageFile';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessEntity } from '../../digi-business-entity/models/business-entity';
import { Freight, Measures, OceanLineDetails, VoyageDetails } from '../models/file';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "../../../subjects/authorisation-behaviour-subject";
var VoyageFileService = /** @class */ (function () {
    function VoyageFileService(http, config, authorisationService) {
        this.http = http;
        this.config = config;
        this.authorisationService = authorisationService;
    }
    VoyageFileService.prototype.saveVoyageFile = function (voyageFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (voyageFile._links && voyageFile._links.self) {
            return this.http.put(voyageFile._links.self.href.replace('\{\?projection\}', ''), JSON.stringify(voyageFile), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/voyageFiles', JSON.stringify(voyageFile), { headers: headers });
        }
    };
    VoyageFileService.prototype.findVoyageFilesByVoyageFileNo = function (fileNumber, type) {
        if (fileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/voyageFiles/search/voyageFileNoStartsWith?voyageFileNo=" + fileNumber + "&type=" + type + "&projection=flattenedVoyageFile")
            .pipe(map(function (response) { return response['_embedded'].voyageFiles; }));
    };
    VoyageFileService.prototype.findVoyageFilesByVoyageNo = function (voyageNo, type) {
        if (voyageNo === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/voyageFiles/search/findByVoyageNo?voyageNo=" + voyageNo + "&type=" + type + "&projection=flattenedVoyageFile")
            .pipe(map(function (response) { return response['_embedded'].voyageFiles; }));
    };
    VoyageFileService.prototype.createNewVoyageMasterFile = function () {
        var voyageMasterFile = new VoyageMasterFile();
        this.authorisationService.getLoggedInUser().pipe(map(function (user) {
            if (user && user.attributes && user.attributes['branch']) {
                voyageMasterFile.branch = user.attributes['branch'][0];
            }
        }));
        voyageMasterFile.overseasAgent = new BusinessEntity();
        voyageMasterFile.localForwarder = new BusinessEntity();
        voyageMasterFile.debtor = new BusinessEntity();
        voyageMasterFile.consignee = new BusinessEntity();
        voyageMasterFile.consignor = new BusinessEntity();
        voyageMasterFile.releaseAgent = new BusinessEntity();
        voyageMasterFile.oceanLineDetails = new OceanLineDetails();
        voyageMasterFile.measures = new Measures();
        voyageMasterFile.freight = new Freight();
        voyageMasterFile.voyageDetails = new VoyageDetails();
        return voyageMasterFile;
    };
    VoyageFileService.prototype.deleteVoyageFile = function (id) {
        return this.http.delete(this.config.filesApiEndpoint + "api/voyageFiles/" + id);
    };
    VoyageFileService.ngInjectableDef = i0.defineInjectable({ factory: function VoyageFileService_Factory() { return new VoyageFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.AuthorisationService)); }, token: VoyageFileService, providedIn: "root" });
    return VoyageFileService;
}());
export { VoyageFileService };
