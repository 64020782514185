import {InjectionToken, NgModule} from '@angular/core';
import {environment} from '../environments/environment';
import { TenantService } from './tenant/tenant.service';
import { Injectable } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

@Injectable({
  providedIn: 'root'
})
export class AppConfig {

  apiEndpoint = 'http://localhost:8080/';
  filesApiEndpoint = 'http://localhost:8081/';
  branchCode = 11;
  zipRequests = false;
  enableTracking = false;

  constructor(
    private tenantService: TenantService
    ) {
      this.apiEndpoint = this.tenantService.apiEndpoint;
      this.filesApiEndpoint = this.tenantService.filesApiEndpoint;
      this.branchCode = this.tenantService.branchCode;
      this.zipRequests = this.tenantService.zipRequests;
      this.enableTracking = this.tenantService.enableTracking;
      console.log('constructed AppConfig: apiEndpoint=%s, filesApiEndpoint=%s, branchCode=%s',
        this.apiEndpoint, this.filesApiEndpoint, this.branchCode);
  }

}

// export const APP_DI_CONFIG: AppConfig = {
//   apiEndpoint: environment.apiEndpoint,
//   filesApiEndpoint: environment.filesApiEndpoint,
//   branchCode: environment.branchCode,
//   tenantService: null
// };

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useClass: AppConfig
  }]
})

export class AppConfigModule {
}
