import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../app.config.module";
import * as i2 from "@angular/common/http";
var ReceivingBankService = /** @class */ (function () {
    function ReceivingBankService(config, http) {
        this.config = config;
        this.http = http;
    }
    ReceivingBankService.prototype.findReceivingBankStartingWith = function (query) {
        return this.http
            .get(this.config.apiEndpoint + "api/receivingBanks/search/codeOrNameStartsWith?nameOrCode=" + query)
            .pipe(map(function (response) { return response['_embedded'].receivingBanks; }));
    };
    ReceivingBankService.ngInjectableDef = i0.defineInjectable({ factory: function ReceivingBankService_Factory() { return new ReceivingBankService(i0.inject(i1.APP_CONFIG), i0.inject(i2.HttpClient)); }, token: ReceivingBankService, providedIn: "root" });
    return ReceivingBankService;
}());
export { ReceivingBankService };
