/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exports-road-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../digi-common/exports-transport-steps-navigation/exports-transport-steps-navigation.component.ngfactory";
import * as i3 from "../../../digi-common/exports-transport-steps-navigation/exports-transport-steps-navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../subjects/exportfile-behaviour-subject";
import * as i6 from "../../../digi-common/services/notification.service";
import * as i7 from "../../../digi-common/services/export-file.service";
import * as i8 from "../../../../subjects/transport-document-behaviour-subject";
import * as i9 from "../../../../subjects/supplierInvoice-behaviour-subject";
import * as i10 from "../../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i11 from "../../../digi-common/services/validation.service";
import * as i12 from "../../../../subjects/billOfEntry-behaviour-subject";
import * as i13 from "./exports-road-dashboard.component";
import * as i14 from "../../../../subjects/clearingInstruction-behavior-subject";
import * as i15 from "../../../../subjects/billOfEntryLine-behaviour-subject";
import * as i16 from "../../../digi-common/services/share-data.service";
var styles_ExportsRoadDashboardComponent = [i0.styles];
var RenderType_ExportsRoadDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportsRoadDashboardComponent, data: {} });
export { RenderType_ExportsRoadDashboardComponent as RenderType_ExportsRoadDashboardComponent };
export function View_ExportsRoadDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "digi-exports-transport-steps-navigation", [["class", "col-md-12 secondary-nav"]], null, null, null, i2.View_ExportsTransportStepsNavigationComponent_0, i2.RenderType_ExportsTransportStepsNavigationComponent)), i1.ɵdid(2, 245760, null, 0, i3.ExportsTransportStepsNavigationComponent, [i4.Router, i5.ExportFileBehaviourSubject, i6.NotificationService, i7.ExportFileService, i8.TransportDocumentBehaviourSubject, i9.SupplierInvoiceBehaviourSubject, i10.SupplierInvoiceLineBehaviourSubject, i11.ValidationService, i12.BillOfEntryBehaviourSubject], { transportMethod: [0, "transportMethod"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "road"; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_ExportsRoadDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-exports-road-dashboard", [], null, null, null, View_ExportsRoadDashboardComponent_0, RenderType_ExportsRoadDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i13.ExportsRoadDashboardComponent, [i4.Router, i5.ExportFileBehaviourSubject, i14.ClearingInstructionBehaviorSubject, i12.BillOfEntryBehaviourSubject, i15.BillOfEntryLineBehaviourSubject, i16.ShareDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExportsRoadDashboardComponentNgFactory = i1.ɵccf("digi-exports-road-dashboard", i13.ExportsRoadDashboardComponent, View_ExportsRoadDashboardComponent_Host_0, {}, {}, []);
export { ExportsRoadDashboardComponentNgFactory as ExportsRoadDashboardComponentNgFactory };
