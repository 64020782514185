<p-toolbar>
  <div class="ui-toolbar-group-left ui-g-9">
  </div>
  <div class="ui-toolbar-group-right">
    <div class="ui-md-12 ui-g-12">
      <button pButton type="button" label="Generate"
              [disabled]="disableButton"
              (click)="generate()"></button>
      <button *ngIf="pdfSrc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
    </div>
  </div>
</p-toolbar>
<div *ngIf="disableButton" class="ui-g-12">
  <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
</div>
<div class="card card-w-title">
  <div class="ui-g">
    <pdf-viewer class="ui-g-12 ui-md-12"
                *ngIf="pdfSrc"
                [src]="pdfSrc"
                [render-text]="true"
                [original-size]="true"
                [show-all]="true"
                [stick-to-page]="true"
                (after-load-complete)="disableButton=false"
    ></pdf-viewer>
  </div>
</div>
