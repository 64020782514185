import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CorrectionService = /** @class */ (function () {
    function CorrectionService(http, config) {
        this.http = http;
        this.config = config;
    }
    CorrectionService.prototype.sendCorrection = function (correction) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (correction._links && correction._links.self) {
            return this.http.put(correction._links.self.href, JSON.stringify(correction), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/corrections', JSON.stringify(correction), { headers: headers });
        }
    };
    CorrectionService.ngInjectableDef = i0.defineInjectable({ factory: function CorrectionService_Factory() { return new CorrectionService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CorrectionService, providedIn: "root" });
    return CorrectionService;
}());
export { CorrectionService };
