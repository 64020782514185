import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';
import {ClearingFile} from '../app/digi-common/models/clearingFile';
import {ShareDataService} from '../app/digi-common/services/share-data.service';
import {ExBondFile} from '../app/digi-common/models/exBondFile';

@Injectable({
  providedIn: 'root'
})
export class ExBondFileBehaviourSubject {
  private exBondFileBehaviorSubject = new BehaviorSubject<ExBondFile>(this.shareDataService.buildExBondFile());

  constructor(private shareDataService: ShareDataService) {
  }

  addExBondFile(exBondFile: ExBondFile) {
    this.exBondFileBehaviorSubject.next(exBondFile);
  }

  getExBondFile(): Observable<ExBondFile> {
    return this.exBondFileBehaviorSubject.asObservable();
  }
}
