import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var DebtorBehaviourSubject = /** @class */ (function () {
    function DebtorBehaviourSubject() {
        this.debtorsSubject = new Subject();
    }
    DebtorBehaviourSubject.prototype.addDebtors = function (debtors) {
        this.debtorsSubject.next(debtors);
    };
    DebtorBehaviourSubject.prototype.getDebtors = function () {
        return this.debtorsSubject.asObservable();
    };
    DebtorBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function DebtorBehaviourSubject_Factory() { return new DebtorBehaviourSubject(); }, token: DebtorBehaviourSubject, providedIn: "root" });
    return DebtorBehaviourSubject;
}());
export { DebtorBehaviourSubject };
