import {Component, OnInit} from '@angular/core';
import {Part} from '../../models/part';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PartService} from '../../services/part.service';
import {NotificationService} from '../../services/notification.service';
import {BusinessEntity, TariffCode} from '../../models/file';
import {CsvUploadService} from '../../services/csv-upload.service';
import {PartDetail} from '../../models/partDetail';

@Component({
  selector: 'digi-parts-maintenance',
  templateUrl: './parts-maintenance.component.html',
  styleUrls: ['./parts-maintenance.component.scss']
})
export class PartsMaintenanceComponent implements OnInit {

  disableButton: boolean;
  part: Part;
  filteredParts: any[];
  importExportIndicator: string;
  filteredTariffCodes: any[];
  tariffCode: TariffCode;
  header = false;
  partDetails: PartDetail[];
  display: boolean;
  private file: any;
  cols: any[];
  businessEntity: BusinessEntity = new BusinessEntity();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private partService: PartService,
    private notificationService: NotificationService,
    private csvUploadService: CsvUploadService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'partNumber', header: 'Part Number', width: '30%'},
      {field: 'description', header: 'Description', width: '30%'},
      {field: 'tariffCode', header: 'Tariff Code', width: '30%'}
    ];
    this.importExportIndicator = this.route.snapshot.paramMap.get('indicator') === 'imports' ? 'I' : 'E';
    this.createNewPart();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.importExportIndicator = this.route.snapshot.paramMap.get('indicator') === 'imports' ? 'I' : 'E';
        this.createNewPart();
      }
    });
  }

  savePart() {
    this.disableButton = true;
    this.partService.savePart(this.part).subscribe(
      part => {
        this.part = part;
        this.notificationService.successNotify('Part Saved Successfully');
        this.disableButton = false;
      },
      _ => this.disableButton = false
    );
  }

  createNewPart() {
    this.part = Object.assign({}, new Part());
    this.part.importExportIndicator = this.importExportIndicator;
  }

  searchParts(event: any) {
    this.partService.findByPartNumber(event.query, this.importExportIndicator).subscribe(data => this.filteredParts = data);
  }

  searchPartsByDescription(event: any) {
      this.partService.findByPartDescription(event.query, this.importExportIndicator).subscribe(data => this.filteredParts = data);
    }

  selectPart(value: Part) {
    this.part = value;
  }

  selectFiles(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.file = files[0];
    this.csvUploadService.createPartDetails(this.file, 'part', this.importExportIndicator, this.businessEntity).subscribe(
      data => this.partDetails = data
    );
  }

  uploadPartDetails() {
    this.disableButton = true;
    this.partService.saveParts(this.partDetails).subscribe(
      data => {
        this.notificationService.successNotify('Parts Created' +
          ' Successfully');
        this.disableButton = false;
      },
      _ => this.disableButton = false
    );
  }

  deletePartDetail(index: any) {
    this.partDetails.splice(index, 1);
    this.partDetails = [...this.partDetails];
  }

  selectBusinessEntity(value: BusinessEntity) {
    this.businessEntity = value;
  }
}
