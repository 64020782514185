<div class="card card-w-title">
  <button type="button" pButton icon="fa fa-search" (click)="openSearchCriteriaModal()"
          label="File Enquiry"></button>
  <h1 style="text-align: center">SARS Messages</h1>
  <p-table [value]="forwardingFiles"
           [paginator]="true"
           [rows]="10"
           [lazy]="true"
           [lazyLoadOnInit]="true"
           [loading]="loading"
           (onLazyLoad)="onLazyLoad($event)"
           [totalRecords]="totalRecords"
           [columns]="cols"
           [responsive]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns let-forwardingFile>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-forwardingFile>
      <tr *ngFor="let houseBill of forwardingFile.houseBills">
        <td class="truncate wrap">
          <a (click)="onForwardingFileNumberClick(forwardingFile?.fileNumber, houseBill.hawbNo)">
            {{forwardingFile?.fileNumber || ' '}}
          </a>
        </td>
        <td class="truncate wrap">{{forwardingFile?.masterBill?.mawbNo || ' '}}</td>
        <td class="truncate wrap">{{houseBill.hawbNo || ' '}}</td>
        <td class="truncate wrap">{{houseBill.customsValue?.localAmount || ' '}}</td>
        <td class="truncate wrap">{{forwardingFile?.cusres?.customsStatus || ' '}}</td>
        <td class="truncate wrap">{{houseBill.clearingFileNumber || ' '}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
