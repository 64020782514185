import { Component, OnInit } from '@angular/core';
import {TreeNode} from 'primeng/primeng';
import {ClearingInstructionsService} from '../../services/clearing-instructions.service';

@Component({
  selector: 'digi-cpc-enquiry',
  templateUrl: './cpc-enquiry.component.html',
  styleUrls: ['./cpc-enquiry.component.scss']
})
export class CpcEnquiryComponent implements OnInit {
  codesTree: TreeNode[];

  constructor(
    private clearingInstructionsService: ClearingInstructionsService
  ) { }

  ngOnInit() {
    this.clearingInstructionsService.findAll().subscribe(customsPurposes => {
      if (customsPurposes) {
        this.codesTree = [];
        customsPurposes.forEach(customsPurpose => {
          const entry: TreeNode = {
            label: customsPurpose.customsPurposeCode + ' - ' + customsPurpose.customsPurposeName,
            expandedIcon: 'ui-icon-folder-open',
            collapsedIcon: 'ui-icon-folder'
          };
          entry.children = [];
          customsPurpose.customsProcedures.forEach(customsProcedure => {
            const child = {
                collapsedIcon: 'ui-icon-description',
                label: customsProcedure.customsProcedureCode + ' - ' + customsProcedure.customsProcDesc
              };
            entry.children.push(child);
          });
          this.codesTree.push(entry);
        });
      }
    });
  }

}
