import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ExportFile} from '../../models/exportFile';
import {ExportFileService} from '../../services/export-file.service';
import {ExportConsignee} from '../../../digi-business-entity/models/business-entity';
import {CollectPrepaidService} from '../../services/collect-prepaid.service';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportTransportDocument} from '../../models/file';
import {AirWaybill, WaybillNumber} from '../../models/AirWaybill';
import {AirWaybillService} from '../../services/air-waybill.service';
import {NotificationService} from '../../services/notification.service';

class SundryCharge {
  code: string;
  amount: number;
}

@Component({
  selector: 'digi-air-waybill',
  templateUrl: './air-waybill.component.html',
  styleUrls: ['./air-waybill.component.scss']
})
export class AirWaybillComponent implements OnInit, OnChanges {
  @Input() exportFile: ExportFile;
  @Input() isHouseBill: boolean;
  disableButton: boolean;
  showFilesDialog: boolean;
  displaySundryModal: boolean;
  generatedFiles: any = [];
  filteredCollectPrepaids: any[];
  filteredExportConsignees: ExportConsignee[];
  sundryCharges: SundryCharge[];
  filteredWaybillNumbers: string[];

  constructor(
    private exportFileService: ExportFileService,
    private collectPrepaidService: CollectPrepaidService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private airWaybillService: AirWaybillService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.exportFileBehaviourSubject.getExportConsignees().subscribe(
      (exportConsignees: ExportConsignee[]) => this.filteredExportConsignees = exportConsignees
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exportFile && changes.exportFile.currentValue) {
      this.exportFile = changes.exportFile.currentValue;
      if (this.exportFile.transportDocument) {
        this.createSundryCharges(this.exportFile.transportDocument);
      }

      if (this.exportFile.transportDocument.shipmentType === 'BACK_TO_BACK' && this.exportFile.transportDocument.mawbNo) {
        this.airWaybillService.findByAirlineCode('ZZ').subscribe(
          (airWaybills: AirWaybill[]) => {
            this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
            if (this.filteredWaybillNumbers.length > 0 && !this.exportFile.transportDocument.hawbNo) {
              this.exportFile.transportDocument.hawbNo = this.filteredWaybillNumbers[0];
              this.updateWaybillPool(this.exportFile.transportDocument.hawbNo);
            }
          }
        );
      }
    }
  }

  loadGeneratedFiles() {
    this.disableButton = true;
    this.exportFileService.loadFiles(this.exportFile.id).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showFilesDialog = true;
      this.disableButton = false;
    }, _ => this.disableButton = false);
  }

  searchCollectOrPrepaid(event) {
    this.collectPrepaidService.findCollectPrepaidStartWith(event.query).subscribe(data => {
      this.filteredCollectPrepaids = data;
    });
  }

  selectCollectOrPrepaid(value) {
    this.exportFile.transportDocument.freight.collectPrepaid.code = value.code;
    this.exportFile.transportDocument.freight.collectPrepaid.description = value.description;
  }

  selectConsignee(value) {
    this.exportFile.exportConsignee.code = value.code;
    this.exportFile.exportConsignee.name = value.name;
    this.exportFile.exportConsignee.label = value.label;
    this.exportFile.exportConsignee.address = value.address;
    this.exportFile.exportConsignee.country = value.country;
    this.exportFile.exportConsignee.termsOfPayment = value.termsOfPayment;
    this.exportFile.exportConsignee.hasBankingDetails = value.hasBankingDetails;
    this.exportFile.exportConsignee.commercialBankName = value.commercialBankName;
    this.exportFile.exportConsignee.commercialBankCode = value.commercialBankCode;
    this.exportFile.exportConsignee.commercialBankAccNo = value.commercialBankAccNo;
    this.exportFile.exportConsignee.commercialBankSwiftCode = value.commercialBankSwiftCode;
    this.exportFile.exportConsignee.defaultPurchaseTerm = value.purchaseTerm;
  }

  openSundryChargeModel() {
    this.displaySundryModal = true;
  }

  closeSundryChargesModal() {
    this.displaySundryModal = false;
    this.createSundryCharges(this.exportFile.transportDocument);
  }

  private createSundryCharges(transportDocument: ExportTransportDocument) {
    const sundryCharges = transportDocument.valueBasedCharges.concat(transportDocument.rateBasedCharges);
    this.sundryCharges = sundryCharges.map(charge => {
      return {code: charge.sundryCharge.code, amount: charge.amount};
    });
  }

  selectLocalAgent(value) {
    this.exportFile.localAgent.code = value.code;
    this.exportFile.localAgent.name = value.name;
    this.exportFile.localAgent.label = value.label;
    this.exportFile.localAgent.address = value.address;
    this.exportFile.localAgent.customsRegCode = value.defaultCustomsCode;
  }

  updateWaybillPool(hawbNo: string) {
    this.airWaybillService.findByAirlineCode('ZZ').subscribe(
      (airWaybills: AirWaybill[]) => {
        if (airWaybills && airWaybills.length > 0) {
          const airWaybill = airWaybills[0];
          if (airWaybill.waybillNumbers) {
            airWaybill.waybillNumbers.forEach(el => {
              el.waybillUsages = el.waybillUsages.filter(u => u.fileNumber !== this.exportFile.clearingFileNumber);
            });

            const waybillNumber = airWaybill.waybillNumbers.find(el => el.number === hawbNo);
            const usage = waybillNumber.waybillUsages.find(waybillUsage => waybillUsage.fileNumber === this.exportFile.clearingFileNumber);
            if (!usage) {
              waybillNumber.waybillUsages.push({fileNumber: this.exportFile.clearingFileNumber});
            }
          }

          this.airWaybillService.save(airWaybill).subscribe(
            data => {
              this.notificationService.successNotify('Air Waybill Pool Updated Successfully');
            }
          );
        }
      }
    );
  }

  searchWaybillNumber() {
    if (this.filteredWaybillNumbers && this.filteredWaybillNumbers.length) {
      this.filteredWaybillNumbers = [...this.filteredWaybillNumbers];
    } else {
      this.filteredWaybillNumbers = [];
      if (this.exportFile && this.exportFile.transportDocument && this.exportFile.transportDocument.airline && this.exportFile.transportDocument.airline.code) {
        this.airWaybillService.findByAirlineCode(this.exportFile.transportDocument.airline.code).subscribe(
          (airWaybills: AirWaybill[]) => {
            this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
          }
        );
      }
    }
  }
}
