import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Airline, LocalForwarder, TransportDocument} from '../../models/file';
import {NotificationService} from '../../services/notification.service';
import {TransportService} from '../../../digi-air/services/transport.service';
import {ClearingFileService} from '../../services/clearing-file.service';

@Component({
  selector: 'digi-hawb-airline-section',
  templateUrl: './hawb-airline-section.component.html',
  styleUrls: ['./hawb-airline-section.component.css'],
  animations: [
    trigger('errorState', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible => hidden', animate('400ms ease-in')),
      transition('hidden => visible', animate('400ms ease-out'))
    ])
  ],
})
export class HawbAirlineSectionComponent implements OnInit, OnChanges {
  @Input('transportDocument') transportDocument: TransportDocument;
  @Input() validationMessagesForMawbNo;
  @Input() validationMessagesForHawbNo;
  filteredAirlines: Airline[];
  checkDigit;
  errorMessage: string;
  @Input() fileNumber: string;
  @Input() isAirExports: boolean;
  @Input() isQuotation: boolean;

  constructor(private transportService: TransportService,
              private messageService: NotificationService,
              private clearingFileService: ClearingFileService) {
  }

  ngOnInit() {}

  search(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  straightCheck(value) {
    this.transportDocument.straight = value;
    if (this.transportDocument.straight) {
      this.transportDocument.hawbNo = this.transportDocument.mawbNo;
      this.transportDocument.hawbDate = this.transportDocument.mawbDate;
      this.transportDocument.localForwarder = new LocalForwarder();
    } else {
      this.transportDocument.hawbNo = '';
      this.transportDocument.hawbDate = '';
    }
  }


  changeMAWBNo(value) {
    const checkDigit = this.calculateCheckDigit(this.transportDocument.mawbNo);
    const lastNumber = this.transportDocument.mawbNo.toString().substring(7, 8);
    const convertToNumber = +lastNumber;
    const validMawbNo = this.transportDocument.mawbNo.toString();
    if (this.transportDocument.validateMawbNo) {
      if (validMawbNo.length !== 8) {
        this.errorMessage = 'MAWB No should be at least 8 digits long';
        this.error(this.errorMessage);
      } else if (checkDigit !== convertToNumber) {
        this.errorMessage = 'Invalid Airline Check Digit.';
        this.error(this.errorMessage);
      }
    }

    if (this.transportDocument.straight) {
      this.transportDocument.hawbNo = this.transportDocument.mawbNo;
    }
  }

  defaultDates() {
    if (!this.transportDocument.hawbDate) {
      this.transportDocument.hawbDate = this.transportDocument.mawbDate;
    }
    if (!this.transportDocument.departureDate) {
      this.transportDocument.departureDate = this.transportDocument.mawbDate;
    }
  }

  private error(errorMessage) {
    this.messageService.errorNotify(errorMessage);
  }

  calculateCheckDigit(mawbNo) {
    const firstSeven = mawbNo.toString().substring(0, 7);
    const convertToNumber = +firstSeven;
    const dividedNumber = convertToNumber / 7;
    const roundedValue = Math.floor(dividedNumber);
    const multipliedNumber = roundedValue * 7;
    return convertToNumber - multipliedNumber;
  }

  select(value) {
    this.transportDocument.airline.prefix = value.airlinePrefix;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transportDocument && changes.transportDocument.currentValue && changes.transportDocument.currentValue.validateMawbNo === undefined) {
      this.transportDocument.validateMawbNo = true;
      if (changes.transportDocument.currentValue.validateHawbNo === undefined) {
        this.transportDocument.validateHawbNo = true;
      }
    }

    if (changes.validationMessagesForMawbNo && changes.validationMessagesForMawbNo.currentValue) {
      this.validationMessagesForMawbNo = changes.validationMessagesForMawbNo.currentValue;
    }

    if (changes.validationMessagesForHawbNo && changes.validationMessagesForHawbNo.currentValue) {
      this.validationMessagesForHawbNo = changes.validationMessagesForHawbNo.currentValue;
    }

    if (changes.transportDocument && changes.transportDocument.currentValue) {
      if (changes.transportDocument.currentValue.straight) {
        this.transportDocument.shipmentType = 'STRAIGHT';
      }
    }
  }

  validateMawbNo() {
    this.clearingFileService.findClearingFileNumbersByMawbNo(this.transportDocument.mawbNo, this.fileNumber).subscribe(
      res => {
        if (res.length > 0) {
          const message = 'MAWB No. used in [' + res.join(', ') + ']';
          const foundDetail = this.validationMessagesForMawbNo.find(el => el.detail === message);
          if (!foundDetail) {
            this.validationMessagesForMawbNo.push({severity: 'warn', summary: 'Duplicate MAWB No.', detail: message});
          }
        } else {
          this.validationMessagesForMawbNo.length = 0;
        }
      },
      _ => this.validationMessagesForMawbNo.length = 0
    );
  }

  validateHawbNo() {
    if (!this.transportDocument.straight) {
      this.clearingFileService.findClearingFileNumbersByHawbNo(this.transportDocument.hawbNo, this.fileNumber).subscribe(
        res => {
          if (res.length > 0) {
            const message = ' [' + this.transportDocument.hawbNo + ']' + ' is also used in [' + res.join(', ') + ']';
            const foundDetail = this.validationMessagesForHawbNo.find(el => el.detail === message);
            if (!foundDetail) {
              this.validationMessagesForHawbNo.push({severity: 'warn', summary: 'Duplicate HAWB No.', detail: message});
            }
            this.transportDocument.duplicateHawbNo = true;
          } else {
            this.validationMessagesForHawbNo.length = 0;
            this.transportDocument.duplicateHawbNo = false;
          }
        }, _ => this.validationMessagesForHawbNo.length = 0
      );
    }
  }
}








