import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Observable} from 'rxjs';
import {UniqueConsignmentReference} from '../models/file';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UniqueConsignmentReferenceService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  saveUCR(ucr: UniqueConsignmentReference): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (ucr.id) {
      return this.http.put(this.config.filesApiEndpoint + `api/uniqueConsignmentReferences/${ucr.id}`, JSON.stringify(ucr), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/uniqueConsignmentReferences', JSON.stringify(ucr), {headers});
    }
  }

  findUCRWithNumberAndCodeAndRoleType(number: string, code: string, roleType: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/uniqueConsignmentReferences/search/findByNumberAndCodeAndRole?number=${number}&businessEntityCode=${code}&businessEntityRole=${roleType}`)
      .pipe(
        map(response => response['_embedded'].uniqueConsignmentReferences)
      );
  }


  findPaymentTerm(code) {
    return this.http
      .get(`${this.config.apiEndpoint}api/ucrPayementTerms/search/codeOrDescriptionStartsWith?code=` + code)
      .pipe(
        map(response => response['_embedded'].ucrPayementTerms)
      );
  }
}
