/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./branch-role.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./branch-role.component";
var styles_BranchRoleComponent = [i0.styles];
var RenderType_BranchRoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BranchRoleComponent, data: {} });
export { RenderType_BranchRoleComponent as RenderType_BranchRoleComponent };
export function View_BranchRoleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" branch-role works!\n"]))], null, null); }
export function View_BranchRoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-branch-role", [], null, null, null, View_BranchRoleComponent_0, RenderType_BranchRoleComponent)), i1.ɵdid(1, 114688, null, 0, i2.BranchRoleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BranchRoleComponentNgFactory = i1.ɵccf("app-branch-role", i2.BranchRoleComponent, View_BranchRoleComponent_Host_0, { businessEntity: "businessEntity", branch: "branch" }, {}, []);
export { BranchRoleComponentNgFactory as BranchRoleComponentNgFactory };
