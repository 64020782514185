import {Country, Currency, PurchaseTerm, SupplierInvoice} from '../../models/file';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PurchaseTermService} from '../../services/purchase-term.service';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-invoice-default-values',
  templateUrl: './invoice-default-values.component.html',
  styleUrls: ['./invoice-default-values.component.css']
})
export class InvoiceDefaultValuesComponent implements OnInit, OnDestroy {
  @Input() isQuotation: boolean;
  selectedSupplierInvoice: SupplierInvoice;
  selectedCountry: Country;
  selectedCurrency: Currency;
  unSubscribe: Subscription;
  countrySubscription: Subscription;
  currencySubscription: Subscription;

  constructor(
    private purchaseTermService: PurchaseTermService,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject) {
  }

  ngOnInit() {
    this.unSubscribe = this.supplierInvoiceBehaviourSubject
      .getSupplierInvoice()
      .subscribe(si => {
        if (si) {
          this.selectedSupplierInvoice = si;
          if (!this.selectedSupplierInvoice.defaultValues.purchaseTerm) {
            this.selectedSupplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
          }
        }
      });

    this.countrySubscription = this.supplierInvoiceBehaviourSubject.getCountry()
      .subscribe(country => {
        if (country) {
          this.selectedCountry = Object.assign({}, country);
        }
      });

    this.currencySubscription = this.supplierInvoiceBehaviourSubject.getCurrency()
      .subscribe(currency => {
        if (currency) {
          this.selectedCurrency = Object.assign({}, currency);
        }
      });
  }

  selectCountryOfOrigin(event) {
    this.selectedCountry = Object.assign({}, event);
    this.selectedSupplierInvoice.defaultValues.country = this.selectedCountry;
  }

  selectCurrency(event) {
    this.selectedCurrency = Object.assign({}, event);
    this.selectedSupplierInvoice.currency = this.selectedCurrency;
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.countrySubscription.unsubscribe();
    this.currencySubscription.unsubscribe();
  }
}
