import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-dependent',
  templateUrl: './dependent.component.html',
  styleUrls: ['./dependent.component.scss']
})
export class DependentComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
