import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PortDetail} from '../../models/file';
import {ExitPortService} from '../../services/exit-port.service';
import {InternationalPortService} from '../../services/international-port.service';
import {CountryService} from '../../services/country.service';

@Component({
  selector: 'digi-manifest-port',
  templateUrl: './manifest-port.component.html',
  styleUrls: ['./manifest-port.component.scss']
})
export class ManifestPortComponent implements OnInit, OnChanges {
  @Input() port: PortDetail = new PortDetail();
  @Input() label: string;
  filteredPortDetails: PortDetail[];
  @Input() isExitPort = false;
  @Input() transportMethod: string;
  @Output() portChange = new EventEmitter();
  @Input() isRequired = true;
  constructor(
    private exitPortService: ExitPortService,
    private internationalPortService: InternationalPortService,
    private countryService: CountryService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.port.currentValue) {
      this.port = changes.port.currentValue;
    }
  }

  searchPort(event) {
    if (this.isExitPort) {
      this.exitPortService.findExitPortByNameOrCode(event.query).subscribe(
        ports => this.filteredPortDetails = ports
      );
    } else {
      switch (this.transportMethod) {
        case 'AIR':
          this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsAir(event.query).subscribe(data => {
            this.filteredPortDetails = data;
          });
          break;
        case 'SEA':
          this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsNotAir(event.query).subscribe(data => {
            this.filteredPortDetails = data;
          });
          break;
        case 'ROA':
          this.internationalPortService.findAllInternationalPortsWithCodeOrNameStartsWith(event.query).subscribe(data => {
            this.filteredPortDetails = data;
          });
          break;
      }
    }
  }

  selectPort(value) {
    this.portChange.emit(value);
    this.updatePortCountry(value.countryCode);
  }

  private updatePortCountry(countryCode) {
    this.countryService.findCountryStartsWith(countryCode).subscribe(data => {
      this.port.countryCode = data[0].code;
      this.port.countryName = data[0].name;
    });
  }
}
