import { Injectable } from '@angular/core';
import {PartDetail} from '../models/partDetail';
import {Observable} from 'rxjs';
import {BusinessEntity} from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class CsvUploadService {

  constructor() { }

  createPartDetails(file: any, objectType: string, importExportIndicator?: string, businessEntity?: BusinessEntity): Observable<any> {
    return new Observable<any>(subscriber => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;
        const headerRow = csvData.split(/\r?\n|\r|\n/g)[0];
        const headerCols = headerRow.split('\t');
        const partNumberIndex = headerCols.map(el => el.toLowerCase()).indexOf('part number');
        const tariffCodeIndex = headerCols.map(el => el.toLowerCase()).indexOf('tariff code');
        const descriptionIndex = headerCols.map(el => el.toLowerCase()).indexOf('description');
        const invoiceValueIndex = headerCols.map(el => el.toLowerCase()).indexOf('invoice value');
        const weightIndex = headerCols.map(el => el.toLowerCase()).indexOf('line weight');

        const dataRows = csvData.split(/\r?\n|\r|\n/g);
        dataRows.splice(0, 1);

        const parts = [];
        dataRows.forEach(el => {
          const isEmptyRow = !el || 0 === el.length;
          if (!isEmptyRow) {
            const data = el.split('\t');
            const part = new PartDetail();
            if (businessEntity && businessEntity.code) {
              part.businessEntity = {name: businessEntity.name, code: businessEntity.code};
            }
            part.importExportIndicator = importExportIndicator;
            if (partNumberIndex !== -1) {
              part.partNumber = data[partNumberIndex];
            }
            if (descriptionIndex !== -1) {
              part.description = data[descriptionIndex];
            }
            if (tariffCodeIndex) {
              const formattedTariffWithCheckDigit = data[tariffCodeIndex].replace(/\./g, '');
              part.tariffCode = formattedTariffWithCheckDigit.substring(0, formattedTariffWithCheckDigit.indexOf('/')).trim();
              if (part.tariffCode.length % 2 !== 0) {
                part.tariffCode = '0' + part.tariffCode;
              }
            }
            if (objectType === 'invoiceLine') {
              if (invoiceValueIndex !== -1) {
                part.invoiceValue = data[invoiceValueIndex];
              }
              if (weightIndex !== -1) {
                part.weight = data[weightIndex];
              }
            }
            if (part.partNumber && part.partNumber !== '') {
              parts.push(part);
            }
          }
        });
        subscriber.next(parts);
      };
    });
  }
}
