import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormulaService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findByFormulaNo(number): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/formulae/search/findByFormulaNo?number=` + number);
  }
}
