import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClearingInstruction, Currency, SchedulePart, TariffCode, TariffFormula} from '../../../models/file';
import {SchedulePartService} from '../../../services/schedule-part.service';
import {DutyCalculationService} from '../../../services/duty-calculation.service';
import {AdditionalScheduleTariff, DutyCalculationParams, DutyCalculationResult} from '../../../models/DutyCalculationParams';

@Component({
  selector: 'digi-tariff-calculations',
  templateUrl: './tariff-calculations.component.html',
  styleUrls: ['./tariff-calculations.component.scss']
})
export class TariffCalculationsComponent implements OnInit, OnChanges {

  @Input() tariffCode = new TariffCode();
  currency = new Currency();
  exchangeRateDate: string;
  customsValue: number;
  multiplyValue: number;
  divideValue: number;
  display: boolean;
  cols: any[];
  dutyCols: any[];
  additionalScheduleTariffs: TariffCode[];
  selectedAdditionalScheduleTariffs: TariffCode[] = [];
  searchedAdditionalScheduleTariffs: TariffCode[];
  rebateTariffs: TariffCode[];
  scheduleParts: TariffCode[];
  selectedRebate: TariffCode;
  uomValue: number;
  customsVat: number;
  totalDuty: number;
  totalDue: number;
  clearingInstruction: ClearingInstruction;
  schedules: AdditionalScheduleTariff[];

  constructor(
    private schedulePartService: SchedulePartService,
    private dutyCalculationService: DutyCalculationService
  ) { }

  ngOnInit() {
    this.clearingInstruction = {
      customsPurpose: {
        customsPurposeCode: 'DP '
      }
    };
    this.cols = [
      {field: 'scheduleCode', header: '', width: '5%'},
      {field: 'scheduleCode', header: 'Schedule', width: '5%'},
      {field: 'dependantCode', header: 'Rebate Code', width: '15%'},
      {field: 'dependantCheckDigit', header: 'Check Digit', width: '5%'},
      {field: 'scheduleDescription', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'}
    ];

    this.dutyCols = [
      {field: '', header: '', width: '2%'},
      {field: 'code', header: 'S/P', width: '2%'},
      {field: 'dependantCode', header: 'Dependant Code', width: '8%'},
      {field: 'tariffCheckDigit', header: '', width: '2%'},
      {field: 'tariffSDesc', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'}
    ];

    this.rebateTariffs = [];
    this.searchedAdditionalScheduleTariffs = [];
    this.additionalScheduleTariffs = [];
    this.selectedRebate = new TariffCode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tariffCode && changes.tariffCode.currentValue) {
      this.tariffCode = changes.tariffCode.currentValue;
      console.log(this.tariffCode);
    }

    this.rebateTariffs = [];
    this.searchedAdditionalScheduleTariffs = [];
    this.additionalScheduleTariffs = [];
    this.selectedAdditionalScheduleTariffs = [];
    this.selectedRebate = new TariffCode();

    this.updateRebateTableDisplay();
    this.updateTableDisplay();
  }

  selectCurrency(value) {
    this.currency = value;
  }

  openRebatesModel() {
    this.display = true;
  }

  select() {
    this.selectedAdditionalScheduleTariffs = [...this.selectedAdditionalScheduleTariffs];
  }

  onRowSelectRebate(event) {
    const rebate = this.updateRebate(event.data);
    if (this.selectedAdditionalScheduleTariffs) {
      this.selectedAdditionalScheduleTariffs = this.selectedAdditionalScheduleTariffs
        .filter(tariff => !this.rebateTariffs.includes(tariff)
        );
    }
    this.additionalScheduleTariffs.push(rebate);
    this.additionalScheduleTariffs = [...this.additionalScheduleTariffs];

    this.selectedAdditionalScheduleTariffs.push(rebate);
    this.selectedAdditionalScheduleTariffs = [...this.selectedAdditionalScheduleTariffs];
  }

  private updateRebate(data): TariffCode {
    return this.updateSchedulePartFormula(data);
  }

  private updateSchedulePartFormula(data): TariffCode {
    const rebate: TariffCode = data;
    rebate.formula = new TariffFormula();
    rebate.formula.description =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].description;
    rebate.formula.computations =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].computations;
    rebate.formula.parameters =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].parameters;
    rebate.parameters = [];
    if (data.effectiveTradeAgreementParameters.STANDARD) {
      Object.keys(data.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
        rebate.parameters.push(
          data.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
      });
    } else {
      rebate.parameters = [];
    }
    return rebate;
  }

  private updateTableDisplay() {
    this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate('DP ', this.tariffCode.code, 'C')
      .subscribe(data => {
        console.log(data);
        this.searchedAdditionalScheduleTariffs = data.map((tariffCode) => {
          const additionalSchedule = new TariffCode();
          additionalSchedule.schedulePart = new SchedulePart();
          additionalSchedule.formula = new TariffFormula();
          additionalSchedule.code = tariffCode.code;
          additionalSchedule.checkDigit = tariffCode.tariffCheckDigit;
          additionalSchedule.dependantCode = tariffCode.dependantCode;
          additionalSchedule.schedule = tariffCode.schedule;
          additionalSchedule.fullDescription = tariffCode.fullDescription;
          additionalSchedule.tariff = tariffCode.tariff;
          additionalSchedule.tariffID = tariffCode.tariffID;
          additionalSchedule.dependantCheckDigit = tariffCode.dependantCheckDigit;
          additionalSchedule.schedulePart.code = tariffCode.schedulePart.code;
          additionalSchedule.schedulePart.description = tariffCode.schedulePart.description;
          additionalSchedule.schedulePart.calcOrder = tariffCode.schedulePart.calcOrder;
          additionalSchedule.schedulePart.chargeRebate = tariffCode.schedulePart.chargeRebate;

          if (tariffCode.effectiveTradeAgreementFormulae['STANDARD']) {
            additionalSchedule.formula.description = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].description;
            additionalSchedule.formula.computations = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].computations;
            additionalSchedule.formula.parameters = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].parameters;
          }

          additionalSchedule.parameters = [];
          if (tariffCode.effectiveTradeAgreementParameters['STANDARD']) {
            Object.keys(tariffCode.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
              additionalSchedule.parameters.push(
                tariffCode.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
            });
          } else {
            additionalSchedule.parameters = [];
          }
          return additionalSchedule;
        });

        this.additionalScheduleTariffs = [...this.searchedAdditionalScheduleTariffs];
        console.log(this.additionalScheduleTariffs);
      });
  }

  private updateRebateTableDisplay() {
    this.schedulePartService.findRebateOrScheduleByTariffCodeAndChargeRebate(this.tariffCode.code, 'R')
      .subscribe(data => this.scheduleParts = data);
  }

  calculate() {
    const params: DutyCalculationParams = {
      uomValue: this.uomValue,
      customsValue: this.customsValue,
      additionalScheduleTariffs: this.selectedAdditionalScheduleTariffs,
      currencyCode: this.currency.code,
      tariffCode: this.tariffCode,
      exchangeRateDate: this.exchangeRateDate
    };
    this.dutyCalculationService.calculateDuty(params).subscribe(
      (result: DutyCalculationResult) => {
        this.customsVat = result.customsVat;
        this.totalDuty = result.customsDuty;
        this.totalDue = result.total;
        this.multiplyValue = result.exchangeRateMultiplier;
        this.divideValue = result.exchangeRateDivisor;
        this.schedules = result.additionalScheduleTariffs;
      }
    );
  }
}
