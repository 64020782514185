/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./digi-hawb-shipment-detail-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "@angular/forms";
import * as i4 from "../../../digi-common/components/local-forwarder/local-forwarder.component.ngfactory";
import * as i5 from "../../../digi-common/components/local-forwarder/local-forwarder.component";
import * as i6 from "../../../../subjects/transport-document-behaviour-subject";
import * as i7 from "../../../../subjects/clearingfile-behaviour-subject";
import * as i8 from "../../../digi-common/services/agent.service";
import * as i9 from "../../../digi-common/components/vessels-agent/vessels-agent.component.ngfactory";
import * as i10 from "../../../digi-common/components/vessels-agent/vessels-agent.component";
import * as i11 from "../../../digi-common/services/vessels-agent.service";
import * as i12 from "primeng/components/dom/domhandler";
import * as i13 from "primeng/components/button/button";
import * as i14 from "../../../digi-common/components/sundry-charges/sundry-charges.component.ngfactory";
import * as i15 from "../../../digi-common/components/sundry-charges/sundry-charges.component";
import * as i16 from "../../../digi-common/services/notification.service";
import * as i17 from "./digi-hawb-shipment-detail-section.component";
var styles_DigiHawbShipmentDetailSectionComponent = [i0.styles];
var RenderType_DigiHawbShipmentDetailSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DigiHawbShipmentDetailSectionComponent, data: {} });
export { RenderType_DigiHawbShipmentDetailSectionComponent as RenderType_DigiHawbShipmentDetailSectionComponent };
export function View_DigiHawbShipmentDetailSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 41, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 40, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 39, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 38, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "input", [["class", "ui-inputgroup"], ["pInputText", ""], ["required", ""], ["type", "text"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forwarding File No"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "input", [["class", "ui-inputgroup"], ["pInputText", ""], ["required", ""], ["type", "text"]], [[2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["External Unique ID"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "digi-app-local-forwarder", [], null, [[null, "cargoCarrierCode"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cargoCarrierCode" === en)) {
        var pd_0 = (_co.getCargoCarrierCode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LocalForwarderComponent_0, i4.RenderType_LocalForwarderComponent)), i1.ɵdid(20, 245760, null, 0, i5.LocalForwarderComponent, [i6.TransportDocumentBehaviourSubject, i7.ClearingFileBehaviourSubject, i8.AgentService], null, { cargoCarrierCode: "cargoCarrierCode" }), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "digi-app-vessels-agent", [], null, null, null, i9.View_VesselsAgentComponent_0, i9.RenderType_VesselsAgentComponent)), i1.ɵdid(23, 245760, null, 0, i10.VesselsAgentComponent, [i6.TransportDocumentBehaviourSubject, i7.ClearingFileBehaviourSubject, i11.VesselsAgentService], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 13, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 12, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 11, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 8, "input", [["class", "ui-inputgroup"], ["pInputText", ""], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 28).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 28)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 28)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 35).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.selectedTransportDocument.clientRefNo = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(29, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(32, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(34, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(35, 278528, null, 0, i2.InputText, [i1.ElementRef, [2, i3.NgModel]], null, null), (_l()(), i1.ɵeld(36, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Client Ref No"])), (_l()(), i1.ɵeld(38, 0, null, null, 3, "div", [["class", "ui-g-12 ui-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 2, "button", [["class", "ui-button-secondary"], ["label", "Sundry Changes"], ["pButton", ""], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSundryChargeModel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i12.DomHandler, i12.DomHandler, []), i1.ɵdid(41, 4341760, null, 0, i13.ButtonDirective, [i1.ElementRef, i12.DomHandler], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(42, 0, null, null, 1, "digi-sundry-charges", [], null, [[null, "displayChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayChange" === en)) {
        var pd_0 = (_co.onDialogClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_SundryChargesComponent_0, i14.RenderType_SundryChargesComponent)), i1.ɵdid(43, 114688, null, 0, i15.SundryChargesComponent, [i16.NotificationService], { display: [0, "display"], hasSundryCharge: [1, "hasSundryCharge"] }, { displayChange: "displayChange" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); _ck(_v, 15, 0); _ck(_v, 20, 0); _ck(_v, 23, 0); var currVal_23 = ""; _ck(_v, 29, 0, currVal_23); var currVal_24 = _co.selectedTransportDocument.clientRefNo; _ck(_v, 32, 0, currVal_24); _ck(_v, 35, 0); var currVal_25 = "Sundry Changes"; _ck(_v, 41, 0, currVal_25); var currVal_26 = _co.display; var currVal_27 = _co.selectedTransportDocument; _ck(_v, 43, 0, currVal_26, currVal_27); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 8).filled; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; var currVal_8 = true; var currVal_9 = i1.ɵnov(_v, 15).filled; _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (i1.ɵnov(_v, 29).required ? "" : null); var currVal_11 = i1.ɵnov(_v, 34).ngClassUntouched; var currVal_12 = i1.ɵnov(_v, 34).ngClassTouched; var currVal_13 = i1.ɵnov(_v, 34).ngClassPristine; var currVal_14 = i1.ɵnov(_v, 34).ngClassDirty; var currVal_15 = i1.ɵnov(_v, 34).ngClassValid; var currVal_16 = i1.ɵnov(_v, 34).ngClassInvalid; var currVal_17 = i1.ɵnov(_v, 34).ngClassPending; var currVal_18 = true; var currVal_19 = true; var currVal_20 = true; var currVal_21 = true; var currVal_22 = i1.ɵnov(_v, 35).filled; _ck(_v, 27, 1, [currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22]); }); }
export function View_DigiHawbShipmentDetailSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-digi-hawb-shipment-detail-section", [], null, null, null, View_DigiHawbShipmentDetailSectionComponent_0, RenderType_DigiHawbShipmentDetailSectionComponent)), i1.ɵdid(1, 245760, null, 0, i17.DigiHawbShipmentDetailSectionComponent, [i6.TransportDocumentBehaviourSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DigiHawbShipmentDetailSectionComponentNgFactory = i1.ɵccf("digi-digi-hawb-shipment-detail-section", i17.DigiHawbShipmentDetailSectionComponent, View_DigiHawbShipmentDetailSectionComponent_Host_0, {}, {}, []);
export { DigiHawbShipmentDetailSectionComponentNgFactory as DigiHawbShipmentDetailSectionComponentNgFactory };
