export class Currency {
    code: string;
    name: string;
    currencyEffectiveUntil: string;
    swiftCurrencyCode: string;
}

export class ExchangeRate {
    rate?: number;
    type?: ExchangeRateType;
    currency?: Currency;
    id?: ExchangeRatePK;
  [k: string]: any;
}

export class ExchangeRatePK {
    exchangeRateType?: string;
    currencyCode?: string;
    effectiveDate?: string;

}
export class ExchangeRateType {
    type?: string;
    description?: string;
    effectiveUntil?: string;
    uuid?: number;
    externalHooks?: number;
}
