import { OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BillOfEntryBehaviourSubject } from '../../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../../subjects/clearingfile-behaviour-subject';
import { BillOfEntryService } from '../../../services/bill-of-entry.service';
import { ClearingFileService } from '../../../services/clearing-file.service';
var GenerateComponent = /** @class */ (function () {
    function GenerateComponent(clearingFileBehaviourSubject, billOfEntryBehaviourSubject, clearingFileService, billOfEntryService) {
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.clearingFileService = clearingFileService;
        this.billOfEntryService = billOfEntryService;
    }
    GenerateComponent.prototype.ngOnInit = function () {
        var _this = this;
        var clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
        var billOfEntryObservable = this.billOfEntryBehaviourSubject.getBillOfEntry();
        this.subscription = combineLatest(clearingFileObservable, billOfEntryObservable).subscribe(function (_a) {
            var clearingFile = _a[0], billOfEntry = _a[1];
            _this.clearingFile = clearingFile;
            _this.selectedBillOfEntry = billOfEntry;
        });
    };
    GenerateComponent.prototype.loadUploadedFiles = function (event, tabview) {
        var _this = this;
        if (event.index === 2) {
            if (this.clearingFile && this.selectedBillOfEntry) {
                this.billOfEntryService.loadUploadedFile(this.selectedBillOfEntry)
                    .pipe(catchError(function (err) {
                    console.log(err.status);
                    if (err.status !== 404) {
                        return throwError(err);
                    }
                    return EMPTY;
                }))
                    .subscribe(function (uploadedFiles) {
                    if (uploadedFiles && uploadedFiles['_embedded']) {
                        _this.files = [];
                        (_a = _this.files).push.apply(_a, uploadedFiles._embedded.supportingDocuments);
                        _this.files = _this.files.slice();
                    }
                    var _a;
                });
            }
        }
    };
    GenerateComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return GenerateComponent;
}());
export { GenerateComponent };
