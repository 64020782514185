import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';
import {ShareDataService} from '../app/digi-common/services/share-data.service';
import {ExportFile} from '../app/digi-common/models/exportFile';
import {Consignor, ExportConsignee, Guarantor, OverseasAgent, Remover, Warehouse} from '../app/digi-business-entity/models/business-entity';

@Injectable({
  providedIn: 'root'
})
export class ExportFileBehaviourSubject {
  private exportFileSubject = new BehaviorSubject<ExportFile>(this.shareDataService.buildExportFile());
  private debtorsSubject = new Subject<Debtor[]>();
  private consigneesSubject = new Subject<ExportConsignee[]>();
  private overseasAgentsSubject = new Subject<OverseasAgent[]>();
  private exporterConsignorSubject = new Subject<Consignor[]>();
  private warehousesSubject = new Subject<Warehouse[]>();
  private removersSubject = new Subject<Warehouse[]>();
  private guarantorSubject = new Subject<Guarantor[]>();

  constructor(private shareDataService: ShareDataService) {
  }

  addExportFile(exportFile: ExportFile) {
    this.exportFileSubject.next(exportFile);
  }

  getExportFile(): Observable<ExportFile> {
    return this.exportFileSubject.asObservable();
  }

  addDebtors(debtors: Debtor[]) {
    this.debtorsSubject.next(debtors);
  }

  getDebtors(): Observable<Debtor[]> {
    return this.debtorsSubject.asObservable();
  }

  getExportConsignees(): Observable<ExportConsignee> {
    return this.consigneesSubject.asObservable();
  }

  addExportConsignees(consignees: ExportConsignee[]) {
    this.consigneesSubject.next(consignees);
  }

  addOverseasAgents(overseasAgents: OverseasAgent[]) {
    this.overseasAgentsSubject.next(overseasAgents);
  }

  getOverseasAgents(): Observable<OverseasAgent> {
    return this.overseasAgentsSubject.asObservable();
  }

  addExporterConsignor(exporterConsignors) {
    this.exporterConsignorSubject.next(exporterConsignors);
  }

  getExporterConsignor(): Observable<Consignor[]> {
    return this.exporterConsignorSubject.asObservable();
  }

  addWarehouses(warehouses: Warehouse[]) {
    this.warehousesSubject.next(warehouses);
  }


  getWarehouses(): Observable<Warehouse[]> {
    return this.warehousesSubject.asObservable();
  }

  addRemovers(warehouses: Remover[]) {
    this.removersSubject.next(warehouses);
  }

  getRemovers(): Observable<Remover[]> {
    return this.removersSubject.asObservable();
  }

  addGuarantors(guarantors: Guarantor[]) {
    this.guarantorSubject.next(guarantors);
  }

  getGuarantors(): Observable<Guarantor[]> {
    return this.guarantorSubject.asObservable();
  }
}
