<div class="ui-g">
  <div class="ui-g-6 ui-md-6">
    <p-panel header="Tariff Details">
      <div class="ui-g form-group ui-fluid">
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
            <textarea [rows]="5" pInputTextarea
                      [(ngModel)]="tariffCode.tariffSDesc"
                      [autoResize]="true">
            </textarea>
            <label>Short Description</label>
          </span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span class="ui-float-label">
            <textarea [rows]="5" pInputTextarea
                      [(ngModel)]="tariffCode.fullDescription"
                      [autoResize]="true">
            </textarea>
            <label>Full Description</label>
          </span>
        </div>
        <div class="ui-g-4 ui-md-4">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                <input type="text" pInputText value="{{rateOfDuty}}" readonly>
                <label>Rate of Duty</label>
                </span>
              </div>
            </span>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-6 ui-md-6">
    <p-panel header="Permits">
      <p-table [columns]="cols" [value]="permits" dataKey="permitNumber"
               [paginator]="true" [rows]="5">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width"/>
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{ col.header }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <label>{{rowData.businessEntityReference.roleType === 6 ? 'I' : 'E'}}</label>
            </td>
            <td>
              <label>{{rowData.permitNo}}</label>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>

  </div>
</div>
