import { Component, OnInit } from '@angular/core';
import {ClearingFile} from '../../../digi-common/models/clearingFile';

@Component({
  selector: 'digi-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  clearingFile: ClearingFile;
  clearingInstructionsObject = [];
  clearingInstructionsInfo = [];
  producedDocuments = [];
  requiredDocuments = [];
  selectedProducedDocuments = [];
  selectedRequiredDocuments = [];

  constructor() { }

  ngOnInit() {
  }

}
