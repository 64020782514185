<p-toast [baseZIndex]="99999" [style]="{marginTop: '80px'}" position="top-right" key="error" >
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
      <h3>{{message.summary}}</h3>
    </div>
    <div *ngIf="message.detail" class="ui-g ui-fluid">
      <div *ngFor="let error of message.detail"  class="ui-g-12">
        {{error}}
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast [baseZIndex]="99999" [style]="{marginTop: '80px'}" position="top-right" key="success"></p-toast>
