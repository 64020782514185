import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {KeycloakService} from 'keycloak-angular';
import {NotificationService} from './digi-common/services/notification.service';
import {Person} from './digi-common/models/person';
import {AuthorisationService} from '../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  loggedInUser: Person;

  constructor(public app: AppComponent,
              private keycloakService: KeycloakService,
              private messageService: NotificationService,
              private authorisationService: AuthorisationService) {
  }

  ngOnInit() {
    this.authorisationService
      .getLoggedInUser()
      .subscribe(p => {
        console.log(p);
        if (p) {
          this.loggedInUser = p;
        }
      });
  }

  logout() {
    this.keycloakService.logout().then(() => {
        this.messageService.successNotify('Successfully Logged Out');
      }
    ).catch(function (fallback) {
      this.messageService.errorNotify('Error Logging Out!!!');
    });
  }
}
