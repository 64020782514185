import {Component, OnDestroy, OnInit} from '@angular/core';
import {ValidationResult, ValidationService} from '../../services/validation.service';
import {ClearingFile} from '../../models/clearingFile';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {Subscription} from 'rxjs';
import {CostingFile, CostingItem, CostingLine, FileCost, SupplierInvoiceCost} from '../../models/costingFile';
import {CostingFileService} from '../../services/costing-file.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntry, BillOfEntryLine} from '../../models/billOfEntries';
import {Uom} from '../../models/TariffCode';
import {UomService} from '../../services/uom.service';
import {ChargeType, Invoice} from '../../models/financials';
import {ChargeTypeService} from '../../services/charge-type.service';
import {NotificationService} from '../../services/notification.service';
import {InvoiceService} from '../../services/invoice.service';

@Component({
  selector: 'digi-costing',
  templateUrl: './costing.component.html',
  styleUrls: ['./costing.component.scss']
})
export class CostingComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  clearingFileSubscription: Subscription;
  boeSubscription: Subscription;
  cols: any[];
  costingFile = new CostingFile();
  costingFiles: CostingFile[];
  disableButton: boolean;

  availableLines: CostingLine[];
  allocatedLines: CostingLine[] = [];
  private boe: BillOfEntry;
  costUnitCols: any[];
  costingLine = new CostingLine();
  filteredUoms: Uom[];
  costingUom: Uom;
  exchangeRatesCols: any[];
  fileCostsCols: any[];
  selectedCost = new FileCost();
  filteredChargeTypes: ChargeType[];
  private chargeTypeSubscription: Subscription;
  filteredCostingItems: CostingItem[];
  display: boolean;
  generatedFiles: any;
  invoice: Invoice;
  private invoicesSubscription: Subscription;
  selectedSupplierInvoiceCost = new SupplierInvoiceCost();
  supplierInvoiceCostsCols: any[];
  exchangeRates: any[];

  constructor(
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private costingFileService: CostingFileService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private uomService: UomService,
    private chargeTypeService: ChargeTypeService,
    private notificationService: NotificationService,
    private invoiceService: InvoiceService,
    private validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'fileNumber', header: 'Costing File Number'},
      {field: 'description', header: 'Description'}
    ];

    this.costUnitCols = [
      {field: 'invoice', header: 'Invoice', width: '15%'},
      {field: 'invoiceLineNo', header: 'Inv. Line', width: '5%'},
      {field: 'costLine', header: 'Cost Line', width: '5%'},
      {field: 'partNumber', header: 'Part Number', width: '10%'},
      {field: 'partDesc', header: 'Part Description', width: '15%'},
      {field: 'clientRefNo', header: 'Client Ref. No.', width: '15%'},
      {field: 'additionalClientRef', header: 'Additional Client Ref.', width: '15%'},
      {field: 'costingUom', header: 'Costing UOM', width: '5%'},
      {field: 'numberOfCostingUnits', header: 'Number of Costing Units', width: '5%'},
      {field: 'markup', header: 'Inv. Val Mark Up %', width: '5%'},
      {field: 'landedCostMarkup', header: 'Lnd. Cost Mark Up %', width: '5%'},
    ];

    this.exchangeRatesCols = [
      {header: 'Inv. Line'},
      {header: 'Cost Line'},
      {header: 'Part Number'},
      {header: 'Client Ref. No.'},
      {header: 'Foreign Amount'},
      {header: 'Revised Value'},
      {header: 'Exchange Rate'},
      {header: 'Local Amount'},
      {header: 'Currency'}
    ];

    this.fileCostsCols = [
      {header: 'Charge Type', width: '30%'},
      {header: 'Amount', width: '30%'},
      {header: 'Apportion By', width: '30%'},
      {header: '', width: '10%'}
    ];

    this.supplierInvoiceCostsCols = [
      {header: 'Invoice'},
      {header: 'Charge Type'},
      {header: 'Amount'},
      {header: 'Apportion By'},
      {header: '', width: '10%'}
      ];

    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(
      data => {
        this.clearingFile = data;
        this.fetchCostingFiles();
        this.invoicesSubscription = this.invoiceService.getInvoices(this.clearingFile).subscribe(
          invoices => {
            if (invoices && invoices.length) {
              this.invoice = invoices[0];
            }
          }
        );
      }
    );

    this.boeSubscription = this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(
      boe => {
        this.boe = boe;
        this.availableLines = this.createAvailableCostLines();
        this.costingFile.boeInvoiceDetails = this.boe.boeInvoiceDetails;
        this.costingFile.mrn = this.boe.mrn;
        this.costingFile.boeVat = this.boe.totalCustomsVAT;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.clearingFileSubscription) {
      this.clearingFileSubscription.unsubscribe();
    }
  }

  saveCostingFile() {
    this.disableButton = true;
    this.costingFileService.saveCostingFile(this.costingFile, this.clearingFile).subscribe(
      costingFile => {
        this.selectCostingFile(costingFile);
        this.disableButton = false;
        this.notificationService.successNotify('Costing File Saved Successfully');
      },
      _ => this.disableButton = false
    );
  }

  createNewCostingFile() {
    this.costingFile = new CostingFile();
    this.costingFile.boeInvoiceDetails = this.boe.boeInvoiceDetails;
    this.costingFile.mrn = this.boe.mrn;
    this.costingFile.boeVat = this.boe.totalCustomsVAT;
    this.costingFile.costs = [...this.getFileCosts()];
    this.selectedCost = this.costingFile.costs[0];
    if (this.costingFiles) {
      this.costingFiles.push(this.costingFile);
    } else {
      this.costingFiles = [this.costingFile];
    }
    this.selectCostingFile(this.costingFile);
  }

  private fetchCostingFiles() {
    this.costingFileService.getCostingFiles(this.clearingFile.id).subscribe(
      costingFiles => {
        this.costingFiles = costingFiles;
        if (this.costingFiles && this.costingFiles.length) {
          this.selectCostingFile(this.costingFiles[0]);
        }
      }
    );
  }

  selectLine(event: any) {
    if (this.costingFile.costingLines) {
      this.costingFile.costingLines = this.costingFile.costingLines.concat(event.items);
    } else {
      this.costingFile.costingLines = [...event.items];
    }

    if (this.costingFile.costingLines && this.costingFile.costingLines.length > 0) {
      this.costingLine = this.costingFile.costingLines[0];
    }
  }

  createCostingLines(lines: BillOfEntryLine[]): CostingLine[] {
    const costingLines = [];
    lines.forEach(line => {
      const costingLine = new CostingLine();
      costingLine.invoiceNumber = line.invoiceNumber;
      costingLine.invoiceMarkupPercentage = this.costingFile.invoiceMarkupPercentage;
      costingLine.landedCostMarkupPercentage = this.costingFile.landedCostMarkupPercentage;
      costingLine.lineNo = line.lineNo;
      costingLine.customsDuty = line.customsDuty;
      costingLine.customsVAT = line.customsVAT;
      costingLine.invoiceGrossValue = line.invoiceGrossValue;
      costingLine.localInvoiceGrossValue = line.localInvoiceGrossValue;
      costingLine.exchangeRate = this.getExchangeRate(line);
      costingLine.revisedValue = this.getRevisedValue(line);
      costingLine.costingUom = line.costingUom;
      costingLine.costingValue = line.costingValue;
      costingLine.supplierCode = this.getSupplierCode(costingLine.invoiceNumber);
      costingLine.tariffCode = line.tariffCode.code;
      costingLine.tariffDescription = line.description;
      costingLine.partNumber = line.partNumber;
      costingLine.quantity = line.lineQuantity;
      costingLine.sch12b = this.getSch12bFromBoeLine(line);
      costingLine.tradeAgreement = line.tradeAgreement;
      costingLines.push(costingLine);
    });

    return costingLines;
  }

  onMoveToSource(event) {
    this.costingFile.costingLines = this.costingFile.costingLines.filter(line => {
      const found = event.items.find(item => item.lineNo === line.lineNo);
      return !found;
    });
    this.availableLines = this.createAvailableCostLines();
  }

  getSupplierCode(invoiceNumber: string) {
    const supplier = this.clearingFile.transportDocument.supplierInvoices.find(si => si.invoiceNumber === invoiceNumber).supplier;
    if (supplier) {
      return supplier.code.trim();
    }
    return '';
  }

  selectCostingFile(costingFile: CostingFile) {
    this.costingFile = costingFile;
    if (this.costingFile.costingLines && this.costingFile.costingLines.length > 0) {
      this.allocatedLines = [...this.costingFile.costingLines];
      this.costingLine = this.costingFile.costingLines[0];
    } else {
      this.allocatedLines = [...[]];
    }

    if (this.boe) {
      this.costingFile.boeInvoiceDetails = this.boe.boeInvoiceDetails;
    }

    this.availableLines = this.createAvailableCostLines();

    if (this.costingFile.costs && this.costingFile.costs.length > 0) {
      this.selectedCost = this.costingFile.costs[0];
    }

    this.createExchangeRatesList();
  }

  private createAvailableCostLines() {
    const costingLines = this.createCostingLines(this.boe.lines);
    return costingLines.filter(line => {
      const allocatedLine = this.allocatedLines.find(allocatedBoeLine => allocatedBoeLine.lineNo === line.lineNo);
      return !allocatedLine;
    });
  }

  selectUom(value: Uom) {
    this.costingLine.costingUom = value;
  }

  searchUoms(event: any) {
    this.uomService.findByCodeStartsWith(event.query).subscribe(
      data => this.filteredUoms = data
    );
  }

  getCountry(costingLine: CostingLine) {
    const country = this.clearingFile.transportDocument.supplierInvoices
      .find(si => si.invoiceNumber === costingLine.invoiceNumber).defaultValues.country;
    if (country) {
      return country.name.trim();
    }
    return '';
  }

  getSupplierName(costingLine: CostingLine) {
    const supplier = this.clearingFile.transportDocument.supplierInvoices.find(si => si.invoiceNumber === costingLine.invoiceNumber).supplier;
    if (supplier) {
      return supplier.name.trim();
    }
    return '';
  }

  getExchangeRate(line: BillOfEntryLine) {
    const exchangeRate = this.boe.boeInvoiceDetails.find(el => el.bankInvoiceNumber === line.invoiceNumber).exchangeRate;
    return Math.round(exchangeRate * 100000000) / 100000000;
  }

  getRevisedValue(line: BillOfEntryLine) {
    return this.boe.lines.find(el => el.invoiceNumber === line.invoiceNumber && line.lineNo === el.lineNo).localInvoiceGrossValue;
  }

  getCurrency(line: BillOfEntryLine) {
    return this.boe.boeInvoiceDetails.find(el => el.bankInvoiceNumber === line.invoiceNumber).currency;
  }

  searchChargeTypes(event) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(event.query).subscribe(chargeTypes => {
      this.filteredChargeTypes = chargeTypes;
    });
  }

  searchCostingItems(event: any) {
    this.costingFileService.findCostingItemByCode(event.query).subscribe(
      data => this.filteredCostingItems = data
    );
  }

  selectCostingItem(value: any) {
    this.selectedCost.costingItem = new CostingItem();
    this.selectedCost.costingItem.code = value.costingItemCode;
    this.selectedCost.costingItem.description = value.costingItemDesc;
    this.selectedCost.costingItem.label = value.label;

  }

  createNewCost() {
    this.selectedCost = new FileCost();
    if (this.costingFile.costs) {
      this.costingFile.costs.push(this.selectedCost);
    } else {
      this.costingFile.costs = [this.selectedCost];
    }
  }

  handleIndexChange(index: number) {
    if (index === 4) {
      if (!this.costingFile.costs) {
        this.costingFile.costs = [...this.getFileCosts()];
        this.selectedCost = this.costingFile.costs[0];
      }

      if (!this.costingFile.supplierInvoiceCosts || (this.costingFile.supplierInvoiceCosts && this.costingFile.supplierInvoiceCosts.length === 0)) {
        this.costingFile.supplierInvoiceCosts = [...this.getSupplierInvoiceCosts()];
      }
    }
    this.saveCostingFile();
  }

  generate() {
    this.disableButton = true;
    const result: ValidationResult = this.validationService.validateCosting(this.allocatedLines);
        if (result.isValid) {
          this.costingFileService.loadFiles(this.costingFile.id).subscribe(data => {
            this.generatedFiles = data._embedded.supportingDocuments;
            this.display = true;
            this.disableButton = false;
          }, _ => this.disableButton = false);
        } else {
          this.notificationService.errorNotify('Validation Failed', result.messages);
          this.disableButton = false
        }
  }

  private getFileCosts(): FileCost[] {
    if (this.invoice) {
      const costs = [];
      this.invoice.lineDetails.forEach(line => {
        const cost = new FileCost();
        cost.chargeType = Object.assign({}, line.chargeType);
        cost.amount = line.localAmount;
        costs.push(cost);
      });
      return costs;
    } else {
      return [this.selectedCost];
    }
  }

  private getSupplierInvoiceCosts() {
    if (this.boe && this.boe.boeInvoiceDetails) {
      const costs = [];
      const standardChargeType: ChargeType = {
        description: 'Standard Sundry Charge'
      };
      this.boe.boeInvoiceDetails.forEach(detail => {
        detail.sundryCharges.forEach((charge, index) => {
          if (charge.type === 'I' && charge.chargeDescription !== 'freightAmount') {
            const cost = new SupplierInvoiceCost();
            cost.lineNo = index + 1;
            cost.invoiceNo = detail.bankInvoiceNumber;
            cost.chargeType = standardChargeType;
            cost.amount = charge.localAmount;
            cost.costingItem = {
              code : 'SHPVAL',
              description : 'Shipment Rand Value',
              label: 'SHPVAL : Shipment Rand Value'
            };
            costs.push(cost);
          }
        });
      });
      return costs;
    }
  }

  private getSch12bFromBoeLine(line: BillOfEntryLine): number {
    const schedulePart = line.additionalScheduleTariffs.find(el => el.schedule === '12B');
    return schedulePart ? schedulePart.calculatedValue : 0;
  }

  onRowDelete(index) {
    this.costingFile.costs.splice(index, 1);
    this.costingFile.costs = [...this.costingFile.costs];
  }

  onSupplierInvoiceCostRowDelete(index) {
    this.costingFile.supplierInvoiceCosts.splice(index, 1);
    this.costingFile.supplierInvoiceCosts = [...this.costingFile.supplierInvoiceCosts];
  }

  calculateRevisedValue(invoiceNumber: string, exchangeRate: number) {
    this.costingFile.costingLines.filter(line => line.invoiceNumber === invoiceNumber).forEach(costingLine => {
      costingLine.exchangeRate = exchangeRate;
      costingLine.revisedValue = Math.round(costingLine.invoiceGrossValue * costingLine.exchangeRate * 100) / 100;
    });
  }

  selectCostingLine(data: any) {
    this.costingLine = data;
  }

  private createExchangeRatesList() {
    this.exchangeRates = [];
    this.costingFile.costingLines.forEach(line => {
      const found = this.exchangeRates.find(el => el.invoiceNumber === line.invoiceNumber);
      if (!found) {
        this.exchangeRates.push({invoiceNumber: line.invoiceNumber, exchangeRate: line.exchangeRate});
      }
    });
    this.exchangeRates = [...this.exchangeRates];
  }
}
