import {Component, OnDestroy, OnInit} from '@angular/core';
import {BillOfEntry} from '../../models/billOfEntries';
import {Subscription} from 'rxjs';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';

@Component({
  selector: 'app-boe-total-schedule-amount',
  templateUrl: './boe-total-schedule-amount.component.html',
  styleUrls: ['./boe-total-schedule-amount.component.scss']
})
export class BoeTotalScheduleAmountComponent implements OnInit, OnDestroy {
  selectedBillOfEntry: BillOfEntry;
  billSubscription: Subscription;
  cols: any;

  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject) {
  }

  ngOnInit() {
    this.billSubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        this.selectedBillOfEntry = boe;
      });
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }
}
