/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cpc-enquiry.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i3 from "primeng/components/panel/panel";
import * as i4 from "../../../../../node_modules/primeng/components/tree/tree.ngfactory";
import * as i5 from "primeng/components/tree/tree";
import * as i6 from "primeng/components/common/treedragdropservice";
import * as i7 from "./cpc-enquiry.component";
import * as i8 from "../../services/clearing-instructions.service";
var styles_CpcEnquiryComponent = [i0.styles];
var RenderType_CpcEnquiryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CpcEnquiryComponent, data: {} });
export { RenderType_CpcEnquiryComponent as RenderType_CpcEnquiryComponent };
export function View_CpcEnquiryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "p-panel", [["header", "CPC"]], null, null, null, i2.View_Panel_0, i2.RenderType_Panel)), i1.ɵdid(2, 49152, null, 1, i3.Panel, [i1.ElementRef], { header: [0, "header"] }, null), i1.ɵqud(335544320, 1, { footerFacet: 0 }), (_l()(), i1.ɵeld(4, 0, null, 1, 4, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p-tree", [], null, null, null, i4.View_Tree_0, i4.RenderType_Tree)), i1.ɵdid(6, 1294336, null, 1, i5.Tree, [i1.ElementRef, [2, i6.TreeDragDropService]], { value: [0, "value"], style: [1, "style"] }, null), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵpod(8, { width: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "CPC"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.codesTree; var currVal_2 = _ck(_v, 8, 0, "100%"); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_CpcEnquiryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-cpc-enquiry", [], null, null, null, View_CpcEnquiryComponent_0, RenderType_CpcEnquiryComponent)), i1.ɵdid(1, 114688, null, 0, i7.CpcEnquiryComponent, [i8.ClearingInstructionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CpcEnquiryComponentNgFactory = i1.ɵccf("digi-cpc-enquiry", i7.CpcEnquiryComponent, View_CpcEnquiryComponent_Host_0, {}, {}, []);
export { CpcEnquiryComponentNgFactory as CpcEnquiryComponentNgFactory };
