<p-panel>
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                           pInputText readonly>
                    <label>Consignor Code</label>
                </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.name}}"
                           pInputText readonly>
                    <label>Consignor Name</label>
                </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" [(ngModel)]="consignor.customsCode"
                           pInputText>
                    <label>Customs Code</label>
                </span>
      </div>
    </div>
  </div>
</p-panel>
