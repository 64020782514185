import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ClearingFile } from '../../models/clearingFile';
import { SupplierInvoice, TransportDocument } from '../../models/file';
import { SupplierInvoiceBehaviourSubject } from 'src/subjects/supplierInvoice-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { ShareDataService } from '../../services/share-data.service';
import { Subscription } from 'rxjs';
import {UniqueConsignmentBehaviourSubject} from '../../../../subjects/unique-consignment-reference-subject';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-supplier-invoice-table',
  templateUrl: './supplier-invoice-table.component.html',
  styleUrls: ['./supplier-invoice-table.component.css']
})
export class SupplierInvoiceTableComponent implements OnInit, OnDestroy {
  selectedInvoice: SupplierInvoice;
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  cols: any[];
  subscription: Subscription;
  tdSubscription: Subscription;
  siSubscription: Subscription;
  @Input() fileType: FileType = 'imports';

  constructor(private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private shareDataService: ShareDataService,
              private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'invoiceNumber', header: 'Invoice No', width: '11%'},
      {field: 'name', header: this.fileType === 'imports' ? 'Supplier' : 'Consignee'},
      {field: 'country', header: 'Country of Origin'}
    ];
    this.subscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
      this.clearingFile = cf;
      if (this.clearingFile) {

        if (this.tdSubscription) {
          this.tdSubscription.unsubscribe();
        }
        this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
          .subscribe(td => {
            if (td) {
              this.selectedTransportDocument = td;
              if (this.selectedTransportDocument) {

                if (this.siSubscription) {
                  this.siSubscription.unsubscribe();
                }
                this.siSubscription = this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
                  .subscribe(si => {
                    if (si) {
                      this.selectedInvoice = si;
                    } else {
                      this.selectedInvoice = this.selectedTransportDocument.supplierInvoices[0];
                    }
                  });
              }
            }
          });
      }
    });
  }

  ngOnDestroy() {
    if(this.tdSubscription) {
      this.tdSubscription.unsubscribe();
    }
    if(this.siSubscription) {
      this.siSubscription.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  onRowSelect(value: any): void {
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(value.data);
    this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(value.data.ucr);
  }

  onRowDelete(index) {
    this.defaultToFirstSupplierInvoice(index);
    this.selectedTransportDocument.supplierInvoices.splice(index, 1);
    this.selectedTransportDocument.supplierInvoices = [...this.selectedTransportDocument.supplierInvoices];
    this.createDefaultSupplierInvoiceLine();
  }

  private createDefaultSupplierInvoiceLine() {
    if (this.selectedTransportDocument.supplierInvoices.length === 0) {
      this.updateTable(this.shareDataService.addSupplierInvoice(this.selectedTransportDocument));
    }
  }

  private defaultToFirstSupplierInvoice(index) {
    if (this.selectedTransportDocument.supplierInvoices[index].sequence === this.selectedInvoice.sequence) {
      if (index === 0) {
        this.supplierInvoiceBehaviourSubject.addSupplierInvoice(this.selectedTransportDocument.supplierInvoices[index + 1]);
      } else {
        this.supplierInvoiceBehaviourSubject.addSupplierInvoice(this.selectedTransportDocument.supplierInvoices[0]);
      }
      this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(this.selectedInvoice.ucr);
    }
  }

  private updateTable(supplierInvoice) {
    this.selectedTransportDocument.supplierInvoices.push(supplierInvoice);
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
    this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(supplierInvoice.ucr);
  }

}
