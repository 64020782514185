import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile,} from '../../../digi-common/models/clearingFile';
import {CustomsRadioCallSign, Remover, TransportDocument} from '../../../digi-common/models/file';
import { CustomsRadioCallSignService } from '../../services/customs-radio-call-sign.service';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import {RemoverService} from '../../services/remover.service';

@Component({
  selector: 'digi-voyage-details',
  templateUrl: './voyage-details.component.html',
  styleUrls: ['./voyage-details.component.scss']
})
export class VoyageDetailsComponent implements OnInit, OnDestroy {
  @Input() selectedTransportDocument: TransportDocument;
  transportDocumentSubscription: Subscription;
  clearingFile: ClearingFile;
  clearingFileSubscription: Subscription;
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[] = [];
  filteredRemovers: Remover[];

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private customsRadioCallSignService: CustomsRadioCallSignService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private removerService: RemoverService) {
  }

  ngOnInit() {
    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe(cf => this.clearingFile = cf);

    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
          if (td) {
            this.selectedTransportDocument = td;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.clearingFileSubscription.unsubscribe();
    this.transportDocumentSubscription.unsubscribe();
  }

  search(event, field) {
    this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(data => {
      this.filteredCustomsRadioCallSigns = data;
    });
  }

  isSeaTransportDocument() {
    return this.clearingFile.clearingInstructions[0].transportMethod.method === 'SEA';
  }

  searchRemover(event) {
    this.removerService.findRemoverNameOrCodeStartsWith(event.query).subscribe(
      result => {
        this.filteredRemovers = result;
      }
    );
  }

  defaultDestinationPort(value) {
    this.selectedTransportDocument.destinationPort = Object.assign({}, value);
  }
}
