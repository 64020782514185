import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var TransporterService = /** @class */ (function () {
    function TransporterService(http, config) {
        this.http = http;
        this.config = config;
    }
    TransporterService.prototype.findTransporterWithCodeOrName = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/transporters/search/codeOrNameStartsWith?transporterCode=" + code)
            .pipe(map(function (response) { return response['_embedded'].transporters; }));
    };
    TransporterService.ngInjectableDef = i0.defineInjectable({ factory: function TransporterService_Factory() { return new TransporterService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: TransporterService, providedIn: "root" });
    return TransporterService;
}());
export { TransporterService };
