import {Inject, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) { }

  findBankStartingWith(query: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/banks/search/nameStartsWith?name=${query}`)
      .pipe(
        map(response => response['_embedded'].banks)
      );
  }
}
