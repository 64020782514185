import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Country, ExportConsignee} from '../../models/business-entity';

@Component({
  selector: 'digi-sea-export-consignee-role',
  templateUrl: './export-consignee-role.component.html',
  styleUrls: ['./export-consignee-role.component.scss']
})
export class ExportConsigneeRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() consignee: ExportConsignee;
  constructor() { }

  ngOnInit() {
  }

  selectCountry(value: any) {
    this.consignee.country = value;
  }

}
