import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var BillOfEntryLineBehaviourSubject = /** @class */ (function () {
    function BillOfEntryLineBehaviourSubject() {
        this.billOfEntryLineSubject = new BehaviorSubject(null);
    }
    BillOfEntryLineBehaviourSubject.prototype.addBillOfEntryLine = function (billOfEntryLine) {
        this.billOfEntryLineSubject.next(billOfEntryLine);
    };
    BillOfEntryLineBehaviourSubject.prototype.getBOELine = function () {
        return this.billOfEntryLineSubject.asObservable();
    };
    BillOfEntryLineBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function BillOfEntryLineBehaviourSubject_Factory() { return new BillOfEntryLineBehaviourSubject(); }, token: BillOfEntryLineBehaviourSubject, providedIn: "root" });
    return BillOfEntryLineBehaviourSubject;
}());
export { BillOfEntryLineBehaviourSubject };
