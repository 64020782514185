import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Currency } from '../../models/file';
import { ExchangeRate, ExchangeRatePK, ExchangeRateType } from '../../models/currency';
import { CurrencyService } from '../../services/currency.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'digi-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss'],
})
export class ExchangeRatesComponent implements OnInit {
  exchangeRateTypes = [];
  exchangeRates = [];
  calculatedExchangeRates = [];
  viewBy = 'effective_date';
  views = [
    { label: 'Currency', value: 'currency', icon: '' },
    { label: 'Effective Date', value: 'effective_date', icon: '' }
  ];
  selectedExchangeRateType: ExchangeRateType;
  cols: any;
  effectiveDate: string;
  filteredCurrencies = [];
  currency: Currency;
  selectedExchangeRate: ExchangeRate = new ExchangeRate;
  multiplierRate: number;
  errorMessage: string;
  filteredExhangeRateTypes = [];
  selectExchangeRateType: ExchangeRateType;
  filteredCopyExhangeRateTypes = [];
  fromDate: string;
  toDate: string;


  constructor(private currencyService: CurrencyService, @Inject(LOCALE_ID) private locale: string,
    private messageService: NotificationService) { }
  ngOnInit() {
    this.cols = [{ field: 'code', header: 'Code', width: '11%' },
    { field: 'rate', header: '/Rate', width: '11%' },
    { field: 'rate', header: '*Rate', width: '11%' },
    { field: 'effectiveDate', header: 'EffectiveDate', width: '11%' }];

    this.currencyService.getExchangeRateTypes()
      .subscribe(data => {
        this.exchangeRateTypes = data._embedded.exchangeRatesTypes;
      });
  }

  searchExchangeRateType(event) {
    this.filteredExhangeRateTypes = [];
    for (let i = 0; i < this.exchangeRateTypes.length; i++) {
      let exchange = this.exchangeRateTypes[i];
      if (exchange.type.toLowerCase().indexOf(event.query.toLowerCase()) == 0 || exchange.description.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredExhangeRateTypes.push(exchange);
      }
    }
  }

  searchExchangeRateTypeForRange(event) {
    this.filteredCopyExhangeRateTypes = [];
    for (let i = 0; i < this.exchangeRateTypes.length; i++) {
      let exchange = this.exchangeRateTypes[i];
      if (exchange.type.toLowerCase().indexOf(event.query.toLowerCase()) == 0 || exchange.description.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredCopyExhangeRateTypes.push(exchange);
      }
    }
  }

  selectDate(event) {
    console.log(event);
    this.calculatedExchangeRates = [];
    if (this.effectiveDate) {
      this.getExchangeRates(this.selectedExchangeRateType.type, this.effectiveDate);
    }
    console.log(this.effectiveDate);

  }

  searchCurrency(event) {
    this.currencyService.findCurrencyStartWith(event.query).subscribe(data => {
      this.filteredCurrencies = data;
    });
  }

  getExchangeRates(type, date) {
    this.currencyService.getTypeAndEffective(type, date).subscribe(data => {
      data._embedded.exchangeRates.forEach((el, index) => el.lineNo = index);
      this.exchangeRates = data._embedded.exchangeRates;
      this.selectRate(this.exchangeRates[0]);
      console.log(this.exchangeRates);
      this.calculatedExchangeRates = [];
      this.calculateRates(this.exchangeRates);
      this.calculatedExchangeRates = [...this.calculatedExchangeRates];
    });
  }

  calculateRates(exchangeRates) {
    exchangeRates.forEach(element => { this.calculation(element); });
  }

  calculation(element) {
    const divideRate = 1 / element.rate;
    const multipleRate = 1 / divideRate;
    const obj = {
      'divide': divideRate,
      'multiply': multipleRate,
      'code': element.id.currencyCode,
      'effectiveDate': element.id.effectiveDate
    };
    this.calculatedExchangeRates.push(obj);
  }

  newRate(event) {
    this.selectedExchangeRate = new ExchangeRate;
  }

  saveRate(event) {
    const id = new ExchangeRatePK;
    id.currencyCode = this.selectedExchangeRate.currency.code;
    id.effectiveDate = moment(this.effectiveDate, 'DD/MM/YYYY').format('DD/MM/YYYY HH:mm:ss');
    id.exchangeRateType = this.selectedExchangeRateType.type;
    const toSave = Object.assign({}, { id, rate: this.selectedExchangeRate.rate, _links: this.selectedExchangeRate._links });
    this.currencyService.saveExchangeRate(toSave).subscribe(
      data => this.successConfirmation('Exchange Rate Saved'),
      (error) => {
      }
    );
  }

  updateMultiplierRate() {
    this.multiplierRate = 1 / this.selectedExchangeRate.rate;
  }

  updateRate() {
    this.selectedExchangeRate.rate = 1 / this.multiplierRate;
  }

  selectFromDate(event) {
    console.log(event);
  }

  selectToDate(event) {
    console.log(event);
  }

  copyRange(selectExchangeRateType: ExchangeRateType, fromDate: string, toDate: string) {
    fromDate = fromDate.replace(/\//g, "-");
    toDate = toDate.replace(/\//g, "-");
    this.currencyService.saveExchangeRateRange(selectExchangeRateType.type, fromDate, toDate).subscribe(
      data => this.successConfirmation(data),
      error => {
      }
    );
  }
  private successConfirmation(data) {
    this.messageService.successNotify(data);

  }

  selectRate(event: any) {
    this.selectedExchangeRate = this.exchangeRates.find(el => el.id && el.id.currencyCode === event.id.currencyCode);
    if (this.selectedExchangeRate) {
      this.updateMultiplierRate();
      this.currencyService.findCurrencyStartWith(event.id.currencyCode).subscribe(data => {
        this.selectedExchangeRate.currency = Object.assign({}, data[0]);
      });
    }
  }

  getMultiplier(exchangeRate: any) {
    return 1 / exchangeRate.rate;
  }
}
