import {Component, EventEmitter, Input, KeyValueDiffers, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {ClearingFile} from '../../models/clearingFile';
import {Currency} from '../../models/file';
import {CurrencyService} from '../../services/currency.service';
import {ShareDataService} from '../../services/share-data.service';


@Component({
  selector: 'digi-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currency') currency: Currency;
  @Input() required = true;
  @Input() readonly: boolean;
  @Input() label;
  @Output() onSelect = new EventEmitter();
  private onSelectUsed = false;
  clearingFile: ClearingFile;
  selectedCurrency: Currency;
  filteredCurrencies = [];
  subscription: Subscription;

  constructor(
    private sharedDataService: ShareDataService,
    private currencyService: CurrencyService,
    private differs: KeyValueDiffers,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject
  ) {
  }

  ngOnInit() {
    this.onSelectUsed = this.onSelect.observers.length > 0;
    this.selectedCurrency = Object.assign({}, this.currency) ;
    this.subscription = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
      this.clearingFile = cf;
//       this.readonly = !this.clearingFile.quotation; //Todo : causing TD -> Freight currency field top be readonly
      this.readonly = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedCurrency = Object.assign({}, this.currency) ;
  }

  search(event) {
    this.currencyService.findCurrencyStartWith(event.query).subscribe(data => {
      this.filteredCurrencies = data;
    });
  }

  select(value) {
    if (this.onSelectUsed) {
      this.onSelect.emit(value);
    } else {
      this.currency.code = value.code;
      this.currency.name = value.name;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
