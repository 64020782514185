import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {Debtor, Warehouse} from '../../digi-business-entity/models/business-entity';
import {ClearingInstruction, Country, CustomsPurpose, CustomsPurposeFile, RebateUser, TransportMethod} from '../../digi-common/models/file';
import {ClearingInstructionsService} from '../../digi-common/services/clearing-instructions.service';
import {Subscription} from 'rxjs';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntry} from '../../digi-common/models/billOfEntries';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {WarehouseBehaviourSubject} from '../../../subjects/warehouse-behaviour-subject';
import {DebtorBehaviourSubject} from '../../../subjects/debtor-behaviour-subject';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {ActivatedRoute} from '@angular/router';
import {Branch} from '../../digi-common/models/company';
import {CompanyService} from '../../digi-common/services/company.service';

enum CustomsPurposeNames {
  XDP = 'Payment of Duty (ex warehouse)',
  XIR = 'Industrial Rebate (ex warehouse)',
  XGR = 'General Rebate (ex warehouse)',
  XRW = 'Re-warehousing (ex warehouse)',
  XIB = 'Removal in Bond (ex warehouse)'
}

@Component({
  selector: 'digi-exbond-register',
  templateUrl: './exbond-register.component.html',
  styleUrls: ['./exbond-register.component.scss']
})
export class ExbondRegisterComponent implements OnInit, OnDestroy {
  exBondFile: ExBondFile;
  filteredDebtors: Debtor[];
  filteredWarehouses: Warehouse[];
  billOfEntry: BillOfEntry;
  availableInstructions: any;
  selectedInstructions: any;
  transportMethods: any[] = [];
  transportMethod: TransportMethod;
  clearingInstruction: ClearingInstruction;
  customsProcedures: any;
  showCustomsProcedures: any;
  display: boolean;
  rebateUsers: RebateUser[];


  exBondFileSubscription: Subscription;
  clearingInstructionsSubscription: Subscription;
  filteredDebtorsSubscription: Subscription;
  filteredWarehousesSubscription: Subscription;
  openSequence: boolean;

  constructor(
    private clearingInstructionService: ClearingInstructionsService,
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private billOfEntryBehaviorSubject: BillOfEntryBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private warehouseBehaviourSubject: WarehouseBehaviourSubject,
    private debtorBehaviourSubject: DebtorBehaviourSubject,
    private exBondFileService: ExBondFileService,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
  }

  ngOnInit() {
    this.findAllClearingInstructions();
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(
      ef => {
        if (ef) {
          this.exBondFile = ef;
          this.companyService.getCompany().subscribe(companies => {
            if (companies && companies.length) {
              const branch: Branch = companies[0].branches[0];
              if (branch.invoicingInUse) {
                this.exBondFile.invoicingInUse = branch.invoicingInUse;
              }
            }
          });
          this.filteredDebtorsSubscription = this.debtorBehaviourSubject.getDebtors().subscribe(
            (debtors: Debtor[]) => this.filteredDebtors = debtors
          );
          this.filteredWarehousesSubscription = this.warehouseBehaviourSubject.getWarehouses().subscribe(
            (warehouses: Warehouse[]) => this.filteredWarehouses = warehouses
          );

          this.billOfEntryBehaviorSubject.getBillOfEntry()
            .subscribe(billOfEntry => this.billOfEntry = billOfEntry);

          this.clearingInstructionsSubscription = this.clearingInstructionBehaviorSubject
            .getClearingInstructions()
            .subscribe(ci => {
              if (ci) {
                this.selectedInstructions = ci;
              }
            });
        }
      }
    );

    const fileNumber = this.route.snapshot.paramMap.get('fileNumber');
    const sequenceNumber = this.route.snapshot.paramMap.get('sequenceNumber');
    if (fileNumber) {
      if (Number.parseInt(sequenceNumber) === 0) {
        this.exBondFileSubscription = this.exBondFileService
          .loadExBondFile(fileNumber)
          .subscribe(data => {
            const exBondFile = data.find(ef => ef.sequence === Number.parseInt(sequenceNumber));
            this.exBondFileService.createNewFile();
            this.exBondFileService.selectExBondFile(exBondFile);
          });
      }
    }
  }

  ngOnDestroy() {
    this.exBondFileSubscription.unsubscribe();
    this.clearingInstructionsSubscription.unsubscribe();
    this.filteredDebtorsSubscription.unsubscribe();
    this.filteredWarehousesSubscription.unsubscribe();
  }

  createNewFile() {
    this.exBondFileService.createNewFile();
  }

  selectDebtor(value) {
    this.exBondFile.debtor.code = value.code;
    this.exBondFile.debtor.name = value.name;
    this.exBondFile.debtor.label = value.label;
    this.exBondFile.debtor.address = value.address;
  }

  selectWarehouse(value) {
    this.exBondFile.warehouse.code = value.code;
    this.exBondFile.warehouse.customsWarehouseNumber = value.customsWarehouseNumber;
    this.exBondFile.warehouse.name = value.name;
    this.exBondFile.warehouse.label = value.label;
    this.exBondFile.warehouse.address = value.address;
  }

  selectImporter(value) {
    this.exBondFile.importer.code = value.code;
    this.exBondFile.importer.name = value.name;
    this.exBondFile.importer.label = value.label;
    this.exBondFile.importer.address = value.address;
    this.exBondFile.importer.customsCode = value.defaultCustomsCode;
    this.exBondFile.importer.taxRegNo = value.defaultVatRegNo;
  }

  selectLocalAgent(value) {
    this.exBondFile.localAgent.code = value.code;
    this.exBondFile.localAgent.name = value.name;
    this.exBondFile.localAgent.label = value.label;
    this.exBondFile.localAgent.address = value.address;
    this.exBondFile.localAgent.customsRegCode = value.defaultCustomsCode;
    this.exBondFile.localAgent.iataCode = value.iataCode;
    this.exBondFile.localAgent.customsOfficeCreditors = value.customsOfficeCreditors;
  }

  selectClearingOption(value) {
    if (value.items.length === 1) {
      const customsPurposeFiles = value.items[0].customsPurposeFiles;
      const customsProcedures = value.items[0].customsProcedures;
      this.updateCustomsProcedure(customsProcedures);
      this.updateCustomsPurposeFile(customsPurposeFiles, this.exBondFile);
      this.setCustomsPurposeOnClearingInstruction(value);
      switch (value.items[0].customsPurposeName) {
        case CustomsPurposeNames.XDP:
        case CustomsPurposeNames.XIR:
        case CustomsPurposeNames.XGR:
        case CustomsPurposeNames.XRW:
        case CustomsPurposeNames.XIB:
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.exBondFile.clearingInstructions);
          this.updateDestinationCountry();
          this.showDialog();
          break;
      }
    }
  }

  onMoveToSource(value: any) {
    const index: number = this.exBondFile.clearingInstructions.indexOf(value);
    if (index !== -1) {
      this.exBondFile.clearingInstructions.splice(index, 1);
    }
    this.customsProcedures = [];
    this.findAllClearingInstructions();
  }

  selectedClearingInstruction(value: any) {
    const clearingInstruction = value.items[0];

    if (value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XDP ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XIR ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XGR ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XRW ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XIB
    ) {
      this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
      this.showDialog();
      this.clearingInstructionBehaviorSubject.addClearingInstructions(this.exBondFile.clearingInstructions);
    }
  }

  private findAllClearingInstructions() {
    this.clearingInstructionService.findAllExBondInstructions().subscribe(
      data => {
        this.availableInstructions = data._embedded.customsPurposes;
        this.updateAvailableClearingInstructions(data);
      });
  }

  private updateAvailableClearingInstructions(data) {
    for (let x = 0; x < data._embedded.customsPurposes.length; x++) {
      this.availableInstructions[x].customsPurpose = new CustomsPurpose();
      this.availableInstructions[x].customsPurpose.customsPurposeName = data._embedded.customsPurposes[x].customsPurposeName;
      this.availableInstructions[x].customsPurpose.customsPurposeCode = data._embedded.customsPurposes[x].customsPurposeCode;
    }
  }

  private showDialog() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  onCloseCustomProcedureModal(event) {
    this.showCustomsProcedures = event;
  }

  private updateCustomsProcedure(customsProcedures) {
    if (customsProcedures && customsProcedures.length === 1) {
      this.exBondFile.clearingInstructions.find(cl => {
        if (cl && (!cl.customsProcedureCode && !cl.customsProcDesc && !cl.procedureCategoryCode && !cl.pcDescription || !cl.destination)) {
          cl.customsProcedureCode = customsProcedures[0].customsProcedureCode;
          cl.customsProcDesc = customsProcedures[0].customsProcDesc;
          cl.procedureCategoryCode = customsProcedures[0].procedureCategory.procedureCategoryCode;
          cl.pcDescription = customsProcedures[0].procedureCategory.pcDescription;
          return true;
        }
        return false;
      });
    } else {
      this.customsProcedures = customsProcedures;
      this.showCustomsProcedures = true;
    }
  }

  private updateCustomsPurposeFile(customsPurposeFiles, exBondFile) {
    if (customsPurposeFiles) {
      customsPurposeFiles.forEach(function (customsPurposeFile) {
        if (customsPurposeFile.id.fileTypeId === 2) {
          exBondFile.clearingInstructions.find(cl => {
            if (cl.customsPurpose && cl.customsPurpose.customsPurposeFile && !cl.customsPurpose.customsPurposeFile.vocDocumentCode) {
              cl.customsPurpose.customsPurposeFile = new CustomsPurposeFile();
              cl.customsPurpose.customsPurposeFile.customsPurposeCode = customsPurposeFile.id.customsPurposeCode;
              cl.customsPurpose.customsPurposeFile.fileTypeId = customsPurposeFile.id.fileTypeId;
              cl.customsPurpose.customsPurposeFile.documentCode = customsPurposeFile.documentCode;
              cl.customsPurpose.customsPurposeFile.vocDocumentCode = customsPurposeFile.vocDocumentCode;
            }
          });
        }
      });
    }
  }

  private setCustomsPurposeOnClearingInstruction(value) {
    const uuid = this.createClearingInstruction_UUID();
    this.exBondFile.clearingInstructions.find(ci => {
      if (ci && (!ci.customsPurpose && !ci.customsPurpose.customsPurposeCode || !ci.destination)) {
        this.clearingInstruction = new ClearingInstruction();
        ci.uuid = uuid;
        ci.customsPurpose.customsPurposeName = value.items[0].customsPurposeName;
        ci.customsPurpose.customsPurposeCode = value.items[0].customsPurposeCode;
        ci.transportMethod = this.transportMethod;
        this.clearingInstruction = ci;
        return true;
      }
      return false;
    });
  }

  private createClearingInstruction_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  updateCustomsOffice(value) {
    this.exBondFile.customOffice = value;
  }

  private updateDestinationCountry() {
    this.exBondFile.clearingInstructions.find(cl => {
      if (cl && !cl.destination) {
        cl.destination = new Country();
        cl.destination.code = 'ZA';
        cl.destination.name = 'South Africa';
        cl.destination.label = 'ZA : SOUTH AFRICA';
        cl.customVatRequired = true;
        return true;
      }
      return false;
    });
  }

  openSequenceModal(value) {
    this.openSequence = value;
  }

  isCorrection(): boolean {
    return this.exBondFile.type === 'ExBondFileCorrection';
  }
}
