import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ExportsReportService = /** @class */ (function () {
    function ExportsReportService(http, config) {
        this.http = http;
        this.config = config;
    }
    ExportsReportService.prototype.generateExportRegistrationReports = function (from, to, isSea) {
        if (isSea) {
            return this.http.get(this.config.filesApiEndpoint + "api/seaRegistrationReports?from=" + from + "&to=" + to);
        }
        else {
            return this.http.get(this.config.filesApiEndpoint + "api/registrationReports?from=" + from + "&to=" + to);
        }
    };
    ExportsReportService.prototype.generateExportPackingListReport = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportPackingList?id=" + id);
    };
    ExportsReportService.ngInjectableDef = i0.defineInjectable({ factory: function ExportsReportService_Factory() { return new ExportsReportService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ExportsReportService, providedIn: "root" });
    return ExportsReportService;
}());
export { ExportsReportService };
