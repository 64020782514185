/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exbond-file-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/button/button";
import * as i4 from "../../../../node_modules/primeng/components/toolbar/toolbar.ngfactory";
import * as i5 from "primeng/components/toolbar/toolbar";
import * as i6 from "../exbond-file-search/exbond-file-search.component.ngfactory";
import * as i7 from "../exbond-file-search/exbond-file-search.component";
import * as i8 from "../../../subjects/exbondfile-behaviour-subject";
import * as i9 from "../services/ex-bond-file.service";
import * as i10 from "@angular/common";
import * as i11 from "./exbond-file-section.component";
import * as i12 from "../../digi-common/services/notification.service";
import * as i13 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i14 from "../../digi-common/services/validation.service";
var styles_ExbondFileSectionComponent = [i0.styles];
var RenderType_ExbondFileSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExbondFileSectionComponent, data: {} });
export { RenderType_ExbondFileSectionComponent as RenderType_ExbondFileSectionComponent };
function View_ExbondFileSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "ui-button-secondary"], ["icon", "fa fa-file"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef, i2.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; var currVal_2 = "fa fa-file"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableButtons; _ck(_v, 0, 0, currVal_0); }); }
function View_ExbondFileSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["icon", "fa fa-plus"], ["label", "Create VOC"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createVoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef, i2.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Create VOC"; var currVal_1 = "fa fa-plus"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ExbondFileSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "p-toolbar", [["class", "toolbar"]], null, null, null, i4.View_Toolbar_0, i4.RenderType_Toolbar)), i1.ɵdid(1, 49152, null, 0, i5.Toolbar, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "ui-toolbar-group-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "digi-exbond-file-search", [], null, null, null, i6.View_ExbondFileSearchComponent_0, i6.RenderType_ExbondFileSearchComponent)), i1.ɵdid(5, 114688, null, 0, i7.ExbondFileSearchComponent, [i8.ExBondFileBehaviourSubject, i9.ExBondFileService], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 7, "div", [["class", "ui-toolbar-group-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["icon", "fa fa-save"], ["label", "Save"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveExBondFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(9, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef, i2.DomHandler], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExbondFileSectionComponent_1)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExbondFileSectionComponent_2)), i1.ɵdid(13, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_1 = "Save"; var currVal_2 = "fa fa-save"; _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_3 = _co.canAdd; _ck(_v, 11, 0, currVal_3); var currVal_4 = ((_co.canCreateVoC && _co.hasExBondFile()) && _co.hasMrn()); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSaveDisabled() || _co.disableButtons); _ck(_v, 7, 0, currVal_0); }); }
export function View_ExbondFileSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-exbond-file-section", [], null, null, null, View_ExbondFileSectionComponent_0, RenderType_ExbondFileSectionComponent)), i1.ɵdid(1, 245760, null, 0, i11.ExbondFileSectionComponent, [i8.ExBondFileBehaviourSubject, i12.NotificationService, i9.ExBondFileService, i13.BillOfEntryBehaviourSubject, i14.ValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExbondFileSectionComponentNgFactory = i1.ɵccf("digi-exbond-file-section", i11.ExbondFileSectionComponent, View_ExbondFileSectionComponent_Host_0, { step: "step", canAdd: "canAdd", label: "label", canCreateVoC: "canCreateVoC" }, { add: "add", displayVocSequenceModal: "displayVocSequenceModal" }, []);
export { ExbondFileSectionComponentNgFactory as ExbondFileSectionComponentNgFactory };
