import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  Address,
  BusinessEntity,
  Country,
  CustomsTraderType,
  ImporterSupplier,
  NominatedBusinessEntity,
  Role, Importer, RoleAddress
} from '../../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../../../services/notification.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ClearingFile} from '../../../models/clearingFile';
import {LocalAgent, PurchaseTerm, SupplierInvoice, SupplierInvoiceLine, TransportDocument} from '../../../models/file';
import {Subscription} from 'rxjs';
import {SaveClearingFile} from '../../../saveClearingFile';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {ForwardingFile, ForwardingTransportDocument} from '../../../models/ForwardingFile';
import {ForwardingFileBehaviourSubject} from '../../../../../subjects/forwarding-file-behaviour-subject';
import {ForwardingFileService} from '../../../services/forwarding-file.service';

@Component({
  selector: 'digi-forwarding-importer-modal',
  templateUrl: './importer-forwarding-modal.component.html',
  styleUrls: ['./importer-forwarding-modal.component.scss']
})
export class ImporterForwardingModalComponent implements OnInit {
  @Input('display') display;
  @Output() created = new EventEmitter();
  roleType = 6;
  businessEntity: BusinessEntity;
  importer: Importer;
  role: Role;
  selectedAddress: Address;
  clearingFile: ClearingFile;
  nominatedBusinessEntity: NominatedBusinessEntity;
  importerSupplier: ImporterSupplier;
  clearingFileSubscription: Subscription;
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;
  forwardingFileServiceSubscription: Subscription;
  forwardingFile: ForwardingFile;
  selectedHouseBill: ForwardingTransportDocument = {};
  tdSubscription: Subscription;



  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
              private onSaveClearingFile: SaveClearingFile,
              private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
              private forwardingFileService: ForwardingFileService,
  ) {}

  ngOnInit() {
    this.initialize();

    this.forwardingFileServiceSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
      .subscribe(file => {
        this.forwardingFile = file;
      });

    this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ForwardingTransportDocument) => {
        if (td) {
          this.selectedHouseBill = td;
        }
      });
    this.role = new Importer();
  }

  initialize() {
    this.businessEntity = new BusinessEntity();
    this.nominatedBusinessEntity = new NominatedBusinessEntity();
    this.importerSupplier = new ImporterSupplier();
    this.importer =  new Importer();
    this.selectedAddress = new Address();
  }

  selectCountry(value: Country) {
    this.importer.country = value;

  }

  selectTraderType(value: CustomsTraderType) {
    this.importer.registrationType = value;
  }

  onSave() {
    this.forwardingFileService.save(this.forwardingFile).subscribe(forwardingFile => {
      this.messageService.successNotify('File Saved Successfully');
      this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
    });
  }

  select(value) {
    this.businessEntity = value;
  }

  onNew() {
    this.initialize();
    this.selectedHouseBill.importer = new Importer();
    this.selectedHouseBill.importer.address = new Address();
  }

  onClose() {
    this.display = false;
    this.created.emit(this.nominatedBusinessEntity.businessEntity);
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  // ngOnDestroy(): void {
  //   this.clearingFileSubscription.unsubscribe();
  // }
}

