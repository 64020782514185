import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {ClearingInstruction, Consignee, Consignor, RebateUser, Remover} from '../app/digi-common/models/file';
import {Warehouse} from '../app/digi-business-entity/models/business-entity';


@Injectable({
  providedIn: 'root'
})
export class ClearingInstructionBehaviorSubject {
  private clearingInstructionSubject = <BehaviorSubject<ClearingInstruction[]>>new BehaviorSubject([]);
  private rebateUsersSubject = <ReplaySubject<RebateUser[]>>new ReplaySubject<RebateUser[]>(1);
  private warehousesSubject = <ReplaySubject<Warehouse[]>>new ReplaySubject<Warehouse[]>(1);
  private removersSubject = <ReplaySubject<Remover[]>>new ReplaySubject<Remover[]>(1);
  private importerConsigneeSubject = <ReplaySubject<Consignee[]>>new ReplaySubject<Consignee[]>(1);
  private instructionSubject = <BehaviorSubject<ClearingInstruction>>new BehaviorSubject(ClearingInstruction);

  constructor() {
  }

  addClearingInstructions(clearingInstructions: ClearingInstruction[]) {
    this.clearingInstructionSubject.next(clearingInstructions);
  }

  getClearingInstructions(): Observable<ClearingInstruction[]> {
    return this.clearingInstructionSubject.asObservable();
  }

  addInstruction(clearingInstruction: ClearingInstruction) {
    this.instructionSubject.next(clearingInstruction);
  }

  getInstruction(): Observable<ClearingInstruction> {
    return this.instructionSubject.asObservable();
  }

  addRebateUsers(rebateUsers: RebateUser[]) {
    this.rebateUsersSubject.next(rebateUsers);
  }


  getRebateUsers(): Observable<RebateUser[]> {
    return this.rebateUsersSubject.asObservable();
  }

  addWarehouses(warehouses: Warehouse[]) {
    this.warehousesSubject.next(warehouses);
  }


  getWarehouses(): Observable<Warehouse[]> {
    return this.warehousesSubject.asObservable();
  }

  addRemovers(remover: Remover[]) {
    this.removersSubject.next(remover);
  }

  getRemovers(): Observable<Remover[]> {
    return this.removersSubject.asObservable();
  }

  addImporterConsignee(importerConsignee: Consignee[]) {
    this.importerConsigneeSubject.next(importerConsignee);
  }

  getImporterConsignee(): Observable<Consignee[]> {
    return this.importerConsigneeSubject.asObservable();
  }
}
