import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'digi-tariff-maintenance-navigation',
  templateUrl: './tariff-maintenance-navigation.component.html',
  styleUrls: ['./tariff-maintenance-navigation.component.scss']
})
export class TariffMaintenanceNavigationComponent implements OnInit {
  items: MenuItem[];
  activeIndex: number = 0;
  label: string;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Tariffs',
        command: (event: any) => {
          this.activeIndex = 0;
          this.router.navigate(['/common/tariff-mantainance/tariff']);
        },
        icon: 'fa fa-user-plus'
      }, {
        label: 'dependents',
        command: (event: any) => {
          this.activeIndex = 0;
          this.router.navigate(['/common/tariff-mantainance/dependent']);
        },
        icon: 'fa fa-user-plus'
      }
    ];
  }

}
