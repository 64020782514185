import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ShareDataService} from '../../../digi-common/services/share-data.service';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {ExportTransportDocument} from '../../../digi-common/models/file';

@Component({
  selector: 'digi-export-air-dashboard',
  templateUrl: './export-air-dashboard.component.html',
  styleUrls: ['./export-air-dashboard.component.scss']
})
export class ExportAirDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private shareDataService: ShareDataService,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject
  ) { }

  ngOnInit() {
    const exportFile = this.shareDataService.buildExportFile('AIR');
    exportFile.transportDocument = <ExportTransportDocument>this.shareDataService.addTransportDocument('exports');
    this.transportDocumentBehaviourSubject.addTransportDocument(exportFile.transportDocument);
    this.exportFileBehaviourSubject.addExportFile(exportFile);
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== 'export/air/register/') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['export', 'air', 'register']);
    }
  }

}
