import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';

import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile} from '../../../models/clearingFile';
import {
  CargoType,
  Container, ContainerSize,
  ContainerSpecification,
  ContainerType, DepotTerminal,
  ExportTransportDocument, SealDetails,
  Solas,
  TransportDocument
} from '../../../models/file';
import {ShareDataService} from '../../../services/share-data.service';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ForwardingFileBehaviourSubject} from '../../../../../subjects/forwarding-file-behaviour-subject';
import {ExportFile} from '../../../models/exportFile';
import {ForwardingFile, ForwardingTransportDocument} from '../../../models/ForwardingFile';
import {FileType} from '../../../models/enumerations';
import {ContainerService} from '../../../services/container.service';

@Component({
  selector: 'digi-containers-section',
  templateUrl: './containers-section.component.html',
  styleUrls: ['./containers-section.component.scss'],
})
export class ContainersSectionComponent implements OnInit, OnDestroy {
  @Input() type: FileType;
  selectedTransportDocument: TransportDocument | ExportTransportDocument | ForwardingTransportDocument;
  transportDocumentSubscription: Subscription;
  selectedContainer: Container;
  containerSubscription: Subscription;
  isTerminalOrDeportSelected: boolean;
  clearingFileSubscription: Subscription;
  clearingFile: ClearingFile | ExportFile | ForwardingFile;
  cargoTypes: any[];
  selectedCargoType: CargoType;
  filteredCargoTypes: any[];
  private file: any;
  showProgressBar: boolean;
  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private containerBehaviorSubject: ContainerBehaviorSubject,
              private shareDataService: ShareDataService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
              private containerService: ContainerService
              ) {
    this.cargoTypes = [
      {code: 'DB', description: 'Dry Bulk', label: 'DB - Dry Bulk'},
      {code: 'LB', description: 'Liquid Bulk', label: 'LB - Liquid Bulk'},
      {code: 'BB', description: 'Break Bulk', label: 'BB - Break Bulk'},
      {code: 'CN', description: 'Container', label: 'CN - Container'},
    ];
  }

  ngOnInit() {
    if (this.type && this.type === 'exports') {
      this.clearingFileSubscription = this.exportFileBehaviourSubject.getExportFile()
        .subscribe(cf => {
          if (cf) {
            this.clearingFile = cf;
          }
        });

    } else if (this.type && this.type === 'imports') {
      this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile()
        .subscribe(cf => {
          if (cf) {
            this.clearingFile = cf;
          }
        });
    } else {
      this.clearingFileSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
        .subscribe(cf => {
          if (cf) {
            this.clearingFile = cf;
          }
        });
    }

    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
        if (td) {
          this.selectedTransportDocument = td;
          if (this.selectedTransportDocument.containers.length === 0 && !this.isRoadTransportDocument()) {
            const container: Container = this.shareDataService.addContainer();
            this.selectedTransportDocument.containers.push(container);
            this.selectedTransportDocument.containers = [...this.selectedTransportDocument.containers];
            this.containerBehaviorSubject.addContainer(container);
          } else {
            this.containerBehaviorSubject.addContainer(this.selectedTransportDocument.containers[0]);
          }
        }
      });

    this.containerSubscription = this.containerBehaviorSubject.getContainer()
      .subscribe(ct => {
        this.selectedContainer = ct;
        if (this.selectedContainer && this.selectedContainer.cargoType) {
          this.selectedCargoType = this.selectedContainer.cargoType;
        }
      });
  }

  ngOnDestroy(): void {
    this.transportDocumentSubscription.unsubscribe();
    this.containerSubscription.unsubscribe();
    this.clearingFileSubscription.unsubscribe();
  }

  addContainer() {
    const container: Container = this.createContainer();
    this.selectedTransportDocument.containers.push(container);
    this.selectedTransportDocument.containers = [...this.selectedTransportDocument.containers];
    this.containerBehaviorSubject.addContainer(container);
  }

  terminalOrDeportSelected(event) {
    this.isTerminalOrDeportSelected = event;
  }

  isSeaForwarding() { //Todo : perhaps refactor these is methods
      return this.type === 'forwarding';
    }

  isRoadAndBulk() {
    if (this.isSeaForwarding()){
      return false;
    } else {
      return (this.clearingFile.clearingInstructions[0].transportMethod.method === 'ROA'
        && !(this.selectedContainer && this.selectedContainer.containerType.description === 'Bulk'
        || this.selectedContainer && this.selectedContainer.containerType.description === 'Break Bulk'))
        || this.clearingFile.clearingInstructions[0].transportMethod.method === 'SEA';
    }
  }

  isSeaTransportDocument() {
    if (this.isSeaForwarding()) {
      return false;
    } else {
      return this.clearingFile.clearingInstructions[0].transportMethod.method === 'SEA';
    }
  }
  selectFiles(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.file = files[0];
  }
  isRoadTransportDocument() {
    if (this.isSeaForwarding()) {
      return false;
    } else {
      return this.clearingFile.clearingInstructions[0].transportMethod.method === 'ROA';
    }
  }

  isRailTransportDocument() {
    if (this.isSeaForwarding()) {
      return false;
    } else {
      return this.clearingFile.clearingInstructions[0].transportMethod.method === 'RAI';
    }
  }

  searchCargoType(event) {
    this.filteredCargoTypes = this.cargoTypes.filter(v => v.label
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredCargoTypes = [...this.filteredCargoTypes];
  }

  selectCargoType(event) {
    this.selectedContainer.cargoType = event;
  }

  createContainer(): Container {
    const container = this.shareDataService.addContainer();
    if (this.selectedTransportDocument.containers.length > 0) {
      const firstContainer = this.selectedTransportDocument.containers[0];
      container.containerType = firstContainer.containerType;
      container.containerSpecification = firstContainer.containerSpecification;
      container.terminal = firstContainer.terminal;
      container.depot = firstContainer.depot;
      container.noOfPackages = firstContainer.noOfPackages;
      container.cbm = firstContainer.cbm;
      container.solas.vgm = firstContainer.solas.vgm;
      container.solas.verificationMethod = firstContainer.solas.verificationMethod;
    }
    return container;
  }
  uploadContainer() {
    if (this.file) {
      this.showProgressBar = true;
      this.containerService.uploadContainerSpreadsheet(this.file).subscribe(
        (containers: Container[]) => { // Expect a list of containers
          containers.forEach((container, index) => {
            if (index === 0 && this.isEmptyContainer(this.selectedTransportDocument.containers[0])) {
              this.selectedTransportDocument.containers[0] = container;
            } else {
              this.addContainerUploaded(container);
            }
          });
          this.showProgressBar = false;
        },
        (error) => {
          this.showProgressBar = false;
        }
      );
    }
  }

  isEmptyContainer(container: Container) {
    return container && container.containerType && container.containerType.code ? false : true;
  }

  addContainerUploaded(uploadedContainer: Container) {
    const newContainer = this.createContainer();
    Object.assign(newContainer, uploadedContainer);
    this.selectedTransportDocument.containers.push(newContainer);
    this.selectedTransportDocument.containers = [...this.selectedTransportDocument.containers];
    this.containerBehaviorSubject.addContainer(newContainer);
  }

}
