import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ChargeType,
  Department,
  Invoice,
  InvoiceSection,
  InvoiceSubSection,
  SupplierInvoiceLine,
  TranType
} from '../../digi-common/models/financials';
import {TaxType} from '../../digi-common/models/TariffCode';
import {ExBondBillOfEntry} from '../../digi-common/models/billOfEntries';
import {combineLatest, Subscription} from 'rxjs';
import {Table} from 'primeng/table';
import {BankingDetails, GenLedgerAccDetails, Company} from '../../digi-common/models/company';
import {ShareDataService} from '../../digi-common/services/share-data.service';
import {DebtorService} from '../../digi-common/services/debtor.service';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ExportFileBehaviourSubject} from '../../../subjects/exportfile-behaviour-subject';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ChargeTypeService} from '../../digi-common/services/charge-type.service';
import {TranTypeService} from '../../digi-common/services/tran-type.service';
import {DepartmentService} from '../../digi-common/services/department.service';
import {ClearingFileService} from '../../digi-common/services/clearing-file.service';
import {InvoiceSectionService} from '../../digi-common/services/invoice-section.service';
import {TaxTypeService} from '../../digi-common/services/tax-type.service';
import {InvoiceService} from '../../digi-common/services/invoice.service';
import {BusinessEntityService} from '../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../../digi-common/services/notification.service';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';
import {CompanyService} from '../../digi-common/services/company.service';
import {DomSanitizer} from '@angular/platform-browser';
import {KeyValue} from '@angular/common';
import {BusinessEntity} from '../../digi-business-entity/models/business-entity';
import {Entry} from '../../digi-common/components/financials/fin-supplrinv-headers/fin-supplrinv-headers.component';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {Address} from '../../digi-common/models/file';

@Component({
  selector: 'digi-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.scss']
})
export class FinancialsComponent implements OnInit, OnDestroy {
  file: ExBondFile;
  invoice = new Invoice();
  invoices: Invoice[];
  filteredChargeTypes: ChargeType[];
  filteredTaxTypes: TaxType[];
  filteredTranTypes: TranType[];
  filteredDepartments: Department[];
  filteredInvoiceSections: InvoiceSection[];
  filteredInvoiceSubSections: InvoiceSubSection[];
  selectedBillOfEntry: ExBondBillOfEntry;
  transportMethod: string;

  exBondFileSubscription: Subscription;
  BOESubscription: Subscription;
  invoiceSubscription: Subscription;
  invoicesSubscription: Subscription;
  taxTypeSubscription: Subscription;
  chargeTypeSubscription: Subscription;
  companySubscription: Subscription;
  invoiceSectionSubscription: Subscription;
  invoiceSubSectionSubscription: Subscription;
  departmentsSubscription: Subscription;
  tranTypeSubscription: Subscription;
  defaultValues: Subscription;

  editingLine = -1;
  showPrintView = false;

  invoiceSectionID: number;

  cols: any[];

  vat: number;

  @ViewChild('templateRef') templateRef: Table;

  ctrlIsPressed = false;
  loggedInUser: string;
  debtor: any;
  logo: any;
  bankingDetails: BankingDetails[];
  genLedgerAccDetails: GenLedgerAccDetails[];

  @HostListener('window:keydown', ['$event.keyCode'])
  onKeyDown(keyCode) {
    if (keyCode === 17) {
      this.ctrlIsPressed = true;
    }
    if (keyCode === 65 && this.ctrlIsPressed && !this.showPrintView) {
      this.addNewLine();
    }
  }

  @HostListener('window:keyup', ['$event.keyCode'])
  onKeyUp(keyCode) {
    if (keyCode === 17) {
      this.ctrlIsPressed = false;
    }
  }

  constructor(
    private shareDataService: ShareDataService,
    private debtorService: DebtorService,
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private chargeTypeService: ChargeTypeService,
    private tranTypeService: TranTypeService,
    private departmentService: DepartmentService,
    private exBondFileService: ExBondFileService,
    private invoiceSectionService: InvoiceSectionService,
    private taxTypeService: TaxTypeService,
    private invoiceService: InvoiceService,
    private businessEntityService: BusinessEntityService,
    private messageService: NotificationService,
    private authorisationService: AuthorisationService,
    private companyService: CompanyService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.authorisationService
      .getLoggedInUser()
      .subscribe(user => {
        if (user) {
          this.loggedInUser = user.name;
        }
      });

    this.getCompany();

    this.cols = [
      {field: 'invoiceNo', header: 'Invoice No'},
      {field: 'date', header: 'Invoice Date'}
    ];
    this.BOESubscription = this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(
      boe => {
        this.selectedBillOfEntry = boe;
        console.log(this.selectedBillOfEntry);
      }
    );

    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(
      file => {
        this.file = file;
        if (this.file.clearingInstructions[0] && this.file.clearingInstructions[0].transportMethod) {
          this.transportMethod = this.file.clearingInstructions[0].transportMethod.method;
        }
        this.searchDebtor(this.file.debtor.name);
        this.invoicesSubscription = this.invoiceService.getInvoices(this.file).subscribe(
          invoices => {
            this.invoices = invoices;
            if (invoices && invoices.length) {
              this.invoice = invoices[0];
            }

            if (this.invoice && this.invoice.confirmed) {
              this.showPrintView = true;
            }

            if (this.invoice && !this.invoice.confirmed) {
              this.getValuesFromBOE();
            }
          }
        );
      });
  }

  order = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  searchDebtor(query) {
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(query, 1).subscribe(
      (businessEntities: BusinessEntity[]) => {
        if (businessEntities[0]) {
          this.file.debtor.vatNo = businessEntities[0].defaultVatRegNo;
          this.invoice.currency = businessEntities[0].roles.find(role => role.roleType === 1)['currency'];
          if (this.invoice.currency.code === 'ZAR') {
            this.invoice.exchangeRate = 1;
          }
          this.debtor = businessEntities[0];
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.exBondFileSubscription) {
      this.exBondFileSubscription.unsubscribe();
    }
    if (this.BOESubscription) {
      this.BOESubscription.unsubscribe();
    }
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
    if (this.taxTypeSubscription) {
      this.taxTypeSubscription.unsubscribe();
    }
    if (this.chargeTypeSubscription) {
      this.chargeTypeSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.invoiceSectionSubscription) {
      this.invoiceSectionSubscription.unsubscribe();
    }
    if (this.invoiceSubSectionSubscription) {
      this.invoiceSubSectionSubscription.unsubscribe();
    }
    if (this.departmentsSubscription) {
      this.departmentsSubscription.unsubscribe();
    }
    if (this.tranTypeSubscription) {
      this.tranTypeSubscription.unsubscribe();
    }

    if (this.defaultValues) {
      this.defaultValues.unsubscribe();
    }
  }

  saveInvoice() {
    this.invoiceSubscription = this.invoiceService.saveInvoice(this.invoice, this.file).subscribe(
      data => {
        this.messageService.successNotify('Invoice Saved Successfully');
        this.invoice = data;
        if (!this.invoices.find(el => el.invoiceNo === this.invoice.invoiceNo)) {
          this.invoices.push(this.invoice);
        }
      }
    );
  }

  searchTaxTypeCode(event) {
    this.taxTypeSubscription = this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(taxTypes => {
      this.filteredTaxTypes = taxTypes;
    });
  }

  searchTranType(event) {
    this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith(event.query).subscribe(tranTypes => {
      this.filteredTranTypes = tranTypes;
    });
  }

  searchChargeType(event) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(event.query).subscribe(chargeTypes => {
      this.filteredChargeTypes = chargeTypes;
    });
  }

  searchDepartmentCode(event) {
    this.departmentsSubscription = this.departmentService.findDepartmentsByCodeStartWith(event.query).subscribe(
      departments => {
        this.filteredDepartments = departments;
      }
    );
  }

  searchInvoiceSection(event) {
    this.invoiceSectionSubscription = this.invoiceSectionService.findInvoiceSection(event.query).subscribe(
      invoiceSections => {
        this.filteredInvoiceSections = invoiceSections;
      }
    );
  }

  searchInvoiceSubSection(query, invoiceSection: InvoiceSection) {
    if (invoiceSection.id) {
      this.invoiceSubSectionSubscription = this.invoiceSectionService
        .findInvoiceSubSectionsByInvoiceSectionId(invoiceSection.id, query)
        .subscribe(
          invoiceSubSections => {
            this.filteredInvoiceSubSections = [];
            invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
          }
        );
    } else {
      this.invoiceSectionSubscription = this.invoiceSectionService
        .findInvoiceSection(invoiceSection.description)
        .subscribe(
          invoiceSections => {
            this.invoiceSubSectionSubscription = this.invoiceSectionService
              .findInvoiceSubSectionsByInvoiceSectionId(invoiceSections[0].id, query)
              .subscribe(
                invoiceSubSections => {
                  this.filteredInvoiceSubSections = [];
                  invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
                }
              );
          }
        );
    }
  }

  getLines() {
    const entries: Entry[] = [];
    if (this.invoice.lineDetails) { //Todo
      this.invoice.lineDetails.forEach(el => {
        if (el.invoiceSection) {
          const foundEntry = entries.find(entry => entry.invoiceSection === el.invoiceSection.description);
          if (foundEntry) {
            const foundLine = foundEntry.lines.find(l => l.invoiceSubSection === el.invoiceSubSection);
            // do not add if line already exists (consolidated already)
            if (!foundLine) {
              const consolidatedLine = this.consolidate(el.invoiceSubSection, el.taxType.sarsTaxCode);
              foundEntry.lines.push(consolidatedLine);
            }
          } else {
            entries.push({
              invoiceSection: el.invoiceSection.description,
              lines: [this.consolidate(el.invoiceSubSection, el.taxType.sarsTaxCode)]
            });
          }
        }
      });
    }

    return entries;
  }

  getTotal(lines, value) {
    let sum = 0;
    if (lines) {
      lines.forEach(el => {
        if (value === 'vat') {
          el.localTax ? sum += Number(el.localTax) : sum += 0;
        }

        if (value === 'localAmount') {
          el.localAmount ? sum += Number(el.localAmount) : sum += 0;
        }

      });
    } else {
      if (value === 'totalExclVat' && this.invoice.lineDetails) {
        this.invoice.lineDetails.forEach(lineDetail => {
          lineDetail.localAmount ? sum += Number(lineDetail.localAmount) : sum += 0;
        });
      }

      if (value === 'totalVat' && this.invoice.lineDetails) {
        this.invoice.lineDetails.forEach(lineDetail => {
          lineDetail.localTax ? sum += Number(lineDetail.localTax) : sum += 0;
        });
      }
    }
    return Number(sum.toFixed(2));
  }

  getTaxSummary() {
    const taxSummaryArr = {S: 0, E: 0, Z: 0, N: 0};
    if (this.invoice.lineDetails) {
      this.invoice.lineDetails.forEach(
        el => {
          if (el.taxType) {
            taxSummaryArr[el.taxType.sarsTaxCode] += Number(el.localAmount);
          }
        }
      );
    }
    return taxSummaryArr;
  }

  addNewLine() {
    const line = new SupplierInvoiceLine();
    if (this.invoice.taxType) {
      line.taxType = this.invoice.taxType;
    }

    if (this.invoice.lineDetails && this.invoice.lineDetails.length) {
      this.invoice.lineDetails.push(line);
    } else {
      this.invoice.lineDetails = [];
      this.invoice.lineDetails.push(line);
    }

    // autofocus on charge type column of a new line
    setTimeout(() => {
      this.templateRef.tableViewChild.nativeElement.getElementsByTagName('tr')
        .item(this.templateRef.value.length).getElementsByTagName('input').item(0).focus();
    }, 300);
  }

  deleteLine(index) {
    this.invoice.lineDetails = this.invoice.lineDetails.filter(el => this.invoice.lineDetails.indexOf(el) !== index);
    this.editingLine = -1;
  }

  print() {
    window.print();
  }

  createNewInvoice() {
    this.invoice = new Invoice();
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(
      file => {
        this.file = file;
        this.searchDebtor(this.file.debtor.name);
      }
    );
    this.getCompany();
    this.getValuesFromBOE();
  }

  private getCaptured() {
    return (this.getTotal(null, 'totalExclVat') + this.getTotal(null, 'totalVat')).toFixed(2);
  }

  confirmInvoice() {
    const errors = this.validateInvoice();
    if (errors.length === 0) {
      this.invoice.confirmed = true;
      this.invoiceSubscription = this.invoiceService.saveInvoice(this.invoice, this.file).subscribe(
        invoice => {
          if (invoice.confirmed) {
            this.messageService.successNotify('Invoice Confirmed Successfully');
            this.showPrintView = true;
          }
        }
      );
    } else {
      this.messageService.errorNotify('Invalid Invoice', errors);
    }
  }

  private getCompany() {
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          const company = companies[0];
          this.logo = this.domSanitizer.bypassSecurityTrustStyle(`url(${'data:image/png;base64,' + company.logo})`);
          const branch = this.file.branch ? companies[0].branches.find(el => el.code === this.file.branch) : companies[0].branches[0];
          this.invoice.companyName = branch.name;
          this.invoice.companyPostalAddress = this.getFormattedAddress(branch.invoiceDetails.postalAddress);
          this.invoice.companyPhysicalAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.invoice.companyVatNo = branch.invoiceDetails.vatNo;
          this.invoice.companyTel = branch.invoiceDetails.tel;
          this.invoice.companyFax = branch.invoiceDetails.fax;
          this.invoice.companyEmail = branch.invoiceDetails.email;

          this.invoice.companyReg = branch.invoiceDetails.regNo;
          this.bankingDetails = branch.invoiceDetails.bankingDetails;
          this.genLedgerAccDetails = branch.invoiceDetails.genLedgerAccDetails;

          this.vat = company.companyDefaults.vat;
        }
      }
    );
  }

  private getValuesFromBOE() {
    let defaultTaxType; //Todo
    let defaultTranType;
    let defaultInvoiceSection;
    const taxTypeObservable = this.taxTypeService.findByCode('vna');
    const invoiceSectionObservable = this.invoiceSectionService.findInvoiceSection('disbursement');
    const tranTypeObservable = this.tranTypeService.findTranTypesByCodeStartWith('rec');
    this.defaultValues = combineLatest(taxTypeObservable, invoiceSectionObservable, tranTypeObservable).subscribe(
      (defaults: any[]) => {
        defaultTaxType = defaults[0];
        if (defaults[1]) {
          defaultInvoiceSection = defaults[1][0];
          this.invoiceSectionID = defaultInvoiceSection.id;
        }
        if (defaults[2]) {
          defaultTranType = defaults[2][0];
        }

        let foundCusDChargeType;
        let foundCusVChargeType;
        let foundCusOChargeType;
        if (this.invoice && this.invoice.lineDetails) {
          foundCusDChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSD  ');
          foundCusVChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSV  ');
          foundCusOChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSOTH');
        }

        if (this.selectedBillOfEntry.totalCustomsDuty > 0 && !foundCusDChargeType) {
          this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - Duty').subscribe(
            data => this.addChargeTypeFromBOE('cusd', defaultTaxType, defaultInvoiceSection, data[0].description,
              defaultTranType, (this.selectedBillOfEntry.totalCustomsDuty + this.selectedBillOfEntry.schedulePartTotals
                .reduce((n, { totalValue}) => n + totalValue, 0)), 0)
          );
        }

        if (this.selectedBillOfEntry.totalCustomsVAT > 0 && !foundCusVChargeType) {
          this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - VAT').subscribe(
            data => this.addChargeTypeFromBOE('cusv', defaultTaxType, defaultInvoiceSection, data[0].description,
              defaultTranType, 0, this.selectedBillOfEntry.totalCustomsVAT)
          );
        }

        if (this.selectedBillOfEntry.schedulePartTotals && this.selectedBillOfEntry.schedulePartTotals.length > 0 && !foundCusOChargeType && this.selectedBillOfEntry.customsProcedureCode !== 41) {
          const sch1p2b = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '12B');
          if (sch1p2b) {
            this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - Schedule 1 Part 2B').subscribe(
              data => this.addChargeTypeFromBOE('cuso', defaultTaxType, defaultInvoiceSection, data[0].description,
                defaultTranType, sch1p2b.totalValue, 0)
            );
          }
          const sch1p2a = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '12A');
          if (sch1p2a) {
            this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - Schedule 1 Part 2A').subscribe(
              data => this.addChargeTypeFromBOE('cuso', defaultTaxType, defaultInvoiceSection, data[0].description,
                defaultTranType, sch1p2a.totalValue, 0)
            );
          }
        }
      }
    );
  }

  private addChargeTypeFromBOE(code: string, defaultTaxType: TaxType, defaultInvoiceSection: InvoiceSection,
                               defaultInvoiceSubSection: string, defaultTranType: TranType, value: number, tax: number) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(code).subscribe(
      (chargeTypes: ChargeType[]) => {
        const line = new SupplierInvoiceLine();
        line.taxType = defaultTaxType;
        line.invoiceSection = defaultInvoiceSection;
        line.invoiceSubSection = defaultInvoiceSubSection;
        line.tranType = defaultTranType;
        line.chargeType = chargeTypes[0];

        line.localAmount = value;
        line.localTax = tax;
        if (this.invoice.lineDetails && this.invoice.lineDetails.length) {
          this.invoice.lineDetails.push(line);
        } else {
          this.invoice.lineDetails = [];
          this.invoice.lineDetails.push(line);
        }
      }
    );
  }

  private consolidate(invoiceSubSectionDesc: string, code: string) {
    const line: SupplierInvoiceLine = new SupplierInvoiceLine();
    this.invoice.lineDetails
      .forEach(
        l => {
          if (l.invoiceSubSection === invoiceSubSectionDesc && l.taxType.sarsTaxCode === code) {
            line.invoiceSection = l.invoiceSection;
            line.invoiceSubSection = l.invoiceSubSection;
            line.localAmount = line.localAmount ? Number(line.localAmount) + Number(l.localAmount) : Number(l.localAmount);
            line.localTax = line.localTax ? Number(line.localTax) + Number(l.localTax) : Number(l.localTax);
            line.taxType = l.taxType;
          }
        }
      );
    return line;
  }

  calculateLocalTax(taxType: TaxType, localAmount: number) {
    if (taxType.code.trim() === 'VAT') {
      return (localAmount * this.vat).toFixed(2);
    } else {
      return 0;
    }
  }

  getRounded(value) {
    if (value) {
      if (typeof value === 'string') {
        return Number(value).toFixed(2);
      } else {
        return value.toFixed(2);
      }
    } else {
      return '0.00';
    }

  }

  private validateInvoice(): string[] {
    const errors = [];
    this.invoice.lineDetails.forEach((lineDetail, index) => {
      if (!lineDetail.invoiceSection || (lineDetail.invoiceSection && !lineDetail.invoiceSection.description)) {
        errors.push(`No Invoice Section on line ${index + 1}`);
      }
    });
    return errors;
  }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }

}
