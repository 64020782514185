import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile} from '../../models/clearingFile';
import {Container, ExportTransportDocument, TransportDocument} from '../../models/file';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../../subjects/container-behavior-subject';

@Component({
  selector: 'digi-hawb-weight-measures-section',
  templateUrl: './hawb-weight-measures-section.component.html',
  styleUrls: ['./hawb-weight-measures-section.component.css']
})
export class HawbWeightMeasuresSectionComponent implements OnInit, OnDestroy {
  @Input() label = 'Volume';
  @Input() view: string;
  transportDocument: TransportDocument|ExportTransportDocument;
  transportDocumentSubscription: Subscription;
  exportsTransportDocumentSubscription: Subscription;
  clearingFile: ClearingFile;
  selectedContainer: Container;
  clearingFileSubscription: Subscription;
  containerSubscription: Subscription;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private selectedContainerBehaviourSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
        }
      });

    this.transportDocumentSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe(td => {
          if (td) {
            this.transportDocument = td;
          }
        }
      );

    if (this.view === 'containers') {
      this.containerSubscription = this.selectedContainerBehaviourSubject.getContainer().subscribe(
        (container: Container) => {
          this.selectedContainer = container
          if (!this.selectedContainer.noOfPackages) {
            this.selectedContainer.noOfPackages = this.transportDocument.measures.numberOfPackages;
          }
        }
      );
    }
  }

  isSeaTransportDocument() {
    return this.clearingFile.clearingInstructions[0].transportMethod.method === 'SEA';
  }

  isRoadTransportDocument() {
    return this.clearingFile.clearingInstructions[0].transportMethod.method === 'ROA';
  }

  ngOnDestroy(): void {
    if (this.transportDocumentSubscription) {
      this.transportDocumentSubscription.unsubscribe();
    }

    if (this.clearingFileSubscription) {
      this.clearingFileSubscription.unsubscribe();
    }

    if (this.containerSubscription) {
      this.containerSubscription.unsubscribe();
    }
  }

}
