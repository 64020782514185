<p-dialog header="Sundry"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1300"
          [minWidth]="200"
          [minY]="100"
          [baseZIndex]="10000"
          (onHide)="onClose()">
  <p-accordion #sundry>
    <p-accordionTab header="Value Based Charge" [selected]="true">
      <digi-value-based-charge [valueBasedCharges]="hasSundryCharge.valueBasedCharges"
                               [hasSundryCharge]="hasSundryCharge"></digi-value-based-charge>
    </p-accordionTab>
    <p-accordionTab header="Rate Based charge">
      <digi-rate-based-charge [rateBasedCharges]="hasSundryCharge.rateBasedCharges"
                              [hasSundryCharge]="hasSundryCharge"></digi-rate-based-charge>
    </p-accordionTab>
  </p-accordion>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()" label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
