<div class="ui-g-12">
  <p-toolbar>
    <div class="ui-toolbar-group-left ui-fluid ui-g-8">
      <div class="ui-g-4 ui-md-4">
        <div class="ui-inputgroup">
              <span class="ui-float-label">
                <p-autoComplete [(ngModel)]="voyageFile" field="voyageFileNo"
                                [suggestions]="filteredVoyageFiles"
                                (completeMethod)="searchVoyageFiles($event)"
                                [autoHighlight]="true"
                                (onSelect)="selectVoyageFile($event)"
                                minLength="2"
                                [inputStyle]="{color:'white'}">
            <ng-template let-option pTemplate="item">
              <div>{{option.voyageFileNo}}</div>
            </ng-template>
          </p-autoComplete>
          <label style="color: white">Voyage File Number</label>
              </span>
        </div>
      </div>
      <div class="ui-g-4 ui-md-4">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="ui-toolbar-group-right">
      <button *ngIf="showNewButton" pButton type="button" label="New" icon="fa fa-file" (click)="onCreateNew()"
              class="ui-button-secondary" [disabled]="disableButton"></button>
      <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="disableButton"
              (click)="onSave()"></button>
    </div>
  </p-toolbar>
</div>
