import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContainerSizeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllContainerSizeStartsWith(searchValue) {
    return this.http
      .get(`${this.config.apiEndpoint}api/containerSpecifications/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].containerSpecifications)
      );
  }
}
