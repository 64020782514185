<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Scanning">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-fluid ui-g-8">
              <div class="ui-g-4 ui-md-4">
                <div class="ui-inputgroup">
                  <p-autoComplete id="hawbNumber" [(ngModel)]="houseBill" field="hawbNumber"
                                  [suggestions]="filteredHouseBillNos"
                                  (completeMethod)="searchHouseBills($event.query)"
                                  minLength="2"
                                  [inputStyle]="{color:'white'}"
                                  placeholder="HAWB Number"
                  >
                  </p-autoComplete>
                </div>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="onDisplayProgressBar()" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <div class="ui-fluid">
              <div class="ui-g">
                <div class="ui-g-8">
                  <p-table [columns]="cols" [value]="houseBillsToUpdate" selectionMode="single"
                           columnResizeMode="expand"
                           [paginator]="true" [rows]="5">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns">{{col.header}}</th>
                        <th style="width: 4%">
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-houseBillToUpdate let-rowIndex="rowIndex">
                      <tr [pSelectableRow]="houseBill" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                        <td>{{houseBillToUpdate.houseBillNumber}}</td>
                        <td>{{houseBillToUpdate.currentStatus}}</td>
                        <td>{{houseBillToUpdate.importerName}}</td>
                        <td>{{houseBillToUpdate.fileNumber}}</td>
                        <td>
                          <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                                  (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <div class="ui-g-4">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <button [disabled]="onUpdateIsDisabled()" type="button" pButton (click)="updateHouseBills();"
                              icon="ui-icon-update" label="Update House Bills"></button>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                       <p-panel header="Status">
                    <p-dropdown [(ngModel)]="packageStatus"
                                [options]="packageTrackingStatuses"
                                [autoWidth]="false">
                    </p-dropdown>
                  </p-panel>
                    </span>
                  </div>
                  <div *ngIf="displayTotalHAWBsLabel()" class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-panel header="Total House Bills">
                         <label>{{ this.houseBillsToUpdate.length }}</label>
                      </p-panel>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>


