import {Component, OnDestroy, OnInit} from '@angular/core';
import {Container, TransportDocument} from '../../../models/file';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ContainerTypeService} from '../../../../digi-ocean/services/container-type.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {validate} from 'codelyzer/walkerFactory/walkerFn';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-container-type-section',
  templateUrl: './container-type-section.component.html',
  styleUrls: ['./container-type-section.component.scss']
})
export class ContainerTypeSectionComponent implements OnInit, OnDestroy {
  selectedTransportDocument: TransportDocument;
  filteredContainerTypes = [];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private containerTypeService: ContainerTypeService,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => this.selectedContainer = ct);
  }

  search(code) {
    this.containerTypeService
      .findAllContainerTypeStartsWith(code.query)
      .subscribe(data => {
        this.filteredContainerTypes = data;
      });
  }

  select(value) {
    this.selectedContainer.containerType = value;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
