import {Component, Input, OnInit} from '@angular/core';
import {TariffCode} from '../../../models/file';

@Component({
  selector: 'digi-tariff-history',
  templateUrl: './tariff-history.component.html',
  styleUrls: ['./tariff-history.component.scss']
})
export class TariffHistoryComponent implements OnInit {
  cols: any[];
  @Input() tariffCode: TariffCode;

  constructor() { }

  ngOnInit() {
    this.cols = [
      {field: {id: 'tradeGroupCode'}, header: 'Trade Agreement', width: '15%'},
      {field: 'number', header: 'Statistical UOM', width: '10%'},
      {field: 'number', header: 'Reference Code', width: '15%'},
      {field: {id: 'formulaEffDate'}, header: 'Effective Date', width: '15%'},
      {field: 'effectiveUntil', header: 'Effective Until Date', width: '15%'},
      {field: 'description', header: 'Formula Description', width: '30%'}
    ];
  }

}
