import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Company} from '../models/company';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getCompany(): Observable<Company[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/company`).pipe(
      map(response => response['_embedded'].company)
    );
  }

  save(company: Company): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (company._links && company._links.self) {
      return this.http.put(company._links.self.href, JSON.stringify(company), {headers});
    } else {
      return this.http.post(`${this.config.filesApiEndpoint}api/company`, JSON.stringify(company), {headers});
    }
  }
}
