import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this.loadingSubject = new Subject();
    }
    LoadingService.prototype.addLoader = function (loader) {
        this.loadingSubject.next(loader);
    };
    LoadingService.prototype.getLoader = function () {
        var loader = this.loadingSubject.asObservable();
        // @ts-ignore
        return loader;
    };
    LoadingService.ngInjectableDef = i0.defineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
export { LoadingService };
