<div class="ui-g ui-fluid">
  <div class="ui-g-3 pt-2">
  </div>
  <div class="ui-g-2 pt-2">
    <span class="ui-inputgroup-addon">Value</span>
  </div>
  <div class="ui-g-2 pt-2">
    <span class="ui-inputgroup-addon">Packs</span>
  </div>
  <div class="ui-g-2 pt-2">
    <span class="ui-inputgroup-addon">Volume</span>
  </div>
  <div class="ui-g-3 pt-2">
    <span class="ui-inputgroup-addon">Gross Weight (KG)</span>
  </div>
  <div class="ui-g-3 pt-2">
    <span class="ui-inputgroup-addon">Total</span>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument?.measures?.noOfContainers}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument?.measures?.numberOfPackages}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument?.measures?.volume}}" readonly>
  </div>
  <div class="ui-g-3 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument?.measures?.grossWeight}}" readonly>
  </div>
  <div class="ui-g-3 pt-2">
    <span class="ui-inputgroup-addon">Captured</span>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.capturedValue}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.capturedNoOfPackages}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.capturedVolume}}" readonly>
  </div>
  <div class="ui-g-3 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.capturedGrossWeight}}" readonly>
  </div>
  <div class="ui-g-3 pt-2">
    <span class="ui-inputgroup-addon">Balance</span>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.remainingValue}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.remainingNoOfPackages}}" readonly>
  </div>
  <div class="ui-g-2 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.remainingVolume}}" readonly>
  </div>
  <div class="ui-g-3 pt-2">
    <input type="text" pInputText value="{{selectedTransportDocument.containerRunningTotals?.remainingGrossWeight}}" readonly>
  </div>
</div>
