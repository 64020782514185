<button pButton type="button" icon="pi pi-plus" title="Add Column" (click)="addValueBasedCharge()" style="left: 95%"></button>
<div class="ui-g">
  <div class="ui-g-5">
    <div class="ui-g-4"><span class="ui-inputgroup-addon">Charge</span></div>
    <div class="ui-g-4"><span class="ui-inputgroup-addon">Amount</span></div>
    <div class="ui-g-4"><span class="ui-inputgroup-addon">Currency</span></div>
  </div>
  <div class="ui-g-7">
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Collect</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Dutiable</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Inland Dutiable</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Inland Non Dutiable</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Freight</span></div>
    <div class="ui-g-2"><span class="ui-inputgroup-addon">Discount</span></div>
    <div class="ui-g-2"><span class="ui-inputgroup-addon">Insurance</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Non Dutiable</span></div>
    <div class="ui-g-1"><span class="ui-inputgroup-addon">Inland Dest. Country</span></div>
  </div>
</div>
<div class="ui-g ui-g-nopad ui-fluid" *ngFor="let valueBasedCharge of valueBasedCharges; let i=index">
  <div class="ui-g-5">
    <div class="ui-g-4">
      <digi-sundry-search [sundryCharge]="valueBasedCharges[i].sundryCharge"
                          [sundryChargeType]="1"></digi-sundry-search>
    </div>
    <div class="ui-g-2">
      <div class="ui-inputgroup pt-2">
        <input type="text" pInputText [(ngModel)]="valueBasedCharges[i].amount">
      </div>
    </div>
    <div class="ui-g-4">
      <digi-currency-nested [currency]="valueBasedCharges[i].currency"></digi-currency-nested>
    </div>
  </div>
  <div class="ui-g-7">
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].collect"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].dutiable"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].inlandDutiable"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].inlandNonDutiable"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].freight"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-2">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].discount"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-2">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].insurance"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].nonDutiable"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
        <p-checkbox binary="true" [(ngModel)]="valueBasedCharges[i].inlandDestinationCountry"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-1">
      <div class="ui-g-12">
      <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
              (click)="onRowDelete(i)" pTooltip="Delete"></button>
      </div>
    </div>
  </div>
</div>
