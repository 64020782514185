import { HttpClient } from '@angular/common/http';
import { DiagnosticTest } from './diagnostic-test.model';
import { AppConfig } from '../../app.config.module';
import { BusinessEntityService } from 'src/app/digi-business-entity/services/business-entity.service';
import { CurrencyService } from '../services/currency.service';
import { SendEdiToSarsService } from '../services/send-edi-to-sars.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "../../digi-business-entity/services/business-entity.service";
import * as i4 from "../services/currency.service";
import * as i5 from "../services/send-edi-to-sars.service";
var DiagnosticService = /** @class */ (function () {
    function DiagnosticService(http, config, businessEntityService, currencyService, sendEdiToSarsService) {
        this.http = http;
        this.config = config;
        this.businessEntityService = businessEntityService;
        this.currencyService = currencyService;
        this.sendEdiToSarsService = sendEdiToSarsService;
    }
    DiagnosticService.prototype.getDiagnosticServiceTests = function () {
        var diagnosticTests = [];
        diagnosticTests.push(new DiagnosticTest(1, 'Business Entity Config Validation', null));
        diagnosticTests.push(new DiagnosticTest(2, 'Auth API connectivity test', null));
        diagnosticTests.push(new DiagnosticTest(3, 'File REST API connectivity test', this.businessEntityService.findBusinessEntityStartsWith('')));
        diagnosticTests.push(new DiagnosticTest(4, 'Entity REST API connectivity test', this.currencyService.getExchangeRateTypes()));
        diagnosticTests.push(new DiagnosticTest(5, 'EDI REST API connectivity test', null));
        diagnosticTests.push(new DiagnosticTest(6, 'EDI end-to-end test [Check EDI responses on server /home/pipelines/inbox/]', this.sendEdiToSarsService.sendEdiTest()));
        return diagnosticTests;
    };
    DiagnosticService.ngInjectableDef = i0.defineInjectable({ factory: function DiagnosticService_Factory() { return new DiagnosticService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig), i0.inject(i3.BusinessEntityService), i0.inject(i4.CurrencyService), i0.inject(i5.SendEdiToSarsService)); }, token: DiagnosticService, providedIn: "root" });
    return DiagnosticService;
}());
export { DiagnosticService };
