import {Component, EventEmitter, Input, KeyValueDiffer, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BillOfEntryService} from '../services/bill-of-entry.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';
import {FileType} from '../models/enumerations';
import {ClearingFile} from '../models/clearingFile';
import {ImporterService} from '../services/importer.service';
import {AgentService} from '../services/agent.service';
import {BusinessEntity, CustomsTraderType, RoleAddress} from '../../digi-business-entity/models/business-entity';
import {OverlayPanel} from 'primeng/primeng';
import {ForwardingFile, ForwardingTransportDocument} from '../models/ForwardingFile';
import {ForwardingFileService} from '../services/forwarding-file.service';
import {
  Airline,
  File,
  Freight, LocalForwarder,
  Measures,
  PreviousBoeDetails,
  SupplierInvoice,
  SupplierInvoiceLine,
  TransportDocument, PortDetail, CustomsValue, DepotTerminal, ForwardFile, LocalAgent, Address, ClearingInstruction
} from '../models/file';
import {forkJoin, Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {SaveClearingFile} from '../saveClearingFile';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntry, Destination} from '../models/billOfEntries';
import {NotificationService} from '../services/notification.service';
import {BusinessEntityService} from '../../digi-business-entity/services/business-entity.service';
import {Branch} from '../models/company';
import {CompanyService} from '../services/company.service';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {HouseBill} from '../models/houseBill';
enum TransportMethods {
  SC = 'sea', SE = 'sea', AC = 'air', AE = 'air', RC = 'road', RE = 'road', TE = 'rail'
}

@Component({
  selector: 'digi-forwarding-dashboard',
  templateUrl: './forwarding-dashboard.component.html',
  styleUrls: ['./forwarding-dashboard.component.scss']
})
export class ForwardingDashboardComponent implements OnInit {
  @Input() transportMethod: string;
  @Input() billOfEntry: BillOfEntry;
  @Output() selectedClearingFile: EventEmitter<any> = new EventEmitter();
  @Output() selectedBusinessEntity = new EventEmitter();
  @ViewChild('correctionList') correctionList: OverlayPanel;
  @Input() file: File;
  filteredBusinessEntities = [];
  clearingFile: ClearingFile;
  forwardingFiles: ForwardingFile[];
  housebill: HouseBill;
  addresses: Address[];
  @Input() businessEntities;
  businessEntity: BusinessEntity;
  totalRecords: number;
  loading: boolean;
  fromDate: string;
  toDate: string;
  cols: any[];
  indicator: FileType;
  displaySearchModal: boolean;
  mawbNo: string;
  containerNo: string;
  private branchCode: any;
  subscription: Subscription;
  billSubscription: Subscription;
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;
  @Input() isNomination = true;
  clearingInstructionsSubscription: Subscription;
  selectedInstructions: ClearingInstruction[] = [];

  constructor(
    private localAgentService: AgentService,
    private importerService: ImporterService,
    private billOfEntryService: BillOfEntryService,
    private router: Router,
    private route: ActivatedRoute,
    private authorisationService: AuthorisationService,
    private forwardingFileService: ForwardingFileService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private onSaveClearingFile: SaveClearingFile,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private messageService: NotificationService,
    private businessEntityService: BusinessEntityService,
    private companyService: CompanyService,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.indicator = this.route.snapshot.paramMap.get('indicator') as FileType;
        this.loadBillOfEntries();
      }
    });
  }

  ngOnInit() {
    this.cols = [
      {field: 'fileNumber', header: 'FILE No.', width: '10%'},
      {field: 'masterBill.mawbNo', header: 'MAWB No.', width: '10%'},
      {field: 'houseBills.hawbNo', header: 'HAWB No.', width: '10%'},
      {field: 'houseBills.customsValue.localAmount', header: 'CUSTOMS VALUE', width: '10%'},
      {field: 'cusres.customsStatus', header: 'STATUS', width: '10%'},
      {field: 'houseBills.clearingFileNumber', header: 'CLEARING FILE No', width: '10%'},
    ];
    this.subscription = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
          if (this.clearingInstructionsSubscription) {
            this.clearingInstructionsSubscription.unsubscribe();
          }
          this.initializeClearingInstructions();
          this.clearingInstructionsSubscription = this.clearingInstructionBehaviorSubject
            .getClearingInstructions()
            .subscribe(ci => {

              ci = this.clearingFile.clearingInstructions;
              if (ci) {
                this.selectedInstructions = ci;
              }
            });
        }
      });

    this.billSubscription = this.billOfEntryBehaviourSubject.getBillOfEntry()
      .subscribe(boe => this.billOfEntry = boe);

    this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));

    this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);


    this.forwardingFileService.getTotalForwardingFilesCount().subscribe(
      (number) => {
        this.totalRecords = number;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        console.error('Error fetching total count:', err);
      });

  }

  onLazyLoad(event: LazyLoadEvent) {
    this.authorisationService.getLoggedInUser().subscribe((user) => {
      if (user) {
        this.loading = true;
        const pageNumber = event.first / event.rows;
        if (user.attributes && user.attributes['branch']) {
          this.branchCode = user.attributes['branch'][0];
        }

    this.forwardingFileService.getAllForwardingFiles(pageNumber, event.rows).subscribe(
      (forwardingFiles) => {
        this.forwardingFiles = forwardingFiles;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        console.error('Error fetching forwarding files:', err);
      });
      }
    });
  }

  loadBillOfEntries() {
    this.onLazyLoad({first: 0, rows: 2});
  }

  openSearchCriteriaModal() {
    this.displaySearchModal = true;
  }

  onForwardingFileNumberClick(fileNumber: string, hawbNo: string) {
    const forwardingFile$ = this.forwardingFileService.findByForwardingFileNo(fileNumber);
    const houseBill$ = this.forwardingFileService.getHouseBillByHouseBillNumber(hawbNo);

   houseBill$.subscribe(houseBil => {
     if (houseBil.clearingFileNumber) {
       if (fileNumber.startsWith('AF')) {
         this.router.navigate(['air', 'register', houseBil.clearingFileNumber, 0]);
       }
     } else {
       if (fileNumber.startsWith('AF')) {
         this.router.navigate(['/air/register', 'nq']);
       } else if (fileNumber.startsWith('SF')) {
         this.router.navigate(['/sea/register', 'nq']);
       }


       forkJoin([forwardingFile$, houseBill$]).subscribe(
         ([forwardingFile, houseBill]) => {


           if (forwardingFile) {
             // REGISTRATION -> CLEARING FILE
             if (fileNumber.startsWith('AF')) {
               this.clearingFile.customOffice.districtOfficeCode = 'JSA';
               this.clearingFile.customOffice.districtOfficeName = 'JSA - Customs Office JOHANNESBURG INT AIRPORT';
             }
             this.clearingFile.fromForwarding = true;

             // TRANSPORT DOCUMENTS
             const td = new TransportDocument();
             td.airline = forwardingFile.masterBill.airline || new Airline();
             td.localForwarder = forwardingFile.masterBill.localForwarder || new LocalForwarder();
             td.mawbNo = forwardingFile.masterBill.mawbNo || '';
             td.mawbDate = forwardingFile.masterBill.mawbDate || '';
             td.hawbDate = forwardingFile.masterBill.mawbDate || '';
             td.flight = forwardingFile.masterBill.flight || '';
             td.departurePort = forwardingFile.masterBill.departurePort || new PortDetail();
             td.destinationPort = forwardingFile.masterBill.destinationPort;
             td.docsIssuedAtPort = forwardingFile.masterBill.docsIssuedAtPort || new PortDetail();
             td.dischargePort = forwardingFile.masterBill.dischargePort || new PortDetail();
             td.freight = forwardingFile.masterBill.freight || new Freight();
             td.customsValue = forwardingFile.masterBill.customsValue || new CustomsValue();
             td.subMAWBNo = forwardingFile.masterBill.subMAWBNo || '';
             td.clientRefNo = forwardingFile.masterBill.clientRefNo || '';
             td.arrivalDate = forwardingFile.masterBill.arrivalDate || '';
             td.depotTerminal = forwardingFile.masterBill.depotTerminal || new DepotTerminal();
             td.forwardFile = forwardingFile.forwardFile || new ForwardFile();
             td.forwardFile.forwardingFileNumber = fileNumber || '';
             td.destinationPort = forwardingFile.masterBill.destinationPort || new PortDetail();
             td.finalDestinationPort = forwardingFile.masterBill.finalDestinationPort || new PortDetail();
             td.straight = typeof forwardingFile.masterBill.straight === 'boolean' ? forwardingFile.masterBill.straight : false;
             td.marksAndNumbers = forwardingFile.masterBill.marksAndNumbers || [];
             td.measures = forwardingFile.masterBill.measures || new Measures();
             td.endorsements = forwardingFile.masterBill.endorsements || [];
             td.departureDate = forwardingFile.masterBill.departureDate || '';
             td.rateBasedCharges = [];
             td.valueBasedCharges = [];
             td.containers = [];
             td.previousBOEDetails = new PreviousBoeDetails();
             td.measures.numberOfPackages = forwardingFile.masterBill.measures.numberOfPackages || 0;
             td.measures.grossWeight = forwardingFile.masterBill.measures.grossWeight || 0;
             td.freight.currency = forwardingFile.masterBill.freight.currency || '';
             td.freight.collectPrepaid = forwardingFile.masterBill.freight.collectPrepaid || '';
             td.freight.amount = forwardingFile.masterBill.freight.amount || 0;
             td.depotTerminal = forwardingFile.masterBill.depot || new DepotTerminal();

             this.clearingFile.transportDocuments.push(td);
           }

           if (houseBill) {
             if (this.clearingFile.transportDocuments.length === 0) {
               this.clearingFile.transportDocuments.push({supplierInvoices: []});
             }
             if (!this.clearingFile.transportDocuments[0].supplierInvoices) {
               this.clearingFile.transportDocuments[0].supplierInvoices = [];
             }
             if (this.clearingFile.transportDocuments[0].supplierInvoices.length === 0) {
               const newSupplierInvoice: SupplierInvoice = {
                 quantity: houseBill.grossWeight || 0,
                 valueBasedCharges: [],
                 userInterfaceDefaults: {},
                 invoiceDate: houseBill.invoiceDate || '',
                 invoiceNumber: houseBill.invoiceNumber || '',
                 totalGrossIncomeValue: houseBill.localAmount || 0,
                 sequence: 0,
                 runningTotals: {},
                 apportionWeightOnLines: false,
                 manualExWarehouseLineCapture: false,
                 supplier: houseBill.supplier || {},
                 currency: forwardingFile.masterBill.freight.currency || {},
                 rateBasedCharges: [],
                 lines: [
                   {
                     lineNo: 1,
                     description: '',
                     fullDescription: '',
                     excludeFromCosting: false,
                     costingUom: {},
                     countryOfOrigin: {
                       code: 'ZA ',
                       name: 'SOUTH AFRICA',
                       defaultTradeAgreement: {
                         code: 'SADC      ',
                         description: 'SADC Trade Agreement 2000                         '
                       },
                       tradeAgreements: [
                         {
                           code: 'SADC      ',
                           description: 'SADC Trade Agreement 2000                         '
                         },
                         {
                           code: 'STANDARD  ',
                           description: 'No Trade Agreement - Standard Tariffs             '
                         }
                       ]
                     },
                     lineQuantity: houseBill.numberOfPackages,
                     lineWeight: houseBill.grossWeight,
                     partNumber: '',
                     tariffCode: {
                       parameters: [],
                       formula: {
                         statisticalUOM: '',
                         description: '',
                         effectiveDate: '',
                         computations: [],
                         rebate: false,
                         parameters: [],
                         hasDcc: false,
                         number: 0,
                         rebateExcludes12B: false,
                         chargeString: '',
                         prodatFormulaNo: '',
                         free: false,
                         hasRcc: false,
                         noOfParameters: 0,
                       },
                       checkDigit: 0,
                       fullDescription: '',
                       tariffSDesc: '',
                       code: '',
                       description: '',
                       schedule: '',
                       tariff: false,
                       tariffID: 0,
                       dependantCode: '',
                       dependantCheckDigit: '',
                       schedulePart: {},
                       scheduleDescription: '',
                       calculatedValue: 0,
                     },
                     noChargeItem: false,
                     repair: false,
                     usedGoods: false,
                     da59: false,
                     customsVAT: true,
                     used: false,
                     invoiceGrossValue: houseBill.localAmount,
                     invoiceValue: houseBill.localAmount,
                     discountPercent: 0,
                     discountValue: 0,
                     customsValue: houseBill.localAmount,
                     tradeAgreement: {
                       code: 'STANDARD  ',
                       description: 'No Trade Agreement - Standard Tariffs             '
                     },
                     globalRebates: [],
                     additionalScheduleTariffs: [],
                     userInterfaceCheckbox: {
                       tariffMode: false,
                       quickPart: false
                     },
                     instructionIndex: 0,
                     instructionUUID: this.createClearingInstruction_UUID(),
                     certificate: {},
                     additionalUoms: [],
                     noOfPacks: 0,
                     itemsPerPack: 0,
                     previousBoeDetails: {
                       sequenceNo: 0,
                       lineNo: 0,
                       noOfPacks: 0
                     }
                   }
                 ],
                 ucrNumber: '',
                 ucrCreditTerms: '',
                 certificate: {},
                 ucr: {},
                 defaultValues: {
                   country: {
                     code: 'ZA',
                     name: 'SOUTH AFRICA',
                     defaultTradeAgreement: {
                       code: 'SADC',
                       description: 'SADC Trade Agreement 2000',
                     },
                     tradeAgreements: [
                       {
                         code: 'SADC',
                         description: 'SADC Trade Agreement 2000',
                       },
                       {
                         code: 'STANDARD',
                         description: 'No Trade Agreement - Standard Tariffs',
                       },
                     ],
                   },
                   purchaseTerm: {
                     code: 'FOB',
                     description: 'Free on Board',
                   },
                 },
               };
               this.clearingFile.transportDocuments[0].supplierInvoices.push(newSupplierInvoice);
             }
             // REGISTRATION
             this.clearingFile.localAgent = houseBill.localAgent;
             this.clearingFile.localAgent.customsRegCode = houseBill.localAgent.customsRegCode || '';
             this.clearingFile.importer = houseBill.importer;
             this.clearingFile.importer.customsCode = houseBill.importer.customsCode;
             this.clearingFile.importer.registrationType = houseBill.importer.registrationType || new CustomsTraderType();
             this.clearingFile.importer.idNumber = houseBill.importer.idNumber || '';
             this.clearingFile.importer.passportNumber = houseBill.importer.passportNumber || '';

             const ftd = this.clearingFile.transportDocuments[0];
             ftd.hawbNo = houseBill.houseBillNumber;
           }

           this.businessEntityService.findBusinessEntityStartsWith(houseBill.importer.name).subscribe(bee => {
             if (houseBill.importer.name === bee[0].name) {
               this.messageService.errorNotify('Importer already exists.Please check the business entities');
             }
           });


           // create importer
           const be = new BusinessEntity();
           this.businessEntity = be;
           this.businessEntity.registeredName = houseBill.importer.name;
           this.businessEntity.name = houseBill.importer.name;
           const role = {roleType: 6};
           this.businessEntity.roles = [];
           this.businessEntity.roles.push(role);
           this.businessEntity.roles = [...this.businessEntity.roles];
           const address = Object.assign({}, houseBill.importer.address);
           this.businessEntityService.saveBusinessEntity(this.businessEntity)
             .subscribe(bee => {
               this.businessEntity = bee;
               if (address && address.line1) {
                 this.businessEntity.addresses = [];
                 this.businessEntity.addresses.push(address);
                 this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(updatedBE => {
                   this.businessEntity = updatedBE;
                   const beRole = this.businessEntity.roles.find(el => el.roleType === 6);
                   const roleAddress = new RoleAddress();
                   roleAddress.address = this.businessEntity.addresses[0];
                   roleAddress.addressType = {id: 'CUS', description: 'Customs Address'};
                   beRole.roleAddresses = [roleAddress];
                   this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(beWithRoleAddress => {
                     this.businessEntity = beWithRoleAddress;
                     this.clearingFile.importer.code = this.businessEntity.code;
                     this.onSaveClearingFile.saveClearingFile(
                       this.clearingFile,
                       this.selectedTransportDocument,
                       this.selectedSupplierInvoice,
                       this.selectedSupplierInvoiceLine,
                     ).subscribe(
                       value => {
                         this.messageService.successNotify('Importer Saved');
                       });
                     this.select(this.businessEntity);
                   });
                 });
               }
             }, error => {
               this.messageService.errorNotify(error.error.message, [error.error.errors]);
             });

           // create supplier
           const be1 = new BusinessEntity();
           this.businessEntity = be1;
           this.businessEntity.registeredName = houseBill.supplier.name;
           this.businessEntity.name = houseBill.supplier.name;
           const role1 = {roleType: 21};
           this.businessEntity.roles = [];
           this.businessEntity.roles.push(role1);
           this.businessEntity.roles = [...this.businessEntity.roles];
           const address1 = Object.assign({}, houseBill.supplier.address);
           this.businessEntityService.saveBusinessEntity(this.businessEntity)
             .subscribe(bee => {
               this.businessEntity = bee;
               if (address1 && address1.line1) {
                 this.businessEntity.addresses = [];
                 this.businessEntity.addresses.push(address1);
                 this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(updatedBE => {
                   this.businessEntity = updatedBE;
                   const beRole = this.businessEntity.roles.find(el => el.roleType === 21);
                   const roleAddress = new RoleAddress();
                   roleAddress.address = this.businessEntity.addresses[0];
                   roleAddress.addressType = {id: 'CUS', description: 'Customs Address'};
                   beRole.roleAddresses = [roleAddress];
                   this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(beWithRoleAddress => {
                     this.businessEntity = beWithRoleAddress;
                     this.clearingFile.transportDocuments[0].supplierInvoices[0].supplier.code = this.businessEntity.code;
                     this.onSaveClearingFile.saveClearingFile(
                       this.clearingFile,
                       this.selectedTransportDocument,
                       this.selectedSupplierInvoice,
                       this.selectedSupplierInvoiceLine,
                     ).subscribe(
                       value => {
                         this.messageService.successNotify('Supplier Saved');
                       });
                     this.select(this.businessEntity);
                   });
                 });
               }
             }, error => {
               this.messageService.errorNotify(error.error.message, [error.error.errors]);
             });

           this.onSaveClearingFile.saveClearingFile(
             this.clearingFile,
             this.selectedTransportDocument,
             this.selectedSupplierInvoice,
             this.selectedSupplierInvoiceLine,
           ).subscribe(
             value => {
               this.saveConfirmation();
               forwardingFile.houseBills.forEach(hb => {
                 if (hb.hawbNo === houseBill.houseBillNumber) {
                   hb.clearingFileNumber = this.clearingFile.clearingFileNumber;
                   houseBill.clearingFileNumber = this.clearingFile.clearingFileNumber;
                 }
               });
               this.forwardingFileService.save(forwardingFile).subscribe(fd => {
                   this.saveConfirmation();
                 }
               );
             }
           );
         }
       );
     }
   });
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  initializeClearingInstructions() {
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.defaultClearingInstruction) {
          this.clearingFile.clearingInstructions[0].procedureCategoryCode = 'A';
          // tslint:disable-next-line:max-line-length
          this.clearingFile.clearingInstructions[0].pcDescription = 'Home use (including goods intended for home use for which there is a relief of duties and taxes).';

          this.clearingFile.clearingInstructions[0].customsProcedureCode = 11;
          // tslint:disable-next-line:max-line-length
          this.clearingFile.clearingInstructions[0].customsProcDesc = 'Clearance of goods for Home Use, and free circulation. [Note: includes imported goods, exempt from VAT).';
          // Initialize customsPurpose
          this.clearingFile.clearingInstructions[0].customsPurpose = {
            customsPurposeCode: 'DP',
            customsPurposeName: 'Duty Paid',
            customsPurposeFile: {
              fileTypeId: 2,
              documentCode: 'DA500',
              vocDocumentCode: 'DA504'
            }
          };
          this.clearingFile.clearingInstructions[0].destination = {
            code: 'ZA',
            name: 'South Africa',
            label: 'ZA : South Africa'
          };
          this.clearingFile.clearingInstructions[0].customVatRequired = true;
          this.clearingFile.clearingInstructions[0].consolidateBoeLines = false;
          this.clearingFile.clearingInstructions[0].transportMethod = {
            method: 'AIR',
            code: '4',
            description: 'AIR'
          };
          this.clearingFile.clearingInstructions[0].customVatRequired = true;
          this.clearingFile.clearingInstructions[0].uuid = this.createClearingInstruction_UUID();

          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.clearingFile.clearingInstructions);
        }
      }
    });
  }


  private createClearingInstruction_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  select(value) {
    this.businessEntity = value;
  }
}
