<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Register" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-clearing-file-section step="registration" transportMethod="ROA" (display)="openSequenceModal($event)" (add)="createNewFile()" [canAdd]="true">
          </digi-clearing-file-section>
        </div>
        <!--/Clearing Form -->
        <digi-correction [fileType]="'imports'" *ngIf="isCorrection()"></digi-correction>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Importer Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-importer></digi-importer>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-panel header="Debtor Details">
                <digi-importer-debtors></digi-importer-debtors>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-panel header="Clearing Agent  Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-clearing-agent [clearingAgent]="clearingFile.localAgent" (onUpdateLocalAgent)="updateLocalAgent($event)"></digi-clearing-agent>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g">
                <div class="ui-g-12 ui-md-12">
                  <p-panel header="To Customs Office">
                    <div class="ui-fluid">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <div class="ui-g form-group">
                            <div class="ui-g-12 ui-md-12">
                              <digi-customs-office [customOffice]="clearingFile.customOffice" [isRequired]=true (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-12 ui-md-12">
                  <p-panel header="File Inco Term">
                    <div class="ui-fluid">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <div class="ui-g form-group">
                            <div class="ui-g-12 ui-md-12">
                              <digi-file-inco-term [clearingFile]="clearingFile"></digi-file-inco-term>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g">
                <div class="ui-g-12 ui-g-nopad">
                  <digi-registration-references-section
                    [file]="clearingFile"></digi-registration-references-section>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-g-12 ui-md-12">
                <p-panel header="Assessment & SLA Date / Promised Date">
                  <div class="ui-g ui-fluid">
                    <div class="ui-g-12 ui-md-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                          <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="Assessment Date"
                                       [(ngModel)]="clearingFile.assessmentDate"></p-inputMask>
                          <label>Assessment Date</label>
                        </span>
                    </div>
                    <div class="ui-g-12 ui-md-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="SLA Date/Promised Date "
                                     [(ngModel)]="clearingFile.slaDate"></p-inputMask>
                        <label>SLA Date/Promised Date</label>
                      </span>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-12 ui-md-12">
                <p-panel header="Shipping Description">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="clearingFile.shippingDescription" [rows]="3" [cols]="50"
                                    placeholder="Your Shipping Description"
                                    style="resize: none">
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-12">
              <p-pickList [source]="availableInstructions" [target]="selectedInstructions"
                          sourceHeader="Available Instruction" targetHeader="Selected Instruction" [responsive]="true"
                          [showSourceControls]="false" [showTargetControls]="false"
                          (onMoveToTarget)="selectClearingOption($event)"
                          (onMoveToSource)="onMoveToSource($event)"
                          [dragdrop]="true" dragdropScope="instructions"
                          (onTargetSelect)="selectedClearingInstruction($event)"
              >
                <ng-template let-instruction pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <div>
                      {{instruction.customsPurpose.customsPurposeCode}} :
                      {{instruction.customsPurpose.customsPurposeName}}
                    </div>
                  </div>
                </ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Planning">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-planning></digi-planning>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Documents">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-documents></digi-documents>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<digi-clearing-instruction-modal [display]="display"
                                 [rebateUsers]="rebateUsers"
                                 (displayChange)="onDialogClose($event)"
                                 (onCloseCustomProcedureModal)="onCloseCustomProcedureModal($event)"
                                 [customsProcedures]="customsProcedures"
                                 [showCustomsProcedures]="showCustomsProcedures"
                                 [rebateUserAddresses]="rebateUserAddresses"
                                 [fileType]="'imports'">

</digi-clearing-instruction-modal>

<digi-sequence-modal
  [fileType]="'imports'"
  [display]="openSequence"
  (displayChange)="onSequenceClose($event)">
</digi-sequence-modal>
