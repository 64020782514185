<!-- Refactor this HTML so that is can be properly be reused -->
<div class="ui-g form-group" *ngIf="withAddress">
    <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
         <p-autoComplete id="clearingAgent"
                         [(ngModel)]="selectedClearingAgent"
                         field="label"
                         [suggestions]="filteredAgents"
                         (completeMethod)="search($event)"
                         [autoHighlight]="true"
                         (onSelect)="select($event)"
                         minLength="3"
                         required=true
         >
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>
        <label for="clearingAgent">{{labelAgent}}</label>
      </span>
        <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="!clearingFile.quotation && !clearingFile?.localAgent?.code" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
</div>
</div>
<div *ngIf="withAddress">
    <digi-address [addresses]="localAgentAddresses" [address]="clearingAgent.address"></digi-address>
</div>
<div class="ui-g-12 ui-md-12" *ngIf="!withAddress">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
       <span class="ui-float-label">
            <p-autoComplete [ngModel]="selectedClearingAgent" field="label" [suggestions]="filteredAgents"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true" (onSelect)="select($event)" minLength="3"
                            required=true>
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}: {{option.name}}</div>
              </ng-template>
            </p-autoComplete>
            <label for="clearingAgent">{{labelAgent}}</label>
       </span>
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
</div>
<div *ngIf="!selectedClearingAgent?.code" class="ui-message ui-messages-error ui-corner-all">
    This field is required
</div>
</span>
</div>
