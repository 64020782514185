import {Address, LocalAgent, TransportMethod} from './file';
import {SupplierInvoiceLine, ChargeType} from './financials';

export class Company {
  name: string;
  code: string;
  logo?: string;
  companyDefaults: CompanyDefaults;
  branches: Branch[];
  [k: string]: any;
}

class GLPeriodDetails {
  period: string;
  description: string;
  finYearMonth: string;
  startDate: string;
  endDate: string;
  invoicingPeriodOpen: boolean;
  receiptPeriodOpen: boolean;
  creditorPeriodOpen: boolean;
  recPeriodOpen: boolean;
}

class GLPeriods {
  glYear: string;
  periods: GLPeriodDetails[];
}

export class BankingDetails {
  bank: string;
  branchCode: string;
  accountNo: string;
  swiftCode: string;
}

export class GenLedgerAccDetails {
  chargeType?: ChargeType;
  genLedgerAccNo: string;
}

export class InvoiceDetails {
  statementHeader: string;
  invoiceHeader: string;
  regNo: string;
  vatNo: string;
  physicalAddress: Address;
  postalAddress: Address;
  email: string;
  fax: string;
  tel: string;
  bankingDetails: BankingDetails[];
  genLedgerAccDetails: GenLedgerAccDetails[];
}

export class Branch {
  invoiceDetails: InvoiceDetails;
  glPeriods: GLPeriods[];
  name: string;
  code: string;
  localAgentCode?: string;
  forwardingLocalAgentCode?: string;
  exporterCode?: string;
  importerCode?: string;
  exitPortCode?: string;
  landingOrderAccountNo?: string;
  shippingOrderAccountNo?: string;
  freightCurrency?: string;
  freightAmount?: number;
  packageType?: string;
  freightCollectPrepaid?: string;
  invoicingInUse?: boolean;
  defaultSundryChargeNonDutiable?: boolean;
  defaultQuantityTypeDisplay?: boolean;
  customsValueInUse?: boolean;
  defaultClearingInstruction?: boolean;
  customsOfficeDefaults?: CustomsOfficeDefaults[];
  invoiceLines?: SupplierInvoiceLine[];
  emailForEdi?: string;
  companyWebsite?: string;
}

export class CustomsOfficeDefaults {
  transportMethod: TransportMethod ;
  declarationType: string;
  customsOfficeCode: string;
}

export class CompanyDefaults {
  atv: number;
  vat: number;
}
