/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guarantor-role.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i3 from "primeng/components/panel/panel";
import * as i4 from "primeng/components/inputtext/inputtext";
import * as i5 from "@angular/forms";
import * as i6 from "../../../digi-common/components/guarantee-type/guarantee-type.component.ngfactory";
import * as i7 from "../../../digi-common/components/guarantee-type/guarantee-type.component";
import * as i8 from "../../../digi-common/services/guaratee-type.service";
import * as i9 from "./guarantor-role.component";
var styles_GuarantorRoleComponent = [i0.styles];
var RenderType_GuarantorRoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuarantorRoleComponent, data: {} });
export { RenderType_GuarantorRoleComponent as RenderType_GuarantorRoleComponent };
export function View_GuarantorRoleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "p-panel", [], null, null, null, i2.View_Panel_0, i2.RenderType_Panel)), i1.ɵdid(1, 49152, null, 1, i3.Panel, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { footerFacet: 0 }), (_l()(), i1.ɵeld(3, 0, null, 1, 10, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [["class", "md-inputfield"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "input", [["pInputText", ""], ["readonly", ""], ["type", "text"]], [[8, "value", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i5.NgModel]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Guarantor Code"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "digi-app-guarantee-type", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectGuarantor($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_GuaranteeTypeComponent_0, i6.RenderType_GuaranteeTypeComponent)), i1.ɵdid(13, 638976, null, 0, i7.GuaranteeTypeComponent, [i8.GuarateeTypeService], { guaranteeType: [0, "guaranteeType"] }, { onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_6 = ((_co.guarantor == null) ? null : _co.guarantor.guaranteeType); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.businessEntity.code, ""); var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 8).filled; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_GuarantorRoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-app-guarantor-role", [], null, null, null, View_GuarantorRoleComponent_0, RenderType_GuarantorRoleComponent)), i1.ɵdid(1, 114688, null, 0, i9.GuarantorRoleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuarantorRoleComponentNgFactory = i1.ɵccf("digi-app-guarantor-role", i9.GuarantorRoleComponent, View_GuarantorRoleComponent_Host_0, { businessEntity: "businessEntity", guarantor: "guarantor" }, {}, []);
export { GuarantorRoleComponentNgFactory as GuarantorRoleComponentNgFactory };
