export declare type FileType = 'exports' | 'imports' | 'exbond' | 'forwarding';
export declare type Step = 'registration' | 'transportDocument' | 'containers' | 'supplierInvoice' | 'supplierInvoiceLines' | 'manifests';
export declare type ShipmentType = 'STRAIGHT' | 'BACK_TO_BACK' | 'CONSOLIDATION';

export enum Gender {
  MALE = 'M',
  FEMALE = 'F'
}

export enum Occupation {
  TRADE = 'G',
  OTHER = 'H'
}

export enum ReasonForMovement {
  WORK = 'D',
  TRANSIT= 'E'
}

export enum TravelDocumentType {
  PASSPORT = 'A',
  TEMPORARY_PERMIT = 'D',
  TRAVEL_DOCUMENT = 'E',
  EMERGENCY_TRAVEL_CERTIFICATE = 'F',
  DEPARTURE_PERMIT = 'L',
  DOCUMENT_FOR_TRAVEL_PURPOSES = 'M',
  PROVISIONAL_PERMIT = 'N',
  COMPUTER_READABLE_PASSPORT = 'N'
}

export enum TravelerType {
  NORMAL_SOUTH_AFRICAN = 'SVR',
  FOREIGN_CONTRACT_WORKER = 'VBK',
  NORMAL_FOREIGNER = 'VBV',
  PERMANENT_RESIDENCE_HOLDER = 'VVP'
}
