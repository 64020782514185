import { BehaviorSubject, Subject } from 'rxjs';
import { Certificate, Country, Currency, Supplier, SupplierInvoice } from '../app/digi-common/models/file';
import * as i0 from "@angular/core";
var SupplierInvoiceBehaviourSubject = /** @class */ (function () {
    function SupplierInvoiceBehaviourSubject() {
        this.supplierInvoiceSubject = new BehaviorSubject(null);
        this.countryOfOriginSubject = new BehaviorSubject(null);
        this.currencySubject = new BehaviorSubject(null);
        this.suppliersSubject = new Subject();
        this.certificateSubject = new Subject();
    }
    SupplierInvoiceBehaviourSubject.prototype.addSupplierInvoice = function (supplierInvoice) {
        if (supplierInvoice && supplierInvoice.lines != null) {
            supplierInvoice.lines.forEach(function (supplierInvoiceLine) {
                if (supplierInvoiceLine.invoiceGrossValue && !supplierInvoiceLine.customsValue) {
                    supplierInvoiceLine.discountValue =
                        supplierInvoiceLine.invoiceGrossValue *
                            (supplierInvoiceLine.discountPercent / 100);
                    supplierInvoiceLine.invoiceValue =
                        supplierInvoiceLine.invoiceGrossValue -
                            supplierInvoiceLine.discountValue;
                    supplierInvoiceLine.customsValue = supplierInvoiceLine.invoiceValue;
                    if (supplierInvoiceLine.vdnPercent > 0) {
                        var vdnPercentDiscountedValue = supplierInvoiceLine.invoiceGrossValue *
                            (supplierInvoiceLine.vdnPercent / 100);
                        supplierInvoiceLine.invoiceValue += vdnPercentDiscountedValue;
                        supplierInvoiceLine.customsValue = supplierInvoiceLine.invoiceValue;
                    }
                }
            });
        }
        this.supplierInvoiceSubject.next(supplierInvoice);
    };
    SupplierInvoiceBehaviourSubject.prototype.getSupplierInvoice = function () {
        return this.supplierInvoiceSubject.asObservable();
    };
    SupplierInvoiceBehaviourSubject.prototype.addCountry = function (countryOfOrigin) {
        this.countryOfOriginSubject.next(countryOfOrigin);
    };
    SupplierInvoiceBehaviourSubject.prototype.getCountry = function () {
        return this.countryOfOriginSubject.asObservable();
    };
    SupplierInvoiceBehaviourSubject.prototype.addCurrency = function (currency) {
        this.currencySubject.next(currency);
    };
    SupplierInvoiceBehaviourSubject.prototype.getCurrency = function () {
        return this.currencySubject.asObservable();
    };
    SupplierInvoiceBehaviourSubject.prototype.addSuppliers = function (suppliers) {
        this.suppliersSubject.next(suppliers);
    };
    SupplierInvoiceBehaviourSubject.prototype.getSuppliers = function () {
        return this.suppliersSubject.asObservable();
    };
    SupplierInvoiceBehaviourSubject.prototype.addCertificate = function (certificate) {
        this.certificateSubject.next(certificate);
    };
    SupplierInvoiceBehaviourSubject.prototype.getCertificate = function () {
        return this.certificateSubject;
    };
    SupplierInvoiceBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function SupplierInvoiceBehaviourSubject_Factory() { return new SupplierInvoiceBehaviourSubject(); }, token: SupplierInvoiceBehaviourSubject, providedIn: "root" });
    return SupplierInvoiceBehaviourSubject;
}());
export { SupplierInvoiceBehaviourSubject };
