import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var UniqueConsignmentBehaviourSubject = /** @class */ (function () {
    function UniqueConsignmentBehaviourSubject() {
        this.uniqueConsignmentReferenceSubject = new BehaviorSubject(null);
    }
    UniqueConsignmentBehaviourSubject.prototype.addUniqueConsignmentReference = function (uniqueConsignmentReference) {
        this.uniqueConsignmentReferenceSubject.next(uniqueConsignmentReference);
    };
    UniqueConsignmentBehaviourSubject.prototype.getUniqueConsignmentReference = function () {
        return this.uniqueConsignmentReferenceSubject.asObservable();
    };
    UniqueConsignmentBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function UniqueConsignmentBehaviourSubject_Factory() { return new UniqueConsignmentBehaviourSubject(); }, token: UniqueConsignmentBehaviourSubject, providedIn: "root" });
    return UniqueConsignmentBehaviourSubject;
}());
export { UniqueConsignmentBehaviourSubject };
