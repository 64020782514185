export class BusinessEntityReference {
  code?: string;
  name?: string;
  roleType?: number;
  label?: string;
}
export class RCCType {
  code?: string;
  description?: string;
}

export class PermitType {
  code?: string;
  description?: string;
  importExportIndicator?: string;
}

export class Permit {
  permitNo?: string;
  expiryDate?: string;
  type?: PermitType;
  rccType?: RCCType;
  businessEntityReference?: BusinessEntityReference;
  allocations?: PermitAllocation[];
  [k: string]: any;
}

export class TariffCodeReference {
  tariffId?: number;
  code?: string;
  checkDigit?: number;
}

export class PermitAllocation {
  tariffCodeReference = new TariffCodeReference();
  allocatedValue = 0;
  value = 0;
  valueBased = false;
  quantityBased = false;
  customsBalance = 0;
  systemBalance = 0;
  quantityCustomsBalance = 0;
  quantityAllocatedValue = 0;
  quantitySystemBalance = 0;
  quantityValue = 0;
  uom?: string;

  constructor() {
  }
}
