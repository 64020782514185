import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map, tap} from 'rxjs/operators';
import {Address, ClearingInstruction, Remover} from '../models/file';
import {EMPTY, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoverService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findRemoverNameOrCodeStartsWith(code) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=${code}&roleType=8&projection=flattenedRemover`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findRemoverFromClearingInstructionAndRemovers(clearingInstruction: ClearingInstruction,
                                                removers: Remover[]): Observable<Remover> {
    if (clearingInstruction.length > 0) {
      if (removers.length === 0) {
        return this.returnDefault(clearingInstruction[0]);
      } else if (removers && removers.length === 1) {
        return this.findRemoverFromClearingInstruction(clearingInstruction, removers[0]);
      } else if (clearingInstruction[0].remover.code) {
        return this.findRemoverFromClearingInstruction(clearingInstruction, clearingInstruction.remover, false);
      }
      return this.returnDefault(clearingInstruction[0]);
    }
    return EMPTY;
  }

  private returnDefault(clearingInstruction: ClearingInstruction) {
    const remover = new Remover();
    remover.address = new Address();
    if (clearingInstruction) {
      clearingInstruction.remover = remover;
    }
    return of([remover]);
  }

  findRemoverFromClearingInstruction(clearingInstruction: ClearingInstruction, remover: Remover, updateRemover: boolean = true) {
    if (clearingInstruction) {
      return this.findRemoverNameOrCodeStartsWith(remover.code).pipe(
        tap(removerData => {
          if (updateRemover) {
            clearingInstruction.remover = new Remover();
            clearingInstruction.remover.name = remover.name;
            clearingInstruction.remover.code = remover.code;
            clearingInstruction.remover.label = remover.label;
            clearingInstruction.remover.customsCode = removerData[0].customsCode;
            clearingInstruction.remover.bondValue = removerData[0].bondValue;
          }
          if (removerData && (!clearingInstruction.remover.address
            || !clearingInstruction.remover.address.line1)) {
            const removerAddresses = removerData[0].addresses;
            let removerAddress;
            if (removerAddresses.length === 1) {
              removerAddress = removerAddresses[0];
            } else {
              removerAddress = removerAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'CUS';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (removerAddress) {
              clearingInstruction.remover.address = new Address();
              clearingInstruction.remover.address.line1 = removerAddress.line1;
              clearingInstruction.remover.address.line2 = removerAddress.line2;
              clearingInstruction.remover.address.line3 = removerAddress.line3;
              clearingInstruction.remover.address.line4 = removerAddress.line4;
              clearingInstruction.remover.address.zipCode = removerAddress.zipCode;
            }
          }

        }));
    } else {
      return this.returnDefault(clearingInstruction);
    }
  }
}
