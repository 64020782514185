import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {GuarateeTypeService} from '../../services/guaratee-type.service';
import {GuaranteeType} from '../../models/file';

@Component({
  selector: 'digi-app-guarantee-type',
  templateUrl: './guarantee-type.component.html',
  styleUrls: ['./guarantee-type.component.scss']
})
export class GuaranteeTypeComponent implements OnInit, OnChanges {
  @Input() guaranteeType: GuaranteeType;
  filteredGuaranteeTypes: GuaranteeType[];
  selectedGuaranteeType: GuaranteeType;
  @Output() onSelect = new EventEmitter();

  constructor(private guarateeTypeService: GuarateeTypeService) {
  }

  ngOnInit() {
    this.selectedGuaranteeType = Object.assign({}, this.guaranteeType);
  }

  ngOnChanges() {
    this.selectedGuaranteeType = Object.assign({}, this.guaranteeType);
  }

  search(event) {
    this.guarateeTypeService.findByCode(event.query).subscribe(data => {
      this.filteredGuaranteeTypes = data;
    });
  }

  select(value) {
    this.onSelect.emit(value);
  }
}
