import { Address, Warehouse } from '../models/file';
import { EMPTY, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var WarehouseService = /** @class */ (function () {
    function WarehouseService(http, config) {
        this.http = http;
        this.config = config;
    }
    WarehouseService.prototype.findWarehouseByCode = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=" + code + "&roleType=13&projection=flattenedWarehouse")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    WarehouseService.prototype.findWarehouseByWarehouseNumber = function (warehouseNumber) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byCustomsWarehouseNumberAndOnlyRoleType?warehouseNumber=" + warehouseNumber + "&roleType=13&projection=flattenedWarehouse")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    WarehouseService.prototype.findWarehouseFromClearingInstructionAndWarehouses = function (clearingInstruction, warehouses) {
        if (clearingInstruction) {
            if (warehouses.length === 0) {
                return this.returnDefault(clearingInstruction);
            }
            else if (clearingInstruction.warehouse.code) {
                return this.findWarehouseFromClearingInstruction(clearingInstruction, clearingInstruction.warehouse, false);
            }
            else if (warehouses && warehouses.length === 1) {
                return this.findWarehouseFromClearingInstruction(clearingInstruction, warehouses[0]);
            }
            return this.returnDefault(clearingInstruction);
        }
        return EMPTY;
    };
    WarehouseService.prototype.returnDefault = function (clearingInstruction) {
        var warehouse = new Warehouse();
        warehouse.address = new Address();
        if (clearingInstruction) {
            clearingInstruction.warehouse = warehouse;
        }
        return of([warehouse]);
    };
    WarehouseService.prototype.findWarehouseFromClearingInstruction = function (clearingInstruction, warehouse, updateWarehouse) {
        if (updateWarehouse === void 0) { updateWarehouse = true; }
        if (clearingInstruction) {
            return this.findWarehouseByCode(warehouse.code).pipe(tap(function (warehouseData) {
                if (updateWarehouse) {
                    clearingInstruction.warehouse = new Warehouse();
                    clearingInstruction.warehouse.name = warehouse.name;
                    clearingInstruction.warehouse.code = warehouse.code;
                    clearingInstruction.warehouse.label = warehouse.label;
                    clearingInstruction.warehouse.customsWarehouseNumber = warehouseData[0].customsWarehouseNumber;
                }
                if (warehouseData && (!clearingInstruction.warehouse.address
                    || !clearingInstruction.warehouse.address.line1)) {
                    var warehouseAddresses = warehouseData[0].addresses;
                    var warehouseAddress = void 0;
                    if (warehouseAddresses.length === 1) {
                        warehouseAddress = warehouseAddresses[0];
                    }
                    else {
                        warehouseAddress = warehouseAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (warehouseAddress) {
                        clearingInstruction.warehouse.address = new Address();
                        clearingInstruction.warehouse.address.line1 = warehouseAddress.line1;
                        clearingInstruction.warehouse.address.line2 = warehouseAddress.line2;
                        clearingInstruction.warehouse.address.line3 = warehouseAddress.line3;
                        clearingInstruction.warehouse.address.line4 = warehouseAddress.line4;
                        clearingInstruction.warehouse.address.zipCode = warehouseAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return this.returnDefault(clearingInstruction);
        }
    };
    WarehouseService.ngInjectableDef = i0.defineInjectable({ factory: function WarehouseService_Factory() { return new WarehouseService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: WarehouseService, providedIn: "root" });
    return WarehouseService;
}());
export { WarehouseService };
