import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Person} from '../app/digi-common/models/person';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {

  private loggedInUser: BehaviorSubject<Person> = new BehaviorSubject(null);

  constructor() {
  }

  addLoggedInUser(person: Person) {
    this.loggedInUser.next(person);
  }

  getLoggedInUser(): Observable<Person> {
    return this.loggedInUser.asObservable();
  }
}
