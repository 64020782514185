import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BusinessEntity, Country, CustomsTraderType, Importer } from '../../models/business-entity';
import {Uom} from '../../../digi-common/models/TariffCode';
import {UomService} from '../../../digi-common/services/uom.service';

@Component({
  selector: 'app-importer-role',
  templateUrl: './importer-role.component.html',
  styleUrls: ['./importer-role.component.scss']
})
export class ImporterRoleComponent implements OnInit, OnChanges {
  @Input() businessEntity: BusinessEntity;
  @Input() importer: Importer;
  filteredUoms: Uom[];

  constructor(private uomService: UomService) {
  }

  ngOnInit() {
    this.copyDefaultsFromBusinessEntity();
  }

  ngOnChanges() {
    this.copyDefaultsFromBusinessEntity();
  }

  private copyDefaultsFromBusinessEntity() {
    if (!this.importer.defaultCustomsCode) {
      this.importer.defaultCustomsCode = this.businessEntity.defaultCustomsCode;
    }

    if (!this.importer.defaultVatRegNo) {
      this.importer.defaultVatRegNo = this.businessEntity.defaultVatRegNo;
    }
  }

  selectCountry(value: Country) {
    this.importer.country = value;
  }

  selectTraderType(value: CustomsTraderType) {
    this.importer.registrationType = value;
  }

  selectUom(value: Uom) {
    this.importer.costingUom = value;
  }

  searchUoms(event: any) {
    this.uomService.findByCodeStartsWith(event.query).subscribe(
      data => this.filteredUoms = data
    );
  }


}
