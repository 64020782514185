<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
                <input type="text" pInputText [(ngModel)]="selectedContainer.solas.vgm">
                 <label>VGM (Gross kg)</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-dropdown [options]="verificationMethods"
                        [(ngModel)]="selectedContainer.solas.verificationMethod"
                        [autoWidth]="false">
            </p-dropdown>
              <label>Verification Method</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="selectedContainer.solas.vgmVerifier">
              <label>VGM Verifier</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
               <input type="text" pInputText [(ngModel)]="selectedContainer.solas.samasaAccrediation">
                <label>SAMASA Accrediation</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
