<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <p-checkbox label="Turn-in-free"
                      [(ngModel)]="selectedContainer.turnInFree"
                      binary="true"></p-checkbox>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-app-depot></digi-app-depot>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="unpackDate" mask="99/99/9999"
                               [(ngModel)]="selectedContainer.unpackDate">
                  </p-inputMask>
                     <label for="unpackDate">Unpack Date</label>
                </span>
              </span>
            </span>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="storageApplicationDate" mask="99/99/9999"
                               [(ngModel)]="selectedContainer.storageDate">
                  </p-inputMask>
                     <label for="storageApplicationDate">Storage Application Date</label>
                </span>
              </span>
            </span>
        </div>
        <div *ngIf="fileType==='imports'" class="ui-g-12 column ui-md-12">
          <div class="ui-inputgroup">
      <span class="ui-float-label">
    <p-dropdown [options]="role" [(ngModel)]="selectedRole"  (onChange)="roleChanged()" [autoWidth]="false"></p-dropdown>
    <label>Release to</label>
    </span>
          </div>
        </div>
        <div *ngIf="fileType==='imports'" class="ui-g-12" [ngSwitch]="selectedRole">
          <div class="" *ngSwitchDefault>
            <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [ngModel]="selectedContainer.containerReleasedTo.label" readonly>
                    </span>
                    <div *ngIf="!selectedContainer?.containerReleasedTo?.code"
                         class="ui-message ui-messages-error ui-corner-all">
                      This field is required
                    </div>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
