import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  Address,
  BusinessEntity,
  Country,
  Currency,
  CustomsTraderType,
  Supplier, ImporterSupplier,
  NominatedBusinessEntity,
  Role, ValuationCode, ValuationMethod
} from '../../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../../../services/notification.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ClearingFile} from '../../../models/clearingFile';
import {PurchaseTerm} from '../../../models/file';
import {ImporterService} from '../../../services/importer.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'digi-supplier-modal',
  templateUrl: './supplier-modal.component.html',
  styleUrls: ['./supplier-modal.component.scss']
})
export class SupplierModalComponent implements OnInit, OnDestroy {
  @Input('display') display;
  @Output() created = new EventEmitter();
  roleType = 21;
  businessEntity: BusinessEntity;
  supplier: Supplier;
  role: Role;
  selectedAddress: Address;
  clearingFile: ClearingFile;
  nominatedBusinessEntity: NominatedBusinessEntity;
  importerSupplier: ImporterSupplier;
  clearingFileSubscription: Subscription;
  valuationMethodTypes: any[];
  valuationCodeTypes: any[];
  selectedValuationMethodType: any;
  selectedValuationCodeType: any;
  filteredValuationMethodTypes = [];
  filteredValuationCodes = [];
  selectedDefaultValidationMethod: ValuationMethod;
  selectedDefaultValidationCode: ValuationCode;

  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private importerService: ImporterService) {
    this.valuationMethodTypes = [
      {code: 1, description: 'Method 1', label: 'Method 1'},
      {code: 2, description: 'Method 2', label: 'Method 2'},
      {code: 3, description: 'Method 3', label: 'Method 3'},
      {code: 4, description: 'Method 4', label: 'Method 4'},
      {code: 5, description: 'Method 5', label: 'Method 5'},
      {code: 6, description: 'Method 6', label: 'Method 6'}
    ] ,
      this.valuationCodeTypes = [
        {code: 'N', description: ' Not Related', label: 'N - Not Related'},
        {code: 'R ', description: 'R - Related', label: 'R - Related'}
      ];
  }

  ngOnInit() {
    this.initialize();

    this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(cf => {
      if (cf) {
        this.clearingFile = cf;
      }
    });
    this.role = new Supplier();
  }

  initialize() {
    this.businessEntity = new BusinessEntity();
    this.nominatedBusinessEntity = new NominatedBusinessEntity();
    this.importerSupplier = new ImporterSupplier();
    this.importerSupplier.defaultPurchaseTerm = new PurchaseTerm();
    this.supplier = new Supplier();
    this.selectedAddress = new Address();
    this.selectedDefaultValidationMethod = new ValuationMethod();
    this.selectedDefaultValidationCode = new ValuationCode();
  }

  selectCountry(value: Country) {
    this.supplier.country = value;

  }

  selectCurrency(value: Currency) {
    this.supplier.currency = value;

  }

  selectTraderType(value: CustomsTraderType) {
    this.supplier.registrationType = value;
  }

  onSave() {
    this.role = this.supplier;
      if (this.businessEntity && !this.businessEntity.id) {
        this.businessEntity.registeredName = this.businessEntity.name;
        this.role.roleType = this.roleType;
        this.businessEntity.roles = [];
        this.businessEntity.roles.push(this.role);
        this.businessEntity.roles = [...this.businessEntity.roles];
        if (this.selectedAddress && this.selectedAddress.line1) {
          this.businessEntity.addresses = [];
          this.businessEntity.addresses.push(this.selectedAddress);
        }
        this.nominatedBusinessEntity.businessEntity = this.businessEntity;
        if (this.selectedValuationCodeType) {
          this.importerSupplier.defaultValuationCode = this.selectedValuationCodeType.valuationMethodType;
        }
        if (this.selectedValuationMethodType) {
          this.importerSupplier.defaultValuationMethod = this.selectedValuationMethodType.code;
        }
        this.nominatedBusinessEntity.roleNomination = this.importerSupplier;
        this.nominatedBusinessEntity.roleNomination.roleType = this.roleType;

        this.businessEntityService.saveBusinessEntityWithRole(this.clearingFile.importer.code, 6, this.roleType, this.nominatedBusinessEntity)
          .subscribe(data => {
            this.nominatedBusinessEntity.businessEntity = data;
            this.businessEntity = data;
            this.businessEntityConfirmation();
            if (this.nominatedBusinessEntity.businessEntity && this.nominatedBusinessEntity.businessEntity.id) {
              this.businessEntityWithImporter();
            }
          }, error => {
            this.messageService.errorNotify(error.error.message, [error.error.errors]);
          });
      } else {
        this.addFailure(['Business Entity has already been saved']);
      }

  }

  businessEntityWithImporter() {
    this.importerService.findImporterFromClearingFile(this.clearingFile).subscribe(data => {
    });
  }

  onNew() {
    this.initialize();
  }

  onClose() {
    this.display = false;
    this.created.emit(this.nominatedBusinessEntity.businessEntity);
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  businessEntityConfirmation() {
    this.messageService.successNotify('Business Entity Saved and Role linked to Importer');
  }

  searchValuationMethods(event) {
    this.filteredValuationMethodTypes = this.valuationMethodTypes.filter(v => v.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredValuationMethodTypes = [...this.filteredValuationMethodTypes];
  }

  selectValuationMethod(event) {
    this.importerSupplier.defaultValuationMethod = this.selectedDefaultValidationMethod.code;
  }

  searchValuationCodes(event) {
    this.filteredValuationCodes = this.valuationCodeTypes.filter(v => v.description
      .toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    this.filteredValuationCodes = [...this.filteredValuationCodes];
  }

  selectValuationCode(event) {
    this.importerSupplier.defaultValuationCode = this.selectedDefaultValidationCode.code;
  }

  ngOnDestroy(): void {
    this.clearingFileSubscription.unsubscribe();
  }
}

