import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomsTraderTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findRegistrationTypeStartsWith(tradeType) {
    return this.http
      .get(`${this.config.apiEndpoint}api/customsTraderType/search/codeStartsWith?description=${tradeType}`)
      .pipe(
        map(response => response['_embedded'].customsTraderType)
      );
  }
}
