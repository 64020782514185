import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../app.config.module";
import * as i2 from "@angular/common/http";
var BankService = /** @class */ (function () {
    function BankService(config, http) {
        this.config = config;
        this.http = http;
    }
    BankService.prototype.findBankStartingWith = function (query) {
        return this.http
            .get(this.config.apiEndpoint + "api/banks/search/nameStartsWith?name=" + query)
            .pipe(map(function (response) { return response['_embedded'].banks; }));
    };
    BankService.ngInjectableDef = i0.defineInjectable({ factory: function BankService_Factory() { return new BankService(i0.inject(i1.APP_CONFIG), i0.inject(i2.HttpClient)); }, token: BankService, providedIn: "root" });
    return BankService;
}());
export { BankService };
