import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CrewMemberService = /** @class */ (function () {
    function CrewMemberService(http, config) {
        this.http = http;
        this.config = config;
    }
    CrewMemberService.prototype.saveCrewMember = function (crewMember) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (crewMember._links && crewMember._links.self) {
            return this.http.put(crewMember._links.self.href, JSON.stringify(crewMember), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + "api/crewMembers", JSON.stringify(crewMember), { headers: headers });
        }
    };
    CrewMemberService.prototype.findBySurnameOrFirstNamesStartsWith = function (nameOrSurname) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/crewMembers/search/nameOrSurnameStartsWith?nameOrSurname=" + nameOrSurname)
            .pipe(map(function (response) { return response['_embedded'].crewMembers; }));
    };
    CrewMemberService.prototype.findAll = function () {
        return this.http
            .get(this.config.filesApiEndpoint + "api/crewMembers")
            .pipe(map(function (response) { return response['_embedded'].crewMembers; }));
    };
    CrewMemberService.prototype.deleteCrewMember = function (crewMember) {
        return this.http.delete(this.config.filesApiEndpoint + "api/crewMembers/" + crewMember.id);
    };
    CrewMemberService.prototype.isValidCrewMember = function (crewMember, validationResult) {
        if (!crewMember.firstNames) {
            validationResult.messages.push('Please Enter First Name(s)');
        }
        if (!crewMember.surname) {
            validationResult.messages.push('Please Enter Surname');
        }
        if (!crewMember.gender) {
            validationResult.messages.push('Please Enter Gender');
        }
        if (!crewMember.occupation) {
            validationResult.messages.push('Please Enter Occupation');
        }
        if (!crewMember.nationality) {
            validationResult.messages.push('Please Enter Nationality');
        }
        if (!crewMember.countryOfResidence) {
            validationResult.messages.push('Please Enter Country of Residence');
        }
        if (!crewMember.foreign && !crewMember.idNumber) {
            validationResult.messages.push('Please Enter ID Number');
        }
        if (!crewMember.foreign && crewMember.idNumber && crewMember.idNumber.length !== 13) {
            validationResult.messages.push('Please Enter A Valid ID Number');
        }
        if (!crewMember.dateOfBirth) {
            validationResult.messages.push('Please Enter Date of Birth');
        }
        if (!crewMember.travelerType) {
            validationResult.messages.push('Please Enter Traveler Type');
        }
        if (!crewMember.reasonForMovement) {
            validationResult.messages.push('Please Enter Reason For Movement');
        }
        if (!crewMember.travelDocType) {
            validationResult.messages.push('Please Enter Travel Document Type');
        }
        if (!crewMember.passportNumber) {
            validationResult.messages.push('Please Enter Passport Number');
        }
        if (!crewMember.travelDocExpiryDate) {
            validationResult.messages.push('Please Enter Travel Document Expiry Date');
        }
        if (!crewMember.travelDocCountryOfIssue) {
            validationResult.messages.push('Please Enter Travel Document Country of Issue');
        }
        if (validationResult.messages.length > 0) {
            validationResult.isValid = false;
        }
        return validationResult;
    };
    CrewMemberService.ngInjectableDef = i0.defineInjectable({ factory: function CrewMemberService_Factory() { return new CrewMemberService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CrewMemberService, providedIn: "root" });
    return CrewMemberService;
}());
export { CrewMemberService };
