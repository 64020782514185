<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Registration Reports" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-4">
              <div class="ui-g-6 ui-md-6">
                <span class="md-inputfield">
                  <span class="ui-float-label">
                      <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                   [(ngModel)]="fromDate"
                                   placeholder="From"
                      >
                      </p-inputMask>
                  </span>
                </span>
              </div>
              <div class="ui-g-6 ui-md-6">
                <span class="md-inputfield">
                  <span class="ui-float-label">
                      <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                   [(ngModel)]="toDate"
                                   placeholder="To"
                      >
                      </p-inputMask>
                  </span>
                </span>
              </div>
            </div>
            <div class="ui-toolbar-group-right">
              <div class="ui-g-12 ui-md-12">
                  <button pButton type="button" label="Generate Air Reports"
                          [disabled]="disableButton"
                          (click)="generate(false)"></button>
                <button pButton type="button" label="Generate Sea Reports"
                        [disabled]="disableButton"
                        (click)="generate(true)"></button>
                <button *ngIf="pdfSrc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="card card-w-title">
            <div class="ui-g">
              <pdf-viewer class="ui-g-12 ui-md-12"
                          *ngIf="pdfSrc"
                          [src]="pdfSrc"
                          [render-text]="true"
                          [original-size]="true"
                          [show-all]="true"
                          [stick-to-page]="true"
                          (after-load-complete)="disableButton=false"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
