import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Container, ExportTransportDocument, TransportDocument} from '../../../models/file';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-container-solas-section',
  templateUrl: './container-solas-section.component.html',
  styleUrls: ['./container-solas-section.component.scss']
})
export class ContainerSolasSectionComponent implements OnInit, OnChanges, OnDestroy {
  verificationMethods: SelectItem[];
  selectedContainer: Container;
  containerSubscription: Subscription;
  @Input() selectedTransportDocument: TransportDocument | ExportTransportDocument;

  constructor(private containerBehaviorSubject: ContainerBehaviorSubject) {
    this.verificationMethods = [
      {label: '', value: null},
      {label: '1', value: 1},
      {label: '2', value: 2}
    ];
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => {
        this.selectedContainer = ct;
        if (!this.selectedContainer.solas.vgm) {
          this.selectedContainer.solas.vgm = this.selectedTransportDocument.measures.grossWeight;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedTransportDocument = changes.selectedTransportDocument.currentValue;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
