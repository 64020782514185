import { Injectable, OnInit } from '@angular/core';
import { ValidationResult, ValidationService } from '../app/digi-common/services/validation.service';
import { ClearingFileBehaviourSubject } from '../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../subjects/transport-document-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../subjects/supplierInvoice-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../subjects/supplier-invoice-line-behaviour-subject.service';
import { ClearingFile } from '../app/digi-common/models/clearingFile';
import { SupplierInvoice, SupplierInvoiceLine, TransportDocument } from '../app/digi-common/models/file';
import { Subscription } from 'rxjs';
import { SaveClearingFile } from '../app/digi-common/saveClearingFile';
import { NotificationService } from '../app/digi-common/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ClearingFileValidationService implements OnInit {
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;
  subscription: Subscription;
  label: string;

  constructor(
    private onSaveClearingFile: SaveClearingFile,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private messageService: NotificationService,
    private validationService: ValidationService
  ) {
  }

  ngOnInit() {
  }

  public validateTransportDocument(clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    const result: ValidationResult = this.validationService.validateTransportDocumentOnly(
      selectedTransportDocument, clearingFile.clearingInstructions[0].transportMethod.method, 'imports'
    );
    if (result.isValid) {
      this.onSaveClearingFile.saveClearingFile(clearingFile, selectedTransportDocument,
        selectedSupplierInvoice, selectedSupplierInvoiceLine);
    } else {
      this.showErrors(result.messages);
    }
    return result.isValid;
  }

  showErrors(messages: string[]) {
    this.messageService.errorNotify('Validation Failed', messages);
  }

}
