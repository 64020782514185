import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ForwardFile, TransportDocument} from '../../models/file';

@Component({
  selector: 'digi-hawb-forward-file-section',
  templateUrl: './hawb-forward-file-section.component.html',
  styleUrls: ['./hawb-forward-file-section.component.css']
})
export class HawbForwardFileSectionComponent implements OnInit, OnChanges {
  @Input() transportDocument: TransportDocument;
  constructor() { }

  ngOnInit() {
    if (this.transportDocument && !this.transportDocument.forwardFile) {
      this.transportDocument.forwardFile = new ForwardFile();
    }
  }

  ngOnChanges() {
    if (this.transportDocument && !this.transportDocument.forwardFile) {
      this.transportDocument.forwardFile = new ForwardFile();
    }
  }

}
