import { Component, OnChanges, OnInit } from '@angular/core';
import { SchedulePartService } from '../../services/schedule-part.service';
import { SchedulePart } from '../../models/file';
import { TariffService } from '../../services/tariff.service';
import { TariffCodeBehaviorSubject } from '../../../../subjects/tariffCode-behavior-subject';
import { TariffCodeReference } from '../../models/TariffCode';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'digi-tariff-dependent',
  templateUrl: './tariff-dependent.component.html',
  styleUrls: ['./tariff-dependent.component.scss']
})
export class TariffDependentComponent implements OnInit, OnChanges {
  filteredScheduleParts: SchedulePart[];
  searchedTariffCode: TariffCodeReference;
  filteredTariffCodeAndDependentCodes: TariffCodeReference;
  selecteTariffCodeAndDependentCode: TariffCodeReference;
  subscription: Subscription;
  display: boolean;
  cols: any[];

  constructor(private schedulePartService: SchedulePartService,
              private tariffService: TariffService,
              private tariffCodeBehaviorSubject: TariffCodeBehaviorSubject,
              private messageService: NotificationService) {
  }

  ngOnInit() {
    this.subscription = this.tariffCodeBehaviorSubject.getTariffCode().subscribe(t => {
      if (t) {
        this.searchedTariffCode = t;
      }
    });
    this.cols = [
      {field: 'tariffCode', header: 'Tariff Code', width: '50%'},
      {field: 'dependentCode', header: 'Dependent Code', width: '50%'}];
  }


  searchSchedulePart(event) {
    this.schedulePartService.findBySchedulePart(event.query).subscribe((data: SchedulePart[]) => {
      this.filteredScheduleParts = data;
    });
  }

  select(value) {
    this.searchedTariffCode.schedule = value.code;
  }

  search() {
    this.tariffService.findByTariffCodeSchedulePartAndDependentCode(this.searchedTariffCode.code,
      this.searchedTariffCode.schedule, this.searchedTariffCode.dependantCode)
      .subscribe(data => {
          this.filteredTariffCodeAndDependentCodes = data;
          this.tariffCodeBehaviorSubject.addTariffCode(data);
        },
        error => this.addFailure(error));
  }

  createNewDependent() {
    this.searchedTariffCode = this.tariffCodeBehaviorSubject.buildTariffCode();
    this.tariffCodeBehaviorSubject.addTariffCode(this.searchedTariffCode);
  }

  saveNewDependent() {
    this.tariffService.saveTariffCode(this.searchedTariffCode).subscribe(data => {
      console.log(data);
        this.tariffCodeBehaviorSubject.addTariffCode(data);
        this.messageService.successNotify('Tariff Saved Successfully');
      },
      error => this.addFailure(error));
  }

  onRowSelect(value): any {
    this.tariffCodeBehaviorSubject.addTariffCode(value.data);
  }

  openModel() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  ngOnChanges() {
    this.subscription.unsubscribe();
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }
}
