import {map, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {EMPTY} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {ClearingFile} from '../models/clearingFile';
import {Address} from '../models/file';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  constructor(private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findImporterNameOrCodeStartsWith(name: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + name + '&roleType=6' + '&projection=flattenedImporter')
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findImporterFromClearingFile(clearingFile: ClearingFile) {
    if (clearingFile.importer && clearingFile.importer.code) {
      return this.findImporterNameOrCodeStartsWith(clearingFile.importer.code).pipe(
        tap(
          importerData => {
            if (importerData) {
              console.log(importerData);
              this.supplierInvoiceBehaviourSubject.addSuppliers(importerData[0].suppliers);
              this.clearingFileBehaviourSubject.addDebtors(importerData[0].debtors);
              this.clearingInstructionBehaviorSubject.addRebateUsers(importerData[0].rebateUsers);
              this.clearingInstructionBehaviorSubject.addWarehouses(importerData[0].warehouses);
              this.clearingInstructionBehaviorSubject.addRemovers(importerData[0].removers);
              this.clearingInstructionBehaviorSubject.addImporterConsignee(importerData[0].importerConsignees)
              if (importerData && (!clearingFile.importer.address || !clearingFile.importer.address.line1)) {
                const importerAddresses = importerData[0].addresses;
                let importerAddress;
                if (importerAddresses.length === 1) {
                  importerAddress = importerAddresses[0];
                } else {
                  importerAddress = importerAddresses.find(address => {
                    const addressType = address.addressTypes.find(addressType => {
                      return addressType.id === 'CUS';
                    });
                    if (addressType) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                }
                if (importerAddress) {
                  clearingFile.importer.address = new Address();
                  clearingFile.importer.address.line1 = importerAddress.line1;
                  clearingFile.importer.address.line2 = importerAddress.line2;
                  clearingFile.importer.address.line3 = importerAddress.line3;
                  clearingFile.importer.address.line4 = importerAddress.line4;
                  clearingFile.importer.address.zipCode = importerAddress.zipCode;
                }
              }

            }
          }
        )
      );
    } else {
      return EMPTY;
    }
  }

  findImporterDeliveryAddress(importerCode: string) {
    if (importerCode) {
      return this.findImporterNameOrCodeStartsWith(importerCode).pipe(
        map(
          importerData => {
            if (importerData && importerData[0]) {
              const importerAddresses = importerData[0].addresses;
              let importerAddress;
              if (importerAddresses.length === 1) {
                importerAddress = importerAddresses[0];
              } else {
                importerAddress = importerAddresses.find(address => {
                  const addressType = address.addressTypes.find(addressType => {
                    return addressType.id === 'DEL';
                  });
                  if (addressType) {
                    return true;
                  } else {
                    return false;
                  }
                });
              }
              return importerAddress;
            }
          }
        )
      );
    } else {
      return EMPTY;
    }
  }
}
