import {ClearingFile} from './clearingFile';
import {BillOfEntryLine, TradeAgreement} from './billOfEntries';
import {ChargeType} from './financials';
import {Uom} from './TariffCode';

export class CostingLine {
  landedCostMarkup?: number;
  landedCostMarkupPercentage?: number;
  invoiceMarkup?: number;
  invoiceMarkupPercentage?: number;
  clientRefNo?: string;
  revisedValue?: number;
  exchangeRate?: number;
  unitCost?: number;
  customsVAT?: number;
  lineNo?: number;
  costingUom?: Uom;
  invoiceNumber?: string;
  localInvoiceGrossValue?: number;
  customsDuty?: number;
  invoiceGrossValue?: number;
  costingValue?: number;
  additionalClientReference?: string;
  supplierCode?: string;
  tariffCode?: string;
  partNumber?: string;
  totalCost?: number;
  apportionedFileCosts?: FileCost[];
  totalApportionedCost?: number;
  tariffDescription?: string;
  invoicedCost?: number;
  quantity?: number;
  sch12b?: number;
  tradeAgreement?: TradeAgreement;
}

export class CostingItem {
  code?: string;
  description?: string;
  label?: string;
}

export class FileCost {
  chargeType?: ChargeType;
  amount?: number;
  costingItem?: CostingItem;
}

export class SupplierInvoiceCost extends FileCost {
  invoiceNo?: string;
  lineNo?: number;
}

export class CostingFile {
  fileNumber?: string;
  clearingFile?: ClearingFile;
  description?: string;
  invoiceMarkupPercentage?: number;
  landedCostMarkupPercentage?: number;
  exchangeRateDate?: string;
  forInsurance?: boolean;
  useClientRefNoAsPartNo?: boolean;
  apportionSundriesByHousebill?: boolean;
  excludeAllVat?: boolean;
  excludeBoeVat?: boolean;
  includeDutiesAndVatForWarehouseEntries?: boolean;
  costingLines?: CostingLine[];
  costs?: FileCost[];
  supplierInvoiceCosts?: SupplierInvoiceCost[];

  [k: string]: any;
}
