import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {ShareDataService} from '../../digi-common/services/share-data.service';

@Component({
  selector: 'digi-road-dashboard',
  templateUrl: './road-dashboard.component.html',
  styleUrls: ['./road-dashboard.component.css']
})
export class RoadDashboardComponent implements OnInit {

  constructor(private router: Router,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile('ROA'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== '/road/register/') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['road', 'register']);
    }
  }

}
