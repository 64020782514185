<p-panel>
  <div class="ui-g">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                           pInputText readonly>
                    <label>Consignee Code</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Customs Code</label>
                        </span>
                    </div>
                  </span>
      </div>
      <digi-country-of-origin label="Country of Destination" class="ui-g-12 ui-md-12" [country]="consignee?.country"
                              (onSelect)="selectCountry($event)"></digi-country-of-origin>
      <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="consignee.termsOfPayment"
                                   pInputText>
                            <label>Terms of Payment</label>
                        </span>
                    </div>
                  </span>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
          <p-checkbox [(ngModel)]="consignee.hasBankingDetails" label="Consignee Banking Details?"
                      binary="true"></p-checkbox>
        </div>
      </div>
      <span *ngIf="consignee?.hasBankingDetails" class="md-inputfield">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
           <span class="md-inputfield">
             <div class="ui-inputgroup">
                 <span class="md-inputfield">
                     <input type="text"
                            [(ngModel)]="consignee.commercialBankName"
                            pInputText>
                     <label>Bank Name</label>
                 </span>
             </div>
           </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                  <span class="md-inputfield">
                      <input type="text"
                             [(ngModel)]="consignee.commercialBankCode"
                             pInputText>
                      <label>Branch Code</label>
                  </span>
              </div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                  <span class="md-inputfield">
                      <input type="text"
                             [(ngModel)]="consignee.commercialBankAccNo"
                             pInputText>
                      <label>Account No.</label>
                  </span>
              </div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                  <span class="md-inputfield">
                      <input type="text"
                             [(ngModel)]="consignee.commercialBankSwiftCode"
                             pInputText>
                      <label>Swift Code</label>
                  </span>
              </div>
            </span>
          </div>
        </div>
      </span>
    </div>
  </div>
</p-panel>
