import {Component, OnDestroy, OnInit} from '@angular/core';
import { SelectItem } from 'primeng/api';
import {Container} from '../../../models/file';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import { Subscription} from 'rxjs';

@Component({
  selector: 'digi-seal-detail-section',
  templateUrl: './seal-detail-section.component.html',
  styleUrls: ['./seal-detail-section.component.scss']
})
export class SealDetailSectionComponent implements OnInit, OnDestroy {
  selectedContainer: Container;
  sealingParties: SelectItem[];
  sealStatuses: SelectItem[];
  sealTypes: SelectItem[];
  containerSubscription: Subscription;

  constructor(private containerBehaviorSubject: ContainerBehaviorSubject) {
    this.sealingParties = [
      {label: '', value: null},
      {label: 'Carrier', value: 'Carrier'},
      {label: 'Customs', value: 'Customs'},
      {label: 'Shipper', value: 'Shipper'},
      {label: 'Terminal Operator', value: 'Terminal Operator'}
    ];
    this.sealStatuses = [
      {label: '', value: null},
      {label: 'Fine', value: 'Fine'},
      {label: 'Damaged', value: 'Damaged'}
    ];
    this.sealTypes = [
      {label: '', value: null},
      {label: 'Mechanical Seal', value: 'Mechanical Seal'},
      {label: 'Electronic seal', value: 'electronic seal'}
    ];
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => this.selectedContainer = ct);
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
