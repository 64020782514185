import {Address, Country, Currency, ExportTransportDocument, File, LocalAgent, OverseasAgent, PurchaseTerm, SupplierInvoice} from './file';
import {CustomsTraderType, ReceivingBank} from '../../digi-business-entity/models/business-entity';

export class LineDetails {
  description?: string;
  mass?: number;
  length?: number;
  width?: number;
  height?: number;
  noOfPacks?: number;
}

export class ShippingOrder {
  accountNumber?: string;
  boeNumber?: string;
  terminal?: string;
  localPort?: string;
  foreignPort?: string;
  authorisedSignatory?: string;
  exporter?: string;
  vesselName?: string;
  voyageNumber?: string;
  imdgClass?: string;
  contractNumber?: string;
  quoteNumber?: string;
  boeDate?: string;
  transportMethodCode?: string;
  termsLinear?: boolean;
  sad500Attached?: boolean;
  boeAttached?: boolean;
  packingListAttached?: boolean;
  suppliersInvoiceAttached?: boolean;
  deliveryOrderAttached?: boolean;
  bolAttached?: boolean;
  lineDetails?: LineDetails[];
  marksAndNumbers?: string[];
  transporter?: string;
  consignee?: string;
  billOfLading?: string;
  railDestination?: string;
  railSlidingNo?: string;


}

export class ExportFile extends File {
  exporter?: Exporter;
  exportConsignee?: ExportConsignee;
  transportDocument?: ExportTransportDocument;
}

export class Exporter {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;
  taxRegNo?: string;
  label?: string;
  registrationType?: CustomsTraderType;
  idNumber?: string;
  passportNumber?: string;
  currency?: Currency;
  receivingBank?: ReceivingBank;
  bank?: string;
}

export class ExportConsignee {
  code?: string;
  address?: Address;
  label?: string;
  name?: string;
  customsCode?: string;
  taxRegNo?: string;
  country?: Country;
  defaultPurchaseTerm: PurchaseTerm;
  termsOfPayment?: string;
  hasBankingDetails?: boolean;
  commercialBankName?: string;
  commercialBankCode?: string;
  commercialBankAccNo?: string;
  commercialBankSwiftCode?: string;
  currency: Currency;
}
