/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tariff-maintenance-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i3 from "primeng/components/steps/steps";
import * as i4 from "./tariff-maintenance-navigation.component";
import * as i5 from "@angular/router";
var styles_TariffMaintenanceNavigationComponent = [i0.styles];
var RenderType_TariffMaintenanceNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TariffMaintenanceNavigationComponent, data: {} });
export { RenderType_TariffMaintenanceNavigationComponent as RenderType_TariffMaintenanceNavigationComponent };
export function View_TariffMaintenanceNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-steps", [["styleClass", "steps-custom"]], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Steps_0, i2.RenderType_Steps)), i1.ɵdid(1, 49152, null, 0, i3.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"], styleClass: [3, "styleClass"] }, { activeIndexChange: "activeIndexChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; var currVal_3 = "steps-custom"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TariffMaintenanceNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-tariff-maintenance-navigation", [], null, null, null, View_TariffMaintenanceNavigationComponent_0, RenderType_TariffMaintenanceNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i4.TariffMaintenanceNavigationComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TariffMaintenanceNavigationComponentNgFactory = i1.ɵccf("digi-tariff-maintenance-navigation", i4.TariffMaintenanceNavigationComponent, View_TariffMaintenanceNavigationComponent_Host_0, {}, {}, []);
export { TariffMaintenanceNavigationComponentNgFactory as TariffMaintenanceNavigationComponentNgFactory };
