import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Address, ClearingInstruction, Consignee, Warehouse} from '../../../../models/file';
import {Subscription} from 'rxjs';
import {ClearingInstructionBehaviorSubject} from '../../../../../../subjects/clearingInstruction-behavior-subject';
import {WarehouseService} from '../../../../services/warehouse.service';

@Component({
  selector: 'digi-warehouse-consignee',
  templateUrl: './warehouse-consignee.component.html',
  styleUrls: ['./warehouse-consignee.component.scss']
})
export class WarehouseConsigneeComponent implements OnInit, OnDestroy {
  @Input() clearingInstruction: ClearingInstruction;
  filteredConsignees: Consignee[];
  subscription: Subscription;
  warehouseAddresses = [];
  roleId = 'Warehouse';
  warehouses: Warehouse[];

  constructor(
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private  warehouseService: WarehouseService
  ) {
  }

  ngOnInit() {
    this.subscription = this.clearingInstructionBehaviorSubject.getWarehouses().subscribe(warehouse => {
      this.warehouses = warehouse;
    });

    if (this.warehouses.length === 1) {
      this.updateSelectedWarehouseToConsignee(this.warehouses[0]);
    } else {
      this.clearingInstruction.consignee = new Consignee();
    }
  }

  search(event) {
    this.filteredConsignees = [];
    if (this.warehouses.length > 0) {
      for (let i = 0; i < this.warehouses.length; i++) {
        const warehouse: Warehouse = this.warehouses[i];
        if (
          warehouse.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
          warehouse.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        ) {
          this.filteredConsignees.push(warehouse);
        }
      }
    }
  }

  selectConsignee(warehouse) {
    this.updateSelectedWarehouseToConsignee(warehouse);
  }

  private updateSelectedWarehouseToConsignee(warehouse) {
    this.clearingInstruction.consignee = new Consignee();
    this.clearingInstruction.consignee.code = warehouse.code;
    this.clearingInstruction.consignee.name = warehouse.name;
    this.clearingInstruction.consignee.label = warehouse.label;
    this.clearingInstruction.consignee.roleType = this.roleId;

    this.warehouseService.findWarehouseByCode(warehouse.code)
      .subscribe(warehouseData => {
        if (warehouseData) {
          this.clearingInstruction.consignee.customsWarehouseNumber = warehouseData[0].customsWarehouseNumber;

          this.clearingInstruction.consignee.address = new Address();
          this.clearingInstruction.consignee.address.line1 = warehouseData[0].addresses[0].line1;
          this.clearingInstruction.consignee.address.line2 = warehouseData[0].addresses[0].line2;
          this.clearingInstruction.consignee.address.line3 = warehouseData[0].addresses[0].line3;
          this.clearingInstruction.consignee.address.line4 = warehouseData[0].addresses[0].line4;
          this.clearingInstruction.consignee.address.zipCode = warehouseData[0].addresses[0].zipCode;
          this.warehouseAddresses = warehouseData[0].addresses;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
