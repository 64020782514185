import {Component, Input, OnInit} from '@angular/core';
import {ClearingInstruction, Consignee, Guarantor} from '../../../../models/file';
import {CustomOfficeService} from '../../../../services/custom-office.service';

@Component({
  selector: 'digi-customs-office-consignee',
  templateUrl: './customs-office-consignee.component.html',
  styleUrls: ['./customs-office-consignee.component.scss']
})
export class CustomsOfficeConsigneeComponent implements OnInit {
  filteredConsignees: Guarantor[];
  @Input() clearingInstruction: ClearingInstruction;
  roleId = 'Customs Office';

  constructor(
    private customOfficeService: CustomOfficeService) {
  }

  ngOnInit() {
    if (this.clearingInstruction && this.clearingInstruction.consignee) {
      this.updateSelectedCustomsOfficeToConsigneeFromClearingFile(this.clearingInstruction.consignee);
    }
  }

  search(event) {
    this.customOfficeService.findAllCustomsOfficeStartWith(event.query).subscribe(data => {
      this.filteredConsignees = data;
    });
  }

  selectConsignee(consignee) {
    this.updateSelectedCustomsOfficeToConsignee(consignee);
  }

  private updateSelectedCustomsOfficeToConsignee(consignee) {
    this.clearingInstruction.consignee = new Consignee();
    this.clearingInstruction.consignee.code = consignee.districtOfficeCode;
    this.clearingInstruction.consignee.name = consignee.districtOfficeName;
    this.clearingInstruction.consignee.label = consignee.label;
    this.clearingInstruction.consignee.roleType = this.roleId;
  }

  private updateSelectedCustomsOfficeToConsigneeFromClearingFile(consignee) {
    this.clearingInstruction.consignee = new Consignee();
    this.clearingInstruction.consignee.code = consignee.code;
    this.clearingInstruction.consignee.name = consignee.name;
    this.clearingInstruction.consignee.label = consignee.label;
    this.clearingInstruction.consignee.roleType = consignee.roleType;
  }
}
