<div class=" ui-md-12 ui-g-12 report-file print">
  <div *ngIf="isReleaseLetter" class="ui-md-12 ui-g-12 title text-uppercase">RELEASE LETTER</div>
  <div *ngIf="!isReleaseLetter" class="ui-md-12 ui-g-12 title text-uppercase">ARRIVAL NOTIFICATION</div>
  <div class="ui-g ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="big-rect border-top border-right border-left">
          <div class="box"></div>
          <span><strong>Importer</strong></span>
          <div class="ui-md-12 ui-g-12">
            {{forwardingHawb?.importer?.name}}<br>
            <span *ngIf="forwardingHawb?.importer?.address.line1">{{forwardingHawb.importer.address.line1}}</span><br>
            <span *ngIf="forwardingHawb?.importer?.address.line2">{{forwardingHawb.importer.address.line2}}</span><br>
            <span *ngIf="forwardingHawb?.importer?.address.line3">{{forwardingHawb.importer.address.line3}}</span><br>
            <span *ngIf="forwardingHawb?.importer?.address.line4">{{forwardingHawb.importer.address.line4}}</span><br>
            <span *ngIf="forwardingHawb?.importer?.address.zipCode">{{forwardingHawb.importer.address.zipCode}}</span><br>
          </div>
          <div class="ui-md-12 ui-g-12 footer">
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <span class="ui-float-label">
              <input id="phone" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="phone">Phone</label>
            </span>
            </div>
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <span class="ui-float-label">
              <input id="fax" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="fax">Fax</label>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="big-rect ui-md-12 ui-g-12 ui-g-nopad border-top border-right border-left">
        <div class="box"></div>
        <span><strong>Clearing Agent</strong></span>
        <div class="ui-md-12 ui-g-12 no-print">
          {{forwardingHawb?.localAgent?.name}} - {{forwardingHawb?.localAgent?.customsRegCode}}<br>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="forwardingHawb?.localAgent?.address.line1" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="forwardingHawb?.localAgent?.address.line2" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="forwardingHawb?.localAgent?.address.line3" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="forwardingHawb?.localAgent?.address.line4" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="forwardingHawb?.localAgent?.address.zipCode" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-12 ui-g-12 print-label">
          {{forwardingHawb?.localAgent?.name}} {{forwardingHawb?.localAgent?.customsRegCode}}<br>
          <span *ngIf="forwardingHawb?.localAgent?.address.line1">{{forwardingHawb.localAgent.address.line1}}</span><br>
          <span *ngIf="forwardingHawb?.localAgent?.address.line2">{{forwardingHawb.localAgent.address.line2}}</span><br>
          <span *ngIf="forwardingHawb?.localAgent?.address.line3">{{forwardingHawb.localAgent.address.line3}}</span><br>
          <span *ngIf="forwardingHawb?.localAgent?.address.line4">{{forwardingHawb.localAgent.address.line4}}</span><br>
          <span *ngIf="forwardingHawb?.localAgent?.address.zipCode">{{forwardingHawb.localAgent.address.zipCode}}</span><br>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 align-center">
      <img [src]="logo" id="logo">
      <div class="ui-g-12 ui-md-12 text-uppercase">
        {{companyName}}<br>
        <div [innerHTML]="companyAddress | multilineAddress"></div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="big-rect border-top border-right border-left">
        <div class="box"></div>
        <span><strong>Supplier</strong></span>
        <div class="ui-md-12 ui-g-12 text-uppercase">
          {{forwardingHawb?.supplier?.name}}<br>
          <span *ngIf="forwardingHawb?.supplier?.address?.line1">{{forwardingHawb?.supplier?.address.line1}}</span><br>
          <span *ngIf="forwardingHawb?.supplier?.address?.line2">{{forwardingHawb?.supplier?.address.line2}}</span><br>
          <span *ngIf="forwardingHawb?.supplier?.address?.line3">{{forwardingHawb?.supplier?.address.line3}}</span><br>
          <span *ngIf="forwardingHawb?.supplier?.address?.line4">{{forwardingHawb?.supplier?.address.line4}}</span><br>
          <span *ngIf="forwardingHawb?.supplier?.address?.zipCode">{{forwardingHawb?.supplier?.address.zipCode}}</span><br>
        </div>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-12 ui-g-12 footer">
          <div class="ui-md-3 ui-g-3">
            Cont. Dep.:
          </div>
          <div class="ui-md-9 ui-g-9">
            {{forwardingHawb.containers[0].depot && forwardingHawb.containers[0].depot.code ? forwardingHawb.containers[0].depot.name : forwardingHawb.containers[0].terminal.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="big-rect border-top border-right">
        <div class="box"></div>
        <div *ngIf="transportMethod==='SEA'" class="footer ui-g-offset-1 ui-g-4 ui-md-4">Est. Delivery Date: {{forwardingFile.masterBill.arrivalDate}}</div>
        <div class="footer ui-g-offset-5 ui-g-4 ui-md-4">File Number: {{forwardingFile.fileNumber}}</div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div *ngIf="transportMethod==='SEA'" class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right border-left">
        <span><strong>Container Number</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.containers[0].containerNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right">
        <span><strong>Importer Reference</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.clientRefNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right">
        <span *ngIf="transportMethod==='SEA'"><strong>E.T.A</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.arrivalDate}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>File Reference</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.reference.referenceFileNo}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-md-6 ui-g-6 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-left">
        <span><strong>Ocean Vessel</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.voyageDetails.customsRadioCallSign.vesselName}}
        </div>
      </div>
    </div>
    <div class="ui-md-2 ui-g-2 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Radio Call Sign</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.voyageDetails.customsRadioCallSign.radioCallSign}}
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Port Of Loading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.departurePort.name}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-md-6 ui-g-6 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>House Bill Of Lading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.hawbNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Ocean Bill Of Lading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.mawbNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Master Cargo Carrier</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.oceanLineDetails.carrierCode.code}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right border-left border-bottom">
        <span><strong>Port of Entry</strong></span>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.dischargePort.name}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Shipment Type</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.containers[0].containerType.code}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Date of Shipment</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingFile.masterBill.mawbDate}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Country of Export</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{forwardingHawb.departurePort.countryCode}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12 ui-g-nopad marks-and-numbers border-left border-right border-bottom">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Marks and Numbers</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Number & Kind of Packages</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Description of Goods</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <div class="ui-md-6 ui-g-6 ui-g-nopad">
          <strong><i>Gross Weight</i></strong>
        </div>
        <div class="ui-md-6 ui-g-6 ui-g-nopad">
          <strong><i>Measurement</i></strong>
        </div>
      </div>
      <div class="ui-md-12 ui-g-12 containers">
        <div class="ui-md-3 ui-g-3" [ngClass]="{'ui-g-offset-3':transportMethod==='AIR','ui-g-offset-9':transportMethod==='SEA'}">
          <div class="ui-md-6 ui-g-6 ui-g-nopad">
            {{forwardingHawb.measures.grossWeight}} kg
          </div>
          <div class="ui-md-6 ui-g-6 ui-g-nopad">
            {{forwardingHawb.measures.volume}} m<sup>3</sup>
          </div>
        </div>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-6 ui-g-6">
          <ng-container *ngFor="let container of forwardingHawb.containers">
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <div class="ui-md-8 ui-g-8 ui-g-nopad">
                {{container.containerNo}}
              </div>
              <div class="ui-md-4 ui-g-4 ui-g-nopad">
                {{container.containerType.code}}
              </div>
            </div>
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              {{container.noOfPackages}}
            </div>
          </ng-container>
        </div>
        <div *ngIf="isReleaseLetter" class="ui-md-12 ui-g-12 footer">
          <div class="ui-g">
            <div class="authorisation text-uppercase ui-md-12 ui-g-12">
              WE {{companyName}} AUTHORISE SHIPPING LINE {{forwardingFile?.masterBill?.oceanLineDetails.oceanLine.name}} TO RELEASE SHIPMENT TO CLEARING AGENT {{forwardingHawb?.localAgent?.name}}.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 text-uppercase">
    Description of Goods:
  </div>
  <div class="ui-md-12 ui-g-12">
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Inco Terms: COST AND FREIGHT
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Date: {{getDate()}}
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Time: {{getTime()}}
    </div>
  </div>
</div>
