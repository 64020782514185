import {Component, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Subscription} from 'rxjs';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {AdditionalInfoBehaviourSubject} from '../../../../subjects/addittional-info-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {ContainerBehaviorSubject} from '../../../../subjects/container-behavior-subject';
import {ShareDataService} from '../../services/share-data.service';
import {ExportFile} from '../../models/exportFile';
import {ExportFileService} from '../../services/export-file.service';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';

@Component({
  selector: 'digi-export-file-search',
  templateUrl: './export-file-search.component.html',
  styleUrls: ['./export-file-search.component.scss']
})
export class ExportFileSearchComponent implements OnInit, OnDestroy {
  clearingFileNumberDiffer: KeyValueDiffer<string, any>;
  filteredExportFiles: any[];
  exportFile: ExportFile;
  listExportFiles: any[];
  @Output() selectedExportFile: EventEmitter<any> = new EventEmitter();
  @ViewChild('correctionList') correctionList: OverlayPanel;
  subscription: Subscription;

  constructor(
    private exportFileService: ExportFileService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
    private billOfEntryService: BillOfEntryService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private shareDataService: ShareDataService,
    private differs: KeyValueDiffers
  ) {
    this.clearingFileNumberDiffer = this.differs.find({}).create();
  }

  ngOnInit() {
    this.subscription = this.exportFileBehaviourSubject.getExportFile().subscribe(ef => {
      this.exportFile = ef;
      if (this.exportFile.clearingFileNumber !== null && this.exportFile.clearingFileNumber !== undefined) {
        this.exportFileService.loadAllExportFileCorrectionsIncludingOriginalExportFile(this.exportFile.clearingFileNumber)
          .subscribe(data => {
            this.listExportFiles = data;
          });
      }
    });
  }

  search(clearingFileNumber) {
    this.exportFileService
      .loadExportFile(clearingFileNumber.query)
      .subscribe(data => {
        this.filteredExportFiles = data;
      });
  }

  selectInitial(value) {
    if (value.type !== 'Correction') {
      this.exportFileService.selectExportFile(value);
    }
  }

  selectCorrection(value) {
    this.exportFileService.selectExportFile(value);
    this.correctionList.hide();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
