import {ExportsBillOfEntryService} from '../../digi-common/services/exports-bill-of-entry.service';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ManifestService} from '../../digi-common/services/manifest.service';
import {RoadManifest} from '../../digi-common/models/roadManifest';
import {CrewMember} from '../../digi-common/models/file';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../digi-common/services/notification.service';
import {CompanyService} from '../../digi-common/services/company.service';
import {AgentService} from '../../digi-common/services/agent.service';
import {Branch} from '../../digi-common/models/company';

@Component({
  selector: 'digi-manifest',
  templateUrl: './manifest.component.html',
  styles: [`
    .ui-steps-item {
      width: 20%
    }
      .color-white {
          color: white !important;
      }

      ::-webkit-input-placeholder {
          color: white;
      }

      :-ms-input-placeholder {
          color: white;
      }

      ::placeholder {
          color: white;
      }

      .page {
          border: 9px solid transparent !important;
          background-clip: content-box !important;
          -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
          border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      }`],
  encapsulation: ViewEncapsulation.None
})
export class ManifestComponent implements OnInit, OnDestroy {
  disableButton: boolean;
  items: MenuItem[];
  activeIndex = 0;
  roadManifest: RoadManifest = new RoadManifest();
  selectedCrewMember: CrewMember = new CrewMember();
  view = 'register';
  listManifestFiles: any[];
  hawbNo: string;

  manifestServiceSubscription: Subscription;
  boeServiceSubscription: Subscription;

  constructor(
    private manifestService: ManifestService,
    private route: Router,
    private boeService: ExportsBillOfEntryService,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private localAgentService: AgentService
  ) {
  }

  ngOnInit() {
    this.createNewRoadManifest();
    this.items = [
      {
        label: 'Registration',
        command: (event: any) => {
          this.activeIndex = 0;
          this.view = 'register';
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Waybills',
        command: (event: any) => {
          this.activeIndex = 1;
          this.view = 'waybills';
        },
        icon: 'fa fa-fw fa-calendar'
      },
      {
        label: 'Manifest',
        command: (event: any) => {
          this.activeIndex = 2;
          this.view = 'manifest';
        },
        icon: 'fa fa-file'
      }
    ];
    this.hawbNo = this.getManifestNo(this.route.url);
    if (this.hawbNo) {
      this.manifestServiceSubscription = this.manifestService.findRoadManifestsByHawbNo(this.hawbNo).subscribe(
        (roadManifests: RoadManifest[]) => {
          if (roadManifests && roadManifests.length > 0) {
            this.roadManifest = roadManifests[0];
          } else {
            this.boeServiceSubscription = this.boeService.findByHawbNoOrMawbNo(this.hawbNo).subscribe(
              boes => {
                if (boes && boes.length > 0) {
                  boes.forEach(boe => {
                    const foundEntry = this.roadManifest.waybills.find(el => el.lrn === boe.lrn && boe.transportDocNo === el.hawbNo);
                    if (!foundEntry) {
                      this.manifestService.createWaybill(this.roadManifest, boe);
                    }
                  });
                }
                this.disableButton = false;
              }
            );
          }
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.manifestServiceSubscription) {
      this.manifestServiceSubscription.unsubscribe();
    }

    if (this.boeServiceSubscription) {
      this.boeServiceSubscription.unsubscribe();
    }
  }

  private getManifestNo(url): string {
    if (url.split('/').length === 5) {
      return this.route.url.split('/')[4];
    }
  }

  updateRoadManifest(roadManifest: RoadManifest) {
    this.roadManifest = roadManifest;
  }

  private createNewRoadManifest() {
    this.roadManifest = this.manifestService.createNewManifest();
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = this.roadManifest.branch ? companies[0].branches
          .find(el => el.code === this.roadManifest.branch) : companies[0].branches[0];
        if (branch && branch.localAgentCode && branch.localAgentCode.trim() !== '') {
          this.localAgentService
            .findLocalAgentNameOrCodeStartsWith(branch.localAgentCode)
            .subscribe(data => {
              if (data && data.length) {
                const localAgent = data[0];
                localAgent.address = localAgent.addresses[0];
                this.roadManifest.localAgent = localAgent;
              }
            });
        }
      }
    });
  }

  saveRoadManifest() {
    if (!this.roadManifest.carn) {
      this.manifestServiceSubscription = this.manifestService.saveRoadManifest(this.roadManifest).subscribe(
        (roadManifest: RoadManifest) => {
          this.roadManifest = roadManifest;
          this.notificationService.successNotify('Manifest Saved Successfully');
          this.disableButton = false;
        },
        _ => this.disableButton = false
      );
    }
  }
}
