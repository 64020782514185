import {Component, OnInit} from '@angular/core';
import {ForwardingFileService} from '../../digi-common/services/forwarding-file.service';
import {HouseBill} from '../../digi-common/models/houseBill';
import {SelectItem} from 'primeng/api';
import {NotificationService} from '../../digi-common/services/notification.service';

@Component({
  selector: 'digi-hawb-scanning',
  templateUrl: './hawb-scanning.component.html',
  styleUrls: ['./hawb-scanning.component.scss']
})
export class HawbScanningComponent implements OnInit {

  houseBill: string;
  filteredHouseBillNos?: string[] = [];
  houseBillsToUpdate?: HouseBill[] = [];
  packageTrackingStatuses: SelectItem[];
  packageStatus?: any = null;
  displayProgressBar = false;

  cols: any[];

  constructor(
    private forwardingFileService: ForwardingFileService,
    private messageService: NotificationService
  ) { }

  ngOnInit() {
    this.cols = [
      {header: 'HAWB No'},
      {header: 'Current Status'},
      {header: 'Importer'},
      {header: 'File Number'}
    ];

    this.packageTrackingStatuses = [];
    this.packageTrackingStatuses.push({label: '-- SELECT STATUS --', value: null, disabled: true});
    this.packageTrackingStatuses.push({label: 'AT WAREHOUSE', value: 'AT WAREHOUSE'});
    this.packageTrackingStatuses.push({label: 'OUT FOR DELIVERY', value: 'OUT FOR DELIVERY'});
    this.packageTrackingStatuses.push({label: 'DELIVERED', value: 'DELIVERED'});
  }

  searchHouseBills(query: any) {
    this.forwardingFileService.getHouseBillByHouseBillNumber(query)
      .subscribe(
        houseBill => {
          if (houseBill['houseBillNumber']) {
            if (houseBill['houseBillNumber'].toString().length > 1) {
              if (!(this.houseBillsToUpdate.filter(h => h.houseBillNumber === (houseBill['houseBillNumber'])).length > 0)) {
                this.houseBillsToUpdate.unshift(houseBill);
                this.houseBillsToUpdate = [...this.houseBillsToUpdate];
                this.clearInput();
              }
              this.houseBill = null;
              this.clearInput();
            }
          }
        });
  }

  clearInput() {
    this.filteredHouseBillNos.splice(0, 1);
    this.filteredHouseBillNos = [...this.filteredHouseBillNos];
  }

  onRowDelete(rowIndex: number) {
    this.houseBillsToUpdate.splice(rowIndex, 1);
    this.houseBillsToUpdate = [...this.houseBillsToUpdate];
  }

  updateHouseBills() {
    this.displayProgressBar = true;
    if (this.packageStatus !== null) {
      this.forwardingFileService.updateScannedHouseBills(this.houseBillsToUpdate, this.packageStatus).subscribe(
        data => {
          this.houseBillsToUpdate = [];
          this.houseBillsToUpdate = <HouseBill[]>JSON.parse(JSON.stringify(data));
          if (this.houseBillsToUpdate.length > 0) {
            this.saveConfirmation();
          }
        },
        (error => {
          console.log(error);
        }));
    }
    this.displayProgressBar = false;
  }

  onUpdateIsDisabled() {
    return ((this.packageStatus === null) || (this.houseBillsToUpdate.length === 0)) ? true : false;
  }

  saveConfirmation() {
    this.messageService.successNotify('HAWBs Updated Successfully');
  }

  onDisplayProgressBar() {
    return this.displayProgressBar;
  }

  displayTotalHAWBsLabel() {
    if (this.houseBillsToUpdate.length > 0) {
      return true;
    }
    return false;
  }
}
