import {map, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {EMPTY} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {Address} from '../models/file';
import {ExportFileBehaviourSubject} from '../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../models/exportFile';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';

@Injectable({
  providedIn: 'root'
})
export class ExporterService {

  constructor(private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private http: HttpClient,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findExporterNameOrCodeStartsWith(name: string) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=`
        + name + '&roleType=12' + '&projection=flattenedExporter')
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findExporterFromExportFile(exportFile: ExportFile) {
    if (exportFile.exporter && exportFile.exporter.code) {
      return this.findExporterNameOrCodeStartsWith(exportFile.exporter.code).pipe(
        tap(
          exporterData => {
            if (exporterData) {
              this.exportFileBehaviourSubject.addExportConsignees(exporterData[0].exporterConsignees);
              this.exportFileBehaviourSubject.addDebtors(exporterData[0].debtors);
              this.exportFileBehaviourSubject.addOverseasAgents(exporterData[0].overseasAgents);
              if (exporterData && (!exportFile.exporter.address || !exportFile.exporter.address.line1)) {
                const exporterAddresses = exporterData[0].addresses;
                let exporterAddress;
                if (exporterAddresses.length === 1) {
                  exporterAddress = exporterAddresses[0];
                } else {
                  exporterAddress = exporterAddresses.find(address => {
                    const addressType = address.addressTypes.find(addressType => {
                      return addressType.id === 'CUS';
                    });
                    if (addressType) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                }
                if (exporterAddress) {
                  exportFile.exporter.address = new Address();
                  exportFile.exporter.address.line1 = exporterAddress.line1;
                  exportFile.exporter.address.line2 = exporterAddress.line2;
                  exportFile.exporter.address.line3 = exporterAddress.line3;
                  exportFile.exporter.address.line4 = exporterAddress.line4;
                  exportFile.exporter.address.zipCode = exporterAddress.zipCode;
                }
              }

            }
          }
        )
      );
    } else {
      return EMPTY;
    }
  }
}
