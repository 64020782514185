import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Country} from 'src/app/digi-common/models/billOfEntries';
import {TradeAgreement} from '../../../models/file';

@Component({
  selector: 'digi-trade-agreement',
  templateUrl: './trade-agreement.component.html',
  styleUrls: ['./trade-agreement.component.css']
})
export class TradeAgreementComponent implements OnChanges {
  @Input() country: Country;
  @Input() selectedTradeAgreement: TradeAgreement;
  @Output() updateTradeAgreement = new EventEmitter();
  @Output() updateROO = new EventEmitter();
  filteredTradeAgreements: TradeAgreement[];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTradeAgreement && changes.selectedTradeAgreement.currentValue) {
      this.selectedTradeAgreement = changes.selectedTradeAgreement.currentValue;
      this.updateROO.emit(this.selectedTradeAgreement);
    }

    if (changes.country && changes.country.currentValue) {
      this.country = changes.country.currentValue;

      if (!this.selectedTradeAgreement || (this.selectedTradeAgreement && !this.selectedTradeAgreement.code)) {
        this.selectedTradeAgreement = new TradeAgreement();
        if (this.country.defaultTradeAgreement) {
          this.selectedTradeAgreement.code = this.country.defaultTradeAgreement.code;
          this.selectedTradeAgreement.description = this.country.defaultTradeAgreement.description;
        } else if (this.country.tradeAgreements.length > 0) { // this.country.tradeAgreements are empty on quotation new line
          this.selectedTradeAgreement.code = this.country.tradeAgreements[0].code;
          this.selectedTradeAgreement.description = this.country.tradeAgreements[0].description;
        }
        this.updateTradeAgreement.emit(this.selectedTradeAgreement);
      } else {
        const found = this.country.tradeAgreements.find(el => el.code === this.selectedTradeAgreement.code);
        if (!found) {
          this.selectedTradeAgreement = new TradeAgreement();
          this.selectedTradeAgreement.code = this.country.defaultTradeAgreement.code;
          this.selectedTradeAgreement.description = this.country.defaultTradeAgreement.description;
          this.updateTradeAgreement.emit(this.selectedTradeAgreement);
        }
      }
    }
  }

  search() {
    this.filteredTradeAgreements = [...this.country.tradeAgreements];
  }

  select(value) {
    this.selectedTradeAgreement = value;
    this.updateTradeAgreement.emit(this.selectedTradeAgreement);
  }
}
