/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i2 from "primeng/components/steps/steps";
import * as i3 from "./transport-steps-navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../subjects/clearingfile-behaviour-subject";
import * as i6 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i7 from "../saveClearingFile";
import * as i8 from "../../../subjects/transport-document-behaviour-subject";
import * as i9 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i10 from "../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i11 from "../services/notification.service";
import * as i12 from "../services/validation.service";
var styles_TransportStepsNavigationComponent = ["\n      .ui-steps-item {\n          width: 14.2%\n      }\n  "];
var RenderType_TransportStepsNavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransportStepsNavigationComponent, data: {} });
export { RenderType_TransportStepsNavigationComponent as RenderType_TransportStepsNavigationComponent };
export function View_TransportStepsNavigationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Steps_0, i1.RenderType_Steps)), i0.ɵdid(1, 49152, null, 0, i2.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"], styleClass: [3, "styleClass"] }, { activeIndexChange: "activeIndexChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; var currVal_3 = ((_co.transportMethod === "sea") ? "sea-steps" : "air-steps"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TransportStepsNavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-transport-steps-navigation", [], null, null, null, View_TransportStepsNavigationComponent_0, RenderType_TransportStepsNavigationComponent)), i0.ɵdid(1, 245760, null, 0, i3.TransportStepsNavigationComponent, [i4.Router, i5.ClearingFileBehaviourSubject, i6.BillOfEntryBehaviourSubject, i7.SaveClearingFile, i8.TransportDocumentBehaviourSubject, i9.SupplierInvoiceBehaviourSubject, i10.SupplierInvoiceLineBehaviourSubject, i11.NotificationService, i12.ValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransportStepsNavigationComponentNgFactory = i0.ɵccf("digi-transport-steps-navigation", i3.TransportStepsNavigationComponent, View_TransportStepsNavigationComponent_Host_0, { transportMethod: "transportMethod" }, {}, []);
export { TransportStepsNavigationComponentNgFactory as TransportStepsNavigationComponentNgFactory };
