import {Component, Input, OnInit} from '@angular/core';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {combineLatest, EMPTY, Subscription} from 'rxjs';
import {ClearingInstruction} from '../../../models/file';
import {switchMap, tap} from 'rxjs/operators';
import {Warehouse} from '../../../../digi-business-entity/models/business-entity';
import {WarehouseService} from '../../../services/warehouse.service';

@Component({
  selector: 'digi-app-warehouse-details',
  templateUrl: './warehouse-details.component.html',
  styleUrls: ['./warehouse-details.component.scss']
})
export class WarehouseDetailsComponent implements OnInit {
  @Input() code;
  subscription: Subscription;
  clearingInstruction: ClearingInstruction;
  warehouses: Warehouse[];
  warehouseAddresses = [];
  filteredWarehouses = [];

  constructor(private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private warehouseService: WarehouseService) {
  }

  ngOnInit() {
    const warehouseObservable = this.clearingInstructionBehaviorSubject.getWarehouses();
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    this.subscription = combineLatest(instructionsObservable, warehouseObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingInstruction = tuple[0];
            if (this.clearingInstruction) {
              this.clearingInstruction.warehouse = Object.assign({}, this.clearingInstruction.warehouse);
            }
          }
          if (tuple[1]) {
            this.warehouses = tuple[1];
          }
        }),
      switchMap((tuple: any[]) => {
        if (this.clearingInstruction && this.warehouses) {
          return this.warehouseService.findWarehouseFromClearingInstructionAndWarehouses(this.clearingInstruction, this.warehouses);
        }
        return EMPTY;
      })
    ).subscribe(warehouse => {
      this.warehouseAddresses = warehouse[0].addresses;
    });
  }

  search(event) {
    this.filteredWarehouses = [];
    for (let i = 0; i < this.warehouses.length; i++) {
      const warehouse: Warehouse = this.warehouses[i];
      if (
        warehouse.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
        warehouse.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0

      ) {
        this.filteredWarehouses.push(warehouse);
      }
    }
  }

  selectWarehouse(warehouse) {
    this.warehouseService.findWarehouseFromClearingInstruction(this.clearingInstruction, warehouse)
      .subscribe(result => {
        this.warehouseAddresses = result[0].addresses;
      });
  }

  isRequired() {
    return !this.code;
  }

}
