import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { ClearingFile } from '../../models/clearingFile';
import { TransportDocument, VesselsAgent } from '../../models/file';
import { VesselsAgentService } from '../../services/vessels-agent.service';

@Component({
  selector: 'digi-app-vessels-agent',
  templateUrl: './vessels-agent.component.html',
  styleUrls: ['./vessels-agent.component.scss']
})
export class VesselsAgentComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  filteredVesselsAgents: VesselsAgent[];
  subscribe: Subscription;
  tdSubscribe: Subscription;

  constructor(private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private vesselsAgentService: VesselsAgentService) {
  }

  ngOnInit() {

    this.subscribe = this.clearingFileBehaviourSubject.getClearingFile()
      .subscribe(cf => {
        if (cf) {
          this.clearingFile = cf;
        }
      });

    this.tdSubscribe = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
    this.tdSubscribe.unsubscribe();
  }

  search(event) {
    this.vesselsAgentService.findVesselsAgentWithCodeOrName(event.query)
      .subscribe(data => {
        console.log(data);
        this.filteredVesselsAgents = data;
      });
  }

  select(value) {
    this.selectedTransportDocument.vesselsAgent = value;
  }

}
