import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-tariff-maintenance-description',
  templateUrl: './tariff-maintenance-description.component.html',
  styleUrls: ['./tariff-maintenance-description.component.scss']
})
export class TariffMaintenanceDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
