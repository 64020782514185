<digi-loading></digi-loading>
<div class="layout-wrapper" (click)="onWrapperClick()"
     [ngClass]="{'layout-wrapper-static': layoutMode === 'static',
                'layout-wrapper-active': mobileMenuActive,
                'layout-menu-horizontal': layoutMode === 'horizontal',
                'layout-rtl': isRTL}">

  <digi-menu [reset]="resetMenu"></digi-menu>
  <div class="layout-main">
    <digi-app-topbar></digi-app-topbar>
    <digi-app-breadcrumb></digi-app-breadcrumb>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <digi-app-footer></digi-app-footer>
    <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
  </div>
</div>
<digi-notification></digi-notification>
