import {Inject, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class ContainerTypeService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAllContainerTypeStartsWith(searchValue) {
    return this.http
      .get(`${this.config.apiEndpoint}api/containerTypes/search/codeStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].containerTypes)
      );
  }
}
