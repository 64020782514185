import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Debtor} from '../app/digi-common/models/file';
import {Warehouse} from '../app/digi-business-entity/models/business-entity';

@Injectable({
  providedIn: 'root'
})
export class WarehouseBehaviourSubject {
  private warehousesSubject = new Subject<Warehouse[]>();

  constructor() {
  }

  addWarehouses(debtors: Debtor[]) {
    this.warehousesSubject.next(debtors);
  }

  getWarehouses(): Observable<Debtor[]> {
    return this.warehousesSubject.asObservable();
  }
}
