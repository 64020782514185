import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentsReportService} from '../../services/payments-report.service';
import {NotificationService} from '../../services/notification.service';
import {WarehouseService} from '../../services/warehouse.service';
import {ImporterService} from '../../services/importer.service';
import {CustomOfficeService} from '../../services/custom-office.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';
import {CustomsOffice, Warehouse, Importer} from '../../../digi-business-entity/models/business-entity';
import {Subscription} from 'rxjs';
import {BillOfEntry, SequenceData} from '../../models/billOfEntries';
import {ImportsReportService} from '../../services/imports-report.service';

@Component({
  selector: 'digi-import-bond-store-reports',
  templateUrl: './import-bond-store-reports.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  `]
})
export class ImportBondStoreReportsComponent implements OnInit, OnDestroy {
  mrnNumber: string;
  importer: Importer;
  filteredImporters: Importer[];
  warehouse: Warehouse;
  filteredWarehouses: Warehouse[];
  fromDate: string;
  toDate: string;
  display = false;
  pdfSrc: string;
  disableButton: boolean;
  generatedFiles: any[];
  private reportSubscription: Subscription;
  private importerSubscription: Subscription;
  private warehouseSubscription: Subscription;

  constructor(
    private importReportsService: ImportsReportService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
    private warehouseService: WarehouseService
  ) {
  }

  ngOnInit() {
  }

  searchWarehouse(event) {
    this.warehouseService.findWarehouseByWarehouseNumber(event.query).subscribe(data => {
      const matchedWarehouses = [];
      data.forEach( warehouse => {
        const matched = this.importer.warehouses.find(w => w.code === warehouse.code);
        if (matched) {
          matchedWarehouses.push(warehouse);
        }
      });
      this.filteredWarehouses = matchedWarehouses;
    });
  }

  searchImporter(event) {
    this.importerSubscription = this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  generate() {
    this.disableButton = true;
    this.reportSubscription = this.importReportsService.generateImportBondStoreReports(this.fromDate, this.toDate, this.importer ? this.importer.code : null, this.importer ? this.importer.name : null ,this.warehouse ? this.warehouse.code : null, this.warehouse ? this.warehouse.name : null, this.mrnNumber ? this.mrnNumber : '').subscribe(data => {
      this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
    },
    error => {
      this.notificationService.errorNotify(error.error.message, error.error.errors);
      this.pdfSrc = null;
      this.disableButton = false;
    });
  }

  showDialog() {
    this.display = true;
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  ngOnDestroy(): void {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
    if (this.importerSubscription) {
      this.importerSubscription.unsubscribe();
    }
    if (this.warehouseSubscription) {
      this.warehouseSubscription.unsubscribe();
    }
  }
}
