import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SupplierInvoiceLineBehaviourSubject = /** @class */ (function () {
    function SupplierInvoiceLineBehaviourSubject() {
        this.supplierInvoiceLineSubject = new BehaviorSubject(null);
        this.countryOfOriginSubject = new BehaviorSubject(null);
    }
    SupplierInvoiceLineBehaviourSubject.prototype.addSupplierInvoiceLine = function (supplierInvoice) {
        this.supplierInvoiceLineSubject.next(supplierInvoice);
    };
    SupplierInvoiceLineBehaviourSubject.prototype.getSupplierInvoiceLine = function () {
        return this.supplierInvoiceLineSubject.asObservable();
    };
    SupplierInvoiceLineBehaviourSubject.prototype.addCountry = function (countryOfOrigin) {
        this.countryOfOriginSubject.next(countryOfOrigin);
    };
    SupplierInvoiceLineBehaviourSubject.prototype.getCountry = function () {
        return this.countryOfOriginSubject.asObservable();
    };
    SupplierInvoiceLineBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function SupplierInvoiceLineBehaviourSubject_Factory() { return new SupplierInvoiceLineBehaviourSubject(); }, token: SupplierInvoiceLineBehaviourSubject, providedIn: "root" });
    return SupplierInvoiceLineBehaviourSubject;
}());
export { SupplierInvoiceLineBehaviourSubject };
