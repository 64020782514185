<p-toolbar>
  <div class="ui-toolbar-group-left">
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Schedule Part</span>
          <p-autoComplete [(ngModel)]="searchedTariffCode.schedule"
                          name="code"
                          [suggestions]="filteredScheduleParts"
                          (completeMethod)="searchSchedulePart($event)"
                          [autoHighlight]="true"
                          (onSelect)="select($event)"
                          minLength="1"
                          required="true">
            <ng-template let-option pTemplate="item">
              <div>{{option.code}}</div>
            </ng-template>
          </p-autoComplete>
          <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewDependent()"></button>
    <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveNewDependent()"></button>
  </div>
</p-toolbar>
<p-panel header="Tariff Details">
  <div class="ui-g">
    <div class="ui-g-6 pt-2">
      <p-panel header="Tariff Section">
        <div class="ui-g ui-fluid pt-2">
          <div class="ui-g-12 ui-g-nopad">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">Tariff Code</span>
              <input type="text" [(ngModel)]="searchedTariffCode.code" pInputText>
              <div class="ui-g-nopad ui-g-12">
                <input type="text" [(ngModel)]="searchedTariffCode.dependantCode" pInputText>
              </div>
              <div class="ui-g-nopad ui-g-2">
                <input type="text" [(ngModel)]="searchedTariffCode.dependantCheckDigit" pInputText/>
              </div>
              <button pButton type="button" label="search" (click)="search();openModel()"></button>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid pt-2">
          <div class="ui-g-4 ui-g-nopad">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">Level</span>
              <input type="text" readonly pInputText/>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid pt-2">
          <div class="ui-g-6 ui-g-nopad">
            <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">
              Tariff ?
            </span>
              <span class="ui-inputgroup-addon">
              <p-checkbox [(ngModel)]="searchedTariffCode.tariff" binary="true"></p-checkbox>
            </span>
            </div>
          </div>
          <div class="ui-g-6 ui-g-nopad">
            <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">
             Valuation - Exempt ?
            </span>
              <span class="ui-inputgroup-addon">
              <p-checkbox [(ngModel)]="searchedTariffCode.valuationExempt" binary="true"></p-checkbox>
            </span>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-g-nopad pt-2">
            <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">
             Mandatory ?
            </span>
              <span class="ui-inputgroup-addon">
              <p-checkbox [(ngModel)]="searchedTariffCode.mandatoryLink" binary="true"></p-checkbox>
            </span>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid pt-2">
          <div class="ui-g-12 ui-g-nopad">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">Link Schedule Part</span>
              <div class="ui-g-nopad ui-g-2">
                <input type="text" [(ngModel)]="searchedTariffCode.linkSchedPart" pInputText>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-4 pt-2">
      <p-panel header="Effective Section">
        <div class="ui-g">
          <div class="ui-fluid">
            <div class="ui-g-12 ui-g-nopad pt-2">
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon">Effective From</span>
                <p-calendar dateFormat="dd/mm/yy"
                            [(ngModel)]="searchedTariffCode.tariffEffectiveFrom"
                            dataType="string"
                            [showIcon]="true"></p-calendar>
              </div>
            </div>
            <div class="ui-g-12 ui-g-nopad pt-2">
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon">Effective Until</span>
                <p-calendar dateFormat="dd/mm/yy"
                            dataType="string"
                            [(ngModel)]="searchedTariffCode.tariffEffectiveUntil"
                            [showIcon]="true"></p-calendar>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 pt-2">
      <p-panel header="Industry Section">
        <div class="ui-g">
          <div class="ui-fluid">
            <textarea class="ui-inputtextarea hideResize" rows="5"
                      cols="175" pInputTextarea></textarea>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 pt-2">
      <p-panel header="Short Description Section">
        <div class="ui-g">
          <div class="ui-fluid">
            <textarea class="ui-inputtextarea hideResize" [(ngModel)]="searchedTariffCode.tariffSDesc" rows="5"
                      cols="175" pInputTextarea></textarea>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 pt-2">
      <p-panel header="Current Customs Description Section">
        <div class="ui-g">
          <div class="ui-fluid">
            <textarea class="ui-inputtextarea hideResize"
                      rows="5"
                      [(ngModel)]="searchedTariffCode.fullDescription"
                      cols="200" pInputTextarea></textarea>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="ui-g">
      <div class="ui-fluid">
        <div class="ui-g-12 ui-g-nopad pt-2">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">Description Effective From</span>
            <p-calendar dateFormat="dd/mm/yy"
                        dataType="string"
                        [showIcon]="true"></p-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-panel>
<p-dialog header="Tariff Code And Dependent Code" [(visible)]="display" [modal]="true" [responsive]="true"
          [width]="400"
          [minWidth]="200"
          [minY]="80" [maximizable]="true" [baseZIndex]="10000">
  <p-table [columns]="cols" [value]="filteredTariffCodeAndDependentCodes" selectionMode="single"
           [(selection)]="selecteTariffCodeAndDependentCode"
           [resizableColumns]="true"
           (onRowSelect)="onRowSelect($event)" [resizableColumns]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td class="ui-resizable-column">
          <label>{{rowData.code}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.dependantCode}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
