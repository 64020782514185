import {Component, OnInit} from '@angular/core';
import {ClearingFile, Importer} from '../../../models/clearingFile';
import {ClearingInstruction, Consignee, Guarantor, LocalAgent, Warehouse} from '../../../models/file';
import {combineLatest, Subscription} from 'rxjs';
import {SelectItem} from 'primeng/primeng';
import {AgentService} from '../../../services/agent.service';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'digi-consignee',
  templateUrl: './consignee.component.html',
  styleUrls: ['./consignee.component.scss']
})
export class ConsigneeComponent implements OnInit {
  filteredConsignees: Guarantor[];
  subscription: Subscription;
  subscribe: Subscription;
  clearingFile: ClearingFile;
  clearingInstruction: ClearingInstruction;
  consigneeAddresses = [];
  display: boolean;
  role: SelectItem[];
  selectedRole = 'Customs Office';
  importer: Importer;
  localAgent: LocalAgent;

  constructor(private localAgentService: AgentService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private messageService: NotificationService
  ) {
    this.role = [
      {label: 'Customs Office', value: 'Customs Office'},
      {label: 'Importer', value: 'Importer'},
      {label: 'Local Agent', value: 'Local Agent'},
      {label: 'Overseas Agent', value: 'Overseas Agent'},
      {label: 'Warehouse', value: 'Warehouse'},
    ];
  }

  ngOnInit() {
    const clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    this.subscription = combineLatest(clearingFileObservable, instructionsObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingFile = tuple[0];
          }
          if (tuple[1]) {
            this.clearingInstruction = tuple[1];
          }
        })
    ).subscribe(consignee => {
    });

    if (this.clearingInstruction.consignee && this.clearingInstruction.consignee.roleType) {
      this.selectedRole = this.clearingInstruction.consignee.roleType;
      this.roleChanged();
    }
  }

  roleChanged() {
    this.filteredConsignees = [];
    if (this.selectedRole === 'Local Agent') {
      if (this.clearingFile.localAgent.code === undefined) {
        this.localAgent = new LocalAgent();
        this.localAgent = this.clearingFile.localAgent;
        this.clearingInstruction.consignee = new Consignee();
        this.clearingInstruction.consignee.label = null;
        this.messageService.errorNotify('Enter a Local Agent');
      }
    } else if (this.selectedRole === 'Importer') {
      if (this.clearingFile.importer.code) {
        this.importer = new Importer();
        this.importer = this.clearingFile.importer;
      } else {
        this.clearingInstruction.consignee.label = null;
        this.messageService.errorNotify('Enter a Importer');
      }
    }
  }
}
