import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'digi-boe-lines-tab-tariff-description-section',
  templateUrl: './boe-lines-tab-tariff-description-section.component.html',
  styleUrls: ['./boe-lines-tab-tariff-description-section.component.scss']
})
export class BoeLinesTabTariffDescriptionSectionComponent implements OnInit {
  @Input('selectedBillOfEntryLine') selectedBillOfEntryLine;
  constructor() { }

  ngOnInit() {
  }

}
