/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "./emptydemo.component";
import * as i4 from "../../breadcrumb.service";
var styles_EmptyDemoComponent = [];
var RenderType_EmptyDemoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmptyDemoComponent, data: {} });
export { RenderType_EmptyDemoComponent as RenderType_EmptyDemoComponent };
export function View_EmptyDemoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Empty Page"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Use this page to start from scratch and place your custom content."])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["href", "#"], ["label", "Home"], ["pButton", ""]], null, null, null, null, null)), i0.ɵprd(512, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(9, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef, i1.DomHandler], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Home"; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_EmptyDemoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EmptyDemoComponent_0, RenderType_EmptyDemoComponent)), i0.ɵdid(1, 49152, null, 0, i3.EmptyDemoComponent, [i4.BreadcrumbService], null, null)], null, null); }
var EmptyDemoComponentNgFactory = i0.ɵccf("ng-component", i3.EmptyDemoComponent, View_EmptyDemoComponent_Host_0, {}, {}, []);
export { EmptyDemoComponentNgFactory as EmptyDemoComponentNgFactory };
