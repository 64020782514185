import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CuscarTypeService {

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) { }

  findByTypeOrDescription(typeOrDesc: string) {
    return this.http
      .get(`${this.config.apiEndpoint}api/cuscarTypes/search/findByTypeOrDescriptionStartsWithIgnoreCase?typeOrDesc=${typeOrDesc}`)
      .pipe(
        map(response => response['_embedded'].cuscarTypes)
      );
  }
}
