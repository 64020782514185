import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClearingFileBehaviourSubject } from '../../../../../subjects/clearingfile-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';
import { TransportService } from '../../../../digi-air/services/transport.service';
import {Container, TransportDocument} from '../../../models/file';
import { ClearingFile } from '../../../models/clearingFile';
import { ShareDataService } from '../../../services/share-data.service';
import { ClearingFileValidationService } from '../../../../../validation/clearing-file-validation.service';
import { ValidationService } from '../../../services/validation.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';

@Component({
  selector: 'digi-hawb',
  templateUrl: './hawb.component.html',
  styleUrls: ['./hawb.component.css'],
})
export class HawbComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  selectedTransportDocument: TransportDocument;
  display = false;
  initialized = false;
  subscription: Subscription;
  tdSubscription: Subscription;
  validationMessagesForMawbNo: any[] = [];
  validationMessagesForHawbNo: any[] = [];


  constructor(
    private transportService: TransportService,
    private shareDataService: ShareDataService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private clearingFileValidationService: ClearingFileValidationService,
    private containerBehaviorSubject: ContainerBehaviorSubject,
  ) {
  }

  ngOnInit() {
    this.subscription = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe((cf: ClearingFile) => {
        this.clearingFile = cf;
        if (this.clearingFile) {
        if (this.clearingFile.transportDocuments.length === 0) {
          const transportDocument: TransportDocument = this.shareDataService.addTransportDocument('imports');
          this.clearingFile.transportDocuments.push(transportDocument);
          this.clearingFile.transportDocuments = [...this.clearingFile.transportDocuments];
          this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
        } else {
          this.transportDocumentBehaviourSubject.addTransportDocument(this.clearingFile.transportDocuments[0]);
        }
        }
      });

    this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => {
        if (td) {
          this.selectedTransportDocument = td;
          this.initialized = true;
        }
      });
  }

  hasTransportDocument() {
    return this.clearingFile && this.clearingFile.transportDocuments && this.clearingFile.transportDocuments.length > 0;
  }

  addTransportDocument() {
    const transportDocument: TransportDocument = this.shareDataService.addTransportDocument('imports');
    this.clearingFile.transportDocuments.push(transportDocument);
    this.clearingFile.transportDocuments = [...this.clearingFile.transportDocuments];
    this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
  }

  validateTransportDocument(event, tabview) {
    if (event.index === 1) {
      if (this.clearingFileValidationService.validateTransportDocument(this.clearingFile, this.selectedTransportDocument, null, null)) {
        tabview.tabs[event.index].selected = true;
        tabview.tabs[0].selected = false;
        event.index = 1;
      } else {
        tabview.tabs[event.index].selected = false;
        tabview.tabs[0].selected = true;
        event.index = 0;
      }
    }
  }

  openSundryChargeModel() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  addContainer() {
    const container: Container = this.shareDataService.addContainer();
    this.selectedTransportDocument.containers.push(container);
    this.selectedTransportDocument.containers = [...this.selectedTransportDocument.containers];
    this.containerBehaviorSubject.addContainer(container);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.tdSubscription.unsubscribe();
  }
}
