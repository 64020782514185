import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digi-boe-line-original-boe',
  templateUrl: './boe-line-original-boe.component.html',
  styleUrls: ['./boe-line-original-boe.component.scss']
})
export class BoeLineOriginalBoeComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
