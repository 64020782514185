import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {ShareDataService} from '../../../digi-common/services/share-data.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../../subjects/billOfEntryLine-behaviour-subject';

@Component({
  selector: 'digi-exports-ocean-dashboard',
  templateUrl: './exports-ocean-dashboard.component.html',
  styleUrls: ['./exports-ocean-dashboard.component.scss'],
})
export class ExportsOceanDashboardComponent implements OnInit {

  constructor(private router: Router,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService) {
  }

  ngOnInit() {
    this.exportFileBehaviourSubject.addExportFile(this.shareDataService.buildExportFile('SEA'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== 'export/sea/register/') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['export', 'sea', 'register']);
    }
  }
}
