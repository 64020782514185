import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {
  Consignee,
  LocalAgent,
  ContainerReleasedTo,
  TransportDocument,
  Container
} from '../../../models/file';
import {ClearingFile, Importer} from '../../../models/clearingFile';
import {NotificationService} from '../../../services/notification.service';
import { ExportFile } from '../../../models/exportFile';
import {FileType} from '../../../models/enumerations';

@Component({
  selector: 'digi-road-storage-details',
  templateUrl: './road-storage-details.component.html',
  styleUrls: ['./road-storage-details.component.scss']
})
export class RoadStorageDetailsComponent implements OnInit, OnChanges {
  @Input() selectedContainer: Container;
  @Input() clearingFile: ClearingFile | ExportFile;
  role: SelectItem[];
  selectedRole = 'Importer';
  importer: Importer;
  @Input() fileType: FileType;

  constructor(private messageService: NotificationService) {
    this.role = [
      {label: 'Importer', value: 'Importer'}
    ];
  }

  ngOnInit() {
    if (this.selectedContainer && !this.selectedContainer.containerReleasedTo) {
      if (this.fileType === 'imports') {
        if (this.clearingFile.importer.code) {
          this.defaultImporterToContainerReleasedTo();
        }
      } else {
        if (this.clearingFile.exporter.code) {
          this.defaultImporterToContainerReleasedTo();
        }
      }
    }

    if (this.selectedContainer && !this.selectedContainer.turnInFree) {
      this.selectedContainer.turnInFree = false;
    } else {
      this.selectedContainer.turnInFree = true;
    }
  }

  ngOnChanges() {
    if (this.selectedContainer && !this.selectedContainer.containerReleasedTo) {
      if (this.clearingFile.importer.code) {
        this.defaultImporterToContainerReleasedTo();
      }
    }
  }

  private defaultImporterToContainerReleasedTo() {
    this.selectedContainer.containerReleasedTo = new ContainerReleasedTo();
    if (this.fileType === 'imports') {
      if (this.clearingFile.importer.code) {
        this.selectedContainer.containerReleasedTo.label = this.clearingFile.importer.code + ':' + this.clearingFile.importer.name;
        this.selectedContainer.containerReleasedTo.code = this.clearingFile.importer.code;
        this.selectedContainer.containerReleasedTo.name = this.clearingFile.importer.name;
        this.selectedContainer.containerReleasedTo.roleType = '6';
      }
    } else {
      if (this.clearingFile.exporter.code) {
        this.selectedContainer.containerReleasedTo.label = this.clearingFile.exporter.code + ':' + this.clearingFile.exporter.name;
        this.selectedContainer.containerReleasedTo.code = this.clearingFile.exporter.code;
        this.selectedContainer.containerReleasedTo.name = this.clearingFile.exporter.name;
        this.selectedContainer.containerReleasedTo.roleType = '12';
      }
    }
  }

  roleChanged() {

    if (this.selectedRole === 'Importer') {
      if (this.clearingFile.importer.code) {
        this.defaultImporterToContainerReleasedTo();
      } else {
        this.messageService.errorNotify('Enter a Importer');
      }
    }
  }
}
