<p-tabView orientation="left" styleClass="stepMenu" (onChange)="loadUploadedFiles($event,tabView)"  #tabView>
  <p-tabPanel header="Generate" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-boe-file-section [fileType]="'imports'">
        </digi-boe-file-section>
        <p-tabView>
          <p-tabPanel header="Bill's of Entries">
            <digi-bill-of-entry [fileType]="'imports'"></digi-bill-of-entry>
          </p-tabPanel>
          <p-tabPanel header="Mrks,Nos,Endrs">
            <digi-boe-marks-numbers [fileType]="'imports'" [file]="clearingFile"></digi-boe-marks-numbers>
          </p-tabPanel>
          <p-tabPanel header="Lines" leftIcon="pi pi-user">
            <digi-boe-lines [fileType]="'imports'" [markedAndSubmitted]="selectedBillOfEntry.markedAsSubmitted"></digi-boe-lines>
          </p-tabPanel>
          <p-tabPanel header="Consolidation Details" leftIcon="pi pi-user">
            <digi-consolidation-details-section></digi-consolidation-details-section>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Confirm BOE">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-confirmation-section [fileType]="'imports'"></digi-confirmation-section>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Supporting Documents">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-upload-documents [files]="files"></digi-upload-documents>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Email Documents">
    <ng-template pTemplate="content">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12 ui-md-12">
            <p-toolbar>
              <div class="ui-g-12 ui-md-3">
                <digi-clearing-file-search></digi-clearing-file-search>
              </div>
            </p-toolbar>
          </div>
          <digi-customs-documents-email [fileType]="'imports'"></digi-customs-documents-email>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
