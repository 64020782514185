var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ExportTransportDocument, Measures } from './file';
var TransShipmentDetails = /** @class */ (function () {
    function TransShipmentDetails() {
    }
    return TransShipmentDetails;
}());
export { TransShipmentDetails };
var ForwardingTransportDocument = /** @class */ (function (_super) {
    __extends(ForwardingTransportDocument, _super);
    function ForwardingTransportDocument() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ForwardingTransportDocument;
}(ExportTransportDocument));
export { ForwardingTransportDocument };
var TrackingDetails = /** @class */ (function () {
    function TrackingDetails() {
    }
    return TrackingDetails;
}());
export { TrackingDetails };
var MasterBillMeasures = /** @class */ (function (_super) {
    __extends(MasterBillMeasures, _super);
    function MasterBillMeasures() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MasterBillMeasures;
}(Measures));
export { MasterBillMeasures };
var DigiScan = /** @class */ (function () {
    function DigiScan() {
    }
    return DigiScan;
}());
export { DigiScan };
var RunningTotals = /** @class */ (function () {
    function RunningTotals() {
    }
    return RunningTotals;
}());
export { RunningTotals };
var ForwardingFile = /** @class */ (function () {
    function ForwardingFile() {
    }
    return ForwardingFile;
}());
export { ForwardingFile };
