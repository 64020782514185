<p-dialog [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="800"
          [minWidth]="400"
          [minY]="100"
          [maximizable]="false">
  <span>The data for the new correction file will be a copy of the data from the following file version</span>
  <div class="ui-g-12 ui-fluid">
            <span class="ui-inputgroup-addon">
              Select Sequence
            </span>
    <p-dropdown [options]="filteredFiles" [(ngModel)]="selectedSequence" appendTo="body"
                [style]="{'width':'150px'}">
      <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle;">{{item.value}}</span>
      </ng-template>
      <ng-template let-select pTemplate="item">
        <div class="ui-helper-clearfix">
          <div>{{select.value}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa fa-close" (click)="onClose();findSelectedSequenceNumber()"
            label="Ok"></button>
  </p-footer>
</p-dialog>
