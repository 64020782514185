<span class="md-inputfield">
<div class="ui-inputgroup">
  <span class="ui-float-label">
    <p-autoComplete id="tradeAgreement" [(ngModel)]="selectedTradeAgreement" field="code"
                    [suggestions]="filteredTradeAgreements"
                    (completeMethod)="search()"
                    [autoHighlight]="true" (onSelect)="select($event)" minLength="2"
                    [dropdown]="true"
                    required=true>
        <ng-template let-option pTemplate="item">
          <div>{{option.code}}</div>
        </ng-template>
    </p-autoComplete>
    <label for="tradeAgreement">Trade Agreement</label>
  </span>
</div>
   <div *ngIf="!selectedTradeAgreement?.code" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
</span>

