import { KeycloakService } from 'keycloak-angular';
import {environment} from '../environments/environment';
import { TenantService } from "../app/tenant/tenant.service";
import { AppConfig } from "../app/app.config.module";

export function initializer(keycloak: KeycloakService,  tenantService: TenantService, appConfig: AppConfig): () => Promise<any> {
    console.log("initializer method...");
    let tenantId = tenantService.tenantId;
    console.log('******** tenantId=' + tenantId);
    console.log('appConfig=', appConfig);
    // console.log('appConfig.apiEndpoint=', appConfig.apiEndpoint);
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                // let tenant = tenantService.getTenant();
                // console.log('******** tenant2=' + tenant);
                // console.log("starting keycloak init...");
                // alert('tenantId=' + tenantService.tenantId);
                await keycloak.init({
                    config: {
                        url: tenantService.oauthEndpoint,
                        realm: tenantService.oauthRealm,
                        clientId: tenantService.oauthClientId,
                    },
                    initOptions: {
                        onLoad: 'login-required',
                        // checkLoginIframe: false,
                    },
                    enableBearerInterceptor: true,
                    loadUserProfileAtStartUp: true,
                    bearerExcludedUrls: ['/assets'],
                });
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
