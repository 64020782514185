/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exports-invoice-headers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../invoice-headers/invoice-headers.component.ngfactory";
import * as i3 from "../invoice-headers/invoice-headers.component";
import * as i4 from "../../../services/share-data.service";
import * as i5 from "../../../services/clearing-file.service";
import * as i6 from "../../../services/export-file.service";
import * as i7 from "../../../services/currency.service";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../../subjects/transport-document-behaviour-subject";
import * as i10 from "../../../../../subjects/clearingfile-behaviour-subject";
import * as i11 from "../../../../../subjects/exportfile-behaviour-subject";
import * as i12 from "../../../../../subjects/supplierInvoice-behaviour-subject";
import * as i13 from "../../../services/unique-consignment-reference.service";
import * as i14 from "../../../../../subjects/unique-consignment-reference-subject";
import * as i15 from "../../../services/csv-upload.service";
import * as i16 from "../../../saveClearingFile";
import * as i17 from "../../../services/notification.service";
import * as i18 from "../../../services/validation.service";
import * as i19 from "./exports-invoice-headers.component";
var styles_ExportsInvoiceHeadersComponent = [i0.styles];
var RenderType_ExportsInvoiceHeadersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportsInvoiceHeadersComponent, data: {} });
export { RenderType_ExportsInvoiceHeadersComponent as RenderType_ExportsInvoiceHeadersComponent };
export function View_ExportsInvoiceHeadersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-invoice-headers", [["fileType", "exports"]], null, null, null, i2.View_InvoiceHeadersComponent_0, i2.RenderType_InvoiceHeadersComponent)), i1.ɵdid(1, 245760, null, 0, i3.InvoiceHeadersComponent, [i4.ShareDataService, i5.ClearingFileService, i6.ExportFileService, i7.CurrencyService, i8.FormBuilder, i1.IterableDiffers, i1.KeyValueDiffers, i9.TransportDocumentBehaviourSubject, i10.ClearingFileBehaviourSubject, i11.ExportFileBehaviourSubject, i12.SupplierInvoiceBehaviourSubject, i13.UniqueConsignmentReferenceService, i14.UniqueConsignmentBehaviourSubject, i15.CsvUploadService, i16.SaveClearingFile, i17.NotificationService, i18.ValidationService], { fileType: [0, "fileType"] }, null)], function (_ck, _v) { var currVal_0 = "exports"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExportsInvoiceHeadersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-exports-invoice-headers", [], null, null, null, View_ExportsInvoiceHeadersComponent_0, RenderType_ExportsInvoiceHeadersComponent)), i1.ɵdid(1, 114688, null, 0, i19.ExportsInvoiceHeadersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExportsInvoiceHeadersComponentNgFactory = i1.ɵccf("digi-exports-invoice-headers", i19.ExportsInvoiceHeadersComponent, View_ExportsInvoiceHeadersComponent_Host_0, {}, {}, []);
export { ExportsInvoiceHeadersComponentNgFactory as ExportsInvoiceHeadersComponentNgFactory };
