import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TradeAgreementService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findTradeAgreementByCodeStartWith(searchValue) {
    return this.http
      .get(`${this.config.apiEndpoint}api/tradeAgreements/search/codeOrNameStartsWith?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].tradeAgreements)
      );
  }

  findByCodeIgnoreCase(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/tradeAgreements/search/findByCode?code=` + code);
  }
}

