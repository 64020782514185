<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="DA65" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12">
<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="Print"
            icon="fa fa-print"
            (click)="print()"></button>
  </div>
</p-toolbar>
<div class=" ui-md-12 ui-g-12 base-text payment-file print">
  <div class="ui-g ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="pp-big-rect">
        <img src="/assets/img/sars-logo-resize.png" id="sars-logo">
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="pp-big-rect h1-text" style="font-size: 20px;">
        <span><strong>CUSTOMS AND EXCISE</strong></span><br>
        <span><strong>Registration of goods for re-importation</strong></span><br>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad align-center">
      <div class="big-rect h1-text ">
        <div class="title" style="font-size: 20px;">
          <span><strong>DA65</strong></span>
        </div>
      </div>
    </div>
  </div>  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="bigger-rect small-text">
      <span>  Notes:</span><br>
      <span>  1. This form may be used to register goods temporarily exported on form SAD 500 so as to assist in the identification of those goods on reimportation.</span><br>
      <span>  2. This form may not be used by a traveller (any person entering or leaving the Republic) in respect of a vehicle, accompanied baggage
or goods in his or her personal possession. The traveller declaration form DA 331 must be used for the registration of any vehicle (for
private or commercial use), accompanied baggage or goods for re-importation in the personal possession of a traveller. </span><br>
      <span>  3. Only articles which can be adequately described and are capable of identification beyond all doubt upon their return to the Republic may be registered.</span><br>
      <span>  4. Any person exporting goods for purposes of processing or repair must comply with item 409.04 to Schedule No. 4 of the Customs and Excise Act, 1964</span><br>
      <span>  5. A diagonal line must be drawn through the unused portion of this form and / or after the last entry in any column. </span><br>
      <span>  6. Please write neatly and clearly. Print in capital letters in black / blue pen.
</span>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-2 ui-g-2 ui-g-nopad">
      <div class="pp-small-rect" style="color: white;">
        <span>.</span>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text" style="font-size: 20px;">
        <span><strong> Identifiable Goods (Other than a self-propelled vehicle) </strong></span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <span><strong>  Full description </strong></span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <span><strong> Serial number(s) </strong></span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect ">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text" style="font-size: 20px;">
        <span><strong>  Self-Propelled Vehicle </strong></span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Make:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Model:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Year of manufacture:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Registration no.:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Diesel / Petrol:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Colour:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Engine no.:</span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  VIN / Chassis no.:</span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect ">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Description of Radio, </span><br>
          <span>  Satellite navigationsystem</span><br>
          <span>  fitted, etc:</span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text" style="font-size: 20px;">
        <span><strong>  Application and Declaration </strong></span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="bigger-rect small-text">
        <span>I...</span>
        <input type="text" style="width: 20%;">
        <span>  (name and surname), hereby </span><br>
        <span>  (a) apply to register, for the purpose of re-importation, the undermentioned goods which I have declared for export on form SAD 500 number </span><br>
        <input type="text" style="width: 20%;">
        <span>dated</span>
        <input type="text" style="width: 20%;">
        <span> at the office of the Controller</span>
        <input type="text" style="width: 20%;">
        <span>/ electronically submitted to the Commissioner (delete which is not applicable) for export through</span>
        <input type="text" style="width: 20%;">
        <span>(state place of export).</span><br>
        <span>  (b) declare that the particulars in this application are true and correct.</span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Signature</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>...........................................</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Place:</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 100%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Date (CCYYMMDD):</span>
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 100%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="pp-small-rect h2-text" style="font-size: 20px;">
        <span><strong>  FOR OFFICIAL USE </strong></span>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Date:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect">
          <span>  Controller:</span>
        </div>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 97%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect ">
          <span>  Notes:</span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>  .</span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect" style="color: white;">
          <span>. </span>
        </div>
      </div>
      <div class="ui-md-10 ui-g-10 ui-g-nopad">
        <div class="pp-small-rect">
          <input type="text" style="width: 98.8%;">
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>

