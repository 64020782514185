import { OnInit } from '@angular/core';
import { ClearingFileBehaviourSubject } from '../../subjects/clearingfile-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../subjects/supplierInvoice-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../subjects/transport-document-behaviour-subject';
import { ClearingFileService } from './services/clearing-file.service';
import { ShareDataService } from './services/share-data.service';
import { CorrectionService } from './services/correction.service';
import { tap } from 'rxjs/operators';
import { FileService } from './services/file.service';
import * as i0 from "@angular/core";
import * as i1 from "../../subjects/clearingfile-behaviour-subject";
import * as i2 from "./services/clearing-file.service";
import * as i3 from "./services/share-data.service";
import * as i4 from "../../subjects/transport-document-behaviour-subject";
import * as i5 from "../../subjects/supplierInvoice-behaviour-subject";
import * as i6 from "../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i7 from "./services/correction.service";
import * as i8 from "./services/file.service";
var SaveClearingFile = /** @class */ (function () {
    function SaveClearingFile(clearingFileBehaviourSubject, clearingFileService, shareDataService, transportDocumentBehaviourSubject, supplierInvoiceBehaviourSubject, supplierInvoiceLineBehaviourSubject, correctionService, fileService) {
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.clearingFileService = clearingFileService;
        this.shareDataService = shareDataService;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.supplierInvoiceLineBehaviourSubject = supplierInvoiceLineBehaviourSubject;
        this.correctionService = correctionService;
        this.fileService = fileService;
    }
    SaveClearingFile.prototype.ngOnInit = function () {
    };
    SaveClearingFile.prototype.saveClearingFile = function (clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        var _this = this;
        this.fileService.setIsFileSaving(true);
        if (!clearingFile.type) {
            clearingFile.type = 'ClearingFile';
            return this.clearingFileService.sendClearingFile(clearingFile).pipe(tap(function (data) {
                _this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
                _this.fileService.setIsFileSaving(false);
            }));
        }
        else if (clearingFile.type === 'Correction') {
            return this.correctionService.sendCorrection(clearingFile).pipe(tap(function (data) {
                _this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
                _this.fileService.setIsFileSaving(false);
            }));
        }
        else {
            return this.clearingFileService.sendClearingFile(clearingFile).pipe(tap(function (data) {
                _this.updateClearingFileAfterSaving(data, clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
                _this.fileService.setIsFileSaving(false);
            }));
        }
    };
    SaveClearingFile.prototype.updateClearingFileAfterSaving = function (data, clearingFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        // update clearingFile
        this.clearingFileBehaviourSubject.addClearingFile(data);
        var transportDocuments = data.transportDocuments;
        // check if transport documents are available and navigate through
        if (transportDocuments === null || transportDocuments.length === 0) {
            var transportDocument = this.shareDataService.addTransportDocument('imports');
            if (clearingFile) {
                clearingFile.transportDocuments.push(transportDocument);
                clearingFile.transportDocuments = clearingFile.transportDocuments.slice();
                this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
            }
        }
        else {
            if (selectedTransportDocument) {
                var nextTransportDocument = transportDocuments.find(function (t) { return t.hawbDate === selectedTransportDocument.hawbDate; });
                if (nextTransportDocument !== undefined) {
                    this.transportDocumentBehaviourSubject.addTransportDocument(nextTransportDocument);
                }
                else {
                    this.transportDocumentBehaviourSubject.addTransportDocument(transportDocuments[0]);
                }
                this.navigateSupplierInvoices(nextTransportDocument, transportDocuments, selectedSupplierInvoice, selectedSupplierInvoiceLine);
            }
        }
    };
    SaveClearingFile.prototype.navigateSupplierInvoices = function (selectedTransportDocument, transportDocuments, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        var _this = this;
        if (selectedTransportDocument) {
            if (selectedSupplierInvoice) {
                transportDocuments.forEach(function (t) {
                    var nextSupplierInvoice = t.supplierInvoices.find(function (s) { return s.invoiceDate === selectedSupplierInvoice.invoiceDate && s.invoiceNumber === selectedSupplierInvoice.invoiceNumber; });
                    if (nextSupplierInvoice) {
                        _this.supplierInvoiceBehaviourSubject.addSupplierInvoice(nextSupplierInvoice);
                    }
                    else {
                        _this.supplierInvoiceBehaviourSubject.addSupplierInvoice(selectedTransportDocument.supplierInvoices[0]);
                    }
                    _this.navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine);
                });
            }
        }
    };
    SaveClearingFile.prototype.navigateSupplierInvoiceLines = function (selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        if (selectedSupplierInvoice) {
            var nextSupplierInvoiceLine = selectedSupplierInvoice.lines.find(function (l) { return selectedSupplierInvoiceLine && l.lineNo === selectedSupplierInvoiceLine.lineNo; });
            if (nextSupplierInvoiceLine) {
                this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(nextSupplierInvoiceLine);
            }
            else {
                this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(selectedSupplierInvoice.lines[0]);
            }
        }
    };
    SaveClearingFile.ngInjectableDef = i0.defineInjectable({ factory: function SaveClearingFile_Factory() { return new SaveClearingFile(i0.inject(i1.ClearingFileBehaviourSubject), i0.inject(i2.ClearingFileService), i0.inject(i3.ShareDataService), i0.inject(i4.TransportDocumentBehaviourSubject), i0.inject(i5.SupplierInvoiceBehaviourSubject), i0.inject(i6.SupplierInvoiceLineBehaviourSubject), i0.inject(i7.CorrectionService), i0.inject(i8.FileService)); }, token: SaveClearingFile, providedIn: "root" });
    return SaveClearingFile;
}());
export { SaveClearingFile };
