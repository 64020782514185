import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {NotificationService} from '../../digi-common/services/notification.service';
import {ExBondFileService} from '../services/ex-bond-file.service';
import {BillOfEntry} from '../../digi-common/models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ValidationService} from '../../digi-common/services/validation.service';

@Component({
  selector: 'digi-exbond-file-section',
  templateUrl: './exbond-file-section.component.html',
  styleUrls: ['./exbond-file-section.component.scss']
})
export class ExbondFileSectionComponent implements OnInit, OnDestroy {
  disableButtons: boolean;
  billOfEntry: BillOfEntry;
  @Input() step: string;
  @Input() canAdd: boolean;
  @Input() label = 'New';
  @Output() add: EventEmitter<any> = new EventEmitter();
  private exBondFileSubscription: Subscription;
  private boeSubscription: Subscription;
  exBondFile: ExBondFile;
  @Input() canCreateVoC: boolean;
  @Output() displayVocSequenceModal: EventEmitter<any> = new EventEmitter();

  constructor(private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
              private messageService: NotificationService,
              private exBondFileService: ExBondFileService,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private validationService: ValidationService) {
    this.validationService.setComponent(this);
  }

  ngOnInit() {
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(ef => this.exBondFile = ef);
    this.boeSubscription = this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(boe => this.billOfEntry = boe);
  }

  addAction() {
    this.add.emit();
  }

  saveExBondFile(): void {
    const validationResult = this.validationService.validateRegistration(this.exBondFile, 'exbond');
    if (validationResult.isValid) {
      this.disableButtons = true;
      this.exBondFileService.save(this.exBondFile).subscribe(
        ef => {
          this.exBondFile = ef;
          this.saveConfirmation();
          this.disableButtons = false;
        },
        error => {
          this.disableButtons = false;
        },
        () => {
          this.disableButtons = false;
        },
      );
    } else {
      this.showMessages(validationResult.messages);
    }
  }

  showErrors(label: string, messages?: string[]) {
    if (messages) {
      this.messageService.errorNotify(label, messages);
    } else {
      this.messageService.errorNotify(label);
    }
    return true;
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  isSaveDisabled() {
    if (this.billOfEntry) {
      if (this.billOfEntry.dateEdiSubmitted) {
        if (this.billOfEntry.cusres && this.billOfEntry.cusres.customsStatus
          && this.billOfEntry.cusres.customsStatus === 'REJECT_TO_CLEARER') {
          return false;
        }

        if (this.billOfEntry.contrl && (this.billOfEntry.contrl.messageStatus === 'REJECTED' ||
          this.billOfEntry.contrl.messageStatus === 'UNB_OR_UNZ_REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'UNB_OR_UNZ_REJECTED')) {
          return false;
        }
        return true;
      }
      if (this.billOfEntry.markedAsSubmitted) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.exBondFileSubscription.unsubscribe();
    this.boeSubscription.unsubscribe();
  }

  createVoc() {

    if (!this.hasMrn()) {
      this.showErrors('Cannot create a VOC as no MRN was received for previous submission.');
    } else {
      this.displayVocSequenceModal.emit(true);
    }
  }


  hasExBondFile() {
    return this.exBondFile.type === 'ExBondFile' || this.exBondFile.type === 'ExBondFileCorrection';
  }

  hasMrn() {
    return !!(this.billOfEntry && this.billOfEntry.mrn);
  }

  showMessages(messages: string[]): void {
    this.messageService.errorNotify('Validation Failed', messages);
  }
}
