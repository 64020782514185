import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { ExBondFileBehaviourSubject } from '../../../subjects/exbondfile-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import { ShareDataService } from '../../digi-common/services/share-data.service';
import { BillOfEntryBehaviourSubject } from '../../../subjects/billOfEntry-behaviour-subject';
import { map, tap } from 'rxjs/operators';
import { BillOfEntryLineBehaviourSubject } from '../../../subjects/billOfEntryLine-behaviour-subject';
import { AdditionalInfoBehaviourSubject } from '../../../subjects/addittional-info-behaviour-subject';
import { ExBondBillOfEntryService } from './ex-bond-bill-of-entry.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "../../../subjects/exbondfile-behaviour-subject";
import * as i4 from "../../../subjects/clearingfile-behaviour-subject";
import * as i5 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i6 from "../../digi-common/services/share-data.service";
import * as i7 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i8 from "../../../subjects/billOfEntryLine-behaviour-subject";
import * as i9 from "./ex-bond-bill-of-entry.service";
import * as i10 from "../../../subjects/addittional-info-behaviour-subject";
var ExBondFileService = /** @class */ (function () {
    function ExBondFileService(http, config, exBondFileBehaviourSubject, clearingFileBehaviourSubject, clearingInstructionBehaviorSubject, shareDataService, billOfEntryBehaviourSubject, billOfEntryLineBehaviourSubject, billOfEntryService, additionalInfoBehaviourSubject) {
        this.http = http;
        this.config = config;
        this.exBondFileBehaviourSubject = exBondFileBehaviourSubject;
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.shareDataService = shareDataService;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.billOfEntryLineBehaviourSubject = billOfEntryLineBehaviourSubject;
        this.billOfEntryService = billOfEntryService;
        this.additionalInfoBehaviourSubject = additionalInfoBehaviourSubject;
    }
    ExBondFileService.prototype.save = function (exbondFile) {
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (!exbondFile.type) {
            exbondFile.type = 'ExBondFile';
        }
        if (exbondFile._links && exbondFile._links.self) {
            return this.http.put(exbondFile._links.self.href, JSON.stringify(exbondFile), { headers: headers })
                .pipe(tap(function (ef) { return _this.exBondFileBehaviourSubject.addExBondFile(ef); }));
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles', JSON.stringify(exbondFile), { headers: headers })
                .pipe(tap(function (ef) { return _this.exBondFileBehaviourSubject.addExBondFile(ef); }));
        }
    };
    ExBondFileService.prototype.selectExBondFile = function (value) {
        var _this = this;
        this.selectedValue = value;
        this.createNewFile();
        this.exBondFileBehaviourSubject.addExBondFile(value);
        if (value.clearingInstructions.length) {
            this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
        }
        this.billOfEntryService
            .findBillOfEntryByExBondFile_id(value.id)
            .subscribe(function (boes) {
            if (boes.length > 0) {
                _this.billOfEntryBehaviourSubject.addBillOfEntry(boes[0]);
                if (boes[0].lines.length) {
                    _this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(boes[0].lines[0]);
                    _this.additionalInfoBehaviourSubject.addAdditionalInfo(boes[0].lines[0].additionalInformation);
                }
                _this.billOfEntryBehaviourSubject.addBillsOfEntry(boes);
            }
        });
    };
    ExBondFileService.prototype.selectXrwExBondFile = function (xrwValue) {
        if (this.selectedValue) {
            this.selectedValue.lines = xrwValue.lines;
            this.clearingInstruction = xrwValue.clearingInstructions[0].customsPurpose.customsPurposeCode;
            this.clearingFileNumber = xrwValue.clearingFileNumber;
            this.linkedToXrw = xrwValue.linkedtoXrw;
        }
    };
    ExBondFileService.prototype.getSelectedLines = function () {
        return this.selectedValue.lines;
    };
    ExBondFileService.prototype.getClearingInstruction = function () {
        return this.clearingInstruction;
    };
    ExBondFileService.prototype.getClearingFile = function () {
        return this.clearingFileNumber;
    };
    ExBondFileService.prototype.getLinkedtoXrw = function () {
        return this.linkedToXrw;
    };
    ExBondFileService.prototype.createNewFile = function () {
        this.exBondFileBehaviourSubject.addExBondFile(this.shareDataService.buildExBondFile());
        this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
        this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exbond'));
        this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);
    };
    ExBondFileService.prototype.loadExBondFile = function (fileNumber) {
        if (fileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exbondFiles/search/fileNumberStartsWith?fileNumber=" + fileNumber)
            .pipe(map(function (response) { return response['_embedded'].exbondFiles; }));
    };
    ExBondFileService.prototype.findAllSequences = function (clearingFileNumber) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exBondFileCorrections/search/findSequencesByClearingFileNumber?clearingFileNumber="
            + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
            .pipe(map(function (response) { return response['_embedded'].exBondFileCorrections; }));
    };
    ExBondFileService.prototype.findByClearingFileNumberAndSequence = function (fileNumber, sequence) {
        if (fileNumber === '' && sequence === null) {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exBondFileCorrections/search/clearingFileNumberAndSequence?clearingFileNumber=" + fileNumber
            + '&sequence=' + sequence)
            .pipe(map(function (response) { return response['_embedded'].exBondFileCorrections; }));
    };
    ExBondFileService.prototype.loadAllCorrectionsIncludingOriginalExBondFile = function (clearingFileNumber) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exBondFileCorrections/search/findByClearingFileNumber?clearingFileNumber=" + clearingFileNumber)
            .pipe(map(function (response) { return response['_embedded'].exBondFileCorrections; }));
    };
    ExBondFileService.ngInjectableDef = i0.defineInjectable({ factory: function ExBondFileService_Factory() { return new ExBondFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ExBondFileBehaviourSubject), i0.inject(i4.ClearingFileBehaviourSubject), i0.inject(i5.ClearingInstructionBehaviorSubject), i0.inject(i6.ShareDataService), i0.inject(i7.BillOfEntryBehaviourSubject), i0.inject(i8.BillOfEntryLineBehaviourSubject), i0.inject(i9.ExBondBillOfEntryService), i0.inject(i10.AdditionalInfoBehaviourSubject)); }, token: ExBondFileService, providedIn: "root" });
    return ExBondFileService;
}());
export { ExBondFileService };
