<p-tabView>
  <p-tabPanel header="Shipping Details">
    <div class="ui-g ui-g-nopad ui-fluid">
      <div class="ui-g-8">
        <p-panel header="Shipping Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-6">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.accountNumber"
                                             pInputText>
                                      <label>Account Number</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.terminal"
                                             pInputText>
                                      <label>Terminal</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.authorisedSignatory"
                                             pInputText>
                                      <label>Authorised Signatory</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.imdgClass"
                                             pInputText>
                                      <label>IMDG Class</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.transporter"
                                             pInputText>
                                      <label>Transporter</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-6">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.boeNumber"
                                             pInputText>
                                      <label>BOE NO.</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <span class="ui-float-label">
                                       <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy"
                                                    [(ngModel)]="shippingOrder.boeDate"></p-inputMask>
                                      <label>BOE Date</label>
                                    </span>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.quoteNumber"
                                             pInputText>
                                      <label>TPT Quote No.</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.contractNumber"
                                             pInputText>
                                      <label>Contract No.</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.railDestination"
                                             pInputText>
                                      <label>Rail Destination</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" class="ui-inputgroup"
                                             [(ngModel)]="shippingOrder.railSlidingNo"
                                             pInputText>
                                      <label>Rail Sliding No.</label>
                                    </span>
                                  </div>
                                </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="Packing List"
                                binary="true"
                                [(ngModel)]="shippingOrder.packingListAttached">
                    </p-checkbox>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="Suppliers Invoice"
                                binary="true"
                                [(ngModel)]="shippingOrder.suppliersInvoiceAttached">
                    </p-checkbox>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="DO"
                                binary="true"
                                [(ngModel)]="shippingOrder.deliveryOrderAttached">
                    </p-checkbox>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="BOL"
                                binary="true"
                                [(ngModel)]="shippingOrder.bolAttached">
                    </p-checkbox>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="SAD500"
                                binary="true"
                                [(ngModel)]="shippingOrder.sad500Attached">
                    </p-checkbox>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <p-checkbox label="BOE"
                                binary="true"
                                [(ngModel)]="shippingOrder.boeAttached">
                    </p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="ui-g-4">
        <p-panel header="Transport Method">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-6 ui-md-6">
                    <p-checkbox label="Rail"
                                binary="true"
                                [ngModel]="shippingOrder.transportMethodCode==='RAI'"
                                (onChange)="shippingOrder.transportMethodCode='RAI'"
                    >

                    </p-checkbox>
                  </div>
                  <div class="ui-g-6 ui-md-6">
                    <p-checkbox label="Road"
                                binary="true"
                                [ngModel]="shippingOrder.transportMethodCode==='ROA'"
                                (onChange)="shippingOrder.transportMethodCode='ROA'"
                    >
                    </p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
        <br>
        <p-panel header="Terms">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-6 ui-md-6">
                    <p-checkbox label="Linear"
                                binary="true"
                                [(ngModel)]="shippingOrder.termsLinear">
                    </p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Marks And Numbers">
    <digi-manifest-marks-and-numbers [displayEndorsements]="false" [marksAndNumbers]="marksAndNumbers"></digi-manifest-marks-and-numbers>
  </p-tabPanel>
  <p-tabPanel header="Line Details">
    <p-table [columns]="lineDetailsCols"
             [value]="shippingOrder.lineDetails"
             [rows]="10">
      <ng-template pTemplate="caption">
        Line Details
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.noOfPacks">
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.description">
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.mass">
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.length">
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.width">
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="text" pInputText [(ngModel)]="rowData.height">
          </td>
          <td>
            <button pButton type="button" icon="fa fa-times" class="danger"
                    (click)="deleteLineDetail(rowIndex)" pTooltip="Delete"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton icon="fa fa-plus" (click)="addLineDetail()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>
  </p-tabPanel>
</p-tabView>
