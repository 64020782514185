<p-panel *ngIf="permits && permits.length" header="Permits">
  <p-table [columns]="cols" [value]="permits" [selection]="selectedPermit" dataKey="permitNumber"
           [paginator]="true" [rows]="4"
           selectionMode="single"
           (onRowSelect)="select($event.data)"
           (onRowUnselect)="unselect($event.data)">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width"/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td>
          <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
        </td>
        <td scope="row">
          <label>{{rowData?.permitNumber}}</label>
        </td>
        <td>
          <label>{{rowData?.quantityBased}}</label>
        </td>
        <td>
          <label>{{rowData?.balanceQuantity}}</label>
        </td>
        <td>
          <label>{{rowData?.appliedQuantity}}</label>
        </td>
        <td>
          <label>{{rowData?.permitUom}}</label>
        </td>
        <td>
          <label>{{rowData?.valueBased}}</label>
        </td>
        <td>
          <label>{{rowData?.balanceValue}}</label>
        </td>
        <td>
          <label>{{rowData?.appliedValueForeign}}</label>
        </td>
        <td>
          <label>{{rowData?.appliedValueLocal}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
