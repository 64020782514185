<div class="ui-g-12">
  <div *ngIf="disableButton" class="ui-g-12">
    <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
  </div>
  <div class="ui-g-6">
    <p-table [columns]="cols" [value]="generatedFiles" [(selection)]="attachments"
             [paginator]="true" [rows]="4"
             selectionMode="multiple">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width"/>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td scope="row">
            <label>{{rowData?.fileName}}</label>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-6">
    <p-panel header="Email">
      <div class="ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" id="userEmail" pInputText
                                         [(ngModel)]="email.to">
                                  <label>To:</label>
                                </span>
                              </div>
                            </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="email.subject">
                                  <label>Subject:</label>
                                </span>
                              </div>
                            </span>
              </div>
              <div class="ui-g-12 ui-md-12">
                <span>Attachments: </span>
                <ul>
                  <li role="menuitem" *ngFor="let attachment of attachments">
                    {{attachment.fileName}}
                  </li>
                </ul>
              </div>
              <div class="ui-g-12 ui-md-12">
                <textarea pInputTextarea [(ngModel)]="email.text" [rows]="3" [cols]="50"
                          style="resize: none">
                </textarea>
              </div>
              <div class="ui-g-12 ui-md-12">
                <button *ngIf="attachments && attachments.length" type="button" pButton icon="fa fa-paper-plane" (click)="sendMail()"
                        label="Send"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
