<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu" (onChange)="onTabChange($event)">
    <p-tabPanel header="Waybills" (click)="resetContainer()">
      <div class="ui-g-12">
        <digi-header [roadManifest]="roadManifest"
                     (onUpdateRoadManifest)="updateRoadManifest($event)"
                     (onCreateNew)="createNewWaybill()"
        ></digi-header>
        <div class="ui-g">
          <div class="ui-g-4 ui-md-4">
            <div class="ui-g-12 ui-md-12"></div>
            <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="boes"
                            field="transportDocNo"
                            [suggestions]="filteredBoes"
                            (completeMethod)="searchBoE($event)"
                            (onSelect)="addBoeToRoadManifest($event)"
                            [autoHighlight]="true">
              <ng-template let-value pTemplate="selectedItem">
          <span style="font-size:14px">{{value.transportDocNo}}</span>
        </ng-template>
            </p-autoComplete>
        <label for="boes">Search Waybill/Manifest No.</label>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12" >
            <p-table [columns]="cols" [value]="roadManifest.waybills" selectionMode="single" [(selection)]="selectedWaybill" (onRowSelect)="selectWaybill($event)" dataKey="lrn"
            >
              <ng-template pTemplate="caption">
                Waybills
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [style.width]="col.width">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td>
                    {{rowData.lrn}}
                  </td>
                  <td>
                    {{rowData.hawbNo}}
                  </td>
                  <td>
                    <button pButton type="button" icon="fa fa-times" class="danger"
                            (click)="deleteWaybill(rowIndex)" pTooltip="Delete"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Waybill Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.lrn"
                             required=true>
                      <label>LRN</label>
                      <div *ngIf="!selectedWaybill.lrn"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.mrn"
                             required=true>
                      <label>MRN</label>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="releaseDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.releaseDate">
                   </p-inputMask>
                      <label for="releaseDate">Release Date</label>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.ucrNumber"
                             required=true>
                      <label>UCR</label>
                      <div *ngIf="!selectedWaybill.ucrNumber"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.mawbNo"
                             required=true>
                      <label>Manifest No.</label>
                      <div *ngIf="!selectedWaybill.mawbNo"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.hawbNo"
                             required=true>
                      <label>Waybill No.</label>
                      <div *ngIf="!selectedWaybill.hawbNo"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="mawbDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.mawbDate">
                   </p-inputMask>
                      <label for="mawbDate">Manifest Date</label>
                      <div *ngIf="!selectedWaybill.mawbDate"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="hawbDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.hawbDate">
                   </p-inputMask>
                      <label for="hawbDate">Waybill Date</label>
                      <div *ngIf="!selectedWaybill.hawbDate"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.cpc"
                             required=true>
                      <label>CPC</label>
                      <div *ngIf="!selectedWaybill.cpc"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Weights & Measures">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="selectedWaybill.grossMass" required=true>
              <label>Weight (kg)</label>
              <div *ngIf="!selectedWaybill.grossMass" class="ui-message ui-messages-error ui-corner-all">
                This field is required
              </div>
            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="selectedWaybill.noOfPackages" required=true>
              <label>No of Packages</label>
              <div *ngIf="!selectedWaybill.noOfPackages" class="ui-message ui-messages-error ui-corner-all">
                This field is required
              </div>
            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="selectedWaybill.cbm" required=true>
              <label>CBM</label>
              <div *ngIf="!selectedWaybill.cbm" class="ui-message ui-messages-error ui-corner-all">
                This field is required
              </div>
            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="selectedWaybill.noOfContainers" required=true>
              <label>No of Containers</label>
              <div *ngIf="!selectedWaybill.noOfContainers" class="ui-message ui-messages-error ui-corner-all">
                This field is required
              </div>
            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Consignor Details">
                <digi-manifest-business-entity-search [businessEntity]="selectedWaybill.consignor"
                                                      roleType="12"
                                                      label="Consignor"
                                                      (updateBusinessEntity)="updateConsignor($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Consignee Details">
                <digi-manifest-business-entity-search [businessEntity]="selectedWaybill.consignee"
                                                      roleType="2"
                                                      label="Consignee"
                                                      (updateBusinessEntity)="updateConsignee($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Package Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.descriptionOfGoods"
                             required=true>
                      <label>Description of Goods</label>
                            <div *ngIf="!selectedWaybill.descriptionOfGoods"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.numberOfPackages"
                             required=true>
                      <label>Number of Packages</label>
                            <div *ngIf="!selectedWaybill.numberOfPackages"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
    <input type="text" pInputText [(ngModel)]="selectedWaybill.packageType"
           required=true>
                      <label>Package Type</label>
                            <div *ngIf="!selectedWaybill.packageType"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
  </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <span class="ui-float-label">
                            <p-dropdown [options]="cargoStatusCodes"
                                        [(ngModel)]="selectedWaybill.cargoStatusCode"
                                        [autoWidth]="false">
                            </p-dropdown>
                              <label>Cargo Status</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Port Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port label="Departure Port" [transportMethod]="roadManifest.transportMethodCode"
                                              [port]="selectedWaybill.departurePort"
                                              (portChange)="selectDeparturePort($event)"
                          ></digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port label="Destination Port" [transportMethod]="roadManifest.transportMethodCode"
                                              [port]="selectedWaybill.destinationPort"
                                              (portChange)="selectDestinationPort($event)"
                          ></digi-manifest-port>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Marks & Numbers">
      <digi-manifest-marks-and-numbers [marksAndNumbers]="selectedWaybill.marksAndNumbers"
                                       (updateMarksAndNumbers)="updateMarksAndNumbers($event)"></digi-manifest-marks-and-numbers>
    </p-tabPanel>
    <p-tabPanel header="Containers">
      <p-toolbar>
        <div class="ui-toolbar-group-left ui-fluid ui-g-8">
          <div class="ui-g-4 ui-md-4">
            <div class="ui-inputgroup">
              <p-autoComplete id="manifestFileNumber" [(ngModel)]="roadManifest" field="manifestFileNo"
                              [suggestions]="filteredRoadManifests"
                              (completeMethod)="searchRoadManifestsByQuery($event)"
                              [autoHighlight]="true"
                              (onSelect)="selectRoadManifest($event)"
                              minLength="2"
                              [inputStyle]="{color:'white'}"
                              placeholder="Manifest File Number">
                <ng-template let-option pTemplate="item">
                  <div>{{option.manifestFileNo}}</div>
                </ng-template>
              </p-autoComplete>
              <span class="sequence ui-inputgroup-addon" (click)="correctionList.toggle($event)">{{roadManifest.sequence}}</span>
              <p-overlayPanel #correctionList [dismissable]="true">
                <ng-template ngFor let-option [ngForOf]="listManifestFiles" pTemplate="item">
                  <div id="sequence" class="ui-overlaypanel-content" (click)="selectCorrection(option)">
                    {{option.manifestFileNo}}/{{option.sequence}}
                  </div>
                </ng-template>
              </p-overlayPanel>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
                  <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     class="color-white"
                     [(ngModel)]="roadManifest.manifestNo">
              <label class="color-white">Manifest No.</label>
            </span>
            </div>
            </span>
          </div>
          <div class="ui-g-2 ui-md-2">
                  <span class="md-inputfield">
            <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text"
                     pInputText
                     class="color-white"
                     [(ngModel)]="cuscarType">
              <label class="color-white">Cuscar Type</label>
            </span>
            </div>
            </span>
          </div>
        </div>
        <div class="ui-toolbar-group-right">
          <button pButton type="button" label="New" icon="fa fa-file" (click)="addContainer()"
                  class="ui-button-secondary" [disabled]="disableButton"></button>
          <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="hasCarn() || disableButton"
                  (click)="saveContainer()"></button>
          <button pButton type="button" *ngIf="hasCarn()" label="Create VOC" icon="fa fa-plus"
                  (click)="displayVocDialog=true"></button>
        </div>
      </p-toolbar>
      <digi-manifest-sequence-modal [roadManifest]="roadManifest"
                                    [display]="displayVocDialog"
                                    (displayChange)="displayVocDialog=$event"
                                    (onUpdateRoadManifest)="updateRoadManifest($event)"

      >
      </digi-manifest-sequence-modal>
      <br>
      <div class="running-balance implementation"  style="margin-left: 40%;">
        <p-panel header="Total Weight & Quantity">
          <div class="ui-g ui-fluid">
            <div class="ui-g-3 pt-2">
            </div>
            <div class="ui-g-2 pt-2">
              <span class="ui-inputgroup-addon">Value</span>
            </div>
            <div class="ui-g-2 pt-2">
              <span class="ui-inputgroup-addon">Packs</span>
            </div>
            <div class="ui-g-2 pt-2">
              <span class="ui-inputgroup-addon">Volume</span>
            </div>
            <div class="ui-g-3 pt-2">
              <span class="ui-inputgroup-addon">Gross Weight (KG)</span>
            </div>
            <div class="ui-g-3 pt-2">
              <span class="ui-inputgroup-addon">Total</span>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill?.noOfContainers}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill?.noOfPackages}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill?.cbm}}" readonly>
            </div>
            <div class="ui-g-3 pt-2">
              <input type="text" pInputText value="{{selectedWaybill?.grossMass}}" readonly>
            </div>
            <div class="ui-g-3 pt-2">
              <span class="ui-inputgroup-addon">Captured</span>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.capturedValue}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.capturedNoOfPackages}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.capturedVolume}}" readonly>
            </div>
            <div class="ui-g-3 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.capturedGrossWeight}}" readonly>
            </div>
            <div class="ui-g-3 pt-2">
              <span class="ui-inputgroup-addon">Balance</span>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.remainingValue}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.remainingNoOfPackages}}" readonly>
            </div>
            <div class="ui-g-2 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.remainingVolume}}" readonly>
            </div>
            <div class="ui-g-3 pt-2">
              <input type="text" pInputText value="{{selectedWaybill.containerRunningTotals?.remainingGrossWeight}}" readonly>
            </div>
          </div>
        </p-panel>
      </div>
      <br>
      <div class="content-section implementation">
        <p-table [columns]="cols" [value]="selectedWaybill.containers" selectionMode="single" [(selection)]="selectedContainer"
                 [resizableColumns]="true" (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="5" >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn>
                {{col.header}}
              </th>
<!--              <th pResizableColumn>Container No.</th>-->
<!--              <th pResizableColumn>Container Type</th>-->
<!--              <th pResizableColumn>Gross Weight</th>-->
<!--              <th pResizableColumn>No of Packages</th>-->
<!--              <th pResizableColumn>CBM</th>-->
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
              <td class="ui-resizable-column">
                <label>{{rowData.containerNo}}</label>
              </td>
              <td class="ui-resizable-column">
                <label>{{rowData.serialNo}}</label>
              </td>
              <td class="ui-resizable-column">
                <label>{{rowData.containerType.code}}</label>
              </td>
              <td class="ui-resizable-column">
                <label>{{rowData.nettWeight}}</label>
              </td>
              <td class="ui-resizable-column">
                <label>{{rowData.noOfPackages}}</label>
              </td>
              <td class="ui-resizable-column">
                <label>{{rowData.cbm}}</label>
                            </td>

              <td class="ui-resizable-column">
                <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                        (click)="onRowDelete(rowIndex); updateRunningTotals()"  pTooltip="Delete"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-12 ui-md-12" style="text-align:right">
       <div class="ui-inputgroup" style="float:right">
        <button pButton
                type="button"
                label="Add Container"
                (click)="addContainer()"></button>

       </div>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="General Container Details">
              <div class="ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="selectedContainer.containerType" field="description"
                                  [suggestions]="filteredContainerTypes"
                                  (completeMethod)="searchContainerTypes($event)"
                                  [autoHighlight]="true" minLength="1"
                                  [dropdown]="true"
                                  required=true (onSelect)="selectContainerType($event)"
                  >
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}: {{option.description}}</div>
                    </ng-template>
                  </p-autoComplete>
                   <label>Container Type</label>
            </span>
              </div>
            </span>
                  </div>
                  <div class="ui-g-12 ui-md-12" *ngIf="selectedContainer.containerType && selectedContainer.containerType.description === 'Bulk'">
                    <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="cargoType" [(ngModel)]="selectedContainer.cargoType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredCargoTypes"
                                    (completeMethod)="searchCargoTypes($event)"
                                    (onSelect)="selectCargoType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="cargoType">Cargo Type</label>
                </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-8">
          <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                    <p-autoComplete id="containerSpecification" [(ngModel)]="selectedContainer.containerSpecification"
                                    field="label"
                                    [suggestions]="filteredContainerSpecifications"
                                    (completeMethod)="searchContainerSpecs($event)"
                                    [autoHighlight]="true" minLength="1"
                                    [dropdown]="true"
                                    [forceSelection]="true"
                                    required=true (onSelect)="selectContainerSpec($event)"
                    >
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}}: {{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                   <label for="containerSpecification">Container Size</label>
                </span>
              </div>
          </span>
                  </div>
                  <div class="ui-g-12 ui-md-4">
                    <div class="ui-inputgroup">
            <span class="md-inputfield">
            <input type="text"
                   placeholder=""
                   pInputText
                   [ngModel]="this.selectedContainer?.containerSpecification?.containerSize?.size"
                   readonly>
              <label>Size(ft)</label>
            </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedContainer.containerNo">
                      <label>Container No.</label>
                    </span>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="selectedContainer.serialNo">
                  <label>Seal No.</label>
                </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="General Weights & Measures">
              <div class="ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="selectedContainer.noOfPackages"
                         (ngModelChange)="updateRunningTotals()">
                  <label>No of Packages</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.nettWeight"
                         (ngModelChange)="updateRunningTotals()"
                         pInputText>

                  <label>Gross Weight</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.solas.vgm"
                         pInputText>

                  <label>VGM</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.cbm"
                         (ngModelChange)="updateRunningTotals()"
                         pInputText>
                  <label>CBM</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.freightTons"
                         (ngModelChange)="updateRunningTotals()"
                         pInputText>
                  <label>Freight tons</label>
                </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
