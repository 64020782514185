import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class UomService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findUomByCodeStartWith(searchValue) {
    return this.http
      .get(`${this.config.apiEndpoint}api/uoms/search/findByCustomsUomCodeOrUomCode?code=` + searchValue)
      .pipe(
        map(response => response['_embedded'].uoms)
      );
  }

  findByCode(code): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/uoms/search/findByCode?code=` + code);
  }

  findByCodeStartsWith(code) {
    return this.http.get(`${this.config.apiEndpoint}api/uoms/search/findByCodeStartsWith?code=` + code)
      .pipe(map(response => response['_embedded'].uoms));
  }
}
