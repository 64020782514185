import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExportFile} from '../../../../digi-common/models/exportFile';
import {Subscription} from 'rxjs';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ExportFileService} from '../../../../digi-common/services/export-file.service';
import {InternationalPortService} from '../../../../digi-common/services/international-port.service';
import {ClearingInstruction, CustomsPurpose, CustomsPurposeFile, TransportMethod} from '../../../../digi-common/models/file';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {TransportMethodsService} from '../../../../digi-common/services/transport-methods.service';
import {ClearingInstructionsService} from '../../../../digi-common/services/clearing-instructions.service';
import {Debtor, ExportConsignee} from '../../../../digi-business-entity/models/business-entity';
import {ExporterService} from 'src/app/digi-common/services/exporter.service';
import {ActivatedRoute} from '@angular/router';

enum CustomsPurposeNames {
  CCA = 'Exports CCA1',
  EAS = 'Export as ship/aircraft stores',
  EIG = 'Export of Imported Goods',
  ELG = 'Export of Local Goods',
  XE = 'Export of Imported Goods (ex warehouse)',
  XES = 'Imported Goods ship/aircraft stores (exw',
  ZE = 'Export of Local Goods (ex warehou',
  ZES = 'SA Goods as ship/aircraft stores (ex whs'
}

@Component({
  selector: 'digi-sea-export-registration',
  templateUrl: './sea-export-registration.component.html',
  styleUrls: ['./sea-export-registration.component.scss']
})
export class SeaExportRegistrationComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
