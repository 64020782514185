import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {ClearingFile} from '../models/clearingFile';
import {Observable, of} from 'rxjs';
import {ExportFile, ShippingOrder} from '../models/exportFile';
import {ShareDataService} from './share-data.service';
import {ExportFileBehaviourSubject} from '../../../subjects/exportfile-behaviour-subject';
import {map, tap} from 'rxjs/operators';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ContainerBehaviorSubject} from '../../../subjects/container-behavior-subject';
import {TransportDocumentBehaviourSubject} from '../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {Container, ExportTransportDocument, SupplierInvoice, TransportDocument} from '../models/file';
import {SupplierInvoiceLineBehaviourSubject} from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {Export, ExportBillOfEntry} from '../models/billOfEntries';
import {BillOfEntryService} from './bill-of-entry.service';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {AdditionalInfoBehaviourSubject} from '../../../subjects/addittional-info-behaviour-subject';
import {UniqueConsignmentBehaviourSubject} from '../../../subjects/unique-consignment-reference-subject';
import {FileType} from '../models/enumerations';
import {PartDetail} from '../models/partDetail';
import {FileService} from './file.service';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private shareDataService: ShareDataService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private containersBehaviourSubject: ContainerBehaviorSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private billOfEntryService: BillOfEntryService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
    private containerBehaviourSubject: ContainerBehaviorSubject,
    private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject,
    private fileService: FileService,
    private authorisationService: AuthorisationService
  ) {
  }

  save(exportFile: ExportFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (exportFile._links && exportFile._links.self) {
      return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id, JSON.stringify(exportFile), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/exportFiles', JSON.stringify(exportFile), {headers});
    }
  }

  saveExportsFile(exportFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine): Observable<ExportFile> {
    this.fileService.setIsFileSaving(true);
    if (!exportFile.type) {
      exportFile.type = 'ExportFile';
      return this.save(exportFile).pipe(
        tap((data: ExportFile) => {
            this.updateFileAfterSaving(data, selectedTransportDocument,
              selectedSupplierInvoice, selectedSupplierInvoiceLine);
          this.fileService.setIsFileSaving(false);
          },
        ),
      );
    } else {
      return this.save(exportFile).pipe(
        tap((data: ExportFile) => {
            this.updateFileAfterSaving(data, selectedTransportDocument,
              selectedSupplierInvoice, selectedSupplierInvoiceLine);
          this.fileService.setIsFileSaving(false);
          },
        ),
      );
    }
  }

  updateFileAfterSaving(data, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    this.exportFileBehaviourSubject.addExportFile(data);
    if (data.transportDocument) {
      this.transportDocumentBehaviourSubject.addTransportDocument(data.transportDocument);
      this.navigateSupplierInvoices(data.transportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
    }
  }

  private navigateSupplierInvoices(selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    if (selectedTransportDocument) {
      if (selectedSupplierInvoice) {
        const nextSupplierInvoice = selectedTransportDocument.supplierInvoices
          .find(s => s.invoiceDate === selectedSupplierInvoice.invoiceDate && s.invoiceNumber === selectedSupplierInvoice.invoiceNumber);
        if (nextSupplierInvoice) {
          this.supplierInvoiceBehaviourSubject.addSupplierInvoice(nextSupplierInvoice);
        } else {
          this.supplierInvoiceBehaviourSubject.addSupplierInvoice(selectedTransportDocument.supplierInvoices[0]);
        }
        this.navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine);
      }
    }
  }

  private navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine) {
    if (selectedSupplierInvoice) {
      const nextSupplierInvoiceLine = selectedSupplierInvoice.lines.find(l => selectedSupplierInvoiceLine && l.lineNo === selectedSupplierInvoiceLine.lineNo);
      if (nextSupplierInvoiceLine) {
        this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(nextSupplierInvoiceLine);
      } else {
        this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(selectedSupplierInvoice.lines[0]);
      }
    }
  }

  createNewFile(transportMethod?: string) {
    const exportFile = this.shareDataService.buildExportFile(transportMethod);
    this.exportFileBehaviourSubject.addExportFile(exportFile);
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.transportDocumentBehaviourSubject.addTransportDocument(exportFile.transportDocument);
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(null);
    this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(null);
    this.containerBehaviourSubject.addContainer(null);
    this.supplierInvoiceBehaviourSubject.addCountry(null);
    this.supplierInvoiceBehaviourSubject.addCurrency(null);
    this.supplierInvoiceLineBehaviourSubject.addCountry(null);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
  }

  loadExportFile(fileNumber: String, transportMethod?: string, branchCode?: string): Observable<any> {
    if (fileNumber === '') {
      return of([]);
    }
    let params = `fileNumber=${fileNumber}`;
    if (transportMethod) {
      params += `&transportMethod=${transportMethod}`;
    }
    if (branchCode) {
      params += `&branchCode=${branchCode}`;
      return this.http
        .get(`${this.config.filesApiEndpoint}api/exportFiles/search/findByFileNumberOrReferenceFileNoAndBranchCode?${params}`)
        .pipe(
          map(response => response['_embedded'].exportFiles)
        );
    } else {
      return this.http
        .get(`${this.config.filesApiEndpoint}api/exportFiles/search/fileNumberOrReferenceFileNoStartsWith?${params}`)
        .pipe(
          map(response => response['_embedded'].exportFiles)
        );
    }

  }

  selectExportFile(value: ExportFile) {
    this.createNewFile();
    this.exportFileBehaviourSubject.addExportFile(value);
    this.transportDocumentBehaviourSubject.addTransportDocument(value.transportDocument);
    if (value.transportDocument && value.transportDocument.containers && value.transportDocument.containers.length > 0) {
      this.containerBehaviourSubject.addContainer(value.transportDocument.containers[0]);
    }
    if (value.transportDocument && value.transportDocument.supplierInvoices && value.transportDocument.supplierInvoices.length > 0) {
      this.supplierInvoiceBehaviourSubject.addSupplierInvoice(value.transportDocument.supplierInvoices[0]);
      if (value.transportDocument.supplierInvoices[0].lines.length > 0) {
        this.supplierInvoiceLineBehaviourSubject
          .addSupplierInvoiceLine(value.transportDocument.supplierInvoices[0].lines[0]);
      }
    }
    if (value.clearingInstructions.length) {
      this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
    }
    this.billOfEntryService
      .findBillOfEntryByExportFile_id(value.id)
      .subscribe(data => {
        if (data._embedded.exportBillOfEntries.length > 0) {
          this.billOfEntryBehaviourSubject.addBillOfEntry(data._embedded.exportBillOfEntries[0]);
          this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(data._embedded.exportBillOfEntries[0].lines[0]);
          this.additionalInfoBehaviourSubject.addAdditionalInfo(data._embedded.exportBillOfEntries[0].lines[0].additionalInformation);
          this.billOfEntryBehaviourSubject.addBillsOfEntry(data._embedded.exportBillOfEntries);
        }
      });
  }

  loadAllExportFileCorrectionsIncludingOriginalExportFile(clearingFileNumber: string, transportMethod?: string): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exportFileCorrections/search/fileNumberOrReferenceFileNoStartsWith?fileNumber=${clearingFileNumber}&transportMethod=${transportMethod}`)
      .pipe(
        map(response => response['_embedded'].exportFileCorrections)
      );
  }

  findAllSequences(clearingFileNumber: String): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exportFileCorrections/search/findSequencesByClearingFileNumber?clearingFileNumber=`
        + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
      .pipe(
        map(response => response['_embedded'].exportFileCorrections)
      );
  }

  findByClearingFileNumberAndSequence(fileNumber: String, sequence: number): Observable<any> {
    if (fileNumber === '' && sequence === null) {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exportFileCorrections/search/clearingFileNumberAndSequence?clearingFileNumber=` + fileNumber
        + '&sequence=' + sequence)
      .pipe(
        map(response => response['_embedded'].exportFileCorrections)
      );
  }

  findClearingFileNumbersByInvoiceNo(invoiceNumber: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportFiles/search/findClearingFileNumbersByInvoiceNo?clearingFileNumber=${fileNumber}&invoiceNo=${invoiceNumber}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  findClearingFileNumbersByHawbNo(hawbNo: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportFiles/search/findClearingFileNumbersByHawbNo?clearingFileNumber=${fileNumber}&hawbNo=${hawbNo}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  findClearingFileNumbersByMawbNo(mawbNo: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportFiles/search/findClearingFileNumbersByMawbNo?clearingFileNumber=${fileNumber}&mawbNo=${mawbNo}`)
      .pipe(
        map((response: any[]) => response.map(el => el.clearingFileNumber))
      );
  }

  findByRef(href: string) {
    return this.http.get(href.replace('\{\?projection\}', ''));
  }

  loadFiles(id: String): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exportFiles/` + id + '/airWaybills');
  }

  createInvoiceLines(partDetails: PartDetail[], exportFileId: string, mawbNo: string, invoiceNumber: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.config.filesApiEndpoint}api/exportFiles/${exportFileId}/supplierInvoices/upload?mawbNo=${mawbNo}&invoiceNo=${invoiceNumber}`, JSON.stringify(partDetails), {headers});
  }
}
