import { map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { Address } from '../models/file';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../subjects/clearingfile-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../../subjects/clearingfile-behaviour-subject";
import * as i2 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i3 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i4 from "@angular/common/http";
import * as i5 from "../../app.config.module";
var ImporterService = /** @class */ (function () {
    function ImporterService(clearingFileBehaviourSubject, supplierInvoiceBehaviourSubject, clearingInstructionBehaviorSubject, http, config) {
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.http = http;
        this.config = config;
    }
    ImporterService.prototype.findImporterNameOrCodeStartsWith = function (name) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + name + '&roleType=6' + '&projection=flattenedImporter')
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    ImporterService.prototype.findImporterFromClearingFile = function (clearingFile) {
        var _this = this;
        if (clearingFile.importer && clearingFile.importer.code) {
            return this.findImporterNameOrCodeStartsWith(clearingFile.importer.code).pipe(tap(function (importerData) {
                if (importerData) {
                    console.log(importerData);
                    _this.supplierInvoiceBehaviourSubject.addSuppliers(importerData[0].suppliers);
                    _this.clearingFileBehaviourSubject.addDebtors(importerData[0].debtors);
                    _this.clearingInstructionBehaviorSubject.addRebateUsers(importerData[0].rebateUsers);
                    _this.clearingInstructionBehaviorSubject.addWarehouses(importerData[0].warehouses);
                    _this.clearingInstructionBehaviorSubject.addRemovers(importerData[0].removers);
                    _this.clearingInstructionBehaviorSubject.addImporterConsignee(importerData[0].importerConsignees);
                    if (importerData && (!clearingFile.importer.address || !clearingFile.importer.address.line1)) {
                        var importerAddresses = importerData[0].addresses;
                        var importerAddress = void 0;
                        if (importerAddresses.length === 1) {
                            importerAddress = importerAddresses[0];
                        }
                        else {
                            importerAddress = importerAddresses.find(function (address) {
                                var addressType = address.addressTypes.find(function (addressType) {
                                    return addressType.id === 'CUS';
                                });
                                if (addressType) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            });
                        }
                        if (importerAddress) {
                            clearingFile.importer.address = new Address();
                            clearingFile.importer.address.line1 = importerAddress.line1;
                            clearingFile.importer.address.line2 = importerAddress.line2;
                            clearingFile.importer.address.line3 = importerAddress.line3;
                            clearingFile.importer.address.line4 = importerAddress.line4;
                            clearingFile.importer.address.zipCode = importerAddress.zipCode;
                        }
                    }
                }
            }));
        }
        else {
            return EMPTY;
        }
    };
    ImporterService.prototype.findImporterDeliveryAddress = function (importerCode) {
        if (importerCode) {
            return this.findImporterNameOrCodeStartsWith(importerCode).pipe(map(function (importerData) {
                if (importerData && importerData[0]) {
                    var importerAddresses = importerData[0].addresses;
                    var importerAddress = void 0;
                    if (importerAddresses.length === 1) {
                        importerAddress = importerAddresses[0];
                    }
                    else {
                        importerAddress = importerAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'DEL';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    return importerAddress;
                }
            }));
        }
        else {
            return EMPTY;
        }
    };
    ImporterService.ngInjectableDef = i0.defineInjectable({ factory: function ImporterService_Factory() { return new ImporterService(i0.inject(i1.ClearingFileBehaviourSubject), i0.inject(i2.SupplierInvoiceBehaviourSubject), i0.inject(i3.ClearingInstructionBehaviorSubject), i0.inject(i4.HttpClient), i0.inject(i5.APP_CONFIG)); }, token: ImporterService, providedIn: "root" });
    return ImporterService;
}());
export { ImporterService };
