<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
             <span class="md-inputfield">
            <input type="text"
                   class="ui-inputgroup"
                   pInputText required>
               <label>Forwarding File No</label>
             </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
             <span class="md-inputfield">
            <input type="text"
                   class="ui-inputgroup"
                   pInputText required>
               <label>External Unique ID</label>
             </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-app-local-forwarder (cargoCarrierCode)="getCargoCarrierCode($event)"></digi-app-local-forwarder>
        </div>
        <div class="ui-g-12 ui-md-12">
          <digi-app-vessels-agent></digi-app-vessels-agent>
        </div>
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
             <span class="md-inputfield">
            <input type="text"
                   class="ui-inputgroup"
                   [(ngModel)]="selectedTransportDocument.clientRefNo"
                   pInputText required>
               <label>Client Ref No</label>
             </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-4">
          <button pButton type="submit" label="Sundry Changes" class="ui-button-secondary"
                  (click)="openSundryChargeModel()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<digi-sundry-charges
  [display]="display"
  (displayChange)="onDialogClose($event)"
  [hasSundryCharge]="selectedTransportDocument">
</digi-sundry-charges>
