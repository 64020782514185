import {Component, Input, OnInit} from '@angular/core';
import {ClearingFile} from '../../../../models/clearingFile';
import {ClearingInstruction, Consignee, LocalAgent} from '../../../../models/file';

@Component({
  selector: 'digi-local-agent-consignee',
  templateUrl: './local-agent-consignee.component.html',
  styleUrls: ['./local-agent-consignee.component.scss']
})
export class LocalAgentConsigneeComponent implements OnInit {
  @Input() clearingFile: ClearingFile;
  @Input() clearingInstruction: ClearingInstruction;
  localAgentAddresses = [];
  roleId = 'Local Agent'
  localAgents: LocalAgent[];

  constructor() {
  }

  ngOnInit() {
    this.localAgents = [];
    if (this.clearingFile.localAgent.code) {
      this.localAgents.push(this.clearingFile.localAgent);
      if (this.localAgents.length === 1) {
        this.updateSelectedToConsignee(this.clearingFile.localAgent);
      }
    }
  }

  private updateSelectedToConsignee(localAgent) {
    this.clearingInstruction.consignee = new Consignee();
    this.clearingInstruction.consignee.code = localAgent.code;
    this.clearingInstruction.consignee.name = localAgent.name;
    this.clearingInstruction.consignee.label = localAgent.label;
    this.clearingInstruction.consignee.roleType = this.roleId;
    this.clearingInstruction.consignee.address = this.clearingFile.localAgent.address;
    this.localAgentAddresses = localAgent.addresses;
  }
}
