var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */
import { File } from './file';
var ClearingFile = /** @class */ (function (_super) {
    __extends(ClearingFile, _super);
    function ClearingFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClearingFile;
}(File));
export { ClearingFile };
var Importer = /** @class */ (function () {
    function Importer() {
    }
    return Importer;
}());
export { Importer };
var ReportFile = /** @class */ (function () {
    function ReportFile() {
    }
    return ReportFile;
}());
export { ReportFile };
var PaymentFile = /** @class */ (function () {
    function PaymentFile() {
    }
    return PaymentFile;
}());
export { PaymentFile };
var ClearingInstructionFile = /** @class */ (function () {
    function ClearingInstructionFile() {
    }
    return ClearingInstructionFile;
}());
export { ClearingInstructionFile };
export var CustomsPurposeNames;
(function (CustomsPurposeNames) {
    CustomsPurposeNames["DP"] = "Duty Paid";
    CustomsPurposeNames["DF"] = "Duty Free (Section 38)";
    CustomsPurposeNames["GR"] = "General Rebate";
    CustomsPurposeNames["IR"] = "Industrial Rebate";
    CustomsPurposeNames["RIB"] = "Removal in Bond";
    CustomsPurposeNames["RIT"] = "Removal in Transit";
    CustomsPurposeNames["IM"] = "Import for Home Use";
    CustomsPurposeNames["WH"] = "Warehouse";
    CustomsPurposeNames["WE"] = "Warehousing for re-exportation";
})(CustomsPurposeNames || (CustomsPurposeNames = {}));
