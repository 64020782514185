import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Document, RateBasedCharge, ValueBasedCharge} from '../../models/file';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'digi-sundry-charges',
  templateUrl: './sundry-charges.component.html',
  styleUrls: ['./sundry-charges.component.scss'],
})
export class SundryChargesComponent implements OnInit {
  @Input('display') display;
  @Output() displayChange = new EventEmitter();
  @Input('hasSundryCharge') hasSundryCharge: Document;
  index = -1;

  constructor(
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  onClose() {
    this.displayChange.emit(false);
  }
}
