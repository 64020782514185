<span class="md-inputfield">
  <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete id="office" class="ng-dirty ng-invalid" [(ngModel)]="customOffice"
                                  field="districtOfficeName"
                                  [suggestions]="filteredCustomsOffices"
                                  (completeMethod)="search($event)"
                                  (onSelect)="select($event)"
                                  [autoHighlight]="true" minLength="3"
                                  [required]="isRequired">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.districtOfficeCode}}: {{option.districtOfficeName}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="office">Custom Office</label>
                </span>
<span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
</div>
<div *ngIf="!customOffice?.districtOfficeCode && isRequired" class="ui-message ui-messages-error ui-corner-all">
    This field is required
</div>
</span>
