<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-4">
          <div class="ui-g">
            <div class="ui-g-12">
              <p-checkbox name="tariffMode" value="tariffMode" label="Tariff mode?"
                          [(ngModel)]="userInterfaceDefaults.tariffMode"
                          binary="true"
                          (onChange)="tariffModeSelected($event)"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="quickPart" value="quickPart" label="Quick Part?"
                          [(ngModel)]="userInterfaceDefaults.quickPart"
                          binary="true"
                          (onChange)="quickPartSelected($event)"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="autoRepeat" value="autoRepeat" label="Auto Repeat?"
                          [(ngModel)]="userInterfaceDefaults.autoRepeat"></p-checkbox>
            </div>
          </div>
        </div>
        <div class="ui-g-12 ui-md-8">
          <div class="ui-g">
            <div class="ui-g-12">
              <p-checkbox name="autoClientOrderNumberClientRef" value="autoClientOrderNumberClientRef"
                          label="Auto Client Order Number (Client Ref)?"
                          [(ngModel)]="userInterfaceDefaults.autoClientOrderNumberClientRef"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="autoClientOrderNumberPart" value="autoClientOrderNumberPart"
                          label="Auto Client Order Number (Part)?"
                          [(ngModel)]="userInterfaceDefaults.autoClientOrderNumberPart"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="defaultAllDependencies" value="defaultAllDependencies"
                          label="  Default all Dependencies?"
                          [(ngModel)]="userInterfaceDefaults.defaultAllDependencies"></p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
