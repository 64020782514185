<div class="form-group">
  <div class="ui-g">
    <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
        <input
          type="text" pInputText
          [value]="clearingInstruction.consignee.label"
          readonly>
        <label>Local Agent</label>
      <div *ngIf="!clearingInstruction?.consignee?.code" class="ui-message ui-messages-error ui-corner-all">
      Local Agent not provided
    </div>
      </span>
    </div>
  </div>
  <digi-address [addresses]="localAgentAddresses" [address]="clearingInstruction?.consignee?.address"></digi-address>
</div>
