import { BehaviorSubject } from 'rxjs';
import { TariffDescriptionHistory, TariffDescriptionHistoryPK } from '../app/digi-common/models/TariffCode';
import * as i0 from "@angular/core";
var TariffDescriptionHistoryBehaviourSubject = /** @class */ (function () {
    function TariffDescriptionHistoryBehaviourSubject() {
        this.tariffDescriptionSubject = new BehaviorSubject(this.buildTariffDescriptionHistory());
    }
    TariffDescriptionHistoryBehaviourSubject.prototype.getTariffDescriptionHistory = function () {
        return this.tariffDescriptionSubject.asObservable();
    };
    TariffDescriptionHistoryBehaviourSubject.prototype.addTariffDescriptionHistory = function (tariffDescriptionHistory) {
        this.tariffDescriptionSubject.next(tariffDescriptionHistory);
    };
    TariffDescriptionHistoryBehaviourSubject.prototype.buildTariffDescriptionHistory = function () {
        var description = new TariffDescriptionHistory();
        description.id = new TariffDescriptionHistoryPK();
        return description;
    };
    TariffDescriptionHistoryBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function TariffDescriptionHistoryBehaviourSubject_Factory() { return new TariffDescriptionHistoryBehaviourSubject(); }, token: TariffDescriptionHistoryBehaviourSubject, providedIn: "root" });
    return TariffDescriptionHistoryBehaviourSubject;
}());
export { TariffDescriptionHistoryBehaviourSubject };
