import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { ExportFile } from '../../models/exportFile';
import { ExportFileService } from '../../services/export-file.service';
import { NotificationService } from '../../services/notification.service';
import { ExportFileBehaviourSubject } from '../../../../subjects/exportfile-behaviour-subject';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { TransportDocumentBehaviourSubject } from '../../../../subjects/transport-document-behaviour-subject';
import { ClearingInstructionBehaviorSubject } from '../../../../subjects/clearingInstruction-behavior-subject';
import { SupplierInvoiceBehaviourSubject } from '../../../../subjects/supplierInvoice-behaviour-subject';
import {ExportTransportDocument, SupplierInvoice, SupplierInvoiceLine, TransportDocument, TransportMethod} from '../../models/file';
import { SupplierInvoiceLineBehaviourSubject } from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { switchMap } from 'rxjs/operators';
import { ValidationService } from '../../services/validation.service';
import { ContainerBehaviorSubject } from '../../../../subjects/container-behavior-subject';
import {ExportBillOfEntry} from '../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {OverlayPanel} from 'primeng/overlaypanel';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';

@Component({
  selector: 'digi-exports-file-header-section',
  templateUrl: './exports-file-header-section.component.html',
  styleUrls: ['./exports-file-header-section.component.scss'],
})
export class ExportsFileHeaderSectionComponent implements OnInit, OnDestroy {
  @Input() step: string;
  exportFile: ExportFile;
  selectedTransportDocument: ExportTransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;
  filteredExportFiles: any;
  listExportFiles: any;
  @Input() canAdd: boolean;
  @Input() transportMethod: string;
  @Input() canDisable = true;
  @Input() canCreateVoC = true;
  @Output() add: EventEmitter<any> = new EventEmitter();
  exportFileServiceSubscription: Subscription;
  transportDocumentSubscription: Subscription;
  disableButtons: boolean;
  billOfEntry: ExportBillOfEntry;
  @Output() display: EventEmitter<any> = new EventEmitter();
  @ViewChild('correctionList') correctionList: OverlayPanel;
  branchCode: string;

  constructor(
    private exportFileService: ExportFileService,
    private notificationService: NotificationService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private messageService: NotificationService,
    private validationService: ValidationService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private authorisationService: AuthorisationService
  ) {
    this.validationService.setComponent(this);
  }

  ngOnInit() {
    this.authorisationService.getLoggedInUser().subscribe(user => {
      if (user && user.attributes && user.attributes['branch']) {
        this.branchCode = user.attributes['branch'][0];
      }
    });
    this.exportFileBehaviourSubject.getExportFile().subscribe(
      ef => {
        this.exportFile = ef;
        if (this.exportFile.clearingFileNumber) {
          this.exportFileService.loadAllExportFileCorrectionsIncludingOriginalExportFile(this.exportFile.clearingFileNumber, this.transportMethod)
            .subscribe(data => {
              this.listExportFiles = data;
            });
        }
      }
    );

    this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ExportTransportDocument) => (this.selectedTransportDocument = td));

    this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);

    this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(
      boe => this.billOfEntry = boe
    );

  }

  ngOnDestroy() {
    if (this.exportFileServiceSubscription) {
      this.exportFileServiceSubscription.unsubscribe();
    }
  }

  search(event: any) {
    this.exportFileServiceSubscription = this.exportFileService.loadExportFile(event.query, this.transportMethod, this.branchCode).subscribe(
      (exportFiles: ExportFile[]) => {
        this.filteredExportFiles = exportFiles;
      },
    );
  }

  selectInitial(value: ExportFile) {
    this.exportFileService.selectExportFile(value);
  }

  saveExportFile() {
    this.disableButtons = true;

    if (this.exportFile.transportDocument && this.exportFile.transportDocument.freight && !this.exportFile.transportDocument.freight.amount) {
      this.exportFile.transportDocument.freight.amount = 0;
    }

    this.validateClearingFile().subscribe(
      failed => {
        if (!failed) {
          this.saveConfirmation();
        }
        this.disableButtons = false;
      },
      error => {
        this.disableButtons = false;
      },
      () => {
        this.disableButtons = false;
      },
    );
  }

  addAction() {
    this.add.emit();
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  showErrors(label: string, messages?: string[]) {
    if (messages) {
      this.messageService.errorNotify(label, messages);
    } else {
      this.messageService.errorNotify(label);
    }
    return true;
  }

  validateClearingFile(): Observable<boolean> {
    let failed = false;
    let shouldSave = false;

    if (this.isSaveDisabled() && this.canDisable) {
      failed = this.showErrors('Cannot save as EDI has already been submitted.');
    }

    if (!failed) {
      failed = this.validationService.validateFileBasedOnStep(this.step, this.exportFile, 'exports',
        (header: string, messages: string[]) => this.showErrors(header, messages));
      if (!failed) { // Only save once
        return this.exportFileService.saveExportsFile(
          this.exportFile,
          this.selectedTransportDocument,
          this.selectedSupplierInvoice,
          this.selectedSupplierInvoiceLine).pipe(
          switchMap(value => of(failed)),
        );
      } else {
        return of(failed);
      }
    }
  }

  isSaveDisabled() {
    if (this.billOfEntry) {
      if (this.billOfEntry.dateEdiSubmitted) {
        if (this.billOfEntry.cusres && this.billOfEntry.cusres.customsStatus
          && this.billOfEntry.cusres.customsStatus === 'REJECT_TO_CLEARER') {
          return false;
        }

        if (this.billOfEntry.contrl && (this.billOfEntry.contrl.messageStatus === 'REJECTED' ||
          this.billOfEntry.contrl.messageStatus === 'UNB_OR_UNZ_REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'REJECTED' ||
          this.billOfEntry.contrl.interchangeStatus === 'UNB_OR_UNZ_REJECTED')) {
          return false;
        }
        return true;
      }

      if (this.billOfEntry.markedAsSubmitted) {
        return true;
      }
    }
    return false;
  }


  hasExportFile() {
    return this.exportFile.type === 'ExportFile' || this.exportFile.type === 'ExportFileCorrection';
  }

  hasMrn() {
    return !!(this.billOfEntry && this.billOfEntry.mrn);
  }

  createVoc() {

    if (!this.hasMrn()) {
      this.showErrors('Cannot create a VOC as no MRN was received for previous submission.');
    } else {
      this.display.emit(true);
    }
  }

  selectCorrection(value) {
    this.exportFileService.selectExportFile(value);
    this.correctionList.hide();
  }

}
