import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {BillOfEntry, ExBondBillOfEntry, ExportBillOfEntry} from '../app/digi-common/models/billOfEntries';
import { ShareDataService } from '../app/digi-common/services/share-data.service';

@Injectable({
  providedIn: 'root'
})
export class BillOfEntryBehaviourSubject {
  private billOfEntrySubject = new BehaviorSubject<BillOfEntry|ExportBillOfEntry|ExBondBillOfEntry>(null);
  private billsOfEntrySubject = new BehaviorSubject<BillOfEntry[]|ExportBillOfEntry[]|ExBondBillOfEntry[]>([]);

  constructor(private shareDataService: ShareDataService) {
  }

  addBillOfEntry(billOfEntry: BillOfEntry|ExportBillOfEntry|ExBondBillOfEntry) {
    this.billOfEntrySubject.next(billOfEntry);
  }

  getBillOfEntry(): Observable<BillOfEntry|ExportBillOfEntry|ExBondBillOfEntry> {
    return this.billOfEntrySubject.asObservable();
  }

  addBillsOfEntry(billOfEntry: BillOfEntry[]|ExportBillOfEntry[]|ExBondBillOfEntry[]) {
    this.billsOfEntrySubject.next(billOfEntry);
  }

  getBillsOfEntry(): Observable<BillOfEntry[]|ExportBillOfEntry[]|ExBondBillOfEntry[]> {
    return this.billsOfEntrySubject.asObservable();
  }
}
