<div class="ui-g">
  <div class="ui-g-12">
    <p-toolbar>
      <div class="ui-toolbar-group-left" style="color: black;">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
        <p-autoComplete [suggestions]="filteredCrew"
                        [(ngModel)]="selectedCrewMember" field="label"
                        [minLength]="3"
                        (completeMethod)="searchCrew($event)"
                        [forceSelection]="true"
                        [autoHighlight]="true"
                        [inputStyle]="{color:'white'}"
                        required=true>
          <ng-template let-option pTemplate="item">
            <div>{{option.label}}</div>
          </ng-template>
        </p-autoComplete>
        <label>Search Crew Member</label>
      </span>
          </div>
        </div>
      </div>
      <div class="ui-toolbar-group-right" style="color: black;">
        <button pButton type="button" (click)="createCrewMember()" label="New"
                icon="fa fa-file" class="ui-button-secondary"></button>
        <button pButton type="button" (click)="saveCrewMember()" label="Save" icon="fa fa-save">
        </button>
        <button *ngIf="selectedCrewMember.id" pButton type="button" (click)="deleteCrewMember()" label="Delete" icon="fa fa-trash">
        </button>
      </div>
    </p-toolbar>
    <digi-crew-member [selectedCrewMember]="selectedCrewMember"></digi-crew-member>
  </div>
</div>
