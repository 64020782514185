<p-toolbar class="toolbar">
  <div class="ui-toolbar-group-left">
    <div class="ui-inputgroup">
      <div class="ui-g form-group">
        <div class="ui-g-6 ui-md-6">
          <div class="ui-inputgroup">
            <p-autoComplete id="clearingFileNumber" [(ngModel)]="forwardingFile" field="fileNumber"
                            [suggestions]="filteredForwardingFiles"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            (onSelect)="select($event)"
                            minLength="2"
                            delay="600"
                            [inputStyle]="{color:'white'}"
                            placeholder="File Number">
              <ng-template let-option pTemplate="item">
                <div>{{option.fileNumber}}</div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div *ngIf="transportMethodCode === 'AIR'" class="ui-g-6 ui-md-6">
          <div class="ui-inputgroup">
            <p-autoComplete id="mawbNumber" [(ngModel)]="forwardingFile" field="mawbNumber"
                            [suggestions]="filteredForwardingFiles"
                            (completeMethod)="searchMawb($event)"
                            [autoHighlight]="true"
                            (onSelect)="select($event)"
                            minLength="2"
                            delay="600"
                            [inputStyle]="{color:'white'}"
                            placeholder="MAWB Number">
              <ng-template let-option pTemplate="item">
                <div>{{option.fileNumber}}</div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="Save" icon="fa fa-save" [disabled]="disableButtons" (click)="saveForwardingFile()"></button>
    <button pButton type="button" label="New" icon="fa fa-file" (click)="addAction()"
            class="ui-button-secondary"></button>
  </div>
</p-toolbar>
