import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Container,
  CustomsRadioCallSign,
  ExportTransportDocument,
  LocalAgent,
  OverseasAgent,
  PortDetail,
} from '../../../models/file';
import {Observable, Subscription} from 'rxjs';
import {ShareDataService} from '../../../services/share-data.service';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ExportFile} from '../../../models/exportFile';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {InternationalPortService} from '../../../services/international-port.service';
import {CustomsRadioCallSignService} from '../../../services/customs-radio-call-sign.service';
import {ExitPortService} from '../../../services/exit-port.service';
import {CollectPrepaidService} from '../../../services/collect-prepaid.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import * as moment from 'moment';
import {CompanyService} from '../../../services/company.service';
import {Branch} from '../../../models/company';
import {ValidationService} from '../../../services/validation.service';

@Component({
  selector: 'digi-export-transport-document',
  templateUrl: './export-transport-document.component.html',
  styleUrls: ['./export-transport-document.component.scss']
})
export class ExportTransportDocumentComponent implements OnInit, OnDestroy {
  exportFile: ExportFile;
  selectedTransportDocument: ExportTransportDocument;
  display = false;
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[];
  filteredPortDetails: PortDetail[];
  callPurposes: string[];
  filteredCollectPrepaids: any[];

  subscription: Subscription;
  tdSubscription: Subscription;
  portsSubscription: Subscription;
  containerSubscription: Subscription;
  overseasAgents: Observable<any>;
  validationMessagesForMawbNo: any[] = [];
  validationMessagesForHawbNo: any[] = [];


  constructor(
    private shareDataService: ShareDataService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private internationalPortService: InternationalPortService,
    private radioCallSignService: CustomsRadioCallSignService,
    private exitPortService: ExitPortService,
    private collectPrepaidService: CollectPrepaidService,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private companyService: CompanyService
  ) {
  }

  ngOnInit() {
    this.subscription = this.exportFileBehaviourSubject
      .getExportFile()
      .subscribe((ef: ExportFile) => {
        this.exportFile = ef;
        if (this.exportFile) {
          if (!this.exportFile.transportDocument) {
            const transportDocument = <ExportTransportDocument>this.shareDataService.addTransportDocument('exports');
            this.exportFile.transportDocument = transportDocument;
            this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
          } else {
            this.transportDocumentBehaviourSubject.addTransportDocument(this.exportFile.transportDocument);
          }
        }
      });

    this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ExportTransportDocument) => {
        if (td) {
          this.selectedTransportDocument = td;
          if (!this.selectedTransportDocument.notifyPartyName) {
            this.selectedTransportDocument.notifyPartyName = this.exportFile.exportConsignee.name;
            this.selectedTransportDocument.notifyPartyAddress = this.exportFile.exportConsignee.address;
          }
          if (!this.selectedTransportDocument.exchangeRateDate) {
            this.selectedTransportDocument.exchangeRateDate = moment(this.exportFile.assessmentDate, 'DD/MM/YYYY')
              .subtract(1, 'day').format('DD/MM/YYYY');
          }

          if (!this.selectedTransportDocument.exitPort.code) {
            this.companyService.getCompany().subscribe(companies => {
              if (companies && companies.length) {
                const branch: Branch = this.exportFile.branch ? companies[0].branches
                  .find(el => el.code === this.exportFile.branch) : companies[0].branches[0];
                this.exitPortService.findExitPortByNameOrCode(branch.exitPortCode).subscribe(
                  ports => this.selectExitPort(ports[0])
                );
              }
            });
          }

          if (this.selectedTransportDocument.straight) {
            this.selectedTransportDocument.hawbNo = this.selectedTransportDocument.mawbNo;
          }
        }
      });

    this.overseasAgents = this.exportFileBehaviourSubject.getOverseasAgents();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.tdSubscription) {
      this.tdSubscription.unsubscribe();
    }

    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }

    if (this.containerSubscription) {
      this.containerSubscription.unsubscribe();
    }
  }

  hasTransportDocument() {
    return this.exportFile && this.exportFile.transportDocument;
  }

  onDialogClose(event) {
    this.display = event;
  }

  searchRadioCallSignByCodeOrName(event, field) {
    this.radioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(
      radioCallSigns => this.filteredCustomsRadioCallSigns = radioCallSigns
    );
  }

  searchPort(event: any, port: string) {
//     let country = '';
//     if (port === 'loading' || port === 'docsIssuedAtPort') {
//       country = 'ZA';
//     }
//
//     if ((port === 'discharge' || port === 'destination') && this.exportFile.exportConsignee.country
//       && this.exportFile.exportConsignee.country.code) {
//       country = this.exportFile.exportConsignee.country.code;
//     }
//
//     if (country !== '') {
//       this.portsSubscription = this.internationalPortService
//         .findAllInternationalPortsWithCodeOrNameStartsWithAndCountryCode(event.query, country).subscribe(data => {
//           this.filteredPortDetails = data;
//         });
//     } else {
     this.portsSubscription = this.internationalPortService
       .findAllInternationalPortsWithCodeOrNameStartsWith(event.query).subscribe(data => {
         this.filteredPortDetails = data;
       });
//     }
  }

  selectPort(value, key) {
    if (value) {
      this.selectedTransportDocument[key] = new PortDetail();
      this.selectedTransportDocument[key].code = value.code;
      this.selectedTransportDocument[key].name = value.name;
      this.selectedTransportDocument[key].label = value.label;
      this.selectedTransportDocument[key].transportMethod = value.transportMethod;
      this.selectedTransportDocument[key].masPortCode = value.masPortCode;
      this.selectedTransportDocument[key].countryName = value.countryName;
      this.selectedTransportDocument[key].countryCode = value.countryCode;

      if (key === 'departurePort' && this.selectedTransportDocument.docsIssuedAtPort
        && !this.selectedTransportDocument.docsIssuedAtPort.code) {
        this.selectedTransportDocument.docsIssuedAtPort = new PortDetail();
        this.selectedTransportDocument.docsIssuedAtPort.code = value.code;
        this.selectedTransportDocument.docsIssuedAtPort.name = value.name;
        this.selectedTransportDocument.docsIssuedAtPort.label = value.label;
        this.selectedTransportDocument.docsIssuedAtPort.transportMethod = value.transportMethod;
        this.selectedTransportDocument.docsIssuedAtPort.masPortCode = value.masPortCode;
        this.selectedTransportDocument.docsIssuedAtPort.countryName = value.countryName;
        this.selectedTransportDocument.docsIssuedAtPort.countryCode = value.countryCode;
      }

      if (key === 'dischargePort' && this.selectedTransportDocument.destinationPort
        && !this.selectedTransportDocument.destinationPort.code) {
        this.selectedTransportDocument.destinationPort = new PortDetail();
        this.selectedTransportDocument.destinationPort.code = value.code;
        this.selectedTransportDocument.destinationPort.name = value.name;
        this.selectedTransportDocument.destinationPort.label = value.label;
        this.selectedTransportDocument.destinationPort.transportMethod = value.transportMethod;
        this.selectedTransportDocument.destinationPort.masPortCode = value.masPortCode;
        this.selectedTransportDocument.destinationPort.countryName = value.countryName;
        this.selectedTransportDocument.destinationPort.countryCode = value.countryCode;
      }
    }
  }

  selectExitPort(value) {
    this.selectedTransportDocument.exitPort = value;
  }

  searchExitPort(event) {
    this.exitPortService.findExitPortByNameOrCode(event.query).subscribe(
      ports => this.filteredPortDetails = ports
    );
  }

  searchCollectOrPrepaid(event) {
    this.collectPrepaidService.findCollectPrepaidStartWith(event.query).subscribe(data => {
      this.filteredCollectPrepaids = data;
    });
  }

  selectCollectOrPrepaid(value) {
    this.selectedTransportDocument.freight.collectPrepaid.code = value.code;
    this.selectedTransportDocument.freight.collectPrepaid.description = value.description;
  }

  selectOverseasAgent(value) {
    if (!this.selectedTransportDocument.overseasAgent) {
      this.selectedTransportDocument.overseasAgent = new OverseasAgent();
    }
    this.selectedTransportDocument.overseasAgent.code = value.code;
    this.selectedTransportDocument.overseasAgent.name = value.name;
    this.selectedTransportDocument.overseasAgent.label = value.label;
    this.selectedTransportDocument.overseasAgent.address = value.address;
  }

  selectCoLoader(value) {
    if (!this.selectedTransportDocument.coLoader) {
      this.selectedTransportDocument.coLoader = new LocalAgent();
    }
    this.selectedTransportDocument.coLoader.code = value.code;
    this.selectedTransportDocument.coLoader.name = value.name;
    this.selectedTransportDocument.coLoader.label = value.label;
    this.selectedTransportDocument.coLoader.address = value.address;
  }

  addContainer() {
    const container: Container = this.shareDataService.addContainer();
    this.exportFile.transportDocument.containers.push(container);
    this.exportFile.transportDocument.containers = [...this.exportFile.transportDocument.containers];
    this.containerBehaviorSubject.addContainer(container);
  }

  openSundryChargeModel() {
    this.display = true;
  }

  selectLocalForwarder(value) {
    this.selectedTransportDocument.localForwarder.cargoCarrierCode = value;
  }

  isRoadTransportDocument() {
    return this.exportFile.clearingInstructions[0].transportMethod.method === 'ROA';
  }

  isRailTransportDocument() {
    return this.exportFile.clearingInstructions[0].transportMethod.method === 'RAI';
  }

  isSeaTransportDocument() {
    return this.exportFile.clearingInstructions[0].transportMethod.method === 'SEA';
  }

  isAirTransportDocument() {
    return this.exportFile.clearingInstructions[0].transportMethod.method === 'AIR';
  }
}
