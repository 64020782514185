import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExportFile, LineDetails} from '../../models/exportFile';
import {Subscription} from 'rxjs';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFileService} from '../../services/export-file.service';
import {InternationalPortService} from '../../services/international-port.service';
import {
  Airline,
  ClearingInstruction,
  CustomsPurpose,
  CustomsPurposeFile,
  DepotTerminal, ExportTransportDocument,
  Information,
  PackageDetails, PackageDimensions,
  PortDetail,
  RouteDetails, SupplierInvoice, SupplierInvoiceLine,
  TransportMethod
} from '../../models/file';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {TransportMethodsService} from '../../services/transport-methods.service';
import {ClearingInstructionsService} from '../../services/clearing-instructions.service';
import {Debtor, ExportConsignee} from '../../../digi-business-entity/models/business-entity';
import {ExporterService} from 'src/app/digi-common/services/exporter.service';
import {ActivatedRoute} from '@angular/router';
import {ValidationService} from '../../services/validation.service';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryMarksAndNumbers, ExportBillOfEntry} from '../../models/billOfEntries';
import {TransportDocumentBehaviourSubject} from '../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {NotificationService} from '../../services/notification.service';
import {DebtorBehaviourSubject} from '../../../../subjects/debtor-behaviour-subject';
import {ShareDataService} from '../../services/share-data.service';
import {Branch} from '../../models/company';
import {CompanyService} from '../../services/company.service';

enum CustomsPurposeNames {
  CCA = 'Exports CCA1',
  EAS = 'Export as ship/aircraft stores',
  EIG = 'Export of Imported Goods',
  ELG = 'Export of Local Goods',
  XE = 'Export of Imported Goods (ex wareh',
  XES = 'Imported Goods ship/aircraft stores (exw',
  ZE = 'Export of Local Goods (ex warehou',
  ZES = 'SA Goods as ship/aircraft stores (ex whs'
}

@Component({
  selector: 'digi-export-registration',
  templateUrl: './export-registration.component.html',
  styleUrls: ['./export-registration.component.scss']
})
export class ExportRegistrationComponent implements OnInit, OnDestroy {
  @Input() transportMethodCode: string;
  display: any;
  customsProcedures: any;
  showCustomsProcedures: any;
  exportFile: ExportFile;
  availableInstructions: any;
  selectedInstructions: any;
  exportFileSubscription: Subscription;
  portsSubscription: Subscription;
  clearingInstructionsSubscription: Subscription;
  transportMethods: any[] = [];
  transportMethod: TransportMethod;
  clearingInstruction: ClearingInstruction;

  filteredDebtors: Debtor[];
  filteredDebtorsSubscription: Subscription;
  filteredExportConsignees: ExportConsignee[];
  filteredExportConsigneesSubscription: Subscription;
  exportFileServiceSubscription: Subscription;
  openSequence = false;
  filteredDepotTerminals: DepotTerminal[];
  disableButton: boolean;
  validationMessagesForMawbNo: any[] = [];
  activeIndex = 0;
  billOfEntry: ExportBillOfEntry;
  selectedTransportDocument: ExportTransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;

  constructor(
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private exportFileService: ExportFileService,
    private exporterService: ExporterService,
    private internationalPortService: InternationalPortService,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private transportMethodsService: TransportMethodsService,
    private clearingInstructionService: ClearingInstructionsService,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private billOfEntryBehaviorSubject: BillOfEntryBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private messageService: NotificationService,
    private debtorsBehaviourSubject: DebtorBehaviourSubject,
    private shareDataService: ShareDataService,
    private companyService: CompanyService
  ) {
    this.validationService.setComponent(this);
  }

  ngOnInit() {
    this.findAllClearingInstructions();
    this.exportFileSubscription = this.exportFileBehaviourSubject.getExportFile().subscribe(
      ef => {
        if (ef) {
          this.exportFile = ef;
          this.companyService.getCompany().subscribe(companies => {
            if (companies && companies.length) {
              const branch: Branch = companies[0].branches[0];
              if (branch.invoicingInUse) {
                this.exportFile.invoicingInUse = branch.invoicingInUse;
              }
            }
          });
          if (this.clearingInstructionsSubscription) {
            this.clearingInstructionsSubscription.unsubscribe();
          }
          if (this.filteredDebtorsSubscription) {
            this.filteredDebtorsSubscription.unsubscribe();
          }
          if (this.filteredExportConsigneesSubscription) {
            this.filteredExportConsigneesSubscription.unsubscribe();
          }
          this.filteredDebtorsSubscription = this.debtorsBehaviourSubject.getDebtors().subscribe(
            (debtors: Debtor[]) => this.filteredDebtors = debtors
          );
          this.exportFileBehaviourSubject.getExportConsignees().subscribe(
            (exportConsignees: ExportConsignee[]) => this.filteredExportConsignees = exportConsignees
          );
          this.transportDocumentBehaviourSubject.getTransportDocuments()
            .subscribe((td: ExportTransportDocument) => (this.selectedTransportDocument = td));

          this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
            .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

          this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
            .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);
          this.billOfEntryBehaviorSubject.getBillOfEntry()
            .subscribe(billOfEntry => this.billOfEntry = billOfEntry);
          this.clearingInstructionsSubscription = this.clearingInstructionBehaviorSubject
            .getClearingInstructions()
            .subscribe(ci => {
              if (ci) {
                this.selectedInstructions = ci;
                if (this.selectedInstructions && this.selectedInstructions.length > 0) {
                  this.transportMethodsService.findTransportMethods().subscribe(
                    transportMethods => {
                      this.transportMethods = transportMethods;
                      this.transportMethods.forEach(method => {
                        if (method.method === this.transportMethodCode) {
                          this.transportMethod = method;
                          this.exportFile.clearingInstructions.forEach(clearingInstruction => {
                            if (clearingInstruction && !clearingInstruction.transportMethod) {
                              clearingInstruction.transportMethod = method;
                            }
                          });
                        }
                      });
                    });
                }
              }
            });

          if (this.exportFile.transportDocument && !this.exportFile.transportDocument.handlingInformation) {
            this.exportFile.transportDocument.handlingInformation = new Information();
          }

          if (this.exportFile.transportDocument && !this.exportFile.transportDocument.accountingInformation) {
            this.exportFile.transportDocument.accountingInformation = new Information();
          }
        }
      }
    );

    const fileNumber = this.route.snapshot.paramMap.get('clearingFileNumber');
    const sequenceNumber = this.route.snapshot.paramMap.get('sequenceNumber');
    if (fileNumber) {
      if (Number.parseInt(sequenceNumber) === 0) {
        this.exportFileServiceSubscription = this.exportFileService
          .loadExportFile(fileNumber, this.transportMethodCode)
          .subscribe(data => {
            const file = data.find(cf => cf.sequence === Number.parseInt(sequenceNumber));
            this.exportFileService.createNewFile();
            this.exportFileService.selectExportFile(file);
          });
      } else {
        this.exportFileServiceSubscription = this.exportFileService
          .loadAllExportFileCorrectionsIncludingOriginalExportFile(fileNumber, this.transportMethodCode)
          .subscribe(data => {
            const file = data.find(cf => cf.sequence === Number.parseInt(sequenceNumber));
            this.exportFileService.createNewFile();
            this.exportFileService.selectExportFile(file);
          });
      }
    }
  }

  ngOnDestroy() {
    if (this.exportFileSubscription) {
      this.exportFileSubscription.unsubscribe();
    }

    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }

    if (this.filteredDebtorsSubscription) {
      this.filteredDebtorsSubscription.unsubscribe();
    }
    if (this.filteredExportConsigneesSubscription) {
      this.filteredExportConsigneesSubscription.unsubscribe();
    }

  }

  createNewFile() {
    this.exportFileService.createNewFile(this.transportMethodCode);
  }

  selectClearingOption(value) {
    if (value.items.length === 1) {
      const customsPurposeFiles = value.items[0].customsPurposeFiles;
      const customsProcedures = value.items[0].customsProcedures;
      this.updateCustomsProcedure(customsProcedures);
      this.updateCustomsPurposeFile(customsPurposeFiles, this.exportFile);
      this.setCustomsPurposeOnClearingInstruction(value);
      switch (value.items[0].customsPurposeName) {
        case CustomsPurposeNames.ELG:
        case CustomsPurposeNames.EAS:
        case CustomsPurposeNames.EIG:
        case CustomsPurposeNames.CCA:
        case CustomsPurposeNames.XE:
        case CustomsPurposeNames.ZE:
          this.clearingInstructionBehaviorSubject.addInstruction(this.clearingInstruction);
          this.clearingInstructionBehaviorSubject.addClearingInstructions(this.exportFile.clearingInstructions);
          this.showDialog();
          break;
      }
    }
  }

  onMoveToSource(value: any) {
    const index: number = this.exportFile.clearingInstructions.indexOf(value);
    if (index !== -1) {
      this.exportFile.clearingInstructions.splice(index, 1);
    }
    this.customsProcedures = [];
    this.findAllClearingInstructions();
  }

  selectedClearingInstruction(value: any) {
    let clearingInstruction = new ClearingInstruction();
    clearingInstruction = value.items[0];

    if (value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.EIG ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.ELG ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.EAS ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.ZE ||
      value.items[0].customsPurpose.customsPurposeName === CustomsPurposeNames.XE) {
      this.clearingInstructionBehaviorSubject.addInstruction(clearingInstruction);
      this.showDialog();
      this.clearingInstructionBehaviorSubject.addClearingInstructions(this.exportFile.clearingInstructions);
    }
  }

  private findAllClearingInstructions() {
    this.clearingInstructionService.findAllExportInstructions().subscribe(
      data => {
        this.availableInstructions = data._embedded.customsPurposes;
        this.updateAvailableClearingInstructions(data);
      });
  }

  private updateAvailableClearingInstructions(data) {
    for (let x = 0; x < data._embedded.customsPurposes.length; x++) {
      this.availableInstructions[x].customsPurpose = new CustomsPurpose();
      this.availableInstructions[x].customsPurpose.customsPurposeName = data._embedded.customsPurposes[x].customsPurposeName;
      this.availableInstructions[x].customsPurpose.customsPurposeCode = data._embedded.customsPurposes[x].customsPurposeCode;
    }
  }

  private showDialog() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  onCloseCustomProcedureModal(event) {
    this.showCustomsProcedures = event;
  }

  private updateCustomsProcedure(customsProcedures) {
    if (customsProcedures && customsProcedures.length === 1) {
      this.exportFile.clearingInstructions.find(cl => {
        if (cl && (!cl.customsProcedureCode && !cl.customsProcDesc && !cl.procedureCategoryCode && !cl.pcDescription || !cl.destination)) {
          cl.customsProcedureCode = customsProcedures[0].customsProcedureCode;
          cl.customsProcDesc = customsProcedures[0].customsProcDesc;
          cl.procedureCategoryCode = customsProcedures[0].procedureCategory.procedureCategoryCode;
          cl.pcDescription = customsProcedures[0].procedureCategory.pcDescription;
          return true;
        }
        return false;
      });
    } else {
      this.customsProcedures = customsProcedures;
      this.showCustomsProcedures = true;
    }
  }

  private updateCustomsPurposeFile(customsPurposeFiles, clearingFile) {
    if (customsPurposeFiles) {
      customsPurposeFiles.forEach(function (customsPurposeFile) {
        if (customsPurposeFile.id.fileTypeId === 2) {
          clearingFile.clearingInstructions.find(cl => {
            if (cl.customsPurpose && cl.customsPurpose.customsPurposeFile && !cl.customsPurpose.customsPurposeFile.vocDocumentCode) {
              cl.customsPurpose.customsPurposeFile = new CustomsPurposeFile();
              cl.customsPurpose.customsPurposeFile.customsPurposeCode = customsPurposeFile.id.customsPurposeCode;
              cl.customsPurpose.customsPurposeFile.fileTypeId = customsPurposeFile.id.fileTypeId;
              cl.customsPurpose.customsPurposeFile.documentCode = customsPurposeFile.documentCode;
              cl.customsPurpose.customsPurposeFile.vocDocumentCode = customsPurposeFile.vocDocumentCode;
            }
          });
        }
      });
    }
  }

  private setCustomsPurposeOnClearingInstruction(value) {
    const uuid = this.createClearingInstruction_UUID();
    this.exportFile.clearingInstructions.find(ci => {
      if (ci && (!ci.customsPurpose && !ci.customsPurpose.customsPurposeCode || !ci.destination)) {
        this.clearingInstruction = new ClearingInstruction();
        ci.uuid = uuid;
        ci.customsPurpose.customsPurposeName = value.items[0].customsPurposeName;
        ci.customsPurpose.customsPurposeCode = value.items[0].customsPurposeCode;
        ci.transportMethod = this.transportMethod;
        this.clearingInstruction = ci;
        return true;
      }
      return false;
    });
  }

  private createClearingInstruction_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  updateCustomsOffice(value) {
    this.exportFile.customOffice = value;
  }

  selectExporter(value) {
    this.exportFile.exporter.code = value.code;
    this.exportFile.exporter.name = value.name;
    this.exportFile.exporter.label = value.label;
    this.exportFile.exporter.address = value.address;
    this.exportFile.exporter.idNumber = value.idNumber;
    this.exportFile.exporter.registrationType = value.registrationType;
    this.exportFile.exporter.passportNumber = value.passportNumber;
    this.exportFile.exporter.currency = value.currency;
    this.exportFile.exporter.customsCode = value.defaultCustomsCode;
    this.exportFile.exporter.taxRegNo = value.defaultVatRegNo;
    this.exportFile.exporter.receivingBank = value.receivingBank;
  }

  selectDebtor(value) {
    this.exportFile.debtor.code = value.code;
    this.exportFile.debtor.name = value.name;
    this.exportFile.debtor.label = value.label;
    this.exportFile.debtor.address = value.address;
  }

  selectConsignee(value) {
    this.exportFile.exportConsignee.code = value.code;
    this.exportFile.exportConsignee.name = value.name;
    this.exportFile.exportConsignee.label = value.label;
    this.exportFile.exportConsignee.address = value.address;
    this.exportFile.exportConsignee.country = value.country;
    this.exportFile.exportConsignee.termsOfPayment = value.termsOfPayment;
    this.exportFile.exportConsignee.hasBankingDetails = value.hasBankingDetails;
    this.exportFile.exportConsignee.commercialBankName = value.commercialBankName;
    this.exportFile.exportConsignee.commercialBankCode = value.commercialBankCode;
    this.exportFile.exportConsignee.commercialBankAccNo = value.commercialBankAccNo;
    this.exportFile.exportConsignee.commercialBankSwiftCode = value.commercialBankSwiftCode;
    this.exportFile.exportConsignee.defaultPurchaseTerm = value.defaultPurchaseTerm;
  }

  selectLocalAgent(value) {
    this.exportFile.localAgent.code = value.code;
    this.exportFile.localAgent.name = value.name;
    this.exportFile.localAgent.label = value.label;
    this.exportFile.localAgent.address = value.address;
    this.exportFile.localAgent.customsRegCode = value.defaultCustomsCode;
    this.exportFile.localAgent.iataCode = value.iataCode;
  }

  isCorrection() {
    return this.exportFile.type === 'ExportFileCorrection';
  }

  openSequenceModal(value) {
    this.openSequence = value;
  }

  onSequenceClose(event) {
    this.openSequence = event;
  }

  isAirTransport() {
    return this.transportMethodCode === 'AIR';
  }

  isSeaTransport() {
    return this.transportMethodCode === 'SEA';
  }

  validateMawbNo() {
    this.exportFileService.findClearingFileNumbersByMawbNo(this.exportFile.transportDocument.mawbNo, this.exportFile.clearingFileNumber).subscribe(
      res => {
        if (res.length > 0) {
          const message = 'MAWB No. used in [' + res.join(', ') + ']';
          const foundDetail = this.validationMessagesForMawbNo.find(el => el.detail === message);
          if (!foundDetail) {
            this.validationMessagesForMawbNo.push({severity: 'warn', summary: 'Duplicate MAWB No.', detail: message});
          }
        } else {
          this.validationMessagesForMawbNo.length = 0;
        }
      },
      _ => this.validationMessagesForMawbNo.length = 0
    );
  }

  handleIndexChange(event: any) {
    if (event.index === 1) {
      const validationResult = this.validationService.validateRegistration(this.exportFile, 'exports');
      if (validationResult.isValid) {
        this.activeIndex = 1;
        if (!this.isSaveDisabled()) {
          this.exportFileService.saveExportsFile(this.exportFile,
            this.selectedTransportDocument,
            this.selectedSupplierInvoice,
            this.selectedSupplierInvoiceLine).subscribe(
            _ => {
              this.saveConfirmation();
            }
          );
        }
      } else {
        this.activeIndex = 0;
        this.showMessages(validationResult.messages);
      }
    }

    if (event.index === 2 && !this.exportFile.transportDocument.flight) {
      this.showMessages(['Please Enter FLight Number']);
    }

    if (event.index === 4) {
      if (this.exportFile.transportDocument.packageDetails) {
        let packageDims = [];
        this.exportFile.transportDocument.packageDetails.forEach(el => packageDims = packageDims.concat(el.dimensions));
        this.exportFile.transportDocument.measures.numberOfPackages = packageDims
          .map(dimensions => dimensions.quantity)
          .reduce((sum, current) => current ? +sum + +current : +sum);

        this.exportFile.transportDocument.measures.volume = packageDims
          .map(dimensions => dimensions.volumetricWeight)
          .reduce((sum, current) => current ? +sum + +current : +sum);

        this.exportFile.transportDocument.measures.grossWeight = this.exportFile.transportDocument.packageDetails
          .map(packageDetail => packageDetail.actualWeight)
          .reduce((sum, current) => current ? +sum + +current : +sum);

        this.exportFile.transportDocument.measures.chargeableWeight = this.exportFile.transportDocument.packageDetails
          .map(packageDetail => packageDetail.chargeableWeight)
          .reduce((sum, current) => current ? +sum + +current : +sum);
      }
    }
  }

  isSaveDisabled() {
    return !!(this.billOfEntry && this.billOfEntry.dateEdiSubmitted);
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  showMessages(messages: string[]) {
    this.messageService.errorNotify('Validation Failed', messages);
  }

  isBackToBackShipment() {
    return this.selectedTransportDocument.shipmentType === 'BACK_TO_BACK';
  }

  createShippingOrder() {
    this.shareDataService.createShippingOrder(this.exportFile, this.billOfEntry);
  }
}
