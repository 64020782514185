import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Permit, TariffCode, TariffFormula, TradeAgreement} from '../../../models/file';
import {PermitsService} from '../../../permits/services/permits.service';

@Component({
  selector: 'digi-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnChanges {
  @Input() tariffCode = new TariffCode();
  @Input() tradeAgreement: TradeAgreement = {code: 'STANDARD'};
  rateOfDuty: string;
  cols: any[];
  permits: Permit[];

  constructor(
    private permitsService: PermitsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.cols = [
      {header: 'Import/Export', width: '30%'},
      {header: 'Permit', width: '70%'}
    ];

    if (changes.tariffCode && changes.tariffCode.currentValue) {
      this.tariffCode = changes.tariffCode.currentValue;
      this.setParametersAndFormula();
    }

    if (changes.tradeAgreement && changes.tradeAgreement.currentValue) {
      this.tradeAgreement = changes.tradeAgreement.currentValue;
      this.setParametersAndFormula();
    }

    this.permitsService.findPermitsByTariffCode(this.tariffCode.code).subscribe(
      permits => this.permits = permits
    );
  }

  private setParametersAndFormula () {
    if (this.tariffCode.effectiveTradeAgreementFormulae && this.tariffCode.effectiveTradeAgreementFormulae[this.tradeAgreement.code.trim()]) {
      const effectiveFormula = this.tariffCode.effectiveTradeAgreementFormulae[this.tradeAgreement.code.trim()];
      this.tariffCode.formula = effectiveFormula;
      this.rateOfDuty = effectiveFormula.description;
    }

    if (!this.isEmpty(this.tariffCode.effectiveTradeAgreementParameters)) {
      this.tariffCode.parameters = [];
      if (this.tariffCode.effectiveTradeAgreementParameters[this.tradeAgreement.code.trim()]) {
        Object.keys(this.tariffCode.effectiveTradeAgreementParameters[this.tradeAgreement.code.trim()]).forEach(effectiveParameterKeys => {
          this.tariffCode.parameters.push(this.tariffCode.effectiveTradeAgreementParameters[this.tradeAgreement.code.trim()][effectiveParameterKeys]);
        });
      }
    }
  }

  private isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
