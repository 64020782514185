import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {ShareDataService} from '../../digi-common/services/share-data.service';

@Component({
  selector: 'digi-rail-dashboard',
  templateUrl: './rail-dashboard.component.html',
  styleUrls: ['./rail-dashboard.component.css']
})
export class RailDashboardComponent implements OnInit {

  constructor(private router: Router,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
              private shareDataService: ShareDataService,
              private route: ActivatedRoute
              ) {
  }

  ngOnInit() {
    this.clearingFileBehaviourSubject.addClearingFile(this.shareDataService.buildClearingFile('RAI'));
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('imports'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);

    if (this.router.url !== '/rail/register/') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate(['rail', 'register']);
    }
  }

}
