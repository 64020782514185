import {
  Container, ContainerRunningTotals, ContainerType,
  CustomOffice,
  CustomsRadioCallSign,
  DepotTerminal,
  Freight, Measures,
  OceanLine,
  OceanLineDetails,
  PortDetail, TariffCode,
  VoyageDetails
} from './file';
import {BusinessEntity} from '../../digi-business-entity/models/business-entity';
import {BillOfEntryMarksAndNumbers, EDIMessage} from './billOfEntries';
import {CuscarType, PackType} from './roadManifest';

export class GoodsDescription {
  code?: string;
  undgCode?: string;
  imoPageNo?: string;
  description?: string;
  unDangerousGoodsNo?: string;
  hazardCodeVersionNo?: string;
  undgFlashPoint?: string;
  undgFlashPointUnit?: string;
}

export class Package {
  description?: string;
  vinNumber?: string;
  referenceNo?: string;
  packageType?: PackType;
  noOfPackages?: number;
  ucrNumber?: string;
  weight?: number;
  volume?: number;
  containerNo?: string;
  tariffCode?: TariffCode;
  goodsDescription?: GoodsDescription;
  containerType?: ContainerType;
}

export class VoyageMasterFile {
  voyageMasterFileNo?: string;
  bolNo?: string;
  sobDate?: string;
  voyageNo?: string;
  voyageDetails?: VoyageDetails;
  oceanLineDetails?: OceanLineDetails;
  overseasAgent?: BusinessEntity;
  localForwarder?: BusinessEntity;
  releaseAgent?: BusinessEntity;
  consignee?: BusinessEntity;
  consignor?: BusinessEntity;
  debtor?: BusinessEntity;
  freight?: Freight;
  customsOffice?: CustomOffice;
  clientRef?: string;
  depot?: DepotTerminal;
  terminal?: DepotTerminal;
  callPurpose?: string;
  containers?: Container[];
  containerRunningTotals?: ContainerRunningTotals;
  arrivalDate?: string;
  departureDate?: string;
  departurePort: PortDetail;
  destinationPort?: PortDetail;
  dischargePort?: PortDetail;
  docsIssuedAtPort?: PortDetail;
  measures?: Measures;
  marksAndNumbers?: BillOfEntryMarksAndNumbers[];
  voyageMasterFilePackages?: Package[];
  releaseTo?: string;
  releaseDate?: string;
  commodity?: string;
  id?: string;
  contrl?: EDIMessage;
  cusres?: EDIMessage;
  cuscars?: string[];
  cuscarType?: CuscarType;
  messageFunction?: number;
  carn?: string;
  documentMessageNumber?: string;
  sequence?: number;
  manifestType?: string;
  cargoType?: string;
  _links?: any;
  radioCallSign?: CustomsRadioCallSign;
  dateEdiSubmitted?: string;
  branch?: string;
}

export class VoyageFile {
  id?: string;
  voyageFileNo?: string;
  voyageNo?: string;
  oceanLine?: OceanLine;
  radioCallSign?: CustomsRadioCallSign;
  departurePort?: PortDetail;
  destinationPort?: PortDetail;
  departureDate?: string;
  originalDepartureDate?: string;
  arrivalDate?: string;
  originalArrivalDate?: string;
  sailingDate?: string;
  dockingDate?: string;
  provisionalStackOpenDate?: string;
  firmStackOpenDate?: string;
  stackCloseDate?: string;
  dischargeCompletedDate?: string;
  storageCommencementDate?: string;
  dischargeCommencementDate?: string;
  lastFreeDate?: string;
  weekNo?: number;
  feederVessel?: string;
  feederVoyageNo?: string;
  transferPort?: PortDetail;
  transShipmentArrivalDate?: string;
  transShipmentDepartureDate?: string;
  type?: string;
  comments?: string;
  voyageMasterFiles?: VoyageMasterFile[];
  [k: string]: any;
}

export enum ManifestType {
  EXPORT = '22', IMPORT = '23', TRANSIT = '24', TRANSHIPMENT = '28', MUTUALLY_DEFINED_MULTIPLE_PROCEDURES = 'ZZZ'
}

export enum CargoType {
  DRY_BULK = 'DB', LIQUID_BULK = 'LB', BREAK_BULK = 'BB', CONTAINER = 'CN', MIXED_CARGO = 'MX'
}
