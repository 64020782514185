<p-tabView orientation="left" styleClass="stepMenu" (onChange)="validateTransportDocument($event,tabView)" #tabView>
  <p-tabPanel [header]="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'?'HBOL':'Road BOL'" [selected]="true">
    <div class="ui-g-12 ui-g-nopad">
      <!-- Clearing Form -->
      <div class="ui-g-12 pt-0">
        <digi-clearing-file-section step="transportDocument" (add)="addTransportDocument()" [canAdd]="true"></digi-clearing-file-section>
      </div>
      <!--/Clearing Form -->
    </div>
    <!-- Table -->
    <div>
      <p-messages [(value)]="validationMessagesForMawbNo"></p-messages>
      <p-messages [(value)]="validationMessagesForHawbNo"></p-messages>
    </div>
    <div *ngIf="hasTransportDocument()" class="mt-2">
      <pre style="visibility: hidden;">{{hasTransportDocument()}}</pre>
      <digi-hawb-summary-table></digi-hawb-summary-table>
      <br>
      <!-- Table -->
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel [header]="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'?'Ocean Line':'Transporter'">
              <digi-road-oceanline-details [validationMessagesForMawbNo]="validationMessagesForMawbNo"
                                           [validationMessagesForHawbNo]="validationMessagesForHawbNo" [fileType]="'imports'">
              </digi-road-oceanline-details>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Local Forwarder">
              <digi-digi-hawb-shipment-detail-section>
              </digi-digi-hawb-shipment-detail-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Voyage Details">
              <digi-voyage-details [selectedTransportDocument]="selectedTransportDocument"></digi-voyage-details>
            </p-panel>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="Weights & Measures">
              <digi-hawb-weight-measures-section label='CBM'></digi-hawb-weight-measures-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="Freight">
              <digi-hawb-freight-section [freight]="selectedTransportDocument.freight"></digi-hawb-freight-section>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="RIB BOE Details">
              <digi-hawb-boe-details-section></digi-hawb-boe-details-section>
            </p-panel>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Containers" [disabled]="clearingFile.transportDocuments.length <= 0">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-containers-section [type]="'imports'">
          <digi-clearing-file-section step="transportDocument" [canAdd]="true" (add)="addContainer()">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12"
                   *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'SEA'">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon">HBOL No</span>
                  <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}" readonly>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12" *ngIf="clearingFile.clearingInstructions[0].transportMethod.method === 'ROA'">
        <span class="md-inputfield">
        <input type="text" pInputText readonly
               value="{{clearingFile?.importer?.code}} : {{clearingFile?.importer?.name}}">
        <label style="color: white;">Importer</label>
      </span>
              </div>
            </div>
          </digi-clearing-file-section>
        </digi-containers-section>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Mrks,Nmbrs" [disabled]="clearingFile.transportDocuments.length <= 0">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-marks-numbers>
          <digi-clearing-file-section step="transportDocument" [canAdd]="false">
            <div class="ui-g-12 ui-md-6">
              <div class="ui-inputgroup">
                <span class="md-inputfield ui-md-4">
                  <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}"
                         readonly>
                  <label style="color: white;">HBOL No</label>
                </span>
              </div>
            </div>
          </digi-clearing-file-section>
        </digi-marks-numbers>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>

