<div class="ui-g form-group">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-inputgroup">
      <p-autoComplete id="clearingFileNumber" [(ngModel)]="clearingFile" field="clearingFileNumber"
                      [suggestions]="filteredClearingFiles"
                      (completeMethod)="search($event)"
                      [autoHighlight]="true"
                      (onSelect)="selectInitial($event)"
                      minLength="2"
                      delay="600"
                      [inputStyle]="{color:'white'}"
                      placeholder="File No./Ref. File No.">
        <ng-template let-option pTemplate="item">
          <div>{{option.clearingFileNumber}}</div>
        </ng-template>
      </p-autoComplete>
      <span class="sequence ui-inputgroup-addon" (click)="correctionList.toggle($event)">{{clearingFile.sequence}}</span>
      <p-overlayPanel #correctionList [dismissable]="true">
        <ng-template ngFor let-option [ngForOf]="listClearingFiles" pTemplate="item">
          <div id="sequence" class="ui-overlaypanel-content" (click)="selectCorrection(option)">
            {{option.clearingFileNumber}}/{{option.sequence}}
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div>
</div>





