var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var AbstractBoE = /** @class */ (function () {
    function AbstractBoE() {
    }
    return AbstractBoE;
}());
export { AbstractBoE };
var BillOfEntry = /** @class */ (function (_super) {
    __extends(BillOfEntry, _super);
    function BillOfEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BillOfEntry;
}(AbstractBoE));
export { BillOfEntry };
var ExportBillOfEntry = /** @class */ (function (_super) {
    __extends(ExportBillOfEntry, _super);
    function ExportBillOfEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExportBillOfEntry;
}(AbstractBoE));
export { ExportBillOfEntry };
var ExBondBillOfEntry = /** @class */ (function (_super) {
    __extends(ExBondBillOfEntry, _super);
    function ExBondBillOfEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExBondBillOfEntry;
}(AbstractBoE));
export { ExBondBillOfEntry };
var SchedulePartTotal = /** @class */ (function () {
    function SchedulePartTotal() {
    }
    return SchedulePartTotal;
}());
export { SchedulePartTotal };
var Container = /** @class */ (function () {
    function Container() {
    }
    return Container;
}());
export { Container };
var DepotTerminal = /** @class */ (function () {
    function DepotTerminal() {
    }
    return DepotTerminal;
}());
export { DepotTerminal };
var SundryChargeAmount = /** @class */ (function () {
    function SundryChargeAmount() {
    }
    return SundryChargeAmount;
}());
var BoEInvoiceDetails = /** @class */ (function () {
    function BoEInvoiceDetails() {
    }
    return BoEInvoiceDetails;
}());
export { BoEInvoiceDetails };
var ErrorDetail = /** @class */ (function () {
    function ErrorDetail() {
    }
    return ErrorDetail;
}());
export { ErrorDetail };
var ErrorPointDetails = /** @class */ (function () {
    function ErrorPointDetails() {
    }
    return ErrorPointDetails;
}());
export { ErrorPointDetails };
var ApplicationErrorInfo = /** @class */ (function () {
    function ApplicationErrorInfo() {
    }
    return ApplicationErrorInfo;
}());
export { ApplicationErrorInfo };
var EDIErrorDetails = /** @class */ (function () {
    function EDIErrorDetails() {
    }
    return EDIErrorDetails;
}());
export { EDIErrorDetails };
var EDIMessage = /** @class */ (function () {
    function EDIMessage() {
    }
    return EDIMessage;
}());
export { EDIMessage };
var Importer = /** @class */ (function () {
    function Importer() {
    }
    return Importer;
}());
export { Importer };
var Address = /** @class */ (function () {
    function Address() {
    }
    return Address;
}());
export { Address };
var SchedulePart = /** @class */ (function () {
    function SchedulePart() {
    }
    return SchedulePart;
}());
export { SchedulePart };
var BoeAddInfoType = /** @class */ (function () {
    function BoeAddInfoType() {
    }
    return BoeAddInfoType;
}());
export { BoeAddInfoType };
var BoeAddInfoTypeId = /** @class */ (function () {
    function BoeAddInfoTypeId() {
    }
    return BoeAddInfoTypeId;
}());
export { BoeAddInfoTypeId };
var BillOfEntryMarksAndNumbers = /** @class */ (function () {
    function BillOfEntryMarksAndNumbers() {
    }
    return BillOfEntryMarksAndNumbers;
}());
export { BillOfEntryMarksAndNumbers };
var ClearingInstruction = /** @class */ (function () {
    function ClearingInstruction() {
    }
    return ClearingInstruction;
}());
export { ClearingInstruction };
var BillOfEntryPermit = /** @class */ (function () {
    function BillOfEntryPermit() {
    }
    return BillOfEntryPermit;
}());
export { BillOfEntryPermit };
var CustomsPurpose = /** @class */ (function () {
    function CustomsPurpose() {
    }
    return CustomsPurpose;
}());
export { CustomsPurpose };
var CustomsPurposeFile = /** @class */ (function () {
    function CustomsPurposeFile() {
    }
    return CustomsPurposeFile;
}());
export { CustomsPurposeFile };
var Country = /** @class */ (function () {
    function Country() {
    }
    return Country;
}());
export { Country };
var TradeAgreement = /** @class */ (function () {
    function TradeAgreement() {
    }
    return TradeAgreement;
}());
export { TradeAgreement };
var TransportMethod = /** @class */ (function () {
    function TransportMethod() {
    }
    return TransportMethod;
}());
export { TransportMethod };
var FormulaComputation = /** @class */ (function () {
    function FormulaComputation() {
    }
    return FormulaComputation;
}());
export { FormulaComputation };
var WarehouseCaptureDetails = /** @class */ (function () {
    function WarehouseCaptureDetails() {
    }
    return WarehouseCaptureDetails;
}());
var AbstractBoELine = /** @class */ (function () {
    function AbstractBoELine() {
    }
    return AbstractBoELine;
}());
export { AbstractBoELine };
var BillOfEntryLine = /** @class */ (function (_super) {
    __extends(BillOfEntryLine, _super);
    function BillOfEntryLine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BillOfEntryLine;
}(AbstractBoELine));
export { BillOfEntryLine };
var ExBondBillOfEntryLine = /** @class */ (function (_super) {
    __extends(ExBondBillOfEntryLine, _super);
    function ExBondBillOfEntryLine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExBondBillOfEntryLine;
}(AbstractBoELine));
export { ExBondBillOfEntryLine };
var ExportBillOfEntryLine = /** @class */ (function (_super) {
    __extends(ExportBillOfEntryLine, _super);
    function ExportBillOfEntryLine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExportBillOfEntryLine;
}(AbstractBoELine));
export { ExportBillOfEntryLine };
var TariffCode = /** @class */ (function () {
    function TariffCode() {
    }
    return TariffCode;
}());
export { TariffCode };
var Permit = /** @class */ (function () {
    function Permit() {
    }
    return Permit;
}());
export { Permit };
var Formula = /** @class */ (function () {
    function Formula() {
    }
    return Formula;
}());
export { Formula };
var FormulaParameter = /** @class */ (function () {
    function FormulaParameter() {
    }
    return FormulaParameter;
}());
export { FormulaParameter };
var TariffParameter = /** @class */ (function () {
    function TariffParameter() {
    }
    return TariffParameter;
}());
export { TariffParameter };
var Supplier = /** @class */ (function () {
    function Supplier() {
    }
    return Supplier;
}());
export { Supplier };
var Destination = /** @class */ (function () {
    function Destination() {
    }
    return Destination;
}());
export { Destination };
var Export = /** @class */ (function () {
    function Export() {
    }
    return Export;
}());
export { Export };
var PaymentType = /** @class */ (function () {
    function PaymentType() {
    }
    return PaymentType;
}());
export { PaymentType };
var PermitTariff = /** @class */ (function () {
    function PermitTariff() {
    }
    return PermitTariff;
}());
export { PermitTariff };
var ContainerType = /** @class */ (function () {
    function ContainerType() {
    }
    return ContainerType;
}());
export { ContainerType };
var ContainerSpecification = /** @class */ (function () {
    function ContainerSpecification() {
    }
    return ContainerSpecification;
}());
export { ContainerSpecification };
var SealDetails = /** @class */ (function () {
    function SealDetails() {
    }
    return SealDetails;
}());
export { SealDetails };
var MasContainerType = /** @class */ (function () {
    function MasContainerType() {
    }
    return MasContainerType;
}());
export { MasContainerType };
var MasContainerSize = /** @class */ (function () {
    function MasContainerSize() {
    }
    return MasContainerSize;
}());
export { MasContainerSize };
var Solas = /** @class */ (function () {
    function Solas() {
    }
    return Solas;
}());
export { Solas };
var ContainerSize = /** @class */ (function () {
    function ContainerSize() {
    }
    return ContainerSize;
}());
export { ContainerSize };
var SequenceData = /** @class */ (function () {
    function SequenceData() {
    }
    return SequenceData;
}());
export { SequenceData };
