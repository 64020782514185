<p-panel>
    <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-3 ui-md-3">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value={{businessEntity.code}} pInputText
                        readonly>
                    <label>Debtor Code</label>
                </span>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value={{businessEntity.name}} pInputText
                           readonly>
                    <label>Debtor name</label>
                </span>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <digi-currency [currency]="debtor?.currency" (onSelect)="selectCurrency($event)"></digi-currency>
          </div>
          <div class="ui-g-3 ui-md-3">
            <digi-country-of-origin [country]="debtor?.country" (onSelect)="selectCountry($event)"></digi-country-of-origin>
          </div>
        </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-3 ui-md-3">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
              <p-dropdown [autoDisplayFirst]="false"
                          [options]="statuses"
                          [(ngModel)]="debtor.status"
                          [showClear]="true"
                          [autoWidth]="false"
                          id="float-status-label">
              </p-dropdown>
              <label for="float-status-label">Status</label>
            </span>
          </div>
        </div>
        <div class="ui-g-3 ui-md-3">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
              <p-dropdown [autoDisplayFirst]="false"
                          [options]="creditTerms"
                          [(ngModel)]="debtor.creditTerms"
                          [showClear]="true"
                          [autoWidth]="false"
                          id="float-creditTerms-label">
              </p-dropdown>
              <label for="float-creditTerms-label">Credit Terms</label>
            </span>
          </div>
        </div>
        <div class="ui-g-3 ui-md-3">
          <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"  pInputText [(ngModel)]="debtor.creditBalance">
                    <label>Credit Balance</label>
                </span>
          </div>
        </div>
        <div class="ui-g-3 ui-md-3">
          <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"  pInputText [(ngModel)]="debtor.insCreditLimit">
                    <label>Ins. Credit Limit</label>
                </span>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-4 ui-md-4">
          <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"  pInputText [(ngModel)]="debtor.creditLimit">
                    <label>Credit Limit</label>
                </span>
          </div>
        </div>
        <div class="ui-g-4 ui-md-4">
          <div class="ui-inputgroup">
            <span class="ui-float-label">
                <p-dropdown [autoDisplayFirst]="false"
                            [options]="includeFreightInCreditLimit"
                            [(ngModel)]="debtor.includeFreightInCreditLimit"
                            [showClear]="true"
                            [autoWidth]="false"
                            id="float-includeFreightInCreditLimit"
                >
                </p-dropdown>
              <label for="float-includeFreightInCreditLimit">Include Freight In Credit Limit? (Y/N)</label>
            </span>
          </div>
        </div>
        <div class="ui-g-4 ui-md-4">
          <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"  pInputText [(ngModel)]="debtor.externalDebtorAccountNumber">
                    <label>External Debtor Account Number</label>
                </span>
          </div>
        </div>
      </div>
    </div>
</p-panel>
