import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CustomsTraderTypeService = /** @class */ (function () {
    function CustomsTraderTypeService(http, config) {
        this.http = http;
        this.config = config;
    }
    CustomsTraderTypeService.prototype.findRegistrationTypeStartsWith = function (tradeType) {
        return this.http
            .get(this.config.apiEndpoint + "api/customsTraderType/search/codeStartsWith?description=" + tradeType)
            .pipe(map(function (response) { return response['_embedded'].customsTraderType; }));
    };
    CustomsTraderTypeService.ngInjectableDef = i0.defineInjectable({ factory: function CustomsTraderTypeService_Factory() { return new CustomsTraderTypeService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CustomsTraderTypeService, providedIn: "root" });
    return CustomsTraderTypeService;
}());
export { CustomsTraderTypeService };
