import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {CustomsRadioCallSign, OceanLine, PortDetail} from '../../models/file';
import {OceanlineService} from '../../../digi-ocean/services/oceanline.service';
import {VoyageFile, VoyageMasterFile} from '../../models/voyageFile';
import {CustomsRadioCallSignService} from '../../services/customs-radio-call-sign.service';
import {ActivatedRoute} from '@angular/router';
import {VoyageFileService} from '../../services/voyage-file.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'digi-ships-agent',
  templateUrl: './ships-agent.component.html',
  styles: [`
    .ui-steps-item {
      width: 20%
    }`],
  encapsulation: ViewEncapsulation.None
})
export class ShipsAgentComponent implements OnInit {

  items: MenuItem[];
  activeIndex = 0;
  view = 'register';
  disableButton: boolean;
  voyageFile: VoyageFile;
  filteredOceanLines: OceanLine[];
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[];
  indicator: string;
  voyageFiles: VoyageFile[];
  voyageMasterFilesCols: any[];
  selectedVoyageMasterFile: VoyageMasterFile;

  constructor(
    private oceanLineService: OceanlineService,
    private customsRadioCallSignService: CustomsRadioCallSignService,
    private route: ActivatedRoute,
    private voyageFileService: VoyageFileService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.items = [
      {
        label: 'Registration',
        command: (event: any) => {
          this.activeIndex = 0;
          this.view = 'register';
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Ocean BOL',
        command: (event: any) => {
          this.activeIndex = 1;
          this.view = 'bol';
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Reports',
        command: (event: any) => {
          this.activeIndex = 2;
          this.view = 'reports';
        },
        icon: 'fa fa-user-plus'
      }
    ];

    this.indicator = this.route.snapshot.url[0].path === 'sea' ? 'import' : 'export';
    this.createNewVoyageFile();
  }

  saveVoyageFile() {
    this.voyageFileService.saveVoyageFile(this.voyageFile).subscribe(
      data => {
        this.voyageFile = data;
        this.notificationService.successNotify('Saved Voyage File Successfully');
      }
    );
  }

  createNewVoyageFile() {
    this.voyageFile = new VoyageFile();
    this.voyageFile.type = this.indicator;
    this.voyageFiles = [];
  }

  searchOceanLines(event: any) {
    this.oceanLineService.findAllOceanLineStartsWith(event.query).subscribe(data => {
      this.filteredOceanLines = data;
    });
  }

  searchRadioCallSigns(event, field) {
    this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(data => {
      this.filteredCustomsRadioCallSigns = data;
    });
  }

  selectDeparturePort(value: PortDetail) {
    this.voyageFile.departurePort = value;
  }

  selectDestinationPort(value: PortDetail) {
    this.voyageFile.destinationPort = value;
  }

  selectTransferPort(value: PortDetail) {
    this.voyageFile.transferPort = value;
  }

  updateOriginalArrivalDockingAndETADates() {
    if (!this.voyageFile.originalArrivalDate) {
      this.voyageFile.originalArrivalDate = this.voyageFile.arrivalDate;
    }

    if (!this.voyageFile.dockingDate) {
      this.voyageFile.dockingDate = this.voyageFile.arrivalDate;
    }

    if (!this.voyageFile.transShipmentArrivalDate) {
      this.voyageFile.transShipmentArrivalDate = this.voyageFile.arrivalDate;
    }
  }

  updateOriginalDepartureETDAndSailingDates() {
    if (!this.voyageFile.originalDepartureDate) {
      this.voyageFile.originalDepartureDate = this.voyageFile.departureDate;
    }

    if (!this.voyageFile.sailingDate) {
      this.voyageFile.sailingDate = this.voyageFile.departureDate;
    }

    if (!this.voyageFile.transShipmentDepartureDate) {
      this.voyageFile.transShipmentDepartureDate = this.voyageFile.departureDate;
    }
  }

  updateVoyageFiles(voyageFiles: VoyageFile[]) {
    this.voyageFiles = voyageFiles;
  }

  updateVoyageFile(value) {
    this.voyageFile = value;
  }

  createNewVoyageMasterFile() {
    const voyageMasterFile = this.voyageFileService.createNewVoyageMasterFile();
    this.voyageFile.voyageMasterFiles.push(voyageMasterFile);
  }

  deleteVoyageFile(index: number) {
    const voyageFile: VoyageFile = this.voyageFiles[index];
    if (voyageFile.id) {
      this.voyageFileService.deleteVoyageFile(voyageFile.id).subscribe(
          _ => this.voyageFiles = this.voyageFiles.filter(el => el.voyageFileNo !== voyageFile.voyageFileNo)
      );
    }
  }

  updateVoyageMasterFile(voyageMasterFile: VoyageMasterFile) {
    if (voyageMasterFile && this.voyageFile.voyageMasterFiles) {
      const foundVoyageMasterFile = this.voyageFile.voyageMasterFiles
        .find(el => el.voyageMasterFileNo === voyageMasterFile.voyageMasterFileNo);

      if (foundVoyageMasterFile) {
        foundVoyageMasterFile.cuscars = voyageMasterFile.cuscars;
        foundVoyageMasterFile.cusres = voyageMasterFile.cusres;
        foundVoyageMasterFile.contrl = voyageMasterFile.contrl;
        foundVoyageMasterFile.dateEdiSubmitted = voyageMasterFile.dateEdiSubmitted;
      }
    }
  }
}
