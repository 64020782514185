import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { BillOfEntryBehaviourSubject } from '../../../../subjects/billOfEntry-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../subjects/clearingfile-behaviour-subject';
import {BillOfEntry, ExportBillOfEntry} from '../../models/billOfEntries';
import { ClearingFile } from '../../models/clearingFile';
import { BillOfEntryService } from '../../services/bill-of-entry.service';
import { NotificationService } from '../../services/notification.service';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {ExportsBillOfEntryService} from '../../services/exports-bill-of-entry.service';

@Component({
  selector: 'digi-boe-file-section',
  templateUrl: './boe-file-section.component.html',
  styleUrls: ['./boe-file-section.component.scss']
})
export class BoeFileSectionComponent implements OnInit, OnDestroy {
  file: ClearingFile|ExportFile;
  billOfEntry: BillOfEntry|ExportBillOfEntry;
  @Output()
  selectedClearingFile: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  billOfEntrySubscription: Subscription;
  @Input() fileType: FileType;


  constructor(private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private billOfEntryService: BillOfEntryService,
              private exportBillOfEntryService: ExportsBillOfEntryService,
              private messageService: NotificationService) {
  }

  ngOnInit() {
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject
        .getClearingFile()
        .subscribe(cf => this.file = cf);
    } else {
      this.subscription = this.exportFileBehaviourSubject
        .getExportFile()
        .subscribe(ef => this.file = ef);
    }
    this.billOfEntrySubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => this.billOfEntry = boe);
  }

  saveBillOfEntry() {
     if (this.fileType === 'imports') {
       this.billOfEntryService.saveBillOfEntry(this.billOfEntry, this.file).subscribe(data => {
         this.billOfEntryBehaviourSubject.addBillOfEntry(data);
         this.addSuccess('Bill Of Entry Saved');
       }, error => this.addFailure(error));
     } else {
       this.exportBillOfEntryService.saveBillOfEntry(this.billOfEntry, <ExportFile>this.file).subscribe(data => {
         this.billOfEntryBehaviourSubject.addBillOfEntry(data);
         this.addSuccess('Bill Of Entry Saved');
       }, error => this.addFailure(error));
     }
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  addSuccess(msg: any) {
    this.messageService.successNotify(
      'Success',
      msg
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.billOfEntrySubscription.unsubscribe();
  }
}
