import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClearingInstructionsService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  findAll(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/customsPurposes?size=1000&projection=flattenedCustomsPurpose`)
      .pipe(
        map(response => response['_embedded'].customsPurposes)
      );
  }

  findAllClearingInstructions(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/customsPurposes/search/imports?projection=flattenedCustomsPurpose`);
  }

  findAllExportInstructions(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/customsPurposes/search/exports?projection=flattenedCustomsPurpose`);
  }

  findAllExBondInstructions(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/customsPurposes/search/exbond?projection=flattenedCustomsPurpose`);
  }

  findCustomProceduresForPreviousBOE(): Observable<any> {
    return this.http.get(`${this.config.apiEndpoint}api/customsProcedures/search/customsPurposeIn?customsPurposeCode=RIB,RIT`)
      .pipe(
        map(response => response['_embedded'].customsProcedures)
      );
  }

  findCustomProceduresForExbond(): Observable<any> {
    const codes = 'XDP';
    return this.http.get(`${this.config.apiEndpoint}api/customsProcedures/search/customsPurposeIn?customsPurposeCode=${codes}`)
      .pipe(
        map(response => response['_embedded'].customsProcedures)
      );
  }
}
