<div class="ui-g form-group" *ngIf="clearingInstruction">
  <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="importerConsignee"
                            [(ngModel)]="clearingInstruction.importerConsignee"
                            field="label"
                            [suggestions]="filteredConsignees"
                            (completeMethod)="search($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectConsignee($event)"
                            minLength="3"
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}: {{option.name}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="importerConsignee">Importer Consignee</label>
            </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
      <div *ngIf="!clearingInstruction?.importerConsignee?.code"  class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
  </div>
  <digi-address [addresses]="importerConsigneeAddresses" [address]="clearingInstruction?.importerConsignee?.address"></digi-address>
</div>
