import { of } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { FileService } from './file.service';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "./file.service";
var TariffService = /** @class */ (function () {
    function TariffService(http, config, fileService) {
        this.http = http;
        this.config = config;
        this.fileService = fileService;
    }
    TariffService.prototype.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff = function (searchValue) {
        var _this = this;
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/codeOrDescriptionStartsWithCurrentlyEffectiveAndIsTariff?code="
            + searchValue + '&date=' + moment().format('DD/MM/YYYY') + '&' + 'projection=tariffCodeWithFullDescription')
            .pipe(map(function (response) { return response['_embedded'].tariffCodes; })).pipe(tap(function (_) { return _this.fileService.setIsLoadingTariffData(false); }));
    };
    TariffService.prototype.findByCodeList = function (codes, date) {
        return this.http
            .post(this.config.apiEndpoint + "api/tariffCodes/search/findByPartDescription?&mawbDate=" + date + "&projection=tariffCodeWithFullDescription", codes)
            .pipe(map(function (response) { return response['_embedded'].tariffCodes; }));
    };
    TariffService.prototype.findTariffByCodeStartsWithCurrentlyEffective = function (searchValue) {
        if (searchValue === '') {
            return of([]);
        }
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/codeOrDescriptionStartsWithCurrentlyEffective?codeOrDescription="
            + searchValue + '&' + 'projection=tariffCodeWithFullDescription')
            .pipe(map(function (response) { return response['_embedded'].tariffCodes; }));
    };
    TariffService.prototype.findByTariffCodeSchedulePartAndDependentCode = function (tariffCode, schedulePart, dependantCode) {
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/codeStartsWithCurrentlyEffectiveAndScheduleCode?code="
            + tariffCode + '&schedule=' + schedulePart + '&dependantCode=' + dependantCode + '&projection=tariffCodeWithFullDescription')
            .pipe(map(function (response) { return response['_embedded'].tariffCodes; }));
    };
    TariffService.prototype.saveTariffDescriptionHistory = function (tariffDescriptionHistory) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.apiEndpoint + 'api/tariffDescriptionHistories', tariffDescriptionHistory, { headers: headers });
    };
    TariffService.prototype.saveTariffFormula = function (tariffFormulaAndTariffParameter) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.apiEndpoint + 'api/tariffFormula', tariffFormulaAndTariffParameter, { headers: headers });
    };
    TariffService.prototype.saveTariffCode = function (tariffCode) {
        console.log(tariffCode);
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.apiEndpoint + 'api/tariffCodes', tariffCode, { headers: headers });
    };
    TariffService.prototype.findTariffCodeByTariffId = function (tariffId) {
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/findByTariffId?tariffID=" + tariffId + "&projection=tariffCodeWithFullDescription")
            .pipe(map(function (response) { return response; }));
    };
    TariffService.prototype.findTariffCodeByCodeAndCheckDigit = function (code, checkDigit) {
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/findByCodeAndCheckDigit?code=" + code + "&checkDigit=" + checkDigit);
    };
    TariffService.prototype.findTariffByCodeStartsWith = function (code) {
        return this.http
            .get(this.config.apiEndpoint + "api/tariffCodes/search/codeStartsWith?code="
            + code + '&' + 'projection=tariffCodeWithFullDescription')
            .pipe(map(function (response) { return response['_embedded'].tariffCodes; }));
    };
    TariffService.ngInjectableDef = i0.defineInjectable({ factory: function TariffService_Factory() { return new TariffService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.FileService)); }, token: TariffService, providedIn: "root" });
    return TariffService;
}());
export { TariffService };
