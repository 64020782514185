import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {BillOfEntryLine, ExportBillOfEntryLine} from '../app/digi-common/models/billOfEntries';

@Injectable({
  providedIn: 'root'
})
export class BillOfEntryLineBehaviourSubject {
  private billOfEntryLineSubject = new BehaviorSubject<BillOfEntryLine|ExportBillOfEntryLine>(null);

  constructor() {
  }
  addBillOfEntryLine(billOfEntryLine: BillOfEntryLine|ExportBillOfEntryLine) {
    this.billOfEntryLineSubject.next(billOfEntryLine);
  }

  getBOELine(): Observable<BillOfEntryLine|ExportBillOfEntryLine> {
    return this.billOfEntryLineSubject.asObservable();
  }
}
