import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var WarehouseBehaviourSubject = /** @class */ (function () {
    function WarehouseBehaviourSubject() {
        this.warehousesSubject = new Subject();
    }
    WarehouseBehaviourSubject.prototype.addWarehouses = function (debtors) {
        this.warehousesSubject.next(debtors);
    };
    WarehouseBehaviourSubject.prototype.getWarehouses = function () {
        return this.warehousesSubject.asObservable();
    };
    WarehouseBehaviourSubject.ngInjectableDef = i0.defineInjectable({ factory: function WarehouseBehaviourSubject_Factory() { return new WarehouseBehaviourSubject(); }, token: WarehouseBehaviourSubject, providedIn: "root" });
    return WarehouseBehaviourSubject;
}());
export { WarehouseBehaviourSubject };
