import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CustomsOffice, Importer} from '../../digi-business-entity/models/business-entity';
import {PaymentsReportService} from '../services/payments-report.service';
import {NotificationService} from '../services/notification.service';
import {ImporterService} from '../services/importer.service';
import {CustomOfficeService} from '../services/custom-office.service';

@Component({
  selector: 'digi-deferred-payments',
  templateUrl: './deferred-payments.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white input{
      color: white !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  .page {
      border: 9px solid transparent !important;
      background-clip: content-box !important;
      -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
  }
  `],
  encapsulation: ViewEncapsulation.None
})
export class DeferredPaymentsComponent implements OnInit {
  importer: Importer;
  filteredImporters: Importer[];
  fromDate: string;
  toDate: string;
  customsOffice: CustomsOffice;
  display = false;
  pdfSrcImports: string;
  pdfSrcExBond: string;
  importers: any[];
  filteredCustomsOffices: CustomsOffice[];
  disableButton: boolean;

  constructor(
    private paymentsReportService: PaymentsReportService,
    private notificationService: NotificationService,
    private importerService: ImporterService,
    private customsOfficeService: CustomOfficeService
  ) {
  }

  ngOnInit() {
  }

  searchImporter(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  generate(fileType) {
    let importerCodes = [];
    if (this.importers) {
      importerCodes = this.importers.map(el => el.code);
    }
    this.disableButton = true;
    if (fileType === 'clearingFile') {
      this.pdfSrcExBond = null;
      this.paymentsReportService.generateImportsReport(this.fromDate, this.toDate, this.customsOffice.districtOfficeCode, importerCodes).subscribe(data => {
        this.pdfSrcImports = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`; // to refresh link
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrcImports = null;
        this.disableButton = false;
      });
    } else {
      this.pdfSrcImports = null;
      this.paymentsReportService.generateExBondReport(this.fromDate, this.toDate, this.customsOffice.districtOfficeCode, importerCodes).subscribe(data => {
        this.pdfSrcExBond = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`; // to refresh link
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrcExBond = null;
        this.disableButton = false;
      });
    }
  }

  openLinkImports() {
    window.open(this.pdfSrcImports, '_blank');
  }

  openLinkExBond() {
    window.open(this.pdfSrcExBond, '_blank');
  }

  selectCustomsOffice(value: CustomsOffice) {
    this.customsOffice = value;
  }

  searchCustomsOffice(event) {
    this.customsOfficeService.findAllCustomsOfficeStartWith(event.query).subscribe(data => {
      this.filteredCustomsOffices = data;
    });
  }
}
