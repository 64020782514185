import { Component, OnInit } from "@angular/core";
import { DiagnosticService } from "../diagnostics/diagnostic.service";
import { DiagnosticTest } from "./diagnostic-test.model";
import { finalize } from "rxjs/operators";
// import { DatePipe } from '@angular/common';

@Component({
  selector: "app-diagnostics",
  templateUrl: "./diagnostics.component.html",
  styleUrls: ["./diagnostics.component.scss"]
  // providers: [DatePipe]
})
export class DiagnosticsComponent implements OnInit {
  diagnosticTests: DiagnosticTest[];
  selectedDiagnosticTest: DiagnosticTest;
  cols: any[];
  isRunning: boolean = false;
  numberOfTestsToRun: number = 0;
  numberOfCompletedTests: number = 0;
  percentCompleted: number = 100;

  constructor(private diagnosticService: DiagnosticService) {}

  ngOnInit() {
    this.diagnosticTests = this.diagnosticService.getDiagnosticServiceTests();
    this.numberOfTestsToRun = this.diagnosticTests.filter(d =>
      d.isImplemented()
    ).length;

    this.cols = [
      { field: "id", header: "ID" },
      { field: "description", header: "Description" },
      { field: "startTime", header: "Start time" },
      { field: "endTime", header: "End time" },
      { field: "duration", header: "Duration (ms)" },
      { field: "statusDescription", header: "Status" }
    ];
  }

  runDiagnosticTests() {
    this.isRunning = true;
    this.numberOfCompletedTests = 0;
    this.diagnosticTests.forEach(diagnosticTest => {
      if (diagnosticTest.isImplemented()) {
        console.log("UI Starting test: ", diagnosticTest.id);
        diagnosticTest.testToRun
          .pipe(finalize( () => this.updateIsRunning()))
          .subscribe();
        // diagnosticTest.testToRun.subscribe(
        //   result => {
        //     console.log("UI Success with test: ", diagnosticTest.id);
        //     this.updateIsRunning();
        //   },
        //   error => {
        //     console.log("UI Error with test: ", diagnosticTest.id);
        //     this.updateIsRunning();
        //   }
        // );
      }
    });
  }

  updateIsRunning() {
    this.numberOfCompletedTests++;
    this.percentCompleted = this.numberOfCompletedTests / this.numberOfTestsToRun * 100;
    console.log('this.percentCompleted=', this.percentCompleted);
    if (this.numberOfCompletedTests === this.numberOfTestsToRun) {
      console.log("UI Completed ALL tests now");
      this.isRunning = false;
    }
  }

  getBackgroundColorByStatus(status: boolean): string {
    return status ? "green" : status === false ? "red" : "transparent";
  }

  getBackgroundColorByStatusDescription(statusDescription: string): string {
    switch (statusDescription) {
      // case "not found yet":
      case "Running...":
        return "yellow";
      case "Success":
        return "green";
      case "Fail":
        return "red";
    }
    return "transparent";
  }
}
