<p-tabView>
  <p-tabPanel header="Line details">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="ui-g-12 ui-md-4">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g form-group">
                <p-panel header="Values">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                            value="{{selectedBillOfEntryLine?.customsValue}}">
                          <label *ngIf="fileType==='imports' || fileType==='exbond'">Customs Value</label>
                          <label *ngIf="fileType==='exports'">Exports Value</label>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                            value="{{selectedBillOfEntryLine?.actualValue}}">
                          <label *ngIf="fileType==='imports' || fileType==='exbond'">Actual price</label>
                          <label *ngIf="fileType==='exports'">Actual Value</label>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12" *ngIf="fileType==='imports' || fileType==='exbond'">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                            value="{{selectedBillOfEntryLine?.customsDuty}}">
                          <label>Customs Duty</label>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12" *ngIf="fileType==='imports' || fileType==='exbond'">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [ngModel]="selectedBillOfEntryLine?.customsVAT"
                                 (ngModelChange)="selectedBillOfEntryLine?.customsVAT ? selectedBillOfEntryLine.customsVAT=$event : selectedBillOfEntryLine.customsVAT=$event">
                          <label>Customs Vat</label>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12" *ngIf="fileType==='imports' || fileType==='exbond'">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                            value="{{selectedBillOfEntryLine?.totalRebate}}">
                          <label>Total Rebated</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </p-panel>
                <br>
                <p-panel header="SchedulePart Amounts Per Line" *ngIf="fileType==='imports' || fileType==='exbond'">
                  <digi-app-boe-schedule-amount-per-line [markedAndSubmitted]="markedAndSubmitted"></digi-app-boe-schedule-amount-per-line>
                </p-panel>
              </div>
            </div>
          </div>
          <div class="ui-g-12 ui-md-4">
            <div class="ui-g-12 ui-md-12">
              <p-panel header="Provisional Payment" *ngIf="fileType==='imports' || fileType==='exbond'">
                <digi-boe-line-schedule-amount></digi-boe-line-schedule-amount>
              </p-panel>
              <p-panel header="Certificate" *ngIf="fileType==='exports' || selectedBillOfEntryLine?.certificate">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 value="{{selectedBillOfEntryLine?.certificate?.type?.description}}">
                          <label>Certificate Type</label>
                        </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 value="{{selectedBillOfEntryLine?.certificate?.certificateNo}}">
                          <label>Certificate Number</label>
                        </span>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Additional Information">
                <digi-boe-line-additional-info></digi-boe-line-additional-info>
              </p-panel>
              <br>
              <p-panel header="Additional UOMs">
                <p-table [columns]="cols" [value]="selectedBillOfEntryLine?.additionalUoms"
                         selectionMode="single"
                         dataKey="code">
                  <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                      <col *ngFor="let col of columns" [style.width]="col.width"/>
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">{{ col.header }}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                      <td class="ui-resizable-column">{{ rowData?.uomCode }}</td>
                      <td>
                        <input type="text" style="height: 20px;" pInputText [(ngModel)]="rowData.value"/>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-panel>
            </div>
          </div>
          <div class="ui-g-12 ui-md-4">
            <div class="ui-g-12 ui-md-12">
              <p-panel header="Original Bill of Entry Details" *ngIf="fileType==='imports'">
                <digi-boe-line-original-boe></digi-boe-line-original-boe>
              </p-panel>
              <br *ngIf="fileType==='imports' || fileType==='exbond'">
              <p-panel header="Units & Quantities">
                <digi-boe-line-units-quantity></digi-boe-line-units-quantity>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
