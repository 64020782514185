<div class="ui-g ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12">
      <p-panel header="Total SchedulePart Amounts">
        <div class="content-section implementation">
          <p-table [value]="selectedBillOfEntry.schedulePartTotals"
                   [resizableColumns]="true">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-schedulePartTotal let-columns="columns">
              <tr *ngIf="schedulePartTotal?.schedulePart?.chargeRebate !='R' && schedulePartTotal.totalValue != 0">
                <td class="ui-resizable-column">
                  <label>{{schedulePartTotal?.schedulePart?.code}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{schedulePartTotal?.totalValue}}</label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-panel>
    </div>
  </div>
</div>
