import {Country, Currency} from './../../models/business-entity';
import {Component, Input, OnInit} from '@angular/core';
import {BusinessEntity, Debtor} from '../../models/business-entity';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'digi-debtor-role',
  templateUrl: './debtor-role.component.html',
  styleUrls: ['./debtor-role.component.scss']
})
export class DebtorRoleComponent implements OnInit {
  @Input() businessEntity: BusinessEntity;
  @Input() debtor: Debtor;
  statuses: SelectItem[];
  creditTerms: SelectItem[];
  includeFreightInCreditLimit: SelectItem[];

  ngOnInit(): void {
    this.statuses = [
      { label: 'Active', value: 'Active'},
      { label: 'Hold', value: 'Hold'},
      { label: 'Suspend', value: 'Suspend'},
      { label: 'Provisional Credit Limit (PCL)', value: 'Provisional Credit Limit (PCL)'}
    ];
    this.creditTerms = [
      { label: 'Cur', value: 'Cur'},
      { label: '30 Days', value: '30 Days'},
      { label: '60 Days', value: '60 Days' },
      { label: '90 Days', value : '90 Days' },
      { label: '120 Days', value : '120 Days'}
    ];
    this.includeFreightInCreditLimit = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ];
  }

  selectCurrency(value: Currency): void {
    this.debtor.currency = value;
  }

  selectCountry(value: Country): void {
    this.debtor.country = value;
  }
}
