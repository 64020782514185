import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ExBondFileBehaviourSubject } from '../../subjects/exbondfile-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "../../subjects/exbondfile-behaviour-subject";
import * as i2 from "@angular/router";
var ExBondFileExistsGuard = /** @class */ (function () {
    function ExBondFileExistsGuard(exBondFileBehaviourSubject, router) {
        this.exBondFileBehaviourSubject = exBondFileBehaviourSubject;
        this.router = router;
    }
    ExBondFileExistsGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.exBondFileBehaviourSubject.getExBondFile().pipe(take(1), switchMap(function (ef) {
            if (ef.id) {
                return of(true);
            }
            else {
                var parent_1 = state.root.firstChild.routeConfig.path;
                _this.router.navigate([parent_1, 'register']);
                return of(false);
            }
        }));
    };
    ExBondFileExistsGuard.ngInjectableDef = i0.defineInjectable({ factory: function ExBondFileExistsGuard_Factory() { return new ExBondFileExistsGuard(i0.inject(i1.ExBondFileBehaviourSubject), i0.inject(i2.Router)); }, token: ExBondFileExistsGuard, providedIn: "root" });
    return ExBondFileExistsGuard;
}());
export { ExBondFileExistsGuard };
