import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CertificateTypeService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) { }

  findByCode(code) {
    return this.http
      .get(`${this.config.apiEndpoint}api/certificateTypes/search/codeStartsWith?code=${code}`)
      .pipe(
        map(response => response['_embedded'].certificateTypes)
      );
  }
}
