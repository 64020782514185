import {Component, OnDestroy, OnInit} from '@angular/core';
import {Container, ContainerSpecification} from '../../../models/file';
import {ContainerSizeService} from '../../../../digi-ocean/services/container-size.service';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {Subscription} from 'rxjs';


@Component({
  selector: 'digi-container-size-section',
  templateUrl: './container-size-section.component.html',
  styleUrls: ['./container-size-section.component.scss']
})
export class ContainerSizeSectionComponent implements OnInit, OnDestroy {
  filteredContainerSpecifications: ContainerSpecification[] = [];
  selectedContainer: Container;
  containerSubscription: Subscription;

  constructor(private containerSizeService: ContainerSizeService,
              private containerBehaviorSubject: ContainerBehaviorSubject) {
  }

  ngOnInit() {
   this.containerSubscription = this.containerBehaviorSubject
      .getContainer()
      .subscribe(ct => this.selectedContainer = ct);
  }

  search(code) {
    this.containerSizeService.findAllContainerSizeStartsWith
    (code.query)
      .subscribe(data => {
        this.filteredContainerSpecifications = data;
        console.log(data);
      });
  }

  select(value) {
    this.selectedContainer.containerSpecification = value;
  }

  ngOnDestroy(): void {
    this.containerSubscription.unsubscribe();
  }
}
