<p-toolbar>
  <div class="ui-toolbar-group-left">
    <div class="ui-g-12 pt-0">
      <digi-selected-tariff-section></digi-selected-tariff-section>
    </div>
  </div>
  <div class="ui-toolbar-group-right">
    <button pButton type="button" label="New" icon="fa fa-file" (click)="createNewTariffFormula()"></button>
    <button pButton type="button" label="Save" icon="fa fa-save"
            (click)="saveNewTariffFormula()"></button>
  </div>
</p-toolbar>
<p-table [columns]="cols" [value]="selectedTariffCode?.formulae" selectionMode="single" columnResizeMode="expand"
         [(selection)]="selectedFormula"
         (onRowSelect)="selectTariffFormula($event)"
         (sortFunction)="customSort($event)" [customSort]="true"
         [scrollable]="true"
         scrollHeight="200px">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field.id">
        {{col.header}}
        <p-sortIcon [field]="col.field.id" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tariffFormula let-rowIndex="rowIndex">
    <tr [pSelectableRow]="tariffFormula" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
      <td>{{tariffFormula?.id?.tradeGroupCode}}</td>
      <td>{{tariffFormula?.formula?.number}}</td>
      <td>{{tariffFormula?.id?.formulaEffDate}}</td>
      <td>{{tariffFormula?.formulaEffUntilDate}}</td>
      <td>{{tariffFormula?.filledFormulaString1}}</td>
    </tr>
  </ng-template>
</p-table>
<div class="ui-g-12 pt-2">
  <p-panel header="Tariff Formula Details">
    <div class="ui-g ui-fluid">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-4">
          <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <span class="ui-float-label">
                      <p-inputMask id="formulaEffectiveFrom"
                                   mask="99/99/9999"
                                   slotChar="dd/mm/yyyy"
                                   [(ngModel)]="selectedFormula.id.formulaEffDate">
                      </p-inputMask>
                      <label for="formulaEffectiveFrom">Effective Date</label>
                    </span>
                </span>
          </div>&nbsp;
          <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                    <div class="ui-inputgroup">
                      <span class="ui-float-label">
                        <p-autoComplete id="tradeGroupCode" name="tradeGroupCode"
                                        [(ngModel)]="selectedFormula.id.tradeGroupCode"
                                        [suggestions]="filteredTradeAgreements"
                                        (completeMethod)="search($event)"
                                        [autoHighlight]="true"
                                        (onSelect)="select($event)"
                                        [dropdown]="true"
                                        minLength="0"
                                        required=true>
                          <ng-template let-option pTemplate="item">
                            <div>{{option.code}}</div>
                          </ng-template>
                        </p-autoComplete>
                        <label for="tradeGroupCode">Trade Agreement</label>
                      </span>
                    </div>
                </span>
          </div>
          <div class="ui-g-12 ui-md-12" style="margin-top:15px;">
               <span class="md-inputfield">
                  <div class="ui-inputgroup">
                    <span class="ui-float-label">
                        <p-autoComplete id="uom"
                                        name="uomCode"
                                        [(ngModel)]="selectedFormula.uomCode"
                                        (completeMethod)="searchUnitMeasure($event)"
                                        (onSelect)="selectUnitMeasure($event)"
                                        [autoHighlight]="true"
                                        minLength="1"
                                        [suggestions]="filteredUoms"
                                        required=true>
                          <ng-template let-option pTemplate="item">
                            <div>{{option.uomCode}}</div>
                          </ng-template>
                        </p-autoComplete>
                        <label for="uom">Statical Unit of Measure</label>
                    </span>
                     <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                  </div>
               </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-5">
          <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete id="referenceCode" name="referenceCode"
                                      [ngModel]="selectedFormula?.referenceCode"
                                      [suggestions]="filteredtariffRefs"
                                      (completeMethod)="searchReferenceCode($event)"
                                      (onSelect)="selectReferenceCode($event)"
                                      [autoHighlight]="true" minLength="1"
                                      required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.referenceCode}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="referenceCode">Reference Code</label>
                  </span>
                  <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                </div>
              </span>
          </div>
          <div class="ui-g-12 ui-md-12" style="margin-top:15px;">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="ui-float-label">
                      <p-dropdown id="formula"
                                  [options]="filteredFormulas"
                                  [(ngModel)]="selectedFormula.formula"
                                  optionLabel="description"
                                  [autoDisplayFirst]="false"
                                  [autofocus]="true"
                                  [style]="{'width':'425px'}"
                                  (onChange)="selectFormula($event)">
                      </p-dropdown>
                      <label for="formula">Formula No</label>
                 </span>
              </div>
            </span>
          </div>
          <div class="ui-g-4 ui-fluid">
            <button pButton type="button" label="Display Gazette"></button>
          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid pt-2">
        <div class="ui-g-7">
          <p-table [columns]="paramCols" selectionMode="single"
                   [value]="tariffAndFormulaParams"
                   columnResizeMode="expand"
                   [paginator]="true" [rows]="5">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td>{{rowData.formulaParam.value}}</td>
                <td>
                  <input type="text" style="height: 20px;" pInputText
                         (change)="fillFormulaString()"
                         (keyup)="fillFormulaString()"
                         [(ngModel)]="rowData?.tariffParam.value1"/>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="ui-g-5 pt-2">
          <p-panel header="Formula Description Section">
            <div (change)="fillFormulaString()">{{selectedFormula.filledFormulaString1}}</div>
          </p-panel>
        </div>
      </div>
    </div>
  </p-panel>
</div>
