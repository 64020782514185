import { Component, Input, OnInit } from '@angular/core';
import { ClearingFile } from '../../../models/clearingFile';
import { IncoTerm } from '../../../models/file';
import { FileIncoTermService } from '../../../services/file-inco-term.service';

@Component({
  selector: 'digi-file-inco-term',
  templateUrl: './file-inco-term.component.html',
  styleUrls: ['./file-inco-term.component.scss']
})
export class FileIncoTermComponent implements OnInit {
  @Input('clearingFile') clearingFile: ClearingFile;
  filteredIncoTerms: IncoTerm[];

  constructor(private fileIncoTermService: FileIncoTermService) {
  }

  ngOnInit() {
  }

  search(event) {
    this.fileIncoTermService.findAllFileIncoTermStartsWith(event.query).subscribe(data => {
      this.filteredIncoTerms = data;
    });
  }
}
